import { InfoTooltip } from "@decentriq/components";
import { Typography } from "@mui/material";
import { memo } from "react";

type SuppressedSegmentsInformationProps = {
  possibleValues: string[];
  suppressedValues: string[];
  hiddenValues: (string | undefined)[];
};

const SuppressedSegmentsInformation: React.FC<
  SuppressedSegmentsInformationProps
> = ({ possibleValues, suppressedValues, hiddenValues }) => {
  return (
    <Typography fontSize={12} variant="caption">
      Showing{" "}
      {possibleValues.length - hiddenValues.length - suppressedValues.length} of{" "}
      {possibleValues.length} values
      <InfoTooltip
        iconSize="xs"
        tooltip={
          <>
            {suppressedValues.length ? (
              <Typography variant="body2">
                The following values have been suppressed for privacy. There
                were too few users in the overlap.
                {suppressedValues.map((value) => (
                  <li style={{ marginLeft: 16 }}>"{value}"</li>
                ))}
              </Typography>
            ) : null}
            {hiddenValues.length ? (
              <Typography mt={suppressedValues.length ? 1 : 0} variant="body2">
                The following values are not shown because of the chart size.
                {hiddenValues.map((value, index) => (
                  <li key={`${index}-${value}`} style={{ marginLeft: 16 }}>
                    "{value}"
                  </li>
                ))}
              </Typography>
            ) : null}
          </>
        }
      />
    </Typography>
  );
};

SuppressedSegmentsInformation.displayName = "SuppressedSegmentsInformation";

export default memo(SuppressedSegmentsInformation);
