import {
  ImageCropper,
  useImageCropper,
  type UseImageCropperOptions,
} from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { faArrowUpFromLine } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useBoolean } from "ahooks";
import { memo, useCallback, useEffect } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

interface ImageUploadButtonProps {
  TriggerButtonProps?: { children?: React.ReactNode };
  SelectButtonProps?: { children?: React.ReactNode };
  modalTitle?: string;
  imageCropperHookOptions?: UseImageCropperOptions;
  uploadButtonTitle?: string;
  disabled?: boolean;
  snackbarOrigin?: string;
  skipCropping?: boolean;
  onImageSelected?: (base64Data: string) => void;
  testIdPrefix?: string;
}

const ImageUploadButton: React.FC<ImageUploadButtonProps> = ({
  TriggerButtonProps = { children: "Upload new logo" },
  SelectButtonProps = { children: "Upload new logo" },
  modalTitle = "New logo upload",
  disabled,
  snackbarOrigin = CommonSnackbarOrigin.ADMIN,
  imageCropperHookOptions = {
    fillMode: "transparent",
    maxSizeInMbs: 10,
    minImageDimensions: { height: 100, width: 100 },
  },
  skipCropping,
  onImageSelected,
  testIdPrefix = "",
}) => {
  const [cropperOpen, { setTrue: openCropper, setFalse: closeCropper }] =
    useBoolean(false);
  const [isCropping, { setTrue: setIsCropping, setFalse: dismissCropping }] =
    useBoolean(false);
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: snackbarOrigin,
  });
  const handleError = useCallback(
    (error: Error) =>
      enqueueSnackbar(error.message, { persist: true, variant: "error" }),
    [enqueueSnackbar]
  );
  const handleImageSelection = useCallback(
    ({ base64Data }: { base64Data: string }) => {
      if (skipCropping) {
        onImageSelected?.(base64Data);
        return;
      }

      setIsCropping();
    },
    [skipCropping, setIsCropping, onImageSelected]
  );
  const { selectImage, cropImage, ImageCropperProps, resetImageSelection } =
    useImageCropper({
      onCroppingComplete: onImageSelected,
      onError: handleError,
      onImageSelectionComplete: handleImageSelection,
      options: imageCropperHookOptions,
      testIdPrefix: testIdPrefix,
    });
  useEffect(() => {
    if (!cropperOpen) {
      dismissCropping();
      resetImageSelection();
    }
  }, [cropperOpen, dismissCropping, resetImageSelection]);
  return (
    <>
      <Button
        color="inherit"
        disabled={disabled}
        onClick={openCropper}
        startIcon={<FontAwesomeIcon icon={faArrowUpFromLine} />}
        sx={{ height: "fit-content" }}
        variant="outlined"
        {...TriggerButtonProps}
        data-testid={
          testIdPrefix + testIds.components.imageUploader.triggerButton
        }
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={closeCropper}
        open={cropperOpen}
      >
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {modalTitle}
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          {isCropping ? (
            <Box alignItems="center" display="flex" flexDirection="column">
              <Typography sx={{ mb: 2 }} textAlign="center" variant="body2">
                Choose the part of the image to be used.
                <br />
                Area not covered by your image will be filled transparently.
              </Typography>
              <ImageCropper {...ImageCropperProps} />
            </Box>
          ) : (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Button
                color="inherit"
                onClick={selectImage}
                startIcon={<FontAwesomeIcon icon={faArrowUpFromLine} />}
                sx={{
                  height: "fit-content",
                  mb: 7,
                  mt: 5,
                }}
                variant="outlined"
                {...SelectButtonProps}
                data-testid={
                  testIdPrefix +
                  testIds.components.imageUploader.openExplorerButton
                }
              />
              <Typography color={grey[500]} variant="body2">
                File should be at least{" "}
                {imageCropperHookOptions.minImageDimensions?.width}x
                {imageCropperHookOptions.minImageDimensions?.height} in .png or
                .jpg format
              </Typography>
            </Box>
          )}
        </DialogContent>
        {isCropping && (
          <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              color="primary"
              data-testid={
                testIdPrefix + testIds.components.imageUploader.submitButton
              }
              onClick={() => {
                closeCropper();
                cropImage();
              }}
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

ImageUploadButton.displayName = "ImageUploadButton";

export default memo(ImageUploadButton);
