import { useAuth0 } from "@auth0/auth0-react";
import { useOrganizationPreferencesQuery } from "@decentriq/graphql/dist/hooks";
import { OrganizationState } from "@decentriq/graphql/dist/types";

type OrganizationPreferences = {
  isAllowedToCreateDataRoom: boolean;
  state: OrganizationState;
  hasAnalyticsFeatures: boolean;
  hasAdvertiserFeatures: boolean;
  hasPublisherFeatures: boolean;
  hasDataPartnerFeatures: boolean;
  numberOfSupportedMediaRoles: number;
  numberOfAvailableDataRoomTypes: number;
  allowExcludingSeedAudience: boolean;
  canViewDataPartners: boolean;
  canViewMeasurements: boolean;
  canViewMediaDataRoom: boolean;
  isSubscribedState: boolean;
  isSuspendedState: boolean;
  isPassiveState: boolean;
  isTrialState: boolean;
  isArchivedState: boolean;
  isLoading: boolean;
  organizationId: string;
};

const useOrganizationPreferences = (): OrganizationPreferences => {
  const { user } = useAuth0();
  const { data: organizationData, loading } = useOrganizationPreferencesQuery({
    skip: !user?.email,
    variables: {
      userEmail: user!.email!,
    },
  });
  const organizationId = organizationData?.userByEmail?.organization?.id!;
  const state =
    organizationData?.userByEmail?.organization?.state ||
    OrganizationState.Trial;
  const hasAnalyticsFeatures =
    organizationData?.userByEmail?.organization?.hasAnalyticsFeatures || false;
  const hasAdvertiserFeatures =
    organizationData?.userByEmail?.organization?.hasAdvertiserFeatures || false;
  const hasPublisherFeatures =
    organizationData?.userByEmail?.organization?.hasPublisherFeatures || false;
  const hasDataPartnerFeatures =
    organizationData?.userByEmail?.organization?.hasDataPartnerFeatures ||
    false;
  const canViewDataPartners =
    organizationData?.userByEmail?.organization?.canViewDataPartners || false;
  const canViewMeasurements =
    organizationData?.userByEmail?.organization?.canViewMeasurements || false;
  const allowExcludingSeedAudience =
    organizationData?.userByEmail?.organization?.allowExcludingSeedAudience ||
    false;
  const isAllowedToCreateDataRoom =
    [OrganizationState.Subscribed, OrganizationState.Trial].includes(state) &&
    (hasAdvertiserFeatures ||
      hasAnalyticsFeatures ||
      hasPublisherFeatures ||
      hasDataPartnerFeatures ||
      canViewMeasurements ||
      hasDataPartnerFeatures);
  const numberOfSupportedMediaRoles = [
    hasAdvertiserFeatures,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
  ].filter(Boolean).length;
  const canViewMediaDataRoom =
    hasAdvertiserFeatures || hasPublisherFeatures || hasDataPartnerFeatures;
  const numberOfAvailableDataRoomTypes = [
    hasAnalyticsFeatures,
    canViewMediaDataRoom,
    canViewMeasurements,
  ].filter(Boolean).length;
  return {
    allowExcludingSeedAudience,
    canViewDataPartners,
    canViewMeasurements,
    canViewMediaDataRoom,
    hasAdvertiserFeatures,
    hasAnalyticsFeatures,
    hasDataPartnerFeatures,
    hasPublisherFeatures,
    isAllowedToCreateDataRoom,
    isArchivedState: state === OrganizationState.Archived,
    isLoading: loading,
    isPassiveState: state === OrganizationState.Passive,
    isSubscribedState: state === OrganizationState.Subscribed,
    isSuspendedState: state === OrganizationState.Suspended,
    isTrialState: state === OrganizationState.Trial,
    numberOfAvailableDataRoomTypes,
    numberOfSupportedMediaRoles,
    organizationId,
    state,
  };
};

export default useOrganizationPreferences;
