import { testIds } from "@decentriq/utils";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo } from "react";
import {
  MediaInsightsDcrCreationStep,
  useCreationWizardConfiguration,
  useCreationWizardDataPartner,
  useCreationWizardPublisher,
  useCreationWizardStepper,
} from "features/MediaInsightsDcr/contexts";
import { OrganizationTile } from "features/mediaPortalShared";

interface MediaInsightsDcrCreateCollaborationRequestStepProps {
  characterLimit?: number;
}

const MediaInsightsDcrCreateCollaborationRequestStep: React.FC<
  MediaInsightsDcrCreateCollaborationRequestStepProps
> = ({ characterLimit = 800 }) => {
  const {
    requestForCollaborationMessage,
    setRequestForCollaborationMessage,
    handleSendCollaborationRequest,
  } = useCreationWizardConfiguration();
  const { selectedPublisher } = useCreationWizardPublisher();
  const { handleBackStep, activeStep } = useCreationWizardStepper();
  const { selectedDataPartner } = useCreationWizardDataPartner();
  const isDataPartnerStep =
    activeStep ===
    MediaInsightsDcrCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER;
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event) => {
      const message = event.target.value;
      setRequestForCollaborationMessage(
        message.length >= characterLimit
          ? message.slice(0, characterLimit)
          : message
      );
    },
    [characterLimit, setRequestForCollaborationMessage]
  );
  const characterCount = useMemo(
    () => requestForCollaborationMessage.length,
    [requestForCollaborationMessage.length]
  );
  const isRequestForCollaborationMessageEmpty = useMemo(
    () => requestForCollaborationMessage.trim().length === 0,
    [requestForCollaborationMessage]
  );
  const orgDetails: {
    name: string;
    logo: string | undefined;
    marketIds: string[];
  } | null = isDataPartnerStep
    ? selectedDataPartner
      ? {
          logo: selectedDataPartner.dataPartnerLogo,
          marketIds: selectedDataPartner.marketIds,
          name: selectedDataPartner.dataPartnerName,
        }
      : null
    : selectedPublisher
      ? {
          logo: selectedPublisher.publisherLogo,
          marketIds: selectedPublisher.marketIds,
          name: selectedPublisher.publisherName,
        }
      : null;
  return (
    <>
      <DialogTitle>
        {isDataPartnerStep ? "Contact data partner" : "Contact publisher"}
      </DialogTitle>
      <DialogContent>
        <Typography mb={2} variant="body2">
          {isDataPartnerStep
            ? "This data partner needs to create a usage configuration for you. Please reach out to them about your data needs."
            : "This publisher needs to create an activation configuration for you. Please reach out to them about your data needs."}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          {orgDetails ? (
            <OrganizationTile
              imageSx={{ maxHeight: "50px", p: 0, pb: 1 }}
              logo={orgDetails.logo}
              marketIds={orgDetails.marketIds}
              name={orgDetails.name}
              sx={{ height: "150px", ml: 2, width: "232px" }}
            />
          ) : null}
        </Box>
        <FormControl fullWidth={true} sx={{ mb: 1 }}>
          <FormLabel component="legend" sx={{ fontSize: "12px", pl: "2px" }}>
            {`Message (${characterCount}/${characterLimit})`}
          </FormLabel>
          <OutlinedInput
            data-testid={
              testIds.mediaInsightsDcr
                .mediaInsightsDcrCreateCollaborationRequestStep.requestTextarea
            }
            fullWidth={true}
            minRows={4}
            multiline={true}
            notched={false}
            onChange={handleChange}
            placeholder={`Enter message to the ${isDataPartnerStep ? "data partner" : "publisher"}`}
            size="small"
            sx={{
              pl: 1,
            }}
            value={requestForCollaborationMessage}
          />
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "auto",
        }}
      >
        <Button color="inherit" onClick={handleBackStep} variant="text">
          Back
        </Button>
        <Tooltip
          placement="top"
          title={
            isRequestForCollaborationMessageEmpty
              ? "Please enter message"
              : null
          }
        >
          <span>
            <Button
              color="primary"
              data-testid={
                testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard
                  .submitButton
              }
              disabled={isRequestForCollaborationMessageEmpty}
              onClick={handleSendCollaborationRequest}
              variant="contained"
            >
              Send
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};

export default MediaInsightsDcrCreateCollaborationRequestStep;
