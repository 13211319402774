import { useApolloClient } from "@apollo/client";
import {
  useMediaInsightsInviteParticipantsMutation,
  useSendCollaborationRequestToDataPartnerMutation,
  useSendCollaborationRequestToPublisherMutation,
} from "@decentriq/graphql/dist/hooks";
import { MyDataRoomsDocument } from "@decentriq/graphql/dist/types";
import { Dialog } from "@mui/material";
import { useSafeState } from "ahooks";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { MediaDataRoomCreateProgress } from "features/mediaDataRoom";
import {
  MediaInsightsDcrCreateWizard,
  MediaInsightsDcrCreateWizardWrapper,
  MediaInsightsDcrCreationStep,
  type PublishMediaInsightsDcrInput,
  useCreationWizardStepper,
} from "features/MediaInsightsDcr";
import {
  CommonSnackbarOrigin,
  useGeneralSnackbar,
  useOrganizationPreferences,
} from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";
import { delay, getEffectiveErrorMessage, noSoonerThan } from "utils";
import usePublishMediaInsightsDcr from "./usePublishMediaInsightsDcr";
import useValidateMediaDataRoom from "./useValidateMediaDataRoom";

interface MediaInsightsDcrCreateDialogWrapperProps {
  open: boolean;
  onCancel: () => void;
  restartCreation: () => void;
}

const MediaInsightsDcrCreateDialogWrapper: React.FC<MediaInsightsDcrCreateDialogWrapperProps> =
  memo(({ open, onCancel, restartCreation }) => {
    const apolloClient = useApolloClient();
    const { enqueueSnackbar } = useGeneralSnackbar({
      origin: CommonSnackbarOrigin.DASHBOARD,
    });
    const navigate = useNavigate();
    const [publishing, setPublishing] = useSafeState(false);
    const [publishingCalled, setPublishingCalled] = useSafeState(false);
    const [publishingError, setPublishingError] = useSafeState<
      unknown | undefined
    >();
    const [mediaInviteParticipants] =
      useMediaInsightsInviteParticipantsMutation({
        onError: (error) => {
          enqueueSnackbar(
            "Data clean room participants could not be invited.",
            {
              context: error?.message,
              persist: true,
              variant: "error",
            }
          );
        },
      });
    const [sendCollaborationRequestToPublisherMutation] =
      useSendCollaborationRequestToPublisherMutation();
    const [sendCollaborationRequestToDataPartnerMutation] =
      useSendCollaborationRequestToDataPartnerMutation();
    const sendCollaborationRequest = useCallback(
      async ({
        requestRecipientId,
        message,
        receiver,
      }: {
        requestRecipientId: string;
        message: string;
        receiver: "publisher" | "dataPartner";
      }) => {
        const mutation =
          receiver === "publisher"
            ? sendCollaborationRequestToPublisherMutation
            : sendCollaborationRequestToDataPartnerMutation;
        await mutation({
          onCompleted: () => {
            enqueueSnackbar("Contact request sent");
            onCancel();
          },
          onError: (error) => {
            enqueueSnackbar("Contact request could not be sent", {
              context: error?.message,
              persist: true,
              variant: "error",
            });
          },
          variables: {
            message,
            requestRecipientId,
          },
        });
      },
      [
        enqueueSnackbar,
        onCancel,
        sendCollaborationRequestToPublisherMutation,
        sendCollaborationRequestToDataPartnerMutation,
      ]
    );
    const { publish: publishMediaInsightsDcr } = usePublishMediaInsightsDcr();
    const { validateMediaInsightsDcr } = useValidateMediaDataRoom();
    const submit = useCallback(
      async (mediaDataRoomInput: PublishMediaInsightsDcrInput) => {
        const isMediaDataRoomValid =
          validateMediaInsightsDcr(mediaDataRoomInput);
        if (!isMediaDataRoomValid) {
          return;
        }
        setPublishing(true);
        setPublishingCalled(true);
        setPublishingError(undefined);
        try {
          await delay(1000);
          const { id: dataRoomId, driverAttestationHash } = await noSoonerThan(
            1000,
            publishMediaInsightsDcr(mediaDataRoomInput)
          );
          await noSoonerThan(
            1000,
            mediaInviteParticipants({
              variables: {
                input: {
                  dataRoomDescription: "",
                  publishedDataRoomDriverAttestationHash: driverAttestationHash,
                  publishedDataRoomEnclaveId: dataRoomId,
                },
              },
            })
          );
          await delay(1000);
          onCancel();
          await apolloClient.query({
            errorPolicy: "ignore",
            fetchPolicy: "network-only",
            query: MyDataRoomsDocument,
          });
          navigate(
            dataRoomPathBuilder(
              dataRoomId,
              DataRoomTypeNames.PublishedMediaInsightsDcr
            )
          );
        } catch (error) {
          enqueueSnackbar(`Data clean room could not be published.`, {
            context: getEffectiveErrorMessage(error),
            persist: true,
            variant: "error",
          });
          setPublishingError(error);
        } finally {
          setPublishing(false);
        }
      },
      [
        validateMediaInsightsDcr,
        publishMediaInsightsDcr,
        enqueueSnackbar,
        mediaInviteParticipants,
        onCancel,
        setPublishing,
        setPublishingCalled,
        setPublishingError,
        navigate,
        apolloClient,
      ]
    );
    if (!open) {
      return null;
    }
    return (
      <MediaInsightsDcrCreateWizardWrapper
        restartCreation={restartCreation}
        sendCollaborationRequest={sendCollaborationRequest}
        submit={submit}
      >
        <MediaInsightsDcrCreateDialog onCancel={onCancel} open={open}>
          {publishing ? (
            <MediaDataRoomCreateProgress
              publishing={publishing}
              publishingCalled={publishingCalled}
              publishingError={publishingError}
            />
          ) : (
            <MediaInsightsDcrCreateWizard />
          )}
        </MediaInsightsDcrCreateDialog>
      </MediaInsightsDcrCreateWizardWrapper>
    );
  });

type CreateDialogProps = React.PropsWithChildren<{
  open: boolean;
  onCancel: () => void;
}>;

const MediaInsightsDcrCreateDialog = memo<CreateDialogProps>(
  ({ open, onCancel, children }) => {
    const { numberOfSupportedMediaRoles } = useOrganizationPreferences();
    const { activeStep } = useCreationWizardStepper();
    return (
      <Dialog
        fullWidth={true}
        maxWidth={
          (activeStep ===
            MediaInsightsDcrCreationStep.SELECT_ORGANIZATION_ROLE &&
            numberOfSupportedMediaRoles <= 2) ||
          activeStep === MediaInsightsDcrCreationStep.SELECT_DATA_SOURCE
            ? "md"
            : "lg"
        }
        onClose={onCancel}
        open={open}
        scroll="paper"
      >
        {children}
      </Dialog>
    );
  }
);

export default MediaInsightsDcrCreateDialogWrapper;
