import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  type InsightsBasicViewFormValues,
  MediaDataRoomInsightsBasicView,
  MediaDataRoomInsightsComparison,
  MediaDataRoomInsightsDetailedView,
  MediaDataRoomInsightsExplorer,
  useMediaDataRoomOverlapInsightsContext,
  useMediaInsightsDcrData,
} from "features/MediaInsightsDcr";
import { useTabPanelStyles } from "styles";

enum InsightsTabs {
  Basic = "Basic",
  Detailed = "Detailed",
  Comparison = "Comparison",
  Explorer = "Explorer",
}

const MediaDataRoomInsightsTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<InsightsTabs>(InsightsTabs.Basic);

  const { classes: tabPanelClasses } = useTabPanelStyles();

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: InsightsTabs) =>
      setActiveTab(newValue),
    [setActiveTab]
  );

  const { audienceType } = useMediaDataRoomOverlapInsightsContext();
  const mediaDataRoomInsightsBasicViewForm =
    useForm<InsightsBasicViewFormValues>({
      defaultValues: {
        audienceType: audienceType || "",
        genderChartValueKey: "shareInOverlap",
      },
      mode: "onChange",
    });
  const { publisherDatasetsHashes: { demographicsDatasetHash } = {} } =
    useMediaInsightsDcrData();
  return (
    <TabContext value={activeTab}>
      <Box>
        <TabList
          onChange={handleTabChange}
          style={{ marginTop: 0, minHeight: "auto" }}
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            label="BASIC"
            sx={{ minHeight: 3, padding: ({ spacing }) => spacing(1, 4) }}
            value={InsightsTabs.Basic}
          />
          {demographicsDatasetHash && (
            <Tab
              label="DETAILED"
              sx={{ minHeight: 3, padding: ({ spacing }) => spacing(1, 4) }}
              value={InsightsTabs.Detailed}
            />
          )}
          <Tab
            label="COMPARISON"
            sx={{ minHeight: 3, padding: ({ spacing }) => spacing(1, 4) }}
            value={InsightsTabs.Comparison}
          />
          <Tab
            label="EXPLORER"
            sx={{ minHeight: 3, padding: ({ spacing }) => spacing(1, 4) }}
            value={InsightsTabs.Explorer}
          />
        </TabList>
      </Box>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <TabPanel classes={tabPanelClasses} value={InsightsTabs.Basic}>
          <Box flexShrink={0} minHeight="100%" sx={{ background: "#fff" }}>
            {/* This is temp fix, form provider should be inside of the basic view. Issue is in audienceType being not updated inside overlap statistics */}
            <FormProvider {...mediaDataRoomInsightsBasicViewForm}>
              <MediaDataRoomInsightsBasicView />
            </FormProvider>
          </Box>
        </TabPanel>
        {demographicsDatasetHash && (
          <TabPanel classes={tabPanelClasses} value={InsightsTabs.Detailed}>
            <Box flex={1} minHeight="100%" sx={{ background: "#fff" }}>
              <MediaDataRoomInsightsDetailedView />
            </Box>
          </TabPanel>
        )}
        <TabPanel classes={tabPanelClasses} value={InsightsTabs.Comparison}>
          <Box
            flexShrink={0}
            minHeight="100%"
            sx={{ background: "#fff", flex: 1, overflow: "hidden" }}
          >
            <MediaDataRoomInsightsComparison />
          </Box>
        </TabPanel>
        <TabPanel classes={tabPanelClasses} value={InsightsTabs.Explorer}>
          <Box
            flexShrink={0}
            height="100%"
            minHeight="100%"
            sx={{ background: "#fff" }}
          >
            <MediaDataRoomInsightsExplorer />
          </Box>
        </TabPanel>
      </div>
    </TabContext>
  );
};

MediaDataRoomInsightsTabs.displayName = "MediaDataRoomInsightsTabs";

export default memo(MediaDataRoomInsightsTabs);
