import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { useDeleteDataset } from "features/datasets";

export type DataNodeChangeOutcomeType = "deleted" | "changed" | "columnAdded";

interface DataNodeWithTestDataChangedDialogProps {
  dataNodeId: string;
  manifestHash: string;
  dataNodeName: string;
  actionType: DataNodeChangeOutcomeType;
  onCancel: () => void;
  open: boolean;
  columnToDeleteId?: string;
}

const DataNodeWithTestDataChangedDialog: React.FC<
  DataNodeWithTestDataChangedDialogProps
> = ({
  dataNodeId,
  manifestHash,
  dataNodeName,
  open,
  onCancel,
  actionType,
  columnToDeleteId,
}) => {
  const { handleTableColumnDelete } = useDataNodeActions();
  const [columnDeleting, setColumnDeleting] = useState<boolean>(false);
  const [deleteDataset, deleting] = useDeleteDataset({
    manifestHash,
    testing: { dataNodeId },
  });
  const handleDeleteColumn = useCallback(async () => {
    if (!columnToDeleteId) {
      return;
    }
    try {
      setColumnDeleting(true);
      await handleTableColumnDelete(columnToDeleteId);
      onCancel();
    } finally {
      setColumnDeleting(false);
    }
  }, [setColumnDeleting, handleTableColumnDelete, columnToDeleteId, onCancel]);
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} role="delete-dialog">
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>
            {actionType === "changed" && (
              <Typography textAlign="center">
                The table schema for <strong>{dataNodeName}</strong> has
                changed.
                <br />
                Would you like to delete the provisioned test data?
              </Typography>
            )}
            {actionType === "deleted" && (
              <Typography textAlign="center">
                The table <strong>{dataNodeName}</strong> was deleted.
                <br />
                Would you like to delete the provisioned test data too?
              </Typography>
            )}
          </strong>
        </Typography>
      </DialogTitle>
      {actionType === "columnAdded" && (
        <DialogContent>
          <Typography
            align="center"
            sx={{ textWrap: "balance" }}
            variant="body2"
          >
            A new column has been added to the <strong>{dataNodeName}</strong>,
            and since a test dataset is provisioned, it will have fewer columns
            than the table schema.
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        {actionType === "columnAdded" ? (
          <LoadingButton
            color="inherit"
            loading={columnDeleting}
            onClick={handleDeleteColumn}
          >
            Undo column creation
          </LoadingButton>
        ) : (
          <Button color="inherit" disabled={deleting} onClick={onCancel}>
            No
          </Button>
        )}
        <LoadingButton
          color="error"
          loading={deleting}
          onClick={() =>
            deleteDataset().then((deleted) => {
              if (deleted) {
                onCancel();
              }
            })
          }
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DataNodeWithTestDataChangedDialog.displayName =
  "DataNodeWithTestDataChangedDialog";

export default memo(DataNodeWithTestDataChangedDialog);
