import {
  ColumnDataType,
  TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { upperFirst } from "lodash";
import { type DataRoomTableColumn } from "models";

export const getAdvertiserAudienceColumns: (
  matchingIdFormat: TableColumnFormatType,
  hashMatchingIdWith: TableColumnHashingAlgorithm | null
) => DataRoomTableColumn[] = (matchingIdFormat, hashMatchingIdWith) => [
  {
    formatType: matchingIdFormat,
    hashWith: hashMatchingIdWith ?? undefined,
    id: "matchingId",
    name: "matchingId",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
  {
    default: "All customers",
    formatType: TableColumnFormatType.String,
    id: "audienceType",
    name: "audienceType",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
];

export interface Audience {
  id: string;
  audienceType: string;
  published: boolean;
  reach: number;
}

export type AudienceOverlapStatistics = {
  type: string;
  overlap: number;
  total: number;
  notMatching?: number;
};

export type AudienceOverlapStatisticsRaw = {
  advertiserSize: number;
  audienceType: string;
  overlapSize: number;
};

export enum OverlapInsightsVisualizeDirection {
  top = "top",
  bottom = "bottom",
}

export type OverlapInsightsChartTypes = "RADAR" | "LINE" | "TAG_CLOUD";

const interestAggregationKey = "interest";

export const getAggregationPresentation = (aggregationName: string): string =>
  aggregationName === interestAggregationKey
    ? "Segment"
    : upperFirst(aggregationName);

export const getOverlapColumnPresentation = new Map<string, string>([
  ["affinity_ratio", "Affinity"],
  ["share_in_overlap", "Share in overlap"],
  ["share_in_addressable_audience", "Share in publisher audience"],
  ["addressable_audience_size", "Addressable users in publisher audience"],
  ["count_users_overlap", "Count in overlap"],
]);

export interface AggregationData {
  addressableAudienceSize: number;
  affinityRatio: number;
  age?: string;
  aggregationName?: string;
  countUsersOverlap: number;
  interest?: string;
  gender?: string;
  shareInAddressableAudience: number;
  shareInOverlap: number;
}

export interface PublisherDatasetsHashes {
  usersDatasetHash: string | null;
  segmentsDatasetHash: string | null;
  demographicsDatasetHash: string | null;
  embeddingsDatasetHash: string | null;
}

export enum LookalikeMediaDataRoomSupportedFeatures {
  ENABLE_MODEL_PERFORMANCE_EVALUATION = "ENABLE_MODEL_PERFORMANCE_EVALUATION",
}
