import { faEnvelopeOpenText } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  type Theme,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "tss-react/mui";

const useChangePasswordDialogStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: grey["400"],
  },
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  title: {
    lineHeight: 1.25,
  },
}));

interface ChangePasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({
  open,
  onClose,
}) => {
  const { classes: changePasswordDialogClasses } =
    useChangePasswordDialogStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <Box className={changePasswordDialogClasses.iconTitleWrapper}>
        <Box className={changePasswordDialogClasses.iconWrapper}>
          <FontAwesomeIcon
            className={changePasswordDialogClasses.icon}
            fixedWidth={true}
            icon={faEnvelopeOpenText}
            size="5x"
          />
        </Box>
        <Typography
          align="center"
          className={changePasswordDialogClasses.title}
          color="textPrimary"
          variant="h6"
        >
          <strong>Please check your email</strong>
        </Typography>
      </Box>
      <DialogContent>
        <Typography align="center" component="div" variant="body2">
          Please check your email address for further
          <br />
          instructions on how to reset your password.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
