import {
  ColumnDataType,
  TableColumnFormatType,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FilledInput,
  FormHelperText,
  List,
  styled,
  Typography,
} from "@mui/material";
import {
  type ChangeEventHandler,
  Fragment,
  type KeyboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { type DataRoomTableColumn } from "models";
import { isValidIdentifier } from "utils/validation";
import { useDataNodeConstructorParams } from "./DataNodeConstructorParamsWrapper";
import TableNodeColumnEditableTile from "./TableNodeColumnEditableTile";
import TableNodeColumnTile from "./TableNodeColumnTile";

const StyledList = styled(List)({
  "& .MuiListItem-root:nth-of-type(even)": {
    backgroundColor: "whitesmoke",
  },
});

interface TableNodeColumnConstructorProps {
  tableNodeId: string;
  columns: DataRoomTableColumn[];
  columnsOrder: string[];
  isLoading: boolean;
  onChangeOutcome?: (columnAdded?: boolean, columnId?: string) => void;
}

export const TableNodeColumnConstructor: React.FC<TableNodeColumnConstructorProps> =
  memo(({ tableNodeId, columns, columnsOrder, isLoading, onChangeOutcome }) => {
    const { readOnly } = useDataNodeConstructorParams();
    const { handleTableColumnCreate, handleTableColumnsOrderUpdate } =
      useDataNodeActions();
    const [items, setItems] = useState(columnsOrder);
    useEffect(() => setItems(columnsOrder), [columnsOrder]);
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
    const onDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        setItems((items) => {
          const oldIndex = items.indexOf(active.id.toString());
          const newIndex = items.indexOf(over!.id.toString());
          const reorderedItems = arrayMove(items, oldIndex, newIndex);
          handleTableColumnsOrderUpdate({
            columnsOrder: reorderedItems,
            id: tableNodeId,
          }).then(() => onChangeOutcome?.());
          return reorderedItems;
        });
      }
    };
    const [value, setValue] = useState("");
    const error = useMemo(() => {
      return value.trim().length > 0
        ? !isValidIdentifier(value)
          ? "Identifiers should begin with a letter, not end in an underscore, and should contain only alphanumeric characters or spaces"
          : columns.some(({ name }) => name === value)
            ? "Column name must be unique"
            : undefined
        : undefined;
    }, [columns, value]);
    const onChange = useCallback<
      ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    >((event) => {
      setValue(event.target.value);
    }, []);
    const onKeyDown = useCallback<
      KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
    >(
      (event) => {
        if (event.key === "Enter" && value.length > 0 && !error) {
          handleTableColumnCreate(tableNodeId, {
            formatType: TableColumnFormatType.String,
            name: value,
            nullable: false,
            primitiveType: ColumnDataType.Text,
          }).then((response) =>
            onChangeOutcome?.(
              true,
              response?.data?.draftTableLeafNode.addColumn.id
            )
          );
          setValue("");
        }
      },
      [error, handleTableColumnCreate, onChangeOutcome, tableNodeId, value]
    );
    if (columns.length === 0 && readOnly) {
      return (
        <Typography sx={{ mt: 1, pl: 0.5 }} variant="body2">
          This table has no columns
        </Typography>
      );
    }
    return (
      <>
        {readOnly ? (
          columns.map((column, index) => (
            <TableNodeColumnTile column={column} key={index} />
          ))
        ) : (
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToParentElement, restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
            sensors={sensors}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <StyledList style={{ overflow: "auto", padding: 0 }}>
                {items.map((id) => {
                  const index = columns.findIndex((c) => c.id === id);
                  const {
                    name = "",
                    primitiveType = ColumnDataType.Text,
                    nullable = true,
                    formatType,
                    hashWith,
                  } = columns[index] || {};
                  return (
                    <TableNodeColumnEditableTile
                      canDelete={items.length > 1}
                      disabled={isLoading}
                      formatType={formatType}
                      hashWith={hashWith}
                      id={id}
                      key={id}
                      name={name}
                      nullable={nullable}
                      onOutcomeDialogOpen={() => onChangeOutcome?.()}
                      primitiveType={primitiveType}
                      validate={(value: string) => {
                        return !isValidIdentifier(value)
                          ? "Identifiers can't include single-quotes, double-quotes and start and/or end with a whitespace"
                          : columns.some(({ name }) => name === value)
                            ? "Column name must be unique"
                            : null;
                      }}
                    />
                  );
                })}
              </StyledList>
            </SortableContext>
          </DndContext>
        )}
        {!readOnly ? (
          <Fragment>
            <FilledInput
              autoFocus={true}
              data-testid={testIds.dataNode.tableColumnConstructor.tableColumn}
              disableUnderline={true}
              error={Boolean(error)}
              fullWidth={true}
              hiddenLabel={true}
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder="Add new column"
              startAdornment={
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faPlusRegular}
                  style={{ marginRight: 4, opacity: 0.5 }}
                />
              }
              sx={{
                "& .MuiFilledInput-input": {
                  height: "1.5rem",
                  lineHeight: "1.5rem",
                  pb: 0.5,
                  pt: 0.5,
                },
                pl: 0.5,
                pr: 0.5,
              }}
              value={value}
            />
            {error ? (
              <FormHelperText error={true}>{error}</FormHelperText>
            ) : null}
          </Fragment>
        ) : null}
      </>
    );
  });
