import {
  faCircleCheck as faCircleCheckDuotone,
  faCircleXmark as faCircleXmarkDuotone,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faCircle1 as faCircle1Light,
  faCircle2 as faCircle2Light,
  faCircleXmark as faCircleXmarkLight,
} from "@fortawesome/pro-light-svg-icons";
import { faCircleCheck as faCircleCheckSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { memo } from "react";

interface MediaDataRoomUploadDataProgressProps {
  [key: string]: any;
}

const MediaDataRoomUploadDataProgress: React.FC<MediaDataRoomUploadDataProgressProps> =
  memo(
    ({
      loading,
      uploadMediaDatasetCalled,
      uploadMediaDatasetLoading,
      uploadMediaDatasetError,
      publishMediaDatasetCalled,
      publishMediaDatasetLoading,
      publishMediaDatasetError,
    }) => {
      return (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 8,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            {loading ? (
              <CircularProgress color="inherit" size={64} thickness={1} />
            ) : [uploadMediaDatasetError, publishMediaDatasetError].filter(
                Boolean
              ).length === 0 ? (
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faCircleCheckDuotone}
                size="4x"
              />
            ) : (
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faCircleXmarkDuotone}
                size="4x"
              />
            )}
          </Box>
          <Grid
            columnSpacing={1}
            container={true}
            rowSpacing={0}
            sx={{ mb: 0.5 }}
          >
            <Grid
              alignItems="center"
              display="flex"
              justifyContent="center"
              xs="auto"
            >
              {!uploadMediaDatasetCalled ? (
                <FontAwesomeIcon fixedWidth={true} icon={faCircle1Light} />
              ) : uploadMediaDatasetLoading ? (
                <CircularProgress color="inherit" size={16} thickness={3} />
              ) : uploadMediaDatasetError ? (
                <FontAwesomeIcon fixedWidth={true} icon={faCircleXmarkLight} />
              ) : (
                <FontAwesomeIcon fixedWidth={true} icon={faCircleCheckSolid} />
              )}
            </Grid>
            <Grid alignItems="center" display="flex" xs={true}>
              <Typography
                component="div"
                sx={{
                  lineHeight: "1.5rem",
                  transition: "all 0.2s linear",
                  whiteSpace: "nowrap",
                }}
              >
                {uploadMediaDatasetLoading
                  ? "Encrypting and uploading media dataset…"
                  : uploadMediaDatasetCalled
                    ? !uploadMediaDatasetError
                      ? "Media dataset was encrypted and uploaded"
                      : "Can't upload media dataset"
                    : "Encrypt and upload media dataset"}
              </Typography>
            </Grid>
          </Grid>
          <Grid columnSpacing={1} container={true} rowSpacing={0}>
            <Grid
              alignItems="center"
              display="flex"
              justifyContent="center"
              xs="auto"
            >
              {!publishMediaDatasetCalled ? (
                <FontAwesomeIcon fixedWidth={true} icon={faCircle2Light} />
              ) : publishMediaDatasetLoading ? (
                <CircularProgress color="inherit" size={16} thickness={3} />
              ) : publishMediaDatasetError ? (
                <FontAwesomeIcon fixedWidth={true} icon={faCircleXmarkLight} />
              ) : (
                <FontAwesomeIcon fixedWidth={true} icon={faCircleCheckSolid} />
              )}
            </Grid>
            <Grid alignItems="center" display="flex" xs={true}>
              <Typography
                component="div"
                sx={{
                  lineHeight: "1.5rem",
                  transition: "all 0.2s linear",
                  whiteSpace: "nowrap",
                }}
              >
                {publishMediaDatasetLoading
                  ? "Publishing media dataset…"
                  : publishMediaDatasetCalled
                    ? !publishMediaDatasetError
                      ? "Media dataset was published"
                      : "Can't publish media dataset"
                    : "Publish media dataset"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );
    }
  );

export default MediaDataRoomUploadDataProgress;
