import {
  useUpdateS3ConnectionParamsMutation,
  useUpdateS3CredentialsDependencyMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDebounceFn } from "ahooks";
import { memo, useCallback } from "react";
import {
  mapDraftDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useNodes,
} from "hooks";
import { DataNodeTypeNames } from "models";
import useS3ConnectionParams from "./useS3NodeConnection";

interface ConnectionParamsFormProps {
  computeNodeId: string;
  readOnly?: boolean;
}

const ConnectionParamsForm: React.FC<ConnectionParamsFormProps> = memo(
  ({ computeNodeId, readOnly = false }) => {
    const { node, credentialsDependency } =
      useS3ConnectionParams(computeNodeId);
    const s3EndpointUrl = node?.endpoint;
    const s3CredentialsFile = credentialsDependency?.id;
    const s3Region = node?.region;
    const { nodes } = useNodes();
    const fileDataNodes =
      nodes
        .filter(
          ({ __typename }) =>
            __typename === DataNodeTypeNames.DraftRawLeafNode ||
            __typename === DataNodeTypeNames.PublishedRawLeafNode
        )
        .map(({ id, name }: any) => ({
          id,
          label: name,
        })) || [];
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [updateS3ConnectionParamsMutation] =
      useUpdateS3ConnectionParamsMutation();
    const [updateS3CredentialsDependencyMutation] =
      useUpdateS3CredentialsDependencyMutation();
    const updateS3CredentialsDependency = useCallback(
      async (dependencyId: string) => {
        try {
          return updateS3CredentialsDependencyMutation({
            variables: {
              computeNodeId,
              dependencyId: { draft: dependencyId },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "S3 settings could not be updated"
            )
          );
          throw error;
        }
      },
      [enqueueSnackbar, computeNodeId, updateS3CredentialsDependencyMutation]
    );
    const updateS3ConnectionParams = useCallback(
      async (data?: object) => {
        try {
          return updateS3ConnectionParamsMutation({
            variables: {
              input: {
                id: computeNodeId,
                ...data,
              },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "S3 settings could not be updated."
            )
          );
          throw error;
        }
      },
      [enqueueSnackbar, computeNodeId, updateS3ConnectionParamsMutation]
    );
    const { run: debouncedUpdateS3ConnectionParams } = useDebounceFn(
      updateS3ConnectionParams,
      { wait: 750 }
    );
    return (
      <Grid
        alignItems="center"
        container={true}
        justifyContent="center"
        spacing={3}
      >
        <Grid item={true} md={4} xs={4}>
          <FormControl fullWidth={true}>
            <FormLabel component="legend">Credentials file</FormLabel>
            <Select
              defaultValue={s3CredentialsFile}
              disabled={readOnly}
              displayEmpty={true}
              onChange={({ target }) => {
                updateS3CredentialsDependency(target.value);
              }}
              renderValue={(value) => {
                return (
                  fileDataNodes.find(({ id }) => id === value)?.label ||
                  "Choose credentials file..."
                );
              }}
              size="small"
              style={{ background: "transparent" }}
              variant="standard"
            >
              {fileDataNodes.map(({ id, label }: any) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item={true} md={5} xs={5}>
          <FormControl fullWidth={true}>
            <FormLabel component="legend">Endpoint URL</FormLabel>
            <TextField
              InputProps={{
                sx: {
                  "& .MuiInput-input": { padding: "7px 0" },
                  "&:before": { borderBottomStyle: "solid" },
                },
              }}
              defaultValue={s3EndpointUrl}
              disabled={readOnly}
              onChange={({ target }) => {
                debouncedUpdateS3ConnectionParams({
                  endpoint: target.value,
                });
              }}
              placeholder="Endpoint URL"
              size="small"
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item={true} md={3} xs={3}>
          <FormControl fullWidth={true}>
            <FormLabel component="legend">Region</FormLabel>
            <TextField
              InputProps={{
                sx: {
                  "& .MuiInput-input": { padding: "7px 0" },
                  "&:before": { borderBottomStyle: "solid" },
                },
              }}
              defaultValue={s3Region}
              disabled={readOnly}
              onChange={({ target }) => {
                debouncedUpdateS3ConnectionParams({
                  region: target.value,
                });
              }}
              placeholder="Region"
              size="small"
              variant="standard"
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
);

ConnectionParamsForm.displayName = "ConnectionParamsForm";

export default ConnectionParamsForm;
