import {
  useDataConnectorJobQuery,
  useDataConnectorJobResultQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJobResult,
  DataImportExportStatus,
} from "@decentriq/graphql/dist/types";

type DatasetImportResultHookReturn = {
  status: DataImportExportStatus | undefined;
  result: DataConnectorJobResult | undefined | null;
};

// Hook that encapsulates both useDatasetImportQuery's status for rendering status icons on the last step
// and useDatasetImportResultQuery from enclave to render error text
const useDatasetImportResult = (
  datasetImportId: string
): DatasetImportResultHookReturn => {
  // Returns DataImportExportStatus
  const { data: datasetImportData } = useDataConnectorJobQuery({
    skip: !datasetImportId,
    variables: { id: datasetImportId as string },
  });
  const {
    dataConnectorJob: {
      status: datasetImportStatus = DataImportExportStatus.Pending,
    } = {},
  } = datasetImportData || {};

  // Returns result state from Enclave
  const { data } = useDataConnectorJobResultQuery({
    variables: { id: datasetImportId },
  });

  const result = data?.dataConnectorJob?.result;

  return {
    result,
    status: datasetImportStatus,
  };
};

export default useDatasetImportResult;
