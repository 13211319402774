import {
  faCaretRight as faCaretRightSolid,
  faLightbulb,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { grey, yellow } from "@mui/material/colors";
import { memo } from "react";
import { useDataLabContext } from "features/dataLabs";
import {
  DemographicDistributionCharts,
  SegmentDistributionChart,
  SegmentPerUserDistributionChart,
} from "./components";

const DataLabStatisticsCharts: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();

  const { segmentsDataset, demographicsDataset, statistics } = dataLab || {};
  const {
    share_of_users_per_segment_distribution: shareOfUsersPerSegmentDistribution,
    segments_per_user_distributions: segmentsPerUserDistributions,
    demographics_distributions: demographicsDistributions,
  } = statistics || {};

  return (
    <Box
      sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      {segmentsDataset &&
        (shareOfUsersPerSegmentDistribution ||
          segmentsPerUserDistributions) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: 1,
              width: "100%",
            }}
          >
            <Typography
              alignSelf="flex-start"
              sx={{ marginBottom: 1 }}
              variant="body1"
            >
              Segments
            </Typography>
            <Accordion
              elevation={0}
              sx={{
                "&.MuiPaper-root": { background: grey[50] },
                width: "100%",
              }}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faCaretRightSolid} />}
                sx={{
                  "& .MuiAccordionSummary-content": {
                    justifyContent: "center",
                  },
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                  flexDirection: "row-reverse",
                }}
              >
                <Box sx={{ alignItems: "center", display: "flex" }}>
                  <FontAwesomeIcon
                    color={yellow[700]}
                    fixedWidth={true}
                    icon={faLightbulb}
                  />
                  <Typography ml={1} variant="subtitle2">
                    Quality guidance
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 1, width: "75%" }}>
                <span>
                  Ideally, there should be between 30-100 distinct segments and
                  the segment names should be comprehensible to humans.
                </span>
                <p>
                  Ideally, each segment should be roughly the same size and not
                  include too many nor too few users. A good share of users per
                  segment is between 2-15% for each segment in the "Share of
                  users per segment" chart.
                </p>
                <p>
                  Ideally, users are in multiple but not too many segments. A
                  good number is between 3-15 in the Share of users per Number
                  of Segments chart.
                </p>
                <span>
                  Ideally, "All users" and "Users with matching ID" have similar
                  distributions in the "Share of users per number of segments"
                  chart.
                </span>
              </AccordionDetails>
            </Accordion>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {shareOfUsersPerSegmentDistribution && (
                <SegmentDistributionChart />
              )}
              {segmentsPerUserDistributions && (
                <SegmentPerUserDistributionChart />
              )}
            </Box>
          </Box>
        )}
      {demographicsDataset && demographicsDistributions && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 1,
            width: "100%",
          }}
        >
          <Typography
            alignSelf="flex-start"
            sx={{ marginBottom: 1 }}
            variant="body1"
          >
            Demographics
          </Typography>
          <Accordion
            elevation={0}
            sx={{ "&.MuiPaper-root": { background: grey[50] }, width: "100%" }}
          >
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faCaretRightSolid} />}
              sx={{
                "& .MuiAccordionSummary-content": {
                  justifyContent: "center",
                },
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(90deg)",
                },
                flexDirection: "row-reverse",
              }}
            >
              <Box sx={{ alignItems: "center", display: "flex" }}>
                <FontAwesomeIcon
                  color={yellow[700]}
                  fixedWidth={true}
                  icon={faLightbulb}
                />
                <Typography ml={1} variant="subtitle2">
                  Quality guidance
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 1, width: "75%" }}>
              <span>
                Ideally, for good interpretability in the insights of the Media
                DCR, the number of age groups should be between 4-10 and the
                number of gender groups should be less than 5.
              </span>
              <p>
                Ideally, there are &lt;10% of users without any demographics
                information.
              </p>
              <p>
                Ideally, the distributions between "All users" and "Users with a
                matching ID" should be similar.
              </p>
              <span>
                Ideally, the demographics distribution should be similar to what
                you and your advertiser expect from your audience.
              </span>
            </AccordionDetails>
          </Accordion>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <DemographicDistributionCharts />
          </Box>
        </Box>
      )}
    </Box>
  );
};

DataLabStatisticsCharts.displayName = "DataLabStatisticsCharts";

export default memo(DataLabStatisticsCharts);
