import { type Key } from "@decentriq/utils";
import { useCallback } from "react";
import useCommonDataNodeActions from "features/dataNodes/hooks/useCommonDataNodeActions";
import { useLookalikeMediaDataRoomState } from "features/mediaDataRoomV2/contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

interface AdvertiserDataNodeHookPayload {
  driverAttestationHash: string;
  retrieveDatasets: () => Promise<void>;
}

const useAdvertiserDataNodeActions = ({
  retrieveDatasets,
}: AdvertiserDataNodeHookPayload) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { publishAdvertiserDataset, unpublishAdvertiserDataset } =
    useLookalikeMediaDataRoomState();
  const onDatasetDeprovision = useCallback(
    async ({ dataNodeId }: { dataNodeId: string }) => {
      await unpublishAdvertiserDataset()
        .then(() => {
          enqueueSnackbar("Your data has been deprovisioned.");
        })
        .catch((error) => {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(error, `Can't deprovision data`)
          );
        });
      // TODO make sure cahce does the retrieval
      await retrieveDatasets();
    },
    [unpublishAdvertiserDataset, retrieveDatasets, enqueueSnackbar]
  );
  const onPublishAdvertiserDataset = useCallback(
    async ({ key, manifestHash }: { key: Key; manifestHash: string }) => {
      await publishAdvertiserDataset({
        dataset: {
          datasetHash: manifestHash,
          encryptionKey: key,
        },
      });
      await retrieveDatasets();
    },
    [publishAdvertiserDataset, retrieveDatasets]
  );
  const {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  } = useCommonDataNodeActions({
    onAfterIngestData: onPublishAdvertiserDataset,
    onConnectDataset: onPublishAdvertiserDataset,
    onDatasetDeprovision,
  });
  return {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  };
};

export default useAdvertiserDataNodeActions;
