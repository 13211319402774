import { type Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useAccordionSummaryReversedStyles = makeStyles()(
  (theme: Theme) => ({
    root: {
      "& .MuiAccordionSummary-expandIconWrapper": {
        margin: 0,
        marginRight: ".5rem",
      },
      flexDirection: "row-reverse",
      marginBottom: ".5rem",
    },
  })
);
