import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import saveAs from "file-saver";
import { useEffect } from "react";
import { useApiCore, usePublishedLookalikeMediaDataRoom } from "contexts";
import { useLookalikeModelPerformanceReportGeneration } from "features/mediaDataRoomV2/hooks";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

interface PerformanceReportDownloadDialogProps {
  open: boolean;
  onCancel: () => void;
}

const PerformanceReportDownloadDialog: React.FC<
  PerformanceReportDownloadDialogProps
> = ({ open, onCancel }) => {
  const { store } = useApiCore();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId, driverAttestationHash } =
    usePublishedLookalikeMediaDataRoom();
  const { mutateAsync: generatePerformanceReport } =
    useLookalikeModelPerformanceReportGeneration({
      dataRoomId,
      driverAttestationHash,
    });
  useEffect(() => {
    if (open) {
      generatePerformanceReport()
        .then((report) => {
          if (!report) {
            throw Error("Performance report is empty");
          }
          const file = new File(
            [JSON.stringify(report, null, 2)],
            `Performance_report_${new Date().toISOString()}.json`,
            {
              type: "application/octet-stream;charset=utf-8",
            }
          );
          if (!open) {
            return;
          }
          saveAs(file!);
          onCancel();
        })
        .catch((error) => {
          if (!open) {
            return;
          }
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Unable to fetch performance report"
            )
          );
          onCancel();
        });
    }
  }, [open, generatePerformanceReport, onCancel, enqueueSnackbar, store]);
  return (
    <Dialog fullWidth={true} onClose={onCancel} open={open}>
      <DialogTitle>Preparing performance report</DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress color="inherit" size={32} />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PerformanceReportDownloadDialog.displayName = "PerformanceReportDownloadDialog";

export default PerformanceReportDownloadDialog;
