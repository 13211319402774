import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserKeychainStatus } from "services/keychain";
import { Loading } from "components";
import { useKeychainService } from "contexts";
import {
  ResetKeychainPassword,
  SetupKeychain,
  UnlockKeychain,
} from "features/keychain";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import KeychainError from "./KeychainError";

export enum KeychainStatus {
  initial,
  loading,
  unset,
  locked,
  unlocked,
  error,
  unknown,
}

interface KeychainContextValue {
  status: KeychainStatus;
  errorMessage: string | undefined;
  checkKeychainStatus: () => void;
  unlockKeychain: (password: string) => Promise<void>;
  createKeychain: (password: string) => Promise<void>;
  resetKeychain: () => Promise<void>;
  emptyKeychain: (password: string) => Promise<boolean>;
  changeKeychainPassword: (
    oldPassword: string,
    newPassword: string
  ) => Promise<boolean>;
}

export const KeychainContext = createContext<KeychainContextValue>({
  changeKeychainPassword: async () => false,
  checkKeychainStatus: () => {},
  createKeychain: async () => {},
  emptyKeychain: async () => false,
  errorMessage: undefined,
  resetKeychain: async () => {},
  status: KeychainStatus.initial,
  unlockKeychain: async () => {},
});

export const KeychainProvider = KeychainContext.Provider;

export const useKeychain = () => useContext(KeychainContext);

interface KeychainWrapperProps {
  children: React.ReactNode;
}

const userKeychainStatusToKeychainStatus: Record<
  UserKeychainStatus,
  KeychainStatus
> = {
  [UserKeychainStatus.Unset]: KeychainStatus.unset,
  [UserKeychainStatus.Locked]: KeychainStatus.locked,
  [UserKeychainStatus.Unlocked]: KeychainStatus.unlocked,
};

const KeychainWrapper: React.FC<KeychainWrapperProps> = memo(({ children }) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.KEYCHAIN,
  });
  const [status, setStatus] = useState<KeychainStatus>(KeychainStatus.initial);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { keychain } = useKeychainService();
  const checkKeychainStatus = useCallback(async () => {
    setStatus(KeychainStatus.loading);
    try {
      const keychainStatus = await keychain.getCurrentStatusOrInitial();
      setStatus(
        (keychainStatus &&
          userKeychainStatusToKeychainStatus[keychainStatus]) ??
          KeychainStatus.unknown
      );
    } catch (error) {
      setErrorMessage(
        error?.message ||
          (typeof error === "string" ? error : "Something went wrong")
      );
      setStatus(KeychainStatus.error);
    }
  }, [keychain]);

  const unlockKeychain = useCallback(
    async (password: string) => {
      try {
        const payload = await keychain.unlock(password);
        if (payload && !payload.error) {
          setStatus(KeychainStatus.unlocked);
        } else {
          throw new Error(payload?.error?.message);
        }
      } catch (error) {
        enqueueSnackbar(`Keychain could not be unlocked.`, {
          context: error?.message || error,
          persist: true,
          variant: "error",
        });
      }
    },
    [keychain, enqueueSnackbar]
  );

  const createKeychain = useCallback(
    async (password: string) => {
      try {
        await keychain.create(password);
        setStatus(KeychainStatus.unlocked);
      } catch (error) {
        enqueueSnackbar(`Keychain setup could not be completed.`, {
          context: error?.message || error,
          persist: true,
          variant: "error",
        });
      }
    },
    [keychain, enqueueSnackbar]
  );

  const resetKeychain = useCallback(async () => {
    try {
      await keychain.reset();
      setStatus(KeychainStatus.unset);
    } catch (error) {
      enqueueSnackbar(`Keychain password could not be reset.`, {
        context: error?.message || error,
        persist: true,
        variant: "error",
      });
    }
  }, [keychain, enqueueSnackbar]);

  const emptyKeychain = useCallback(
    async (password: string) => {
      try {
        await keychain.empty(password);
        enqueueSnackbar(`Keychain has been successfully emptied.`);
        return true;
      } catch (error) {
        enqueueSnackbar(`Keychain could not be emptied.`, {
          context: error?.message || error,
          persist: true,
          variant: "error",
        });
        return false;
      }
    },
    [keychain, enqueueSnackbar]
  );

  const changeKeychainPassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      try {
        await keychain.changePassword(oldPassword, newPassword);
        enqueueSnackbar(`Password has been successfully changed.`);
        return true;
      } catch (error) {
        enqueueSnackbar(`Keychain password could not be changed.`, {
          context: error?.message || error,
          persist: true,
          variant: "error",
        });
        return false;
      }
    },
    [keychain, enqueueSnackbar]
  );

  const value = useMemo(
    () => ({
      changeKeychainPassword,
      checkKeychainStatus,
      createKeychain,
      emptyKeychain,
      errorMessage,
      resetKeychain,
      status,
      unlockKeychain,
    }),
    [
      status,
      errorMessage,
      checkKeychainStatus,
      unlockKeychain,
      emptyKeychain,
      changeKeychainPassword,
      createKeychain,
      resetKeychain,
    ]
  );

  return <KeychainProvider value={value}>{children}</KeychainProvider>;
});

export default KeychainWrapper;

export const withKeychain = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  return function WithKeychain(props: P): JSX.Element {
    const [isForgotPasswordOpened, setIsForgotPasswordOpened] =
      useState<boolean>(false);
    const { status, checkKeychainStatus, errorMessage } = useKeychain();
    const isInitial = status === KeychainStatus.initial;
    const isUnset = status === KeychainStatus.unset;
    const isLocked = status === KeychainStatus.locked;
    const isUnlocked = status === KeychainStatus.unlocked;
    const isError = status === KeychainStatus.error;
    useEffect(() => {
      if (!isInitial) {
        return;
      }
      checkKeychainStatus();
    }, [isInitial, checkKeychainStatus]);
    if (isUnlocked) {
      return <Component {...props} />;
    }
    if (isUnset) {
      return <SetupKeychain />;
    }
    if (isLocked) {
      return (
        <>
          <UnlockKeychain
            onForgotPassword={() => setIsForgotPasswordOpened(true)}
            open={!isForgotPasswordOpened}
          />
          <ResetKeychainPassword
            onCancel={() => setIsForgotPasswordOpened(false)}
            open={isForgotPasswordOpened}
          />
        </>
      );
    }
    if (isError) {
      return <KeychainError message={errorMessage} />;
    }
    return <Loading />;
  };
};
