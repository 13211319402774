import { type DatasetDefinitionData } from "../models";

export const calculateDatasetGeneralStatistics = (
  data: DatasetDefinitionData[][]
): {
  numberOfDuplicateRows: number;
  numberOfNotNullableRows: number;
} => {
  let numberOfNotNullableRows = 0;
  const uniqueRows = new Set<string>();
  data.forEach((row) => {
    if (!row.includes("")) {
      numberOfNotNullableRows++;
    }
    uniqueRows.add(row.join());
  });
  let numberOfDuplicateRows = data.length - uniqueRows.size;
  return {
    numberOfDuplicateRows,
    numberOfNotNullableRows,
  };
};

export const truncateStatisticValue = (
  value: number
): { withTooltip: boolean; value: number; fullValue: number } => {
  const truncateLimit = 3;
  if (Number.isInteger(value) || (value < 0.01 && value > -0.01)) {
    return {
      fullValue: value,
      value,
      withTooltip: false,
    };
  }
  const parts = value.toString().split(".");
  const base = parts[0];
  const decimals = parts[1];
  const truncatedDecimals =
    decimals.length > truncateLimit
      ? decimals.substring(0, truncateLimit)
      : decimals;
  return {
    fullValue: value,
    value: parseFloat(`${base}.${truncatedDecimals}`),
    withTooltip: decimals.length > truncatedDecimals.length,
  };
};
