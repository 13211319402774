import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo, useState } from "react";
import { useKeychain } from "wrappers";

interface ResetKeychainPasswordProps {
  onCancel: () => void;
  open: boolean;
}

const ResetKeychainPassword: React.FC<ResetKeychainPasswordProps> = ({
  open,
  onCancel,
}) => {
  const [reseting, setReseting] = useState(false);
  const { resetKeychain } = useKeychain();
  return (
    <Dialog
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }),
      }}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>
            Are you sure you want to reset your Keychain password?
          </strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          If you reset your keychain password, you will no longer be able to use
          the keys you currently have in the Keychain.
        </Typography>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          Note: Datasets will remain provisioned to all data clean rooms. No
          data will be lost.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={reseting} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="inherit"
          loading={reseting}
          loadingPosition="start"
          onClick={async () => {
            setReseting(true);
            await resetKeychain();
            setReseting(false);
          }}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          sx={{ color: "error.main" }}
          variant="contained"
        >
          Empty Keychain and reset password
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ResetKeychainPassword);
