import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { memo } from "react";

const LicenseTypes = (
  <div style={{ padding: "4px" }}>
    License status:
    <ul style={{ margin: 0, paddingInlineStart: "24px" }}>
      <li>SUBSCRIBED: All features.</li>
      <li>SUSPENDED: PASSIVE + Admin Portal access.</li>
      <li>TRIAL: All features. No Admin Portal access.</li>
      <li>PASSIVE: Only DCR participation, can’t create. No admin portal.</li>
      <li>ARCHIVED: All users deleted. For archive purposes only.</li>
    </ul>
  </div>
);

const OrganizationLicenseTypesTooltip: React.FC = () => {
  return (
    <Tooltip placement="right-start" title={LicenseTypes}>
      <FontAwesomeIcon
        fixedWidth={true}
        icon={faInfoCircle}
        style={{ marginLeft: "4px" }}
      />
    </Tooltip>
  );
};

OrganizationLicenseTypesTooltip.displayName = "OrganizationLicenseTypesTooltip";

export default memo(OrganizationLicenseTypesTooltip);
