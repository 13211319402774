import {
  DataNodeConstructor,
  DataNodeConstructorMode,
} from "features/dataNodes";
import TestDataNodeActions from "features/dataNodes/containers/TestDraftDataNodeActions/TestDraftDataNodeActions";
import CommittedDataNodeActions from "../ComittedDataNodeActions/ComittedDataNodeActions";
import { DataRoomActionsWrapper } from "./DataNodesActionsWrapper";
import { useDataNodes } from "./useDataNodes";

interface DataNodesProps {
  dataRoomId: string;
  mode: DataNodeConstructorMode;
  ownerEmail: string;
  currentUserEmail: string;
  permittedOnly?: boolean;
}

const DataNodes: React.FC<DataNodesProps> = (props) => {
  const { mode, dataRoomId } = props;
  const { dataNodes, dataNodesOrder, loading, error } = useDataNodes({
    dataRoomId,
    mode,
    permittedOnly: props.permittedOnly,
  });
  return (
    <DataRoomActionsWrapper dataRoomId={dataRoomId}>
      <DataNodeConstructor
        dataNodes={dataNodes}
        dataNodesOrder={dataNodesOrder}
        dataRoomId={dataRoomId}
        error={error}
        loading={loading}
        mode={mode}
        renderDataActions={(dataNode) => {
          if (mode === DataNodeConstructorMode.EDIT_WITH_TESTING) {
            return (
              <TestDataNodeActions
                dataNode={dataNode}
                dataRoomId={dataRoomId}
                mode={mode}
                nodesLoading={loading}
              />
            );
          }
          return (
            <CommittedDataNodeActions
              dataNode={dataNode}
              dataRoomId={dataRoomId}
              mode={mode}
              nodesLoading={loading}
            />
          );
        }}
      />
    </DataRoomActionsWrapper>
  );
};

export default DataNodes;
