import { InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  alpha,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import uniqBy from "lodash/uniqBy";
import {
  type FocusEventHandler,
  type KeyboardEventHandler,
  memo,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useFormContext } from "react-hook-form";
import { normalizeEmail } from "features/mediaDataRoom/utils";
import ParticipantsEditor from "./ParticipantsEditor";

const CollaborationConfigurationStepRoleContainer = styled(FormControl)(
  ({ theme }) => ({
    backgroundColor: "transparent",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
    borderWidth: "1px",
    fontWeight: 500,
    letterSpacing: -0.5,
    marginLeft: 0,
    marginRight: 0,
    padding: theme.spacing(0.5),
  })
);

type RoleEmailFieldName =
  | "advertiserEmail"
  | "publisherEmail"
  | "dataPartnerEmail"
  | "agencyEmail"
  | "observerEmail";

interface CollaborationConfigurationStepRoleProps {
  onEmailAdd?: (email: string) => void;
  onEmailRemove?: (email: string) => void;
  icon: IconDefinition;
  roleTagLabel: ReactNode;
  emailValues: string[];
  emailPlaceholder?: string;
  fieldName?: RoleEmailFieldName;
  mainParticipantValue?: string;
  onMainParticipantChange?: (email: SetStateAction<string>) => void;
}

const CollaborationConfigurationStepRole: React.FC<CollaborationConfigurationStepRoleProps> =
  memo(
    ({
      icon,
      roleTagLabel,
      emailValues = [],
      emailPlaceholder = "Type in user email and press 'Enter'",
      onEmailAdd,
      onEmailRemove,
      fieldName,
      onMainParticipantChange = () => {},
      mainParticipantValue,
    }) => {
      const {
        control: formControl,
        resetField,
        getFieldState,
      } = useFormContext<{ [k in RoleEmailFieldName]?: string }>();
      // Add participant to the list to show in chips then
      const handleAddEmailOnKeyDown = useCallback<
        KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
      >(
        (event) => {
          const { target, keyCode } = event || {};
          const { error } = getFieldState(target.name);
          if (keyCode !== 13 || !target.value || error?.message || !onEmailAdd)
            return;
          onEmailAdd(normalizeEmail(target.value));
          resetField(target.name);
        },
        [getFieldState, onEmailAdd, resetField]
      );
      const handleAddEmailOnBlur = useCallback<
        FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      >(
        (event) => {
          const { target } = event || {};
          const { error } = getFieldState(target.name as RoleEmailFieldName);
          if (!target.value || error?.message || !onEmailAdd) return;
          onEmailAdd(normalizeEmail(target.value));
          resetField(target.name as RoleEmailFieldName);
        },
        [getFieldState, onEmailAdd, resetField]
      );

      // Main Participant selector
      const domainsList: { label: string; value: string }[] = useMemo(() => {
        const participants = emailValues.map((email) => {
          const [, emailDomain] = email.split("@");
          return {
            label: emailDomain,
            value: email,
          };
        });
        return uniqBy(participants, "label");
      }, [emailValues]);

      // Main participant email is required only if more than 1 email and they are in different domains (uniqBy in map)
      const isMainParticipantSelectVisible = domainsList.length > 1;

      useEffect(() => {
        const isParticipantExist = domainsList.find(
          ({ value }) => value === mainParticipantValue
        );
        if (!isMainParticipantSelectVisible || !isParticipantExist) {
          onMainParticipantChange(domainsList[0]?.value || "");
        }
      }, [
        domainsList,
        isMainParticipantSelectVisible,
        mainParticipantValue,
        onMainParticipantChange,
      ]);
      const textFieldTestids: Record<RoleEmailFieldName, string> = {
        advertiserEmail:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .advertiserEmail,
        agencyEmail:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .agencyEmail,
        dataPartnerEmail:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .dataPartnerEmail,
        observerEmail:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .observerEmail,
        publisherEmail:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .publisherEmail,
      };
      return (
        <CollaborationConfigurationStepRoleContainer
          sx={{ borderRadius: null, width: "100%" }}
        >
          <Grid columnSpacing={1} container={true}>
            <Grid container={true} item={true} xs={3}>
              <Box
                sx={(theme) => ({
                  alignItems: "center",
                  backgroundColor: alpha(theme.palette.primary.main, 0.08),
                  color: theme.palette.primary.main,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                  padding: theme.spacing(1),
                  width: "100%",
                })}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FontAwesomeIcon fontSize="1.5rem" icon={icon} />
                  <Typography fontWeight={500} sx={{ ml: 1 }} variant="body2">
                    {roleTagLabel}
                  </Typography>
                </Box>
                {isMainParticipantSelectVisible && (
                  <Box
                    sx={(theme) => ({
                      alignItems: "center",
                      color: theme.palette.text.primary,
                      display: "flex",
                      mt: 1,
                      width: "100%",
                    })}
                  >
                    <Select
                      displayEmpty={true}
                      fullWidth={true}
                      onChange={(event) => {
                        onMainParticipantChange(event.target.value);
                      }}
                      renderValue={(value) => {
                        const participantEmail = domainsList.find(
                          (domain) => domain.value === value
                        )?.label;
                        return participantEmail || "Select organization";
                      }}
                      size="small"
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "4px 32px 4px 4px !important",
                        },
                        background: "transparent",
                        maxWidth: "80%",
                      }}
                      value={mainParticipantValue || ""}
                      variant="filled"
                    >
                      {domainsList.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                    <InfoTooltip
                      tooltip="Please select the organization the represents the publisher/advertiser in this DCR"
                      top="1px"
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid alignItems="center" display="flex" item={true} xs={9}>
              <ParticipantsEditor
                emails={emailValues}
                fieldName={fieldName}
                formControl={formControl}
                onBlur={handleAddEmailOnBlur}
                onEmailRemove={onEmailRemove}
                onKeyDown={handleAddEmailOnKeyDown}
                placeholder={emailPlaceholder}
                readonly={!fieldName}
                testId={fieldName ? textFieldTestids[fieldName] : undefined}
              />
            </Grid>
          </Grid>
        </CollaborationConfigurationStepRoleContainer>
      );
    }
  );

CollaborationConfigurationStepRole.displayName =
  "CollaborationConfigurationStepRole";

export default CollaborationConfigurationStepRole;
