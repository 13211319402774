import {
  faFileCertificate,
  faKey,
  faMicrochip,
  faShieldCheck,
  faShieldKeyhole,
} from "@fortawesome/pro-duotone-svg-icons";
import { faExternalLinkSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  type Theme,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";

const useListItemIconStyles = makeStyles()((theme: Theme) => ({
  root: {
    minWidth: theme.spacing(5),
  },
}));

const usePublishedAndProtectedDialogStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: theme.palette.secondary.main,
  },
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  linkButtonIcon: {},
  listIcon: {},
  title: {
    lineHeight: 1.25,
  },
}));

const useLinkButtonStyles = makeStyles()((theme: Theme) => ({
  label: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  root: {
    borderRadius: 0,
    padding: theme.spacing(1, 3),
  },
}));

interface PublishedAndProtectedDialogProps {
  open: boolean;
  onClose: () => void;
}

const PublishedAndProtectedDialog: React.FC<
  PublishedAndProtectedDialogProps
> = ({ open, onClose }) => {
  const { classes: publishedAndProtectedDialogClasses } =
    usePublishedAndProtectedDialogStyles();
  const { classes: listItemIconClasses } = useListItemIconStyles();
  const { classes: linkButtonClasses } = useLinkButtonStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <Box className={publishedAndProtectedDialogClasses.iconTitleWrapper}>
        <Box className={publishedAndProtectedDialogClasses.iconWrapper}>
          <FontAwesomeIcon
            className={publishedAndProtectedDialogClasses.icon}
            fixedWidth={true}
            icon={faShieldKeyhole}
            size="5x"
          />
        </Box>
        <Typography
          align="center"
          className={publishedAndProtectedDialogClasses.title}
          color="textPrimary"
          variant="h6"
        >
          <strong>
            This data clean room is published and protected
            <br />
            by confidential computing guarantees
          </strong>
        </Typography>
      </Box>
      <DialogContent>
        <Typography align="left" component="div" variant="body2">
          Data clean room participants can securely provision their datasets.
          Hardware technologies like Intel® SGX and AMD SEV-SNP help to protect
          this data from disclosure or modification by partitioning Decentriq
          Platform application into the enclaves in memory that increase
          security:
          <List dense={true}>
            <ListItem disableGutters={true}>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  className={publishedAndProtectedDialogClasses.listIcon}
                  fixedWidth={true}
                  icon={faShieldCheck}
                />
              </ListItemIcon>
              <ListItemText
                primary="Enclaves have hardware-assisted confidentiality and
              integrity-added protections to help prevent access from processes
              at higher privilege levels."
              />
            </ListItem>
            <ListItem disableGutters={true}>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  className={publishedAndProtectedDialogClasses.listIcon}
                  fixedWidth={true}
                  icon={faMicrochip}
                />
              </ListItemIcon>
              <ListItemText
                primary="Hardware-enhanced content protection capabilities help content
              owners with protecting their intellectual property through
              unaltered or unmodified streaming."
              />
            </ListItem>
            <ListItem disableGutters={true}>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  className={publishedAndProtectedDialogClasses.listIcon}
                  fixedWidth={true}
                  icon={faFileCertificate}
                />
              </ListItemIcon>
              <ListItemText
                primary="Through attestation services it is also possible to receive
              verification on the identity of the application running in the
              enclave."
              />
            </ListItem>
            <ListItem disableGutters={true}>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  className={publishedAndProtectedDialogClasses.listIcon}
                  fixedWidth={true}
                  icon={faKey}
                />
              </ListItemIcon>
              <ListItemText primary="Data is end-to-end encrypted so nobody except data clean room participants — not even Decentriq — can decrypt and read it." />
            </ListItem>
          </List>
        </Typography>
      </DialogContent>
      <Divider />
      <Button
        classes={linkButtonClasses}
        color="inherit"
        href="https://www.intel.com/content/www/us/en/developer/tools/software-guard-extensions/attestation-services.html"
        target="_blank"
      >
        How Intel® SGX remote attestation works
        <FontAwesomeIcon
          className={publishedAndProtectedDialogClasses.linkButtonIcon}
          fixedWidth={true}
          icon={faExternalLinkSquare}
        />
      </Button>
      <Divider />
      <Button
        classes={linkButtonClasses}
        color="inherit"
        href="https://blog.decentriq.com/swiss-cheese-to-cheddar-securing-amd-sev-snp-early-boot-2/"
        target="_blank"
      >
        How Decentriq operates on AMD SEV-SNP
        <FontAwesomeIcon
          className={publishedAndProtectedDialogClasses.linkButtonIcon}
          fixedWidth={true}
          icon={faExternalLinkSquare}
        />
      </Button>
      <Divider />
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PublishedAndProtectedDialog.displayName = "PublishedAndProtectedDialog";

export default memo(PublishedAndProtectedDialog);
