import { InfoTooltip } from "@decentriq/components";
import { useUpdateSyntheticNodeAccuracyMutation } from "@decentriq/graphql/dist/hooks";
import { FormControlLabel, MenuItem, Select } from "@mui/material";
import { memo, useCallback } from "react";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type SyntheticNodeAccuracy,
  syntheticNodeAccuracyPresentation,
} from "models";
import useSyntheticNodeAccuracy from "./useSyntheticNodeAccuracy";

type SyntheticNodeAccuracySelectProps = {
  computeNodeId: string;
  readOnly?: boolean;
};

const SyntheticNodeAccuracySelect: React.FC<
  SyntheticNodeAccuracySelectProps
> = ({ computeNodeId, readOnly }) => {
  const { accuracy } = useSyntheticNodeAccuracy(computeNodeId);
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [updateSyntheticNodeAccuracyMutation] =
    useUpdateSyntheticNodeAccuracyMutation();
  const updateSyntheticNodeAccuracy = useCallback(
    async (accuracy: SyntheticNodeAccuracy) => {
      try {
        return await updateSyntheticNodeAccuracyMutation({
          variables: {
            input: {
              accuracy,
              id: computeNodeId,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The privacy mode settings could not be updated."
          )
        );
        throw error;
      }
    },
    [updateSyntheticNodeAccuracyMutation, computeNodeId, enqueueSnackbar]
  );

  return (
    <FormControlLabel
      control={
        <>
          <InfoTooltip
            tooltip={
              <>
                Choose <i>Balanced</i> for best accuracy.
                <br />
                Choose <i>Strictest</i> for the strongest privacy guarantees.
              </>
            }
          />
          <Select
            disabled={readOnly}
            onChange={({ target }) => {
              updateSyntheticNodeAccuracy(
                target.value as SyntheticNodeAccuracy
              );
            }}
            size="small"
            style={{
              background: "transparent",
              marginLeft: ".5rem",
              minWidth: 144,
              textAlign: "center",
            }}
            value={accuracy}
            variant="standard"
          >
            {Array.from(syntheticNodeAccuracyPresentation.entries()).map(
              ([value, label], index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              )
            )}
          </Select>
        </>
      }
      label={<span>Privacy mode</span>}
      style={{
        flexDirection: "row-reverse",
        marginLeft: "16px",
      }}
    />
  );
};

SyntheticNodeAccuracySelect.displayName = "SyntheticNodeAccuracySelect";

export default memo(SyntheticNodeAccuracySelect);
