import { Container } from "@mui/material";
import { type ReactNode } from "react";
import { Sidebar, SIDEBAR_WIDTH } from "features";

interface MainProps {
  children: ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  return (
    <Container disableGutters={true} maxWidth={false} sx={{ display: "flex" }}>
      <Sidebar />
      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
        }}
      >
        {children}
      </Container>
    </Container>
  );
};

export default Main;
