import { useEffect, useState } from "react";
import {
  OverlapInsightsExecutionContext,
  useLookalikeMediaOverlapInsightsContext,
} from "features/mediaDataRoomV2/contexts";
import {
  type ModelledAggregationsCollection,
  type SegmentAggregationsCollection,
} from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";

const useSelectedAudienceAggregations = (
  selectedAudienceType: string
): SegmentAggregationsCollection[] => {
  const [audienceTypeAggregations, setAudienceTypeAggregations] = useState<
    SegmentAggregationsCollection[] | ModelledAggregationsCollection[]
  >([]);
  const {
    overlapInsights,
    executionContext,
    reach: activationAudienceReach,
    audienceType: activationAudienceType,
  } = useLookalikeMediaOverlapInsightsContext();

  useEffect(() => {
    const { aggregations = [] } =
      overlapInsights.find(({ audience_type, reach }) =>
        executionContext !== OverlapInsightsExecutionContext.insightsTab &&
        activationAudienceType
          ? audience_type === activationAudienceType &&
            reach === activationAudienceReach
          : audience_type === selectedAudienceType
      ) || {};
    setAudienceTypeAggregations(aggregations || []);
  }, [
    activationAudienceReach,
    activationAudienceType,
    executionContext,
    overlapInsights,
    selectedAudienceType,
  ]);

  return audienceTypeAggregations || [];
};

export default useSelectedAudienceAggregations;
