import { Box, Grid } from "@mui/material";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  type InsightsComparisonViewFormValues,
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  MediaDataRoomInsightsAggregationSelector,
  MediaDataRoomInsightsComparisonItemTable,
  useMediaDataRoomOverlapInsightsContext,
} from "features/MediaInsightsDcr";
import { AffinityRatioSegmentBarChart } from "features/MediaInsightsDcr/components/MediaDataRoomInsights/components/MediaDataRoomInsightsBasicView/components";

const MediaDataRoomInsightsComparisonItem: React.FC = () => {
  const { audienceType } = useMediaDataRoomOverlapInsightsContext();
  const { watch, ...restMediaDataRoomInsightsComparisonForm } =
    useForm<InsightsComparisonViewFormValues>({
      defaultValues: {
        aggregation: "",
        audienceType: audienceType || "",
      },
      mode: "onChange",
    });

  return (
    <FormProvider watch={watch} {...restMediaDataRoomInsightsComparisonForm}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="auto"
        width="100%"
      >
        <Grid columnSpacing={3} container={true}>
          <Grid item={true} md={6} xs={12}>
            <MediaDataRoomInsightsAdvertiserAudienceSelector />
          </Grid>
          <Grid item={true} md={6} xs={12}>
            <MediaDataRoomInsightsAggregationSelector />
          </Grid>
        </Grid>
        <Box
          display="flex"
          flexDirection="column"
          mb={1}
          sx={({ breakpoints }) => ({
            minHeight: 200,
            [breakpoints.up("md")]: { minHeight: 280 },
            [breakpoints.up("xl")]: { minHeight: 350 },
          })}
        >
          <AffinityRatioSegmentBarChart />
        </Box>
        <MediaDataRoomInsightsComparisonItemTable />
      </Box>
    </FormProvider>
  );
};

MediaDataRoomInsightsComparisonItem.displayName =
  "MediaDataRoomInsightsComparisonItem";

export default memo(MediaDataRoomInsightsComparisonItem);
