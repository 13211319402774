import { alpha, Chip, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback } from "react";
import { useCreationWizardPublisher } from "features/MediaInsightsDcr/contexts";

const FilterChip = styled(Chip)<{ selected: boolean }>(
  ({ theme, selected }) => ({
    borderRadius: "2px",
    cursor: "pointer",
    height: "24px",
    ...(selected
      ? {
          "&:hover": { background: alpha(theme.palette.primary.main, 0.21) },
          background: alpha(theme.palette.primary.main, 0.17),
        }
      : {}),
  })
);

const MediaInsightsDcrCreateSelectPublisherStepMarketFilters: React.FC = () => {
  const { advertiserMarketFilters, updateAdvertiserMarketFilters } =
    useCreationWizardPublisher();
  const handleClick = useCallback(
    (marketId: string) =>
      updateAdvertiserMarketFilters(
        advertiserMarketFilters
          .map((filter) =>
            filter.id === marketId
              ? {
                  ...filter,
                  selected: !filter.selected,
                }
              : filter
          )
          .filter(({ selected }) => selected)
          .map(({ id }) => id)
      ),
    [advertiserMarketFilters, updateAdvertiserMarketFilters]
  );
  const handleAllClick = useCallback(
    () => updateAdvertiserMarketFilters([]),
    [updateAdvertiserMarketFilters]
  );
  if (!advertiserMarketFilters.length) {
    return null;
  }
  const areAllMarketsAllowed = advertiserMarketFilters.every(
    ({ selected }) => !selected
  );
  return (
    <Box mb={2}>
      <FilterChip
        key={0}
        label="ALL"
        onClick={handleAllClick}
        selected={areAllMarketsAllowed}
        sx={{ mr: 1 }}
        variant={areAllMarketsAllowed ? "filled" : "outlined"}
      />
      {advertiserMarketFilters.map(({ id, name, selected }) => (
        <FilterChip
          key={id}
          label={`${name.toUpperCase()} (${id})`}
          onClick={(event) => handleClick(id)}
          selected={selected}
          sx={{ mr: 1 }}
          variant={selected ? "filled" : "outlined"}
        />
      ))}
    </Box>
  );
};

MediaInsightsDcrCreateSelectPublisherStepMarketFilters.displayName =
  "MediaInsightsDcrCreateSelectPublisherStepMarketFilters";

export default MediaInsightsDcrCreateSelectPublisherStepMarketFilters;
