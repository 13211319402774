import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEmptySet } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";

type EmptyDataProps = {
  icon?: IconProp;
  primaryText?: string;
  secondaryText?: string;
};

const EmptyData: React.FC<EmptyDataProps> = ({
  icon = faEmptySet,
  primaryText,
  secondaryText,
}) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {icon && <FontAwesomeIcon fixedWidth={true} icon={icon} size="5x" />}
      {primaryText && (
        <Typography variant="h5">
          <strong>{primaryText}</strong>
        </Typography>
      )}
      {secondaryText && <Typography marginTop={2}>{secondaryText}</Typography>}
    </Box>
  );
};

export default EmptyData;
