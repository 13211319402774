import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mui/material";
import { memo } from "react";
import { DataRoomCreateDialog } from "features/dataRoom";
import { useOrganizationPreferences } from "hooks";

const GettingStartedActions: React.FC = () => {
  const { isAllowedToCreateDataRoom } = useOrganizationPreferences();
  return (
    <DataRoomCreateDialog
      renderTrigger={(openDataRoomCreateDialog) => (
        <Tooltip
          placement="top"
          title={
            !isAllowedToCreateDataRoom ? (
              <div>
                An active license is required to create new data clean rooms.
                Please check with your organization administrator or contact our
                support in case of questions.
              </div>
            ) : undefined
          }
        >
          <span>
            <Button
              color="primary"
              data-testid="new_dcr_button"
              disabled={!isAllowedToCreateDataRoom}
              onClick={openDataRoomCreateDialog}
              startIcon={
                <FontAwesomeIcon fixedWidth={true} icon={faPlusRegular} />
              }
              variant="contained"
            >
              New data clean room
            </Button>
          </span>
        </Tooltip>
      )}
    />
  );
};

GettingStartedActions.displayName = "GettingStartedActions";

export default memo(GettingStartedActions);
