import {
  faCheck,
  faChevronDown,
  faFlask,
  faLinkSlash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  Popover,
} from "@mui/material";
import { memo, useCallback, useRef } from "react";
import { usePublishedLookalikeMediaDataRoom } from "contexts";
import { usePopupState } from "hooks";
import usePublisherDataNodeActions from "../../usePublisherDataNodeActions";

type PublisherDataNodeDeprovisionButtonProps = {
  retrieveDatasets: () => Promise<void>;
};

const PublisherDataNodeDeprovisionButton: React.FC<
  PublisherDataNodeDeprovisionButtonProps
> = ({ retrieveDatasets }) => {
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });
  const anchorRef = useRef<HTMLDivElement>(null);
  const { isPublisher, publishedDataLab } =
    usePublishedLookalikeMediaDataRoom();
  const { deprovisionDataLab, deprovisioning } = usePublisherDataNodeActions({
    retrieveDatasets,
  });
  const handleDataLabDeprovision = useCallback(() => {
    close();
    deprovisionDataLab(publishedDataLab!);
  }, [close, deprovisionDataLab, publishedDataLab]);
  if (deprovisioning) {
    return <CircularProgress color="inherit" size={16} />;
  }
  return (
    <>
      <ButtonGroup
        aria-label="split button"
        ref={anchorRef}
        sx={{ alignItems: "center", marginLeft: 2 }}
      >
        <Button
          color="primary"
          size="small"
          startIcon={
            <FontAwesomeIcon
              fixedWidth={true}
              icon={isPublisher ? faFlask : faCheck}
              style={{ marginRight: "4px" }}
            />
          }
          style={{
            justifyContent: "flex-start",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          variant="contained"
        >
          {publishedDataLab?.name || "Datalab provisioned"}
        </Button>
        <Button
          aria-controls={isOpen ? "split-button-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="menu"
          color="primary"
          onClick={open}
          size="small"
          sx={{ "&.MuiButtonGroup-grouped": { minWidth: "auto" } }}
          variant="outlined"
        >
          <FontAwesomeIcon fixedWidth={true} icon={faChevronDown} />
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        marginThreshold={8}
        onClose={close}
        open={isOpen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem onClick={handleDataLabDeprovision}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faLinkSlash}
            style={{ marginRight: ".5rem" }}
          />{" "}
          Deprovision datalab
        </MenuItem>
      </Popover>
    </>
  );
};

PublisherDataNodeDeprovisionButton.displayName =
  "PublisherDataNodeDeprovisionButton";

export default memo(PublisherDataNodeDeprovisionButton);
