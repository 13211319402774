import { testIds } from "@decentriq/utils";
import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useDataLabContext } from "features/dataLabs";

const DataLabValidationButton: React.FC = () => {
  const {
    computeStatistics,
    statisticsLoading,
    dataLab: { data },
  } = useDataLabContext();
  const {
    usersDataset,
    embeddingsDataset,
    requireEmbeddingsDataset,
    demographicsDataset,
    requireDemographicsDataset,
    requireSegmentsDataset,
    segmentsDataset,
  } = data!;
  const enabled =
    Boolean(usersDataset) &&
    (Boolean(embeddingsDataset) || !requireEmbeddingsDataset) &&
    (Boolean(demographicsDataset) || !requireDemographicsDataset) &&
    (Boolean(segmentsDataset) || !requireSegmentsDataset);
  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{ marginRight: "8px" }}
    >
      <LoadingButton
        data-testid={testIds.dataLabs.dataLabValidation.dataLabValidationButton}
        disabled={!enabled}
        loading={statisticsLoading}
        onClick={computeStatistics}
        size="small"
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faChartSimple} />}
        style={{ marginLeft: "16px" }}
      >
        Compute statistics
      </LoadingButton>
    </Box>
  );
};

export default DataLabValidationButton;
