import { DatasetSchemaExtractionDialog } from "@decentriq/components";
import { type VersionedSchema } from "@decentriq/components/dist/components/DatasetUploader/types";
import { exceptions } from "@decentriq/utils";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { DataNodeUploadDataDialog } from "features";
import { DataNodeConstructorMode } from "features/dataNodes/models";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
  type FileIngestionDefinition,
} from "features/datasets";
import { useReportError } from "hooks";
import {
  castFormatTypeToPrimitiveType,
  type DataRoomData,
  type DataRoomDataTable,
  DataRoomType,
} from "models";
import { chainPromises } from "utils";
import DataNodeActions from "../../components/DataNodeConstructor/DataNodeActions";
import { useDataNodeActions } from "../DataNodes/DataNodesActionsWrapper";
import useTestDraftDataNodeActions from "./useTestDraftDataNodeActions";

interface TestDataNodeActionsProps {
  dataNode: DataRoomData;
  dataRoomId: string;
  mode: DataNodeConstructorMode;
  nodesLoading: boolean;
}

const TestDataNodeActions: React.FC<TestDataNodeActionsProps> = ({
  dataNode,
  dataRoomId,
  mode,
  nodesLoading,
}) => {
  const {
    handleTableColumnsOrderUpdate,
    handleTableColumnCreate,
    handleTableColumnDelete,
  } = useDataNodeActions();
  const {
    activeDataRoomUpload,
    currentUserEmail,
    dataNodeForIngestion,
    handleIngestData,
    handleDataDeprovision,
    handleConnectFromKeychain,
    resetUploadings,
    setDataNodeForIngestion,
    uploadings,
    handleUploadClose,
  } = useTestDraftDataNodeActions();
  const reportError = useReportError();
  useEffect(() => {
    if (
      mode === DataNodeConstructorMode.ACTION ||
      mode === DataNodeConstructorMode.STATUS ||
      mode === DataNodeConstructorMode.DEGRADE_ACTION
    ) {
      resetUploadings();
    }
  }, [mode, dataRoomId, resetUploadings]);
  const onDataDeprovision = useCallback(
    async () => await handleDataDeprovision(dataNode.id),
    [handleDataDeprovision, dataNode.id]
  );
  const handleError = useCallback(
    (error: Error) => {
      if (
        error instanceof exceptions.DatasetValidationError &&
        error.hasReport
      ) {
        return;
      }
      reportError(
        {
          details: error.message,
          errorContext: [
            {
              content: dataRoomId,
              name: "dataRoomId",
            },
          ],
          origin: `${DataRoomType.DataScience}_TEST`,
        },
        { silent: true }
      );
    },
    [reportError, dataRoomId]
  );
  const key = `${dataNode.id}-${currentUserEmail}`;
  const id = dataNodeForIngestion?.id;
  const onIngest = useCallback(
    async (
      payload:
        | DataIngestionPayload<DatasetIngestionDefinition>
        | DataIngestionPayload<FileIngestionDefinition>
    ) => {
      if (payload.source === "local") {
        return await handleIngestData({
          dataNodeId: id!,
          schema: payload.schema,
          shouldStoreInKeychain: !!payload.shouldStoreInKeychain,
          uploadResult: payload.uploadResult!,
        });
      }
      if (payload.source === "keychain") {
        return await handleConnectFromKeychain(
          id!,
          payload.datasetKeychainItem!
        );
      }
    },
    [id, handleIngestData, handleConnectFromKeychain]
  );
  const { dataType } = dataNode;
  const [open, setOpen] = useState(false);
  const replaceWithColumns = useCallback(
    async (columns: VersionedSchema["columns"]) => {
      const dataNodeColumns = (dataNode as DataRoomDataTable).columns;
      await Promise.all(
        dataNodeColumns.map(({ id }: { id: string }) =>
          handleTableColumnDelete(id)
        )
      );
      const creationResult = await chainPromises(
        columns,
        ({ name, nullable, formatType, hashWith }) =>
          handleTableColumnCreate(dataNode.id, {
            formatType,
            hashWith,
            name,
            nullable,
            primitiveType: castFormatTypeToPrimitiveType(formatType),
          })
      );
      const columnsOrder = creationResult.results
        .map(({ result }) => result?.data?.draftTableLeafNode?.addColumn?.id)
        .filter((id) => id !== undefined) as string[];
      await handleTableColumnsOrderUpdate({ columnsOrder, id: dataNode.id });
    },
    [
      dataNode,
      handleTableColumnsOrderUpdate,
      handleTableColumnCreate,
      handleTableColumnDelete,
    ]
  );
  return (
    <>
      {dataType === "table" ? (
        <Button
          color="inherit"
          onClick={() => setOpen(true)}
          variant="outlined"
        >
          Import schema
        </Button>
      ) : null}
      <DatasetSchemaExtractionDialog
        DatasetUploaderProps={{
          OkayButtonProps: {
            onClick: (_, schema) => {
              replaceWithColumns?.(schema?.columns || []);
              setOpen(false);
            },
          },
          schema: undefined,
        }}
        DialogTitleChildren={
          <>
            <span>Import schema</span>
            <IconButton
              color="inherit"
              onClick={() => setOpen(false)}
              sx={{ fontSize: "1.25rem", p: 0.5, width: "2rem" }}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
            </IconButton>
          </>
        }
        DialogTitleProps={{
          sx: {
            display: "flex",
            justifyContent: "space-between",
          },
        }}
        onClose={() => setOpen(false)}
        open={open}
      />
      <DataNodeActions
        dataType={dataNode.dataType}
        datasetHash={dataNode?.testDataset?.datasetHash}
        id={dataNode.id}
        isLoading={
          uploadings[key]?.isLoading ||
          activeDataRoomUpload === key ||
          nodesLoading
        }
        onDeprovision={onDataDeprovision}
        onUpload={() => {
          setDataNodeForIngestion(dataNode);
        }}
        testing={true}
      />
      {dataNodeForIngestion && (
        <DataNodeUploadDataDialog
          columns={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).columns
              : undefined
          }
          columnsOrder={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).columnsOrder
              : undefined
          }
          id={dataNodeForIngestion.id}
          name={dataNodeForIngestion.name}
          onClose={handleUploadClose}
          onError={handleError}
          onIngest={onIngest}
          open={!!dataNodeForIngestion}
          uniqueColumnIds={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).uniqueColumnIds
              : undefined
          }
        />
      )}
    </>
  );
};

export default TestDataNodeActions;
