interface GetPublisherCharts {
  publisherSlug: string;
}

export const getPublisherCharts = ({ publisherSlug }: GetPublisherCharts) => `
import matplotlib.pyplot as plt
import pandas as pd
import seaborn as sns

PUBLISHER = '${publisherSlug}'

input_df = pd.read_pickle('/input/attribution/campaign_stats.pkl')
campaign_stats = input_df.loc[input_df['publisher'] == PUBLISHER]

# Set seaborn style
sns.set(style="whitegrid")

# Visualization 1: Bar Chart of Impressions and Clicks per Publisher per Campaign
try:  
    plt.figure(figsize=(12, 6))
    sns.barplot(data=campaign_stats, x='campaign', y='num_impressions', hue='publisher', errorbar=None)
    plt.title('Impressions per Publisher per Campaign')
    plt.xlabel('Campaign')
    plt.ylabel('Number of Impressions')
    plt.xticks(rotation=45)
    plt.legend(title='Publisher')
    plt.tight_layout()
    plt.savefig('/output/imp_pub.png')
except:
  pass

try:  
  plt.figure(figsize=(12, 6))
  sns.barplot(data=campaign_stats, x='campaign', y='num_clicks', hue='publisher', errorbar=None)
  plt.title('Clicks per Publisher per Campaign')
  plt.xlabel('Campaign')
  plt.ylabel('Number of Clicks')
  plt.xticks(rotation=45)
  plt.legend(title='Publisher')
  plt.savefig('/output/clk_pub.png')
except:
  pass

# Visualization 2: Bar Chart of Conversions and Total Value per Publisher per Campaign
try:  
  plt.figure(figsize=(12, 6))
  sns.barplot(data=campaign_stats, x='campaign', y='num_conversions', hue='publisher', errorbar=None)
  plt.title('Conversions per Publisher per Campaign')
  plt.xlabel('Campaign')
  plt.ylabel('Number of Conversions')
  plt.xticks(rotation=45)
  plt.legend(title='Publisher')
  plt.tight_layout()
  plt.savefig('/output/conv_pub.png')
except:
  pass

try:  
  plt.figure(figsize=(12, 6))
  sns.barplot(data=campaign_stats, x='campaign', y='total_value_attributed', hue='publisher', errorbar=None)
  plt.title('Total Value per Publisher per Campaign')
  plt.xlabel('Campaign')
  plt.ylabel('Total Value Attributed')
  plt.xticks(rotation=45)
  plt.legend(title='Publisher')
  plt.tight_layout()
  plt.show()
  plt.savefig('/output/val_pub.png')
except:
  pass

# Visualization 4: Heatmap of Attribution by Publisher and Campaign
try:  
  pivot_table = campaign_stats.pivot_table(index='publisher', columns='campaign', values='num_conversions', aggfunc='sum')
  plt.figure(figsize=(12, 6))
  sns.heatmap(pivot_table, cmap='viridis', annot=True)
  plt.title('Attribution by Publisher and Campaign')
  plt.xlabel('Campaign')
  plt.ylabel('Publisher')
  plt.tight_layout()
  plt.savefig('/output/heat.png')
except:
  pass
`;
