import {
  faChevronDown,
  faUpload,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
} from "@mui/material";
import { memo, useMemo, useRef } from "react";
import { usePopupState } from "hooks";
import { type DataType } from "models";
import DataNodeDataButton from "./DataNodeDataButton";

interface DataNodeReprovisionButtonProps {
  id: string;
  label: string;
  onUpload: () => void;
  datasetHash?: string;
  dataType: DataType;
  testing?: boolean;
}

const DataNodeReprovisionButton: React.FC<DataNodeReprovisionButtonProps> = ({
  id,
  label,
  onUpload,
  datasetHash,
  dataType,
  testing,
}) => {
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });
  const anchorRef = useRef<HTMLDivElement>(null);
  const options = useMemo<
    { label: string; icon: IconDefinition; tooltip: string }[]
  >(
    () => [
      {
        icon: faUpload,
        label: `Reprovision ${label}`,
        tooltip: `This will replace the current ${label} with a new ${label} provisioned by you. All derived computation results will be reset.`,
      },
    ],
    [label]
  );
  const handleClick = (index: number) => {
    if (index === 0) {
      onUpload();
      return;
    }
  };
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    close();
    handleClick(index);
  };
  return (
    <>
      <ButtonGroup aria-label="split button" ref={anchorRef}>
        <DataNodeDataButton
          dataType={dataType}
          datasetHash={datasetHash}
          id={id}
          onClick={() => close()}
          testing={testing}
        />
        <Button
          aria-controls={isOpen ? "split-button-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="menu"
          color={testing ? "secondary" : "primary"}
          onClick={open}
          size="small"
          sx={{ "&.MuiButtonGroup-grouped": { minWidth: "auto" } }}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faChevronDown} />
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        marginThreshold={8}
        onClose={close}
        open={isOpen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuList autoFocusItem={true} id="split-button-menu">
          {options.map((option, index) => (
            <Tooltip placement="left" title={option.tooltip}>
              <MenuItem
                key={option.label}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={option.icon}
                  style={{ marginRight: ".5rem" }}
                />{" "}
                {option.label}
              </MenuItem>
            </Tooltip>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};

export default memo(DataNodeReprovisionButton);
