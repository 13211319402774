import { Loader, PromiseState } from "@decentriq/components";
import { Box, Dialog } from "@mui/material";
import { useSafeState } from "ahooks";
import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMeasurement } from "features/measurementDcr/hooks";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
} from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";
import MeasurementDcrCreateConfigForm from "../MeasurementDcrCreateConfigForm/MeasurementDcrCreateConfigForm";
import { type MeasurementConfigurationFormValues } from "../MeasurementDcrCreateConfigForm/model";

interface MeasurementDcrCreateDialogProps {
  open: boolean;
  onCancel: () => void;
  restartCreation: () => void;
}

const MeasurementDcrCreateDialog: React.FC<MeasurementDcrCreateDialogProps> =
  memo(({ open, onCancel, restartCreation }) => {
    const { enqueueSnackbar } = useGeneralSnackbar({
      origin: CommonSnackbarOrigin.DASHBOARD,
    });
    const [loading, setLoading] = useSafeState(false);
    const [loadingState, setLoadingState] = useSafeState<PromiseState>(
      PromiseState.Pending
    );
    const { publish } = useMeasurement();
    const navigate = useNavigate();

    const handleSubmit = useCallback(
      async (payload: MeasurementConfigurationFormValues) => {
        try {
          setLoading(true);
          setLoadingState(PromiseState.Pending);
          const id = await publish({ payload });
          onCancel();
          setLoadingState(PromiseState.Fulfilled);
          const dataRoomLink = dataRoomPathBuilder(
            id,
            DataRoomTypeNames.PublishedDataRoom
          );
          navigate(dataRoomLink);
          setLoading(false);
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Error creating data clean room"
            )
          );
          setLoading(false);
          setLoadingState(PromiseState.Rejected);
        }
      },
      [
        enqueueSnackbar,
        navigate,
        onCancel,
        publish,
        setLoading,
        setLoadingState,
      ]
    );
    return (
      <Dialog
        PaperProps={{ sx: { transition: "all 0.2s linear" } }}
        fullWidth={true}
        maxWidth="lg"
        onClose={!loading ? onCancel : undefined}
        open={open}
        scroll="paper"
      >
        {loading ? (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Loader
              loadLabel="Creating Measurement DCR…"
              loadState={loadingState}
            />
          </Box>
        ) : (
          <MeasurementDcrCreateConfigForm
            onSubmit={handleSubmit}
            restartCreation={restartCreation}
          />
        )}
      </Dialog>
    );
  });

export default MeasurementDcrCreateDialog;
