import {
  type DatasetPublication,
  type DeleteDatasetPayload,
  type MutationDeleteDatasetArgs,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextInterface } from "contexts";
import { maybeUseDataRoomSecret } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "../../../models";

export const makeDeleteDatasetResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    parent = null,
    args: MutationDeleteDatasetArgs,
    context: LocalResolverContext
  ): Promise<DeleteDatasetPayload> => {
    const {
      input: { datasetId, manifestHash, publications },
    } = args;

    // Delete dataset references from Enclave
    const deleteDatasetReference = (
      driverAttestationHash: string,
      dataRoomId: string,
      computeNodeId: string
    ) => {
      sessionManager
        .get({
          driverAttestationHash,
        })
        .then(async (session) => {
          await maybeUseDataRoomSecret(session, context.cache, dataRoomId);
          session.removePublishedDataset(dataRoomId, computeNodeId);
        });
    };

    await Promise.all(
      publications?.map(({ dataRoom, leaf }: DatasetPublication) => {
        return deleteDatasetReference(
          dataRoom?.driverAttestationHash,
          dataRoom?.id,
          leaf?.computeNodeId
        );
      })
    );

    // Delete dataset from Enclave and Database via Client call
    await client.deleteDataset(manifestHash);

    return {
      id: datasetId,
    };
  };
