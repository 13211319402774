import { Button, styled } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo, useCallback } from "react";
import DataLabErrorReportDialog from "./DataLabErrorReportDialog";
import { type DataLabErrorReportContextValue } from "./DataLabErrorReportProvider";

const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-text": { color: "white" },
}));

interface DataLabErrorReportButtonProps extends DataLabErrorReportContextValue {
  dataLabId: string;
  error: string;
  details: string;
  closeSnackbar: () => void;
}

const DataLabErrorReportButton: React.FC<DataLabErrorReportButtonProps> = ({
  dataLabId,
  error,
  details,
  closeSnackbar,
  ...contextValue
}) => {
  const [isDialogOpen, { setFalse: closeDialog, setTrue: openDialog }] =
    useBoolean(false);
  const handleFinish = useCallback(() => {
    closeSnackbar();
    closeDialog();
  }, [closeDialog, closeSnackbar]);
  return (
    <>
      <StyledButton onClick={openDialog} variant="text">
        Report issue to Decentriq
      </StyledButton>
      <DataLabErrorReportDialog
        dataLabId={dataLabId}
        details={details}
        error={error}
        onCancel={closeDialog}
        onFinish={handleFinish}
        open={isDialogOpen}
        {...contextValue}
      />
    </>
  );
};

DataLabErrorReportButton.displayName = "DataLabErrorReportButton";

export default memo(DataLabErrorReportButton);
