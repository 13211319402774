import { type ApolloError } from "@apollo/client";
import {
  useConsentlessActivatedAudiencesQuery,
  useDirectActivatedAudiencesQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  ActivationType,
  type ConsentlessActivatedAudiencesQuery,
  type DirectActivatedAudiencesQuery,
} from "@decentriq/graphql/dist/types";
import { useEffect } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

export type ConsentlessAudience =
  ConsentlessActivatedAudiencesQuery["mediaRetrieveConsentlessActivatedAudiences"]["nodes"][number];

export type DirectAudience =
  DirectActivatedAudiencesQuery["mediaRetrieveDirectActivatedAudiences"]["nodes"][number];

interface PublisherMediaDataRoomActivationHookPayload {
  activationType: ActivationType;
}

interface PublisherMediaDataRoomActivationHookResult {
  audiences: (ConsentlessAudience | DirectAudience)[];
  activationDownloadByPublisher: boolean;
  loading: boolean;
  error: ApolloError | undefined;
}

const usePublisherMediaDataRoomActivation = ({
  activationType,
}: PublisherMediaDataRoomActivationHookPayload): PublisherMediaDataRoomActivationHookResult => {
  const { dataRoomId, dcrHash, driverAttestationHash } =
    usePublishedMediaDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const {
    data: consentlessData,
    loading: consentlessDataLoading,
    error: consentlessError,
  } = useConsentlessActivatedAudiencesQuery({
    fetchPolicy: "cache-and-network",
    skip: activationType !== ActivationType.Consentless,
    variables: {
      id: dataRoomId,
      input: {
        dataRoomId: dcrHash,
        driverAttestationHash,
      },
    },
  });
  const {
    data: directData,
    loading: directDataLoading,
    error: directError,
  } = useDirectActivatedAudiencesQuery({
    fetchPolicy: "cache-and-network",
    skip: activationType !== ActivationType.Direct,
    variables: {
      id: dataRoomId,
      input: {
        dataRoomId: dcrHash,
        driverAttestationHash,
      },
    },
  });
  const audiences: (ConsentlessAudience | DirectAudience)[] =
    consentlessData?.mediaRetrieveConsentlessActivatedAudiences.nodes ||
    directData?.mediaRetrieveDirectActivatedAudiences.nodes ||
    [];
  const error = directError || consentlessError;
  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(error, "Failed to get audiences")
      );
    }
  }, [error, enqueueSnackbar]);
  return {
    activationDownloadByPublisher:
      directData?.publishedMediaDataRoom.activationDownloadByPublisher ||
      consentlessData?.publishedMediaDataRoom.activationDownloadByPublisher ||
      false,
    audiences,
    error,
    loading: directDataLoading || consentlessDataLoading,
  };
};

export default usePublisherMediaDataRoomActivation;
