import { faCheckCircle, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { memo } from "react";
import { Chip } from "components";
import { useDataLabContext } from "features/dataLabs/contexts";

interface DataLabStatusLabelProps {
  id: string;
}

const DataLabStatusLabel: React.FC<DataLabStatusLabelProps> = ({ id }) => {
  const {
    dataLab: { data },
  } = useDataLabContext();
  if (!data) return <></>;
  const validated = data!.isValidated;
  return (
    <Box sx={{ alignItems: "center", display: "flex", ml: 0.5, mt: "2px" }}>
      <Chip
        color={validated ? "success" : "warning"}
        icon={<FontAwesomeIcon icon={validated ? faCheckCircle : faWarning} />}
        label={validated ? "Validated" : "Not validated"}
      />
    </Box>
  );
};

DataLabStatusLabel.displayName = "DataLabStatusLabel";

export default memo(DataLabStatusLabel);
