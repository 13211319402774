import { ApolloError } from "@apollo/client";
import { data_science } from "@decentriq/core";
import { type TestDataset } from "@decentriq/core/dist/session";
import {
  type ComputeJob,
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  CreateComputeJobDocument,
  type MutationRunSubmittedDataRoomRequestArgs,
  PublishedComputeNodeJobDocument,
} from "@decentriq/graphql/dist/types";
import * as forge from "node-forge";
import { type ApiCoreContextInterface } from "contexts";
import { parseDataRoomComputationError } from "utils";
import * as apicoreUtils from "utils/apicore";
import {
  getNodeById,
  maybeUseDataRoomSecret,
  parseErrorMessage,
} from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeRunSubmittedDataRoomRequestResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: null,
    args: MutationRunSubmittedDataRoomRequestArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<Partial<ComputeJob>> => {
    const {
      dcrHash,
      driverAttestationHash,
      computeNodeId,
      dataRoomId,
      commitId,
      autoFetching,
    } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dataRoomId);
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    const computeNode = getNodeById(context, computeNodeId, args);
    try {
      const commit = await wrapper.retrieveCommit(commitId);
      apicoreUtils.changeCommitExecutionPermissions(
        commit,
        sdkSession.metaData.email
      );
      const jobCommitId = await wrapper.createCommit(commit);
      let dataScienceJobId;
      const testDatasetsInput = args.input.testDatasets;
      const testing = !!testDatasetsInput?.length;
      if (testing) {
        const testDatasetsInput = args.input.testDatasets;
        const testDatasets = new Map<string, TestDataset>();
        for (const dataset of testDatasetsInput || []) {
          testDatasets.set(dataset.leafNodeId, {
            key: store.pop(dataset.encryptionKey)!,
            manifestHash: dataset.manifestHash,
          });
        }
        dataScienceJobId = await wrapper.createCommitJob(
          [computeNodeId],
          jobCommitId,
          {
            dryRun: {
              testDatasets,
            },
          }
        );
      } else {
        dataScienceJobId = await wrapper.createCommitJob(
          [computeNodeId],
          jobCommitId
        );
      }
      const encodedDataScienceJobHandle = forge.util.binary.base64.encode(
        new TextEncoder().encode(JSON.stringify(dataScienceJobId))
      );
      const result = await context.client.mutate({
        mutation: CreateComputeJobDocument,
        variables: {
          input: {
            computeNodeId: {
              published: {
                commitId,
                computeNodeId,
                publishedDataRoomId: dataRoomId,
              },
            },
            dataRoomHash: dcrHash,
            driverAttestationHash,
            enclaveComputeJobHandleBase64: encodedDataScienceJobHandle,
            enclaveComputeJobId: dataScienceJobId.jobId,
          },
        },
      });
      const computeJob = result.data?.computeJob.create.record;
      if (computeJob) {
        context.client.writeQuery({
          data: {
            publishedNode: {
              __typename: computeNode?.__typename,
              commitId,
              dcrHash,
              driverAttestationHash,
              id: computeNodeId,
              job: {
                __typename: "ComputeJob",
                autoFetching: autoFetching
                  ? testing
                    ? ComputeJobAutoFetching.TestResult
                    : ComputeJobAutoFetching.Result
                  : ComputeJobAutoFetching.None,
                createdAt: computeJob.createdAt,
                dataRoomHash: computeJob.dataRoomHash,
                driverAttestationHash: computeJob.driverAttestationHash,
                enclaveComputeJobId: computeJob.enclaveComputeJobId,
                id: computeJob.id,
                purpose: testing
                  ? ComputeJobPurpose.Test
                  : ComputeJobPurpose.Standard,
                status: computeJob.status,
              },
            },
          },
          query: PublishedComputeNodeJobDocument,
        });
        return computeJob;
      } else {
        throw new ApolloError({
          errorMessage: "Failed to create job",
          graphQLErrors: result.errors,
        });
      }
    } catch (error) {
      throw parseDataRoomComputationError(
        parseErrorMessage(context.cache, error, args),
        computeNode?.name
      );
    }
  };
