import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, ListItem, styled } from "@mui/material";
import isEqual from "lodash/isEqual";
import { memo } from "react";
import { DragHandleButton } from "components";

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  // TODO use this border radius instead when new theming available
  // borderRadius: theme.shape.borderRadius,
  borderRadius: 4,
  display: "flex",
  flex: 1,
  overflow: "hidden",
}));

interface ComputeNodesListItemProps extends React.PropsWithChildren {
  draggable?: boolean | { id: string };
}

const ComputeNodesListItem = memo<ComputeNodesListItemProps>(
  ({ draggable, children }) => {
    const { id } = draggable
      ? (draggable as { id: string })
      : { id: undefined };
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: id! });
    const style = {
      transform: CSS.Transform.toString(
        transform
          ? {
              ...transform,
              scaleY: 1,
            }
          : null
      ),
      transition: transition || undefined,
    };
    return draggable ? (
      <Box
        mb={1}
        ref={setNodeRef}
        style={style}
        sx={{ alignItems: "center", display: "flex" }}
      >
        <DragHandleButton {...attributes} {...listeners} />
        <StyledListItem
          sx={{
            backgroundColor: "common.white",
            border: "1px solid",
            borderColor: "divider",
            p: 1,
          }}
        >
          {children}
        </StyledListItem>
      </Box>
    ) : (
      <StyledListItem
        sx={{
          backgroundColor: "common.white",
          border: "1px solid",
          borderColor: "divider",
          mb: 1,
          p: 1,
        }}
      >
        {children}
      </StyledListItem>
    );
  },
  isEqual
);

ComputeNodesListItem.displayName = "ComputeNodesListItem";

export default ComputeNodesListItem;
