import { Box } from "@mui/material";
import { memo, useMemo } from "react";
import {
  type AggregationData,
  MediaDataRoomInsightsTable,
  useMediaDataRoomOverlapInsights,
} from "features/MediaInsightsDcr";

type MediaDataRoomInsightsComparisonItemTableProps = {};

const MediaDataRoomInsightsComparisonItemTable: React.FC<
  MediaDataRoomInsightsComparisonItemTableProps
> = () => {
  const { aggregationData, isEmptyAggregation } =
    useMediaDataRoomOverlapInsights();

  const segmentAggregationTableData: Partial<AggregationData>[] = useMemo(
    () =>
      aggregationData.map(({ affinityRatio, interest, gender, age }) => ({
        affinityRatio,
        age,
        gender,
        interest,
      })),
    [aggregationData]
  );

  return (
    <Box sx={{ flex: 1, height: 350 }}>
      <MediaDataRoomInsightsTable
        isEmpty={isEmptyAggregation}
        tableData={segmentAggregationTableData}
      />
    </Box>
  );
};

MediaDataRoomInsightsComparisonItemTable.displayName =
  "MediaDataRoomInsightsComparisonItemTable";

export default memo(MediaDataRoomInsightsComparisonItemTable);
