import { useOrganizationDetailsQuery } from "@decentriq/graphql/dist/hooks";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { memo } from "react";

type OrganizationDetailsProps = {
  organizationId: string;
};

const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
  organizationId,
}) => {
  const { data: organizationDetailsData } = useOrganizationDetailsQuery({
    variables: {
      organizationId,
    },
  });
  const { createdAt, createdBy, updatedAt } =
    organizationDetailsData?.organization || {};

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography>
        <strong>Created at:</strong>{" "}
        {createdAt ? format(new Date(createdAt), "dd.MM.yyy, HH:mm") : "–"}
      </Typography>
      <Typography>
        <strong>Updated at:</strong>{" "}
        {createdAt ? format(new Date(updatedAt), "dd.MM.yyy, HH:mm") : "–"}
      </Typography>
      <Typography>
        <strong>Created by:</strong> {createdBy?.email || "–"}
      </Typography>
    </Box>
  );
};

OrganizationDetails.displayName = "OrganizationDetails";

export default memo(OrganizationDetails);
