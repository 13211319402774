import { Typography } from "@mui/material";
import { memo } from "react";
import useGoogleCloudStorageConnectionConfigurationData from "./useGoogleCloudStorageConfigurationData";

type GoogleCloudStorageConnectionConfigurationProps = {
  connectionId: string;
};

const GoogleCloudStorageConnectionConfiguration: React.FC<
  GoogleCloudStorageConnectionConfigurationProps
> = ({ connectionId }) => {
  const { bucketName, objectName } =
    useGoogleCloudStorageConnectionConfigurationData(connectionId);

  return (
    <div>
      <Typography variant="body2">
        <strong>Bucket name:</strong> {bucketName}
      </Typography>
      <Typography variant="body2">
        <strong>Object name:</strong> {objectName}
      </Typography>
    </div>
  );
};

GoogleCloudStorageConnectionConfiguration.displayName =
  "GoogleCloudStorageConnectionConfiguration";

export default memo(GoogleCloudStorageConnectionConfiguration);
