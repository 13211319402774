import { testIds } from "@decentriq/utils";
import { Grid } from "@mui/material";
import { memo } from "react";
import {
  ConfigurationFormNameField,
  DisplayConfigurationActiveMarketsField,
  DisplayConfigurationCollaborativeUsersField,
  DisplayConfigurationDescriptionField,
  DisplayConfigurationLogoField,
} from "features/mediaPortalShared";
import { useDataPartnerPortal } from "../../contexts/DataPartnerPortalContext/DataPartnerPortalContext";
import { type DataPartnerDisplayConfigurationFormValues } from "./model";

const DataPartnerDisplayConfigurationForm: React.FC = () => {
  const { dataPartnerMarkets, organizationUsers } = useDataPartnerPortal();
  return (
    <Grid columnSpacing={3} container={true} rowSpacing={2}>
      <Grid container={true} item={true} justifyContent="center" xs={12}>
        <DisplayConfigurationLogoField<DataPartnerDisplayConfigurationFormValues>
          fieldName="logo"
          testId={
            testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
              .logoHelper
          }
        />
      </Grid>
      <Grid container={true} item={true} xs={12}>
        <ConfigurationFormNameField<DataPartnerDisplayConfigurationFormValues>
          fieldName="name"
          placeholder="Enter name"
          testId={
            testIds.dataPartnerPortal.upsertDisplayConfigurationDialog.name
          }
          title="Name"
        />
      </Grid>
      <Grid container={true} item={true} xs={6}>
        <DisplayConfigurationCollaborativeUsersField<DataPartnerDisplayConfigurationFormValues>
          fieldName="collaborationRequestUsers"
          optionTestId={
            testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
              .collaborativeUsersSelectorOptionHelper
          }
          organizationUsers={organizationUsers}
          testId={
            testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
              .collaborativeUsersSelector
          }
        />
      </Grid>
      <Grid container={true} item={true} xs={6}>
        <DisplayConfigurationActiveMarketsField<DataPartnerDisplayConfigurationFormValues>
          activeMarkets={dataPartnerMarkets}
          fieldName="marketIds"
          optionTestId={
            testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
              .activeMarketsSelectorOptionHelper
          }
          testId={
            testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
              .activeMarketsSelector
          }
          tooltip="This is a list of all markets you operate in. Brands can search for data partners in the markets they are interested in and will be able to find and activate with you."
        />
      </Grid>
      <Grid container={true} item={true} xs={12}>
        <DisplayConfigurationDescriptionField<DataPartnerDisplayConfigurationFormValues>
          fieldName="description"
          placeholder="Enter description"
          testId={
            testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
              .description
          }
          title="Description"
        />
      </Grid>
    </Grid>
  );
};

DataPartnerDisplayConfigurationForm.displayName =
  "DataPartnerDisplayConfigurationForm";

export default memo(DataPartnerDisplayConfigurationForm);
