import { memo } from "react";
import { DatasetSchema as DatasetSchemaComponent } from "features/datasets";
import {
  type DatasetSchema,
  mapSchemaToUnifiedSchemaPresentation,
} from "utils/dataset";

type DatasetSchemaTabPanelProps = {
  schema: DatasetSchema;
};

const DatasetSchemaTabPanel: React.FC<DatasetSchemaTabPanelProps> = ({
  schema,
}) => (
  <DatasetSchemaComponent
    schema={mapSchemaToUnifiedSchemaPresentation(schema)}
  />
);

DatasetSchemaTabPanel.displayName = "DatasetSchemaTabPanel";

export default memo(DatasetSchemaTabPanel);
