import { testIds } from "@decentriq/utils";
import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import UpsertDataPartnerDisplayConfigurationDialog from "../UpsertDataPartnerDisplayConfigurationDialog/UpsertDataPartnerDisplayConfigurationDialog";

const CreateDataPartnerDisplayConfigurationButton: React.FC = () => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  return (
    <>
      <Button
        color="primary"
        data-testid={
          testIds.dataPartnerPortal.displayConfiguration.createButton
        }
        onClick={openDialog}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faPlusRegular} />}
        variant="contained"
      >
        Display configuration
      </Button>
      <UpsertDataPartnerDisplayConfigurationDialog
        onClose={closeDialog}
        open={isDialogOpen}
      />
    </>
  );
};

CreateDataPartnerDisplayConfigurationButton.displayName =
  "CreateDataPartnerDisplayConfigurationButton";

export default memo(CreateDataPartnerDisplayConfigurationButton);
