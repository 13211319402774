import {
  faChevronDown as faChevronDownRegular,
  faUserPlus as faUserPlusRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Popover } from "@mui/material";
import { useBoolean } from "ahooks";
import { Fragment, memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { AddDemoUserDialog, AddOrganizationUserDialog } from "components";
import { useIsDecentriqOrganization, usePopupState } from "hooks";

const UsersAddButton: React.FC = () => {
  const { organizationId } = useParams();
  const isDecentriqOrganization = useIsDecentriqOrganization(organizationId!);

  // Add organization user dialog
  const [
    isAddOrganizationUserDialogOpen,
    {
      setTrue: openAddOrganizationUserDialog,
      setFalse: closeAddOrganizationUserDialog,
    },
  ] = useBoolean(false);

  // Add demo user (available only for Super Admins)
  const {
    anchorEl: anchorElAddDemoUserButton,
    isOpen: isAddDemoUserPopoverOpen,
    open: openAddDemoUserPopover,
    close: closeAddDemoUserPopover,
  } = usePopupState({
    popupId: "addDemoUser",
    variant: "popover",
  });
  const [
    isAddDemoUserDialogOpen,
    { setTrue: openAddDemoUserDialog, setFalse: closeAddDemoUserDialog },
  ] = useBoolean(false);

  const handleOpenAddDemoUserDialog = useCallback(() => {
    openAddDemoUserDialog();
    closeAddDemoUserPopover();
  }, [closeAddDemoUserPopover, openAddDemoUserDialog]);

  return (
    <Fragment>
      <ButtonGroup disableElevation={true} sx={{ m: 1 }}>
        <Button
          color="primary"
          onClick={openAddOrganizationUserDialog}
          startIcon={
            <FontAwesomeIcon fixedWidth={true} icon={faUserPlusRegular} />
          }
          variant="contained"
        >
          Add organization user
        </Button>
        {isDecentriqOrganization && (
          <Fragment>
            <Button
              color="primary"
              onClick={openAddDemoUserPopover}
              variant="tonal"
            >
              <FontAwesomeIcon fixedWidth={true} icon={faChevronDownRegular} />
            </Button>
            <Popover
              anchorEl={anchorElAddDemoUserButton}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              marginThreshold={8}
              onClose={closeAddDemoUserPopover}
              open={isAddDemoUserPopoverOpen}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
            >
              <Button
                color="inherit"
                onClick={handleOpenAddDemoUserDialog}
                variant="text"
              >
                Add demo user
              </Button>
            </Popover>
          </Fragment>
        )}
      </ButtonGroup>
      <AddOrganizationUserDialog
        onCancel={closeAddOrganizationUserDialog}
        open={isAddOrganizationUserDialogOpen}
        organizationId={organizationId!}
      />
      <AddDemoUserDialog
        onCancel={closeAddDemoUserDialog}
        open={isAddDemoUserDialogOpen}
        organizationId={organizationId!}
      />
    </Fragment>
  );
};

UsersAddButton.displayName = "UsersAddButton";

export default memo(UsersAddButton);
