import { DqTable } from "@decentriq/components";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import {
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import { useState } from "react";
import { EmptyData, TimeAgoFormatted } from "components";
import { DataLabDetails, DataLabDetailsToolbar } from "features/dataLabs";
import { type DataLab } from "features/dataLabs/models";
import { DataLabWrapper, useDataLabsContext } from "../../contexts";

const dataLabsColumnDef: MRT_ColumnDef<DataLab>[] = [
  {
    Cell: ({ cell }) => {
      return (
        <Typography
          component="span"
          fontWeight="fontWeightSemiBold"
          noWrap={true}
          sx={{ flex: 1 }}
          variant="body2"
        >
          {cell.getValue<string>()}
        </Typography>
      );
    },
    accessorKey: "name",
    header: "Name",
    id: "name",
  },
  {
    Cell: ({ cell }) => {
      const createdAt = cell.getValue<string>();
      return createdAt ? <TimeAgoFormatted date={createdAt} /> : "—";
    },
    accessorKey: "updatedAt",
    header: "Last modified",
    id: "updatedAt",
  },
];

const DataLabsList: React.FC = () => {
  const {
    dataLabs: { data: dataLabs = [], loading },
  } = useDataLabsContext();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const selectedDataLabs = Object.keys(rowSelection)
    .filter((key) => rowSelection[key])
    .map((key) => dataLabs.find(({ id }) => id === key));
  const totalCount = dataLabs.length;
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        left="0"
        position="absolute"
        top="0"
        width="100%"
      >
        <CircularProgress color="inherit" size="2.5rem" thickness={1} />
      </Box>
    );
  }
  if (!loading && !totalCount) {
    return (
      <EmptyData
        primaryText="Datalabs"
        secondaryText="You haven't created any datalabs yet"
      />
    );
  }
  return (
    <Box
      sx={{
        alignItems: "stretch",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      <Grid
        columnSpacing={0}
        container={true}
        sx={{ flex: 1, overflow: "hidden" }}
      >
        <Grid
          item={true}
          sx={{
            borderColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            height: "100%",
            overflow: "hidden",
          }}
          xs={6}
        >
          <DqTable
            autoSelectFirstRow={true}
            columns={dataLabsColumnDef}
            data={dataLabs}
            enableBatchRowSelection={false}
            enableGlobalFilter={true}
            enableMultiRowSelection={false}
            enableRowSelection={true}
            enableSelectAll={false}
            enableSorting={true}
            enableTopToolbar={true}
            getRowId={(row) => row.id}
            initialState={{
              showGlobalFilter: true,
              sorting: [{ desc: true, id: "updatedAt" }],
            }}
            localization={{
              noRecordsToDisplay: "No datalabs found",
            }}
            muiSearchTextFieldProps={{
              InputProps: {
                sx: {
                  margin: 1,
                  minWidth: "260px",
                },
              },
              placeholder: "Search datalabs",
              size: "medium",
              variant: "standard",
            }}
            muiTableBodyRowProps={({
              row: { getToggleSelectedHandler, getIsSelected },
            }) => {
              return {
                onClick: getIsSelected()
                  ? undefined
                  : getToggleSelectedHandler(),
                sx: {
                  "&:hover td:after": {
                    backgroundColor: "primary.light",
                    content: '""',
                    height: "100%",
                    left: 0,
                    opacity: 0.125,
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    zIndex: -1,
                  },
                  cursor: "pointer",
                },
              };
            }}
            muiTablePaperProps={{
              sx: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
                width: "100%",
              },
            }}
            muiTopToolbarProps={{
              sx: (theme) => ({
                flex: "0 0 auto",
                minHeight: theme.spacing(8),
                // eslint-disable-next-line sort-keys-fix/sort-keys-fix
                borderBottom: "1px solid",
                borderColor: "divider",
              }),
            }}
            onRowSelectionChange={setRowSelection}
            state={{
              columnVisibility: { "mrt-row-select": false },
              rowSelection,
            }}
          />
        </Grid>
        {!loading ? (
          <Grid item={true} sx={{ height: "100%", overflow: "hidden" }} xs={6}>
            {selectedDataLabs.map((selectedDataLab) =>
              selectedDataLab ? (
                <Box
                  key={selectedDataLab.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    height: "100%",
                    overflow: "hidden",
                    p: 2,
                  }}
                >
                  <DataLabWrapper id={selectedDataLab.id}>
                    <DataLabDetailsToolbar id={selectedDataLab.id} />
                    <DataLabDetails id={selectedDataLab.id} />
                  </DataLabWrapper>
                </Box>
              ) : null
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default DataLabsList;
