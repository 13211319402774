import { type Mutex } from "async-mutex";
import { type ApiCoreContextInterface, type KeychainService } from "contexts";
import {
  makeMediaActivateConsentlessAudienceResolver,
  makeMediaActivateDirectAudienceResolver,
  makeMediaDeleteConsentlessAudienceResolver,
  makeMediaDownloadActivatedConsentlessAudienceResolver,
  makeMediaDownloadActivatedDirectAudienceResolver,
  makeMediaGenerateConsentlessAudienceResolver,
  makeMediaPublishDraftDataRoomResolver,
  makeMediaPublishPublisherDatasetResolver,
  makeMediaStoreActivatedDirectAudiencesAsDatasetResolver,
  makeMediaUnpublishAdvertiserDatasetResolver,
  makeMediaUnpublishPublisherDatasetResolver,
  makePublishMediaAdvertiserDatasetResolver,
} from "../media";
import { makeApproveSubmittedDataRoomRequestResolver } from "./approveSubmittedDataRoomRequest";
import { makeDeleteDatasetResolver } from "./deleteDataset";
import {
  makeCreateDatasetExportResolver,
  makePollDatasetExportResolver,
} from "./exportDataset";
import {
  makeCreateDatasetImportResolver,
  makePollDatasetImportResolver,
} from "./importDataset";
import { makeMergeSubmittedDataRoomRequestResolver } from "./mergeSubmittedDataRoomRequest";
import { makePollComputeJobResolver } from "./pollComputeJob";
import { makePublishDatasetResolver } from "./publishDataset";
import { makePublishDraftDataRoomResolver } from "./publishDraftDataRoom";
import { makeRebaseSubmittedDataRoomRequestResolver } from "./rebaseSubmittedDataRoomRequest";
import { makeRetrieveComputeJobResultResolver } from "./retrieveComputeJobResult";
import { makeRetrieveComputeJobResultFileAsCsvResolver } from "./retrieveComputeJobResultFileAsCsv";
import { makeRetrieveComputeJobResultFileAsZipResolver } from "./retrieveComputeJobResultFileAsZip";
import { makeRetrieveComputeJobResultSizeResolver } from "./retrieveComputeJobResultSize";
import { makeRetrieveDraftComputationFormatResolver } from "./retrieveDraftComputationFormat";
import { makeRetrieveJobStatusResolver } from "./retrieveJobStatus";
import { makeRetrievePublishedComputationFormatResolver } from "./retrievePublishedComputationFormat";
import { makeRetrievePublishedDatasetsResolver } from "./retrievePublishedDatasets";
import { makeRunComputationResolver } from "./runComputation";
import { makeRunDevComputationResolver } from "./runDevComputation";
import { makeRunSubmittedDataRoomRequestResolver } from "./runSubmittedDataRoomRequest";
import { makeSignSubmittedDataRoomRequestResolver } from "./signSubmittedDataRoomRequest";
import { makeStopDataRoomResolver } from "./stopDataRoom";
import { makeSubmitDataRoomRequestResolver } from "./submitDataRoomRequest";
import { makeTestAllComputationsResolver } from "./testAllComputations";
import { makeDraftTestComputationResolver } from "./testDraftComputation";
import { makeUnlockDataRoomResolver } from "./unlockDataRoom";
import { makeUnpublishDatasetResolver } from "./unpublishDataset";
import { makeValidateDataRoomRequestResolver } from "./validateDataRoomRequest";

export const makeMutationResolvers = (
  client: ApiCoreContextInterface["client"],
  sessionManager: ApiCoreContextInterface["sessionManager"],
  store: ApiCoreContextInterface["store"],
  mutexMap: Map<string, Mutex>,
  keychain: KeychainService
) => ({
  approveSubmittedDataRoomRequest: makeApproveSubmittedDataRoomRequestResolver(
    client,
    sessionManager,
    store
  ),
  createDatasetExport: makeCreateDatasetExportResolver(
    client,
    sessionManager,
    keychain
  ),
  createDatasetImport: makeCreateDatasetImportResolver(
    client,
    sessionManager,
    keychain
  ),
  deleteDataset: makeDeleteDatasetResolver(client, sessionManager, store),
  lookalikeMedia: () => {
    return {
      __typename: "LookalikeMediaMutations",
    };
  },
  mediaActivateConsentlessAudience:
    makeMediaActivateConsentlessAudienceResolver(client, sessionManager, store),
  mediaActivateDirectAudience: makeMediaActivateDirectAudienceResolver(
    client,
    sessionManager,
    store
  ),
  mediaDeleteConsentlessAudience: makeMediaDeleteConsentlessAudienceResolver(
    client,
    sessionManager,
    store
  ),
  mediaDownloadActivatedConsentlessAudience:
    makeMediaDownloadActivatedConsentlessAudienceResolver(
      client,
      sessionManager,
      store
    ),
  mediaDownloadActivatedDirectAudience:
    makeMediaDownloadActivatedDirectAudienceResolver(
      client,
      sessionManager,
      store
    ),
  mediaGenerateConsentlessAudience:
    makeMediaGenerateConsentlessAudienceResolver(client, sessionManager, store),
  mediaPublishAdvertiserDataset: makePublishMediaAdvertiserDatasetResolver(
    client,
    sessionManager,
    store
  ),
  mediaPublishDraftDataRoom: makeMediaPublishDraftDataRoomResolver(
    client,
    sessionManager,
    store
  ),
  mediaPublishPublisherDataset: makeMediaPublishPublisherDatasetResolver(
    client,
    sessionManager,
    store
  ),
  mediaUnpublishAdvertiserDataset: makeMediaUnpublishAdvertiserDatasetResolver(
    client,
    sessionManager,
    store
  ),
  mediaUnpublishPublisherDataset: makeMediaUnpublishPublisherDatasetResolver(
    client,
    sessionManager,
    store
  ),
  mergeSubmittedDataRoomRequest: makeMergeSubmittedDataRoomRequestResolver(
    client,
    sessionManager,
    store,
    mutexMap
  ),

  pollComputeJob: makePollComputeJobResolver(client, sessionManager, store),
  pollDatasetExport: makePollDatasetExportResolver(
    client,
    sessionManager,
    keychain
  ),
  pollDatasetImport: makePollDatasetImportResolver(
    client,
    sessionManager,
    keychain
  ),
  publishDataset: makePublishDatasetResolver(client, sessionManager, store),
  publishDraftDataRoom: makePublishDraftDataRoomResolver(
    client,
    sessionManager,
    store
  ),
  rebaseSubmittedDataRoomRequest: makeRebaseSubmittedDataRoomRequestResolver(
    client,
    sessionManager,
    store,
    mutexMap
  ),
  retrieveComputeJobResult: makeRetrieveComputeJobResultResolver(
    client,
    sessionManager,
    store
  ),
  retrieveComputeJobResultFileAsCsv:
    makeRetrieveComputeJobResultFileAsCsvResolver(
      client,
      sessionManager,
      store
    ),
  retrieveComputeJobResultFileAsZip:
    makeRetrieveComputeJobResultFileAsZipResolver(
      client,
      sessionManager,
      store
    ),
  retrieveComputeJobResultSize: makeRetrieveComputeJobResultSizeResolver(
    client,
    sessionManager,
    store
  ),
  retrieveDraftComputationFormat: makeRetrieveDraftComputationFormatResolver(
    client,
    sessionManager,
    store
  ),
  retrieveJobStatus: makeRetrieveJobStatusResolver(
    client,
    sessionManager,
    store
  ),
  retrievePublishedComputationFormat:
    makeRetrievePublishedComputationFormatResolver(
      client,
      sessionManager,
      store
    ),
  retrievePublishedDatasets: makeRetrievePublishedDatasetsResolver(
    client,
    sessionManager,
    store
  ),
  runComputation: makeRunComputationResolver(client, sessionManager, store),
  runDevComputation: makeRunDevComputationResolver(
    client,
    sessionManager,
    store
  ),
  runSubmittedDataRoomRequest: makeRunSubmittedDataRoomRequestResolver(
    client,
    sessionManager,
    store
  ),
  signSubmittedDataRoomRequest: makeSignSubmittedDataRoomRequestResolver(
    client,
    sessionManager,
    store
  ),
  stopDataRoom: makeStopDataRoomResolver(client, sessionManager, store),
  storeActivatedDirectAudienceAsDataset:
    makeMediaStoreActivatedDirectAudiencesAsDatasetResolver(
      client,
      sessionManager,
      store
    ),
  submitDataRoomRequest: makeSubmitDataRoomRequestResolver(
    client,
    sessionManager,
    store,
    mutexMap
  ),
  testAllComputations: makeTestAllComputationsResolver(
    client,
    sessionManager,
    store
  ),
  testDraftComputation: makeDraftTestComputationResolver(
    client,
    sessionManager,
    store
  ),
  unlockDataRoom: makeUnlockDataRoomResolver(client, sessionManager, store),
  unpublishDataset: makeUnpublishDatasetResolver(client, sessionManager, store),
  validateDataRoomRequest: makeValidateDataRoomRequestResolver(
    client,
    sessionManager,
    store
  ),
});
