import { Box, Grid } from "@mui/material";
import { memo } from "react";
import {
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  OverlapStatistics,
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import {
  AffinityRatioAgeBarChart,
  AffinityRatioSegmentBarChart,
  GenderDonutChart,
} from "./components";

const MediaDataRoomInsightsBasicView: React.FC = () => {
  const { publisherDatasetsHashes: { demographicsDatasetHash } = {} } =
    useMediaInsightsDcrData();
  const {
    supportedFeatures: { hideAbsoluteValues = false },
  } = usePublishedMediaInsightsDcr();

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Grid columnSpacing={4} container={true} flex={1} item={true} mb={1}>
        <Grid container={true} item={true} md={4} xs={12}>
          <MediaDataRoomInsightsAdvertiserAudienceSelector />
        </Grid>
        {(demographicsDatasetHash || !hideAbsoluteValues) && (
          <Grid columnSpacing={3} container={true} item={true} xs={12}>
            {demographicsDatasetHash && (
              <Grid
                container={true}
                item={true}
                maxHeight={500}
                mb={2}
                md={hideAbsoluteValues ? 12 : 6}
                minHeight={{ xl: 300, xs: 220 }}
                xs={12}
              >
                <GenderDonutChart />
              </Grid>
            )}
            {!hideAbsoluteValues && (
              <Grid
                container={true}
                item={true}
                mb={2}
                md={demographicsDatasetHash ? 6 : 12}
                xs={12}
              >
                <OverlapStatistics />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Grid columnSpacing={4} container={true} flex={2} item={true}>
        <Grid
          item={true}
          md={demographicsDatasetHash ? 6 : 12}
          minHeight={200}
          xs={12}
        >
          <AffinityRatioSegmentBarChart
            aggregation={["interest"]}
            segmentsThreshold={12}
          />
        </Grid>
        {demographicsDatasetHash && (
          <Grid
            item={true}
            md={6}
            minHeight={180}
            mt={{ md: 0, xs: 4 }}
            xs={12}
          >
            <AffinityRatioAgeBarChart />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

MediaDataRoomInsightsBasicView.displayName = "MediaDataRoomInsightsBasicView";

export default memo(MediaDataRoomInsightsBasicView);
