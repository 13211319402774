import { TextField } from "@mui/material";
import { isEmpty } from "lodash";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const GoogleCloudStorageFormBucketField = () => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="configuration.bucketName"
      render={({ field, formState }) => {
        const { errors } = formState;
        const fieldError = errors?.configuration?.bucketName;
        return (
          <TextField
            InputProps={{
              sx: {
                "& .MuiInput-input": { padding: "7px 0" },
                "&:before": { borderBottomStyle: "solid" },
              },
            }}
            error={!isEmpty(fieldError)}
            fullWidth={true}
            helperText={fieldError?.message}
            label="Bucket name"
            placeholder="Example: my-bucket-name"
            size="small"
            sx={{ mb: 1 }}
            variant="standard"
            {...field}
          />
        );
      }}
    />
  );
};

GoogleCloudStorageFormBucketField.displayName =
  "GoogleCloudStorageFormBucketField";

export default memo(GoogleCloudStorageFormBucketField);
