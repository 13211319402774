import { DqTable, InfoTooltip } from "@decentriq/components";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useMemo } from "react";
import { Chip } from "components";
import { EmptyStateContainer } from "features/mediaDataRoomV2";
import { useLookalikeMediaOverlapInsights } from "features/mediaDataRoomV2/components/LookalikeMediaInsights/hooks";
import { type AggregationData } from "features/mediaDataRoomV2/models";

type LookalikeMediaInsightsTableProps = {};

const LookalikeMediaInsightsTable: React.FC<
  LookalikeMediaInsightsTableProps
> = () => {
  const { aggregationData: tableData, isEmpty } =
    useLookalikeMediaOverlapInsights();

  const formatter = Intl.NumberFormat("de-DE");

  const withInterestColumn: boolean = useMemo(
    () => tableData.some(({ interest }: AggregationData) => !!interest),
    [tableData]
  );
  const withAgeColumn: boolean = useMemo(
    () => tableData.some(({ age }: AggregationData) => !!age),
    [tableData]
  );
  const withGenderColumn: boolean = useMemo(
    () => tableData.some(({ gender }: AggregationData) => !!gender),
    [tableData]
  );

  const overlapInsightsColumns = useMemo<
    MRT_ColumnDef<Partial<AggregationData>>[]
  >(
    () => [
      ...(withInterestColumn
        ? ([
            {
              accessorKey: "interest",
              header: "Segment",
              id: "interest",
            },
          ] as MRT_ColumnDef<Partial<AggregationData>>[])
        : []),
      ...(withAgeColumn
        ? ([
            {
              accessorKey: "age",
              header: "Age",
              id: "age",
            },
          ] as MRT_ColumnDef<Partial<AggregationData>>[])
        : []),
      ...(withGenderColumn
        ? ([
            {
              accessorKey: "gender",
              header: "Gender",
              id: "gender",
            },
          ] as MRT_ColumnDef<Partial<AggregationData>>[])
        : []),
      {
        Cell: ({ cell }) => {
          const affinityRatioValue = cell.getValue() as
            | number
            | null
            | undefined;
          if (typeof affinityRatioValue !== "number") {
            return <span style={{ marginLeft: "4px" }}>N/A</span>;
          }
          const affinityRatio = `${affinityRatioValue.toFixed(1)}x`;
          return affinityRatioValue > 1 ? (
            <Chip
              label={affinityRatio}
              sx={(theme) => ({
                "& .MuiChip-label": {
                  color: theme.palette.primary.main,
                  padding: "0 8px",
                },
                background: "rgba(40, 144, 144, 0.08)",
              })}
            />
          ) : (
            <span style={{ marginLeft: "4px" }}>{affinityRatio}</span>
          );
        },
        Header: ({ column }) => (
          <div>
            {column.columnDef.header}
            <InfoTooltip tooltip="This is the ratio “Share in overlap” / “Share in publisher audience”. The higher this value, the more likely it is that a person in this segment is interested in the Advertiser’s product compared to a random person in the addressable publisher audience." />
          </div>
        ),
        accessorKey: "affinityRatio",
        header: "Affinity",
        id: "affinityRatio",
      },
      {
        Cell: ({ cell }) => {
          const shareInOverlapValue = cell.getValue() as
            | number
            | undefined
            | null;
          if (typeof shareInOverlapValue !== "number") {
            return "N/A";
          }
          return `${(shareInOverlapValue * 100).toFixed(1)}%`;
        },
        Header: ({ column }) => (
          <div>
            {column.columnDef.header}
            <InfoTooltip tooltip="This is the share of individuals as a fraction of all individuals in the overlap." />
          </div>
        ),
        accessorKey: "shareInOverlap",
        header: "Share in overlap",
        id: "shareInOverlap",
      },
      {
        Cell: ({ cell }) => {
          const shareInAddressableAudienceValue = cell.getValue() as
            | number
            | undefined
            | null;
          if (typeof shareInAddressableAudienceValue !== "number") {
            return "N/A";
          }
          return `${(shareInAddressableAudienceValue * 100).toFixed(1)}%`;
        },
        Header: ({ column }) => (
          <div>
            {column.columnDef.header}
            <InfoTooltip tooltip="This is the share of individuals as a fraction of all individuals in the addressable publisher audience." />
          </div>
        ),
        accessorKey: "shareInAddressableAudience",
        header: "Share in publisher audience",
        id: "shareInAddressableAudience",
        minSize: 250,
      },
      {
        Cell: ({ cell }) => {
          const addressableAudienceSizeValue = cell.getValue() as number;
          const roundedAddressableAudienceSizeValue =
            Math.round(addressableAudienceSizeValue / 10) * 10;
          return `~${formatter.format(roundedAddressableAudienceSizeValue)}`;
        },
        Header: ({ column }) => (
          <div>
            {column.columnDef.header}
            <InfoTooltip
              tooltip={
                <span>
                  This is the amount of people that the publisher is able to
                  address in this segment.
                  <br />
                  <br />
                  We round these numbers and add a small amount of noise to
                  these totals to protect individual privacy. We sample from a
                  Laplacian Mechanism, with a scale factor of 10. This means
                  that there is a 63% chance of adding noise between -10 and 10
                  to any given count of users. The chance of adding more noise
                  falls off rapidly, there is less than a 2% chance that the
                  noise is larger than 40.
                </span>
              }
            />
          </div>
        ),
        accessorKey: "addressableAudienceSize",
        header: "Addressable users in publisher audience",
        id: "addressableAudienceSize",
        minSize: 250,
      },
    ],
    [withInterestColumn, withAgeColumn, withGenderColumn, formatter]
  );

  if (isEmpty) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography mt={1} variant="subtitle2">
          All segments are too small to be displayed.
        </Typography>
        <Typography variant="subtitle2">
          Segments which are too small are not displayed for privacy.
        </Typography>
      </EmptyStateContainer>
    );
  }

  return (
    <DqTable
      columns={overlapInsightsColumns}
      data={tableData}
      initialState={{
        // https://github.com/KevinVandy/material-react-table/issues/143
        columnOrder: [
          "interest",
          "age",
          "gender",
          "affinityRatio",
          "shareInOverlap",
          "shareInAddressableAudience",
          "addressableAudienceSize",
        ],
      }}
      localization={{
        noRecordsToDisplay: "The selected Advertiser audience is too small",
      }}
      muiTablePaperProps={{
        sx: { flex: 3 },
      }}
    />
  );
};

LookalikeMediaInsightsTable.displayName = "LookalikeMediaInsightsTable";

export default memo(LookalikeMediaInsightsTable);
