import { type AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageKeychainKeyId } from "services/keychain";
import { Loading as Auth0Loading, UnauthorizedDialog } from "components";
import { useConfiguration } from "contexts";

const Auth0Error = memo<{ message?: string }>(
  ({ message = "Unknown error" }) => {
    const { logout } = useAuth0();
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <UnauthorizedDialog
          error={message}
          onClose={() => {
            localStorage.removeItem(localStorageKeychainKeyId);
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
          open={true}
        />
      </Box>
    );
  }
);

Auth0Error.displayName = "Auth0Error";

const Auth0Consumer = memo<React.PropsWithChildren>(({ children }) => {
  const { error, isLoading } = useAuth0();
  return error ? (
    <Auth0Error message={error?.message} />
  ) : isLoading ? (
    <Auth0Loading />
  ) : (
    children
  );
});
Auth0Consumer.displayName = "Auth0Consumer";

const Auth0Wrapper = memo<React.PropsWithChildren>(({ children }) => {
  const {
    configuration: { auth0Audience, auth0ClientId, auth0Domain },
  } = useConfiguration();
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };
  return (
    <Auth0Provider
      authorizationParams={{
        audience: auth0Audience,
        redirect_uri: window.location.origin,
      }}
      cacheLocation="memory"
      clientId={auth0ClientId}
      domain={auth0Domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <Auth0Consumer>{children}</Auth0Consumer>
    </Auth0Provider>
  );
});
Auth0Wrapper.displayName = "Auth0Wrapper";

export default Auth0Wrapper;
