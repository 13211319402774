import { type Key } from "@decentriq/utils";
import { useCallback } from "react";
import useCommonDataNodeActions from "features/dataNodes/hooks/useCommonDataNodeActions";
import { useDeleteDataset } from "features/datasets";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useMediaInsightsDcrState } from "../../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";

interface AdvertiserDataNodeHookPayload {
  datasetHash: string;
  driverAttestationHash: string;
  retrieveDatasets: () => Promise<void>;
}

const useAdvertiserDataNodeActions = ({
  datasetHash,
  retrieveDatasets,
}: AdvertiserDataNodeHookPayload) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { publishAdvertiserDataset, unpublishAdvertiserDataset } =
    useMediaInsightsDcrState();
  const [deleteDataset] = useDeleteDataset({
    manifestHash: datasetHash,
  });
  const onDatasetDeprovision = useCallback(async () => {
    await unpublishAdvertiserDataset()
      .then(() => {
        enqueueSnackbar("Your data has been deprovisioned.");
      })
      .catch((error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(error, `Can't deprovision data`)
        );
      });
    await retrieveDatasets();
  }, [unpublishAdvertiserDataset, retrieveDatasets, enqueueSnackbar]);
  const onDatasetDelete = useCallback(async () => {
    if (!(await deleteDataset())) {
      return;
    }
    await unpublishAdvertiserDataset()
      .then(() => {
        enqueueSnackbar("Your data has been deleted.");
      })
      .catch((error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(error, "Can't delete data")
        );
      });
    await retrieveDatasets();
  }, [
    deleteDataset,
    enqueueSnackbar,
    retrieveDatasets,
    unpublishAdvertiserDataset,
  ]);
  const onPublishAdvertiserDataset = useCallback(
    async ({ key, manifestHash }: { key: Key; manifestHash: string }) => {
      await publishAdvertiserDataset({
        dataset: {
          datasetHash: manifestHash,
          encryptionKey: key,
        },
      });
      await retrieveDatasets();
    },
    [publishAdvertiserDataset, retrieveDatasets]
  );
  const {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleDataDelete,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  } = useCommonDataNodeActions({
    onAfterIngestData: onPublishAdvertiserDataset,
    onConnectDataset: onPublishAdvertiserDataset,
    onDatasetDelete,
    onDatasetDeprovision,
  });
  return {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDelete,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  };
};

export default useAdvertiserDataNodeActions;
