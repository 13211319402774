import {
  useDownloadActivatedDirectAudienceMutation,
  useStoreActivatedDirectAudienceAsDatasetMutation,
} from "@decentriq/graphql/dist/hooks";
import { faDownload, faFileImport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Button, Popover, styled, Tooltip, useTheme } from "@mui/material";
import format from "date-fns/format";
import saveAs from "file-saver";
import { useSnackbar } from "notistack";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useApiCore, usePublishedMediaDataRoom } from "contexts";
import {
  mapMediaDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  usePopupState,
} from "hooks";

const DownloadButton = styled(Button)(({ theme: { spacing } }) => ({
  padding: spacing(0.5, 2),
}));

interface DirectActivationDownloadButtonProps {
  audienceId: string;
  audienceName: string;
}

const DirectActivationDownloadButton: React.FC<
  DirectActivationDownloadButtonProps
> = ({ audienceId, audienceName }) => {
  const { store } = useApiCore();
  const navigate = useNavigate();
  const theme = useTheme();

  const { advertiserOrganization, dcrHash, driverAttestationHash } =
    usePublishedMediaDataRoom();
  const { enqueueSnackbar: enqueueDataRoomSnackbar } = useDataRoomSnackbar();
  const { enqueueSnackbar } = useSnackbar();
  const {
    anchorEl: anchorElDownloadButtonButton,
    isOpen: isDownloadButtonPopoverOpen,
    open: openDownloadButtonPopover,
    close: closeDownloadButtonPopover,
  } = usePopupState({
    popupId: "Download",
    variant: "popover",
  });

  // Download Audience
  const [
    downloadActivatedDirectAudienceMutation,
    { loading: downloadingDirect },
  ] = useDownloadActivatedDirectAudienceMutation({
    onCompleted: (data) => {
      const audienceCsv =
        data?.mediaDownloadActivatedDirectAudience.audienceCsv;
      const fileName = `Advertiser_${advertiserOrganization?.name}_${audienceName}_${format(
        new Date(),
        "dd_MM_yyyy HH_mm"
      )}.csv`;
      if (audienceCsv) {
        const content = store.pop<string>(audienceCsv!);
        if (content) {
          const file = new File([content], fileName, {
            type: "application/octet-stream;charset=utf-8",
          });
          saveAs(file!);
        }
      }
    },
    onError: (error) => {
      enqueueDataRoomSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          "Audience could not be downloaded."
        )
      );
    },
    update: (cache, { data }) => {
      const id = data!.mediaDownloadActivatedDirectAudience.audienceType;
      cache.modify({
        fields: {
          downloaded: () => true,
        },
        id: cache.identify({
          __typename: "DirectAudience",
          name: id,
        }),
      });
    },
  });

  const downloadActivatedAudience = useCallback(() => {
    closeDownloadButtonPopover();
    downloadActivatedDirectAudienceMutation({
      variables: {
        input: {
          audienceType: audienceId,
          dataRoomId: dcrHash,
          driverAttestationHash,
        },
      },
    });
  }, [
    closeDownloadButtonPopover,
    downloadActivatedDirectAudienceMutation,
    audienceId,
    dcrHash,
    driverAttestationHash,
  ]);

  // Save Audience as dataset
  const [
    createDatasetImportMutation,
    { loading: isCreateDatasetImportLoading },
  ] = useStoreActivatedDirectAudienceAsDatasetMutation({
    onCompleted: () => {
      enqueueSnackbar({
        action: (
          <Button onClick={() => navigate("/datasets")}>Go to Datasets</Button>
        ),
        message: `${audienceName} result is being stored. Please check the status in the 'Datasets' page.`,
      });
    },
    onError: (error) => {
      enqueueDataRoomSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          `${audienceName} result could not be stored.`
        )
      );
    },
  });

  const saveActivatedAudienceAsDataset = useCallback(() => {
    closeDownloadButtonPopover();
    createDatasetImportMutation({
      variables: {
        input: {
          audienceType: audienceId,
          dataRoomId: dcrHash,
          driverAttestationHash,
        },
      },
    });
  }, [
    closeDownloadButtonPopover,
    createDatasetImportMutation,
    audienceId,
    dcrHash,
    driverAttestationHash,
  ]);

  return (
    <>
      <Tooltip
        disableFocusListener={true}
        disableTouchListener={true}
        placement="top"
        title="Download audience with the list of activation IDs"
      >
        <LoadingButton
          color="inherit"
          loading={downloadingDirect || isCreateDatasetImportLoading}
          loadingPosition="start"
          onClick={openDownloadButtonPopover}
          startIcon={<FontAwesomeIcon icon={faDownload} />}
          sx={{ marginRight: 1 }}
          variant="text"
        >
          Download
        </LoadingButton>
      </Tooltip>
      <Popover
        anchorEl={anchorElDownloadButtonButton}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        marginThreshold={8}
        onClose={closeDownloadButtonPopover}
        open={isDownloadButtonPopoverOpen}
        sx={{
          "& .MuiPopover-paper": {
            border: "none !important",
            boxShadow: `${theme.shadows[1]} !important`,
            display: "flex",
            flexDirection: "column",
            padding: "4px 0",
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <DownloadButton
          color="inherit"
          onClick={saveActivatedAudienceAsDataset}
          startIcon={<FontAwesomeIcon icon={faFileImport} />}
        >
          Store as dataset
        </DownloadButton>
        <DownloadButton
          color="inherit"
          onClick={downloadActivatedAudience}
          startIcon={<FontAwesomeIcon icon={faDownload} />}
        >
          Download result
        </DownloadButton>
      </Popover>
    </>
  );
};

DirectActivationDownloadButton.displayName = "DirectActivationDownloadButton";

export default memo(DirectActivationDownloadButton);
