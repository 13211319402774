import { useMount, useSafeState } from "ahooks";
import { memo, useMemo, useState } from "react";
import { KeychainClientService, UserKeychainStatus } from "services/keychain";
import {
  type KeychainService,
  KeychainServiceProvider,
  useApiCore,
} from "contexts";

interface KeychainServiceWrapperProps {
  children: React.ReactNode;
}

const KeychainServiceWrapper: React.FC<KeychainServiceWrapperProps> = memo(
  ({ children }) => {
    const { client } = useApiCore();
    const [keychain] = useState<KeychainService>(
      new KeychainClientService(client)
    );
    const [keychainStatus, setKeychainStatus] =
      useSafeState<UserKeychainStatus>(UserKeychainStatus.Unset);
    useMount(() => {
      (async () => {
        const status = await keychain.getCurrentStatusOrInitial();
        setKeychainStatus(status);
      })();
    });
    const value = useMemo(
      () => ({
        keychain,
        keychainStatus,
      }),
      [keychain, keychainStatus]
    );
    return (
      <KeychainServiceProvider value={value}>
        {children}
      </KeychainServiceProvider>
    );
  }
);

export default KeychainServiceWrapper;
