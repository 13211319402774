import { DqTable } from "@decentriq/components";
import { useDataConnectorJobsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJob,
  DataConnectorJobType,
  DataImportExportStatus,
} from "@decentriq/graphql/dist/types";
import {
  faEmptySet,
  faFileExport,
  faFileImport,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  CircularProgress,
  Grid,
  type Theme,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { orderBy } from "lodash";
import {
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import { useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { TimeAgoFormatted } from "components";
import {
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import ExternalConnectionDetails, {
  externalConnectionStatusPresentation,
} from "features/datasets/components/ExternalConnectionDetails/ExternalConnectionDetails";
import ExternalConnectionToolbar from "features/datasets/components/ExternalConnectionToolbar/ExternalConnectionToolbar";

const useEmptyPlaceholderStyle = makeStyles()((theme: Theme) => ({
  containerCenter: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  contentText: {
    display: "block",
    marginBlockEnd: "0.7em",
    marginBlockStart: "0.3em",
    marginInlineEnd: "0px",
    marginInlineStart: "0px",
  },
  icon: {
    height: "5em",
    marginBottom: "0.5em",
    width: "5em !important",
  },
}));

const externalsColumnDef: MRT_ColumnDef<DataConnectorJob>[] = [
  {
    Cell: ({ cell, row }) => {
      const name = cell.getValue<string>();
      const isExport = [
        DataConnectorJobType.ExportDataset,
        DataConnectorJobType.ExportDcrJobResult,
      ].includes(row.original.type);
      const connectionType = isExport
        ? row.original.targetType!
        : row.original.sourceType!;
      return (
        <div
          style={{
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              overflow: "hidden",
            }}
          >
            <FontAwesomeIcon
              fixedWidth={true}
              icon={isExport ? faFileExport : faFileImport}
            />
            <Typography
              sx={{
                cursor: "pointer",
                margin: "0 8px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              variant="body2"
            >
              {name}
            </Typography>
          </div>
          <Box display="flex" justifyContent="center" width="18px">
            <ExternalConnectionsIcon
              connectionType={connectionType}
              size={ExternalConnectionsIconSize.xs}
            />
          </Box>
        </div>
      );
    },
    accessorKey: "name",
    enableSorting: false,
    header: "Name",
    id: "name",
  },
  {
    Cell: ({ cell, row }) => {
      const status = cell.getValue<DataImportExportStatus>();
      return (
        <Typography
          color={
            status === DataImportExportStatus.Failed ? red[500] : "inherit"
          }
          sx={{ cursor: "pointer" }}
          variant="body2"
        >
          {status === DataImportExportStatus.Success ? (
            <>
              Completed{" "}
              {!!row.original.finishedAt && (
                <TimeAgoFormatted date={row.original.finishedAt!} />
              )}
            </>
          ) : (
            externalConnectionStatusPresentation.get(status)
          )}
        </Typography>
      );
    },
    accessorKey: "status",
    header: "Status",
    id: "status",
  },
];

const ExternalConnectionsList: React.FC = () => {
  const { classes: emptyPlaceholderClasses } = useEmptyPlaceholderStyle();
  const [sortDir] = useState<"asc" | "desc">("desc");
  const timeFilter = useMemo(
    () => ({
      createdBefore: new Date().toISOString(),
    }),
    []
  );
  const externalsQueryFilter = useMemo(() => ({ ...timeFilter }), [timeFilter]);
  const { data, loading } = useDataConnectorJobsQuery({
    variables: {
      filter: externalsQueryFilter,
    },
  });
  const externals = useMemo(() => {
    const connectionsArray = data?.dataConnectorJobs?.nodes;
    return orderBy(
      connectionsArray,
      ({ createdAt }) => new Date(createdAt),
      sortDir
    );
  }, [data, sortDir]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const selectedExternals = Object.keys(rowSelection)
    .filter((key) => rowSelection[key])
    .map((key) => externals.find(({ id }) => id === key));
  const totalCount = externals.length;
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        left="0"
        position="absolute"
        top="0"
        width="100%"
      >
        <CircularProgress color="inherit" size="2.5rem" thickness={1} />
      </Box>
    );
  }
  if (!loading && !totalCount) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <FontAwesomeIcon
          className={emptyPlaceholderClasses.icon}
          fixedWidth={true}
          height="5em !important"
          icon={faEmptySet}
          width={24}
        />
        <Typography variant="h5">
          <strong>No imports/exports</strong>
        </Typography>
        <Typography textAlign={"center"}>
          <p className={emptyPlaceholderClasses.contentText}>
            To import a dataset, press the button at top-right corner.
          </p>
          <p className={emptyPlaceholderClasses.contentText}>
            To export an existing dataset, go to the Datasets tab, select a
            dataset and press the Export button.
          </p>
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        alignItems: "stretch",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      <Grid
        columnSpacing={0}
        container={true}
        sx={{ flex: 1, overflow: "hidden" }}
      >
        <Grid
          item={true}
          sx={{
            borderColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            height: "100%",
            overflow: "hidden",
          }}
          xs={5}
        >
          <DqTable
            autoSelectFirstRow={true}
            columns={externalsColumnDef}
            data={externals}
            enableBatchRowSelection={false}
            enableMultiRowSelection={false}
            enableRowSelection={true}
            enableSelectAll={false}
            getRowId={(row) => row.id}
            localization={{
              noRecordsToDisplay: "No imports/exports found",
            }}
            muiTableBodyRowProps={({
              row: { getToggleSelectedHandler, getIsSelected },
            }) => {
              return {
                onClick: getIsSelected()
                  ? undefined
                  : getToggleSelectedHandler(),
                sx: {
                  "&:hover td:after": {
                    backgroundColor: "primary.light",
                    content: '""',
                    height: "100%",
                    left: 0,
                    opacity: 0.125,
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    zIndex: -1,
                  },
                  cursor: "pointer",
                },
              };
            }}
            muiTablePaperProps={{
              sx: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "hidden",
                width: "100%",
              },
            }}
            onRowSelectionChange={setRowSelection}
            state={{
              columnVisibility: { "mrt-row-select": false },
              rowSelection,
            }}
          />
        </Grid>
        {!loading ? (
          <Grid item={true} sx={{ height: "100%", overflow: "hidden" }} xs={6}>
            {selectedExternals.map((selectedExternal) =>
              selectedExternal ? (
                <Box
                  key={selectedExternal.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    height: "100%",
                    overflow: "hidden",
                    p: 2,
                  }}
                >
                  <ExternalConnectionToolbar
                    connectionId={selectedExternal.id}
                  />
                  <ExternalConnectionDetails
                    connectionId={selectedExternal.id}
                  />
                </Box>
              ) : null
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default ExternalConnectionsList;
