import { useDataRoomsListQuery } from "@decentriq/graphql/dist/hooks";
import { faClock as faClockRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@mui/material";
import { orderBy } from "lodash";
import { memo, useMemo } from "react";
import { RecentDataRoom } from "./components";

const RecentDataRoomsBlock: React.FC = () => {
  const { data: { dataRooms: { nodes: dataRoomsList = [] } = {} } = {} } =
    useDataRoomsListQuery({
      fetchPolicy: "network-only",
    });
  const recentDataRoomsList = useMemo(() => {
    return orderBy(dataRoomsList, "updatedAt", "desc").slice(0, 3);
  }, [dataRoomsList]);
  if (!recentDataRoomsList.length) return null;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography
        noWrap={true}
        sx={{ alignItems: "center", display: "flex", gap: 1 }}
        variant="body1"
      >
        <FontAwesomeIcon fixedWidth={true} icon={faClockRegular} />
        <strong>Recents</strong>
      </Typography>
      <Grid columnSpacing={2} container={true}>
        {recentDataRoomsList.map((dataRoom) => (
          <RecentDataRoom key={dataRoom.id} {...dataRoom} />
        ))}
      </Grid>
    </Box>
  );
};

RecentDataRoomsBlock.displayName = "RecentDataRoomsBlock";

export default memo(RecentDataRoomsBlock);
