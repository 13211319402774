import { faDownload as faDownloadRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import { memo, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import {
  type MediaDataRoomInsightsFormValues,
  useMediaDataRoomOverlapInsights,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";

const DownloadMediaDataRoomInsightsButton: React.FC = () => {
  const { advertiserOrganization } = usePublishedMediaInsightsDcr();
  const { aggregationData, isEmptyAggregation } =
    useMediaDataRoomOverlapInsights();
  const { getValues } = useFormContext<MediaDataRoomInsightsFormValues>();

  const downloadMediaDataRoomInsights = useCallback(() => {
    if (isEmptyAggregation) return;
    const { audienceType: selectedAudienceType } = getValues();
    const fileContent = aggregationData.reduce(
      (
        csv,
        {
          interest,
          age,
          gender,
          affinityRatio,
          shareInOverlap,
          shareInAddressableAudience,
          addressableAudienceSize,
        },
        index,
        aggregations
      ) => {
        // Formatting values
        const affinityRatioValue =
          typeof affinityRatio !== "number"
            ? "N/A"
            : `${affinityRatio.toFixed(1)}x`;
        const shareInOverlapValue =
          typeof shareInOverlap !== "number"
            ? "N/A"
            : `${(shareInOverlap * 100).toFixed(1)}%`;
        const shareInAddressableAudienceValue =
          typeof shareInAddressableAudience !== "number"
            ? "N/A"
            : `${(shareInAddressableAudience * 100).toFixed(1)}%`;

        // Defining whether data is available
        const withInterest = aggregations.some(({ interest }) => !!interest);
        const withAge = aggregations.some(({ age }) => !!age);
        const withGender = aggregations.some(({ gender }) => !!gender);

        const newRow: string = [
          interest,
          age,
          gender,
          affinityRatioValue,
          shareInOverlapValue,
          shareInAddressableAudienceValue,
          addressableAudienceSize,
        ]
          .filter((value) => !!value)
          .join(",");

        // Generate initial row
        if (index === 0) {
          const initialRow: string = [
            withInterest ? "Segment" : "",
            withAge ? "Age" : "",
            withGender ? "Gender" : "",
            "Affinity",
            "Share in overlap",
            "Share in Publisher audience",
            "Addressable users in publisher audience",
          ]
            .filter((value) => !!value)
            .join(",");
          return `${initialRow}\n${newRow}`;
        }

        return `${csv}\n${newRow}`;
      },
      ""
    );
    const fileName = `Top_Affinity_Insights_${advertiserOrganization?.name}_${selectedAudienceType}_${format(
      new Date(),
      "dd_MM_yyyy HH_mm"
    )}.csv`;
    const file = new File([fileContent], fileName, {
      type: "application/octet-stream;charset=utf-8",
    });
    saveAs(file);
  }, [
    advertiserOrganization?.name,
    aggregationData,
    getValues,
    isEmptyAggregation,
  ]);

  if (isEmptyAggregation) return null;

  return (
    <Tooltip
      disableFocusListener={true}
      disableTouchListener={true}
      placement="top-start"
      title="Export selected audience to CSV"
    >
      <IconButton onClick={downloadMediaDataRoomInsights} sx={{ padding: 0.5 }}>
        <FontAwesomeIcon fixedWidth={true} icon={faDownloadRegular} />
      </IconButton>
    </Tooltip>
  );
};

DownloadMediaDataRoomInsightsButton.displayName =
  "DownloadMediaDataRoomInsightsButton";

export default memo(DownloadMediaDataRoomInsightsButton);
