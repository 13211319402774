import { createContext, useContext } from "react";
import {
  type KeychainService,
  type UserKeychainStatus,
} from "../../services/keychain";

export interface KeychainServiceContextValue {
  keychain: KeychainService;
  keychainStatus: UserKeychainStatus;
}

export const KeychainServiceContext = createContext<
  KeychainServiceContextValue | undefined
>(undefined);

export const KeychainServiceProvider = KeychainServiceContext.Provider;

export const useKeychainService = () => {
  const context = useContext(KeychainServiceContext);
  if (!context) {
    throw new Error("Keychain must be available in context");
  }
  return context;
};

export type { KeychainService } from "../../services/keychain";
