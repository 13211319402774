import { useQuery } from "@tanstack/react-query";
import { type lookalike_media_request } from "ddc";
import { useApiCore } from "contexts";
import { type PublisherDatasetsHashes } from "features/mediaDataRoomV2/models";

interface UsePublishedLookalikeMediaDataRoomPublishedDatasetsResult {
  advertiserDatasetHash: string | null;
  publisherDatasetsHashes: PublisherDatasetsHashes;
  error?: string;
  hasRequiredData: boolean;
  hasAdvertiserData: boolean;
  hasPublisherData: boolean;
}

const usePublishedLookalikeMediaDataRoomPublishedDatasets = ({
  dataRoomId,
  driverAttestationHash,
  queryKeyPrefix,
}: {
  queryKeyPrefix: string[];
  dataRoomId: string;
  driverAttestationHash: string;
}) => {
  // TODO @matyasfodor - figure out session management. Could be created once and passed down to all hooks
  const { sessionManager } = useApiCore();
  const queryResult = useQuery({
    queryFn:
      async (): Promise<UsePublishedLookalikeMediaDataRoomPublishedDatasetsResult> => {
        const session = await sessionManager.get({ driverAttestationHash });
        const request: lookalike_media_request.LookalikeMediaRequest = {
          retrievePublishedDatasets: {
            dataRoomIdHex: dataRoomId,
          },
        };
        const response = await session.sendLookalikeMediaRequest(request);
        if (!("retrievePublishedDatasets" in response)) {
          throw new Error("Expected retrievePublishedDatasets response");
        }

        const {
          advertiserDatasetHashHex: advertiserDatasetHash = null,
          demographicsDatasetHashHex: demographicsDatasetHash = null,
          embeddingsDatasetHashHex: embeddingsDatasetHash = null,
          segmentsDatasetHashHex: segmentsDatasetHash = null,
          publisherDatasetHashHex: usersDatasetHash = null,
        } = response.retrievePublishedDatasets;

        const hasAdvertiserData = Boolean(advertiserDatasetHash);
        const hasPublisherData =
          Boolean(usersDatasetHash) && Boolean(segmentsDatasetHash);
        const hasRequiredData = hasAdvertiserData && hasPublisherData;

        return {
          advertiserDatasetHash,
          hasAdvertiserData,
          hasPublisherData,
          hasRequiredData,
          publisherDatasetsHashes: {
            demographicsDatasetHash,
            embeddingsDatasetHash,
            segmentsDatasetHash,
            usersDatasetHash,
          },
        };
      },
    queryKey: [
      ...queryKeyPrefix,
      "published-datasets",
      dataRoomId,
      driverAttestationHash,
    ],
  });

  return {
    ...queryResult,
    data: queryResult.data ?? {
      advertiserDatasetHash: null,
      hasAdvertiserData: false,
      hasPublisherData: false,
      hasRequiredData: false,
      publisherDatasetsHashes: {
        demographicsDatasetHash: null,
        embeddingsDatasetHash: null,
        segmentsDatasetHash: null,
        usersDatasetHash: null,
      },
    },
  };
};

export default usePublishedLookalikeMediaDataRoomPublishedDatasets;
