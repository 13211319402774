import {
  Chip as MuiChip,
  type ChipProps as MuiChipProps,
  type Theme,
} from "@mui/material";
import { type SystemStyleObject } from "@mui/system";
import { isFunction } from "lodash";
import { forwardRef, memo } from "react";

const Chip = forwardRef<HTMLDivElement, MuiChipProps>(
  ({ sx = {}, label, ...props }, ref) => {
    return (
      <MuiChip
        label={label}
        ref={ref}
        sx={(theme) => ({
          borderRadius: "2px",
          height: "22px",
          marginTop: "-2px",
          ...(isFunction(sx) ? sx(theme) : (sx as SystemStyleObject<Theme>)),
        })}
        {...props}
      />
    );
  }
);

Chip.displayName = "Chip";

export default memo(Chip);
