import { useAuth0 } from "@auth0/auth0-react";
import { InfoTooltip } from "@decentriq/components";
import { useSubmittedRequestSigneesQuery } from "@decentriq/graphql/dist/hooks";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  type Theme,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";
import DataRoomTableParticipantAvatar from "components/entities/dataRoom/DataRoomParticipantAvatar/DataRoomParticipantAvatar";

interface SubmittedRequestSigneesProps {
  submittedRequestId: string;
}

const useTableStyles = makeStyles()((theme: Theme) => ({
  cell: {
    padding: "8px !important",
  },
}));

const SubmittedRequestSignees: React.FC<SubmittedRequestSigneesProps> = ({
  submittedRequestId,
}) => {
  const { user } = useAuth0();
  const currentUserEmail = user?.email;
  const { classes: tableClasses } = useTableStyles();
  const { data } = useSubmittedRequestSigneesQuery({
    variables: {
      id: submittedRequestId,
    },
  });
  const signees = data?.submittedDataRoomRequest?.signers?.nodes;
  return (
    <>
      <Typography variant="body2">
        <strong>Affected Data Owners:</strong>
        <InfoTooltip tooltip="All participants with permissions to provision datasets to dependencies of this computation are required to approve the request." />
      </Typography>
      {signees?.length ? (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table size="small">
            <TableBody>
              {signees.map((s) => (
                <TableRow key={s.id}>
                  <TableCell className={tableClasses.cell}>
                    <Box alignItems="center" display="flex">
                      <DataRoomTableParticipantAvatar
                        active={!!s.signature}
                        name={s.userEmail}
                      />
                      <Typography
                        style={{
                          marginLeft: "0.25rem",
                        }}
                      >
                        {s.userEmail}
                        {s.userEmail === currentUserEmail && (
                          <strong style={{ marginLeft: ".5rem" }}>(You)</strong>
                        )}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={tableClasses.cell}>
                    {s.signature ? "Approved" : "Pending"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body2">
          {signees
            ? "no dependencies yet"
            : "Please validate the computation to determine the affected data owners."}
        </Typography>
      )}
    </>
  );
};

SubmittedRequestSignees.displayName = "SubmittedRequestSignees";

export default memo(SubmittedRequestSignees);
