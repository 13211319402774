import {
  useAudienceInsightsPublisherQuery,
  useAudienceInsightsPublisherStatisticsQuery,
  useMediaUnpublishPublisherDatasetMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  faLinkSlash as faLinkSlashRegular,
  faUpload as faUploadRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo, useCallback } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import {
  MediaDataRoomUploadDataDialog,
  MediaDataRoomUserRole,
} from "features/mediaDataRoom";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import MediaDataRoomParticipantAvatar from "../AudienceInsightsAdvertiser/MediaDataRoomParticipantAvatar";

interface AudienceInsightsPublisherProps {
  id: string;
  [key: string]: any;
}

const AudienceInsightsPublisher: React.FC<AudienceInsightsPublisherProps> =
  memo(({ id }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { publisherOrganization, isStopped, isDeactivated, isPublisher } =
      usePublishedMediaDataRoom();
    const {
      data: audienceInsightsPublisherData,
      loading: audienceInsightsPublisherLoading,
      called: audienceInsightsPublisherCalled,
    } = useAudienceInsightsPublisherQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            `Media data clean room could not be retrieved. Please try again by refreshing the page.`
          )
        );
      },
      variables: { id },
    });
    const { publishedMediaDataRoom } = audienceInsightsPublisherData || {};
    const {
      driverAttestationHash,
      publisherDatasetHash,
      advertiserDatasetHash,
    } = publishedMediaDataRoom || {};
    const {
      data: audienceInsightsPublisherStatisticsData,
      loading: audienceInsightsPublisherStatisticsLoading,
    } = useAudienceInsightsPublisherStatisticsQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Publisher audience insights could not be retrieved."
          )
        );
      },
      // TODO: Remove dataset hashes from `skip` and handle error instead
      skip:
        !id ||
        !driverAttestationHash ||
        !publisherDatasetHash ||
        !advertiserDatasetHash,
      variables: {
        input: {
          dataRoomId: id,
          driverAttestationHash,
        },
      },
    });
    const { mediaCalculateStatistics } =
      audienceInsightsPublisherStatisticsData || {};
    const { publisherNumberOfSegments } = mediaCalculateStatistics || {};
    const [
      isMediaDataRoomUploadDataDialogOpen,
      {
        setTrue: openMediaDataRoomUploadDataDialog,
        setFalse: closeMediaDataRoomUploadDataDialog,
      },
    ] = useBoolean(false);
    // TODO: Export `formatter` from /utils for consistency?
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    const [
      mediaUnpublishPublisherDatasetMutation,
      { loading: mediaUnpublishPublisherDatasetLoading },
    ] = useMediaUnpublishPublisherDatasetMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Dataset could not be deprovisioned from the data clean room."
          )
        );
      },
    });
    const mediaUnpulishPublisherDataset = useCallback(() => {
      mediaUnpublishPublisherDatasetMutation({
        variables: {
          dataRoomId: id,
          driverAttestationHash,
        },
      });
    }, [driverAttestationHash, id, mediaUnpublishPublisherDatasetMutation]);
    return (
      <Box>
        <Typography variant="subtitle1">Publisher</Typography>
        <Grid container={true} spacing={2}>
          <Grid alignItems="center" container={true} item={true} xs={5}>
            <MediaDataRoomParticipantAvatar
              avatarText={publisherOrganization?.name}
              logoBase64={publisherOrganization?.logo}
            />
          </Grid>
          <Grid flexDirection="column" item={true} xs={7}>
            {audienceInsightsPublisherLoading ? (
              <Skeleton variant="rectangular">
                <Typography color="inherit" />
              </Skeleton>
            ) : (
              <Typography>{publisherOrganization?.name}</Typography>
            )}
            <Typography color="inherit" mb="6px" mt="2px" variant="subtitle2">
              {audienceInsightsPublisherLoading ||
              audienceInsightsPublisherStatisticsLoading ||
              audienceInsightsPublisherData === undefined ? (
                <Skeleton variant="rectangular" />
              ) : publisherNumberOfSegments ? (
                `${formatter.format(publisherNumberOfSegments)} segment${
                  publisherNumberOfSegments > 1 ? "s" : ""
                }`
              ) : publisherDatasetHash ? (
                "Audience provisioned"
              ) : (
                "Waiting for audience data"
              )}
            </Typography>
            {isPublisher ? (
              audienceInsightsPublisherCalled &&
              (audienceInsightsPublisherLoading ||
                audienceInsightsPublisherData === undefined) ? (
                <Skeleton height={32} variant="rectangular" width={122} />
              ) : publisherDatasetHash ? (
                <LoadingButton
                  color="error"
                  disabled={mediaUnpublishPublisherDatasetLoading}
                  loading={mediaUnpublishPublisherDatasetLoading}
                  onClick={mediaUnpulishPublisherDataset}
                  startIcon={
                    <FontAwesomeIcon
                      fixedWidth={true}
                      icon={faLinkSlashRegular}
                    />
                  }
                >
                  Deprovision data
                </LoadingButton>
              ) : isStopped || isDeactivated ? null : (
                <Button
                  color="primary"
                  onClick={openMediaDataRoomUploadDataDialog}
                  startIcon={
                    <FontAwesomeIcon fixedWidth={true} icon={faUploadRegular} />
                  }
                  variant="contained"
                >
                  Import data
                </Button>
              )
            ) : null}
          </Grid>
        </Grid>
        <MediaDataRoomUploadDataDialog
          id={id}
          onCancel={closeMediaDataRoomUploadDataDialog}
          open={isMediaDataRoomUploadDataDialogOpen}
          role={MediaDataRoomUserRole.Publisher}
        />
      </Box>
    );
  });

export default AudienceInsightsPublisher;
