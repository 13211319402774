import { Typography } from "@mui/material";
import { useComputeNodesVars } from "contexts";
import { ComputeNodeConstructorMode } from "features/computeNodes/models";

const emptyConstructorStatusMap = (permittedOnly: boolean) =>
  new Map<ComputeNodeConstructorMode, string>([
    [
      ComputeNodeConstructorMode.READONLY,
      "This data clean room doesn't have any computations declared.",
    ],
    [
      ComputeNodeConstructorMode.ACTION,
      permittedOnly
        ? "There are no computations available to you."
        : "There are no computations.",
    ],
  ]);

const ComputeNodesEmptyPanel = () => {
  const { mode, permittedOnly } = useComputeNodesVars();
  return emptyConstructorStatusMap(permittedOnly).get(mode) ? (
    <Typography mb={1} variant="body1">
      {emptyConstructorStatusMap(permittedOnly).get(mode)}
    </Typography>
  ) : null;
};

export default ComputeNodesEmptyPanel;
