import { Box, Toolbar, Typography } from "@mui/material";
import {
  DataLabActions,
  DataLabStatusLabel,
  useDataLabContext,
} from "features/dataLabs";

interface DataLabToolbarProps {
  id: string;
}

const DataLabToolbar: React.FC<DataLabToolbarProps> = ({ id }) => {
  const {
    dataLab: { data: dataLab, loading },
  } = useDataLabContext();
  if (loading || !dataLab) return <></>;
  return (
    <Toolbar
      disableGutters={true}
      sx={{ paddingLeft: (theme) => theme.spacing(2) }}
    >
      <Typography fontWeight="fontWeightSemiBold" noWrap={true} variant="h6">
        {!dataLab?.name ? "Loading datalab name..." : dataLab.name}
      </Typography>
      <Box>
        <Box sx={{ display: "flex" }}>
          <DataLabStatusLabel id={id} />
          <DataLabActions id={id} />
        </Box>
      </Box>
    </Toolbar>
  );
};

DataLabToolbar.displayName = "DataLabToolbar";

export default DataLabToolbar;
