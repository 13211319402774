import {
  useMediaDataRoomActivationTypeQuery,
  usePublishMediaAdvertiserDatasetMutation,
  usePublishMediaPublisherDatasetMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  ActivationType,
  ColumnDataType,
  TableColumnFormatType,
} from "@decentriq/graphql/dist/types";
import { exceptions, Key } from "@decentriq/utils";
import { memo, useCallback, useEffect, useMemo } from "react";
import { type KeychainItem, KeychainItemKind } from "services/keychain";
import { useApiCore, useKeychainService } from "contexts";
import { DataNodeUploadDataDialog } from "features/dataNodes";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
  type FileIngestionDefinition,
} from "features/datasets";
import { MediaDataRoomUserRole } from "features/mediaDataRoom";
import {
  mapMediaDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useReportError,
} from "hooks";
import { type DataRoomTableColumn, DataRoomType } from "models";
import { hashedIdAsArray, idAsHash } from "utils/apicore";

const delay = (ms: number, fn?: Function) => {
  return new Promise<any>((resolve) => {
    setTimeout(() => resolve(fn?.()), ms);
  });
};

const noSoonerThan = async (ms: number, fn?: Function) => {
  const [result] = await Promise.all([fn?.(), delay(ms)]);
  return result;
};

interface MediaDataRoomUploadDataDialogProps {
  id: string;
  role: MediaDataRoomUserRole.Publisher | MediaDataRoomUserRole.Advertiser;
  [key: string]: any;
}

const MediaDataRoomUploadDataDialog: React.FC<MediaDataRoomUploadDataDialogProps> =
  memo(({ id: dataRoomId, role, open, onCancel }) => {
    const reportError = useReportError();
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { store } = useApiCore();
    const { keychain } = useKeychainService();
    const { data: mediaDataRoomUploadDataDialogData } =
      useMediaDataRoomActivationTypeQuery({
        fetchPolicy: "cache-and-network",
        onError: (error) => {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Media data clean room could not be retrieved. Please try again by refreshing the page."
            )
          );
        },
        variables: { id: dataRoomId },
      });
    const { publishedMediaDataRoom } = mediaDataRoomUploadDataDialogData || {};
    const { activationType = null, driverAttestationHash } =
      publishedMediaDataRoom || {};
    const publishMediaAdvertiserDatasetMutation =
      usePublishMediaAdvertiserDatasetMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Dataset could not be uploaded."
            )
          );
        },
      });
    const publishMediaPublisherDatasetMutation =
      usePublishMediaPublisherDatasetMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Dataset could not be uploaded."
            )
          );
        },
      });
    const [
      publishMediaDataset,
      { called: publishMediaDatasetCalled, reset: resetPublishDatasetMutation },
    ] =
      role === MediaDataRoomUserRole.Advertiser
        ? publishMediaAdvertiserDatasetMutation
        : publishMediaPublisherDatasetMutation;
    useEffect(() => {
      if (!open && publishMediaDatasetCalled) {
        resetPublishDatasetMutation();
      }
    }, [resetPublishDatasetMutation, open, publishMediaDatasetCalled]);
    const closeMediaDataRoomUploadDataDialog = useCallback(() => {
      onCancel();
    }, [onCancel]);

    const onInsertDatasetKey = useCallback(
      async (
        datasetManifestHash: string,
        keyMaterial: Uint8Array
      ): Promise<boolean> => {
        try {
          await keychain.insertItem({
            id: datasetManifestHash,
            kind: KeychainItemKind.Dataset,
            value: idAsHash(keyMaterial)!,
          });
          return true;
        } catch (error) {
          enqueueSnackbar(`Error storing dataset hash in keychain: ${error}`);
          return false;
        }
      },
      [enqueueSnackbar, keychain]
    );
    const columns: DataRoomTableColumn[] = useMemo(() => {
      let columns: DataRoomTableColumn[] = [];
      switch (activationType) {
        case ActivationType.Direct:
          columns =
            role === MediaDataRoomUserRole.Publisher
              ? [
                  {
                    formatType: TableColumnFormatType.String,
                    id: "matchingId",
                    name: "Matching ID",
                    nullable: false,
                    primitiveType: ColumnDataType.Text,
                    tableNodeId: "Publisher segments",
                  },
                  {
                    formatType: TableColumnFormatType.String,
                    id: "activationId",
                    name: "Activation ID",
                    nullable: false,
                    primitiveType: ColumnDataType.Text,
                    tableNodeId: "Publisher segments",
                  },
                ]
              : role === MediaDataRoomUserRole.Advertiser
                ? [
                    {
                      formatType: TableColumnFormatType.String,
                      id: "matchingId",
                      name: "Matching ID",
                      nullable: false,
                      primitiveType: ColumnDataType.Text,
                      tableNodeId: "Advertiser audiences",
                    },
                    {
                      default: "All customers",
                      formatType: TableColumnFormatType.String,
                      id: "audienceType",
                      name: "Audience type",
                      nullable: false,
                      primitiveType: ColumnDataType.Text,
                      tableNodeId: "Advertiser audiences",
                    },
                  ]
                : [];
          break;
        case ActivationType.Consentless:
          columns =
            role === MediaDataRoomUserRole.Publisher
              ? [
                  {
                    formatType: TableColumnFormatType.String,
                    id: "matchingId",
                    name: "Matching ID",
                    nullable: false,
                    primitiveType: ColumnDataType.Text,
                    tableNodeId: "Publisher segments",
                  },
                  {
                    formatType: TableColumnFormatType.String,
                    id: "segment",
                    name: "Segment",
                    nullable: false,
                    primitiveType: ColumnDataType.Text,
                    tableNodeId: "Publisher segments",
                  },
                ]
              : role === MediaDataRoomUserRole.Advertiser
                ? [
                    {
                      formatType: TableColumnFormatType.String,
                      id: "matchingId",
                      name: "Matching ID",
                      nullable: false,
                      primitiveType: ColumnDataType.Text,
                      tableNodeId: "Advertiser audiences",
                    },
                    {
                      default: "All customers",
                      formatType: TableColumnFormatType.String,
                      id: "audienceType",
                      name: "Audience type",
                      nullable: false,
                      primitiveType: ColumnDataType.Text,
                      tableNodeId: "Advertiser audiences",
                    },
                  ]
                : [];
          break;
        case null:
          columns = [
            {
              formatType: TableColumnFormatType.String,
              id: "matchingId",
              name: "Matching ID",
              nullable: false,
              primitiveType: ColumnDataType.Text,
              tableNodeId: "Publisher segments",
            },
          ];
          break;
        default:
          columns = [];
      }
      return columns;
    }, [activationType, role]);
    const handleIngestData = useCallback(
      (
        shouldStoreInKeychain: boolean,
        source: string,
        testing: boolean,
        uploadResult: { key: Key; manifestHash: string }
      ) => {
        let encryptionKeyReference: string;
        let datasetHash: string;
        let key: Key;
        return (
          noSoonerThan(1000)
            .then(() => uploadResult)
            .then((result) => {
              datasetHash = result.manifestHash;
              key = result.key;
              encryptionKeyReference = store.push(key);
              return noSoonerThan(1000, () =>
                publishMediaDataset({
                  variables: {
                    input: {
                      dataRoomId,
                      datasetHash,
                      driverAttestationHash,
                      encryptionKey: encryptionKeyReference,
                    },
                  },
                })
              );
            })
            // TODO: Store in keychain before publishing
            .then((result) => {
              if (shouldStoreInKeychain) {
                return onInsertDatasetKey(datasetHash, key.material);
              } else {
                return result;
              }
            })
            .then(() => {
              return delay(1000, () => {
                closeMediaDataRoomUploadDataDialog();
              });
            })
        );
      },
      [
        closeMediaDataRoomUploadDataDialog,
        dataRoomId,
        driverAttestationHash,
        onInsertDatasetKey,
        publishMediaDataset,
        store,
      ]
    );
    const handleConnectFromKeychain = useCallback(
      async (keychainItem: KeychainItem) => {
        await noSoonerThan(1000);
        let key = new Key(hashedIdAsArray(keychainItem.value));
        let datasetHash = keychainItem.id;
        let encryptionKeyReference = store.push(key);
        await noSoonerThan(1000, () =>
          publishMediaDataset({
            variables: {
              input: {
                dataRoomId,
                datasetHash,
                driverAttestationHash,
                encryptionKey: encryptionKeyReference,
              },
            },
          })
        );
        return await delay(1000, () => {
          closeMediaDataRoomUploadDataDialog();
        });
      },
      [
        closeMediaDataRoomUploadDataDialog,
        dataRoomId,
        driverAttestationHash,
        publishMediaDataset,
        store,
      ]
    );
    const handleError = useCallback(
      (error: Error) => {
        if (
          error instanceof exceptions.DatasetValidationError &&
          error.hasReport
        ) {
          return;
        }
        reportError(
          {
            details: error.message,
            errorContext: [
              {
                content: dataRoomId,
                name: "dataRoomId",
              },
            ],
            origin: DataRoomType.Media,
          },
          { silent: true }
        );
      },
      [reportError, dataRoomId]
    );
    const onIngest = useCallback(
      async (
        payload:
          | DataIngestionPayload<DatasetIngestionDefinition>
          | DataIngestionPayload<FileIngestionDefinition>
      ) => {
        if (payload.source === "local") {
          return await handleIngestData(
            !!payload.shouldStoreInKeychain,
            payload.source,
            false,
            payload.uploadResult!
          );
        }
        if (payload.source === "keychain") {
          return await handleConnectFromKeychain(payload.datasetKeychainItem!);
        }
      },
      [handleConnectFromKeychain, handleIngestData]
    );
    return open ? (
      <DataNodeUploadDataDialog
        // @ts-ignore
        columns={columns}
        columnsOrder={columns.map(({ name }) => name)}
        id={"mediaData"}
        name={columns[0].tableNodeId}
        onClose={onCancel}
        onError={handleError}
        onIngest={onIngest}
        open={open}
        uniqueColumnIds={[]}
      />
    ) : null;
  });

export default MediaDataRoomUploadDataDialog;
