import {
  FormControl,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { memo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  OverlapInsightsExecutionContext,
  useMediaDataRoomOverlapInsightsContext,
} from "features/MediaInsightsDcr";
import { useMediaInsightsDcrData } from "features/MediaInsightsDcr/contexts";

const MediaDataRoomInsightsAdvertiserAudienceSelector: React.FC<{
  alwaysShowSelector?: boolean;
}> = ({ alwaysShowSelector = true }) => {
  const { control: formControl, setValue } = useFormContext();
  const { audienceType: activationAudienceType, executionContext } =
    useMediaDataRoomOverlapInsightsContext();
  const {
    overlapStatistics: {
      computeResults: {
        statistics: overlapStatistics = [],
        isEmpty: isEmptyOverlapStatistics = false,
      } = {},
      status: overlapStatisticsStatus,
    },
  } = useMediaInsightsDcrData();

  useEffect(() => {
    if (
      activationAudienceType &&
      executionContext !== OverlapInsightsExecutionContext.insightsTab
    ) {
      setValue("audienceType", activationAudienceType);
      return;
    }
    if (overlapStatisticsStatus === "COMPLETED") {
      const advertiserAudienceDefaultValue =
        overlapStatistics.filter(({ overlapSize }) => overlapSize !== 0)[0]
          ?.audienceType || "";
      // Set default value
      if (advertiserAudienceDefaultValue) {
        setValue("audienceType", advertiserAudienceDefaultValue);
      }
    }
  }, [
    activationAudienceType,
    executionContext,
    overlapStatistics,
    overlapStatisticsStatus,
    setValue,
  ]);

  // There is no reason to render the select in See Insights dialog, for instance, as value
  // will always be preselected. However, this value has to be set to the form values for
  // utilizing it in all the other Insights form components
  if (
    isEmptyOverlapStatistics ||
    !overlapStatistics.some(({ overlapSize }) => overlapSize) ||
    !(
      alwaysShowSelector ||
      executionContext !== OverlapInsightsExecutionContext.insightsTab
    ) ||
    !alwaysShowSelector ||
    overlapStatisticsStatus !== "COMPLETED"
  ) {
    return null;
  }

  return (
    <FormControl sx={{ marginBottom: 2, width: "100%" }}>
      <FormLabel
        component="legend"
        sx={{ fontSize: "12px", paddingLeft: "2px" }}
      >
        Advertiser audience
      </FormLabel>
      <Controller
        control={formControl}
        name="audienceType"
        render={({ field }) => {
          return (
            <Select
              displayEmpty={true}
              fullWidth={true}
              multiple={false}
              renderValue={(value) => value}
              sx={{
                ...(overlapStatistics.length === 1
                  ? {
                      "& .MuiSelect-icon": { display: "none" },
                      "&.Mui-disabled": { background: "white" },
                    }
                  : {}),
                background: "transparent",
              }}
              variant="filled"
              {...field}
            >
              {overlapStatistics.map(({ audienceType, overlapSize }) => (
                <MenuItem
                  disabled={overlapSize === 0}
                  key={audienceType}
                  value={audienceType}
                >
                  <ListItemText
                    primary={audienceType}
                    secondary={overlapSize === 0 ? "Overlap is too small" : ""}
                    secondaryTypographyProps={{
                      sx: {
                        fontSize: "12px",
                        maxWidth: "340px",
                        whiteSpace: "normal",
                      },
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};

MediaDataRoomInsightsAdvertiserAudienceSelector.displayName =
  "MediaDataRoomInsightsAdvertiserAudienceSelector";

export default memo(MediaDataRoomInsightsAdvertiserAudienceSelector);
