import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { JsonEditorField, Loading } from "components";

interface AdvertiserValidationReportDialogProps {
  data?: string;
  loading: boolean;
  onClose: () => void;
  open: boolean;
}

const AdvertiserValidationReportDialog: React.FC<
  AdvertiserValidationReportDialogProps
> = ({ data, loading, open, onClose }) => {
  const formattedValue =
    data && typeof data === "string"
      ? JSON.stringify(JSON.parse(data), null, 2)
      : data;
  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">
            Validation report for Advertiser audience table
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <JsonEditorField
            editorOptions={{
              lineNumbers: "off",
              readOnly: true,
              resizable: false,
            }}
            height={400}
            value={formattedValue}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdvertiserValidationReportDialog.displayName =
  "AdvertiserValidationReportDialog";
export default AdvertiserValidationReportDialog;
