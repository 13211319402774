import { Box, CircularProgress } from "@mui/material";
import {
  AdvertiserDataNode,
  PublisherDataNode,
  useLookalikeMediaDataRoomData,
} from "features/mediaDataRoomV2";

interface MediaDataRoomDataProps {
  dataRoomId: string;
  driverAttestationHash: string;
}

const MediaDataRoomData: React.FC<MediaDataRoomDataProps> = ({
  dataRoomId,
  driverAttestationHash,
}) => {
  const {
    advertiserDatasetHash,
    publisherDatasetsHashes: {
      demographicsDatasetHash,
      embeddingsDatasetHash,
      segmentsDatasetHash,
      usersDatasetHash,
    },
    retrieveDatasets,
    datasetsLoading,
  } = useLookalikeMediaDataRoomData();

  if (datasetsLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress color="inherit" size="1.5rem" thickness={1} />
      </Box>
    );
  }
  return (
    <>
      <AdvertiserDataNode
        dataRoomId={dataRoomId}
        datasetHash={advertiserDatasetHash}
        retrieveDatasets={retrieveDatasets}
      />
      <PublisherDataNode
        dataRoomId={dataRoomId}
        demographicsDatasetHash={demographicsDatasetHash}
        embeddingsDatasetHash={embeddingsDatasetHash}
        retrieveDatasets={retrieveDatasets}
        segmentsDatasetHash={segmentsDatasetHash}
        usersDatasetHash={usersDatasetHash}
      />
    </>
  );
};

MediaDataRoomData.displayName = "MediaDataRoomData";

export default MediaDataRoomData;
