import { testIds } from "@decentriq/utils";
import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { DataLabCreateDialog } from "features/dataLabs";

const DataLabCreateButton: React.FC = () => {
  const [
    isDataLabCreateDialogVisible,
    { setTrue: openDataLabCreateDialog, setFalse: closeDataLabCreateDialog },
  ] = useBoolean(false);

  return (
    <>
      <Button
        color="primary"
        data-testid={testIds.dataLabs.dataLabScreen.newDataLab}
        onClick={openDataLabCreateDialog}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faPlusRegular} />}
        variant="contained"
      >
        New lab
      </Button>
      <Portal>
        <DataLabCreateDialog
          onCancel={closeDataLabCreateDialog}
          open={isDataLabCreateDialogVisible}
        />
      </Portal>
    </>
  );
};

DataLabCreateButton.displayName = "DataLabCreateButton";

export default memo(DataLabCreateButton);
