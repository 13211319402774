import { InfoTooltip } from "@decentriq/components";
import { Box, Typography } from "@mui/material";
import { type BarDatum, ResponsiveBar } from "@nivo/bar";
import { memo, useMemo } from "react";
import { useDataLabContext } from "features/dataLabs";

const chartDataKeyToLabelMap = new Map<string, string>([
  ["allUsers", "All Users"],
  ["effectiveUsers", "Users with a Matching ID"],
]);

// Only first 15 items will be shown in order to prevent charts breaking the markup and looking too overloaded with data
const SEGMENTS_THRESHOLD = 15;

const SegmentPerUserDistributionChart: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();

  const { statistics: dataLabStatistics } = dataLab || {};
  const { segments_per_user_distributions: segmentsPerUserDistributions } =
    dataLabStatistics || {};

  const segmentsPerUserDistributionsChartData: BarDatum[] = useMemo(
    () =>
      segmentsPerUserDistributions
        ? Object.entries(segmentsPerUserDistributions)
            .slice(0, SEGMENTS_THRESHOLD)
            .map(([segmentName, values]) => ({
              allUsers: (values as [number, number])[0],
              effectiveUsers: (values as [number, number])[1],
              segment: segmentName as string,
            }))
        : [],
    [segmentsPerUserDistributions]
  );

  // Chart is being cut at the top when maxValue is set to auto
  // In order to prevent this happening, the biggest value in the array is found and treshhold value is added
  const chartMaxValue = useMemo(() => {
    const dataMaxValue = Math.max(
      ...(Object.values(segmentsPerUserDistributions || {})
        .slice(0, SEGMENTS_THRESHOLD)
        .flat() as number[])
    );
    return dataMaxValue >= 0.97 ? 1 : dataMaxValue + 0.03;
  }, [segmentsPerUserDistributions]);

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "350px",
        marginBottom:
          segmentsPerUserDistributionsChartData.length > SEGMENTS_THRESHOLD
            ? 5
            : 0,
        marginTop: 3,
        width: "80%",
      }}
    >
      <Typography>
        Share of users per number of segments
        <InfoTooltip tooltip="Each bar of this chart represents the share of all users who are in the given number of segments. For example, a bar for Number of Segments = 3 with a value of 15% means that of all users 15% are in exactly 3 segments. This chart is presented once for All Users and once only for Users with a Matching ID. For each of the colors, the bars are expected to sum to 100%." />
      </Typography>
      <ResponsiveBar
        axisBottom={{
          legend: "Number of Segments",
          legendOffset: 32,
          legendPosition: "middle",
          tickPadding: 5,
          tickSize: 5,
        }}
        axisLeft={{
          format: ">-.0%",
          legend: "Share of Users",
          legendOffset: -48,
          legendPosition: "middle",
          tickPadding: 5,
          tickSize: 5,
        }}
        colors={["#2672B7", "#82DEDF"]}
        data={segmentsPerUserDistributionsChartData}
        enableGridY={true}
        enableLabel={false}
        groupMode="grouped"
        indexBy="segment"
        keys={["allUsers", "effectiveUsers"]}
        layout="vertical"
        margin={{
          bottom: 64,
          left: 64,
          right: 32,
          top: 8,
        }}
        maxValue={chartMaxValue}
        padding={0.2}
        tooltipLabel={({ data, id }) =>
          `${data.segment} - ${chartDataKeyToLabelMap.get(id as string)}`
        }
        valueFormat=">-.2%"
      />
      {segmentsPerUserDistributionsChartData.length > SEGMENTS_THRESHOLD && (
        <Typography mt={-2} variant="subtitle2">
          Chart is limited to the first 15 segments. To view all, please click
          the download statistics button above.
        </Typography>
      )}
    </Box>
  );
};

SegmentPerUserDistributionChart.displayName = "SegmentPerUserDistributionChart";

export default memo(SegmentPerUserDistributionChart);
