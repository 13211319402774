import {
  faCaretDown as faCaretDownSolid,
  faCaretRight as faCaretRightSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Collapse,
  Grid,
  ListItem,
  styled,
  Typography,
} from "@mui/material";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:first-of-type:not(:last-of-type)": {
    marginBottom: 0,
  },
  alignItems: "stretch",
  background: "white",
  border: ".5px solid #bbb",
  borderRadius: theme.shape.borderRadius,
  flexDirection: "column",
  padding: theme.spacing(0.5),
}));

interface DataNodeItemProps {
  title: string;
  toggleCollapse?: () => void;
  isCollapsed: boolean;
  actions: React.ReactNode;
  content: React.ReactNode;
  description?: React.ReactNode;
}

const DataNodeItem: React.FC<DataNodeItemProps> = ({
  description,
  title,
  toggleCollapse,
  actions,
  content,
  ...restProps
}) => {
  const collapsable: boolean = Boolean(toggleCollapse);
  const isCollapsed = collapsable && restProps.isCollapsed;
  return (
    <Box sx={{ display: "flex", flex: 1, mb: 1, padding: 0 }}>
      <StyledListItem>
        <Box sx={{ width: "100%" }}>
          <Grid container={true} spacing={1}>
            <Grid
              alignItems="center"
              container={true}
              item={true}
              onClick={toggleCollapse}
              style={{
                cursor: "pointer",
                flexWrap: "nowrap",
              }}
              xs={12}
            >
              {collapsable && (
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={isCollapsed ? faCaretRightSolid : faCaretDownSolid}
                  style={{ cursor: "pointer", margin: "8px 4px" }}
                />
              )}
              <Box
                sx={{
                  flex: 1,
                  padding: collapsable ? 0 : "4px 0 4px 24px",
                  width: 0,
                }}
              >
                <Typography variant="body1">
                  {title}
                  {description && (
                    <Typography
                      component="span"
                      sx={{ marginLeft: ".75rem" }}
                      variant="caption"
                    >
                      {description}
                    </Typography>
                  )}
                </Typography>
              </Box>
              {actions}
            </Grid>
          </Grid>
        </Box>
        {collapsable ? (
          <Collapse
            in={!isCollapsed}
            style={{
              marginLeft: 6,
              marginRight: 6,
              marginTop: 10,
            }}
            timeout="auto"
            unmountOnExit={true}
          >
            {content}
          </Collapse>
        ) : (
          <Box>{content}</Box>
        )}
      </StyledListItem>
    </Box>
  );
};

export default DataNodeItem;
