export const replaceStringFromMap = (
  str: string,
  map: { [key: string]: string }
) => {
  const matchStr = Object.keys(map).join("|");
  if (!matchStr) return str;
  const regexp = new RegExp(matchStr, "g");
  return str.replace(regexp, (match) => map[match]);
};

/**
 * Ellipsisify truncates the filename (before the extension) to a desired length
 * by keeping the beginning, the end, and the extension of the filename.
 * Examples:
 *
 * With an extension:
 * ellipsisify('asdasdasdasdasdsdf676a87sd678a6sd78a6s78d6a78sd68a.78sd76a87sd6as87da8s7d6a8sd.zip', 20)
 * 'asdasdas…s7d6a8sd.zip'
 *
 * Without an extension:
 * ellipsisify('asdasdasdasdasdsdf676a87sd678a6sd78a6s78d6a78sd68a78sd76a87sd6as87da8s7d6a8sd', 20)
 * 'asdasdas…s7d6a8sd'
 *
 * @param filename
 * @param lengthThreshold
 * @returns
 */
export const ellipsisify = (
  filename: string,
  lengthThreshold: number = 20
): string => {
  if (filename.length < lengthThreshold) return filename;
  const sideLength = Math.floor((lengthThreshold - 3) * 0.5);
  const filenamePieces = filename.split(".");

  let extension;
  let filenameBeforeExtension;

  if (filenamePieces.length > 1) {
    extension = filenamePieces.pop();
    filenameBeforeExtension = filenamePieces
      .slice(0, filenamePieces.length)
      .join(".");
  } else {
    extension = "";
    filenameBeforeExtension = filenamePieces[0];
  }
  const firstLetters = filenameBeforeExtension.substring(0, sideLength);
  const lastLetters = filenameBeforeExtension.substring(
    filenameBeforeExtension.length - sideLength
  );
  const truncatedFilename = `${firstLetters}…${lastLetters}`;
  if (extension) {
    return `${truncatedFilename}.${extension}`;
  } else {
    return truncatedFilename;
  }
};
