import {
  type MutationRetrieveComputeJobResultFileAsZipArgs,
  type RetrieveComputeJobResultFileAsZipPayload,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextInterface } from "contexts";
import { fetchJobResult } from "wrappers/ApolloWrapper/helpers/fetchJobResult";
import { getNodeById, parseErrorMessage } from "../../../helpers";
import { type LocalResolverContext } from "../../../models";

export const makeRetrieveComputeJobResultFileAsZipResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: null,
    args: MutationRetrieveComputeJobResultFileAsZipArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<RetrieveComputeJobResultFileAsZipPayload> => {
    const { computeNodeId } = args.input;
    try {
      const computeNodeResult = await fetchJobResult(
        args,
        context,
        sessionManager
      );
      const file = new File(
        [computeNodeResult],
        `${args.input.resultFileName}.zip`,
        {
          type: "application/octet-stream;charset=utf-8",
        }
      );
      const fileReference = store.push(file);
      return {
        resultZip: fileReference,
      };
    } catch (error) {
      const errorMessage = parseErrorMessage(context.cache, error, args);
      const computeNode = getNodeById(context, computeNodeId, args);
      throw new Error(
        `${computeNode ? computeNode?.name + ". " : ""}${errorMessage}`
      );
    }
  };
