import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import { memo, useMemo } from "react";
import { useComputeNodesVars } from "contexts";
import { ComputeNodeTypeNames } from "models";
import {
  MatchComputeNodeEditor,
  PostComputeNodeEditor,
  PreviewComputeNodeEditor,
  S3ComputeNodeEditor,
  ScriptingComputeNodeEditor,
  SqlComputeNodeEditor,
  SyntheticComputeNodeEditor,
} from "./components";
import useComputeNodeEditor from "./useComputeNodeEditor";

interface ComputeNodeEditorProps {
  computeNodeId: string;
  [key: string]: any;
}

const ComputeNodeEditor: React.FC<ComputeNodeEditorProps> = memo((props) => {
  const { computeNodeId } = props;
  const node = useComputeNodeEditor(computeNodeId);
  const { readOnly } = useComputeNodesVars();
  const computationTypename = node?.__typename;
  const sqlEditorOptions = useMemo(
    () => ({
      ...props.editorOptions,
      dynamicHeight: readOnly,
      height: "100%",
      readOnly,
    }),
    [props.editorOptions, readOnly]
  );
  const scriptingEditorOptions = useMemo(
    () => ({
      ...props.editorOptions,
      height: readOnly ? 560 : "100%",
      readOnly,
    }),
    [props.editorOptions, readOnly]
  );

  switch (computationTypename) {
    case ComputeNodeTypeNames.DraftMatchNode:
    case ComputeNodeTypeNames.PublishedMatchNode:
      return <MatchComputeNodeEditor {...props} readOnly={readOnly} />;
    case ComputeNodeTypeNames.DraftSqliteNode:
    case ComputeNodeTypeNames.PublishedSqliteNode:
      return (
        <SqlComputeNodeEditor
          {...props}
          editorOptions={sqlEditorOptions}
          hidePrivacyFilter={true}
          readOnly={readOnly}
          variant={WorkerTypeShortName.Sqlite}
        />
      );
    case ComputeNodeTypeNames.DraftSqlNode:
    case ComputeNodeTypeNames.PublishedSqlNode:
      return (
        <SqlComputeNodeEditor
          {...props}
          editorOptions={sqlEditorOptions}
          readOnly={readOnly}
          variant={WorkerTypeShortName.Sql}
        />
      );
    case ComputeNodeTypeNames.DraftScriptingNode:
    case ComputeNodeTypeNames.PublishedScriptingNode:
      return (
        <ScriptingComputeNodeEditor
          {...props}
          editorOptions={scriptingEditorOptions}
          fullHeight={props.fullHeight}
          readOnly={readOnly}
        />
      );
    case ComputeNodeTypeNames.DraftPostNode:
    case ComputeNodeTypeNames.PublishedPostNode:
      return <PostComputeNodeEditor {...props} readOnly={readOnly} />;
    case ComputeNodeTypeNames.DraftPreviewNode:
    case ComputeNodeTypeNames.PublishedPreviewNode:
      return <PreviewComputeNodeEditor {...props} readOnly={readOnly} />;
    case ComputeNodeTypeNames.DraftSyntheticNode:
    case ComputeNodeTypeNames.PublishedSyntheticNode:
      return <SyntheticComputeNodeEditor {...props} readOnly={readOnly} />;
    case ComputeNodeTypeNames.DraftS3SinkNode:
    case ComputeNodeTypeNames.PublishedS3SinkNode:
      return <S3ComputeNodeEditor {...props} readOnly={readOnly} />;
    default:
      return null;
  }
});

ComputeNodeEditor.displayName = "ComputeNodeEditor";

export default ComputeNodeEditor;
