import { Avatar, styled } from "@mui/material";
import { memo } from "react";
import { DataRoomParticipantAvatar } from "components";

const StyledAvatar = styled(Avatar)({
  ".MuiAvatar-img": {
    aspectRatio: "1 / 1",
    objectFit: "contain",
  },
});

type LogoProps = {
  logoBase64?: string;
  avatarText?: string;
  size?: string;
};

const Logo: React.FC<LogoProps> = ({
  logoBase64,
  size = "32px",
  avatarText = "",
}) => {
  return logoBase64 ? (
    <StyledAvatar
      src={`data:image;base64,${logoBase64}`}
      sx={{ height: size, margin: "2px 0", width: size }}
      variant="square"
    />
  ) : (
    <DataRoomParticipantAvatar
      name={avatarText}
      size={size}
      sx={{ aspectRatio: "1 / 1 !important" }}
    />
  );
};

Logo.displayName = "Logo";

export default memo(Logo);
