import { type MutationRetrieveComputeJobResultArgs } from "@decentriq/graphql/dist/types";
import { type ApiCoreContextInterface } from "contexts";
import { fetchJobResultSize } from "wrappers/ApolloWrapper/helpers/fetchJobResultSize";
import { type LocalResolverContext } from "../../../models";

export const makeRetrieveComputeJobResultSizeResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: null,
    args: MutationRetrieveComputeJobResultArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<number> => {
    const jobResultSize = await fetchJobResultSize(
      args,
      context,
      sessionManager
    );
    return jobResultSize;
  };
