import { useDeactivateDataRoomMutation } from "@decentriq/graphql/dist/hooks";
import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";

type DeactivateDataRoomDialogProps = {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
  closeDataRoomDetailsDialog: () => void;
};

const DeactivateDataRoomDialog: React.FC<DeactivateDataRoomDialogProps> = ({
  dataRoomId,
  open,
  onCancel,
  closeDataRoomDetailsDialog,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [deactivateDataRoom, { loading: isDeactivateDataRoomLoading }] =
    useDeactivateDataRoomMutation({
      onCompleted: () => {
        enqueueSnackbar("Data clean room has been successfully deactivated.");
        closeDataRoomDetailsDialog();
        onCancel();
      },
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Data clean room could not be deactivated."
          )
        );
        onCancel();
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            deactivatedAt: () =>
              data?.publishedDataRoom?.deactivate?.deactivatedAt,
            isActive: () => data?.publishedDataRoom?.deactivate?.isActive,
          },
          id: cache.identify({
            __typename: "PublishedDataRoom",
            id: dataRoomId,
          }),
        });
      },
      variables: { dataRoomId },
    });

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={!isDeactivateDataRoomLoading ? onCancel : undefined}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>
            Are you sure you want to deactivate this data clean room?
          </strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          This is an irreversible action. Participants will not be able to
          provision data or run computations in this DCR.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          disabled={isDeactivateDataRoomLoading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          loading={isDeactivateDataRoomLoading}
          loadingPosition="start"
          onClick={() => deactivateDataRoom()}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Deactivate DCR
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeactivateDataRoomDialog.displayName = "DeactivateDataRoomDialog";

export default memo(DeactivateDataRoomDialog);
