import { faKey } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

const KeychainSidebarMenuItem: React.FC = () => {
  return (
    <SidebarMenuItem
      data-testid="sidebar_keychain"
      icon={faKey}
      label="Keychain"
      to="/keychain"
    />
  );
};

KeychainSidebarMenuItem.displayName = "KeychainSidebarMenuItem";

export default memo(KeychainSidebarMenuItem);
