import { ClientApiError } from "@decentriq/core";
import { Key } from "@decentriq/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { type media_insights_request } from "ddc";
import { loadAsync } from "jszip";
import * as forge from "node-forge";
import { type SnackbarKey } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useApiCore } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type ActivatedAudience,
  type ActivatedAudiencesConfigWrapper,
  type Audience,
} from "../../models";
import {
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "../MediaInsightsDcrContext/MediaInsightsDcrContext";

export type AddAudienceFunctionArgs = {
  audienceType: string;
  reach: number;
  excludeSeedAudience: boolean;
  onSuccess?: () => void;
};

const advertiserAudiencesContext = createContext<{
  activatedAudiences: Audience[];
  addAudienceLoading: boolean;
  addAudience: (params: AddAudienceFunctionArgs) => void;
  publishAudience: (param: {
    audienceType: string;
    reach: number;
    activationType: string;
  }) => void;
  retryViewAudiences: () => Promise<void>;
  viewAudiencesError?: string;
  viewAudiencesLoading: boolean;
}>({
  activatedAudiences: [],
  addAudience: () => {},
  addAudienceLoading: false,
  publishAudience: () => {},
  retryViewAudiences: () => Promise.resolve(),
  viewAudiencesError: undefined,
  viewAudiencesLoading: false,
});

const audienceToActivatedAudience = ({
  reach,
  published,
  activationType,
  audienceType,
  excludeSeedAudience = false,
}: Audience): ActivatedAudience => ({
  activation_type: activationType,
  audience_type: audienceType,
  exclude_seed_audience: excludeSeedAudience,
  is_published: published,
  reach,
});

const activatedAudienceToAudience = ({
  activation_type,
  audience_type,
  is_published,
  reach,
  exclude_seed_audience = false,
}: ActivatedAudience): Audience => ({
  activationType: activation_type,
  audienceType: audience_type,
  excludeSeedAudience: exclude_seed_audience,
  id: `${audience_type}-${reach}`,
  published: is_published,
  reach,
});

export const AdvertiserAudiencesContextWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const queryClient = useQueryClient();
  const {
    dataRoomId,
    driverAttestationHash,
    isDeactivated,
    isAdvertiser,
    isObserver,
    isAgency,
  } = usePublishedMediaInsightsDcr();
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const { client, sessionManager } = useApiCore();

  const { advertiserDatasetHash } = useMediaInsightsDcrData();
  const advertiserAudiencesQueryKey = useMemo(
    () => [
      "mi-dcr-advertiser-audiences",
      dataRoomId,
      driverAttestationHash,
      advertiserDatasetHash,
    ],
    [dataRoomId, driverAttestationHash, advertiserDatasetHash]
  );

  const advertiserAudiencesQuery = useQuery({
    enabled:
      !!dataRoomId &&
      !!driverAttestationHash &&
      (isAdvertiser || isObserver || isAgency) &&
      !isDeactivated &&
      !!advertiserDatasetHash,
    queryFn: async () => {
      const [scopeId, session] = await Promise.all([
        client.ensureDcrDataScope(dataRoomId),
        sessionManager.get({
          driverAttestationHash,
        }),
      ]);
      if (
        advertiserDatasetHash === null ||
        advertiserDatasetHash === undefined
      ) {
        throw new Error("Missing advertiser dataset hash");
      }
      const request: media_insights_request.MediaInsightsRequest = {
        getAudiencesForAdvertiser: {
          dataRoomIdHex: dataRoomId,
          scopeIdHex: scopeId,
        },
      };
      const response = await session.sendMediaInsightsRequest(request);
      if (!("getAudiencesForAdvertiser" in response)) {
        throw new Error("Expected getAudiencesForAdvertiser response");
      }
      const computeNodeName =
        response.getAudiencesForAdvertiser.computeNodeName;
      const jobIdHex = response.getAudiencesForAdvertiser.jobIdHex;
      const result = await session.getComputationResult(
        { computeNodeId: computeNodeName, jobId: jobIdHex },
        { interval: 1 }
      );
      const zip = await loadAsync(result);
      const activatedAudiencesFile = zip.file("activated_audiences.json");
      if (activatedAudiencesFile === null) {
        throw new Error("activated_audiences.json not found in zip");
      }
      const activatedAudiences: ActivatedAudiencesConfigWrapper = JSON.parse(
        await activatedAudiencesFile.async("string")
      );

      // For retargeting `advertiser_manifest_hash` is `null`
      if (
        activatedAudiences.advertiser_manifest_hash !== null &&
        activatedAudiences.advertiser_manifest_hash !== advertiserDatasetHash
      ) {
        return [];
      }
      return activatedAudiences.activated_audiences.map(
        activatedAudienceToAudience
      );
    },
    queryKey: advertiserAudiencesQueryKey,
  });

  useEffect(() => {
    if (advertiserAudiencesQuery.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          advertiserAudiencesQuery.error,
          "Unable to fetch activated audiences"
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [
    advertiserAudiencesQuery.error,
    enqueueSnackbar,
    closeSnackbar,
    setErrorSnackbarId,
  ]);

  const publishActivatedAudiencesMutation = useMutation({
    mutationFn: async ({
      newActivatedAudiences,
    }: {
      oldActivatedAudiences: Audience[];
      newActivatedAudiences: Audience[];
    }) => {
      queryClient.setQueryData(
        advertiserAudiencesQueryKey,
        () => newActivatedAudiences
      );
      const [scopeId, session] = await Promise.all([
        client.ensureDcrDataScope(dataRoomId),
        sessionManager.get({
          driverAttestationHash,
        }),
      ]);
      if (
        advertiserDatasetHash == null ||
        advertiserDatasetHash === undefined
      ) {
        throw new Error("Advertiser dataset not published");
      }
      const activatedAudiencesConfig: ActivatedAudiencesConfigWrapper = {
        activated_audiences: newActivatedAudiences.map(
          audienceToActivatedAudience
        ),
        advertiser_manifest_hash: advertiserDatasetHash,
      };
      const key = new Key();
      const activatedAudienceConfigManifestHash = await client.uploadDataset(
        new TextEncoder().encode(JSON.stringify(activatedAudiencesConfig)),
        key,
        "activated_audiences_config.json",
        { isAccessory: true }
      );
      const request: media_insights_request.MediaInsightsRequest = {
        publishActivatedAudiencesConfig: {
          dataRoomIdHex: dataRoomId,
          datasetHashHex: activatedAudienceConfigManifestHash,
          encryptionKeyHex: forge.util.binary.hex.encode(key.material),
          scopeIdHex: scopeId,
        },
      };
      const response = await session.sendMediaInsightsRequest(request);
      if (!("publishActivatedAudiencesConfig" in response)) {
        throw new Error("Expected publishAdvertiserDataset response");
      }
      return;
    },
    onError: (_, { oldActivatedAudiences }) => {
      queryClient.setQueryData(
        advertiserAudiencesQueryKey,
        () => oldActivatedAudiences
      );
    },
  });

  const addAudience = useCallback(
    async ({
      audienceType,
      reach,
      excludeSeedAudience,
      onSuccess,
    }: AddAudienceFunctionArgs) => {
      const newActivatedAudiences: Audience[] = [
        {
          activationType: "lookalike",
          audienceType,
          excludeSeedAudience,
          id: `${audienceType}-${reach}`,
          published: false,
          reach,
        },
        ...(advertiserAudiencesQuery.data ?? []),
      ];
      publishActivatedAudiencesMutation.mutate(
        {
          newActivatedAudiences,
          oldActivatedAudiences: advertiserAudiencesQuery.data ?? [],
        },
        {
          onError: (error) => {
            enqueueSnackbar(
              ...mapMediaDataRoomErrorToSnackbar(
                error,
                "Failed to generate audience."
              )
            );
          },
          ...(onSuccess ? { onSuccess } : {}),
        }
      );
    },
    [
      advertiserAudiencesQuery.data,
      enqueueSnackbar,
      publishActivatedAudiencesMutation,
    ]
  );

  const publishAudience = useCallback(
    ({
      audienceType,
      reach,
      activationType,
    }: {
      audienceType: string;
      reach: number;
      activationType: string;
    }) => {
      const newActivatedAudiences = (advertiserAudiencesQuery.data ?? []).map(
        (audience) => {
          let published = audience.published;
          if (
            audience.audienceType === audienceType &&
            audience.reach === reach &&
            audience.activationType === activationType
          ) {
            published = true;
          }
          return {
            ...audience,
            published,
          };
        }
      );
      publishActivatedAudiencesMutation.mutate(
        {
          newActivatedAudiences,
          oldActivatedAudiences: advertiserAudiencesQuery.data ?? [],
        },
        {
          onError: (error) => {
            enqueueSnackbar(
              ...mapMediaDataRoomErrorToSnackbar(
                error,
                "Failed to publish audience."
              )
            );
          },
        }
      );
    },
    [
      advertiserAudiencesQuery.data,
      enqueueSnackbar,
      publishActivatedAudiencesMutation,
    ]
  );

  return (
    <advertiserAudiencesContext.Provider
      value={{
        activatedAudiences: useMemo(
          () => advertiserAudiencesQuery.data ?? [],
          [advertiserAudiencesQuery.data]
        ),
        addAudience,
        addAudienceLoading: publishActivatedAudiencesMutation.isPending,
        publishAudience,
        retryViewAudiences: useCallback(
          () => advertiserAudiencesQuery.refetch().then(),
          [advertiserAudiencesQuery]
        ),
        viewAudiencesError: useMemo(() => {
          const { error } = advertiserAudiencesQuery;
          if (error) {
            if (error instanceof ClientApiError) {
              return error.message;
            }
            return error.toString();
          }
        }, [advertiserAudiencesQuery]),
        viewAudiencesLoading: useMemo(
          () => advertiserAudiencesQuery.isLoading,
          [advertiserAudiencesQuery.isLoading]
        ),
      }}
    >
      {children}
    </advertiserAudiencesContext.Provider>
  );
};

export const useAdvertiserAudiences = () =>
  useContext(advertiserAudiencesContext);
