import { faPencil as faPencilRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import UpsertDataPartnerDisplayConfigurationDialog from "../UpsertDataPartnerDisplayConfigurationDialog/UpsertDataPartnerDisplayConfigurationDialog";

const UpdateDataPartnerDisplayConfigurationButton: React.FC = () => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  return (
    <>
      <Button
        color="primary"
        onClick={openDialog}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faPencilRegular} />}
        variant="contained"
      >
        Edit
      </Button>
      <UpsertDataPartnerDisplayConfigurationDialog
        onClose={closeDialog}
        open={isDialogOpen}
        updating={true}
      />
    </>
  );
};

UpdateDataPartnerDisplayConfigurationButton.displayName =
  "UpdateDataPartnerDisplayConfigurationButton";

export default memo(UpdateDataPartnerDisplayConfigurationButton);
