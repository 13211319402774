import { useAuth0 } from "@auth0/auth0-react";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import { useBoolean } from "ahooks";
import { Fragment, memo } from "react";
import { DeleteConsentlessAudienceDialog } from "..";

type DeleteConsentlessAudienceButtonProps = {
  audienceId: string;
  owner: string | null | undefined;
};

const DeleteConsentlessAudienceButton: React.FC<
  DeleteConsentlessAudienceButtonProps
> = ({ audienceId, owner }) => {
  const { user = {} } = useAuth0();
  const { email: currentUserEmail } = user || {};
  const [
    isConsentlessAudienceDeleteDialogOpen,
    {
      setTrue: openConsentlessAudienceDeleteDialog,
      setFalse: closeConsentlessAudienceDeleteDialog,
    },
  ] = useBoolean(false);
  return (
    <Fragment>
      <Tooltip
        disableFocusListener={true}
        disableTouchListener={true}
        placement="top"
        title={
          owner !== currentUserEmail
            ? `This action can only be performed by the audience creator: ${owner}`
            : ""
        }
      >
        <span>
          <IconButton
            color="error"
            disabled={owner !== currentUserEmail}
            onClick={openConsentlessAudienceDeleteDialog}
          >
            <FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />
          </IconButton>
        </span>
      </Tooltip>
      <DeleteConsentlessAudienceDialog
        audienceId={audienceId}
        onCancel={closeConsentlessAudienceDeleteDialog}
        open={isConsentlessAudienceDeleteDialogOpen}
      />
    </Fragment>
  );
};

DeleteConsentlessAudienceButton.displayName = "DeleteConsentlessAudienceButton";

export default memo(DeleteConsentlessAudienceButton);
