import { useAuth0 } from "@auth0/auth0-react";
import { type LookalikeMediaDataRoomLatest } from "@decentriq/core";
import {
  usePublishedLookalikeMediaDataRoomDataLabDataQuery,
  usePublishedLookalikeMediaDataRoomOrganizationsQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type FullDataLabFragment,
  type Organization,
  type TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { useMemo } from "react";
import { isNormalizedEmailIncluded } from "features/mediaDataRoom/utils";
import usePublishedLookalikeMediaDataRoomDefinition from "features/mediaDataRoomV2/contexts/MediaInsightsDcrContext/queries/usePublishedLookalikeMediaDataRoomDefinition";
import { LookalikeMediaDataRoomSupportedFeatures } from "features/mediaDataRoomV2/models";

interface PublishedLookalikeMediaDataRoomQueryHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  isDeactivated: boolean;
  isStopped: boolean;
}

export interface PublishedLookalikeMediaDataRoomQueryHookResult {
  definition: LookalikeMediaDataRoomLatest;
  loading: boolean;
  dataRoomId: string;
  driverAttestationHash: string;
  isStopped: boolean;
  isDeactivated: boolean;
  isPublisher: boolean;
  isAdvertiser: boolean;
  isAgency: boolean;
  publishedDataLab: FullDataLabFragment | null;
  wasDataLabPublishedBefore: boolean;
  isObserver: boolean;
  publisherOrganization:
    | Pick<Organization, "logo" | "__typename" | "id" | "name">
    | undefined;
  advertiserOrganization:
    | Pick<Organization, "logo" | "__typename" | "id" | "name">
    | undefined;
  supportedFeatures: {
    performanceReportEnabled: boolean;
  };
  matchingIdFormat: TableColumnFormatType;
  hashMatchingIdWith: TableColumnHashingAlgorithm | null;
}

const usePublishedMediaDataRoomQuery = ({
  dataRoomId,
  driverAttestationHash,
  isDeactivated,
  isStopped,
}: PublishedLookalikeMediaDataRoomQueryHookPayload): PublishedLookalikeMediaDataRoomQueryHookResult => {
  const { user = {} } = useAuth0();
  const { email: currentUserEmail = "" } = user || {};
  const { data: lookalikeMediaDataRoomDefinition, isLoading: loading } =
    usePublishedLookalikeMediaDataRoomDefinition({
      dataRoomId,
      driverAttestationHash,
      queryKeyPrefix: ["lal"],
    });
  const {
    mainAdvertiserEmail,
    mainPublisherEmail,
    publisherEmails: publisherUserEmails = [],
    advertiserEmails: advertiserUserEmails = [],
    agencyEmails: agencyUserEmails = [],
    observerEmails: observerUserEmails = [],
    hashMatchingIdWith,
    matchingIdFormat,
  } = lookalikeMediaDataRoomDefinition?.definition ?? {};
  const {
    isPublisher = true,
    isAdvertiser = false,
    isAgency = false,
    isObserver = false,
  } = useMemo(
    () => ({
      isAdvertiser: isNormalizedEmailIncluded(
        advertiserUserEmails,
        currentUserEmail
      ),
      isAgency: isNormalizedEmailIncluded(agencyUserEmails, currentUserEmail),
      isObserver: isNormalizedEmailIncluded(
        observerUserEmails,
        currentUserEmail
      ),
      isPublisher: isNormalizedEmailIncluded(
        publisherUserEmails,
        currentUserEmail
      ),
    }),
    [
      advertiserUserEmails,
      agencyUserEmails,
      currentUserEmail,
      observerUserEmails,
      publisherUserEmails,
    ]
  );
  // Backend query
  const { data: organizationsData } =
    usePublishedLookalikeMediaDataRoomOrganizationsQuery({
      skip: !mainAdvertiserEmail || !mainPublisherEmail,
      variables: {
        advertiserEmail: mainAdvertiserEmail!,
        publisherEmail: mainPublisherEmail!,
      },
    });

  // Backend query
  const {
    data: lookalikeMediaDataRoomDataLabData,
    loading: dataLabDataLoading,
  } = usePublishedLookalikeMediaDataRoomDataLabDataQuery({
    variables: {
      id: dataRoomId,
    },
  });

  const publishedMediaDataRoomContextValue = useMemo(
    (): PublishedLookalikeMediaDataRoomQueryHookResult => ({
      advertiserOrganization: {
        ...organizationsData?.advertiserOrganization?.organization!,
        logo: organizationsData?.advertiserOrganization?.organizationLogo,
      },
      dataRoomId,
      definition: lookalikeMediaDataRoomDefinition?.definition!,
      driverAttestationHash,
      hashMatchingIdWith: (hashMatchingIdWith ||
        null) as TableColumnHashingAlgorithm | null,
      isAdvertiser,
      isAgency,
      isDeactivated,
      isObserver,
      isPublisher,
      isStopped,
      loading: loading || dataLabDataLoading,
      matchingIdFormat: matchingIdFormat as TableColumnFormatType,
      publishedDataLab:
        lookalikeMediaDataRoomDataLabData?.publishedLookalikeMediaDataRoom
          ?.publishedDataLab ?? null,
      publisherOrganization: {
        ...organizationsData?.publisherOrganization?.organization!,
        logo: organizationsData?.publisherOrganization?.organizationLogo,
      },
      supportedFeatures: {
        performanceReportEnabled:
          lookalikeMediaDataRoomDefinition?.supportedFeatures?.includes(
            LookalikeMediaDataRoomSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION
          ) ?? false,
      },
      wasDataLabPublishedBefore:
        !!lookalikeMediaDataRoomDataLabData?.publishedLookalikeMediaDataRoom
          ?.wasDataLabPublishedBefore,
    }),
    [
      organizationsData?.advertiserOrganization?.organization,
      organizationsData?.advertiserOrganization?.organizationLogo,
      organizationsData?.publisherOrganization?.organization,
      organizationsData?.publisherOrganization?.organizationLogo,
      dataRoomId,
      lookalikeMediaDataRoomDefinition?.definition,
      lookalikeMediaDataRoomDefinition?.supportedFeatures,
      driverAttestationHash,
      hashMatchingIdWith,
      isAdvertiser,
      isAgency,
      isDeactivated,
      isObserver,
      isPublisher,
      isStopped,
      loading,
      dataLabDataLoading,
      matchingIdFormat,
      lookalikeMediaDataRoomDataLabData?.publishedLookalikeMediaDataRoom
        ?.publishedDataLab,
      lookalikeMediaDataRoomDataLabData?.publishedLookalikeMediaDataRoom
        ?.wasDataLabPublishedBefore,
    ]
  );
  return publishedMediaDataRoomContextValue;
};

export default usePublishedMediaDataRoomQuery;
