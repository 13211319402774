import { testIds } from "@decentriq/utils";
import {
  faBullhorn,
  faDatabase,
  faNewspaper,
} from "@fortawesome/pro-light-svg-icons";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { memo, useState } from "react";
import { MediaInsightsDcrCreateOrganizationRole } from "features/MediaInsightsDcr";
import { useCreationWizardStepper } from "features/MediaInsightsDcr/contexts";
import { useOrganizationPreferences } from "hooks";
import MediaInsightsDcrCreateSelectOrganizationRoleButton from "./MediaInsightsDcrCreateSelectOrganizationRoleButton";

const MediaInsightsDcrCreateSelectOrganizationRoleStep: React.FC = () => {
  const {
    numberOfSupportedMediaRoles,
    hasAdvertiserFeatures,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
  } = useOrganizationPreferences();
  const { setOrganizationRole: submitOrganizationRole, handleBackStep } =
    useCreationWizardStepper();
  const [organizationRole, setOrganizationRole] =
    useState<MediaInsightsDcrCreateOrganizationRole | null>(null);
  const columnWidth = 12 / numberOfSupportedMediaRoles;
  return (
    <>
      <DialogTitle>Create new media clean room</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography fontWeight="fontWeightSemiBold" mb={0.5} variant="body1">
            Organization role
          </Typography>
          <Typography mb={1} variant="body2">
            Select in which role you want to create the data clean room
          </Typography>
          <Grid columnSpacing={2} container={true} item={true} xs={12}>
            {hasAdvertiserFeatures && (
              <Grid item={true} xs={columnWidth}>
                <MediaInsightsDcrCreateSelectOrganizationRoleButton
                  icon={faBullhorn}
                  onSelect={setOrganizationRole}
                  role={MediaInsightsDcrCreateOrganizationRole.ADVERTISER}
                  selected={
                    organizationRole ===
                    MediaInsightsDcrCreateOrganizationRole.ADVERTISER
                  }
                  testId={
                    testIds.mediaInsightsDcr
                      .mediaInsightDcrCreateSelectOrganizationRole.advertiser
                  }
                />
              </Grid>
            )}
            {hasPublisherFeatures && (
              <Grid item={true} xs={columnWidth}>
                <MediaInsightsDcrCreateSelectOrganizationRoleButton
                  icon={faNewspaper}
                  onSelect={setOrganizationRole}
                  role={MediaInsightsDcrCreateOrganizationRole.PUBLISHER}
                  selected={
                    organizationRole ===
                    MediaInsightsDcrCreateOrganizationRole.PUBLISHER
                  }
                  testId={
                    testIds.mediaInsightsDcr
                      .mediaInsightDcrCreateSelectOrganizationRole.publisher
                  }
                />
              </Grid>
            )}
            {hasDataPartnerFeatures && (
              <Grid item={true} xs={columnWidth}>
                <MediaInsightsDcrCreateSelectOrganizationRoleButton
                  icon={faDatabase}
                  onSelect={setOrganizationRole}
                  role={MediaInsightsDcrCreateOrganizationRole.DATA_PARTNER}
                  selected={
                    organizationRole ===
                    MediaInsightsDcrCreateOrganizationRole.DATA_PARTNER
                  }
                  testId={
                    testIds.mediaInsightsDcr
                      .mediaInsightDcrCreateSelectOrganizationRole.dataPartner
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "auto",
        }}
      >
        <Button color="inherit" onClick={handleBackStep} variant="text">
          Back
        </Button>
        <Button
          color="primary"
          disabled={!organizationRole}
          onClick={() => {
            if (!organizationRole) {
              return;
            }
            submitOrganizationRole(organizationRole);
          }}
          variant="contained"
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

MediaInsightsDcrCreateSelectOrganizationRoleStep.displayName =
  "MediaInsightsDcrCreateSelectOrganizationRoleStep";

export default memo(MediaInsightsDcrCreateSelectOrganizationRoleStep);
