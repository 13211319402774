import { type Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useTabListStyles = makeStyles()((theme: Theme) => ({
  indicator: {
    display: "none",
  },
  root: {
    minHeight: "auto",
  },
}));

export const useTabStyles = makeStyles()((theme: Theme) => ({
  root: {
    minHeight: "25px",
    minWidth: "auto",
    padding: theme.spacing(0.325, 4),
  },
  selected: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const useTabPanelStyles = makeStyles()((theme: Theme) => ({
  root: {
    "&:not([hidden])": {
      display: "flex",
      flexDirection: "column",
    },
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
}));
