import { faKey, faShield } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { memo } from "react";

const ExternalConnectionCredentialsLabel: React.FC = () => {
  return (
    <Typography color="inherit">
      <FontAwesomeIcon
        icon={faKey}
        style={{ marginRight: 4, marginTop: 2, opacity: 0.5 }}
      />
      Credentials
      <Tooltip
        disableFocusListener={true}
        disableTouchListener={true}
        placement="top-start"
        title="Credentials are encrypted and utilized only once by the enclave."
      >
        <FontAwesomeIcon
          color={grey[600]}
          icon={faShield}
          style={{ cursor: "pointer", marginLeft: "4px" }}
        />
      </Tooltip>
    </Typography>
  );
};

ExternalConnectionCredentialsLabel.displayName =
  "ExternalConnectionCredentialsLabel";

export default memo(ExternalConnectionCredentialsLabel);
