import { InfoTooltip } from "@decentriq/components";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
} from "features/datasets/components/ExternalConnections";

type GoogleAdManagerFormProps = ExportDatasetFormProps;

enum GoogleAdIdentifierKind {
  cookie_encrypted = "cookie_encrypted",
  cookie_idfa = "cookie_idfa",
  ppid = "ppid",
  cookie_rida = "cookie_rida",
  cookie_tvos = "cookie_tvos",
}

const GoogleAdManagerFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    bucket: yup
      .string()
      .trim()
      .required("Ad Manager storage bucket is required"),
    identifierKind: yup
      .mixed()
      .oneOf(Object.values(GoogleAdIdentifierKind))
      .required("Identifier is required"),
    object: yup.string().trim().required("Object name is required"),
    segment: yup.string().trim().required("Audience segment ID is required"),
  }),
  credentialsFile: yup.mixed().required("Credentials file is required"),
});

type GoogleAdManagerFormValues = yup.InferType<
  typeof GoogleAdManagerFormValidationSchema
>;

const GoogleAdManagerForm: React.FC<GoogleAdManagerFormProps> = ({
  onCancel: cancelFormHandler,
  onSubmit: submitFormHandler,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        bucket: "gdfp_cookieupload_[Ad Manager network code]",
        identifierKind: GoogleAdIdentifierKind.cookie_encrypted,
        object: "",
        segment: "",
      },
      credentialsFile: undefined,
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(GoogleAdManagerFormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: GoogleAdManagerFormValues) => {
      const {
        configuration: {
          segment = "",
          bucket = "",
          object = "",
          identifierKind = "",
        } = {},
        credentialsFile,
      } = formValues;

      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          const credentialsJSON = JSON.parse(reader?.result as string);
          const credentials = JSON.stringify(credentialsJSON, null, 4);
          if (!credentials) {
            throw new Error("Credentials file is not valid");
          }
          submitFormHandler({
            input: {
              googleAdManager: {
                bucket: bucket.trim(),
                credentials,
                identifierKind,
                inputHasHeader: false,
                listId: segment.trim(),
                objectName: object.trim(),
              },
            },
          });
        };
        reader.readAsText(credentialsFile);
      } catch (error) {
        throw new Error(error as string);
      }
      reset();
    },
    [reset, submitFormHandler]
  );

  return (
    <Box>
      <form>
        <Box>
          <ExternalConnectionConfigurationLabel />
          <Box>
            <Controller
              control={control}
              name="configuration.identifierKind"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.identifierKind;
                const withError = !isEmpty(fieldError);
                return (
                  <FormControl sx={{ mb: 0.5, mt: 1, width: "260px" }}>
                    <FormLabel component="legend">Identifier</FormLabel>
                    <Select
                      displayEmpty={true}
                      error={withError}
                      size="small"
                      style={{ background: "transparent" }}
                      variant="standard"
                      {...field}
                    >
                      {Object.keys(GoogleAdIdentifierKind).map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    {withError && (
                      <FormHelperText error={withError}>
                        {fieldError?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
          </Box>

          <Controller
            control={control}
            name="configuration.segment"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.segment;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Audience segment ID"
                  placeholder="Example: 3153490"
                  size="small"
                  sx={{ mb: 1, width: "260px" }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Grid columnSpacing={1} container={true}>
            <Grid item={true} xs={8}>
              <Controller
                control={control}
                name="configuration.bucket"
                render={({ field, formState }) => {
                  const { errors } = formState;
                  const fieldError = errors?.configuration?.bucket;
                  return (
                    <TextField
                      InputProps={{
                        sx: {
                          "& .MuiInput-input": { padding: "7px 0" },
                          "&:before": { borderBottomStyle: "solid" },
                        },
                      }}
                      error={!isEmpty(fieldError)}
                      fullWidth={true}
                      helperText={fieldError?.message}
                      label="Ad Manager storage bucket"
                      // placeholder="Example: 1234512345"
                      size="small"
                      sx={{ mb: 1 }}
                      variant="standard"
                      {...field}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <Controller
                control={control}
                name="configuration.object"
                render={({ field, formState }) => {
                  const { errors } = formState;
                  const fieldError = errors?.configuration?.object;
                  return (
                    <TextField
                      InputProps={{
                        sx: {
                          "& .MuiInput-input": { padding: "7px 0" },
                          "&:before": { borderBottomStyle: "solid" },
                        },
                      }}
                      error={!isEmpty(fieldError)}
                      fullWidth={true}
                      helperText={fieldError?.message}
                      label="Object name"
                      placeholder="Example: audience_export_decentriq.csv"
                      size="small"
                      sx={{ mb: 1 }}
                      variant="standard"
                      {...field}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={2} mt={2}>
          <ExternalConnectionCredentialsLabel />
          <Controller
            control={control}
            name="credentialsFile"
            render={({
              field: { value, onChange, ...restField },
              formState,
            }) => {
              const { errors } = formState;
              const fieldError = errors?.credentialsFile;
              return (
                <FormControl sx={{ marginTop: 0.5 }}>
                  <FormLabel component="legend" sx={{ marginBottom: 0.5 }}>
                    Service account key in JSON format
                    <InfoTooltip
                      tooltip={
                        <>
                          Please{" "}
                          <a
                            href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                            rel="noreferrer"
                            style={{ color: "inherit" }}
                            target="_blank"
                          >
                            follow the instructions
                          </a>{" "}
                          to obtain a service account key
                        </>
                      }
                    />
                  </FormLabel>
                  <TextField
                    {...restField}
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        accept: "application/JSON",
                        multiple: false,
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => onChange((event?.target?.files || [])[0]),
                        type: "file",
                      },
                      sx: {
                        "& .MuiInput-input": { padding: "0 0 7px" },
                      },
                    }}
                    error={!isEmpty(fieldError)}
                    helperText={fieldError?.message}
                    size="small"
                    type="file"
                    value={value?.fileName}
                    variant="standard"
                  />
                </FormControl>
              );
            }}
          />
        </Box>
      </form>
      <ExternalConnectionActionsWrapper>
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Export
        </Button>
      </ExternalConnectionActionsWrapper>
    </Box>
  );
};

GoogleAdManagerForm.displayName = "GoogleAdManagerForm";

export default memo(GoogleAdManagerForm);
