import {
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  ComputeJobStatus,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { faCode, faCodeSimple } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Collapse, Grid, styled, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback, useEffect } from "react";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
} from "contexts";
import { getConfigExpandActionLabel } from "features/computeNode/utils";
import ComputeNodeResult from "../ComputeNodeResult/ComputeNodeResult";
import {
  ComputeNodeEditor,
  ComputeNodeEditorDialog,
  ComputeNodeToolbar,
  SyntheticDataReportDialog,
} from "../";
import useComputeNode from "./useComputeNode";

const ComputeNodeEditorCollapse = styled(Collapse)({
  ".MuiCollapse-wrapperInner": {
    display: "flex",
    flexDirection: "column",
  },
});

interface ComputeNodeProps {
  computeNodeId: string;
}

const ComputeNode = ({ computeNodeId }: ComputeNodeProps) => {
  const node = useComputeNode(computeNodeId);
  const { testing } = usePublishedDataRoom();
  const { isPublished } = useDataRoom();
  const computationTypename = node?.computationType;
  const nodeJob = node?.job;
  const job =
    isPublished &&
    ((testing && nodeJob?.purpose === ComputeJobPurpose.Standard) ||
      (!testing && nodeJob?.purpose === ComputeJobPurpose.Test))
      ? undefined
      : nodeJob;
  const hasResultToFetch =
    job?.status === ComputeJobStatus.Succeded &&
    job?.autoFetching !== ComputeJobAutoFetching.None;
  const hasSdgQualityReport =
    job &&
    job?.status === ComputeJobStatus.Succeded &&
    computationTypename === WorkerTypeShortName.Synthetic;
  const {
    isExpanded,
    isEditorDialogOpened,
    readOnly,
    isSdgQualityReportDialogOpened,
    expand,
    isExpandedConfig,
    toggleConfig,
  } = useComputeNodesVars();
  useEffect(() => {
    if (hasResultToFetch) {
      expand(computeNodeId);
    }
  }, [hasResultToFetch, expand, computeNodeId]);
  const isEditorHidden = !isExpandedConfig(computeNodeId);
  const toggleEditor = useCallback(
    () => toggleConfig(computeNodeId),
    [computeNodeId, toggleConfig]
  );
  return (
    <div style={{ flex: "0 0 auto", overflow: "hidden", width: "100%" }}>
      <ComputeNodeToolbar computeNodeId={computeNodeId} />
      <Collapse
        in={Boolean(isExpanded(computeNodeId))}
        sx={{ padding: "8px 16px" }}
        timeout="auto"
        unmountOnExit={true}
      >
        <Grid container={true}>
          <Grid item={true} xs={12}>
            {readOnly && hasResultToFetch ? (
              <Button
                color="inherit"
                onClick={toggleEditor}
                sx={{ mb: isEditorHidden ? 1 : 0 }}
              >
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={isEditorHidden ? faCode : faCodeSimple}
                  style={{
                    marginRight: "0.5rem",
                  }}
                />
                {getConfigExpandActionLabel(
                  isEditorHidden,
                  computationTypename
                )}
              </Button>
            ) : null}
            <ComputeNodeEditorCollapse
              in={!readOnly || !isEditorHidden || !hasResultToFetch}
              timeout="auto"
              unmountOnExit={true}
            >
              {isEditorDialogOpened(computeNodeId) ? (
                <Box
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    padding: "1.5rem 0",
                    position: "relative",
                  }}
                >
                  <Typography style={{ color: grey["400"] }} variant="caption">
                    Content is being edited on the modal window
                  </Typography>
                </Box>
              ) : (
                <Box style={{ height: "100%", position: "relative" }}>
                  <ComputeNodeEditor
                    computeNodeId={computeNodeId}
                    editorOptions={{ resizable: true }}
                  />
                </Box>
              )}
            </ComputeNodeEditorCollapse>
            {hasResultToFetch && (
              <ComputeNodeResult
                autoFetching={node!.job!.autoFetching}
                computationTypename={computationTypename!}
                computeNodeId={computeNodeId}
                dcrHash={node!.job!.dataRoomHash}
                driverAttestationHash={node!.job!.driverAttestationHash}
                jobId={node!.job!.id}
              />
            )}
            {computationTypename === WorkerTypeShortName.Match ? (
              <Typography
                color="textSecondary"
                sx={{ mt: readOnly ? 1 : 3 }}
                variant="body2"
              >
                This computation matches data from both tables according to
                configurable matching conditions.
              </Typography>
            ) : null}
            {computationTypename === WorkerTypeShortName.Match ? (
              readOnly ? (
                <Typography
                  color="text.secondary"
                  component="div"
                  sx={{ mt: 1 }}
                  variant="body2"
                >
                  Notes:
                  <ul>
                    <li>The combined keys from each group must be unique.</li>
                    <li>
                      Results of the Matching Node are not directly available.
                      Access these results by using them in other computations.
                    </li>
                    <li>
                      Statistics are available to all analysts of the Matching
                      Node.
                    </li>
                  </ul>
                </Typography>
              ) : (
                <Typography
                  color="text.secondary"
                  component="div"
                  sx={{ mt: 1 }}
                  variant="body2"
                >
                  Analysts of this computation will only be able to access
                  aggregated matching statistics. To use the matching result,
                  refer to it in further computations.
                </Typography>
              )
            ) : null}
          </Grid>
        </Grid>
      </Collapse>
      {isEditorDialogOpened(computeNodeId) && (
        <ComputeNodeEditorDialog computeNodeId={computeNodeId} />
      )}
      {hasSdgQualityReport && isSdgQualityReportDialogOpened(computeNodeId) ? (
        <SyntheticDataReportDialog
          computeNodeId={computeNodeId}
          jobId={node!.job!.id}
          open={isSdgQualityReportDialogOpened(computeNodeId)}
        />
      ) : null}
    </div>
  );
};

export default ComputeNode;
