import { Box, styled } from "@mui/material";

const EmptyStateContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
});

export default EmptyStateContainer;
