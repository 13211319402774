import { type VersionedSchema } from "@decentriq/components/dist/components/DatasetUploader/types";
import { type ColumnDataType } from "@decentriq/graphql/dist/types";
import type { ParseCsvOptions } from "@decentriq/uploader";
import { type Key } from "@decentriq/utils";
import { type KeychainItem } from "services/keychain";
import { type DataIngestionSource, type DataType } from "models";

export * from "./dataSource";
export * from "./dataTarget";

export type DatasetDefinitionData = string | number;

export interface DataIngestionDefinition {
  file?: File;
  progressTotal?: number;
  dataType: DataType;
  size: number;
  filename: string;
  description: string;
}

export type DatasetIngestionDefinitionCsvOptions = ParseCsvOptions;

export interface DatasetIngestionDefinition extends DataIngestionDefinition {
  csvOptions: DatasetIngestionDefinitionCsvOptions;
  preview?: DatasetDefinitionData[][];
  rows: number;
  numColumns?: number;
  numAdditionalWarnings: number;
  numberOfDuplicateRows?: number;
  numberOfNotNullableRows?: number;
  fillRatio?: number;
}

export interface FileIngestionDefinition extends DataIngestionDefinition {}

export interface DataIngestionPayload<D extends DataIngestionDefinition> {
  source: DataIngestionSource;
  definition?: D;
  datasetKeychainItem?: KeychainItem;
  shouldStoreInKeychain?: boolean;
  uploadResult?: { key: Key; manifestHash: string };
  schema?: VersionedSchema | null | undefined;
}

export interface DatasetStatisticsInterface {
  numberOfNotNullableRows?: number;
  fillRatio?: number;
  numberOfRows?: number;
}

export interface Dataset {
  datasetId: string;
  name?: string;
  datasetHash?: string;
  description?: string;
  stats?: {
    numberOfRows?: number;
    numberOfDuplicateRows?: number;
    numberOfNotNullableRows?: number;
    fillRatio?: number;
    statistics?: DatasetStatisticsInterface;
    statisticsVersion?: number;
    fileSize?: number;
  };
  updatedAt: string;
  createdAt: string;
  ownerEmail: string;
  datasetLinks?: any;
}

export enum DatasetsView {
  All = "All",
  KeychainOnly = "KeychainOnly",
}
