import { Grid, Typography } from "@mui/material";
import { memo } from "react";
import { Chip } from "components";

interface MatchingIdProps {}

const MatchingId: React.FC<MatchingIdProps> = () => {
  return (
    <Grid container={true} item={true} mt={1} spacing={1}>
      <Grid item={true} sx={{ pb: 0 }} xs={12}>
        <Typography fontWeight="fontWeightSemiBold" variant="body1">
          4. Matching ID
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        {/* TODO This is hardcoded now, but in the future versions we might want to have a dropdown */}
        <Chip label="HASHED EMAIL" />
      </Grid>
    </Grid>
  );
};

MatchingId.displayName = "MatchingId";

export default memo(MatchingId);
