import { Box, Divider, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import {
  OverlapInsightsExecutionContext,
  useLookalikeMediaOverlapInsightsContext,
} from "features/mediaDataRoomV2/contexts";
import {
  type OverlapInsightsChartTypes,
  OverlapInsightsVisualizeDirection,
} from "features/mediaDataRoomV2/models";
import {
  DownloadLookalikeMediaInsightsButton,
  LookalikeMediaInsightsCharts,
  LookalikeMediaInsightsForm,
  LookalikeMediaInsightsStatistics,
  LookalikeMediaInsightsTable,
} from "./components";

interface LookalikeMediaInsightsFormValues {
  aggregation: string;
  audienceType: string;
  chartType: OverlapInsightsChartTypes;
  visualize: {
    limit: number;
    orderBy: string;
    direction: OverlapInsightsVisualizeDirection;
  };
}

const LookalikeMediaInsights: React.FC = () => {
  const { executionContext, audienceType } =
    useLookalikeMediaOverlapInsightsContext();

  const lookalikeMediaInsightsForm = useForm<LookalikeMediaInsightsFormValues>({
    defaultValues: {
      aggregation: "",
      audienceType: audienceType || "",
      chartType: "RADAR",
      visualize: {
        direction: OverlapInsightsVisualizeDirection.top,
        limit: 20,
        orderBy: "",
      },
    },
    mode: "onChange",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <FormProvider {...lookalikeMediaInsightsForm}>
        <Grid container={true} mb={3}>
          <Grid container={true} item={true} xs={3}>
            <LookalikeMediaInsightsForm />
          </Grid>
          <Grid container={true} item={true} justifyContent="center" xs={1}>
            <Divider flexItem={true} orientation="vertical" variant="middle" />
          </Grid>
          <Grid
            container={true}
            flexDirection="column"
            item={true}
            justifyContent="center"
            xs={8}
          >
            {executionContext ===
              OverlapInsightsExecutionContext.insightsTab && (
              <LookalikeMediaInsightsStatistics />
            )}
            <Box sx={{ position: "relative", width: "100%" }}>
              <LookalikeMediaInsightsCharts />
              <Box sx={{ position: "absolute", right: 0, top: 0 }}>
                <DownloadLookalikeMediaInsightsButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <LookalikeMediaInsightsTable />
      </FormProvider>
    </div>
  );
};

LookalikeMediaInsights.displayName = "LookalikeMediaInsights";

export default LookalikeMediaInsights;
