import { TabList } from "@mui/lab";
import { styled } from "@mui/material";
import { grey } from "@mui/material/colors";

const MediaDataRoomInsightsChartTabs = styled(TabList)(
  ({ theme: { spacing, palette } }) => ({
    "& .MuiTab-iconWrapper": {
      marginBottom: "0 !important",
      marginRight: spacing(0.5),
    },
    "& .MuiTab-root": {
      "&.Mui-selected": {
        background: "rgba(40, 144, 144, 0.08)",
        color: palette.primary.main,
      },
      "&:not(:last-of-type)": {
        marginRight: "-1px",
      },
      border: `1px solid ${grey[200]}`,
      flexDirection: "row",
      height: spacing(4),
      minHeight: spacing(4),
      minWidth: "auto",
      padding: spacing(1.5),
      textTransform: "capitalize",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    height: spacing(4),
    minHeight: spacing(4),
  })
);

export default MediaDataRoomInsightsChartTabs;
