import { useOrganizationPageQuery } from "@decentriq/graphql/dist/hooks";
import {
  OrganizationState,
  OrganizationUsersDocument,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import {
  CollaboratingOrganizations,
  EmptyData,
  ExternalInvitationsList,
  OrganizationDataRooms,
  OrganizationForm,
  UsersList,
} from "components";
import {
  DataPartnerConfigurations,
  DataPartnerPortalWrapper,
} from "features/dataPartnerPortal";
import {
  PublisherConfigurations,
  PublisherPortalWrapper,
} from "features/publisherPortal";
import { useUserRole } from "hooks";
import { useTabListStyles, useTabPanelStyles, useTabStyles } from "styles";

export enum OrganizationPageTabs {
  SETTINGS = "settings",
  USERS = "users",
  DATAROOMS = "datarooms",
  COLLABORATING_ORGANIZATIONS = "collaborating-organizations",
  EXTERNAL_INVITATIONS = "external-invitations",
  PUBLISHER_CONFIGURATIONS = "publisher-configurations",
  DATA_PARTNER_CONFIGURATIONS = "data-partner-configurations",
}

interface OrganizationAdminProps {
  activeTab: OrganizationPageTabs;
  baseUrl: string;
}

const OrganizationAdmin: React.FC<OrganizationAdminProps> = ({
  activeTab,
  baseUrl,
}) => {
  const { classes: tabClasses } = useTabStyles();
  const { classes: tabListClasses } = useTabListStyles();
  const { classes: tabPanelClasses } = useTabPanelStyles();
  const { organizationId } = useParams();
  const { isSuperAdmin } = useUserRole();
  const { data } = useOrganizationPageQuery({
    skip: !organizationId,
    variables: { organizationId: organizationId ?? "" },
  });
  const {
    name: organizationName,
    state: organizationLicense,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
  } = data?.organization ?? {};
  const showPublisherConfigurations = useMemo(
    () => hasPublisherFeatures && isSuperAdmin,
    [hasPublisherFeatures, isSuperAdmin]
  );
  const showDataPartnerConfigurations = useMemo(
    () => hasDataPartnerFeatures && isSuperAdmin,
    [hasDataPartnerFeatures, isSuperAdmin]
  );
  if (organizationId === undefined) {
    return <EmptyData secondaryText="Organization not provided" />;
  }
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.bar",
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Typography variant="h6">
          <strong>
            Admin Portal
            {organizationName ? ` for ${organizationName}` : ""}
          </strong>
        </Typography>
      </Box>
      <TabContext value={activeTab}>
        <Box sx={{ backgroundColor: "background.bar" }}>
          <TabList
            classes={tabListClasses}
            textColor="inherit"
            variant="scrollable"
          >
            <Tab
              classes={tabClasses}
              component={Link}
              data-testid={testIds.adminPortal.organizationTabList.settings}
              label="SETTINGS"
              to={`${baseUrl}/${OrganizationPageTabs.SETTINGS}`}
              value={OrganizationPageTabs.SETTINGS}
            />
            <Tab
              classes={tabClasses}
              component={Link}
              label="USERS"
              to={`${baseUrl}/${OrganizationPageTabs.USERS}`}
              value={OrganizationPageTabs.USERS}
            />
            <Tab
              classes={tabClasses}
              component={Link}
              label="DATA CLEAN ROOMS"
              to={`${baseUrl}/${OrganizationPageTabs.DATAROOMS}`}
              value={OrganizationPageTabs.DATAROOMS}
            />
            <Tab
              classes={tabClasses}
              component={Link}
              label="COLLABORATING ORGANIZATIONS"
              to={`${baseUrl}/${OrganizationPageTabs.COLLABORATING_ORGANIZATIONS}`}
              value={OrganizationPageTabs.COLLABORATING_ORGANIZATIONS}
            />
            {organizationLicense !== OrganizationState.Passive && (
              <Tab
                classes={tabClasses}
                component={Link}
                label="EXTERNAL INVITATIONS"
                to={`${baseUrl}/${OrganizationPageTabs.EXTERNAL_INVITATIONS}`}
                value={OrganizationPageTabs.EXTERNAL_INVITATIONS}
              />
            )}
            {showPublisherConfigurations && (
              <Tab
                classes={tabClasses}
                component={Link}
                data-testid={
                  testIds.adminPortal.organizationTabList
                    .publisherConfigurations
                }
                label="PUBLISHER CONFIGURATIONS"
                to={`${baseUrl}/${OrganizationPageTabs.PUBLISHER_CONFIGURATIONS}`}
                value={OrganizationPageTabs.PUBLISHER_CONFIGURATIONS}
              />
            )}
            {showDataPartnerConfigurations && (
              <Tab
                classes={tabClasses}
                component={Link}
                data-testid={
                  testIds.adminPortal.organizationTabList
                    .dataPartnerConfigurations
                }
                label="DATA PARTNER CONFIGURATIONS"
                to={`${baseUrl}/${OrganizationPageTabs.DATA_PARTNER_CONFIGURATIONS}`}
                value={OrganizationPageTabs.DATA_PARTNER_CONFIGURATIONS}
              />
            )}
          </TabList>
        </Box>
        <Box sx={{ flex: 1, overflow: "hidden" }}>
          <TabPanel
            classes={tabPanelClasses}
            sx={{
              flex: 1,
              flexDirection: "column",
              height: "100%",
            }}
            value={OrganizationPageTabs.SETTINGS}
          >
            <OrganizationForm organizationId={organizationId} />
          </TabPanel>
          <TabPanel
            classes={tabPanelClasses}
            sx={{
              flex: 1,
              flexDirection: "column",
              height: "100%",
            }}
            value={OrganizationPageTabs.USERS}
          >
            <Typography gutterBottom={true} variant="body2">
              This lets you add users to your organisation. You can only add a
              user whose email address matches one of the allowed domains of
              your organisation. This user’s use of the Decentriq Platform will
              be counted against your organisation’s quota.
              <br />
              To invite external collaborators to the Decentriq Platform, use
              the External Invitations tab.
            </Typography>
            <UsersList
              dataKey="organization.users"
              query={OrganizationUsersDocument}
              variables={{ organizationId }}
            />
          </TabPanel>
          <TabPanel
            classes={tabPanelClasses}
            sx={{
              flex: 1,
              flexDirection: "column",
              height: "100%",
            }}
            value={OrganizationPageTabs.DATAROOMS}
          >
            <OrganizationDataRooms organizationId={organizationId} />
          </TabPanel>
          <TabPanel
            classes={tabPanelClasses}
            sx={{
              flex: 1,
              flexDirection: "column",
              height: "100%",
            }}
            value={OrganizationPageTabs.COLLABORATING_ORGANIZATIONS}
          >
            <CollaboratingOrganizations organizationId={organizationId} />
          </TabPanel>
          {organizationLicense !== OrganizationState.Passive && (
            <TabPanel
              classes={tabPanelClasses}
              sx={{
                flex: 1,
                flexDirection: "column",
                height: "100%",
              }}
              value={OrganizationPageTabs.EXTERNAL_INVITATIONS}
            >
              <Typography gutterBottom={true} variant="body2">
                This lets you invite external users who you want to collaborate
                with. These users will only be able to participate in data clean
                rooms but not create them. They will not appear under your
                organisation and do not affect your organisation’s quota.
              </Typography>
              <ExternalInvitationsList organizationId={organizationId} />
            </TabPanel>
          )}
          {showPublisherConfigurations && (
            <TabPanel
              classes={tabPanelClasses}
              sx={{
                flex: 1,
                flexDirection: "column",
                height: "100%",
              }}
              value={OrganizationPageTabs.PUBLISHER_CONFIGURATIONS}
            >
              <PublisherPortalWrapper organizationId={organizationId}>
                <PublisherConfigurations />
              </PublisherPortalWrapper>
            </TabPanel>
          )}
          {showDataPartnerConfigurations && (
            <TabPanel
              classes={tabPanelClasses}
              sx={{
                flex: 1,
                flexDirection: "column",
                height: "100%",
              }}
              value={OrganizationPageTabs.DATA_PARTNER_CONFIGURATIONS}
            >
              <DataPartnerPortalWrapper organizationId={organizationId}>
                <DataPartnerConfigurations />
              </DataPartnerPortalWrapper>
            </TabPanel>
          )}
        </Box>
      </TabContext>
    </>
  );
};
OrganizationAdmin.displayName = "OrganizationAdmin";
export default OrganizationAdmin;
