import {
  FormControl,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { memo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  OverlapInsightsExecutionContext,
  useLookalikeMediaOverlapInsightsContext,
} from "features/mediaDataRoomV2/contexts";

const LookalikeMediaInsightsAdvertiserAudienceSelector: React.FC = () => {
  const { control: formControl, setValue } = useFormContext();
  const {
    audienceType: activationAudienceType,
    executionContext,
    overlapInsightsStatistics: overlapStatistics,
  } = useLookalikeMediaOverlapInsightsContext();

  useEffect(() => {
    if (
      activationAudienceType &&
      executionContext !== OverlapInsightsExecutionContext.insightsTab
    ) {
      setValue("audienceType", activationAudienceType);
      return;
    }
    const advertiserAudienceDefaultValue =
      overlapStatistics.filter(({ overlapSize }) => overlapSize !== 0)[0]
        ?.audienceType || "";
    // Set default value
    if (advertiserAudienceDefaultValue) {
      setValue("audienceType", advertiserAudienceDefaultValue);
    }
  }, [activationAudienceType, executionContext, overlapStatistics, setValue]);

  // There is no reason to render the select in See Insights dialog, for instance, as value
  // will always be preselected. However, this value has to be set to the form values for
  // utilizing it in all the other Insights form components
  if (executionContext !== OverlapInsightsExecutionContext.insightsTab) {
    return null;
  }

  return (
    <FormControl sx={{ marginBottom: 2, width: "100%" }}>
      <FormLabel component="legend" sx={{ paddingLeft: "2px" }}>
        Advertiser audience
      </FormLabel>
      <Controller
        control={formControl}
        name="audienceType"
        render={({ field }) => {
          return (
            <Select
              displayEmpty={true}
              fullWidth={true}
              multiple={false}
              renderValue={(value) => value}
              sx={{
                ...(overlapStatistics.length === 1
                  ? {
                      "& .MuiSelect-icon": { display: "none" },
                      "&.Mui-disabled": { background: "white" },
                    }
                  : {}),
                background: "transparent",
              }}
              variant="standard"
              {...field}
            >
              {overlapStatistics.map(({ audienceType, overlapSize }) => (
                <MenuItem
                  disabled={overlapSize === 0}
                  key={audienceType}
                  value={audienceType}
                >
                  <ListItemText
                    primary={audienceType}
                    secondary={overlapSize === 0 ? "Overlap is too small" : ""}
                    secondaryTypographyProps={{
                      sx: {
                        maxWidth: "340px",
                        whiteSpace: "normal",
                      },
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};

LookalikeMediaInsightsAdvertiserAudienceSelector.displayName =
  "LookalikeMediaInsightsAdvertiserAudienceSelector";

export default memo(LookalikeMediaInsightsAdvertiserAudienceSelector);
