import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  type Theme,
} from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";
import { type UnifiedSchemaPresentation } from "utils/dataset";

const useStyles = makeStyles()((theme: Theme) => ({
  cell: {
    border: "none",
    padding: "8px !important",
  },
  headCell: {
    color: "inherit",
    padding: "8px !important",
  },
  row: {
    "&:nth-of-type(even)": {
      backgroundColor: "whitesmoke",
    },
  },
}));

interface DatasetSchemaProps {
  schema: UnifiedSchemaPresentation;
}

const DatasetSchema: React.FC<DatasetSchemaProps> = ({
  schema: { columns },
}) => {
  const { classes: styles } = useStyles();
  if (!columns.length) {
    return (
      <Alert severity="info">Schema is not provided for this dataset</Alert>
    );
  }
  const hasHashedColumns = columns.some(({ hashed }) => hashed !== null);
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ borderBottom: "1px solid lightgrey", mb: 0.5 }}>
            <TableCell className={styles.headCell} sx={{ minWidth: "250px" }}>
              Column
            </TableCell>
            <TableCell className={styles.headCell}>Type</TableCell>
            <TableCell className={styles.headCell}>
              Allow empty values
            </TableCell>
            {hasHashedColumns && (
              <TableCell className={styles.headCell}>Hashed values</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {columns.map(({ name, type, nullable, hashed }, index) => (
            <TableRow className={styles.row} key={index}>
              <TableCell className={styles.cell} sx={{ minWidth: "250px" }}>
                {name}
              </TableCell>
              <TableCell className={styles.cell}>{type}</TableCell>
              <TableCell className={styles.cell}>
                {nullable && (
                  <FontAwesomeIcon fixedWidth={true} icon={faCheck} />
                )}
              </TableCell>
              {hasHashedColumns && (
                <TableCell className={styles.cell}>
                  {hashed && (
                    <FontAwesomeIcon fixedWidth={true} icon={faCheck} />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DatasetSchema.displayName = "DatasetSchema";

export default memo(DatasetSchema);
