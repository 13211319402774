import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";

interface DeprovisionConfirmationDialogProps {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
  onDeprovision: () => Promise<void>;
}

const DeprovisionConfirmationDialog: React.FC<
  DeprovisionConfirmationDialogProps
> = ({ dataRoomId, open, onCancel, onDeprovision }) => {
  const [deprovisioning, setDeprovisioning] = useState(false);
  const handleDeprovision = useCallback(async () => {
    try {
      setDeprovisioning(true);
      await onDeprovision();
      onCancel();
    } finally {
      setDeprovisioning(false);
    }
  }, [setDeprovisioning, onDeprovision, onCancel]);
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} role="delete-dialog">
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>
            Are you sure you want to deprovision from Advertiser Audience?
          </strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          Changing the customer list will delete all insights and audiences from
          this data clean room. The lookalike model will have to be trained in
          the newly provisioned dataset and might take some time.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={deprovisioning} onClick={onCancel}>
          Go back
        </Button>
        <LoadingButton
          color="error"
          disabled={deprovisioning}
          loading={deprovisioning}
          onClick={handleDeprovision}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Deprovision
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeprovisionConfirmationDialog.displayName = "DeprovisionConfirmationDialog";

export default DeprovisionConfirmationDialog;
