import { Box } from "@mui/material";
import { memo } from "react";
import { TagCloud } from "react-tagcloud";
import { useAudienceOverlapInsightsData } from "features/mediaDataRoom/hooks";

type TagsCloudProps = {
  selectedSegments: string[];
  selectedAudienceType: string;
};

const TagsCloud: React.FC<TagsCloudProps> = ({
  selectedSegments = [],
  selectedAudienceType,
}) => {
  const { overlapInsights } =
    useAudienceOverlapInsightsData(selectedAudienceType);

  const tagsCloudData = overlapInsights.map(({ segment, netPropensity }) => ({
    count: netPropensity * 1000,
    value: segment,
  }));

  return (
    <Box sx={{ height: "calc(100vh - 600px)", width: "80%" }}>
      <TagCloud
        disableRandomColor={true}
        maxSize={35}
        minSize={12}
        shuffle={true}
        tags={tagsCloudData}
      />
    </Box>
  );
};

TagsCloud.displayName = "TagsCloud";

export default memo(TagsCloud);
