import { data_science } from "@decentriq/core";
import { MergeRemoteSubmittedDataRoomRequestDocument } from "@decentriq/graphql/dist/hooks";
import {
  type MutationMergeSubmittedDataRoomRequestArgs,
  SubmittedDataRoomRequestSignaturesDocument,
} from "@decentriq/graphql/dist/types";
import { type Mutex } from "async-mutex";
import * as forge from "node-forge";
import { type ApiCoreContextInterface } from "contexts";
import { maybeUseDataRoomSecret } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";
import { fetchDataScienceDataRoom } from "../../PublishedDataRoom";

export function toRecord<T extends Record<string, any>, K extends keyof T>(
  array: T[],
  selector: K
): Record<T[K], T> {
  return array.reduce(
    (acc, item) => ({ ...acc, [item[selector]]: item }),
    {} as Record<T[K], T>
  );
}

export const makeMergeSubmittedDataRoomRequestResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"],
    mutexMap: Map<string, Mutex>
  ) =>
  async (
    _obj: any,
    args: MutationMergeSubmittedDataRoomRequestArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<void> => {
    const { id, dcrHash, driverAttestationHash } = args.input;
    const response = await context.client.query({
      query: SubmittedDataRoomRequestSignaturesDocument,
      variables: {
        id,
      },
    });
    const approvalSignatures =
      response.data.submittedDataRoomRequest.signers.nodes
        .map((signer) => {
          if (!signer.signature) {
            throw new Error(
              "Missing signature from signer: " + signer.userEmail
            );
          }
          return [signer.userEmail, signer.signature];
        })
        .reduce(
          (acc, [email, signature]) => {
            return {
              ...acc,
              [email]: forge.util.binary.base64.decode(signature),
            };
          },
          {} as Record<string, Uint8Array>
        );
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    await wrapper.mergeCommit(
      response.data.submittedDataRoomRequest.enclaveCommitId,
      approvalSignatures
    );
    await context.client.mutate({
      mutation: MergeRemoteSubmittedDataRoomRequestDocument,
      variables: {
        id,
      },
    });
    await fetchDataScienceDataRoom(
      {
        driverAttestationHash,
        id: dcrHash,
      },
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap,
      true
    );
    return;
  };
