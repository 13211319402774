import { Box, CircularProgress, Typography } from "@mui/material";
import { memo } from "react";
import useMicrosoftConnectionConfigurationData from "./useMicrosoftConnectionConfigurationData";

type MicrosoftConnectionConfigurationProps = {
  connectionId: string;
};

const MicrosoftConnectionConfiguration: React.FC<
  MicrosoftConnectionConfigurationProps
> = ({ connectionId }) => {
  const {
    microsoftUploadJob,
    memberId,
    microsoftDspMember,
    segmentCode,
    segmentShortName,
    isMicrosoftUploadJobLoading,
  } = useMicrosoftConnectionConfigurationData(connectionId);
  const { job_id, last_modified, upload_url } = microsoftUploadJob || {};
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">
          <strong>Member ID:</strong> {memberId || "-"}
        </Typography>
        <Typography variant="body2">
          <strong>Microsoft DSP Member:</strong> {microsoftDspMember || "-"}
        </Typography>
        <Typography variant="body2">
          <strong>Segment Code:</strong> {segmentCode || "-"}
        </Typography>
        <Typography variant="body2">
          <strong>Segment Short Name:</strong> {segmentShortName || "-"}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" mt={2}>
        <Typography ml={-2} variant="body2">
          Microsoft Upload Job Details
          {isMicrosoftUploadJobLoading && (
            <CircularProgress
              color="inherit"
              size={10}
              sx={{ marginLeft: 1 }}
            />
          )}
        </Typography>
        {!isMicrosoftUploadJobLoading ? (
          <>
            <Typography variant="body2">
              <strong>Job ID:</strong> {job_id || "-"}
            </Typography>
            <Typography variant="body2">
              <strong>Last Modified:</strong> {last_modified || "-"}
            </Typography>
            <Typography variant="body2">
              <strong>Upload URL:</strong> {upload_url || "-"}
            </Typography>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

MicrosoftConnectionConfiguration.displayName =
  "MicrosoftConnectionConfiguration";

export default memo(MicrosoftConnectionConfiguration);
