import { useMediaDataRoomActivationQuery } from "@decentriq/graphql/dist/hooks";
import { ActivationType } from "@decentriq/graphql/dist/types";
import { Alert } from "@mui/material";
import { memo } from "react";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import AdvertiserConsentlessMediaDataRoomActivation from "./components/AdvertiserConsentlessMediaDataRoomActivation/AdvertiserConsentlessMediaDataRoomActivation";
import DirectMediaDataRoomActivation from "./components/DirectMediaDataRoomActivation/DirectMediaDataRoomActivation";

interface MediaDataRoomActivationProps {
  id: string;
}

const MediaDataRoomActivation = memo<MediaDataRoomActivationProps>(({ id }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { data } = useMediaDataRoomActivationQuery({
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          "Media data clean room could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    variables: {
      id,
    },
  });
  if (!data?.publishedMediaDataRoom?.activationType) {
    return <></>;
  }
  const isDataProvisioned =
    data.publishedMediaDataRoom.advertiserDatasetHash &&
    data.publishedMediaDataRoom.publisherDatasetHash;
  if (!isDataProvisioned) {
    return (
      <Alert severity="info">
        Waiting until both parties provisioned data.
      </Alert>
    );
  }
  const activationType = data?.publishedMediaDataRoom?.activationType;
  switch (activationType) {
    case ActivationType.Direct:
      return <DirectMediaDataRoomActivation id={id} />;
    case ActivationType.Consentless:
      return <AdvertiserConsentlessMediaDataRoomActivation id={id} />;
  }
});

MediaDataRoomActivation.displayName = "MediaDataRoomActivation";

export default memo(MediaDataRoomActivation);
