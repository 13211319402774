import { TextField } from "@mui/material";
import { isEmpty } from "lodash";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const S3BucketFormConfigurationFields: React.FC = () => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name="configuration.url"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.configuration?.url;
          return (
            <TextField
              InputProps={{
                sx: {
                  "& .MuiInput-input": { padding: "7px 0" },
                  "&:before": { borderBottomStyle: "solid" },
                },
              }}
              error={!isEmpty(fieldError)}
              fullWidth={true}
              helperText={fieldError?.message}
              label="S3 Bucket name"
              placeholder="Example: my-bucket-name"
              size="small"
              sx={{ mb: 1 }}
              variant="standard"
              {...field}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="configuration.region"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.configuration?.region;
          return (
            <TextField
              InputProps={{
                sx: {
                  "& .MuiInput-input": { padding: "7px 0" },
                  "&:before": { borderBottomStyle: "solid" },
                },
              }}
              error={!isEmpty(fieldError)}
              fullWidth={true}
              helperText={fieldError?.message}
              label="Region code"
              placeholder="Example: eu-central-2"
              size="small"
              sx={{ mb: 1 }}
              variant="standard"
              {...field}
            />
          );
        }}
      />
    </>
  );
};

S3BucketFormConfigurationFields.displayName = "S3BucketFormConfigurationFields";

export default memo(S3BucketFormConfigurationFields);
