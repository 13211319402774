import { faTable } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

const DatasetsSidebarMenuItem: React.FC = () => {
  return (
    <SidebarMenuItem
      data-testid="sidebar_datasets"
      icon={faTable}
      label="Datasets"
      to="/datasets"
    />
  );
};

DatasetsSidebarMenuItem.displayName = "DatasetsSidebarMenuItem";

export default memo(DatasetsSidebarMenuItem);
