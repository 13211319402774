import { InfoTooltip } from "@decentriq/components";
import {
  type DataTargetType,
  PermutiveServiceProvider,
} from "@decentriq/graphql/dist/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { ExternalConnectionsIcon } from "features/datasets";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
} from "features/datasets/components/ExternalConnections";
import {
  GoogleCloudStorageFormBucketField,
  GoogleCloudStorageFormCredentials,
} from "features/datasets/components/ImportData/ImportExternalDataDialog/components";
import { dataTargetTypePresentation } from "features/datasets/models";

type PermutiveFormProps = ExportDatasetFormProps;

const PermutiveFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    apiKey: yup.string().trim().required("Permutive API Key is required"),
    bucketName: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.GoogleCloudStorage,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Bucket name is required"),
      }),
    importId: yup.string().trim().required("Import ID is required"),
    objectName: yup.string().trim().required("Object name is required"),
    region: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.S3,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Region is required"),
      }),
    segmentCode: yup.string().trim().required("Segment code is required"),
    segmentName: yup.string().trim().required("Segment name is required"),
    serviceProvider: yup
      .mixed()
      .oneOf(Object.values(PermutiveServiceProvider))
      .required("Service provider is required"),
    url: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.S3,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Bucket URL is required"),
      }),
  }),
  credentials: yup.object().when("configuration.serviceProvider", {
    is: (serviceProvider: PermutiveServiceProvider) =>
      serviceProvider === PermutiveServiceProvider.S3,
    otherwise: () =>
      yup.object().shape({
        accessKey: yup.string(),
        credentialsFile: yup.mixed().required("Credentials file is required"),
        secretKey: yup.string(),
      }),
    then: () =>
      yup.object().shape({
        accessKey: yup.string().trim().required("Access key is required"),
        credentialsFile: yup.mixed(),
        secretKey: yup.string().trim().required("Secret Key is required"),
      }),
  }),
});

// TODO: figure out typings here
type PermutiveFormValues = yup.Asserts<typeof PermutiveFormValidationSchema>;

const PermutiveForm: React.FC<PermutiveFormProps> = ({
  onSubmit: submitFormHandler,
  onCancel: cancelFormHandler,
  defaultValues,
}) => {
  const permutiveHookForm = useForm({
    defaultValues: {
      configuration: {
        apiKey: "",
        bucketName: "", // Cloud Storage configuration
        importId: "",
        objectName: defaultValues?.datasetName || "",
        region: "", // S3 bucket configuration
        segmentCode: "",
        segmentName: "",
        serviceProvider: PermutiveServiceProvider.GoogleCloudStorage,
        url: "", // S3 bucket configuration
      },
      credentials: {
        accessKey: "", // S3 bucket credentials
        credentialsFile: undefined, // Cloud Storage Credentials
        secretKey: "", // S3 bucket credentials
      },
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(PermutiveFormValidationSchema),
  });
  const { control, handleSubmit, reset } = permutiveHookForm;

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: PermutiveFormValues) => {
      const {
        configuration: {
          serviceProvider,
          importId = "",
          segmentCode = "",
          segmentName = "",
          apiKey = "",
          bucketName = "",
          objectName = "",
          url = "",
          region = "",
        } = {},
        credentials: { accessKey = "", secretKey = "", credentialsFile } = {},
      } = formValues;

      const permutiveCommonFields = {
        credentials: {
          apiKey: apiKey.trim(),
        },
        importId: importId.trim(),
        inputHasHeader: false,
        segmentCode: segmentCode.trim(),
        segmentName: segmentName.trim(),
      };

      if (serviceProvider === PermutiveServiceProvider.S3) {
        submitFormHandler({
          input: {
            permutive: {
              aws: {
                credentials: {
                  accessKey: accessKey.trim(),
                  secretKey: secretKey.trim(),
                },
                targetConfig: {
                  bucket: url.trim(),
                  objectKey: objectName.trim(),
                  region: region.trim(),
                },
              },
              ...permutiveCommonFields,
            },
          },
        });
      }

      if (serviceProvider === PermutiveServiceProvider.GoogleCloudStorage) {
        try {
          const reader = new FileReader();
          reader.onloadend = () => {
            const credentialsJSON = JSON.parse(reader?.result as string);
            const credentials = JSON.stringify(credentialsJSON, null, 4);
            if (!credentials) {
              throw new Error("Credentials file is not valid");
            }
            submitFormHandler({
              input: {
                permutive: {
                  gcs: {
                    bucketName: bucketName.trim(),
                    credentials,
                    objectName: objectName.trim(),
                  },
                  ...permutiveCommonFields,
                },
              },
            });
          };
          reader.readAsText(credentialsFile);
        } catch (error) {
          throw new Error(error as string);
        }
      }
      reset();
    },
    [reset, submitFormHandler]
  );

  return (
    <Box>
      <FormProvider {...permutiveHookForm}>
        <Box>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.apiKey"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.apiKey;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Permutive API Key"
                  placeholder="Example: e26272e-ab1e-4254-b35a-3d225f9dca56"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.segmentName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.segmentName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Segment name"
                  placeholder="Example: Data Clean Room"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.segmentCode"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.segmentCode;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Segment code"
                  placeholder="Example: 1234"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.importId"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.importId;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label={
                    <>
                      Import ID
                      <InfoTooltip
                        tooltip={
                          <>
                            Please check the{" "}
                            <a
                              href={
                                "https://support.permutive.com/hc/en-us/articles/360012495920-Audience-Import-Send-Segments-to-Permutive"
                              }
                              rel="noreferrer"
                              style={{ color: "inherit" }}
                              target="_blank"
                            >
                              Permutive documentation
                            </a>{" "}
                            for more details about importing.
                          </>
                        }
                      />
                    </>
                  }
                  placeholder="Example: ffedc3c9-d8fd-48f2-9719-12bfa83eb30d8"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              marginBottom: "16px",
            }}
          >
            <ExternalConnectionsIcon
              connectionType={
                PermutiveServiceProvider.GoogleCloudStorage as unknown as DataTargetType
              }
            />
            <Typography sx={{ marginLeft: "4px" }}>
              {dataTargetTypePresentation.get(
                PermutiveServiceProvider.GoogleCloudStorage as unknown as DataTargetType
              )}{" "}
              credentials
            </Typography>
          </Box>
          <Controller
            control={control}
            name="configuration.objectName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.objectName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Object name"
                  placeholder="Example: my-object-name"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <GoogleCloudStorageFormBucketField />
        </Box>
        <Box mb={2} mt={2}>
          <GoogleCloudStorageFormCredentials />
        </Box>
      </FormProvider>
      <ExternalConnectionActionsWrapper>
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Export
        </Button>
      </ExternalConnectionActionsWrapper>
    </Box>
  );
};

PermutiveForm.displayName = "PermutiveForm";

export default memo(PermutiveForm);
