import { Box } from "@mui/material";
import { memo } from "react";
import { OrganizationDataRoomsList } from "components";

type OrganizationDataRoomsProps = {
  organizationId: string;
};

const OrganizationDataRooms: React.FC<OrganizationDataRoomsProps> = ({
  organizationId,
}) => {
  return (
    <Box
      sx={{
        alignItems: "flex-start",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
      }}
    >
      <OrganizationDataRoomsList organizationId={organizationId} />
    </Box>
  );
};

OrganizationDataRooms.displayName = "OrganizationDataRooms";

export default memo(OrganizationDataRooms);
