import { memo } from "react";

type TradeDeskFormProps = {};

const TradeDeskForm: React.FC<TradeDeskFormProps> = () => {
  return <>TradeDesk form</>;
};

TradeDeskForm.displayName = "TradeDeskForm";

export default memo(TradeDeskForm);
