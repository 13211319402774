import {
  ActivationType,
  type PublishedMediaDataRoom,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextInterface } from "contexts";
import {
  retrieveMediaDataRoomCached,
  retrievePublishedDatasetsCached,
} from "wrappers/ApolloWrapper/resolvers/media";
import { type LocalResolverContext } from "../../models";

export const makePublishedMediaDataRoomResolvers = (
  client: ApiCoreContextInterface["client"],
  sessionManager: ApiCoreContextInterface["sessionManager"],
  store: ApiCoreContextInterface["store"]
) => ({
  activationDownloadByAdvertiser: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { enableDownloadByAdvertiser } = data;
    return enableDownloadByAdvertiser;
  },
  activationDownloadByAgency: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { enableDownloadByAgency } = data;
    return enableDownloadByAgency;
  },
  activationDownloadByPublisher: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { enableDownloadByPublisher } = data;
    return enableDownloadByPublisher;
  },
  activationType: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { activationType } = data;
    switch (activationType) {
      case "consentless":
        return ActivationType.Consentless;
      case "direct":
        return ActivationType.Direct;
      default:
        return null;
    }
  },
  advertiserDatasetHash: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrievePublishedDatasetsCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { advertiserDatasetHash } = data || {};
    return advertiserDatasetHash;
  },
  advertiserUserEmails: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { advertiserEmails: advertiserUserEmails = [] } = data;
    return advertiserUserEmails;
  },
  agencyUserEmails: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { agencyEmails: agencyUserEmails = [] } = data;
    return agencyUserEmails;
  },
  enableAuditLogRetrieval: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { enableAuditLogRetrieval } = data;
    return enableAuditLogRetrieval;
  },
  enableOverlapInsights: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { enableOverlapInsights } = data;
    return enableOverlapInsights;
  },
  name: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { name } = data;
    return name;
  },
  observerUserEmails: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { observerEmails: observerUserEmails = [] } = data;
    return observerUserEmails;
  },
  publisherDatasetHash: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrievePublishedDatasetsCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { publisherDatasetHash } = data || {};
    return publisherDatasetHash;
  },
  publisherUserEmails: async (
    parent: Partial<PublishedMediaDataRoom>,
    _args: null,
    _context: LocalResolverContext,
    _info: any
  ) => {
    const { id: dataRoomHash, driverAttestationHash } = parent;
    const data = await retrieveMediaDataRoomCached(
      sessionManager,
      dataRoomHash!,
      driverAttestationHash
    );
    const { publisherEmails: publisherUserEmails = [] } = data;
    return publisherUserEmails;
  },
});
