import { DqTable } from "@decentriq/components";
import { useConsentlessAudiencesQuery } from "@decentriq/graphql/dist/hooks";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useMemo } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import { replaceDecentriqOrgName } from "features/mediaDataRoom/utils";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  ActivateConsentlessAudienceButton,
  ConsentlessActivationDownloadButton,
  DeleteConsentlessAudienceButton,
} from "..";

const AdvertiserConsentlessAudiencesTable: React.FC = () => {
  const { dcrHash, driverAttestationHash, publisherOrganization } =
    usePublishedMediaDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const {
    data: audiencesData,
    error,
    loading,
  } = useConsentlessAudiencesQuery({
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          `Audiences could not be retrieved. Please try again by refreshing the page.`
        )
      );
    },
    variables: {
      id: dcrHash,
      input: {
        dataRoomId: dcrHash,
        driverAttestationHash,
      },
    },
  });
  const consentlessAudiences =
    audiencesData?.mediaRetrieveConsentlessAudiences?.nodes || [];
  const publisherDownloadingPermission =
    audiencesData?.publishedMediaDataRoom?.activationDownloadByPublisher ||
    false;
  type Audience = Omit<(typeof consentlessAudiences)[number], "__typename">;
  const columns = useMemo<MRT_ColumnDef<Audience>[]>(
    () => [
      {
        Cell: ({ cell, row }) => {
          return (
            <Typography sx={{ my: "auto" }}>{`${cell.getValue()} - ${Math.round(
              row?.original?.precision * 100
            )}%`}</Typography>
          );
        },
        accessorKey: "name",
        header: "Top affinity audience",
        id: "name",
        minSize: 150,
      },
      {
        Cell: ({ row }) => {
          const isActivated = row?.original?.activated;
          const isDownloaded = row?.original?.downloaded;
          const size = row?.original?.size;
          if (!size) {
            return <Typography>Empty audience</Typography>;
          }
          return (
            <Typography sx={{ my: "auto" }}>
              {isDownloaded
                ? "Downloaded"
                : isActivated
                  ? `Available for download${
                      publisherDownloadingPermission
                        ? ` by ${replaceDecentriqOrgName(
                            publisherOrganization?.name,
                            "Publisher"
                          )}`
                        : ""
                    }`
                  : publisherDownloadingPermission
                    ? "Ready to activate"
                    : ""}
            </Typography>
          );
        },
        header: "Status",
        id: "status",
        minSize: 200,
      },
    ],
    [publisherOrganization?.name, publisherDownloadingPermission]
  );
  return loading ? (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
      padding="1rem"
    >
      <CircularProgress color="inherit" size="1.5rem" thickness={1} />
    </Box>
  ) : error ? (
    <Alert severity="error">{error.message}</Alert>
  ) : consentlessAudiences.length ? (
    <DqTable
      columns={columns}
      data={consentlessAudiences}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 350,
        },
      }}
      enableRowActions={true}
      localization={{
        actions: "",
      }}
      renderRowActions={({ row }) => {
        const {
          activated,
          id: audienceId,
          name: audienceName,
          size,
        } = row?.original || {};
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {size ? (
              <Box mr={0.5}>
                <ActivateConsentlessAudienceButton
                  audienceId={row?.original?.id}
                  isActivated={activated}
                  owner={row?.original?.owner}
                />
              </Box>
            ) : null}
            {activated && (
              <ConsentlessActivationDownloadButton
                audienceId={audienceId}
                audienceName={audienceName}
              />
            )}
            <DeleteConsentlessAudienceButton
              audienceId={row?.original?.id}
              owner={row?.original?.owner}
            />
          </Box>
        );
      }}
    />
  ) : null;
};

AdvertiserConsentlessAudiencesTable.displayName =
  "AdvertiserConsentlessAudiencesTable";

export default memo(AdvertiserConsentlessAudiencesTable);
