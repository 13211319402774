import { useDevComputeNodeActionsQuery } from "@decentriq/graphql/dist/hooks";
import {
  ComputeJobAutoFetching,
  ComputeJobStatus,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import {
  faArrowUpRightAndArrowDownLeftFromCenter as faArrowUpRightAndArrowDownLeftFromCenterRegular,
  faDownload as faDownloadRegular,
  faFileChartColumn as faFileChartColumnRegular,
  faFileMagnifyingGlass as faFileMagnifyingGlassRegular,
  faPlay as faPlayRegular,
  faTrashCan as faTrashCanRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Actions } from "components";
import { useComputeNodesVars, useDataRoom } from "contexts";
import { useJobPolling } from "features/computeNode/hooks";
import {
  type ComputeNodeTypeNames,
  DraftComputeNodeTypeNames,
  type DraftComputeNodeTypes,
} from "models";
import {
  useComputeNodeDownload,
  useComputeNodePreview,
  useDraftComputeNodeDelete,
} from "../hooks";
import useTestDraftComputation from "../hooks/useTestDraftComputation/useTestDraftComputation";
import JobLastRunLabel from "../JobLastRunLabel";

interface DraftComputeNodeActionsProps {
  computeNodeId: string;
}

const DraftComputeNodeActions: React.FC<DraftComputeNodeActionsProps> = ({
  computeNodeId: id,
}) => {
  const { allowTestMode } = useDataRoom();
  const { openEditorDialog } = useComputeNodesVars();
  const popDraftComputeNode = () => openEditorDialog(id);
  const { openSdgQualityReportDialog } = useComputeNodesVars();
  const [deleteDraftComputeNode] = useDraftComputeNodeDelete({
    variables: { id },
  });
  const { data: actionsData, loading: actionsDataLoading } =
    useDevComputeNodeActionsQuery({
      skip: !allowTestMode,
      variables: {
        computeNodeId: id,
      },
    });
  const computationType = actionsData?.draftNode.__typename as
    | ComputeNodeTypeNames
    | undefined;
  const { loading: computeNodeRunLoading, testDraftComputeNode } =
    useTestDraftComputation({ computationType, computeNodeId: id });
  const job = (actionsData?.draftNode as DraftComputeNodeTypes | undefined)
    ?.job;
  const { previewResult, loading: previewResultLoading } =
    useComputeNodePreview({
      jobId: job?.id || "",
    });
  const { downloadResult, loading: downloadResultLoading } =
    useComputeNodeDownload({
      computationType,
      computeNodeId: id,
      dataRoomHash: job?.dataRoomHash,
      driverAttestationHash: job?.driverAttestationHash || "",
      jobId: job?.id || "",
      name: actionsData?.draftNode.name ?? "",
    });
  const status = job?.status;
  const isJobRunning = status === ComputeJobStatus.Running;
  const isJobFailed = status === ComputeJobStatus.Failed;
  const isJobCompleted = status === ComputeJobStatus.Succeded;
  const isPreviewNode = useMemo(() => {
    const draftNode = actionsData?.draftNode;
    return (
      draftNode &&
      "computationType" in draftNode &&
      draftNode.computationType === WorkerTypeShortName.Preview
    );
  }, [actionsData]);
  const isAutoFetchingJob =
    job?.autoFetching && job?.autoFetching !== ComputeJobAutoFetching.None;
  const hasSdgQualityReport =
    isJobCompleted &&
    actionsData?.draftNode.__typename ===
      DraftComputeNodeTypeNames.DraftSyntheticNode;
  const isComputeNodeRunning = computeNodeRunLoading || isJobRunning;
  const { startJobPolling } = useJobPolling({
    autoFetching: job!?.autoFetching,
    computeNodeId: id,
    dataRoomHash: job?.dataRoomHash,
    driverAttestationHash: job?.driverAttestationHash,
    enclaveJobId: job!?.enclaveComputeJobId,
    jobId: job!?.id,
  });
  useEffect(() => {
    if (isJobRunning) {
      startJobPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  if (actionsDataLoading && !actionsData && allowTestMode) {
    return <></>;
  }
  return (
    <>
      <JobLastRunLabel
        isJobCompleted={isJobCompleted && !isComputeNodeRunning}
        runAt={job?.createdAt}
      />
      <Actions
        actions={{
          buttons: [
            {
              color: allowTestMode ? "secondary" : "primary",
              component: Button,
              disabled: isComputeNodeRunning,
              hidden: !allowTestMode || isPreviewNode,
              icon:
                isComputeNodeRunning && !isJobFailed ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  <FontAwesomeIcon fixedWidth={true} icon={faPlayRegular} />
                ),
              name: allowTestMode ? "Run test" : "Run",
              onClick: testDraftComputeNode,
              tooltip: isJobCompleted
                ? `Re-run${allowTestMode ? " test" : ""}`
                : `Run${allowTestMode ? " test" : ""}`,
              type: "Run",
            },
            {
              disabled: previewResultLoading,
              hidden:
                isAutoFetchingJob || !isJobCompleted || isComputeNodeRunning,
              icon: faFileMagnifyingGlassRegular,
              loading: previewResultLoading,
              onClick: previewResult,
              tooltipTitle: "Preview",
              type: "Preview",
            },
            {
              hidden:
                !isJobCompleted || !hasSdgQualityReport || isComputeNodeRunning,
              icon: faFileChartColumnRegular,
              onClick: () => openSdgQualityReportDialog(id),
              tooltipTitle: "Quality report",
              type: "Quality report",
            },
            {
              disabled: downloadResultLoading,
              hidden: !isJobCompleted || isComputeNodeRunning,
              icon:
                (isComputeNodeRunning || downloadResultLoading) &&
                !isJobFailed ? (
                  <CircularProgress color="inherit" size={16} />
                ) : (
                  <FontAwesomeIcon fixedWidth={true} icon={faDownloadRegular} />
                ),
              name: "Download",
              onClick: downloadResult,
              sx: {
                cursor: "pointer",
                padding: "8px 16px",
              },
              tooltipTitle: "Download",
            },
            {
              hidden: isPreviewNode,
              icon: faArrowUpRightAndArrowDownLeftFromCenterRegular,
              isRed: false,
              name: "Open in popup",
              onClick: popDraftComputeNode,
              tooltipTitle: "Open in popup",
              type: "Open in popup",
            },
            {
              disabled: isComputeNodeRunning,
              icon: faTrashCanRegular,
              isRed: false,
              name: "Delete",
              onClick: deleteDraftComputeNode,
              tooltipTitle: "Delete",
              type: "Delete",
            },
          ],
        }}
        moreTooltipTitle="More"
      />
    </>
  );
};

export default DraftComputeNodeActions;
