import { useMediaDataRoomSummaryQuery } from "@decentriq/graphql/dist/hooks";
import { ActivationType } from "@decentriq/graphql/dist/types";
import {
  faArrowRight,
  faBullhorn,
  faBullseyePointer,
  faDoorOpen,
  faEye,
  faFileChartColumn,
  faNewspaper,
  faUserTie,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { memo } from "react";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

const ReviewStepIcon = styled(FontAwesomeIcon)(({ theme: { spacing } }) => ({
  marginRight: spacing(1),
}));

interface MediaDataRoomSummaryProps {
  open: boolean;
  id: string;
}

const MediaDataRoomSummary: React.FC<MediaDataRoomSummaryProps> = memo(
  ({ open, id }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { data, loading } = useMediaDataRoomSummaryQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Media data clean room could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: !open,
      variables: { id },
    });
    const {
      activationDownloadByAdvertiser,
      activationDownloadByAgency,
      activationDownloadByPublisher,
      activationType,
      advertiserUserEmails = [],
      enableOverlapInsights,
      name,
      observerUserEmails = [],
      publisherUserEmails = [],
      agencyUserEmails = [],
    } = data?.publishedMediaDataRoom || {};

    if (loading) {
      return <CircularProgress color="inherit" size={64} thickness={1} />;
    }

    return (
      <Grid container={true}>
        <Grid container={true} xs={10}>
          <Grid alignItems="center" container={true} xs={3}>
            <ReviewStepIcon fixedWidth={true} icon={faDoorOpen} />
            <Typography>Name</Typography>
          </Grid>
          <Grid xs={9}>{name || "—"}</Grid>
        </Grid>
        <Grid container={true} mt={1} xs={10}>
          <Grid alignItems="center" container={true} xs={3}>
            <ReviewStepIcon fixedWidth={true} icon={faNewspaper} />
            <Typography>Publisher</Typography>
          </Grid>
          <Grid xs={9}>
            {publisherUserEmails.length ? publisherUserEmails.join(", ") : "—"}
          </Grid>
        </Grid>
        <Grid container={true} mt={1} xs={10}>
          <Grid alignItems="center" container={true} xs={3}>
            <ReviewStepIcon fixedWidth={true} icon={faBullhorn} />
            <Typography>Advertiser</Typography>
          </Grid>
          <Grid xs={9}>
            {advertiserUserEmails.length
              ? advertiserUserEmails.join(", ")
              : "—"}
          </Grid>
        </Grid>
        <Grid container={true} mt={1} xs={10}>
          <Grid
            alignItems="center"
            container={true}
            sx={{ height: "fit-content" }}
            xs={3}
          >
            <ReviewStepIcon fixedWidth={true} icon={faFileChartColumn} />
            <Typography>Insights</Typography>
          </Grid>
          <Grid xs={9}>
            {!enableOverlapInsights && <Typography>No insights</Typography>}
            {enableOverlapInsights && (
              <Typography>Show segments insights</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container={true} mt={1} xs={10}>
          <Grid alignItems="center" container={true} xs={3}>
            <ReviewStepIcon fixedWidth={true} icon={faBullseyePointer} />
            <Typography>Data clean room type</Typography>
          </Grid>
          <Grid xs={9}>
            <Typography>
              {activationType === ActivationType.Direct
                ? "Remarketing"
                : activationType === ActivationType.Consentless
                  ? "Top affinity segments"
                  : "Media planning"}
            </Typography>
          </Grid>
        </Grid>
        {activationType && (
          <Grid container={true} mt={1} xs={10}>
            <Grid
              alignItems="center"
              container={true}
              sx={{ height: "fit-content" }}
              xs={3}
            >
              <ReviewStepIcon fixedWidth={true} icon={faArrowRight} />
              <Typography>Destinations</Typography>
            </Grid>
            <Grid xs={9}>
              {!(
                activationDownloadByPublisher ||
                activationDownloadByAdvertiser ||
                activationDownloadByAgency
              ) && "—"}
              {activationDownloadByPublisher && (
                <Typography>Download by Publisher</Typography>
              )}
              {activationDownloadByAdvertiser && (
                <Typography>Download by Advertiser</Typography>
              )}
              {activationDownloadByAgency && (
                <Typography>Download by Agency</Typography>
              )}
            </Grid>
          </Grid>
        )}
        <Grid container={true} mt={1} xs={10}>
          <Grid alignItems="center" container={true} xs={3}>
            <ReviewStepIcon fixedWidth={true} icon={faEye} />
            <Typography>Observer</Typography>
          </Grid>
          <Grid xs={9}>
            {observerUserEmails.length ? observerUserEmails.join(", ") : "—"}
          </Grid>
        </Grid>
        <Grid container={true} mt={1} xs={10}>
          <Grid alignItems="center" container={true} xs={3}>
            <ReviewStepIcon fixedWidth={true} icon={faUserTie} />
            <Typography>Agency</Typography>
          </Grid>
          <Grid xs={9}>
            {agencyUserEmails.length ? agencyUserEmails.join(", ") : "—"}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

MediaDataRoomSummary.displayName = "MediaDataRoomSummary";

export default MediaDataRoomSummary;
