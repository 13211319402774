import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { Box, Typography, useTheme } from "@mui/material";
import { Bar, type BarDatum } from "@nivo/bar";
import { memo, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { AutoSizer } from "react-virtualized";
import { EmptyData } from "components";
import {
  type InsightsBasicViewFormValues,
  type InsightsComparisonViewFormValues,
  OverlapInsightsVisualizeDirection,
  SuppressedSegmentsInformation,
  useAudienceAggregationIdByName,
  useMediaDataRoomAggregationData,
  useMediaDataRoomOverlapInsights,
} from "features/MediaInsightsDcr";

type AffinityRatioSegmentBarChartProps = {
  segmentsThreshold?: number;
  aggregation?: string[];
};

const AffinityRatioSegmentBarChart: React.FC<
  AffinityRatioSegmentBarChartProps
> = ({ segmentsThreshold, aggregation }) => {
  const {
    aggregationData: segmentAggregationData,
    suppressedValues,
    hasSuppressedValues,
    possibleValues,
    hiddenValues,
    hasHiddenValues,
  } = useMediaDataRoomOverlapInsights({
    aggregation,
    visualize: {
      direction: OverlapInsightsVisualizeDirection.top,
      limit: segmentsThreshold,
      orderBy: "affinity_ratio",
    },
  });

  const { watch } = useFormContext<
    InsightsComparisonViewFormValues | InsightsBasicViewFormValues
  >();

  const selectedAudienceType = watch("audienceType");
  const aggregationId = useAudienceAggregationIdByName({
    aggregationNames: aggregation || [],
    audienceType: selectedAudienceType,
  });

  const selectedAggregationId = watch("aggregation");
  const aggregationData = useMediaDataRoomAggregationData(selectedAudienceType);
  // TODO: return aggregationName data from useMediaDataRoomOverlapInsights
  const aggregationLabel = aggregationData.find(
    ({ value }) => value === selectedAggregationId || aggregationId
  )?.label;

  const { palette } = useTheme();

  const affinityRatioSegmentBarData: BarDatum[] = useMemo(
    () =>
      (segmentAggregationData || []).map(
        ({ affinityRatio, aggregationName }) => ({
          aggregationName: aggregationName as string,
          value: affinityRatio,
        })
      ),
    [segmentAggregationData]
  );

  return (
    <Box display="flex" flex={1} flexDirection="column" height="100%">
      <Box alignItems="baseline" display="flex">
        <Typography fontWeight="fontWeightSemiBold" mb={1} mr={1}>
          Affinity vs. {aggregationLabel}
        </Typography>
        {(hasSuppressedValues || hasHiddenValues) && (
          <SuppressedSegmentsInformation
            hiddenValues={hiddenValues}
            possibleValues={possibleValues}
            suppressedValues={suppressedValues}
          />
        )}
      </Box>
      {affinityRatioSegmentBarData.length ? (
        <AutoSizer>
          {({ width, height }) => (
            <Bar
              axisBottom={{
                format: (value) =>
                  affinityRatioSegmentBarData.length <= segmentsThreshold
                    ? value.length <= 4
                      ? value
                      : `${value.substring(0, 4)}...`
                    : "",
                legend: aggregationLabel,
                legendOffset: 32,
                legendPosition: "middle",
                tickPadding: 5,
                tickSize: 5,
              }}
              axisLeft={{
                legend: "Affinity",
                legendOffset: -48,
                legendPosition: "middle",
                tickPadding: 5,
                tickSize: 5,
              }}
              colors={palette.chart.primary.main}
              data={affinityRatioSegmentBarData}
              enableGridY={true}
              enableLabel={false}
              groupMode="stacked"
              height={height}
              indexBy="aggregationName"
              key="aggregationName"
              layout="vertical"
              margin={{
                bottom: 64,
                left: 64,
                top: 8,
              }}
              padding={0.2}
              tooltipLabel={({ data }) => `${data.aggregationName}`}
              width={width}
            />
          )}
        </AutoSizer>
      ) : (
        <EmptyData
          icon={faChartSimple}
          secondaryText="No chart data available"
        />
      )}
    </Box>
  );
};

AffinityRatioSegmentBarChart.displayName = "AffinityRatioSegmentBarChart";

export default memo(AffinityRatioSegmentBarChart);
