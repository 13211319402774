import { EMAIL_REGEXP } from "constants/index";
import { useDraftParticipantCreateMutation } from "@decentriq/graphql/dist/hooks";
import { DraftParticipantUserEmailFragment } from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilledInput, FormHelperText, styled } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

const AddParticipantFieldWrapper = styled("div")({
  background: "white",
  borderTop: "0.5px solid #bbb",
  bottom: "0%",
  paddingTop: "0.5rem",
  position: "sticky",
  top: "auto",
});

interface AddParticipantFieldProps {
  idForDataRoom: string;
  emails: string[];
}

const AddParticipantField: React.FC<AddParticipantFieldProps> = memo(
  ({ idForDataRoom, emails }): JSX.Element => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [draftParticipantCreateMutation] = useDraftParticipantCreateMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            `Participant could not be added.`
          )
        );
      },
    });
    const handleCreateParticipant = useCallback(
      (userEmail: string) => {
        if (!EMAIL_REGEXP.test(userEmail)) {
          enqueueSnackbar("Invalid participant email.", { variant: "error" });
          return;
        }
        const normalizedEmail = userEmail.toLowerCase();
        draftParticipantCreateMutation({
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                participants: (existing = {}) => {
                  const dataRoomParticipantRef = cache.writeFragment({
                    data: data?.draftParticipant?.createForDraftDataRoom,
                    fragment: DraftParticipantUserEmailFragment,
                  });
                  return {
                    ...existing,
                    nodes: [dataRoomParticipantRef, ...(existing?.nodes || [])],
                  };
                },
              },
              id: cache.identify({
                __typename: "DraftDataRoom",
                id: idForDataRoom,
              }),
            });
          },
          variables: {
            draftDataRoomId: idForDataRoom,
            userEmail: normalizedEmail,
          },
        });
      },
      [draftParticipantCreateMutation, idForDataRoom, enqueueSnackbar]
    );
    const [value, setValue] = useState("");
    const error = useMemo(() => {
      return value.trim().length > 0
        ? emails.some(
            (e) => e.toLowerCase().trim() === value.toLowerCase().trim()
          )
          ? "Participant email must be unique"
          : !EMAIL_REGEXP.test(value)
            ? "Invalid participant email"
            : undefined
        : undefined;
    }, [emails, value]);
    const onChange = useCallback((event) => {
      setValue(event.target.value);
    }, []);
    const onKeyDown = useCallback(
      (event) => {
        if (event.key === "Enter" && value.length > 0 && !error) {
          handleCreateParticipant(value);
          setValue("");
        }
      },
      [error, handleCreateParticipant, value]
    );
    return (
      <AddParticipantFieldWrapper>
        <FilledInput
          autoFocus={true}
          data-testid={testIds.dataroom.addParticipants.newParticipant}
          disableUnderline={true}
          error={Boolean(error)}
          fullWidth={true}
          hiddenLabel={true}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder="Add new participant"
          startAdornment={
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faPlusRegular}
              style={{ marginRight: 4, opacity: 0.5 }}
            />
          }
          sx={{
            "& .MuiFilledInput-input": {
              height: "1.5rem",
              lineHeight: "1.5rem",
              pb: 0.5,
              pt: 0.5,
            },
            pl: 0.5,
            pr: 0.5,
          }}
          value={value}
        />
        {error ? <FormHelperText error={true}>{error}</FormHelperText> : null}
      </AddParticipantFieldWrapper>
    );
  }
);

AddParticipantField.displayName = "AddParticipantField";

export default AddParticipantField;
