import { faFileExport as faFileExportRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import {
  ExportDatasetDialog,
  ExportDatasetDialogWrapper,
} from "features/datasets/components/ExportDataset";

type ExportDatasetButtonProps = {
  datasetManifestHash: string;
};

const ExportDatasetButton: React.FC<ExportDatasetButtonProps> = ({
  datasetManifestHash,
}) => {
  const [
    isExportDatasetDialogVisible,
    { setTrue: openExportDatasetDialog, setFalse: closeExportDatasetDialog },
  ] = useBoolean(false);
  return (
    <>
      <Button
        color="primary"
        onClick={openExportDatasetDialog}
        startIcon={
          <FontAwesomeIcon fixedWidth={true} icon={faFileExportRegular} />
        }
        sx={({ palette }) => ({ borderColor: palette.text.disabled })}
        variant="contained"
      >
        Export dataset
      </Button>
      <Portal>
        <ExportDatasetDialogWrapper datasetManifestHash={datasetManifestHash}>
          <ExportDatasetDialog
            onCancel={closeExportDatasetDialog}
            open={isExportDatasetDialogVisible}
          />
        </ExportDatasetDialogWrapper>
      </Portal>
    </>
  );
};

ExportDatasetButton.displayName = "ExportDatasetButton";

export default memo(ExportDatasetButton);
