import { Typography } from "@mui/material";
import { memo } from "react";
import useSplickyConnectionConfigurationData from "./useSplickyConnectionConfigurationData";

type SplickyConnectionConfigurationProps = {
  connectionId: string;
};

const SplickyConnectionConfiguration: React.FC<
  SplickyConnectionConfigurationProps
> = ({ connectionId }) => {
  const { seatId, userDefinedAudienceName } =
    useSplickyConnectionConfigurationData(connectionId);
  return (
    <div>
      <Typography variant="body2">
        <strong>Seat ID:</strong> {seatId || "-"}
      </Typography>
      <Typography variant="body2">
        <strong>Audience Name:</strong> {userDefinedAudienceName || "-"}
      </Typography>
    </div>
  );
};

SplickyConnectionConfiguration.displayName = "SplickyConnectionConfiguration";

export default memo(SplickyConnectionConfiguration);
