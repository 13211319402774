import { Navigate, useResolvedPath, useRoutes } from "react-router-dom";
import { PublisherPortal, PublisherPortalTabs } from "features/publisherPortal";
import { Main } from "layouts";

const PublisherPortalPage: React.FC<{}> = () => {
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;

  const element = useRoutes([
    {
      element: (
        <PublisherPortal
          activeTab={PublisherPortalTabs.DATAROOMS}
          baseUrl={url}
        />
      ),
      path: PublisherPortalTabs.DATAROOMS,
    },
    {
      element: (
        <PublisherPortal
          activeTab={PublisherPortalTabs.DATA_LABS}
          baseUrl={url}
        />
      ),
      path: PublisherPortalTabs.DATA_LABS,
    },
    {
      element: (
        <PublisherPortal
          activeTab={PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}
          baseUrl={url}
        />
      ),
      path: PublisherPortalTabs.PUBLISHER_CONFIGURATIONS,
    },
    {
      element: <Navigate to={`${url}/${PublisherPortalTabs.DATAROOMS}`} />,
      path: "/",
    },
    {
      element: <Navigate to={`${url}/${PublisherPortalTabs.DATAROOMS}`} />,
      path: "*",
    },
  ]);

  return <Main>{element}</Main>;
};

PublisherPortalPage.displayName = "PublisherPortalPage";

export default PublisherPortalPage;
