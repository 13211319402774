import * as yup from "yup";

export const PublisherDisplayConfigurationValidationSchema = yup.object({
  collaborationRequestUsers: yup
    .array()
    .of(yup.string().required())
    .min(1, "Please select at least one participant"),
  description: yup
    .string()
    .max(800, "Description must be no longer than 800 characters"),
  logo: yup.string(),
  marketIds: yup
    .array()
    .of(yup.string().required())
    .min(1, "Please select at least one market"),
  publisherName: yup.string().required("Publisher name is required"),
});

export type PublisherDisplayConfigurationFormValues = yup.InferType<
  typeof PublisherDisplayConfigurationValidationSchema
>;

export const defaultPublisherDisplayConfigurationFormValues: PublisherDisplayConfigurationFormValues =
  {
    collaborationRequestUsers: [],
    description: "",
    logo: undefined,
    marketIds: [],
    publisherName: "",
  };
