import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { OrganizationsAddButton, OrganizationsList } from "components";

const OrganizationsPage = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 1,
        }}
      >
        <Typography variant="h6">
          <strong>Manage Organizations</strong>
        </Typography>
        <OrganizationsAddButton />
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: 2,
          overflow: "hidden",
          padding: 1,
        }}
      >
        <OrganizationsList />
      </Box>
    </>
  );
};

export default memo(OrganizationsPage);
