import { Key } from "@decentriq/utils";
import { useCallback, useState } from "react";
import { type KeychainItem, KeychainItemKind } from "services/keychain";
import { useKeychainService } from "contexts";
import { type DataLab } from "features/dataLabs/models";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { hashedIdAsArray } from "utils/apicore";
import { useMediaInsightsDcrState } from "../../../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";

interface PublisherDataNodeHookPayload {
  retrieveDatasets: () => Promise<void>;
}

interface PublisherDataNodeHookResult {
  connecting: boolean;
  deprovisioning: boolean;
  dataLabId: string | null;
  connectDataLab: (dataLab: DataLab) => Promise<boolean>;
  deprovisionDataLab: (
    dataLab: Pick<DataLab, "id" | "usersDataset" | "demographicsDataset">
  ) => Promise<boolean>;
}

const usePublisherDataNodeActions = ({
  retrieveDatasets,
}: PublisherDataNodeHookPayload): PublisherDataNodeHookResult => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { keychain } = useKeychainService();
  const [dataLabId, setDataLabId] = useState<string | null>(null);
  const [connecting, setConnecting] = useState(false);
  const [deprovisioning, setDeprovisioning] = useState(false);
  const { publishPublisherDatasets, unpublishPublisherDatasets } =
    useMediaInsightsDcrState();
  const handleKeysFetching = useCallback(
    async ({
      usersDataset,
      segmentsDataset,
      requireDemographicsDataset,
      requireEmbeddingsDataset,
      embeddingsDataset,
      demographicsDataset,
    }: DataLab): Promise<(KeychainItem | undefined)[]> => {
      const datasetsHashes: (string | undefined)[] = [
        usersDataset?.manifestHash,
        segmentsDataset?.manifestHash,
        requireDemographicsDataset
          ? demographicsDataset?.manifestHash
          : undefined,
        requireEmbeddingsDataset ? embeddingsDataset?.manifestHash : undefined,
      ];
      return Promise.all(
        datasetsHashes.map((datasetHash) => {
          if (!datasetHash) {
            return undefined;
          }
          return keychain
            .getItem(datasetHash!, KeychainItemKind.Dataset)
            .then((value) => value.item);
        })
      );
    },
    [keychain]
  );
  // Provisioning a datalab
  const handleDataLabConnecting = useCallback(
    async (dataLab: DataLab) => {
      try {
        setConnecting(true);
        setDataLabId(dataLab?.id);
        const [
          usersKeyItem,
          segmentsKeyItem,
          demographicsKeyItem,
          embeddingsKeyItem,
        ] = await handleKeysFetching(dataLab);
        await publishPublisherDatasets({
          dataLabId: dataLab?.id,
          ...(segmentsKeyItem
            ? {
                segmentsDataset: {
                  datasetHash: segmentsKeyItem!.id,
                  encryptionKey: new Key(
                    hashedIdAsArray(segmentsKeyItem!.value)
                  ),
                },
              }
            : {}),
          usersDataset: {
            datasetHash: usersKeyItem!.id,
            encryptionKey: new Key(hashedIdAsArray(usersKeyItem!.value)),
          },
          ...(demographicsKeyItem
            ? {
                demographicsDataset: {
                  datasetHash: demographicsKeyItem!.id,
                  encryptionKey: new Key(
                    hashedIdAsArray(demographicsKeyItem!.value)
                  ),
                },
              }
            : {}),
          ...(embeddingsKeyItem
            ? {
                embeddingsDataset: {
                  datasetHash: embeddingsKeyItem!.id,
                  encryptionKey: new Key(
                    hashedIdAsArray(embeddingsKeyItem!.value)
                  ),
                },
              }
            : {}),
        });
        await retrieveDatasets();
        return true;
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(error, "Unable to connect datalab")
        );
        return false;
      } finally {
        setConnecting(false);
        setDataLabId(null);
      }
    },
    [
      handleKeysFetching,
      publishPublisherDatasets,
      retrieveDatasets,
      enqueueSnackbar,
    ]
  );
  // Deprovisioning a datalab
  const handleDataLabDeprovision = useCallback(
    async (
      dataLab: Pick<DataLab, "id" | "usersDataset" | "demographicsDataset">
    ) => {
      try {
        setDeprovisioning(true);
        setDataLabId(dataLab?.id);
        const usersDatasetProvisioned = dataLab?.usersDataset;
        const demographicsDatasetProvisioned = dataLab?.demographicsDataset;
        await unpublishPublisherDatasets({
          withDemographicsDataset: !!usersDatasetProvisioned,
          withEmbeddingsDataset: !!demographicsDatasetProvisioned,
        });
        await retrieveDatasets();
        return true;
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Unable to deprovision datalab"
          )
        );
        return false;
      } finally {
        setDeprovisioning(false);
        setDataLabId(null);
      }
    },
    [unpublishPublisherDatasets, retrieveDatasets, enqueueSnackbar]
  );
  return {
    connectDataLab: handleDataLabConnecting,
    connecting,
    dataLabId,
    deprovisionDataLab: handleDataLabDeprovision,
    deprovisioning,
  };
};

export default usePublisherDataNodeActions;
