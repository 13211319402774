import { useParams } from "react-router-dom";
import { DataLab } from "features/dataLabs";
import { Main } from "layouts";

const DataLabPage: React.FC<{}> = () => {
  const { datLabId } = useParams();

  return (
    <Main>
      <DataLab id={datLabId!} />
    </Main>
  );
};

export default DataLabPage;
