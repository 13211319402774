// @ts-nocheck
import { memo, useCallback } from "react";
import { useSelections } from "contexts";
import FontAwesomeCheckbox from "./FontAwesomeCheckbox";
import FontAwesomeRadiobox from "./FontAwesomeRadiobox";

const Select = ({ id = undefined, ids = [id], type = "one" }) => {
  const {
    toggle,
    isSelected,
    setSelected,
    allSelected,
    partiallySelected,
    toggleAll,
  } = useSelections();
  const checked = ids.every((id) => isSelected(id));
  const stopPropagation = useCallback((event) => {
    event.stopPropagation();
  }, []);
  switch (type) {
    case "all":
      return (
        <FontAwesomeCheckbox
          checked={allSelected}
          indeterminate={partiallySelected}
          onChange={toggleAll}
          onClick={stopPropagation}
          onMouseDown={stopPropagation}
          onMouseUp={stopPropagation}
        />
      );
    case "any":
      return (
        <FontAwesomeCheckbox
          checked={checked}
          onChange={() => ids.map((id) => toggle(id))}
          onClick={stopPropagation}
          onMouseDown={stopPropagation}
          onMouseUp={stopPropagation}
        />
      );
    case "one":
    default:
      return (
        <FontAwesomeRadiobox
          checked={checked}
          onChange={setSelected.bind(this, ids)}
          onClick={stopPropagation}
          onMouseDown={stopPropagation}
          onMouseUp={stopPropagation}
        />
      );
  }
};

Select.All = memo((props) => <Select type="all" {...props} />);

Select.Any = memo((props) => <Select type="any" {...props} />);

Select.One = memo((props) => <Select type="one" {...props} />);

export default Select;
