import { createDefaultTheme } from "@decentriq/theme";
import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { memo } from "react";

interface StylesWrapperProps {
  children?: React.ReactNode;
}

const StylesWrapper: React.FC<StylesWrapperProps> = memo(({ children }) => {
  const styles = {
    ".svg-inline--fa.fa-fw": { width: "1em" },
    ":focus": { outline: "none" },
  };
  const theme = createDefaultTheme();
  return (
    <StyledEngineProvider injectFirst={true}>
      <GlobalStyles styles={styles} />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});
StylesWrapper.displayName = "StylesWrapper";

export default StylesWrapper;
