import { CircularProgress } from "@mui/material";
import { memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminRoute, PrivateRoute } from "components";
import { useUserRole } from "hooks";
import {
  AccessTokensPage,
  AdminPortalPage,
  DataLabPage,
  DataLabsPage,
  DataPartnerPortalPage,
  DataRoomRedirectPage,
  DataRoomsPage,
  DatasetsPage,
  DraftDataRoomPage,
  FourOFourPage,
  GettingStartedPage,
  KeychainPage,
  MediaDataRoomPage,
  MediaDataRoomPageV2,
  MediaInsightsDcrPage,
  PublishedDataRoomPage,
  PublisherPortalPage,
  UnderMaintenancePage,
} from "pages";
import { useFeatureFlag } from "utils/featureFlags";

const PagesWrapper = memo(() => {
  const isUnderMaintenanceEnabled = useFeatureFlag("under_maintenance");
  const {
    isOrganizationAdmin,
    isSuperAdmin,
    isSuperAdminReadOnly,
    organizationId: userOrganizationId,
    isLoading,
  } = useUserRole();

  return isUnderMaintenanceEnabled ? (
    <Routes>
      <Route element={<UnderMaintenancePage />} path="*" />
    </Routes>
  ) : (
    <Routes>
      <Route
        element={<PrivateRoute component={GettingStartedPage} />}
        path="/"
      />
      <Route
        element={<PrivateRoute component={DatasetsPage} />}
        path="/datasets/*"
      />
      <Route
        element={<PrivateRoute component={DraftDataRoomPage} />}
        path="/datarooms/d/:dataRoomId/*"
      />
      <Route
        element={<PrivateRoute component={PublishedDataRoomPage} />}
        path="/datarooms/p/:dataRoomId/*"
      />
      <Route
        element={<PrivateRoute component={MediaDataRoomPage} />}
        path="/datarooms/m/:dataRoomId"
      />
      <Route
        element={<PrivateRoute component={MediaDataRoomPageV2} />}
        path="/datarooms/lm/:dataRoomId"
      />
      <Route
        element={<PrivateRoute component={MediaInsightsDcrPage} />}
        path="/datarooms/mi/:dataRoomId/*"
      />
      <Route element={<DataRoomRedirectPage />} path="/datarooms/:dataRoomId" />
      <Route
        element={<PrivateRoute component={DataRoomsPage} />}
        path="/datarooms"
      />
      <Route element={<DataLabPage />} path="/datalabs/:datLabId" />
      <Route element={<DataLabsPage />} path="/datalabs" />
      <Route
        element={<PrivateRoute component={AccessTokensPage} />}
        path="/tokens"
      />
      <Route
        element={<PrivateRoute component={KeychainPage} />}
        path="/keychain"
      />
      <Route
        element={<PrivateRoute component={PublisherPortalPage} />}
        path="/publisherportal/*"
      />
      <Route
        element={<PrivateRoute component={DataPartnerPortalPage} />}
        path="/data-partner-portal/*"
      />
      <Route
        element={<AdminRoute component={AdminPortalPage} />}
        path="/admin/*"
      />
      {/* Redirecting to organizations page if user is super admin and organization page if organization admin */}
      <Route
        element={
          isLoading ? (
            <CircularProgress
              color="inherit"
              sx={{
                alignSelf: "center",
                display: "flex",
                height: "2rem !important",
                justifyContent: "center",
                width: "100% !important",
              }}
              thickness={3}
            />
          ) : (
            <Navigate
              replace={true}
              to={{
                pathname:
                  isSuperAdmin || isSuperAdminReadOnly
                    ? "/admin/organizations"
                    : isOrganizationAdmin
                      ? `/admin/organizations/${userOrganizationId}`
                      : "/",
              }}
            />
          )
        }
        path="/admin"
      />
      <Route element={<FourOFourPage />} path="*" />
    </Routes>
  );
});

export default PagesWrapper;
