import { faDownload as faDownloadRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import { memo, useCallback } from "react";
import { useDataLabContext } from "features/dataLabs";

const DataLabDownloadValidationReportButton: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();
  const { statistics: dataLabStatistics, name: dataLabName } = dataLab || {};

  const downloadValidationReport = useCallback(() => {
    const statisticsBlob = new Blob(
      [JSON.stringify(dataLabStatistics, null, 2)],
      { type: "application/json" }
    );
    saveAs(
      statisticsBlob,
      `${dataLabName}_data_quality_statistics_${format(
        new Date(),
        "dd_MM_yyyy HH_mm"
      )}`
    );
  }, [dataLabStatistics, dataLabName]);

  return (
    <Tooltip
      disableFocusListener={true}
      disableTouchListener={true}
      placement="top-start"
      title="Download statistics"
    >
      <div style={{ display: "flex" }}>
        <IconButton onClick={downloadValidationReport} sx={{ padding: 0.5 }}>
          <FontAwesomeIcon fixedWidth={true} icon={faDownloadRegular} />
        </IconButton>
      </div>
    </Tooltip>
  );
};

DataLabDownloadValidationReportButton.displayName =
  "DataLabDownloadValidationReportButton";

export default memo(DataLabDownloadValidationReportButton);
