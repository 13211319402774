import { testIds } from "@decentriq/utils";
import { Grid } from "@mui/material";
import { memo } from "react";
import {
  ConfigurationFormNameField,
  DisplayConfigurationActiveMarketsField,
  DisplayConfigurationCollaborativeUsersField,
  DisplayConfigurationDescriptionField,
  DisplayConfigurationLogoField,
} from "features/mediaPortalShared";
import { usePublisherPortal } from "features/publisherPortal/contexts/PublisherPortalContext/PublisherPortalContext";
import { type PublisherDisplayConfigurationFormValues } from "./model";

const PublisherDisplayConfigurationForm: React.FC = () => {
  const { publisherMarkets, organizationUsers } = usePublisherPortal();
  return (
    <Grid columnSpacing={3} container={true} rowSpacing={2}>
      <Grid container={true} item={true} justifyContent="center" xs={12}>
        <DisplayConfigurationLogoField<PublisherDisplayConfigurationFormValues>
          fieldName="logo"
          testId={
            testIds.publisherPortal.upsertDisplayConfigurationDialog.logoHelper
          }
        />
      </Grid>
      <Grid container={true} item={true} xs={12}>
        <ConfigurationFormNameField<PublisherDisplayConfigurationFormValues>
          fieldName="publisherName"
          placeholder="Enter placeholder name"
          testId={
            testIds.publisherPortal.upsertDisplayConfigurationDialog
              .publisherName
          }
          title="Publisher name"
        />
      </Grid>
      <Grid container={true} item={true} xs={6}>
        <DisplayConfigurationCollaborativeUsersField<PublisherDisplayConfigurationFormValues>
          fieldName="collaborationRequestUsers"
          optionTestId={
            testIds.publisherPortal.upsertDisplayConfigurationDialog
              .collaborativeUsersSelectorOptionHelper
          }
          organizationUsers={organizationUsers}
          testId={
            testIds.publisherPortal.upsertDisplayConfigurationDialog
              .collaborativeUsersSelector
          }
        />
      </Grid>
      <Grid container={true} item={true} xs={6}>
        <DisplayConfigurationActiveMarketsField<PublisherDisplayConfigurationFormValues>
          activeMarkets={publisherMarkets}
          fieldName="marketIds"
          optionTestId={
            testIds.publisherPortal.upsertDisplayConfigurationDialog
              .activeMarketsSelectorOptionHelper
          }
          testId={
            testIds.publisherPortal.upsertDisplayConfigurationDialog
              .activeMarketsSelector
          }
          tooltip="This is a list of all markets you operate in. Brands can search for publishers in the markets they are interested in and will be able to find and activate with you."
        />
      </Grid>
      <Grid container={true} item={true} xs={12}>
        <DisplayConfigurationDescriptionField<PublisherDisplayConfigurationFormValues>
          fieldName="description"
          placeholder="Enter publisher description"
          testId={
            testIds.publisherPortal.upsertDisplayConfigurationDialog.description
          }
          title="Publisher description"
        />
      </Grid>
    </Grid>
  );
};

PublisherDisplayConfigurationForm.displayName =
  "PublisherDisplayConfigurationForm";

export default memo(PublisherDisplayConfigurationForm);
