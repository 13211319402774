import { ActivationType } from "@decentriq/graphql/dist/types";
import { memo, useCallback, useState } from "react";
import {
  DEFAULT_PARTICIPANTS_EMAILS,
  MediaDataRoomCreateWizardProvider,
  type MediaDataRoomUserRole,
} from "features/mediaDataRoom";
import { type DataRoomType } from "models";

interface MediaDataRoomCreateWizardWrapperProps {
  children: React.ReactNode;
  dataRoomType: DataRoomType;
}

const MediaDataRoomCreateWizardWrapper: React.FC<MediaDataRoomCreateWizardWrapperProps> =
  memo(({ children, dataRoomType }) => {
    const [name, setName] = useState("");
    const [activationType, setActivationType] = useState(
      ActivationType.Consentless
    );

    const [matchingId, setMatchingId] = useState("");

    const [activationDownloadByPublisher, setActivationDownloadByPublisher] =
      useState(true);
    const [activationDownloadByAdvertiser, setActivationDownloadByAdvertiser] =
      useState(true);
    const [enableOverlapInsights, setEnableOverlapInsights] = useState(true);
    const [withAgency, setWithAgency] = useState<boolean>(false);
    const [withObserver, setWithObserver] = useState<boolean>(false);
    // Handle participants
    const [participantsEmails, setParticipantsEmails] = useState<
      Map<MediaDataRoomUserRole, string[]>
    >(DEFAULT_PARTICIPANTS_EMAILS);
    const addParticipantEmail = useCallback(
      (role: MediaDataRoomUserRole, email: string) => {
        // Originally, Map was created for immutability, hence, without changing a reference,
        // React will not know that state was updated
        // Taking that into consideration, cloning a map is needed to trigger UI updates
        // Read more: https://medium.com/swlh/using-es6-map-with-react-state-hooks-800b91eedd5f
        const participantsEmailsClone = new Map(participantsEmails);
        const existingParticipants: string[] =
          participantsEmailsClone.get(role) || [];
        const newParticipantEmailsList = participantsEmailsClone.set(role, [
          ...existingParticipants,
          email,
        ]);
        setParticipantsEmails(newParticipantEmailsList);
      },
      [participantsEmails]
    );
    const removeParticipantEmail = useCallback(
      (role: MediaDataRoomUserRole, email: string) => {
        const participantsEmailsClone = new Map(participantsEmails);
        const existingParticipants: string[] =
          participantsEmailsClone.get(role) || [];
        const newParticipantEmailsList = participantsEmailsClone.set(
          role,
          existingParticipants.filter(
            (existingEmail) => existingEmail !== email
          )
        );
        setParticipantsEmails(newParticipantEmailsList);
      },
      [participantsEmails]
    );
    // Handle main participant and main advertiser - to use their emails for showing Org data in the published Media DCR
    const [mainPublisherUserEmail, setMainPublisherUserEmail] =
      useState<string>("");
    const [mainAdvertiserUserEmail, setMainAdvertiserUserEmail] =
      useState<string>("");

    return (
      <MediaDataRoomCreateWizardProvider
        value={{
          activationDownloadByAdvertiser,
          activationDownloadByPublisher,
          activationType,
          addParticipantEmail,
          dataRoomType,
          enableOverlapInsights,
          mainAdvertiserUserEmail,
          mainPublisherUserEmail,
          matchingId,
          name,
          participantsEmails,
          removeParticipantEmail,
          setActivationDownloadByAdvertiser,
          setActivationDownloadByPublisher,
          setActivationType,
          setEnableOverlapInsights,
          setMainAdvertiserUserEmail,
          setMainPublisherUserEmail,
          setMatchingId,
          setName,
          setWithAgency,
          setWithObserver,
          withAgency,
          withObserver,
        }}
      >
        {children}
      </MediaDataRoomCreateWizardProvider>
    );
  });

MediaDataRoomCreateWizardWrapper.displayName =
  "MediaDataRoomCreateWizardWrapper";

export default MediaDataRoomCreateWizardWrapper;
