import { type DataSourceType } from "@decentriq/graphql/dist/types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from "@mui/material";
import { memo } from "react";
import {
  dataSourceTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { ExternalDataImportStatus, ExternalDataSourceForm } from "./components";
import { ImportExternalDataWrapper, useImportExternalData } from "./contexts";
import { EXTERNAL_DATA_IMPORT_STEPS } from "./types";

type ImportExternalDataDialogProps = {
  open: boolean;
  restartImport: () => void;
  onCancel: () => void;
};

const ImportExternalDataDialogWrapper: React.FC<
  ImportExternalDataDialogProps & { selectedDataSource?: DataSourceType }
> = ({ selectedDataSource, ...props }) => {
  if (!props.open) {
    return null;
  }
  return (
    <ImportExternalDataWrapper defaultSelectedDataSource={selectedDataSource}>
      <ImportExternalDataDialog {...props} />
    </ImportExternalDataWrapper>
  );
};

const ImportStatusActionsWrapper = styled(DialogActions)({
  display: "flex",
  justifyContent: "space-between",
});

const ImportStatusStatusWrapper = styled(DialogContent)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
});

const ImportExternalDataDialog: React.FC<ImportExternalDataDialogProps> = memo(
  ({ open, restartImport, onCancel }) => {
    const { importStep, selectedDataSource } = useImportExternalData();
    return (
      <Dialog fullWidth={true} maxWidth="md" onClose={onCancel} open={open}>
        <DialogTitle>Import dataset from external source</DialogTitle>
        {importStep === EXTERNAL_DATA_IMPORT_STEPS.SOURCE_FORM && (
          <>
            <DialogContent
              sx={{
                alignItems: "center",
                display: "flex",
                mb: 2,
                pb: 0,
              }}
            >
              <ExternalConnectionsIcon
                connectionType={selectedDataSource}
                size={ExternalConnectionsIconSize.sm}
              />
              <Typography sx={{ marginLeft: "4px" }}>
                {dataSourceTypePresentation.get(selectedDataSource)}
              </Typography>
            </DialogContent>
            <ExternalDataSourceForm onCancel={restartImport} />
          </>
        )}
        {importStep === EXTERNAL_DATA_IMPORT_STEPS.IMPORT_STATUS && (
          <ExternalDataImportStatus
            ActionsWrapper={ImportStatusActionsWrapper}
            StatusWrapper={ImportStatusStatusWrapper}
            onClose={onCancel}
          />
        )}
      </Dialog>
    );
  }
);

ImportExternalDataDialog.displayName = "ImportExternalDataDialog";

export default ImportExternalDataDialogWrapper;
