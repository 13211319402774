import { Typography } from "@mui/material";
import { memo } from "react";
import { useRequest, useRequestsVars } from "contexts";
import useRequestName from "./useRequestName";

interface RequestNameProps {
  requestId: string;
}

const RequestName: React.FC<RequestNameProps> = () => {
  const { computeNodeId } = useRequest();
  const { name } = useRequestName(computeNodeId);
  const { context } = useRequestsVars();
  return (
    <div style={{ flex: 1, marginRight: "8px", width: 0 }}>
      {name}
      <Typography style={{ marginLeft: "1.5rem" }} variant="caption">
        <i>{context === "draft" ? "Draft" : "Pending"}</i>
      </Typography>
    </div>
  );
};

RequestName.displayName = "RequestName";

export default memo(RequestName);
