import {
  FormControl,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useUpdateEffect } from "ahooks";
import { memo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { OverlapInsightsVisualizeDirection } from "features/MediaInsightsDcr";
import useVisualizeSelectorOrderByData from "./useVisualizeSelectorOrderByData";

const visualizeDirectionMap = new Map<
  OverlapInsightsVisualizeDirection,
  string
>([
  [OverlapInsightsVisualizeDirection.top, "Top"],
  [OverlapInsightsVisualizeDirection.bottom, "Bottom"],
]);

const LookalikeMediaInsightsVisualizeSelector: React.FC = () => {
  const { control: formControl, setValue, getValues } = useFormContext();

  const { visualizeSelectorData: orderByData, rowsTotalCount } =
    useVisualizeSelectorOrderByData();

  const optionsTag = orderByData?.map(({ value }) => value).join("|") || "";

  // Set default value
  useEffect(() => {
    const options = optionsTag.split("|");
    const defaultOrderByValue = options[0];
    const currentValue = getValues().visualize.orderBy;
    if (defaultOrderByValue && !options.includes(currentValue)) {
      setValue("visualize.orderBy", defaultOrderByValue);
    }
  }, [optionsTag, setValue, getValues]);

  useUpdateEffect(() => {
    setValue("visualize.limit", rowsTotalCount >= 20 ? 20 : rowsTotalCount);
  }, [setValue, rowsTotalCount]);

  return (
    <>
      <Grid columnSpacing={2} container={true} mb={2}>
        <Grid item={true} md={9} xl={10} xs={8}>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel
              component="legend"
              sx={{ fontSize: "12px", paddingLeft: "2px" }}
            >
              Visualize
            </FormLabel>
            <Controller
              control={formControl}
              name="visualize.direction"
              render={({ field }) => (
                <Select
                  displayEmpty={true}
                  fullWidth={true}
                  multiple={false}
                  renderValue={(value) => visualizeDirectionMap.get(value)}
                  sx={{
                    background: "transparent",
                  }}
                  variant="filled"
                  {...field}
                >
                  {Object.keys(OverlapInsightsVisualizeDirection).map(
                    (direction) => (
                      <MenuItem key={direction} value={direction}>
                        <ListItemText
                          primary={visualizeDirectionMap.get(
                            direction as OverlapInsightsVisualizeDirection
                          )}
                        />
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid
          alignItems="flex-end"
          container={true}
          item={true}
          justifyContent="flex-end"
          md={3}
          xl={2}
          xs={4}
        >
          <Controller
            control={formControl}
            name="visualize.limit"
            render={({ field: { onChange, ...restField } }) => (
              <TextField
                InputProps={{
                  inputProps: { max: rowsTotalCount, min: 0 },
                  sx: {
                    "& .MuiInput-input": { padding: "4px 0" },
                    "&:before": { borderBottomStyle: "solid" },
                    paddingBottom: "4px",
                  },
                  type: "number",
                }}
                fullWidth={true}
                onChange={(event) => onChange(parseInt(event?.target?.value))}
                type="number"
                variant="standard"
                {...restField}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid columnSpacing={2} container={true} mb={2}>
        <Grid item={true} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel
              component="legend"
              sx={{ fontSize: "12px", paddingLeft: "2px" }}
            >
              By
            </FormLabel>
            <Controller
              control={formControl}
              name="visualize.orderBy"
              render={({ field }) => (
                <Select
                  displayEmpty={true}
                  fullWidth={true}
                  multiple={false}
                  renderValue={(value) =>
                    orderByData.find((orderBy) => orderBy?.value === value)
                      ?.label || ""
                  }
                  sx={{
                    background: "transparent",
                  }}
                  variant="filled"
                  {...field}
                >
                  {orderByData.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

LookalikeMediaInsightsVisualizeSelector.displayName =
  "LookalikeMediaInsightsVisualizeSelector";

export default memo(LookalikeMediaInsightsVisualizeSelector);
