import {
  List,
  ListItem,
  ListItemAvatar,
  type Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "tss-react/mui";
import { DataRoomParticipantAvatar } from "components";

const useParticipantsGroupStyles = makeStyles()((theme: Theme) => ({
  extraLabel: {
    color: grey["500"],
    cursor: "pointer",
    margin: "0 .5rem 0 .25rem",
  },
  hiddenList: {
    margin: 0,
    padding: 0,
  },
  hiddenListItem: {
    padding: "4px 0",
  },
  root: {
    alignItems: "center",
    display: "flex",
  },
}));

interface DataRoomParticipantAvatarGroupProps {
  participants: { name: string; active?: boolean }[];
  max?: number;
}

const DataRoomParticipantAvatarGroup: React.FC<
  DataRoomParticipantAvatarGroupProps
> = ({ participants = [], max = 4 }) => {
  const { classes } = useParticipantsGroupStyles();
  const hasExceededMax = participants.length > max;
  const participantsToShow = participants.slice(0, max);
  const participantsToHide = hasExceededMax ? participants.slice(max) : [];
  const overflowNumber = hasExceededMax ? participants.length - max : 0;
  return (
    <div className={classes.root}>
      {participantsToShow.map((p, i) => (
        <DataRoomParticipantAvatar
          active={p.active}
          key={i}
          name={p.name}
          shifted={i !== 0}
        />
      ))}
      {hasExceededMax && (
        <Tooltip
          title={
            <List className={classes.hiddenList}>
              {participantsToHide.map((p, i) => (
                <ListItem className={classes.hiddenListItem} key={i}>
                  <ListItemAvatar>
                    <DataRoomParticipantAvatar
                      active={p.active}
                      name={p.name}
                    />
                  </ListItemAvatar>
                  <Typography variant="caption">{p.name}</Typography>
                </ListItem>
              ))}
            </List>
          }
        >
          <Typography className={classes.extraLabel} variant="caption">
            +{overflowNumber}
          </Typography>
        </Tooltip>
      )}
    </div>
  );
};

export default DataRoomParticipantAvatarGroup;
