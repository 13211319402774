import { useAuth0 } from "@auth0/auth0-react";
import {
  useActivateConsentlessAudienceMutation,
  useMediaDataRoomActivationDownloadConfigQuery,
} from "@decentriq/graphql/dist/hooks";
import { ConsentlessAudiencesDocument } from "@decentriq/graphql/dist/types";
import { faBullseyePointer } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, Tooltip } from "@mui/material";
import { memo, useCallback } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

type ActivateConsentlessAudienceButtonProps = {
  audienceId: string;
  isActivated: boolean;
  owner: string | null | undefined;
};

const ActivateConsentlessAudienceButton: React.FC<
  ActivateConsentlessAudienceButtonProps
> = ({ audienceId, isActivated, owner }) => {
  const { user = {} } = useAuth0();
  const { email: currentUserEmail } = user || {};
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dcrHash, driverAttestationHash } = usePublishedMediaDataRoom();
  const {
    data: activationDownloadConfigData,
    loading: activationDownloadConfigLoading,
  } = useMediaDataRoomActivationDownloadConfigQuery({
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          "Audience activation settings could not be retrieved."
        )
      );
    },
    variables: { id: dcrHash },
  });
  const { publishedMediaDataRoom } = activationDownloadConfigData || {};
  const { activationDownloadByPublisher } = publishedMediaDataRoom || {};
  const [activateConsentlessAudienceMutation, { loading }] =
    useActivateConsentlessAudienceMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Audience could not be activated."
          )
        );
      },
      refetchQueries: [
        {
          query: ConsentlessAudiencesDocument,
          variables: {
            id: dcrHash,
            input: {
              dataRoomId: dcrHash,
              driverAttestationHash,
            },
          },
        },
      ],
    });
  const mediaActivateConsentlessAudience = useCallback(
    (audienceId: string) => {
      activateConsentlessAudienceMutation({
        variables: {
          input: {
            audienceId,
            dataRoomId: dcrHash,
            driverAttestationHash,
          },
        },
      });
    },
    [activateConsentlessAudienceMutation, dcrHash, driverAttestationHash]
  );
  return activationDownloadConfigLoading ? (
    <CircularProgress color="inherit" size={32} thickness={2} />
  ) : !activationDownloadByPublisher && isActivated ? null : (
    <Tooltip
      disableFocusListener={true}
      disableTouchListener={true}
      placement="top"
      title={
        owner !== currentUserEmail
          ? `This action can only be performed by the audience creator: ${owner}`
          : ""
      }
    >
      <span>
        <LoadingButton
          color="inherit"
          disabled={isActivated || owner !== currentUserEmail}
          loading={loading}
          loadingPosition="start"
          onClick={() => mediaActivateConsentlessAudience(audienceId)}
          startIcon={
            <FontAwesomeIcon icon={isActivated ? faCheck : faBullseyePointer} />
          }
          variant="text"
        >
          {activationDownloadByPublisher
            ? isActivated
              ? "Available to Publisher"
              : "Make available to Publisher"
            : isActivated
              ? null
              : "Get segments list"}
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

ActivateConsentlessAudienceButton.displayName =
  "ActivateConsentlessAudienceButton";

export default memo(ActivateConsentlessAudienceButton);
