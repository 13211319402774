import { Box, Typography } from "@mui/material";
import { memo } from "react";
import useS3ConnectionConfigurationData from "./useS3ConnectionConfigurationData";

const ConfigurationRow: React.FC<{
  label: string;
  value: string | undefined;
}> = ({ label, value }) => (
  <Typography variant="body2">
    <strong>{label}:</strong> {value}
  </Typography>
);

type S3ConnectionConfigurationProps = {
  connectionId: string;
};

const S3ConnectionConfiguration: React.FC<S3ConnectionConfigurationProps> = ({
  connectionId,
}) => {
  const { bucket, region, objectKey } =
    useS3ConnectionConfigurationData(connectionId);
  return (
    <Box>
      <ConfigurationRow label="Bucket" value={bucket} />
      <ConfigurationRow label="Region" value={region} />
      <ConfigurationRow label="Object" value={objectKey} />
    </Box>
  );
};

S3ConnectionConfiguration.displayName = "S3ConnectionConfiguration";

export default memo(S3ConnectionConfiguration);
