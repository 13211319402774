import { useAuth0 } from "@auth0/auth0-react";
import { useCurrentUserDomainQuery } from "@decentriq/graphql/dist/hooks";
import { Link } from "@mui/material";
import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CustomBrandingLogo } from "components";

const OrganizationLogoSidebarItem: React.FC = () => {
  const { user } = useAuth0();
  const { email: currentUserEmail = "" } = user || {};
  const domain =
    currentUserEmail.slice(currentUserEmail.indexOf("@") + 1).toLowerCase() ||
    "";
  const { data: currentUserDomainData } = useCurrentUserDomainQuery();
  const logoBase64 = currentUserDomainData?.myself?.organizationLogo;

  return (
    <Link
      color="inherit"
      component={RouterLink}
      display="flex"
      justifyContent="center"
      to="/"
    >
      <CustomBrandingLogo
        domain={domain}
        fallbackToDecentriq={false}
        logoBase64={logoBase64!}
        maxHeight={"auto"}
        withPoweredBy={false}
        wrapperSx={{ mb: 1, p: 1 }}
      />
    </Link>
  );
};

OrganizationLogoSidebarItem.displayName = "OrganizationLogoSidebarItem";

export default memo(OrganizationLogoSidebarItem);
