import { memo, type SetStateAction, useCallback } from "react";
import { Checkbox } from "components";

type HideArchivedOrganizationsCheckboxProps = {
  setHideArchivedOrganizations: (checked: SetStateAction<boolean>) => void;
  checked: boolean;
};

const HideArchivedOrganizationsCheckbox: React.FC<
  HideArchivedOrganizationsCheckboxProps
> = ({ setHideArchivedOrganizations, checked }) => {
  const handleHideArchivedOrganizationsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setHideArchivedOrganizations(checked);
    },
    [setHideArchivedOrganizations]
  );
  return (
    <Checkbox
      checked={checked}
      label="Hide archived organizations"
      onChange={handleHideArchivedOrganizationsChange}
      size="small"
      sx={{ margin: 1, width: "fit-content" }}
    />
  );
};

HideArchivedOrganizationsCheckbox.displayName =
  "HideArchivedOrganizationsCheckbox";

export default memo(HideArchivedOrganizationsCheckbox);
