import { Box, Typography } from "@mui/material";
import { memo } from "react";
import useAzureConnectionConfigurationData from "./useAzureConnectionConfigurationData";

type AzureConnectionConfigurationProps = {
  connectionId: string;
};

const AzureConnectionConfiguration: React.FC<
  AzureConnectionConfigurationProps
> = ({ connectionId }) => {
  const { storageAccount, storageContainer, blobName } =
    useAzureConnectionConfigurationData(connectionId);
  return (
    <Box>
      <Typography variant="body2">
        <strong>Storage account:</strong> {storageAccount}
      </Typography>
      <Typography variant="body2">
        <strong>Storage container:</strong> {storageContainer}
      </Typography>
      <Typography variant="body2">
        <strong>Blob name:</strong> {blobName}
      </Typography>
    </Box>
  );
};

AzureConnectionConfiguration.displayName = "AzureConnectionConfiguration";

export default memo(AzureConnectionConfiguration);
