import { EMAIL_REGEXP } from "constants/index";
import { InfoTooltip } from "@decentriq/components";
import {
  faBullhorn as faBullhornDuotone,
  faGlasses as faGlassesDuotone,
  faNewspaper as faNewspaperDuotone,
  faUserTie as faUserTieDuotone,
} from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import { memo, type SetStateAction, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useMediaDataRoomCreateWizard } from "features/mediaDataRoom";
import { MediaDataRoomUserRole } from "features/mediaDataRoom/types";
import ConfigureStepRole from "../components/ConfigureStepRole";

const RolesAndParticipants: React.FC = () => {
  const {
    withAgency,
    withObserver,
    setWithAgency,
    setWithObserver,
    participantsEmails,
    addParticipantEmail,
    removeParticipantEmail,
    setMainAdvertiserUserEmail,
    setMainPublisherUserEmail,
    mainPublisherUserEmail,
    mainAdvertiserUserEmail,
  } = useMediaDataRoomCreateWizard();

  const PARTICIPANTS_FORM_DEFAULT_VALUES = useMemo(
    () => ({
      advertiserEmail: "",
      agencyEmail: "",
      observerEmail: "",
      publisherEmail: "",
    }),
    []
  );

  const participantsValidationSchema = yup.object().shape({
    ...Object.keys(PARTICIPANTS_FORM_DEFAULT_VALUES).reduce(
      (schema, field) => ({
        [field]: yup
          .string()
          .notRequired() // Inputs are used only to add values to the array, when it's done - input is set to empty string
          .matches(EMAIL_REGEXP, "Email is invalid")
          .test(
            "check-unique-email",
            ({ value = "" }) => {
              const role = Array.from(participantsEmails.entries()).reduce(
                (role, [participantRole, emails]) =>
                  emails.includes(value) ? participantRole : role,
                ""
              );
              return `Participant is already assigned to a ${capitalize(
                role
              )} role`;
            },
            (value = "") => {
              const participantsEmailsArray =
                Array.from(participantsEmails.values()).flat() || [];
              return !participantsEmailsArray.includes(value);
            }
          ),
        ...schema,
      }),
      {}
    ),
  });

  const participantsForm = useForm({
    defaultValues: PARTICIPANTS_FORM_DEFAULT_VALUES,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(participantsValidationSchema),
  });

  return (
    <>
      <Grid item={true} sx={{ pb: 0 }} xs={12}>
        <Typography sx={{ color: "#666666" }}>
          Roles and participants
        </Typography>
      </Grid>
      <FormProvider {...participantsForm}>
        <Grid item={true} xs={12}>
          <ConfigureStepRole
            emailValues={
              participantsEmails.get(MediaDataRoomUserRole.Publisher)!
            }
            fieldName="publisherEmail"
            icon={faNewspaperDuotone}
            mainParticipantValue={mainPublisherUserEmail}
            onEmailAdd={(email: string) =>
              addParticipantEmail(MediaDataRoomUserRole.Publisher, email)
            }
            onEmailRemove={(email: string) =>
              removeParticipantEmail(MediaDataRoomUserRole.Publisher, email)
            }
            onMainParticipantChange={(email: SetStateAction<string>) =>
              setMainPublisherUserEmail(email)
            }
            roleTagLabel="Publisher"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <ConfigureStepRole
            emailValues={
              participantsEmails.get(MediaDataRoomUserRole.Advertiser)!
            }
            fieldName="advertiserEmail"
            icon={faBullhornDuotone}
            mainParticipantValue={mainAdvertiserUserEmail}
            onEmailAdd={(email: string) =>
              addParticipantEmail(MediaDataRoomUserRole.Advertiser, email)
            }
            onEmailRemove={(email: string) =>
              removeParticipantEmail(MediaDataRoomUserRole.Advertiser, email)
            }
            onMainParticipantChange={(email: SetStateAction<string>) =>
              setMainAdvertiserUserEmail(email)
            }
            roleTagLabel="Advertiser"
          />
        </Grid>
        {withObserver && (
          <Grid item={true} xs={12}>
            <ConfigureStepRole
              emailValues={
                participantsEmails.get(MediaDataRoomUserRole.Observer)!
              }
              fieldName="observerEmail"
              icon={faGlassesDuotone}
              onEmailAdd={(email: string) =>
                addParticipantEmail(MediaDataRoomUserRole.Observer, email)
              }
              onEmailRemove={(email: string) =>
                removeParticipantEmail(MediaDataRoomUserRole.Observer, email)
              }
              roleTagLabel={
                <span>
                  Observer
                  <InfoTooltip
                    tooltip={
                      <>
                        This participant can only view statistics about the
                        overlap and the segments.
                        <br />
                        They cannot view or create any audience for activation.
                      </>
                    }
                  />
                </span>
              }
            />
          </Grid>
        )}
        {withAgency && (
          <Grid item={true} xs={12}>
            <ConfigureStepRole
              emailValues={
                participantsEmails.get(MediaDataRoomUserRole.Agency)!
              }
              fieldName="agencyEmail"
              icon={faUserTieDuotone}
              onEmailAdd={(email: string) =>
                addParticipantEmail(MediaDataRoomUserRole.Agency, email)
              }
              onEmailRemove={(email: string) =>
                removeParticipantEmail(MediaDataRoomUserRole.Agency, email)
              }
              roleTagLabel={
                <span>
                  Agency
                  <InfoTooltip tooltip="A media agency can act on behalf of the advertiser, except uploading datasets" />
                </span>
              }
            />
          </Grid>
        )}
        <Grid item={true} sx={{ paddingTop: 0 }} xs={12}>
          {!withObserver && (
            <Button
              color="inherit"
              onClick={() => setWithObserver(true)}
              startIcon={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
              sx={({ palette }) => ({ color: palette.text.secondary })}
            >
              Add Observer
            </Button>
          )}
          {!withAgency && (
            <Button
              color="inherit"
              onClick={() => setWithAgency(true)}
              startIcon={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
              sx={({ palette }) => ({
                color: palette.text.secondary,
                marginLeft: 1,
              })}
            >
              Add Agency
            </Button>
          )}
        </Grid>
      </FormProvider>
      <Grid item={true} sx={{ pb: 0 }} xs={12}>
        <Typography
          sx={({ palette }) => ({
            color: palette.text.secondary,
            marginTop: "auto",
          })}
        >
          Please double-check that all participants have a Decentriq Platform
          account.
          <br />
          Invite external participants via the Admin interface.
        </Typography>
      </Grid>
    </>
  );
};

RolesAndParticipants.displayName = "RolesAndParticipants";

export default memo(RolesAndParticipants);
