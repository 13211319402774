import { InfoTooltip } from "@decentriq/components";
import { FormControl, FormHelperText, FormLabel, Input } from "@mui/material";
import { isEmpty } from "lodash";
import { memo } from "react";
import {
  Controller,
  type FieldError,
  type FieldValues,
  type Merge,
  type Path,
  useFormContext,
} from "react-hook-form";

interface ConfigurationFormNameFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  title: string;
  placeholder: string;
  tooltip?: string;
  testId?: string;
}

type ConfigurationFormNameFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormNameFieldProps<F>
) => React.ReactNode;

const ConfigurationFormNameField = memo(
  <F extends FieldValues>({
    fieldName,
    title,
    placeholder,
    tooltip,
    testId,
  }: ConfigurationFormNameFieldProps<F>) => {
    const { control: formControl } = useFormContext<F>();
    return (
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.[fieldName] as
            | Merge<FieldError, (FieldError | undefined)[]>
            | undefined;
          return (
            <FormControl
              error={!isEmpty(errors[fieldName])}
              fullWidth={true}
              sx={{ mb: 1 }}
            >
              <FormLabel
                component="legend"
                sx={{ fontSize: "12px", pl: "2px" }}
              >
                {title}
                {tooltip && <InfoTooltip tooltip={tooltip} />}
              </FormLabel>
              <Input
                error={!isEmpty(errors[fieldName])}
                fullWidth={true}
                inputProps={{
                  sx: {
                    "&.MuiInput-input": { pl: "2px" },
                  },
                }}
                placeholder={placeholder}
                size="medium"
                {...field}
                data-testid={testId}
              />
              <FormHelperText sx={{ ml: 0 }}>
                {fieldError?.message || ""}
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);

ConfigurationFormNameField.displayName = "ConfigurationFormNameField";

export default ConfigurationFormNameField as ConfigurationFormNameFieldComponent;
