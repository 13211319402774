import { DataTargetType } from "@decentriq/graphql/dist/types";
import { createContext, type SetStateAction, useContext } from "react";
import { EXPORT_DATASET_STEPS } from "features/datasets/components/ExportDataset";

export const ExportDatasetDialogContext = createContext<{
  exportStep: EXPORT_DATASET_STEPS;
  selectedDataTarget: DataTargetType;
  setExportStep: (exportStep: SetStateAction<EXPORT_DATASET_STEPS>) => void;
  setSelectedDataTarget: (
    selectedDataTarget: SetStateAction<DataTargetType>
  ) => void;
  // After submitting a form, datasetExportId is returned, so dataset export data (incl. status) can be fetched via datasetExport query
  datasetExportId: string | null;
  setDatasetExportId: (datasetExportId: SetStateAction<string | null>) => void;
  setIsExportCreated: (isExportCreated: SetStateAction<boolean>) => void;
  isExportCreated: boolean;
  datasetManifestHash: string;
  setDatasetExportError: (error: string) => void;
  datasetExportError: string | null;
}>({
  datasetExportError: "",
  datasetExportId: null,
  datasetManifestHash: "",

  exportStep: EXPORT_DATASET_STEPS?.SELECT_TARGET,
  // Returns true if the first step of export is completed (creating export itself)
  isExportCreated: false,
  selectedDataTarget: DataTargetType.S3,
  setDatasetExportError: () => {},
  setDatasetExportId: () => {},
  setExportStep: () => {},
  setIsExportCreated: () => {},
  setSelectedDataTarget: () => {},
});

export const ExportDatasetDialogProvider = ExportDatasetDialogContext.Provider;

export const useExportDatasetDialog = () =>
  useContext(ExportDatasetDialogContext);
