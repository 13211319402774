import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Link,
  type Theme,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "tss-react/mui";

const useSupportDialogStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: grey["400"],
  },
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  title: {
    lineHeight: 1.25,
  },
}));

interface SupportDialogProps {
  open: boolean;
  onClose: () => void;
}

const SupportDialog: React.FC<SupportDialogProps> = ({ open, onClose }) => {
  const { classes: supportDialogClasses } = useSupportDialogStyles();
  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth="xs"
      onClose={onClose}
      open={open}
    >
      <Box className={supportDialogClasses.iconTitleWrapper}>
        <Box className={supportDialogClasses.iconWrapper}>
          <FontAwesomeIcon
            className={supportDialogClasses.icon}
            fixedWidth={true}
            icon={faQuestionCircle}
            size="5x"
          />
        </Box>
        <Typography
          align="center"
          className={supportDialogClasses.title}
          color="textPrimary"
          variant="h6"
        >
          <strong>
            For technical support, please contact us at the following address:
          </strong>
        </Typography>
      </Box>
      <DialogContent>
        <div style={{ padding: "1rem", textAlign: "center" }}>
          <Link
            href="mailto:support@decentriq.com"
            sx={(theme) => ({ color: theme.palette.primary.main })}
          >
            support@decentriq.com
          </Link>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 0",
          }}
        >
          <Button color="inherit" fullWidth={true} onClick={onClose}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SupportDialog;
