import {
  ComputeJobAutoFetching,
  ComputeJobStatus,
} from "@decentriq/graphql/dist/types";
import {
  faGripDots as faGripDotsRegular,
  faPlay as faPlayRegular,
  faXmark as faXmarkRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { memo } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
} from "contexts";
import {
  ComputeNodeEditor,
  ComputeNodeNameEditor,
  ComputeNodeResult,
  ComputeNodeType,
  useComputeNode,
} from "features/computeNode/components";
import {
  useComputeNodeRun,
  useDevComputeNodeRun,
  useTestDraftComputation,
} from "features/computeNode/components/ComputeNodeActions/hooks";
import { useDevComputeNode } from "features/devComputeNodes/contexts/devComputeNode";
import {
  type ComputeNodeTypeNames,
  workerTypeShortNameToDraftComputeNodeTypeName,
  workerTypeShortNameToPublishedComputeNodeTypeName,
} from "models";

interface ComputeNodeEditorDialogProps {
  computeNodeId: string;
}

const ComputeNodeEditorDialog: React.FC<ComputeNodeEditorDialogProps> = memo(
  ({ computeNodeId }) => {
    const { isEditorDialogOpened, closeEditorDialog, executionContext } =
      useComputeNodesVars();
    const { playgroundId } = useDevComputeNode();
    const { allowTestMode, isPublished } = useDataRoom();
    const { testing } = usePublishedDataRoom();
    const isTestingEnabled = isPublished ? testing : allowTestMode;
    const node = useComputeNode(computeNodeId);
    const computationType: ComputeNodeTypeNames | undefined =
      node && node.computationType
        ? executionContext === "development"
          ? workerTypeShortNameToDraftComputeNodeTypeName.get(
              node.computationType
            )
          : workerTypeShortNameToPublishedComputeNodeTypeName.get(
              node.computationType
            )
        : undefined;
    const hasResultToFetch =
      node?.job?.status === ComputeJobStatus.Succeded &&
      node?.job?.autoFetching !== ComputeJobAutoFetching.None;
    const { loading: computeNodeRunLoading, runComputeNode } =
      useComputeNodeRun({
        computeNodeId,
      });
    const { loading: computeNodeDraftRunLoading, testDraftComputeNode } =
      useTestDraftComputation({ computationType, computeNodeId });
    const { loading: devComputeNodeRunLoading, runDevComputeNode } =
      useDevComputeNodeRun({ computationType, computeNodeId, playgroundId });
    const isComputationRunning = isPublished
      ? executionContext === "development"
        ? devComputeNodeRunLoading
        : computeNodeRunLoading
      : computeNodeDraftRunLoading;

    return (
      <Dialog
        fullScreen={true}
        fullWidth={true}
        onClose={closeEditorDialog}
        open={isEditorDialogOpened(computeNodeId)}
      >
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <ComputeNodeNameEditor computeNodeId={computeNodeId} />
            <ComputeNodeType computeNodeId={computeNodeId} />
          </Box>
          <Box>
            {((allowTestMode && isTestingEnabled) ||
              executionContext === "development") && (
              <Button
                color={isTestingEnabled ? "secondary" : "primary"}
                disabled={isComputationRunning}
                onClick={
                  isPublished
                    ? executionContext === "development"
                      ? runDevComputeNode
                      : runComputeNode
                    : testDraftComputeNode
                }
                startIcon={
                  isComputationRunning ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : (
                    <FontAwesomeIcon fixedWidth={true} icon={faPlayRegular} />
                  )
                }
                sx={{
                  "&:hover": {
                    color: "rgb(106 34 123)",
                  },
                  color: "rgb(54, 18, 63)",
                  marginRight: 2,
                }}
              >
                {isTestingEnabled ? "Run test" : "Run"}
              </Button>
            )}
            <IconButton onClick={closeEditorDialog}>
              <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "100%",
            overflow: "auto",
          }}
        >
          <PanelGroup direction="vertical">
            <Panel defaultSize={70}>
              <ComputeNodeEditor
                computeNodeId={computeNodeId}
                editorOptions={{ fullHeight: true, resizable: true }}
                readOnly={false}
              />
            </Panel>
            <PanelResizeHandle>
              <Divider variant="middle">
                <IconButton>
                  <FontAwesomeIcon fixedWidth={true} icon={faGripDotsRegular} />
                </IconButton>
              </Divider>
            </PanelResizeHandle>
            <Panel maxSize={75} minSize={10} style={{ overflow: "auto" }}>
              {/* TODO in stabilization -> https://www.notion.so/decentriq/Wording-for-Computation-Result-area-0ac4e2950375414ea75bf0956270e452?pvs=4 */}
              {/* <Typography>Run test to see the result here</Typography> */}
              {hasResultToFetch && (
                <ComputeNodeResult
                  autoFetching={node?.job?.autoFetching!}
                  computationTypename={node?.computationType!}
                  computeNodeId={computeNodeId}
                  dcrHash={node?.job?.dataRoomHash}
                  driverAttestationHash={node?.job?.driverAttestationHash}
                  jobId={node!?.job?.id!}
                />
              )}
            </Panel>
          </PanelGroup>
        </DialogContent>
      </Dialog>
    );
  }
);

ComputeNodeEditorDialog.displayName = "ComputeNodeEditorDialog";

export default ComputeNodeEditorDialog;
