import { Grid, Typography } from "@mui/material";
import { memo } from "react";
import { Chip } from "components";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import { OrganizationTile } from "features/mediaPortalShared";

const DataPartnerDisplayConfigurationPresentation: React.FC = () => {
  const { displayConfiguration } = useDataPartnerPortal();
  if (!displayConfiguration) {
    return null;
  }
  const { name, collaborationRequestUsers, description, logo, marketIds } =
    displayConfiguration;
  return (
    <Grid
      columnSpacing={4}
      container={true}
      sx={{ flex: 1, minWidth: "1024px", overflowY: "scroll", pl: 2, pr: 2 }}
    >
      <OrganizationTile
        imageSx={{ maxHeight: "50px", p: 0, pb: 1 }}
        logo={logo as string | undefined}
        marketIds={marketIds}
        name={name}
        sx={{ height: "150px", ml: 2, width: "232px" }}
      />
      <Grid item={true} lg={4} xs={4}>
        <Typography color="textSecondary" mb={1} variant="body2">
          Description
        </Typography>
        <Typography sx={{ wordBreak: "break-all" }} variant="body1">
          {description}
        </Typography>
      </Grid>
      <Grid item={true} lg={5} xl={6} xs={4}>
        <Typography color="textSecondary" mb={1} variant="body2">
          Contact request email
        </Typography>
        <Typography variant="body1">
          {collaborationRequestUsers.map((email) => (
            <Chip
              key={email}
              label={
                <Typography fontWeight="medium" variant="body2">
                  {email}
                </Typography>
              }
              size="medium"
              sx={{
                borderRadius: 0,
                height: "28px",
                mb: 1,
                mr: 1,
                mt: 0,
              }}
              variant="outlined"
            />
          ))}
        </Typography>
      </Grid>
    </Grid>
  );
};

DataPartnerDisplayConfigurationPresentation.displayName =
  "DataPartnerDisplayConfigurationPresentation";

export default memo(DataPartnerDisplayConfigurationPresentation);
