import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Popover, Tooltip, useTheme } from "@mui/material";
import { memo, useState } from "react";
import { type ActivationType, type Audience } from "features/MediaInsightsDcr";
import { usePopupState } from "hooks";
import { ActivationDownloadButton, SaveAsDatasetButton } from "./components";

type ExportAudienceButtonProps = {
  activationType: ActivationType;
  activatedAudiences: Audience[];
  audienceType: string;
  reach: number;
};

const ExportAudienceButton: React.FC<ExportAudienceButtonProps> = ({
  activatedAudiences,
  activationType,
  reach,
  audienceType,
}) => {
  const theme = useTheme();
  const [isExportAudienceLoading, setExportAudienceLoading] = useState(false);
  const {
    anchorEl: anchorElExportButtonButton,
    isOpen: isExportButtonPopoverOpen,
    open: openExportButtonPopover,
    close: closeExportButtonPopover,
  } = usePopupState({
    popupId: "Export",
    variant: "popover",
  });

  return (
    <>
      <Tooltip
        disableFocusListener={true}
        disableTouchListener={true}
        placement="top"
        title="Export audience with the list of activation IDs"
      >
        <LoadingButton
          color="primary"
          endIcon={<FontAwesomeIcon icon={faChevronDown} />}
          loading={isExportAudienceLoading}
          loadingPosition="start"
          onClick={openExportButtonPopover}
          sx={{
            "& .MuiButton-endIcon": { svg: { fontSize: "12px" } },
            "& .MuiLoadingButton-loadingIndicator": { left: "-12px" },
          }}
          variant="text"
        >
          Export
        </LoadingButton>
      </Tooltip>
      <Popover
        anchorEl={anchorElExportButtonButton}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        marginThreshold={8}
        onClose={closeExportButtonPopover}
        open={isExportButtonPopoverOpen}
        sx={{
          "& .MuiPopover-paper": {
            border: "none !important",
            boxShadow: `${theme.shadows[1]} !important`,
            display: "flex",
            flexDirection: "column",
            padding: "4px 0",
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <SaveAsDatasetButton
          activatedAudiences={activatedAudiences}
          activationType={activationType}
          audienceType={audienceType}
          closeExportButtonPopover={closeExportButtonPopover}
          reach={reach}
          setExportAudienceLoading={setExportAudienceLoading}
        />
        <ActivationDownloadButton
          activatedAudiences={activatedAudiences}
          activationType={activationType}
          audienceType={audienceType}
          closeExportButtonPopover={closeExportButtonPopover}
          reach={reach}
          setExportAudienceLoading={setExportAudienceLoading}
        />
      </Popover>
    </>
  );
};

ExportAudienceButton.displayName = "ExportAudienceButton";

export default memo(ExportAudienceButton);
