import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import {
  faCaretDown as faCaretDownSolid,
  faCaretRight as faCaretRightSolid,
  faLock,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { grey, orange } from "@mui/material/colors";
import { useCallback } from "react";
import { DataRoomParticipantAvatarGroup, NodeTooltip } from "components";
import { type DataRoomData, type DataRoomDataTable } from "models";
import { useDataNodeConstructorParams } from "./DataNodeConstructorParamsWrapper";

interface DataNodeTileProps {
  dataNode: DataRoomData;
  isCollapsed: boolean;
  onToggleCollapsion: (dataNodeId: string) => void;
  renderDataActions?: (dataNode: DataRoomData) => React.ReactNode;
}

const DataNodeTile: React.FC<DataNodeTileProps> = ({
  dataNode,
  isCollapsed,
  onToggleCollapsion,
  renderDataActions,
}) => {
  const { id: dataNodeId } = dataNode;
  const toggle = useCallback(
    () => onToggleCollapsion(dataNodeId),
    [onToggleCollapsion, dataNodeId]
  );
  const { withUploading, withDataDeletion, withDatasetStatus, readOnly } =
    useDataNodeConstructorParams();
  return (
    <Grid container={true} spacing={1}>
      <Grid
        alignItems="center"
        container={true}
        item={true}
        onClick={toggle}
        style={{
          alignItems: "flex-start",
          cursor: "pointer",
          flexWrap: "nowrap",
        }}
        xs={12}
      >
        <FontAwesomeIcon
          fixedWidth={true}
          icon={isCollapsed ? faCaretRightSolid : faCaretDownSolid}
          style={{ cursor: "pointer", margin: "8px 4px" }}
        />
        <div style={{ flex: 1, marginTop: 4, width: 0 }}>
          <NodeTooltip nodeType="data" value={dataNodeId}>
            {dataNode.name}
          </NodeTooltip>
        </div>
        {dataNode.dataType === "table" && (
          <Typography
            style={{ lineHeight: "32px", marginLeft: 16 }}
            variant="caption"
          >
            {(dataNode as DataRoomDataTable).columns.length === 0 && (
              <FontAwesomeIcon
                color={orange[400]}
                fixedWidth={true}
                icon={faTriangleExclamation}
                style={{ marginRight: 4 }}
              />
            )}
            {(dataNode as DataRoomDataTable).columns.length} column
            {(dataNode as DataRoomDataTable).columns.length !== 1 ? "s" : ""}
          </Typography>
        )}
        <Box
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            marginLeft: "8px",
          }}
        >
          <DataRoomParticipantAvatarGroup
            participants={dataNode.participants.map(
              ({ userEmail: name, uploadedAt }) => ({
                active: !!uploadedAt && withDatasetStatus,
                name,
              })
            )}
          />
          {readOnly &&
          !withUploading &&
          !withDataDeletion &&
          !dataNode.participants.length ? (
            <Tooltip
              placement="top-start"
              title={`There are no Data Owners with permissions to provision datasets to this ${
                dataNode.dataType === "table" ? "table" : "file"
              }`}
            >
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faLock}
                style={{ color: grey[400], margin: "0 .5rem" }}
              />
            </Tooltip>
          ) : null}
        </Box>
        {/* Temporary hiding this as we have only one dataset for table */}
        {/* withStatus && (
          <Typography
            style={{ lineHeight: "32px", marginLeft: 4 }}
            variant="caption"
          >
            {dataUploadingStatus(table.participants)}
          </Typography>
        )*/}
        {(withUploading || withDataDeletion) && renderDataActions
          ? renderDataActions(dataNode)
          : null}
      </Grid>
    </Grid>
  );
};

export default DataNodeTile;
