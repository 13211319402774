import { useMemo } from "react";
import { useSelectedAudienceAggregations } from "features/mediaDataRoomV2/components/LookalikeMediaInsights/hooks";
import { getAggregationPresentation } from "features/mediaDataRoomV2/models";

// Returns aggregations for the selected audience type
const useLookalikeMediaAggregationData = (selectedAudienceType: string) => {
  const aggregations = useSelectedAudienceAggregations(selectedAudienceType);

  const aggregationData = useMemo(
    () =>
      aggregations.map(({ aggregation = [], id }) => {
        return {
          label: aggregation
            .map(({ column }) => getAggregationPresentation(column))
            .join("-"),
          value: id,
        };
      }),
    [aggregations]
  );

  return aggregationData;
};

export default useLookalikeMediaAggregationData;
