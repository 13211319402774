import { SalesforceProductType } from "@decentriq/graphql/dist/types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { SalesforceProductTypeMap } from "features/datasets/components/ExternalConnectionConfiguration/components";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
} from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "../../../../types";

type SalesforceFormProps = ImportExternalDataFormProps & {
  ActionsWrapper?: React.ComponentType;
  FormWrapper?: React.ComponentType;
};

const SalesforceFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    apiName: yup.string().trim().required("Salesforce Object is required"),
    domainUrl: yup.string().trim().required("My Domain is required"),
    productType: yup
      .mixed()
      .oneOf(Object.values(SalesforceProductType))
      .required("Product type is required"),
  }),
  credentials: yup.object({
    clientId: yup.string().trim().required("Client ID is required"),
    clientSecret: yup.string().trim().required("Client secret is required"),
  }),
  datasetName: yup.string(),
});

type SalesforceFormValues = yup.InferType<
  typeof SalesforceFormValidationSchema
>;

const SalesforceForm: React.FC<SalesforceFormProps> = ({
  onSubmit,
  onCancel,
  ActionsWrapper = ExternalConnectionActionsWrapper,
  FormWrapper = Box,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        apiName: "",
        domainUrl: "",
        productType: SalesforceProductType.Core,
      },
      credentials: {
        clientId: "",
        clientSecret: "",
      },
      datasetName: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(SalesforceFormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: SalesforceFormValues) => {
      const {
        configuration: { apiName = "", domainUrl = "", productType = "" } = {},
        credentials: { clientId = "", clientSecret = "" } = {},
        datasetName = "",
      } = formValues;

      onSubmit({
        input: {
          datasetName: datasetName.trim(),
          salesforce: {
            apiName: apiName.trim(),
            credentials: {
              clientId: clientId.trim(),
              clientSecret: clientSecret.trim(),
            },
            domainUrl: domainUrl.trim(),
            productType: productType.trim(),
          },
        },
      });
      reset();
    },
    [reset, onSubmit]
  );

  return (
    <>
      <FormWrapper>
        <form>
          <Box>
            <ExternalConnectionConfigurationLabel />
            <Controller
              control={control}
              name="configuration.productType"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.productType;
                const withError = !isEmpty(fieldError);
                return (
                  <FormControl sx={{ mb: 0.5, mt: 1, width: "260px" }}>
                    <FormLabel component="legend">Product type</FormLabel>
                    <Select
                      displayEmpty={true}
                      error={withError}
                      size="small"
                      style={{ background: "transparent" }}
                      variant="standard"
                      {...field}
                    >
                      {Object.values(SalesforceProductType).map((value) => (
                        <MenuItem
                          disabled={
                            value === SalesforceProductType.MarketingCloud
                          }
                          key={value}
                          value={value}
                        >
                          <ListItemText
                            primary={SalesforceProductTypeMap.get(
                              value as SalesforceProductType
                            )}
                            secondary={
                              value === SalesforceProductType.MarketingCloud
                                ? "Please contact support"
                                : ""
                            }
                            secondaryTypographyProps={{
                              sx: {
                                maxWidth: "340px",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                    {withError && (
                      <FormHelperText error={withError}>
                        {fieldError?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.domainUrl"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.domainUrl;
                return (
                  <TextField
                    InputProps={{
                      sx: {
                        "& .MuiInput-input": { padding: "7px 0" },
                        "&:before": { borderBottomStyle: "solid" },
                      },
                    }}
                    error={!isEmpty(fieldError)}
                    fullWidth={true}
                    helperText={fieldError?.message}
                    label="My Domain"
                    placeholder="Example: owndomain.my.salesforce.com"
                    size="small"
                    sx={{ mb: 1 }}
                    variant="standard"
                    {...field}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.apiName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.apiName;
                return (
                  <TextField
                    InputProps={{
                      sx: {
                        "& .MuiInput-input": { padding: "7px 0" },
                        "&:before": { borderBottomStyle: "solid" },
                      },
                    }}
                    error={!isEmpty(fieldError)}
                    fullWidth={true}
                    helperText={fieldError?.message}
                    label="Salesforce Object"
                    placeholder="Example: CustomerList__c"
                    size="small"
                    sx={{ mb: 1 }}
                    variant="standard"
                    {...field}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="datasetName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.datasetName;
                return (
                  <TextField
                    InputProps={{
                      sx: {
                        "& .MuiInput-input": { padding: "7px 0" },
                        "&:before": { borderBottomStyle: "solid" },
                      },
                    }}
                    error={!isEmpty(fieldError)}
                    fullWidth={true}
                    helperText={fieldError?.message}
                    label="Stored dataset name (optional)"
                    placeholder="Example: DQ_dataset_name"
                    size="small"
                    sx={{ mb: 1 }}
                    variant="standard"
                    {...field}
                  />
                );
              }}
            />
          </Box>
          <Box mb={2} mt={2}>
            <ExternalConnectionCredentialsLabel />
            <Controller
              control={control}
              name="credentials.clientId"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.clientId;
                return (
                  <TextField
                    InputProps={{
                      sx: {
                        "& .MuiInput-input": { padding: "7px 0" },
                        "&:before": { borderBottomStyle: "solid" },
                      },
                    }}
                    error={!isEmpty(fieldError)}
                    fullWidth={true}
                    helperText={fieldError?.message}
                    label="Client ID"
                    placeholder="Example: 4NBTEYKgiArB1Wngttv8hHxLezlGuPVYihk..."
                    size="small"
                    sx={{ mb: 1 }}
                    variant="standard"
                    {...field}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="credentials.clientSecret"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.clientSecret;
                return (
                  <TextField
                    InputProps={{
                      sx: {
                        "& .MuiInput-input": { padding: "7px 0" },
                        "&:before": { borderBottomStyle: "solid" },
                      },
                    }}
                    error={!isEmpty(fieldError)}
                    fullWidth={true}
                    helperText={fieldError?.message}
                    label="Client secret"
                    placeholder="Example: EEA998BF910D4332392091999..."
                    size="small"
                    sx={{ mb: 1 }}
                    variant="standard"
                    {...field}
                  />
                );
              }}
            />
          </Box>
        </form>
      </FormWrapper>
      <ActionsWrapper>
        <Button
          color="inherit"
          onClick={handlePreviousStepClick}
          variant="text"
        >
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Import
        </Button>
      </ActionsWrapper>
    </>
  );
};

SalesforceForm.displayName = "SalesforceForm";

export default memo(SalesforceForm);
