export type ExternalConnectionFormProps = {
  onCancel: () => void;
  submitButtonText?: string;
  defaultValues?: any;
};

export enum ExternalConnectionType {
  EXPORT,
  IMPORT,
}
