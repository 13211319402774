import { type ExportDatasetInput } from "@decentriq/graphql/dist/types";
import { type ExternalConnectionFormProps } from "features/datasets/components/ExternalConnections";

export enum EXPORT_DATASET_STEPS {
  SELECT_TARGET,
  TARGET_FORM,
  EXPORT_STATUS,
}

export type ExportDatasetTargetFormPayload = {
  input: Omit<ExportDatasetInput, "manifestHash">;
};

export type ExportDatasetFormProps = {
  onSubmit: (payload: ExportDatasetTargetFormPayload) => void;
} & ExternalConnectionFormProps;
