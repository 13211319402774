import { DataTargetType } from "@decentriq/graphql/dist/types";
import { memo, useState } from "react";
import {
  EXPORT_DATASET_STEPS,
  ExportDatasetDialogProvider,
} from "features/datasets/components/ExportDataset";

interface ExportDatasetDialogWrapperProps {
  children: React.ReactNode;
  datasetManifestHash: string;
}

const ExportDatasetDialogWrapper: React.FC<ExportDatasetDialogWrapperProps> = ({
  children,
  datasetManifestHash,
}) => {
  // After submitting a form, datasetExportId is returned, so dataset export data (incl. status)
  // can be fetched via datasetExport query on the EXPORT_STATUS step
  const [datasetExportId, setDatasetExportId] = useState<string | null>(null);
  const [exportStep, setExportStep] = useState<EXPORT_DATASET_STEPS>(
    EXPORT_DATASET_STEPS.SELECT_TARGET
  );
  const [selectedDataTarget, setSelectedDataTarget] = useState<DataTargetType>(
    DataTargetType.S3
  );
  // Returns true if the first step of external export is completed (creating export itself)
  const [isExportCreated, setIsExportCreated] = useState<boolean>(false);
  // Errors that come from the local resolver
  const [datasetExportError, setDatasetExportError] = useState<string | null>(
    null
  );

  return (
    <ExportDatasetDialogProvider
      value={{
        datasetExportError,
        datasetExportId,
        datasetManifestHash,
        exportStep,
        isExportCreated,
        selectedDataTarget,
        setDatasetExportError,
        setDatasetExportId,
        setExportStep,
        setIsExportCreated,
        setSelectedDataTarget,
      }}
    >
      {children}
    </ExportDatasetDialogProvider>
  );
};

export default memo(ExportDatasetDialogWrapper);
