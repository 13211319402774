import { TextField } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ExternalConnectionCredentialsLabel } from "features/datasets/components/ExternalConnections";

const S3BucketFormCredentials: React.FC = () => {
  const { control } = useFormContext();
  return (
    <>
      <ExternalConnectionCredentialsLabel />
      <Controller
        control={control}
        name="credentials.accessKey"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.credentials?.accessKey;
          return (
            <TextField
              InputProps={{
                sx: {
                  "& .MuiInput-input": { padding: "7px 0" },
                  "&:before": { borderBottomStyle: "solid" },
                },
              }}
              error={!isEmpty(fieldError)}
              fullWidth={true}
              helperText={fieldError?.message}
              label="Access Key"
              placeholder="Example: 5JG8C6HNDPQF7W2X3Y1Z"
              size="small"
              sx={{ mb: 1 }}
              variant="standard"
              {...field}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="credentials.secretKey"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.credentials?.secretKey;
          return (
            <TextField
              InputProps={{
                sx: {
                  "& .MuiInput-input": { padding: "7px 0" },
                  "&:before": { borderBottomStyle: "solid" },
                },
              }}
              autoComplete="off"
              error={!isEmpty(fieldError)}
              fullWidth={true}
              helperText={fieldError?.message}
              label="Secret Key"
              placeholder="Example: F57yT3QaX2nD8jHr4KlP9cVs1W6zU0E2sY8hN4bM"
              size="small"
              sx={{ mb: 1 }}
              variant="standard"
              {...field}
            />
          );
        }}
      />
    </>
  );
};

S3BucketFormCredentials.displayName = "S3BucketFormCredentials";

export default memo(S3BucketFormCredentials);
