import { memo } from "react";

type SportradarFormProps = {};

const SportradarForm: React.FC<SportradarFormProps> = () => {
  return <>SportradarForm</>;
};

SportradarForm.displayName = "SportradarForm";

export default memo(SportradarForm);
