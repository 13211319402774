import { exceptions } from "@decentriq/utils";
import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { JsonEditorField, Loading } from "components";
import { usePublishedDataRoom } from "contexts";
import { DataNodeUploadDataDialog } from "features";
import DataNodeActions, {
  DataNodeActionsContainer,
  DataNodeActionsPlaceholder,
} from "features/dataNodes/components/DataNodeConstructor/DataNodeActions";
import DataNodeDataButton from "features/dataNodes/components/DataNodeConstructor/DataNodeDataButton";
import { DataNodeConstructorMode } from "features/dataNodes/models";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
  type FileIngestionDefinition,
} from "features/datasets";
import { useGetValidationReport, useReportError } from "hooks";
import {
  type DataRoomData,
  type DataRoomDataTable,
  DataRoomType,
} from "models";
import useCommittedDataNodeActions from "./useComittedDataNodeActions";

interface CommitedDataNodeActionsProps {
  dataRoomId: string;
  dataNode: DataRoomData;
  mode: DataNodeConstructorMode;
  nodesLoading: boolean;
}

const ValidationReportDialog: React.FC<{
  dataNode: DataRoomData;
  dataRoomId: string;
  onClose: () => void;
  open: boolean;
}> = ({ open, onClose, dataRoomId, dataNode }) => {
  const { driverAttestationHash } = usePublishedDataRoom();
  const validationReport = useGetValidationReport({
    dataRoomId,
    datanodeId: dataNode.id,
    datasetHash: dataNode.dataset?.datasetHash!,
    driverAttestationHash,
    enabled: open,
  });
  const formattedValue = useMemo(() => {
    const data = validationReport.data;
    return data && typeof data === "string"
      ? JSON.stringify(JSON.parse(data), null, 2)
      : data;
  }, [validationReport.data]);

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>
          Validation report for {dataNode.name}{" "}
          {dataNode.dataType === "table" ? "table" : "file"}
        </span>
        <IconButton onClick={onClose}>
          <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {validationReport.isLoading ? (
          <Loading />
        ) : (
          <JsonEditorField
            editorOptions={{
              lineNumbers: "off",
              readOnly: true,
              resizable: false,
            }}
            height={400}
            value={formattedValue}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CommittedDataNodeActions: React.FC<CommitedDataNodeActionsProps> = ({
  dataNode,
  mode,
  dataRoomId,
  nodesLoading,
}) => {
  const { testing } = usePublishedDataRoom();
  const reportError = useReportError();
  const [validationReportOpen, setValidationReportOpen] = useState(false);
  const {
    activeDataRoomUpload,
    currentUserEmail,
    dataNodeForIngestion,
    handleIngestData,
    handleDataDeprovision,
    handleConnectFromKeychain,
    resetUploadings,
    setDataNodeForIngestion,
    uploadings,
    handleUploadClose,
  } = useCommittedDataNodeActions({ dataRoomId });
  useEffect(() => {
    if (
      mode === DataNodeConstructorMode.ACTION ||
      mode === DataNodeConstructorMode.STATUS ||
      mode === DataNodeConstructorMode.DEGRADE_ACTION
    ) {
      resetUploadings();
    }
  }, [mode, dataRoomId, resetUploadings]);
  const onDataDeprovision = useCallback(
    async () => await handleDataDeprovision(dataNode.id),
    [handleDataDeprovision, dataNode.id]
  );
  const handleError = useCallback(
    (error: Error) => {
      if (
        error instanceof exceptions.DatasetValidationError &&
        error.hasReport
      ) {
        return;
      }
      reportError(
        {
          details: error.message,
          errorContext: [
            {
              content: dataRoomId,
              name: "dataRoomId",
            },
          ],
          origin: DataRoomType.DataScience,
        },
        { silent: true }
      );
    },
    [reportError, dataRoomId]
  );
  const dataset = testing ? dataNode.testDataset : dataNode.dataset;
  const id = dataNodeForIngestion?.id;
  const onIngest = useCallback(
    async (
      payload:
        | DataIngestionPayload<DatasetIngestionDefinition>
        | DataIngestionPayload<FileIngestionDefinition>
    ) => {
      if (payload.source === "local") {
        return await handleIngestData({
          dataNodeId: id!,
          schema: payload.schema,
          shouldStoreInKeychain: !!payload.shouldStoreInKeychain,
          uploadResult: payload.uploadResult!,
        });
      }
      if (payload.source === "keychain") {
        return await handleConnectFromKeychain(
          id!,
          payload.datasetKeychainItem!
        );
      }
    },
    [id, handleIngestData, handleConnectFromKeychain]
  );
  if (
    (mode === DataNodeConstructorMode.ACTION ||
      mode === DataNodeConstructorMode.DEGRADE_ACTION) &&
    !testing
  ) {
    if (!dataNode.participants.length) {
      return (
        <DataNodeActionsContainer>
          <Tooltip
            title={`There are no Data Owners with permissions to provision datasets to this ${
              dataNode.dataType === "table" ? "table" : "file"
            }`}
          >
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faLock}
              style={{ color: grey[400] }}
            />
          </Tooltip>
        </DataNodeActionsContainer>
      );
    }
    if (!dataNode.hasPermission && dataset) {
      return (
        <DataNodeActionsContainer>
          <Box ml={2}>
            <DataNodeDataButton
              dataType={dataNode.dataType}
              datasetHash={dataset!.datasetHash}
              id={dataNode.id}
              secondary={true}
            />
          </Box>
        </DataNodeActionsContainer>
      );
    }
    if (
      !dataNode.hasPermission ||
      (mode === DataNodeConstructorMode.DEGRADE_ACTION && !dataset)
    ) {
      return (
        <DataNodeActionsContainer>
          <Box ml={2}>
            <DataNodeActionsPlaceholder
              dataType={dataNode.dataType}
              testing={testing}
            />
          </Box>
        </DataNodeActionsContainer>
      );
    }
  }
  const key = `${dataNode.id}-${currentUserEmail}`;
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DataNodeActions
        dataType={dataNode.dataType}
        datasetHash={dataset?.datasetHash}
        hasValidationError={!testing && dataNode.dataType === "table"}
        id={dataNode.id}
        isLoading={
          uploadings[key]?.isLoading ||
          activeDataRoomUpload === key ||
          nodesLoading
        }
        onDeprovision={onDataDeprovision}
        onUpload={() => {
          setDataNodeForIngestion(dataNode);
        }}
        openValidationReport={() => setValidationReportOpen(true)}
        testing={testing}
      />
      {dataNodeForIngestion && (
        <DataNodeUploadDataDialog
          columns={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).columns
              : undefined
          }
          columnsOrder={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).columnsOrder
              : undefined
          }
          id={dataNodeForIngestion.id}
          name={dataNodeForIngestion.name}
          onClose={handleUploadClose}
          onError={handleError}
          onIngest={onIngest}
          open={!!dataNodeForIngestion}
          uniqueColumnIds={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).uniqueColumnIds
              : undefined
          }
        />
      )}
      <ValidationReportDialog
        dataNode={dataNode}
        dataRoomId={dataRoomId}
        onClose={() => setValidationReportOpen(false)}
        open={validationReportOpen}
      />
    </div>
  );
};

export default CommittedDataNodeActions;
