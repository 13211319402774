import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { memo } from "react";

interface DataRoomRequestSubmissionDialogProps {
  warning?: React.ReactElement | null;
  open: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const DataRoomRequestSubmissionDialog: React.FC<
  DataRoomRequestSubmissionDialogProps
> = ({ warning, open, loading, onCancel, onConfirm }) => {
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open}>
      <DialogContent>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon fixedWidth={true} icon={faInfoCircle} size="3x" />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom={true}
          style={{ lineHeight: 1.25 }}
          variant="h6"
        >
          <strong>
            Submitting this request will ask all affected Data Owners for
            approval.
          </strong>
        </Typography>
        {warning}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={onCancel}>
          Go back to editing
        </Button>
        <LoadingButton
          color="inherit"
          data-testid="popup_publish_dcr_btn"
          loading={loading}
          loadingPosition="start"
          onClick={onConfirm}
          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
        >
          Submit for approval
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DataRoomRequestSubmissionDialog.displayName = "DataRoomRequestSubmissionDialog";

export default memo(DataRoomRequestSubmissionDialog);
