import { DqCard, DqCardContent } from "@decentriq/components";
import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFile, faTable } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import { memo } from "react";
import { type DataType } from "models";

interface LocalDataSourceSelectorProps {
  onTypeChange: (type: DataType) => void;
  selectedType: DataType | null;
}

interface LocalDataSourceTypeOption {
  title: string;
  type: DataType;
  icon: IconProp;
}

const localDataSourceTypeOptions: LocalDataSourceTypeOption[] = [
  {
    icon: faFile,
    title: "Upload as a file",
    type: "unstructured",
  },
  {
    icon: faTable,
    title: "Upload as a table",
    type: "table",
  },
];

const LocalDataSourceSelector = memo<LocalDataSourceSelectorProps>(
  ({ selectedType, onTypeChange }) => (
    <Grid columnSpacing={2} container={true}>
      {localDataSourceTypeOptions.map(({ type, title, icon }) => (
        <Grid item={true} key={type} xs={6}>
          <DqCard
            onSelect={() => onTypeChange(type)}
            selected={type === selectedType}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <DqCardContent>
              <FontAwesomeIcon icon={icon} size="3x" />
              <Typography fontWeight="600" mt={1}>
                {title}
              </Typography>
            </DqCardContent>
          </DqCard>
        </Grid>
      ))}
    </Grid>
  )
);

LocalDataSourceSelector.displayName = "LocalDataSourceSelector";

export default LocalDataSourceSelector;
