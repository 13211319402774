import { useAuth0 } from "@auth0/auth0-react";
import {
  useSetUserHasLoggedInBeforeMutation,
  useUserHasLoggedInBeforeQuery,
} from "@decentriq/graphql/dist/hooks";
import { memo, type PropsWithChildren, useCallback } from "react";
import { WelcomePopup } from "components";
import {
  KeychainStatus,
  useKeychain,
} from "wrappers/KeychainWrapper/KeychainWrapper";

const WelcomeWrapper = memo<PropsWithChildren>(({ children }) => {
  const { user, isAuthenticated } = useAuth0();
  const { data: userHasLoggedInBeforeData } = useUserHasLoggedInBeforeQuery({
    skip: user?.email === null,
    variables: { email: user?.email! },
  });
  const { userByEmail } = userHasLoggedInBeforeData || {};
  const { id, hasLoggedInBefore = true } = userByEmail || {};
  const { status } = useKeychain();
  const [setUserHasLoggedInBeforeMutation] =
    useSetUserHasLoggedInBeforeMutation();
  const onClose = useCallback(() => {
    if (id) {
      setUserHasLoggedInBeforeMutation({
        variables: {
          input: {
            loggedIn: true,
            userId: id,
          },
        },
      });
    }
  }, [id, setUserHasLoggedInBeforeMutation]);
  return (
    <>
      {children}
      <WelcomePopup
        onClose={onClose}
        open={
          isAuthenticated &&
          status === KeychainStatus.unlocked &&
          !hasLoggedInBefore
        }
      />
    </>
  );
});

export default WelcomeWrapper;
