import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface AttributionItemProps {
  options: { label: string; value: string | number }[];
  attributionLabel: string;
  fieldName: string;
  customOptions?: JSX.Element[];
}

const AttributionItem: React.FC<AttributionItemProps> = ({
  options,
  attributionLabel,
  fieldName,
  customOptions,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => {
        return (
          <FormControl size="small" sx={{ mb: 2, width: "250px" }}>
            <Typography fontSize="small">{attributionLabel}</Typography>
            <Select displayEmpty={true} variant="standard" {...field}>
              {customOptions?.length
                ? customOptions
                : options.map(({ label, value }) => {
                    return (
                      <MenuItem key={`${value}-${label}`} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};

export default memo(AttributionItem);
