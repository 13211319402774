import { DqCard, DqCardContent } from "@decentriq/components";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  alpha,
  Box,
  styled,
  type SxProps,
  type Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { Chip } from "components";

const StyledImg = styled("img")(({ theme }) => ({
  maxHeight: "70px",
  maxWidth: "100%",
  padding: theme.spacing(1),
}));

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  maxHeight: "70px",
  maxWidth: "100%",
  padding: theme.spacing(1),
}));

interface OrganizationTileProps {
  name: string;
  marketIds: string[];
  logo: string | undefined;
  sx?: SxProps<Theme>;
  imageSx?: SxProps<Theme>;
  selected?: boolean;
  onSelect?: () => void;
}

const OrganizationTile: React.FC<OrganizationTileProps> = ({
  name,
  logo,
  marketIds,
  sx,
  imageSx,
  selected,
  onSelect,
}) => (
  <DqCard onSelect={onSelect} selected={selected} sx={sx}>
    <DqCardContent>
      <>
        {logo ? (
          <StyledImg
            alt="publisher logo"
            loading="lazy"
            src={`data:image;base64,${logo}`}
            sx={imageSx}
          />
        ) : (
          <StyledIcon height="43px" icon={faNewspaper} size="3x" sx={imageSx} />
        )}
        <Tooltip placement="top" title={marketIds.join(", ")}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              mb: 1,
              mt: 1,
            }}
          >
            {marketIds
              .map((id) => (
                <Box key={id} sx={{ mb: 0.5, mr: 0.5, mt: 0 }}>
                  <Chip
                    key={id}
                    label={id}
                    sx={(theme) => ({
                      background: alpha(theme.palette.text.primary, 0.08),
                    })}
                  />
                </Box>
              ))
              .slice(0, 3)}
            {marketIds.length > 3 ? (
              <Box sx={{ mb: 0.5, mr: 0.5, mt: 0 }}>
                <Chip
                  label={`+${marketIds.length - 3}`}
                  sx={(theme) => ({
                    background: alpha(theme.palette.text.primary, 0.08),
                  })}
                />
              </Box>
            ) : null}
          </Box>
        </Tooltip>
        <Tooltip placement="top" title={name}>
          <Typography
            fontWeight={500}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
            textAlign="center"
            variant="body1"
          >
            {name}
          </Typography>
        </Tooltip>
      </>
    </DqCardContent>
  </DqCard>
);

OrganizationTile.displayName = "OrganizationTile";

export default memo(OrganizationTile);
