import { useParams } from "react-router-dom";
import { MediaDataRoom } from "features/mediaDataRoomV2";
import { Main } from "layouts";

const MediaDataRoomPageV2 = () => {
  const { dataRoomId } = useParams();
  return (
    <Main>
      <MediaDataRoom id={dataRoomId!} />
    </Main>
  );
};

export default MediaDataRoomPageV2;
