import { faShareFromSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Popover, Tooltip, useTheme } from "@mui/material";
import { memo, useState } from "react";
import { usePopupState } from "hooks";
import { ActivationExportButton, SaveAsDatasetButton } from "./components";

type DownloadAudienceButtonProps = {
  audienceType: string;
  reach: number;
};

const DownloadAudienceButton: React.FC<DownloadAudienceButtonProps> = ({
  reach,
  audienceType,
}) => {
  const theme = useTheme();
  const [isDownloadAudienceLoading, setDownloadAudienceLoading] =
    useState(false);
  const {
    anchorEl: anchorElDownloadButtonButton,
    isOpen: isDownloadButtonPopoverOpen,
    open: openDownloadButtonPopover,
    close: closeDownloadButtonPopover,
  } = usePopupState({
    popupId: "Download",
    variant: "popover",
  });

  return (
    <>
      <Tooltip
        disableFocusListener={true}
        disableTouchListener={true}
        placement="top"
        title="Export audience with the list of activation IDs"
      >
        <LoadingButton
          color="inherit"
          loading={isDownloadAudienceLoading}
          loadingPosition="start"
          onClick={openDownloadButtonPopover}
          startIcon={<FontAwesomeIcon icon={faShareFromSquare} />}
          sx={{ marginRight: 1 }}
          variant="text"
        >
          Export audience
        </LoadingButton>
      </Tooltip>
      <Popover
        anchorEl={anchorElDownloadButtonButton}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        marginThreshold={8}
        onClose={closeDownloadButtonPopover}
        open={isDownloadButtonPopoverOpen}
        sx={{
          "& .MuiPopover-paper": {
            border: "none !important",
            boxShadow: `${theme.shadows[1]} !important`,
            display: "flex",
            flexDirection: "column",
            padding: "4px 0",
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <SaveAsDatasetButton
          audienceType={audienceType}
          closeDownloadButtonPopover={closeDownloadButtonPopover}
          reach={reach}
          setDownloadAudienceLoading={setDownloadAudienceLoading}
        />
        <ActivationExportButton
          audienceType={audienceType}
          closeDownloadButtonPopover={closeDownloadButtonPopover}
          reach={reach}
          setDownloadAudienceLoading={setDownloadAudienceLoading}
        />
      </Popover>
    </>
  );
};

DownloadAudienceButton.displayName = "DownloadAudienceButton";

export default memo(DownloadAudienceButton);
