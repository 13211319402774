import { faUserPlus as faUserPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { SendInvitationDialog } from "components";
import { useUserRole } from "hooks";

type SendInvitationButtonProps = {
  organizationId: string;
};

const SendInvitationButton: React.FC<SendInvitationButtonProps> = ({
  organizationId,
}) => {
  const { isSuperAdminReadOnly, organizationId: userOrganizationId } =
    useUserRole();
  const [
    isSendInvitationDialogOpen,
    { setTrue: openSendInvitationDialog, setFalse: closeSendInvitationDialog },
  ] = useBoolean(false);
  return !isSuperAdminReadOnly && userOrganizationId === organizationId ? (
    <>
      <Button
        color="primary"
        onClick={openSendInvitationDialog}
        startIcon={
          <FontAwesomeIcon fixedWidth={true} icon={faUserPlusRegular} />
        }
        sx={{ marginLeft: "auto" }}
        variant="contained"
      >
        Send invitation
      </Button>
      <SendInvitationDialog
        onCancel={closeSendInvitationDialog}
        open={isSendInvitationDialogOpen}
        organizationId={organizationId}
      />
    </>
  ) : null;
};

SendInvitationButton.displayName = "SendInvitationButton";

export default memo(SendInvitationButton);
