import { useAuth0 } from "@auth0/auth0-react";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Popover, Typography } from "@mui/material";
import { memo } from "react";
import { usePopupState } from "hooks";
import { bindHover, bindPopover } from "hooks/usePopupState/usePopupState";

type PopoverParticipant = {
  id: string;
  email?: string;
  userEmail?: string;
};

type ParticipantsPopoverProps = {
  participants: PopoverParticipant[];
  ownerEmail: string;
};

const ParticipantsPopover: React.FC<ParticipantsPopoverProps> = ({
  participants = [],
  ownerEmail = "",
}) => {
  const { user: { email: currentUserEmail = "" } = {} } = useAuth0();
  const popupState = usePopupState({
    popupId: "participants",
    variant: "popover",
  });

  return (
    <>
      <Box
        {...bindHover(popupState)}
        sx={{ alignItems: "center", display: "flex" }}
      >
        <Typography display="inline" sx={{ marginRight: 0.5 }} variant="body2">
          {participants.length} participants
        </Typography>
        <FontAwesomeIcon icon={faChevronDown} size="xs" />
      </Box>
      <Popover
        slotProps={{
          paper: {
            sx: { padding: "6px" },
          },
        }}
        {...bindPopover(popupState)}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        marginThreshold={8}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {participants.map(({ userEmail, email }, index) => (
            <Typography key={index} mb={0.5} variant="body2">
              {userEmail || email}{" "}
              <strong>
                {currentUserEmail === (userEmail || email) && "(you)"}
              </strong>
              <strong>
                {userEmail === (ownerEmail || email) && "(creator)"}
              </strong>
            </Typography>
          ))}
        </Box>
      </Popover>
    </>
  );
};

ParticipantsPopover.displayName = "ParticipantsPopover";

export default memo(ParticipantsPopover);
