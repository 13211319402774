import { testIds } from "@decentriq/utils";
import {
  faPencil as faPencilRegular,
  faPlus as faPlusRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
} from "hooks";
import {
  defaultPublisherDisplayConfigurationFormValues,
  type PublisherDisplayConfigurationFormValues,
  PublisherDisplayConfigurationValidationSchema,
} from "../PublisherDisplayConfigurationForm/model";
import PublisherDisplayConfigurationForm from "../PublisherDisplayConfigurationForm/PublisherDisplayConfigurationForm";

interface UpsertPublisherDisplayConfigurationDialogProps {
  updating?: boolean;
  open: boolean;
  onClose: () => void;
}

const UpsertPublisherDisplayConfigurationDialog: React.FC<
  UpsertPublisherDisplayConfigurationDialogProps
> = ({ open, onClose, updating }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.PUBLISHER_PORTAL,
  });
  const { handleSubmit, reset, ...publisherDisplayConfigurationForm } =
    useForm<PublisherDisplayConfigurationFormValues>({
      defaultValues: defaultPublisherDisplayConfigurationFormValues,
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(PublisherDisplayConfigurationValidationSchema),
    });
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);
  const {
    publisherDisplayConfiguration,
    createPublisherDisplayConfiguration,
    updatePublisherDisplayConfiguration,
  } = usePublisherPortal();
  const publisherDisplayConfigurationId = useMemo(
    () => publisherDisplayConfiguration?.id,
    [publisherDisplayConfiguration?.id]
  );
  const handleCreate = useCallback(
    (values: PublisherDisplayConfigurationFormValues) =>
      createPublisherDisplayConfiguration({
        onCompleted: () => {
          enqueueSnackbar(`Display configuration has been created`);
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Cannot create Publisher display configuration"
            )
          );
        },
        variables: {
          input: {
            ...values,
            collaborationRequestUsers: values.collaborationRequestUsers || [],
          },
        },
      }),
    [createPublisherDisplayConfiguration, enqueueSnackbar, onClose]
  );
  const handleUpdate = useCallback(
    (id: string, values: PublisherDisplayConfigurationFormValues) =>
      updatePublisherDisplayConfiguration({
        onCompleted: () => {
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Cannot update Publisher display configuration"
            )
          );
        },
        variables: {
          input: {
            collaborationRequestUsers: values.collaborationRequestUsers,
            description: {
              value: values.description,
            },
            id,
            logo: {
              value: values.logo,
            },
            marketIds: values.marketIds,
            publisherName: {
              value: values.publisherName,
            },
          },
        },
      }),
    [updatePublisherDisplayConfiguration, enqueueSnackbar, onClose]
  );
  const handleUpsert = useCallback(
    async (values: PublisherDisplayConfigurationFormValues) => {
      setLoading(true);
      await (updating
        ? handleUpdate(publisherDisplayConfigurationId!, values)
        : handleCreate(values));
      setLoading(false);
    },
    [
      updating,
      publisherDisplayConfigurationId,
      handleCreate,
      handleUpdate,
      setLoading,
    ]
  );
  useEffect(() => {
    if (publisherDisplayConfiguration && open) {
      const {
        publisherName,
        description,
        collaborationRequestUsers,
        logo,
        marketIds,
      } = publisherDisplayConfiguration;
      reset({
        collaborationRequestUsers:
          collaborationRequestUsers ||
          defaultPublisherDisplayConfigurationFormValues.collaborationRequestUsers,
        description:
          description ||
          defaultPublisherDisplayConfigurationFormValues.description,
        logo: logo || defaultPublisherDisplayConfigurationFormValues.logo,
        marketIds:
          marketIds || defaultPublisherDisplayConfigurationFormValues.marketIds,
        publisherName:
          publisherName ||
          defaultPublisherDisplayConfigurationFormValues.publisherName,
      });
    }
  }, [publisherDisplayConfiguration, reset, open]);
  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={onClose} open={open}>
      <FormProvider
        {...publisherDisplayConfigurationForm}
        handleSubmit={handleSubmit}
        reset={reset}
      >
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {updating ? "Edit" : "Create"} display configuration
        </DialogTitle>
        <DialogContent>
          <PublisherDisplayConfigurationForm />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            color="primary"
            data-testid={
              testIds.publisherPortal.upsertDisplayConfigurationDialog
                .submitButton
            }
            loading={loading}
            loadingPosition="start"
            onClick={handleSubmit(handleUpsert)}
            startIcon={
              <FontAwesomeIcon
                icon={updating ? faPencilRegular : faPlusRegular}
              />
            }
            variant="contained"
          >
            {updating ? "Update" : "Create"}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
UpsertPublisherDisplayConfigurationDialog.displayName =
  "UpsertPublisherDisplayConfigurationDialog";

export default memo(UpsertPublisherDisplayConfigurationDialog);
