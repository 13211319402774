import { InfoTooltip } from "@decentriq/components";
import { useSetPrivacyFilterMutation } from "@decentriq/graphql/dist/hooks";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useDebounceFn } from "ahooks";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import usePrivacyFilter from "./usePrivacyFilter";

interface PrivacyFilterEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
}

const PrivacyFilterEditor: React.FC<PrivacyFilterEditorProps> = ({
  computeNodeId,
  readOnly,
}) => {
  const node = usePrivacyFilter(computeNodeId);
  const isPrivacyFilterEnabled = node?.privacyFilter?.isEnabled || false;
  const minimumRows = node?.privacyFilter?.minimumRows;
  const [privacyFilterValue, setPrivacyFilterValue] = useState<
    number | undefined
  >();
  useEffect(() => {
    if (privacyFilterValue === undefined && minimumRows !== undefined) {
      setPrivacyFilterValue(minimumRows);
    }
  }, [privacyFilterValue, minimumRows, setPrivacyFilterValue]);
  const [setPrivacyFilterMutation] = useSetPrivacyFilterMutation();
  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      if (!checked) {
        setPrivacyFilterValue(0);
      }
      setPrivacyFilterMutation({
        variables: {
          input: {
            id: computeNodeId,
            privacyFilterIsEnabled: checked,
            ...(!checked ? { privacyFilterMinimumRow: 0 } : {}),
          },
        },
      });
    },
    [computeNodeId, setPrivacyFilterMutation, setPrivacyFilterValue]
  );
  const updatePrivacyFilter = useCallback(
    (privacyFilterValue: number) =>
      setPrivacyFilterMutation({
        refetchQueries: ["DraftPrivacyFilter"],
        variables: {
          input: {
            id: computeNodeId,
            privacyFilterMinimumRow: privacyFilterValue,
          },
        },
      }),
    [computeNodeId, setPrivacyFilterMutation]
  );
  const { run: updatePrivacyFilterDebounced } = useDebounceFn(
    updatePrivacyFilter,
    { wait: 750 }
  );
  const onTextFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const privacyFilterValue = Number(event.target.value);
      setPrivacyFilterValue(privacyFilterValue);
      updatePrivacyFilterDebounced(privacyFilterValue);
    },
    [setPrivacyFilterValue, updatePrivacyFilterDebounced]
  );
  const inputRef = useRef<HTMLInputElement>();
  const Label = (
    <Typography ml={1} mr={0.5} sx={{ lineHeight: 1.2 }} variant="body2">
      Privacy filter: Enforce all SQL results to come from aggregating over at
      least
    </Typography>
  );
  const LabelTail = (
    <Fragment>
      {readOnly && <strong>{privacyFilterValue || 0}</strong>}
      <Typography ml={0.5} variant="body2">
        rows
      </Typography>
      <InfoTooltip
        tooltip={
          <Fragment>
            This k-anonymity privacy filter removes all rows from the output of
            a query that have not been calculated by aggregating over at least k
            rows, where k is the specified parameter.
            <br />
            If this option is enabled, it enforces that all computations with an
            associated analyst include a GROUP BY in the final SELECT statement.
          </Fragment>
        }
      />
    </Fragment>
  );
  return (
    <Box sx={{ alignItems: "center", display: "flex", mb: 2 }}>
      {readOnly ? (
        isPrivacyFilterEnabled ? (
          <Fragment>
            {Label}
            {LabelTail}
          </Fragment>
        ) : null
      ) : (
        <Fragment>
          <FormControlLabel
            componentsProps={{ typography: { variant: "body2" } }}
            control={
              <Checkbox
                checked={isPrivacyFilterEnabled}
                color="primary"
                onChange={onCheckboxChange}
                size="small"
              />
            }
            label={Label}
            style={{ margin: 0 }}
          />
          {!readOnly && (
            <TextField
              InputProps={{
                sx: { "&:before": { borderBottomStyle: "solid !important" } },
              }}
              disabled={!isPrivacyFilterEnabled}
              inputProps={{
                inputMode: "numeric",
                min: 0,
                pattern: "[0-9]",
                step: 1,
              }}
              inputRef={inputRef}
              onChange={onTextFieldChange}
              onFocus={() => {
                if (!Number(privacyFilterValue || 0)) {
                  inputRef.current?.select();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "," || e.key === ".") {
                  e.preventDefault();
                }
              }}
              size="small"
              style={{ width: "3.75rem" }}
              sx={({ spacing }) => ({ margin: spacing(0, 1) })}
              type="number"
              value={privacyFilterValue || 0}
              variant="standard"
            />
          )}
          {LabelTail}
        </Fragment>
      )}
    </Box>
  );
};

export default PrivacyFilterEditor;
