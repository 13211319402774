export enum UserKeychainStatus {
  Locked = "LOCKED",
  Unlocked = "UNLOCKED",
  Unset = "UNSET",
}

export type ResetKeychainPayload = {
  error?: KeychainOperationError;
};

export type ChangeKeychainPasswordPayload = {
  error?: KeychainOperationError;
};

export type RemoveKeychainItemPayload = {
  error?: KeychainOperationError;
  keychainItemId?: string;
  kind?: KeychainItemKind;
};

export enum KeychainStatus {
  initial,
  loading,
  unset,
  locked,
  unlocked,
  error,
  unknown,
}

export type KeychainItemPayload = {
  error?: KeychainOperationError;
  item?: KeychainItem;
};

export type KeychainItem = {
  id: string;
  kind: KeychainItemKind;
  value: string;
};

export type EmptyKeychainPayload = {
  error?: KeychainOperationError;
};

export enum KeychainItemKind {
  Dataset = "DATASET",
  DatasetMetadata = "DATASET_METADATA",
  PendingDatasetImport = "PENDING_DATASET_IMPORT",
}

export enum KeychainOperationErrorKind {
  ItemNotFound = "ITEM_NOT_FOUND",
  PermissionDenied = "PERMISSION_DENIED",
  WrongPassword = "WRONG_PASSWORD",
}

export type KeychainOperationError = {
  kind: KeychainOperationErrorKind;
  message?: string;
};

export type InsertKeychainItemPayload = {
  error?: KeychainOperationError;
  keychainItem?: KeychainItem;
};

export type UnlockKeychainPayload = {
  error?: KeychainOperationError;
};

export interface KeychainService {
  unlock(password: string): Promise<UnlockKeychainPayload>;
  create(password: string): Promise<void>;
  insertItem(item: KeychainItem): Promise<InsertKeychainItemPayload>;
  insertItems(items: KeychainItem[]): Promise<void>;
  removeItem(
    item: Omit<KeychainItem, "value">
  ): Promise<RemoveKeychainItemPayload>;
  removeItems(items: Omit<KeychainItem, "value">[]): Promise<void>;
  reset(): Promise<ResetKeychainPayload>;
  empty(password: string): Promise<EmptyKeychainPayload>;
  changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<ChangeKeychainPasswordPayload>;
  getItem(id: string, kind: KeychainItemKind): Promise<KeychainItemPayload>;
  getItems(): Promise<KeychainItem[]>;
  getCurrentStatusOrInitial(): Promise<UserKeychainStatus>;
  registerListener(callback: (() => void) | (() => Promise<void>)): () => void;
  initializeLocalKeychain(): Promise<void>;
  isInitialized(): boolean;
}
