import { Box, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { memo } from "react";
import TimeAgo from "react-timeago";
import { useComputeNodesVars } from "contexts";
import { useTimeFormatter } from "hooks";

interface ComputeNodeLastSavedProps {
  computeNodeId: string;
}

const ComputeNodeLastSaved: React.FC<ComputeNodeLastSavedProps> = memo(
  ({ computeNodeId }) => {
    const { readOnly } = useComputeNodesVars();
    const { updatedAt, isSaving } = {
      isSaving: false,
      updatedAt: new Date().toISOString(),
    }; // TODO: use correct query
    const timeFormatter = useTimeFormatter();
    return (
      <Box style={{ bottom: "8px", position: "absolute", right: "16px" }}>
        {updatedAt && !isSaving && !readOnly ? (
          <Typography color={grey["400"]} variant="caption">
            Last saved <TimeAgo date={updatedAt} formatter={timeFormatter} />
          </Typography>
        ) : undefined}
        {isSaving && <CircularProgress color="inherit" size={16} />}
      </Box>
    );
  }
);

ComputeNodeLastSaved.displayName = "ComputeNodeLastSaved";

export default ComputeNodeLastSaved;
