import {
  faCircle1 as faCircle1Light,
  faCircleXmark as faCircleXmarkLight,
} from "@fortawesome/pro-light-svg-icons";
import { faCircleCheck as faCircleCheckSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { memo } from "react";

interface MediaDataRoomCreateProgressProps {
  publishingCalled: boolean;
  publishing: boolean;
  publishingError?: unknown;
}

const MediaDataRoomCreateProgress: React.FC<MediaDataRoomCreateProgressProps> =
  memo(({ publishingCalled, publishing, publishingError }) => {
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 8,
        }}
      >
        {publishing && (
          <Box sx={{ mb: 2 }}>
            <CircularProgress color="inherit" size={64} thickness={1} />
          </Box>
        )}
        <Box>
          <Grid
            columnSpacing={1}
            container={true}
            rowSpacing={0}
            sx={{ mb: 0.5 }}
          >
            <Grid
              alignItems="center"
              display="flex"
              justifyContent="center"
              xs="auto"
            >
              {!publishingCalled ? (
                <FontAwesomeIcon fixedWidth={true} icon={faCircle1Light} />
              ) : publishing ? null : publishingError ? (
                <FontAwesomeIcon fixedWidth={true} icon={faCircleXmarkLight} />
              ) : (
                <FontAwesomeIcon fixedWidth={true} icon={faCircleCheckSolid} />
              )}
            </Grid>
            <Grid alignItems="center" display="flex" xs={true}>
              <Typography
                component="div"
                sx={{
                  lineHeight: "1.5rem",
                  transition: "all 0.2s linear",
                  whiteSpace: "nowrap",
                }}
              >
                {publishing
                  ? "Creating media data clean room…"
                  : publishingCalled
                    ? !publishingError
                      ? "Media data clean room was created"
                      : "Can't create media data clean room"
                    : "Create media data clean room"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  });

export default MediaDataRoomCreateProgress;
