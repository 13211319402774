import { Box, Paper, Typography } from "@mui/material";
import { type PointTooltipProps, ResponsiveLine } from "@nivo/line";
import { memo, useMemo } from "react";
import { useLookalikeMediaOverlapInsights } from "features/mediaDataRoomV2/components/LookalikeMediaInsights/hooks";
import {
  SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY,
  SHARE_IN_OVERLAP_CHART_KEY,
} from "../../constants";

const LineTooltip: React.FC<PointTooltipProps> = ({
  point: { serieId, data } = {},
}) => (
  <Paper sx={{ padding: "4px" }}>
    <Typography>
      <>
        <b>Aggregation:</b> {data?.x}
      </>
    </Typography>
    <Typography>
      <>
        <b>{serieId}:</b> {((data?.y || 0) * 100).toFixed(1)}%
      </>
    </Typography>
  </Paper>
);

const LineChart: React.FC = () => {
  const { aggregationData, isEmpty } = useLookalikeMediaOverlapInsights();

  const lineChartData = useMemo(
    () => [
      {
        data: aggregationData.map(({ aggregationName, shareInOverlap }) => ({
          x: aggregationName,
          y: shareInOverlap,
        })),
        id: SHARE_IN_OVERLAP_CHART_KEY,
      },
      {
        data: aggregationData.map(
          ({ aggregationName, shareInAddressableAudience }) => ({
            x: aggregationName,
            y: shareInAddressableAudience,
          })
        ),
        id: SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY,
      },
    ],
    [aggregationData]
  );

  return !isEmpty ? (
    <Box sx={{ height: "calc(100vh - 600px)", minHeight: "300px" }}>
      <ResponsiveLine
        axisLeft={{ format: (value) => `${((value || 0) * 100).toFixed(1)}%` }}
        {...(aggregationData.length > 6 ? { axisBottom: null } : {})}
        colors={["#37C7C7", "#2FA5E6"]}
        data={lineChartData}
        enableArea={true}
        enableCrosshair={true}
        isInteractive={true}
        legends={[
          {
            anchor: "top-left",
            direction: "row",
            itemDirection: "left-to-right",
            itemHeight: 20,
            itemOpacity: 0.75,
            itemWidth: 0,
            itemsSpacing: 200,
            justify: false,
            symbolSize: 12,
            translateX: -32,
            translateY: -32,
          },
        ]}
        margin={{
          bottom: aggregationData.length > 6 ? 8 : 32,
          left: 48,
          right: aggregationData.length > 6 ? 0 : 32,
          top: 32,
        }}
        tooltip={LineTooltip}
        useMesh={true}
      />
    </Box>
  ) : null;
};

LineChart.displayName = "LineChart";

export default memo(LineChart);
