import {
  useCreateDatasetExportMutation,
  useDatasetByHashQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type CreateDatasetExportMutation,
  DataTargetType,
} from "@decentriq/graphql/dist/types";
import { memo, useCallback } from "react";
import {
  EXPORT_DATASET_STEPS,
  type ExportDatasetTargetFormPayload,
  useExportDatasetDialog,
} from "features/datasets/components/ExportDataset";
import { ExternalConnectionType } from "features/datasets/components/ExternalConnections";
import {
  AzureForm,
  GoogleCloudStorageForm,
  S3BucketForm,
} from "features/datasets/components/ImportData/ImportExternalDataDialog/components";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  AdForm,
  GoogleAdManagerForm,
  GoogleDV360Form,
  MetaForm,
  MicrosoftForm,
  PermutiveForm,
  SplickyForm,
  SportradarForm,
  TradeDeskForm,
} from "./components";

const ExportDatasetTargetForm: React.FC = () => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const {
    selectedDataTarget,
    setExportStep,
    datasetManifestHash,
    setDatasetExportId,
    setIsExportCreated,
    setDatasetExportError,
  } = useExportDatasetDialog();
  const { data: dataByHash } = useDatasetByHashQuery({
    skip: !datasetManifestHash,
    variables: {
      manifestHash: datasetManifestHash!,
    },
  });
  const { name: datasetName } = dataByHash?.datasetByManifestHash || {};

  const [createDatasetExportMutation] = useCreateDatasetExportMutation();

  const submitFormHandler = useCallback(
    (createDatasetExportVariables: ExportDatasetTargetFormPayload) => {
      createDatasetExportMutation({
        onCompleted: ({ createDatasetExport }: CreateDatasetExportMutation) => {
          setDatasetExportId(createDatasetExport?.dataConnectorJob?.id);
          setTimeout(() => setIsExportCreated(true), 3000);
        },
        onError: (error) => {
          setDatasetExportError(error?.message);
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "The exporting process could not get started. Please try again by refreshing the page."
            )
          );
        },
        variables: {
          input: {
            ...(createDatasetExportVariables?.input || {}),
            manifestHash: datasetManifestHash,
          },
        },
      });
      setExportStep(EXPORT_DATASET_STEPS.EXPORT_STATUS);
    },
    [
      createDatasetExportMutation,
      datasetManifestHash,
      enqueueSnackbar,
      setDatasetExportError,
      setDatasetExportId,
      setExportStep,
      setIsExportCreated,
    ]
  );

  const cancelFormHandler = useCallback(() => {
    setExportStep(EXPORT_DATASET_STEPS.SELECT_TARGET);
  }, [setExportStep]);

  switch (selectedDataTarget) {
    case DataTargetType.S3:
      return (
        <S3BucketForm
          defaultValues={{ datasetName }}
          onCancel={cancelFormHandler}
          onSubmit={submitFormHandler}
          submitButtonText="Export"
          type={ExternalConnectionType.EXPORT}
        />
      );
    case DataTargetType.GoogleCloudStorage:
      return (
        <GoogleCloudStorageForm
          defaultValues={{ datasetName }}
          onCancel={cancelFormHandler}
          onSubmit={submitFormHandler}
          submitButtonText="Export"
          type={ExternalConnectionType.EXPORT}
        />
      );
    case DataTargetType.Meta:
      return (
        <MetaForm onCancel={cancelFormHandler} onSubmit={submitFormHandler} />
      );
    case DataTargetType.GoogleDv_360:
      return (
        <GoogleDV360Form
          onCancel={cancelFormHandler}
          onSubmit={submitFormHandler}
        />
      );
    case DataTargetType.GoogleAdManager:
      return (
        <GoogleAdManagerForm
          onCancel={cancelFormHandler}
          onSubmit={submitFormHandler}
        />
      );
    case DataTargetType.Azure:
      return (
        <AzureForm
          defaultValues={{ datasetName }}
          onCancel={cancelFormHandler}
          onSubmit={submitFormHandler}
          submitButtonText="Export"
          type={ExternalConnectionType.EXPORT}
        />
      );
    case DataTargetType.Permutive:
      return (
        <PermutiveForm
          defaultValues={{ datasetName }}
          onCancel={cancelFormHandler}
          onSubmit={submitFormHandler}
        />
      );
    case DataTargetType.Adform:
      return (
        <AdForm
          defaultValues={{ datasetName }}
          onCancel={cancelFormHandler}
          onSubmit={submitFormHandler}
        />
      );
    case DataTargetType.MicrosoftDsp:
      return (
        <MicrosoftForm
          onCancel={cancelFormHandler}
          onSubmit={submitFormHandler}
        />
      );
    case DataTargetType.Splicky:
      return (
        <SplickyForm
          onCancel={cancelFormHandler}
          onSubmit={submitFormHandler}
        />
      );
    case DataTargetType.TradeDesk:
      return <TradeDeskForm />;
    case DataTargetType.Sportradar:
      return <SportradarForm />;
  }
};

ExportDatasetTargetForm.displayName = "ExportDatasetTargetForm";

export default memo(ExportDatasetTargetForm);
