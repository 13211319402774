import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { Fragment, memo } from "react";
import { OrganizationsAddDialog } from "components";

const OrganizationsAddButton: React.FC = () => {
  const [
    isOrganizationsAddDialogOpen,
    {
      setTrue: openOrganizationsAddDialog,
      setFalse: closeOrganizationsAddDialog,
    },
  ] = useBoolean(false);
  return (
    <Fragment>
      <Button
        color="primary"
        onClick={openOrganizationsAddDialog}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faPlusRegular} />}
        variant="contained"
      >
        New organization
      </Button>
      <Portal>
        <OrganizationsAddDialog
          onCancel={closeOrganizationsAddDialog}
          open={isOrganizationsAddDialogOpen}
        />
      </Portal>
    </Fragment>
  );
};

OrganizationsAddButton.displayName = "OrganizationsAddButton";

export default memo(OrganizationsAddButton);
