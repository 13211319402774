import { createContext, type SetStateAction, useContext } from "react";
import { type QueryAvailableAudiencesHookResult } from "features/MediaInsightsDcr/hooks/useQueryAvailableAudiences";
import {
  type Audience,
  type FilteredLookalikeAudienceQuality,
  type LookalikeAudience,
} from "features/MediaInsightsDcr/models";

export const AdvertiserAudienceGeneratorContext = createContext<{
  isAdvertiserAudienceQualityDrawerOpen: boolean;
  openAdvertiserAudienceQualityDrawer: () => void;
  closeAdvertiserAudienceQualityDrawer: () => void;
  reach: number;
  setReach: (reach: SetStateAction<number>) => void;
  openExcludeSeedAudienceInfoDialog: () => void;
  closeExcludeSeedAudienceInfoDialog: () => void;
  excludeSeedAudience: boolean;
  generateLookalikeAudienceLoading: boolean;
  setExcludeSeedAudience: (
    excludeSeedAudience: SetStateAction<boolean>
  ) => void;
  isExcludeSeedAudienceInfoDialogVisible: boolean;
  availableAudiences: QueryAvailableAudiencesHookResult;
  selectedAudienceType: string | undefined;
  setSelectedAudienceType: (
    audienceType: SetStateAction<string | undefined>
  ) => void;
  selectedLookalikeAudience: LookalikeAudience | undefined;
  estimatedAudienceSize: number;
  activatedLookalikeAudiences: Audience[];
  generateLookalikeAudience: () => void;
  selectedAudienceQuality: FilteredLookalikeAudienceQuality | undefined;
}>({
  activatedLookalikeAudiences: [],
  availableAudiences: {
    error: undefined,
    loading: false,
    retry: async () => {},
    status: "COMPUTING",
  },
  closeAdvertiserAudienceQualityDrawer: () => {},
  closeExcludeSeedAudienceInfoDialog: () => {},
  estimatedAudienceSize: 0,
  excludeSeedAudience: false,
  generateLookalikeAudience: () => {},
  generateLookalikeAudienceLoading: false,
  isAdvertiserAudienceQualityDrawerOpen: false,
  isExcludeSeedAudienceInfoDialogVisible: false,
  openAdvertiserAudienceQualityDrawer: () => {},
  openExcludeSeedAudienceInfoDialog: () => {},
  reach: 1,
  selectedAudienceQuality: undefined,
  selectedAudienceType: undefined,
  selectedLookalikeAudience: undefined,
  setExcludeSeedAudience: () => {},
  setReach: () => {},
  setSelectedAudienceType: () => {},
});

export const AdvertiserAudienceGeneratorProvider =
  AdvertiserAudienceGeneratorContext.Provider;

export const useAdvertiserAudienceGenerator = () =>
  useContext(AdvertiserAudienceGeneratorContext);
