import { InfoTooltip } from "@decentriq/components";
import { useDraftDataRoomUpdateEnableImmutabilityMutation } from "@decentriq/graphql/dist/hooks";
import { memo, useCallback } from "react";
import { Checkbox } from "components";
import { usePermissionsVars } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import useDataRoomEnableImmutability from "./useDataRoomEnableImmutability";

interface EnableImmutabilityCheckboxProps {
  idForDataRoom: string;
}

const EnableImmutabilityCheckbox: React.FC<EnableImmutabilityCheckboxProps> =
  memo(({ idForDataRoom }) => {
    const { canEditPermissions } = usePermissionsVars();
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [draftDataRoomUpdateEnableImmutability, { loading: isMutating }] =
      useDraftDataRoomUpdateEnableImmutabilityMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "DCR immutability settings could not be updated."
            )
          );
        },
      });
    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        draftDataRoomUpdateEnableImmutability({
          variables: {
            id: idForDataRoom,
            mutable: !checked,
          },
        });
      },
      [draftDataRoomUpdateEnableImmutability, idForDataRoom]
    );
    const checked = useDataRoomEnableImmutability();
    const disabled = isMutating || !canEditPermissions;
    return (
      <Checkbox
        checked={checked}
        disabled={disabled}
        label={
          <div>
            This data clean room is immutable
            <InfoTooltip
              tooltip={
                <>
                  Once it is published, participants will not be able to submit
                  any request for modifications in the data clean room.
                </>
              }
            />
          </div>
        }
        onChange={onChange}
        size="small"
        sx={{ margin: 0, width: "fit-content" }}
      />
    );
  });

EnableImmutabilityCheckbox.displayName = "EnableImmutabilityCheckbox";

export default EnableImmutabilityCheckbox;
