import { testIds } from "@decentriq/utils";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import {
  ConfigurationFormDomainField,
  ConfigurationFormFeaturesField,
  ConfigurationFormMatchingIdField,
  ConfigurationFormNameField,
  ConfigurationFormParticipantsField,
} from "features/mediaPortalShared";
import {
  dataPartnerDcrCollaborationTypes,
  type DataPartnerUsageConfigurationFormValues,
} from "./model";

const DataPartnerUsageConfigurationForm: React.FC = () => {
  const { organizationUsers } = useDataPartnerPortal();
  return (
    <Grid columnSpacing={3} container={true} rowSpacing={2}>
      <Grid container={true} item={true} xs={6}>
        <ConfigurationFormNameField<DataPartnerUsageConfigurationFormValues>
          fieldName="name"
          placeholder="Enter configuration name"
          testId={testIds.dataPartnerPortal.createUsageConfigurationDialog.name}
          title="Name"
          tooltip="User defined name for the configuration. Not publicly visible, only within your organization."
        />
      </Grid>
      <Grid container={true} item={true} xs={6}>
        <ConfigurationFormParticipantsField<DataPartnerUsageConfigurationFormValues>
          fieldName="participants"
          optionTestId={
            testIds.dataPartnerPortal.createUsageConfigurationDialog
              .participantsSelectorOptionHelper
          }
          organizationUsers={organizationUsers}
          testId={
            testIds.dataPartnerPortal.createUsageConfigurationDialog
              .participantsSelector
          }
          title="Participants"
          tooltip="These are the users from your organization that will be invited to newly created clean rooms using this configuration. These users will have permission to upload your data into the DCR."
        />
      </Grid>
      <Grid container={true} item={true} xs={6}>
        <ConfigurationFormFeaturesField<DataPartnerUsageConfigurationFormValues>
          featuresList={dataPartnerDcrCollaborationTypes}
          fieldName="dataRoomTypes"
          optionTestId={
            testIds.dataPartnerPortal.createUsageConfigurationDialog
              .featuresSelectorOptionHelper
          }
          testId={
            testIds.dataPartnerPortal.createUsageConfigurationDialog
              .featuresSelector
          }
          tooltip="These are the DCR collaboration types that are available under this configuration. Other collaboration types will be greyed out in the data partner discovery menu and unavailable in the clean room creation dialog."
        />
        <ConfigurationFormMatchingIdField<DataPartnerUsageConfigurationFormValues>
          fieldName="matchingId"
          optionTestId={
            testIds.dataPartnerPortal.createUsageConfigurationDialog
              .matchingIdSelectorOptionHelper
          }
          testId={
            testIds.dataPartnerPortal.createUsageConfigurationDialog
              .matchingIdSelector
          }
          tooltip="The type of matching ID. The data you will upload into DCRs created by advertisers under this configuration must include this type of identifier."
        />
      </Grid>
      <Grid alignItems="start" container={true} item={true} xs={6}>
        <ConfigurationFormDomainField<DataPartnerUsageConfigurationFormValues>
          checkboxFieldName="matchAnyDomain"
          checkboxTestId={
            testIds.dataPartnerPortal.createUsageConfigurationDialog
              .matchAnyDomain
          }
          textFieldName="advertiserDomains"
          textFieldTestId={
            testIds.dataPartnerPortal.createUsageConfigurationDialog
              .advertiserDomain
          }
        />
      </Grid>
    </Grid>
  );
};

DataPartnerUsageConfigurationForm.displayName =
  "DataPartnerUsageConfigurationForm";

export default memo(DataPartnerUsageConfigurationForm);
