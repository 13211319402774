const useFileToBase64 = () => {
  const fileToBase64 = async (file: File): Promise<string | undefined> => {
    if ("File" in window && file instanceof File) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () =>
          resolve((reader?.result as String)?.split(",")[1]);
        reader.readAsDataURL(file);
      });
    }
  };

  return (file: File) => fileToBase64(file);
};

export default useFileToBase64;
