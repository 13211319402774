import { InfoTooltip } from "@decentriq/components";
import { Box, Divider, Typography } from "@mui/material";
import { memo } from "react";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import CreateDataPartnerDisplayConfigurationButton from "../CreateDataPartnerDisplayConfigurationButton/CreateDataPartnerDisplayConfigurationButton";
import CreateDataPartnerUsageConfigurationButton from "../CreateDataPartnerUsageConfigurationButton/CreateDataPartnerUsageConfigurationButton";
import DataPartnerDisplayConfiguration from "../DataPartnerDisplayConfiguration/DataPartnerDisplayConfiguration";
import UsageConfigurationsTable from "../UsageConfigurationsTable/UsageConfigurationsTable";

const DataPartnerConfigurations: React.FC = () => {
  const { displayConfiguration } = useDataPartnerPortal();
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 2,
          flexDirection: "column",
          marginBottom: 4,
          minHeight: "300px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <Typography variant="subtitle1">
            <strong>Display configuration</strong>
            <InfoTooltip tooltip="Setting up the display configuration lets you be discovered by advertisers in the Decentriq platform. They can contact you in their platform view via email." />
          </Typography>
          {displayConfiguration === null && (
            <CreateDataPartnerDisplayConfigurationButton />
          )}
        </Box>
        <DataPartnerDisplayConfiguration />
      </Box>
      <Divider />
      <Box
        sx={{
          flex: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ padding: 2 }} variant="subtitle1">
            <strong>Data usage configurations</strong>
            <InfoTooltip tooltip="Setting up a usage configuration lets you define the details of a data clean room for specific advertisers. This allows these advertiser to create preconfigured data clean rooms with you." />
          </Typography>
          <Box sx={{ marginLeft: "auto", padding: 2, width: "fit-content" }}>
            <CreateDataPartnerUsageConfigurationButton />
          </Box>
        </Box>
        <UsageConfigurationsTable />
      </Box>
    </Box>
  );
};

DataPartnerConfigurations.displayName = "DataPartnerConfigurations";

export default memo(DataPartnerConfigurations);
