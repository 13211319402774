import { faFileImport as faFileImportRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { memo } from "react";
import ImportDataDialog from "../ImportDataDialog/ImportDataDialog";

const ImportDataButton: React.FC = () => (
  <ImportDataDialog
    renderTrigger={(open) => (
      <Button
        color="primary"
        onClick={open}
        startIcon={
          <FontAwesomeIcon fixedWidth={true} icon={faFileImportRegular} />
        }
        variant="contained"
      >
        Import dataset
      </Button>
    )}
  />
);

ImportDataButton.displayName = "ImportDataButton";

export default memo(ImportDataButton);
