import { type Session } from "@decentriq/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useApiCore } from "contexts";
import useAdvertiserDataReport from "features/mediaDataRoomV2/components/AdvertiserDataReportLabel/useAdvertiserDataReport";
import useLookalikeMediaOverlapInsightsData, {
  type LookalikeMediaOverlapInsightsHookResult,
} from "features/mediaDataRoomV2/components/LookalikeMediaInsights/hooks/useLookalikeMediaOverlapInsightsData/useLookalikeMediaOverlapInsightsData";
import usePublishedLookalikeMediaDataRoomPublishedDatasets from "features/mediaDataRoomV2/contexts/MediaInsightsDcrContext/queries/usePublishedLookalikeMediaDataRoomPublishedDatasets";
import { useLookalikeMediaDataRoomAudienceSizes } from "features/mediaDataRoomV2/hooks";
import { type UseLookalikeMediaDataRoomAudienceSizesReturnValue } from "features/mediaDataRoomV2/hooks/useLookalikeMediaDataRoomAudienceSizes/useLookalikeMediaDataRoomAudienceSizes";
import { type QueryMediaInsightsComputeJobHookResult } from "features/mediaDataRoomV2/hooks/useQueryMediaInsightsComputeJob/useQueryMediaInsightsComputeJob";
import { type PublisherDatasetsHashes } from "features/mediaDataRoomV2/models";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  getOverlapInsightsCacheKey,
  type LookalikeAdvertiserDataReportCacheKey,
  type OverlapInsightsCacheKey,
} from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";
import type { PublishedLookalikeMediaDataRoomQueryHookResult } from "../usePublishedMediaDataRoomQuery/usePublishedMediaDataRoomQuery";

interface UseLookalikeMediaDataRoomDataQueryPayload {
  publishedMediaDataRoom: PublishedLookalikeMediaDataRoomQueryHookResult;
}

export interface UseLookalikeMediaDataRoomDataQueryResult {
  advertiserDatasetHash: string | null;
  audienceSizes: UseLookalikeMediaDataRoomAudienceSizesReturnValue;
  publisherDatasetsHashes: PublisherDatasetsHashes;
  datasetsLoading: boolean;
  error?: string;
  hasRequiredData: boolean;
  hasAdvertiserData: boolean;
  hasPublisherData: boolean;
  isPublisherAudienceBeingUpdated: boolean;
  retrieveDatasets: () => Promise<void>;
  insights: LookalikeMediaOverlapInsightsHookResult;
  advertiserDataReport: QueryMediaInsightsComputeJobHookResult<{
    numberOfDeduplicatedRows: number;
    numberOfIngestedRows: number;
  }>;
  session?: Session | null;
}

const useLookalikeMediaDataRoomDataQuery = ({
  publishedMediaDataRoom,
}: UseLookalikeMediaDataRoomDataQueryPayload) => {
  const {
    dataRoomId,
    driverAttestationHash,
    isDeactivated,
    isAdvertiser,
    wasDataLabPublishedBefore,
  } = publishedMediaDataRoom;
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { sessionManager } = useApiCore();
  const queryClient = useQueryClient();
  const publishedDatasetsQuery =
    usePublishedLookalikeMediaDataRoomPublishedDatasets({
      dataRoomId,
      driverAttestationHash,
      queryKeyPrefix: ["lal-dcr"],
    });

  const error = useMemo(
    () => publishedDatasetsQuery?.error?.message,
    [publishedDatasetsQuery.error]
  );

  useEffect(() => {
    const error = publishedDatasetsQuery?.error;
    if (error) {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(error, "Unable to fetch datasets")
      );
    }
  }, [enqueueSnackbar, publishedDatasetsQuery?.error]);

  const { data: session } = useQuery({
    enabled: driverAttestationHash != null,
    queryFn: async () => {
      return await sessionManager.get({ driverAttestationHash });
    },
    queryKey: ["lal-dcr", "session", driverAttestationHash],
  });

  const { data: key } = useQuery({
    queryFn: async () => {
      const key = await getOverlapInsightsCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );
      return key;
    },
    queryKey: ["lal-dcr", "key", dataRoomId, driverAttestationHash],
  });

  const createCacheKeyString = useCallback(
    async (key: OverlapInsightsCacheKey): Promise<string> => {
      const publishedDatasets =
        (await session?.retrievePublishedDatasets(key.dataRoomId))
          ?.publishedDatasets || [];
      return computeCacheKeyString({
        ...key,
        publishedDatasets,
      });
    },
    [session]
  );

  const insights = useLookalikeMediaOverlapInsightsData({
    createCacheKeyString,
    key,
    session,
    skip: isDeactivated || !publishedDatasetsQuery.data?.hasRequiredData, // TODO @matyasfodor - only enable if the user is on the insights / activations tab
  });

  const audienceSizes = useLookalikeMediaDataRoomAudienceSizes({
    createCacheKeyString,
    key,
    session,
    skip:
      isDeactivated ||
      !isAdvertiser ||
      !publishedDatasetsQuery.data?.hasRequiredData, // TODO @matyasfodor only enable if the user is on the activations tab
  });

  const advertiserDataReportKey = useMemo(():
    | LookalikeAdvertiserDataReportCacheKey
    | undefined => {
    const advertiserDatasetHash =
      publishedDatasetsQuery.data?.advertiserDatasetHash;
    if (advertiserDatasetHash) {
      return { advertiserDatasetHash, dataRoomId };
    }
    return undefined;
  }, [dataRoomId, publishedDatasetsQuery.data?.advertiserDatasetHash]);

  const advertiserDataReport = useAdvertiserDataReport({
    key: advertiserDataReportKey,
    session,
    skip: isDeactivated || !isAdvertiser,
  });

  // TODO imperative refetching shouldn't happen in the first place
  // Facilitate this with cache invalidation in the future
  const handleDatasetsRetrieval = useCallback(async () => {
    await publishedDatasetsQuery.refetch();
    await queryClient.invalidateQueries({ queryKey: ["lal-dcr"] });
  }, [publishedDatasetsQuery, queryClient]);

  const contextValue = useMemo(
    (): UseLookalikeMediaDataRoomDataQueryResult => ({
      ...publishedDatasetsQuery.data,
      advertiserDataReport,
      audienceSizes,
      datasetsLoading: publishedDatasetsQuery.isLoading,
      error,
      insights,
      isPublisherAudienceBeingUpdated:
        wasDataLabPublishedBefore &&
        !publishedDatasetsQuery.data?.hasPublisherData &&
        !!publishedDatasetsQuery.data?.hasAdvertiserData,
      retrieveDatasets: handleDatasetsRetrieval,
      session,
    }),
    [
      publishedDatasetsQuery.data,
      publishedDatasetsQuery.isLoading,
      advertiserDataReport,
      audienceSizes,
      error,
      insights,
      session,
      wasDataLabPublishedBefore,
      handleDatasetsRetrieval,
    ]
  );

  return contextValue;
};

export default useLookalikeMediaDataRoomDataQuery;
