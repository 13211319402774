import {
  type ApolloClient,
  type InMemoryCache,
  type NormalizedCacheObject,
} from "@apollo/client";
import {
  CompleteSubmittedDataRoomRequestDocument,
  type CompleteSubmittedDataRoomRequestQuery,
  EnclaveConnectionParametersForSubmittedDataRoomRequestDocument,
  type SubmittedDataRoomRequest,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextInterface } from "contexts";
import { translateDataScienceCommit } from "utils/apicore";
import { maybeUseDataRoomSecret } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeSubmittedDataRoomRequestResolvers = (
  client: ApiCoreContextInterface["client"],
  sessionManager: ApiCoreContextInterface["sessionManager"],
  store: ApiCoreContextInterface["store"]
) => ({
  node: async (
    parent: Partial<SubmittedDataRoomRequest>,
    _args: null,
    context: LocalResolverContext,
    _info: any
  ) => {
    const submittedDataRoomRequest = await fetchDataScienceCommit(
      parent,
      context.cache,
      context.client,
      sessionManager
    );
    return submittedDataRoomRequest.node;
  },
});

export async function fetchDataScienceCommit(
  parent: Partial<SubmittedDataRoomRequest>,
  apolloCache: InMemoryCache,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  sessionManager: ApiCoreContextInterface["sessionManager"]
): Promise<CompleteSubmittedDataRoomRequestQuery["submittedDataRoomRequest"]> {
  const { id: requestId } = parent;
  if (requestId === undefined) {
    throw new Error(`Request id is missing`);
  }
  // Check if we already have the data science data room in the cache
  const cached = apolloCache.readQuery<CompleteSubmittedDataRoomRequestQuery>({
    query: CompleteSubmittedDataRoomRequestDocument,
    variables: { id: requestId },
  });
  if (cached) {
    return cached.submittedDataRoomRequest;
  }
  // Get the data science data room from the enclave
  const { driverAttestationHash, dataRoomId } =
    await getEnclaveConnectionParams(requestId, apolloClient);
  const sdkSession = await sessionManager.get({
    driverAttestationHash,
  });
  await maybeUseDataRoomSecret(sdkSession, apolloCache, dataRoomId);
  const dataScienceDcr =
    await sdkSession.retrieveDataScienceDataRoom(dataRoomId);
  const dataScienceCommit = await sdkSession.retrieveDataScienceCommit(
    parent.enclaveCommitId!,
    dataScienceDcr!
  );
  // Translate the data science data room to the PublishedDataRoom graphql shape
  const submittedDataRoomRequest = translateDataScienceCommit(
    sdkSession.compiler,
    requestId,
    dataScienceDcr!,
    dataScienceCommit,
    driverAttestationHash,
    dataRoomId,
    parent.enclaveCommitId!
  );
  submittedDataRoomRequest.node.dataRoomId = dataRoomId;
  // Write the data science data room to the cache
  apolloCache.writeQuery({
    data: { submittedDataRoomRequest },
    query: CompleteSubmittedDataRoomRequestDocument,
    variables: { id: requestId },
  });
  return submittedDataRoomRequest;
}

// Get necessary connection parameters to get the data science data room
// from the enclave, either from parent or by querying the API
async function getEnclaveConnectionParams(
  requestId: string,
  apolloClient: ApolloClient<NormalizedCacheObject>
) {
  const connectionParams = await apolloClient.query({
    query: EnclaveConnectionParametersForSubmittedDataRoomRequestDocument,
    variables: {
      id: requestId,
    },
  });
  const driverAttestationHash =
    connectionParams.data.submittedDataRoomRequest.publishedDataRoom
      .driverAttestationHash;
  const dataRoomId =
    connectionParams.data.submittedDataRoomRequest.publishedDataRoom.id;
  return { dataRoomId, driverAttestationHash };
}
