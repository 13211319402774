import { Box } from "@mui/material";
import { memo } from "react";
import { TagCloud } from "react-tagcloud";

const TagsCloud: React.FC = () => {
  return (
    <Box sx={{ height: "calc(100vh - 600px)", width: "80%" }}>
      <TagCloud
        disableRandomColor={true}
        maxSize={35}
        minSize={12}
        shuffle={true}
        tags={[]}
      />
    </Box>
  );
};

TagsCloud.displayName = "TagsCloud";

export default memo(TagsCloud);
