import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";
import { Typography } from "@mui/material";
import { memo } from "react";

type GoogleAdManagerConnectionConfigurationProps = {
  connectionId: string;
};

const GoogleAdManagerConnectionConfiguration: React.FC<
  GoogleAdManagerConnectionConfigurationProps
> = ({ connectionId }) => {
  const {
    data: { dataConnectorJob: { config: datasetExportConfig = {} } = {} } = {},
  } = useDataConnectorJobQuery({
    variables: { id: connectionId },
  });
  const { identifierKind, listId, bucket, objectName } =
    datasetExportConfig || {};

  return (
    <div>
      <Typography variant="body2">
        <strong>Identifier kind:</strong> {identifierKind}
      </Typography>
      <Typography variant="body2">
        <strong>Segment:</strong> {listId}
      </Typography>
      <Typography variant="body2">
        <strong>Bucket:</strong> {bucket}
      </Typography>
      <Typography variant="body2">
        <strong>Object name:</strong> {objectName}
      </Typography>
    </div>
  );
};

GoogleAdManagerConnectionConfiguration.displayName =
  "GoogleAdManagerConnectionConfiguration";

export default memo(GoogleAdManagerConnectionConfiguration);
