import { DataLabs } from "features/dataLabs";
import { Main } from "layouts";

const DataLabsPage: React.FC<{}> = () => {
  return (
    <Main>
      <DataLabs />
    </Main>
  );
};

export default DataLabsPage;
