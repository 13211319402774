import { useAuth0 } from "@auth0/auth0-react";
import { useAvailablePublishersQuery } from "@decentriq/graphql/dist/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { memo, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import AttributionSelector from "./AttributionSelector";
import DataRoomName from "./DataRoomName";
import MatchingId from "./MatchingId";
import {
  defaultMeasurementConfigurationFormValues,
  type MeasurementConfigurationFormValues,
  MeasurementConfigurationValidationSchema,
} from "./model";
import RolesAndParticipants from "./RolesAndParticipants";

interface MediaDcrCreateConfigFormProps {
  onSubmit: (payload: MeasurementConfigurationFormValues) => void;
  restartCreation: () => void;
}

const MediaDcrCreateConfigForm: React.FC<MediaDcrCreateConfigFormProps> = ({
  onSubmit,
  restartCreation,
}) => {
  const { user } = useAuth0();
  const currentUserEmail = user?.email!;
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DASHBOARD,
  });
  const configForm = useForm({
    context: { currentUserEmail },
    defaultValues: defaultMeasurementConfigurationFormValues,
    mode: "onSubmit",
    resolver: yupResolver(MeasurementConfigurationValidationSchema),
  });
  useEffect(() => {
    const error = Object.values(configForm.formState.errors)?.[0];
    if (!error?.message) return;
    enqueueSnackbar(error?.message, {
      variant: "error",
    });
  }, [enqueueSnackbar, configForm.formState.errors]);
  const handleSubmit = async (): Promise<void> => {
    const isValid = await configForm.trigger();
    if (isValid) {
      onSubmit(configForm.getValues());
    }
  };
  const { data: availablePublishersQueryData } = useAvailablePublishersQuery();
  const publishers = useMemo(
    () =>
      availablePublishersQueryData?.availablePublishers.nodes.filter(
        (publisher) => publisher.allowMeasurements
      ),
    [availablePublishersQueryData]
  );
  return (
    <>
      <DialogTitle>Create new data clean room</DialogTitle>
      <FormProvider {...configForm}>
        <DialogContent>
          <DataRoomName />
          <RolesAndParticipants availablePublishers={publishers ?? []} />
          <AttributionSelector />
          <MatchingId />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "auto",
          }}
        >
          <Button color="inherit" onClick={restartCreation} variant="text">
            Back
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Publish
          </Button>
        </DialogActions>
      </FormProvider>
    </>
  );
};

export default memo(MediaDcrCreateConfigForm);
