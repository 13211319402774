import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import React, { memo, useCallback } from "react";
import { useAdvertiserAudiences } from "features/MediaInsightsDcr";

type ActivateAudienceButtonProps = {
  audienceType: string;
  activationType: string;
  reach: number;
  published: boolean;
};

const ActivateAudienceButton: React.FC<ActivateAudienceButtonProps> = ({
  audienceType,
  activationType,
  reach,
  published,
}) => {
  const { publishAudience } = useAdvertiserAudiences();

  const publish = useCallback(
    async () => publishAudience({ activationType, audienceType, reach }),
    [audienceType, publishAudience, reach, activationType]
  );

  return (
    <LoadingButton
      color="primary"
      disabled={published}
      loadingPosition="start"
      onClick={publish}
      startIcon={<FontAwesomeIcon icon={faArrowRight} />}
      variant="text"
    >
      Grant publisher access
    </LoadingButton>
  );
};

ActivateAudienceButton.displayName = "ActivateAudienceButton";

export default memo(ActivateAudienceButton);
