import { useAuth0 } from "@auth0/auth0-react";
import { testIds } from "@decentriq/utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { memo } from "react";
import { Link } from "react-router-dom";
import { DataRoomParticipants } from "containers";
import { useDataRoom } from "contexts";
import {
  ComputeNodeConstructorMode,
  ComputeNodes,
  DataNodeConstructorMode,
  DataNodes,
} from "features";
import { DraftDataRoomTab } from "models";
import { useTabListStyles, useTabPanelStyles, useTabStyles } from "styles";
import { ComputeNodesVarsWrapper, PermissionsVarsWrapper } from "wrappers";

interface DraftDataRoomTabsProps {
  activeTab: string;
  baseUrl: string;
  ownerEmail: string | undefined;
}

const DraftDataRoomTabs: React.FC<DraftDataRoomTabsProps> = ({
  activeTab,
  baseUrl,
  ownerEmail,
}) => {
  const { user = {} } = useAuth0();
  const { email: currentUserEmail } = user || {};
  const { allowTestMode, dataRoomId } = useDataRoom();
  const { classes: tabListClasses } = useTabListStyles();
  const { classes: tabPanelClasses } = useTabPanelStyles();
  const { classes: tabClasses } = useTabStyles();
  return (
    <TabContext value={activeTab}>
      <Box sx={{ backgroundColor: "background.bar" }}>
        <TabList
          classes={tabListClasses}
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            classes={tabClasses}
            component={Link}
            data-testid={testIds.containers.draftDataRoom.dataTab}
            label="DATA"
            to={`${baseUrl}/${DraftDataRoomTab.data}`}
            value={DraftDataRoomTab.data}
          />
          <Tab
            classes={tabClasses}
            component={Link}
            data-testid={testIds.containers.draftDataRoom.computationsTab}
            label="COMPUTATIONS"
            to={`${baseUrl}/${DraftDataRoomTab.computations}`}
            value={DraftDataRoomTab.computations}
          />
          <Tab
            classes={tabClasses}
            component={Link}
            data-testid={testIds.containers.draftDataRoom.permissionsTab}
            label="PERMISSIONS"
            to={`${baseUrl}/${DraftDataRoomTab.permissions}`}
            value={DraftDataRoomTab.permissions}
          />
          <Tab
            classes={tabClasses}
            component={Link}
            data-testid={testIds.containers.draftDataRoom.overviewTab}
            label="OVERVIEW"
            to={`${baseUrl}/${DraftDataRoomTab.overview}`}
            value={DraftDataRoomTab.overview}
          />
        </TabList>
      </Box>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <TabPanel classes={tabPanelClasses} value={DraftDataRoomTab.data}>
          <DataNodes
            currentUserEmail={currentUserEmail!}
            dataRoomId={dataRoomId}
            mode={
              allowTestMode
                ? DataNodeConstructorMode.EDIT_WITH_TESTING
                : DataNodeConstructorMode.EDIT
            }
            ownerEmail={ownerEmail!}
          />
        </TabPanel>
        <TabPanel
          classes={tabPanelClasses}
          value={DraftDataRoomTab.computations}
        >
          <ComputeNodesVarsWrapper
            hasComputeNodeActions={true}
            hasComputeNodeParticipants={true}
            mode={ComputeNodeConstructorMode.EDIT}
          >
            <ComputeNodes />
          </ComputeNodesVarsWrapper>
        </TabPanel>
        <TabPanel
          classes={tabPanelClasses}
          value={DraftDataRoomTab.permissions}
        >
          <PermissionsVarsWrapper canEditPermissions={true}>
            <DataRoomParticipants />
          </PermissionsVarsWrapper>
        </TabPanel>
        <TabPanel classes={tabPanelClasses} value={DraftDataRoomTab.overview}>
          <Typography gutterBottom={true} variant="h6">
            Data
          </Typography>
          <DataNodes
            currentUserEmail={currentUserEmail!}
            dataRoomId={dataRoomId}
            mode={DataNodeConstructorMode.READONLY}
            ownerEmail={ownerEmail!}
          />
          <ComputeNodesVarsWrapper
            hasComputeNodeActions={false}
            hasComputeNodeParticipants={true}
            mode={ComputeNodeConstructorMode.READONLY}
          >
            <Typography gutterBottom={true} variant="h6">
              Computations
            </Typography>
            <ComputeNodes relaxed={true} />
          </ComputeNodesVarsWrapper>
          <Typography gutterBottom={true} variant="h6">
            Permissions
          </Typography>
          <PermissionsVarsWrapper canEditPermissions={false}>
            <DataRoomParticipants />
          </PermissionsVarsWrapper>
        </TabPanel>
      </div>
    </TabContext>
  );
};

DraftDataRoomTabs.displayName = "DraftDataRoomTabs";

export default memo(DraftDataRoomTabs);
