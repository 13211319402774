import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { DataRoomAuditLogs } from "containers";
import { usePublishedLookalikeMediaDataRoom } from "contexts";
import {
  AdvertiserAudiencesContextWrapper,
  MediaDataRoomActivation,
  MediaDataRoomData,
  MediaDataRoomInsights,
  OverlapInsightsExecutionContext,
} from "features/mediaDataRoomV2";
import LookalikeMediaInsightsWrapper from "features/mediaDataRoomV2/components/LookalikeMediaInsights/wrappers/LookalikeMediaInsightsWrapper/LookalikeMediaInsightsWrapper";
import { useDataRoomTabsNavigationState } from "hooks";
import { useTabListStyles, useTabPanelStyles, useTabStyles } from "styles";

interface MediaDataRoomTabsProps {
  dataRoomId: string;
  driverAttestationHash: string;
}

enum MediaDataRoomTab {
  data = "data",
  insights = "insights",
  activation = "activation",
  auditLog = "auditLog",
}

const tabs = [
  MediaDataRoomTab.data,
  MediaDataRoomTab.insights,
  MediaDataRoomTab.activation,
  MediaDataRoomTab.auditLog,
];
const defaultTab = MediaDataRoomTab.data;

const MediaDataRoomTabs: React.FC<MediaDataRoomTabsProps> = ({
  dataRoomId,
  driverAttestationHash,
}) => {
  const { isObserver } = usePublishedLookalikeMediaDataRoom();
  const { classes: tabListClasses } = useTabListStyles();
  const { classes: tabPanelClasses } = useTabPanelStyles();
  const { classes: tabClasses } = useTabStyles();
  const { activeTab, setActiveTab } = useDataRoomTabsNavigationState({
    dataRoomId,
    defaultTab,
    tabs,
  });
  useEffect(() => {
    if (isObserver && activeTab === MediaDataRoomTab.activation) {
      setActiveTab(defaultTab);
    }
  }, [activeTab, isObserver, setActiveTab]);
  const handleTabChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: MediaDataRoomTab) =>
      setActiveTab(newValue),
    [setActiveTab]
  );
  return (
    <TabContext key={dataRoomId} value={activeTab}>
      <Box sx={{ backgroundColor: "background.bar" }}>
        <TabList
          classes={tabListClasses}
          onChange={handleTabChange}
          style={{ marginTop: 0 }}
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            classes={tabClasses}
            label="INSIGHTS"
            value={MediaDataRoomTab.insights}
          />
          {!isObserver && (
            <Tab
              classes={tabClasses}
              label="ACTIVATION"
              value={MediaDataRoomTab.activation}
            />
          )}
          <Tab
            classes={tabClasses}
            label="DATA"
            value={MediaDataRoomTab.data}
          />
          <Tab
            classes={tabClasses}
            label="AUDIT LOG"
            value={MediaDataRoomTab.auditLog}
          />
        </TabList>
      </Box>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <TabPanel classes={tabPanelClasses} value={MediaDataRoomTab.insights}>
          <Box
            flex={1}
            minHeight="100%"
            sx={{ background: "#fff", overflow: "hidden", p: 2 }}
          >
            <LookalikeMediaInsightsWrapper
              executionContext={OverlapInsightsExecutionContext.insightsTab}
            >
              <MediaDataRoomInsights />
            </LookalikeMediaInsightsWrapper>
          </Box>
        </TabPanel>
        {!isObserver && (
          <TabPanel
            classes={tabPanelClasses}
            value={MediaDataRoomTab.activation}
          >
            <Box flex={1} flexShrink={0} minHeight="100%" p={2}>
              <AdvertiserAudiencesContextWrapper>
                <MediaDataRoomActivation dataRoomId={dataRoomId} />
              </AdvertiserAudiencesContextWrapper>
            </Box>
          </TabPanel>
        )}
        <TabPanel classes={tabPanelClasses} value={MediaDataRoomTab.data}>
          <Box
            flexShrink={0}
            minHeight="100%"
            sx={{ background: "#fff", p: 2 }}
          >
            <MediaDataRoomData
              dataRoomId={dataRoomId}
              driverAttestationHash={driverAttestationHash!}
            />
          </Box>
        </TabPanel>
        <TabPanel classes={tabPanelClasses} value={MediaDataRoomTab.auditLog}>
          <DataRoomAuditLogs
            dcrHash={dataRoomId!}
            driverAttestationHash={driverAttestationHash!}
          />
        </TabPanel>
      </div>
    </TabContext>
  );
};

MediaDataRoomTabs.displayName = "MediaDataRoomTabs";

export default memo(MediaDataRoomTabs);
