import { testIds } from "@decentriq/utils";
import { Box, CircularProgress, styled, type Theme } from "@mui/material";
import { memo } from "react";
import { EmptyData } from "components";
import { DeleteDisplayConfigurationButton } from "features/mediaPortalShared";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import { CommonSnackbarOrigin } from "hooks";
import PublisherDisplayConfigurationPresentation from "../PublisherDisplayConfigurationPresentation/PublisherDisplayConfigurationPresentation";
import UpdatePublisherDisplayConfigurationButton from "../UpdatePublisherDisplayConfigurationButton/UpdatePublisherDisplayConfigurationButton";

const StyledCenteringBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
}));

const PublisherDisplayConfiguration: React.FC = () => {
  const {
    publisherDisplayConfiguration,
    isPublisherDisplayConfigurationLoading,
    deletePublisherDisplayConfiguration,
  } = usePublisherPortal();
  if (isPublisherDisplayConfigurationLoading) {
    return (
      <StyledCenteringBox>
        <CircularProgress color="inherit" size="1.5rem" thickness={1} />
      </StyledCenteringBox>
    );
  }
  if (publisherDisplayConfiguration === null) {
    return (
      <EmptyData secondaryText="No publisher configuration found. Please create one." />
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <PublisherDisplayConfigurationPresentation />
      <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 2 }}>
        <DeleteDisplayConfigurationButton
          confirmationTestId={
            testIds.publisherPortal.displayConfiguration
              .deleteDialogConfirmationButton
          }
          deleteConfiguration={deletePublisherDisplayConfiguration}
          dialogDescription="This will also delete all DCR configurations. This cannot be undone."
          dialogTitle="Are you sure you want to delete this display configuration?"
          displayConfiguration={publisherDisplayConfiguration}
          errorMessage="Cannot delete display configuration"
          snackbarOrigin={CommonSnackbarOrigin.PUBLISHER_PORTAL}
          testId={testIds.publisherPortal.displayConfiguration.deleteButton}
        />
        <UpdatePublisherDisplayConfigurationButton />
      </Box>
    </Box>
  );
};

export default memo(PublisherDisplayConfiguration);
