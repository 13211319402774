// @ts-nocheck
import { faCheckSquare, faSquare } from "@fortawesome/pro-light-svg-icons";
import {
  faCheckSquare as faCheckSquareSolid,
  faMinusSquare as faMinusSquareSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, styled } from "@mui/material";
import { memo, useMemo } from "react";

const FontAwesomeCheckbox = memo(({ className, outlined = false, ...rest }) => {
  const icon = useMemo(
    () => <FontAwesomeIcon fixedWidth={true} icon={faSquare} />,
    []
  );
  const checkedIcon = useMemo(
    () => (
      <FontAwesomeIcon
        fixedWidth={true}
        icon={outlined ? faCheckSquare : faCheckSquareSolid}
      />
    ),
    [outlined]
  );
  const indeterminateIcon = useMemo(
    () => <FontAwesomeIcon fixedWidth={true} icon={faMinusSquareSolid} />,
    []
  );
  const TouchRippleProps = { center: false };
  return (
    <Checkbox
      TouchRippleProps={TouchRippleProps}
      checkedIcon={checkedIcon}
      className={className}
      color="default"
      icon={icon}
      indeterminateIcon={indeterminateIcon}
      {...rest}
    />
  );
});

const StyledFontAwesomeCheckbox = styled(FontAwesomeCheckbox)(({ theme }) => ({
  "&.Mui-disabled": {
    color: theme.palette.action.disabled,
    pointerEvents: "none",
  },
  borderRadius: theme.shape.borderRadius,
  color: "inherit",
  padding: theme.spacing(0.5),
}));

export default StyledFontAwesomeCheckbox;
