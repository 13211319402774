import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DataLabDetails, useDataLabContext } from "features/dataLabs";

interface DataLabDetailsDialogProps {
  id: string;
  open: boolean;
  onCancel?: () => void;
}

const DataLabDetailsDialog: React.FC<DataLabDetailsDialogProps> = ({
  id,
  open,
  onCancel,
}) => {
  const {
    dataLab: { data },
  } = useDataLabContext();
  const name = data?.name;
  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={onCancel} open={open}>
      <DialogTitle>{name}</DialogTitle>
      <DialogContent>
        <Box sx={{ margin: (theme) => theme.spacing(-4, -1, 0) }}>
          <DataLabDetails id={id} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DataLabDetailsDialog.displayName = "DataLabDetailsDialog";

export default DataLabDetailsDialog;
