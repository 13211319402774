import { Button, styled } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo, useCallback } from "react";
import { type DataRoomErrorReportContextValue } from "contexts";
import DataRoomErrorReportDialog from "./DataRoomErrorReportDialog";

const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-text": { color: "white" },
}));

interface DataRoomErrorReportButtonProps
  extends DataRoomErrorReportContextValue {
  dataRoomId: string;
  error: string;
  details: string;
  closeSnackbar: () => void;
}

const DataRoomErrorReportButton: React.FC<DataRoomErrorReportButtonProps> = ({
  dataRoomId,
  error,
  details,
  closeSnackbar,
  ...contextValue
}) => {
  const [isDialogOpen, { setFalse: closeDialog, setTrue: openDialog }] =
    useBoolean(false);
  const handleFinish = useCallback(() => {
    closeSnackbar();
    closeDialog();
  }, [closeDialog, closeSnackbar]);
  return (
    <>
      <StyledButton onClick={openDialog} variant="text">
        Report issue to Decentriq
      </StyledButton>
      <DataRoomErrorReportDialog
        dataRoomId={dataRoomId}
        details={details}
        error={error}
        onCancel={closeDialog}
        onFinish={handleFinish}
        open={isDialogOpen}
        {...contextValue}
      />
    </>
  );
};

DataRoomErrorReportButton.displayName = "DataRoomErrorReportButton";

export default memo(DataRoomErrorReportButton);
