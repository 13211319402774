import { AccessTokens } from "containers";
import { Main } from "layouts";

const AccessTokensPage = () => (
  <Main>
    <AccessTokens />
  </Main>
);

export default AccessTokensPage;
