import { testIds } from "@decentriq/utils";
import { faFileContract as faFileContractRegular } from "@fortawesome/pro-regular-svg-icons";
import { faCaretDown as faCaretDownSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { usePopupState } from "hooks";

interface EncryptAndPublishButtonProps {
  disabled: boolean;
  loading: boolean;
  onClick: (validate: boolean) => void;
}

const EncryptAndPublishButton: React.FC<EncryptAndPublishButtonProps> = ({
  onClick,
  disabled,
  loading,
}) => {
  const options = useMemo<{ label: string }[]>(
    () => [
      {
        label: "Publish",
      },
      {
        label: "Publish without validation",
      },
    ],
    []
  );
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleClick = () => onClick(selectedIndex === 0);
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    close();
    setSelectedIndex(index);
  };
  return (
    <div style={{ display: "flex" }}>
      <ButtonGroup
        aria-label="split button"
        disableElevation={true}
        ref={anchorRef}
      >
        <Tooltip
          disableFocusListener={true}
          disableTouchListener={true}
          placement="bottom-end"
          title={
            <div>
              <div>Publish</div>
              <div>
                Publishing the data clean room means that it is going to be
                encrypted and locked. You won't be able to edit tables,
                computations, or participants after the data clean room is
                published.
              </div>
            </div>
          }
        >
          <Button
            color="primary"
            data-testid={testIds.draftDataRoom.encryptAndPublish.publishButton}
            disabled={disabled || loading}
            onClick={handleClick}
            startIcon={<FontAwesomeIcon icon={faFileContractRegular} />}
            variant="contained"
          >
            {options[selectedIndex].label}
          </Button>
        </Tooltip>
        <Button
          aria-controls={isOpen ? "split-button-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="menu"
          color="primary"
          onClick={open}
          size="small"
          sx={{ "&.MuiButtonGroup-grouped": { minWidth: "auto" } }}
          variant="contained"
        >
          <FontAwesomeIcon fixedWidth={true} icon={faCaretDownSolid} />
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        marginThreshold={8}
        onClose={close}
        open={isOpen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuList autoFocusItem={true} id="split-button-menu">
          {options.map(({ label }, index) => {
            if (index === selectedIndex) {
              return null;
            }
            return (
              <MenuItem
                key={label}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </div>
  );
};

export default EncryptAndPublishButton;
