import { DqTable, InfoTooltip } from "@decentriq/components";
import {
  type FullActivationConfigurationFragment,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { type MRT_ColumnDef } from "material-react-table";
import React, { memo, useMemo } from "react";
import { EmptyData } from "components";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import { CollaborationTypes } from "models";
import DeleteActivationConfigurationButton from "../DeleteActivationConfigurationButton/DeleteActivationConfigurationButton";

const ActivationConfigurationsTable: React.FC = () => {
  const { activationConfigurations, publisherDisplayConfiguration } =
    usePublisherPortal();
  const hasDisplayConfiguration = Boolean(publisherDisplayConfiguration);
  const columns: MRT_ColumnDef<FullActivationConfigurationFragment>[] =
    useMemo(() => {
      return [
        {
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="User defined name for the config. Not publicly visible, only within your organization." />
            </div>
          ),
          accessorKey: "name",
          header: "Configuration name",
          id: "name",
          maxSize: 150,
        },
        {
          Cell: ({ cell, row }) => {
            const advertiserDomains = cell.getValue<string[]>() || [];
            const { matchAnyDomain, id } = row?.original || {};
            return matchAnyDomain
              ? "Match any domain"
              : advertiserDomains.map((domain, index) => (
                  <React.Fragment key={`${id}-match-${index}`}>
                    {index > 0 && <br />}
                    <span>{domain}</span>
                  </React.Fragment>
                ));
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="Advertisers registered to these domains will be able to create clean rooms using this config. If there is both a domain and global config, the domain config has precedence." />
            </div>
          ),
          accessorKey: "advertiserDomains",
          header: "Advertiser",
          id: "advertiserDomains",
          maxSize: 150,
        },
        {
          Cell: ({ row }) => {
            const {
              allowInsights,
              allowLookalike,
              allowRetargeting,
              allowMeasurements,
              allowExclusionTargeting,
            } = row?.original || {};

            const allowedDcrFeatures = [
              allowInsights ? CollaborationTypes.Insights : undefined,
              allowRetargeting ? CollaborationTypes.Retargeting : undefined,
              allowLookalike ? CollaborationTypes.Lookalike : undefined,
              allowExclusionTargeting
                ? CollaborationTypes.ExclusionTargeting
                : undefined,
              allowMeasurements ? CollaborationTypes.Measurements : undefined,
            ].filter(Boolean);
            return allowedDcrFeatures.join(", ");
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="These are the DCR features that are available in this config. Other features will be greyed out in the publisher discovery menu, and unavailble in the clean room creation dialog." />
            </div>
          ),
          accessorKey: "allowInsights",
          header: "Collaboration types",
          id: "dcrFeatures",
        },
        {
          Cell: ({ row }) => {
            const { matchingIdFormat, matchingIdHashingAlgorithm } =
              row?.original || {};
            return matchingIdTypeAndHashingAlgorithmPresentation({
              matchingIdFormat,
              matchingIdHashingAlgorithm:
                matchingIdHashingAlgorithm as TableColumnHashingAlgorithm,
            });
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="The type of matching key. The advertiser will be able to match against this key, and you must provision datalabs using this key to any clean rooms they create with this config. Types with a specific format (e.g. email, hashed email) have some format validation." />
            </div>
          ),
          accessorKey: "matchingIdFormat",
          header: "Matching ID",
          id: "matchingId",
        },
        {
          Cell: ({ cell, row }) => {
            const publisherParticipants = cell.getValue<string[]>() || [];
            const { id } = row?.original || {};
            return (
              <div>
                {publisherParticipants.map((participant, index) => (
                  <React.Fragment key={`${id}-participants-${index}`}>
                    {index > 0 && <br />}
                    <span>{participant}</span>
                  </React.Fragment>
                ))}
              </div>
            );
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="These are the users from your organization that will be in clean rooms using this config. These accounts have permission upload data, download data, and view insights." />
            </div>
          ),
          accessorKey: "publisherParticipants",
          header: "Accounts",
          id: "publisherParticipants",
        },
      ];
    }, []);
  if (activationConfigurations.length === 0) {
    return (
      <EmptyData
        secondaryText={`No DCR configurations found. ${hasDisplayConfiguration ? "Please create one." : "Please create first a display configuration and then DCR configuration."}`}
      />
    );
  }
  return (
    <DqTable
      columns={columns}
      data={activationConfigurations}
      enableRowActions={true}
      localization={{ actions: "" }}
      muiTableBodyRowProps={({ row }) => ({
        "data-testid":
          testIds.publisherPortal.activationConfigurations.recordHelper +
          row.original.name.toLowerCase(),
      })}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
          width: "100%",
        },
      }}
      muiTopToolbarProps={{
        sx: {
          flex: "0 0 auto",
          minHeight: "auto",
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          borderBottom: "1px solid",
          borderColor: "divider",
        },
      }}
      renderRowActions={({
        row: {
          original: { id, name },
        },
      }) => <DeleteActivationConfigurationButton id={id} name={name} />}
    />
  );
};

export default memo(ActivationConfigurationsTable);
