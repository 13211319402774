import { GettingStarted } from "components";
import { Main } from "layouts";

const GettingStartedPage = () => {
  return (
    <Main>
      <GettingStarted />
    </Main>
  );
};

export default GettingStartedPage;
