import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
} from "features/datasets/components/ExternalConnections";

const microsoftFormValidationSchema = yup.object().shape({
  memberId: yup
    .string()
    .matches(/^[0-9]+$/, "Invalid Member ID")
    .required("Member ID is required"),
  segmentCode: yup
    .string()
    .matches(
      /^[a-zA-Z0-9_-]*$/,
      "Only alphanumeric characters, dashes and underscores are allowed in segment code"
    )
    .required("Segment code is required"),
  segmentShortName: yup.string().required("Segment short name is required"),
});

type MicrosoftFormData = yup.InferType<typeof microsoftFormValidationSchema>;

const MicrosoftForm: React.FC<ExportDatasetFormProps> = ({
  onSubmit,
  onCancel,
  submitButtonText = "Export",
}) => {
  const microsoftForm = useForm({
    defaultValues: {
      memberId: "",
      segmentCode: "",
      segmentShortName: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(microsoftFormValidationSchema),
  });
  const { control, handleSubmit, reset } = microsoftForm;

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: MicrosoftFormData) => {
      const {
        memberId = "",
        segmentCode = "",
        segmentShortName = "",
      } = formValues;

      onSubmit({
        input: {
          microsoftDsp: {
            memberId,
            segmentCode,
            segmentShortName,
          },
        },
      });
      reset();
    },
    [reset, onSubmit]
  );

  return (
    <>
      <FormProvider {...microsoftForm}>
        <Stack spacing={1}>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="memberId"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.memberId;
              return (
                <FormControl
                  error={!!fieldError}
                  fullWidth={true}
                  sx={{ mb: 1, mt: 1 }}
                >
                  <InputLabel>Member ID</InputLabel>
                  <OutlinedInput
                    label="Member ID"
                    placeholder="Example: 5821"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="segmentCode"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.segmentCode;
              return (
                <FormControl error={!!fieldError} fullWidth={true}>
                  <InputLabel>Segment code</InputLabel>
                  <OutlinedInput
                    label="Segment code"
                    placeholder="Example: SEGMENT_CODE-123"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="segmentShortName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.segmentShortName;
              return (
                <FormControl error={!!fieldError} fullWidth={true}>
                  <InputLabel>Segment short name</InputLabel>
                  <OutlinedInput
                    label="Segment short name"
                    placeholder="Example: Segment short name"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </Stack>
      </FormProvider>
      <ExternalConnectionActionsWrapper>
        <Button
          color="inherit"
          onClick={handlePreviousStepClick}
          variant="text"
        >
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          {submitButtonText}
        </Button>
      </ExternalConnectionActionsWrapper>
    </>
  );
};

MicrosoftForm.displayName = "MicrosoftForm";

export default memo(MicrosoftForm);
