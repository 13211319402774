import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { memo, type SetStateAction, useCallback } from "react";
import { type ActivationType, type Audience } from "features/MediaInsightsDcr";
import useDownloadAudience from "./useDownloadAudience";

type ActivationDownloadButtonProps = {
  activationType: ActivationType;
  activatedAudiences: Audience[];
  audienceType: string;
  reach: number;
  closeExportButtonPopover: () => void;
  setExportAudienceLoading: (isLoading: SetStateAction<boolean>) => void;
};

const ActivationDownloadButton: React.FC<ActivationDownloadButtonProps> = ({
  activationType,
  activatedAudiences,
  audienceType,
  reach,
  closeExportButtonPopover,
  setExportAudienceLoading,
}) => {
  const { loading, downloadAudience } = useDownloadAudience({
    activatedAudiences,
    activationType,
    audienceType,
    reach,
  });
  const handleDownloadAudience = useCallback(async () => {
    setExportAudienceLoading(true);
    closeExportButtonPopover();
    await downloadAudience();
    setExportAudienceLoading(false);
  }, [closeExportButtonPopover, downloadAudience, setExportAudienceLoading]);
  return (
    <LoadingButton
      color="inherit"
      loading={loading}
      loadingPosition="start"
      onClick={handleDownloadAudience}
      startIcon={<FontAwesomeIcon icon={faDownload} />}
      sx={({ spacing }) => ({
        "& .MuiButton-startIcon": {
          marginRight: spacing(1.5),
        },
        fontSize: "16px",
        fontWeight: 400,
        justifyContent: "flex-start",
        padding: spacing(1, 2),
      })}
      variant="text"
    >
      Download file
    </LoadingButton>
  );
};

ActivationDownloadButton.displayName = "ActivationDownloadButton";

export default memo(ActivationDownloadButton);
