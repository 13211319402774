import { DqTable } from "@decentriq/components";
import { Box, Typography } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { type Audience } from "features/mediaDataRoomV2/models";

interface AudiencesTableProps {
  audiences: Audience[];
  rowActions: (row: Audience) => React.ReactNode;
  noRecordsToDisplay: string;
  getAudienceStatusLabel: (row: Audience) => string;
}

const AudiencesTable: React.FC<AudiencesTableProps> = ({
  audiences,
  getAudienceStatusLabel,
  rowActions,
  noRecordsToDisplay,
}) => {
  const columns = useMemo<MRT_ColumnDef<Audience>[]>(
    () => [
      {
        Cell: ({ cell, row }) => {
          return (
            <Typography sx={{ my: "auto" }}>{`${cell.getValue()} - ${
              row?.original?.reach
            }%`}</Typography>
          );
        },
        accessorKey: "audienceType",
        header: "Audience",
        minSize: 150,
      },
      {
        Cell: ({ row }) => {
          return (
            <Typography sx={{ my: "auto" }}>
              {getAudienceStatusLabel(row?.original)}
            </Typography>
          );
        },
        header: "Status",
        id: "status",
        minSize: 200,
      },
    ],
    [getAudienceStatusLabel]
  );

  return (
    <DqTable
      columns={columns}
      data={audiences}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 350,
        },
      }}
      enableRowActions={true}
      getRowId={(row) => `${row.audienceType}-${row.reach}`}
      localization={{
        actions: "",
        noRecordsToDisplay,
      }}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
          width: "100%",
        },
      }}
      renderRowActions={({ row }) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {rowActions(row?.original)}
          </Box>
        );
      }}
    />
  );
};

AudiencesTable.displayName = "AudiencesTable";

export default AudiencesTable;
