import { useAccessTokensQuery } from "@decentriq/graphql/dist/hooks";
import { Box, List } from "@mui/material";
import { memo } from "react";
import {
  AccessTokenListItem,
  AccessTokensConstructorForm,
  AccessTokensTile,
} from "components";

const AccessTokensConstructor: React.FC = () => {
  const { data: tokensData } = useAccessTokensQuery();
  const tokens = (tokensData?.myself?.apiTokens?.nodes || []).filter(
    ({ revoked }) => !revoked
  );

  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      <AccessTokensConstructorForm />
      {tokens.length > 0 && (
        <List>
          {tokens.map(({ id, revoked }) => (
            <AccessTokenListItem key={id} revoked={revoked}>
              <AccessTokensTile revoked={revoked} tokenId={id} />
            </AccessTokenListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

AccessTokensConstructor.displayName = "AccessTokensConstructor";

export default memo(AccessTokensConstructor);
