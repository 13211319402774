import { InlineEditor } from "@decentriq/components";
import { TableColumnHashingAlgorithm } from "@decentriq/graphql/dist/types";
import {
  Box,
  Checkbox,
  ListItem,
  MenuItem,
  Select,
  styled,
  Tooltip,
} from "@mui/material";
import { memo } from "react";
import { useDataRoom } from "contexts";
import {
  castPrimitiveTypeToFormatType,
  type DataRoomTableColumn,
} from "models";
import {
  dataRoomTableFormatTypeOptions,
  dataRoomTablePrimitiveTypeOptions,
} from "./DataNodeConstructorModels";

interface TableNodeColumnTileProps {
  column: DataRoomTableColumn;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:nth-of-type(even)": {
    background: "whitesmoke",
  },
}));

const TableNodeColumnTile: React.FC<TableNodeColumnTileProps> = ({
  column,
}) => {
  const style = {
    display: "flex",
  };
  const { isPublished } = useDataRoom();
  const { primitiveType, formatType, hashWith } = column;
  return (
    <StyledListItem style={style} sx={{ p: 0.5 }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          gap: 0.5,
          width: 0,
        }}
      >
        <InlineEditor
          fullWidth={true}
          readOnly={true}
          style={{ flex: 1 }}
          value={column.name}
        />
        <Box
          sx={{
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            mb: -0.5,
            mt: -0.5,
          }}
        >
          <Tooltip
            disableFocusListener={true}
            disableTouchListener={true}
            enterDelay={500}
            placement="top"
            title="" // Data type
          >
            <Select
              disableUnderline={true}
              disabled={true}
              size="small"
              sx={(theme) => ({
                "& .MuiSelect-select": {
                  backgroundColor: "transparent",
                  paddingBottom: `${theme.spacing(0.875)} !important`,
                  paddingLeft: `${theme.spacing(3)} !important`,
                  paddingRight: `${theme.spacing(3)} !important`,
                },
                backgroundColor: "transparent",
                borderBottom: "solid #CCC 1px",
                minWidth: 124,
                textAlign: "center",
                width: 240,
              })}
              value={
                !isPublished
                  ? formatType || castPrimitiveTypeToFormatType(primitiveType)
                  : formatType || primitiveType
              }
              variant="standard"
            >
              {!isPublished || (isPublished && formatType)
                ? dataRoomTableFormatTypeOptions?.map(
                    ({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    )
                  )
                : dataRoomTablePrimitiveTypeOptions?.map(
                    ({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    )
                  )}
            </Select>
          </Tooltip>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              minWidth: 184,
              width: 184,
            }}
          >
            <Checkbox
              checked={hashWith === TableColumnHashingAlgorithm.Sha256Hex}
              disabled={true}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
              sx={{
                color: "#498E8F",
                flex: 1,
                p: 0,
              }}
            />
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              minWidth: 180,
              width: 180,
            }}
          >
            <Checkbox
              checked={column.nullable}
              disabled={true}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
              sx={{
                color: "#498E8F",
                flex: 1,
                p: 0,
              }}
            />
          </div>
        </Box>
      </Box>
    </StyledListItem>
  );
};

export default memo(TableNodeColumnTile);
