import { testIds } from "@decentriq/utils";
import { faCubes } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

const DataRoomsListSidebarMenuItem: React.FC = () => {
  return (
    <SidebarMenuItem
      data-testid={testIds.sidebar.sidebarMenuItems.dcrList}
      icon={faCubes}
      label="DCRs"
      to="/datarooms"
    />
  );
};

DataRoomsListSidebarMenuItem.displayName = "DataRoomsListSidebarMenuItem";

export default memo(DataRoomsListSidebarMenuItem);
