import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { KeychainItemKind } from "services/keychain/types";
import { useKeychainService } from "contexts";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

type DeleteKeychainItemDialogProps = {
  id: string;
  kind: KeychainItemKind;
  open: boolean;
  onCancel: () => void;
};

const DeleteKeychainItemDialog: React.FC<DeleteKeychainItemDialogProps> = ({
  id,
  kind,
  onCancel,
  open = false,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.KEYCHAIN,
  });
  const { keychain } = useKeychainService();
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteKeychainItem = useCallback(async () => {
    setIsDeleting(true);
    try {
      if (kind === KeychainItemKind.Dataset) {
        await keychain.removeItems([
          { id, kind: KeychainItemKind.Dataset },
          { id, kind: KeychainItemKind.DatasetMetadata },
        ]);
      } else {
        await keychain.removeItem({ id, kind });
      }
    } catch (error) {
      enqueueSnackbar(`Item could not be deleted from the Keychain.`, {
        context: error?.message || error,
        persist: true,
        variant: "error",
      });
    } finally {
      setIsDeleting(false);
    }
  }, [kind, keychain, id, enqueueSnackbar]);
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={isDeleting ? undefined : onCancel}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>
            Are you sure you want to delete this key from your Keychain?
          </strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          You will not be able anymore to reprovision the associated dataset to
          data clean rooms.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={isDeleting} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          loading={isDeleting}
          loadingPosition="start"
          onClick={deleteKeychainItem}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteKeychainItemDialog.displayName = "DeleteKeychainItemDialog";

export default memo(DeleteKeychainItemDialog);
