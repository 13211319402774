import {
  faArrowRight as faArrowRightRegular,
  faTrashCan as faTrashCanRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Portal, Typography } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Actions } from "components";
import { ActionsRawButton } from "components/base/Actions/Actions";
import {
  DataLabDeleteDialog,
  DataLabStatusLabel,
  useDataLabContext,
} from "features/dataLabs";

const DataLabNavigateButton = memo<{ onNavigate: () => void }>(
  ({ onNavigate }) => (
    <Button
      color="primary"
      onClick={onNavigate}
      startIcon={
        <FontAwesomeIcon fixedWidth={true} icon={faArrowRightRegular} />
      }
      sx={({ palette }) => ({ borderColor: palette.text.disabled })}
      variant="contained"
    >
      Go to lab
    </Button>
  )
);

interface DataLabDetailsToolbarProps {
  id: string;
}

const DataLabDetailsToolbar: React.FC<DataLabDetailsToolbarProps> = ({
  id,
}) => {
  const [
    isDeleteDialogOpen,
    { setTrue: openDeleteDialog, setFalse: closeDeleteDialog },
  ] = useBoolean(false);
  const {
    dataLab: { data: dataLab, loading },
  } = useDataLabContext();
  const navigate = useNavigate();
  const onNavigate = useCallback(
    () => navigate(`/datalabs/${dataLab?.id}`),
    [dataLab?.id, navigate]
  );
  const actions = useCallback(
    () => ({
      buttons: [
        {
          childComponent: DataLabNavigateButton,
          color: "primary",
          component: ActionsRawButton,
          name: "Go to Lab",
          onNavigate,
          type: "Go to Lab",
          variant: "contained",
        },
      ],
      menuLists: [
        [
          {
            icon: faTrashCanRegular,
            isRed: true,
            name: "Delete Lab",
            onClick: openDeleteDialog,
            type: "Delete",
          },
        ],
      ],
    }),
    [onNavigate, openDeleteDialog]
  );
  if (loading || !dataLab) return <></>;
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          gap: 1,
          overflow: "hidden",
        }}
      >
        <Typography fontWeight="fontWeightSemiBold" noWrap={true} variant="h6">
          {!dataLab?.name ? "Loading datalab name..." : dataLab.name}
        </Typography>
        <DataLabStatusLabel id={id} />
      </Box>
      <Actions actions={actions()} inline={false}>
        <Portal>
          <DataLabDeleteDialog
            id={id}
            onCancel={closeDeleteDialog}
            open={isDeleteDialogOpen}
          />
        </Portal>
      </Actions>
    </Box>
  );
};

DataLabDetailsToolbar.displayName = "DataLabDetailsToolbar";

export default memo(DataLabDetailsToolbar);
