import { InlineChipsEditor } from "@decentriq/components";
import {
  faCheck as faCheckLight,
  faXmark as faXmarkLight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@mui/material";
import { type FocusEvent } from "react";

interface UniquenessConstructorProps {
  columnNameById?: Map<string, string>;
  currentColumns: string[];
  isPublished?: boolean;
  loading?: boolean;
  onAddColumn?: (name: string) => void;
  onAddColumnAndAddUniqueColumnIds?: (name: string) => void;
  onAddUniqueColumnIds?: () => void;
  onDeleteColumn?: (name: string, indexToDelete: number) => void;
  onDeleteUniqueColumnIds?: (idsIndexToDelete: number) => void;
  readOnly: boolean;
  uniqueColumnIds?: string[][];
}

const UniquenessConstructor: React.FC<UniquenessConstructorProps> = ({
  columnNameById,
  currentColumns: value,
  isPublished,
  loading,
  onAddColumn,
  onAddColumnAndAddUniqueColumnIds,
  onAddUniqueColumnIds,
  onDeleteColumn,
  onDeleteUniqueColumnIds,
  readOnly,
  uniqueColumnIds,
}) => {
  return (
    <Box>
      {!readOnly ||
      (readOnly && uniqueColumnIds && uniqueColumnIds?.length > 0) ? (
        <Box>Column uniqueness constraints</Box>
      ) : null}
      {!readOnly ? (
        <Box sx={{ alignItems: "center", display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            <InlineChipsEditor
              alwaysShowPlaceholder={true}
              blurBehavior="add"
              disableUnderline={false}
              disabled={readOnly || loading}
              fullWidth={true}
              onAdd={onAddColumn}
              onBlur={(event: FocusEvent<HTMLInputElement>) => {
                onAddColumnAndAddUniqueColumnIds?.(event.target.value);
              }}
              onDelete={onDeleteColumn}
              onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
                e.preventDefault()
              }
              onTouchStart={(e: React.TouchEvent<HTMLButtonElement>) =>
                e.preventDefault()
              }
              placeholder="Add column"
              value={value.map(
                (uniqueColumnId) => columnNameById?.get(uniqueColumnId) || "?"
              )}
            />
          </Box>
          <IconButton
            disabled={value.length === 0}
            onClick={onAddUniqueColumnIds}
          >
            <FontAwesomeIcon fixedWidth={true} icon={faCheckLight} />
          </IconButton>
        </Box>
      ) : null}
      {uniqueColumnIds?.map((uniqueColumnIdsArray: string[], index: number) => (
        <Box key={index} sx={{ alignItems: "center", display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            <InlineChipsEditor
              alwaysShowPlaceholder={false}
              blurBehavior="add"
              disableUnderline={readOnly}
              disabled={readOnly || loading}
              fullWidth={true}
              placeholder="Add column"
              readOnly={true}
              value={uniqueColumnIdsArray.map(
                (uniqueColumnId) =>
                  columnNameById?.get(uniqueColumnId) ||
                  (isPublished ? uniqueColumnId : "?")
              )}
            />
          </Box>
          {!readOnly ? (
            <IconButton onClick={onDeleteUniqueColumnIds?.bind(null, index)}>
              <FontAwesomeIcon fixedWidth={true} icon={faXmarkLight} />
            </IconButton>
          ) : null}
        </Box>
      ))}
    </Box>
  );
};

UniquenessConstructor.displayName = "UniquenessConstructor";

export default UniquenessConstructor;
