import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
} from "features/datasets/components/ExternalConnections";

type MetaFormProps = ExportDatasetFormProps;

const metaFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    audienceName: yup.string().trim().required("Audience name is required"),
  }),
  credentials: yup.object({
    accessToken: yup.string().trim().required("Access token is required"),
    accountId: yup.string().trim().required("Ad Account ID is required"),
  }),
});

type MetaFormValues = yup.InferType<typeof metaFormValidationSchema>;

const MetaForm: React.FC<MetaFormProps> = ({
  onCancel: cancelFormHandler,
  onSubmit: submitFormHandler,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        audienceName: "",
      },
      credentials: {
        accessToken: "",
        accountId: "",
      },
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(metaFormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: MetaFormValues) => {
      const {
        configuration: { audienceName = "" } = {},
        credentials: { accessToken = "", accountId = "" } = {},
      } = formValues;

      submitFormHandler({
        input: {
          meta: {
            accessToken: accessToken.trim(),
            adAccountId: accountId.trim(),
            audienceName: audienceName.trim(),
          },
        },
      });
      reset();
    },
    [reset, submitFormHandler]
  );

  return (
    <Box>
      <form>
        <Box>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.audienceName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.audienceName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Audience name"
                  placeholder="Example: DCR Audience"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
        </Box>
        <Box mb={2} mt={2}>
          <ExternalConnectionCredentialsLabel />
          <Controller
            control={control}
            name="credentials.accountId"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.credentials?.accountId;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Account ID"
                  placeholder="Example: 397218503624195"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="credentials.accessToken"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.credentials?.accessToken;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  autoComplete="off"
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Access Token"
                  placeholder="Example: F57yT3QaX2nD8jHr4KlP9cVs1W6zU0E2sY8hN4bM..."
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
        </Box>
      </form>
      <ExternalConnectionActionsWrapper>
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Export
        </Button>
      </ExternalConnectionActionsWrapper>
    </Box>
  );
};

MetaForm.displayName = "MetaForm";

export default memo(MetaForm);
