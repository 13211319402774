import Grid from "@mui/material/Unstable_Grid2";
import { useMatchNodeDataSources } from "../../hooks";
import { QueryBuilder } from "./components";

interface RulesComposerProps {
  id: string;
  readOnly?: boolean;
}

const RulesComposer: React.FC<RulesComposerProps> = ({ id, readOnly }) => {
  const {
    availableDataSources,
    selectedLeftDataSourceNodeId: dataSource1NodeId,
    selectedRightDataSourceNodeId: dataSource2NodeId,
    config,
    updateConfig,
  } = useMatchNodeDataSources(id);
  const query = config?.query;
  const dataSource1Columns =
    availableDataSources.find(({ id }) => id === dataSource1NodeId)?.columns ||
    [];
  const dataSource2Columns =
    availableDataSources.find(({ id }) => id === dataSource2NodeId)?.columns ||
    [];
  return (
    <Grid container={true} spacing={2}>
      <Grid alignItems="flex-start" display="flex" sx={{ pt: 2 }} xs={1}>
        On
      </Grid>
      <Grid alignItems="flex-start" display="flex" xs={true}>
        <QueryBuilder
          dataSource1Columns={dataSource1Columns}
          dataSource1NodeId={dataSource1NodeId || ""}
          dataSource2Columns={dataSource2Columns}
          dataSource2NodeId={dataSource2NodeId || ""}
          onChange={(newQuery) => {
            if (JSON.stringify(newQuery) !== JSON.stringify(query)) {
              const newConfig = {
                ...config,
                epsilon: 1,
                query: newQuery,
                round: 100,
                sensitivity: 10,
              };
              updateConfig({
                optimisticResponse: {
                  draftMatchNode: {
                    __typename: "DraftMatchNodeMutations",
                    update: {
                      __typename: "DraftMatchNode",
                      config: newConfig,
                      id,
                    },
                  },
                },
                variables: {
                  config: newConfig,
                  id,
                },
              });
            }
          }}
          query={query}
          readOnly={readOnly}
        />
      </Grid>
    </Grid>
  );
};
RulesComposer.displayName = "RulesComposer";

export default RulesComposer;
