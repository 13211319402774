import { InfoTooltip } from "@decentriq/components";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { isEmpty } from "lodash";
import { memo } from "react";
import {
  Controller,
  type FieldError,
  type FieldValues,
  type Merge,
  type Path,
  useFormContext,
} from "react-hook-form";
import { matchingIdTypes } from "features/dataLabs/components/DataLabCreateDialog/DataLabCreateDialog";

interface ConfigurationFormMatchingIdFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  tooltip?: string;
  testId?: string;
  optionTestId?: string;
}

type ConfigurationFormMatchingIdFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormMatchingIdFieldProps<F>
) => React.ReactNode;

const ConfigurationFormMatchingIdField = memo(
  <F extends FieldValues>({
    fieldName,
    tooltip,
    testId,
    optionTestId,
  }: ConfigurationFormMatchingIdFieldProps<F>) => {
    const { control: formControl } = useFormContext<F>();
    return (
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.[fieldName] as
            | Merge<FieldError, (FieldError | undefined)[]>
            | undefined;
          const withError = !isEmpty(fieldError);
          return (
            <FormControl error={withError} fullWidth={true} sx={{ mb: 1 }}>
              <FormLabel
                component="legend"
                sx={{ fontSize: "12px", pl: "2px" }}
              >
                Matching ID
                {tooltip && <InfoTooltip tooltip={tooltip} />}
              </FormLabel>
              <Select
                displayEmpty={true}
                fullWidth={true}
                multiple={false}
                renderValue={(value) => {
                  const option = matchingIdTypes.find((o) => o.value === value);
                  if (!option) return "None";
                  return option.name;
                }}
                size="medium"
                sx={{
                  background: "transparent",
                }}
                variant="standard"
                {...field}
                data-testid={testId}
              >
                {matchingIdTypes.map(({ value, name: label }, index) => (
                  <MenuItem
                    key={index}
                    value={value}
                    {...(optionTestId
                      ? {
                          "data-testid": `${optionTestId}${value.toLowerCase()}`,
                        }
                      : {})}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ ml: 0 }}>
                {fieldError?.message}
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);

ConfigurationFormMatchingIdField.displayName =
  "ConfigurationFormMatchingIdField";

export default ConfigurationFormMatchingIdField as ConfigurationFormMatchingIdFieldComponent;
