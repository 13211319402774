import { useUserDetailsQuery } from "@decentriq/graphql/dist/hooks";
import { CircularProgress, Typography } from "@mui/material";
import { memo } from "react";
import { AdminDialog, UserDeleteButton } from "components";
import { useUserRole } from "hooks";
import UserDetailsJSONView from "./UserDetailsJSONView/UserDetailsJSONView";
import UserLogoEditor from "./UserLogoEditor/UserLogoEditor";
import UserOrganizationField from "./UserOrganizationField/UserOrganizationField";
import UserRoleField from "./UserRoleField/UserRoleField";

type UserDetailsDialogProps = {
  userId: string;
  open: boolean;
  onClose: () => void;
};

const UserDetailsDialog: React.FC<UserDetailsDialogProps> = ({
  userId,
  open,
  onClose,
}) => {
  const { data: { user } = {}, loading } = useUserDetailsQuery({
    variables: { userId },
  });
  const { isSuperAdmin, isSuperAdminReadOnly } = useUserRole();

  return (
    <AdminDialog
      confirmButtonText="Close"
      disabled={!userId}
      maxWidth={isSuperAdmin || isSuperAdminReadOnly ? "md" : "sm"}
      onClose={onClose}
      onConfirm={onClose}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          "& > .MuiDialogContent-root": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
          height: isSuperAdmin || isSuperAdminReadOnly ? "80%" : "fit-content",
        },
      }}
      title="User details"
      withCancelButton={false}
    >
      {isSuperAdmin && <UserLogoEditor userId={userId!} />}
      <Typography
        sx={{ alignItems: "center", display: "inline-flex", marginBottom: 1 }}
      >
        <strong>Email</strong>:{" "}
        {loading ? (
          <CircularProgress size={16} sx={{ marginLeft: "6px" }} />
        ) : (
          user?.email
        )}
      </Typography>
      <UserRoleField userId={userId!} />
      {(isSuperAdmin || isSuperAdminReadOnly) && (
        <UserOrganizationField
          closeUserDetailsDialog={onClose}
          userId={userId!}
        />
      )}
      {(isSuperAdmin || isSuperAdminReadOnly) && (
        <UserDetailsJSONView userId={userId!} />
      )}
      <UserDeleteButton closeUserDetailsDialog={onClose} userId={userId!} />
    </AdminDialog>
  );
};

UserDetailsDialog.displayName = "UserDetailsDialog";

export default memo(UserDetailsDialog);
