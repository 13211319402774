import {
  faChartLine,
  faRadar,
  faText,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, styled, Tab } from "@mui/material";
import { grey } from "@mui/material/colors";
import { memo, useState } from "react";
import {
  LineChart,
  RadarChart,
  SegmentsSelector,
  TagsCloud,
} from "./components";

const WITH_TAG_CLOUD = false;

const ChartTypeTabs = styled(TabList)(({ theme: { spacing, palette } }) => ({
  "& .MuiTab-root": {
    "&.Mui-selected": {
      background: "rgba(40, 144, 144, 0.08)",
      color: palette.primary.main,
    },
    "&:not(:last-of-type)": {
      marginRight: "-1px",
    },
    aspectRatio: "1",
    border: `1px solid ${grey[200]}`,
    minWidth: "auto",
    padding: spacing(1.5),
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  marginLeft: spacing(2),
}));

type OverlapInsightsChartTypes = "RADAR" | "LINE" | "TAG_CLOUD";
type AudienceOverlapInsightsChartsProps = {
  selectedAudienceType: string;
};

const AudienceOverlapInsightsCharts: React.FC<
  AudienceOverlapInsightsChartsProps
> = ({ selectedAudienceType }) => {
  const [selectedChartType, selectChartType] =
    useState<OverlapInsightsChartTypes>("RADAR");
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);

  const handleTabChange = (event: any, newValue: any) =>
    selectChartType(newValue);

  return (
    <Box sx={{ flex: 2 }}>
      <TabContext value={selectedChartType}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <SegmentsSelector
            selectedAudienceType={selectedAudienceType}
            selectedSegments={selectedSegments}
            setSelectedSegments={setSelectedSegments}
          />
          <ChartTypeTabs onChange={handleTabChange}>
            <Tab
              label={<FontAwesomeIcon icon={faRadar} size="lg" />}
              value="RADAR"
            />
            <Tab
              label={<FontAwesomeIcon icon={faChartLine} size="lg" />}
              value="LINE"
            />
            {WITH_TAG_CLOUD && (
              <Tab
                label={<FontAwesomeIcon icon={faText} size="lg" />}
                value="TAG_CLOUD"
              />
            )}
          </ChartTypeTabs>
        </Box>
        <Box>
          <TabPanel sx={{ padding: "16px 0" }} value="RADAR">
            <RadarChart
              selectedAudienceType={selectedAudienceType}
              selectedSegments={selectedSegments}
            />
          </TabPanel>
          <TabPanel sx={{ padding: "16px 0" }} value="LINE">
            <LineChart
              selectedAudienceType={selectedAudienceType}
              selectedSegments={selectedSegments}
            />
          </TabPanel>
          {WITH_TAG_CLOUD && (
            <TabPanel sx={{ padding: "16px 0" }} value="TAG_CLOUD">
              <TagsCloud
                selectedAudienceType={selectedAudienceType}
                selectedSegments={selectedSegments}
              />
            </TabPanel>
          )}
        </Box>
      </TabContext>
    </Box>
  );
};

AudienceOverlapInsightsCharts.displayName = "AudienceOverlapInsightsCharts";

export default memo(AudienceOverlapInsightsCharts);
