import { ApolloError } from "@apollo/client";
import { data_science } from "@decentriq/core";
import {
  CreateSubmittedDataRoomRequestDocument,
  type CreateSubmittedDataRoomRequestMutation,
  type CreateSubmittedDataRoomRequestMutationVariables,
  MergeSubmittedDataRoomRequestDocument,
  type MutationSubmitDataRoomRequestArgs,
  SignSubmittedDataRoomRequestDocument,
  type SignSubmittedDataRoomRequestMutation,
  type SignSubmittedDataRoomRequestMutationVariables,
  type SubmitDataRoomRequestPayload,
  SubmittedDataRoomRequestProcessingStatus,
} from "@decentriq/graphql/dist/types";
import { type Mutex } from "async-mutex";
import { type ApiCoreContextInterface } from "contexts";
import { maybeUseDataRoomSecret } from "wrappers/ApolloWrapper/helpers";
import { createDataScienceCommit } from "wrappers/ApolloWrapper/helpers/createDataScienceCommit";
import { getDataRoomRequestCommitData } from "wrappers/ApolloWrapper/helpers/getDataRoomRequestCommitData";
import { type LocalResolverContext } from "../../../models";
import { fetchDataScienceDataRoom } from "../../PublishedDataRoom";

export const makeSubmitDataRoomRequestResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"],
    mutexMap: Map<string, Mutex>
  ) =>
  async (
    _obj: any,
    args: MutationSubmitDataRoomRequestArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<SubmitDataRoomRequestPayload> => {
    const { id, dcrHash, driverAttestationHash, validate } = args.input;
    const { draftNode, analysts } = await getDataRoomRequestCommitData(
      id,
      context.client
    );
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
    try {
      const dataScienceDataRoom =
        await sdkSession.retrieveDataScienceDataRoom(dcrHash);
      const wrapper = data_science.createDataScienceDataRoomWrapper(
        dcrHash,
        dataScienceDataRoom!,
        sdkSession
      );
      if (validate) {
        const { commitId: validationCommitId } = await createDataScienceCommit(
          draftNode as any,
          analysts,
          true,
          client,
          wrapper
        );
        // Run dry run with empty datasets
        const job = await wrapper.createCommitJob(
          [draftNode.id],
          validationCommitId,
          {
            dryRun: {
              testDatasets: new Map(),
            },
          }
        );
        await wrapper.waitForJobCompletion(job);
      }
      const { commitId, pin } = await createDataScienceCommit(
        draftNode as any,
        analysts,
        false,
        client,
        wrapper
      );
      const signers = await wrapper.retrieveCommitApprovers(commitId);
      const creationResponse = await context.client.mutate<
        CreateSubmittedDataRoomRequestMutation,
        CreateSubmittedDataRoomRequestMutationVariables
      >({
        mutation: CreateSubmittedDataRoomRequestDocument,
        variables: {
          input: {
            dataRoomRequestId: args.input.id,
            enclaveCommitId: commitId,
            enclaveConfigurationPin: pin,
            signers: signers,
          },
        },
      });
      const requestId =
        creationResponse.data?.dataRoomRequest?.submitForApproval.id;
      const currentUserEmail = sdkSession.metaData.email;
      const isCurrentUserApprover = signers.includes(currentUserEmail);
      let signatures:
        | ({ userEmail: string; signature?: string } | undefined)[]
        | undefined;
      if (isCurrentUserApprover) {
        const signingResponse = await context.client.mutate<
          SignSubmittedDataRoomRequestMutation,
          SignSubmittedDataRoomRequestMutationVariables
        >({
          mutation: SignSubmittedDataRoomRequestDocument,
          variables: {
            input: {
              commitId,
              dcrHash,
              driverAttestationHash,
              id: requestId!,
            },
          },
        });
        signatures =
          signingResponse?.data?.signSubmittedDataRoomRequest?.signers;
      }
      const isMergeable =
        signers.length === 0 ||
        (signatures?.length && signatures.every((s) => Boolean(s?.signature)));
      if (!isMergeable) {
        await fetchDataScienceDataRoom(
          {
            driverAttestationHash,
            id: dcrHash,
          },
          context.cache,
          context.client,
          sessionManager,
          client,
          mutexMap,
          true
        );
        return {
          id: requestId!,
          status: signatures?.some(
            (s) => s?.userEmail === currentUserEmail && Boolean(s.signature)
          )
            ? SubmittedDataRoomRequestProcessingStatus.Approved
            : SubmittedDataRoomRequestProcessingStatus.Created,
        };
      }
      await context.client.mutate({
        mutation: MergeSubmittedDataRoomRequestDocument,
        variables: {
          input: {
            // commitId, // NOTE: was defined here but does not belong according to spec
            dcrHash,
            driverAttestationHash,
            id: requestId!,
          },
        },
      });
      return {
        id: requestId!,
        status: SubmittedDataRoomRequestProcessingStatus.Merged,
      };
    } catch (error: any) {
      throw new ApolloError({
        errorMessage: `'${error?.extraInfo?.nodeName || draftNode?.name}'. ${
          error.message
        }`,
      });
    }
  };
