import { useParams } from "react-router-dom";
import { DataRoomErrorReportProvider } from "contexts";
import { MediaDataRoom } from "features/mediaDataRoom";
import { Main } from "layouts";
import { DataRoomType } from "models";

const MediaDataRoomPage = () => {
  const { dataRoomId } = useParams();
  return (
    <Main>
      <DataRoomErrorReportProvider
        value={{ dataRoomId: dataRoomId!, dataRoomType: DataRoomType.Media }}
      >
        <MediaDataRoom id={dataRoomId!} />
      </DataRoomErrorReportProvider>
    </Main>
  );
};

export default MediaDataRoomPage;
