import { type ApolloError } from "@apollo/client";
import { useSetDataNodesOrderMutation } from "@decentriq/graphql/dist/hooks";
import { testIds } from "@decentriq/utils";
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  List,
  ListItem,
  styled,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSelections } from "ahooks";
import { useCallback, useMemo } from "react";
import { DragHandleButton } from "components";
import { DatasetConstraints } from "features/computeNode";
import { DataNodeConstructorMode } from "features/dataNodes/models";
import { type DataRoomData, type DataRoomDataTable } from "models";
import { type DataNodeChangeOutcomeType } from "../DataNodeWithTestDataChangedDialog/DataNodeWithTestDataChangedDialog";
import { Uniqueness } from "./components";
import DataNodeAddNewField from "./DataNodeAddNewField";
import DataNodeChangeOutcomeHandler, {
  useDataNodeChangeOutcomeHandler,
} from "./DataNodeChangeOutcomeHandler";
import { type DataRoomDataNodeActionsRenderer } from "./DataNodeConstructorModels";
import {
  DataNodeConstructorParamsConsumer,
  DataNodeConstructorParamsWrapper,
  useDataNodeConstructorParams,
} from "./DataNodeConstructorParamsWrapper";
import DataNodeEditableTile from "./DataNodeEditableTile";
import DataNodeTile from "./DataNodeTile";
import { TableNodeColumnConstructor } from "./TableNodeColumnConstructor";
import TableNodeColumnConstructorHeader from "./TableNodeColumnConstructorHeader";

export * from "./DataNodeConstructorModels";

export const UNIQUENESS_ENABLED = true;

interface SharedProps {
  renderDataActions?: DataRoomDataNodeActionsRenderer;
  toggle: (id: string) => void;
}

interface DataNodeItemProps extends SharedProps {
  dataNode: DataRoomData;
  isExpanded: boolean;
  otherComputeNodeNames: string[];
}

interface DataNodeListProps extends SharedProps {
  dataRoomId: string;
  dataNodes: DataRoomData[];
  isSelected: (id: string) => boolean;
  dataNodesOrder: string[];
}

function DataNodeItem({
  dataNode,
  isExpanded,
  renderDataActions,
  otherComputeNodeNames,
  toggle,
}: DataNodeItemProps) {
  const { id, isRequired } = dataNode;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const { handleChangeOutcome } = useDataNodeChangeOutcomeHandler();
  const style = {
    display: "flex",
    flex: 1,
    padding: 0,
    transform: CSS.Transform.toString(
      transform
        ? {
            ...transform,
            scaleY: 1,
          }
        : null
    ),
    transition: transition || undefined,
  };
  const { readOnly } = useDataNodeConstructorParams();
  return (
    <>
      <Box ref={setNodeRef} style={style} sx={{ display: "flex", mb: 1 }}>
        {!readOnly && <DragHandleButton {...attributes} {...listeners} />}
        <StyledListItem
          key={id}
          sx={{
            backgroundColor: "common.white",
            border: "1px solid",
            borderColor: "divider",
            p: 1,
          }}
        >
          <div style={{ flex: 1 }}>
            {readOnly ? (
              <DataNodeTile
                dataNode={dataNode}
                isCollapsed={!isExpanded}
                onToggleCollapsion={toggle}
                renderDataActions={renderDataActions}
              />
            ) : (
              <DataNodeEditableTile
                dataNode={dataNode}
                dataTestid={testIds.dataNode.dataNodeEditableTile.nameInput}
                hasError={false} // TODO: pass actual error
                helperTestid={
                  testIds.dataNode.dataNodeEditableTile.nameInputHelper
                }
                isCollapsed={!isExpanded}
                isLoading={false}
                onChangeOutcome={() =>
                  handleChangeOutcome("deleted", { dataNode })
                }
                onToggleCollapsion={toggle}
                otherDataNodeNames={otherComputeNodeNames}
                renderDataActions={renderDataActions}
              />
            )}
            <Collapse
              in={isExpanded}
              style={{
                marginLeft: 6,
                marginRight: 6,
                marginTop: 10,
              }}
              timeout="auto"
              unmountOnExit={true}
            >
              {dataNode.dataType === "table" && (
                <>
                  <TableNodeColumnConstructorHeader
                    isListEmpty={
                      !(dataNode as DataRoomDataTable).columns.length
                    }
                    readOnly={readOnly}
                  />
                  <TableNodeColumnConstructor
                    columns={(dataNode as DataRoomDataTable).columns}
                    columnsOrder={(dataNode as DataRoomDataTable).columnsOrder}
                    isLoading={false}
                    onChangeOutcome={(columnAdded, columnId) =>
                      handleChangeOutcome(
                        (columnAdded
                          ? "columnAdded"
                          : "changed") as DataNodeChangeOutcomeType,
                        { columnId, dataNode }
                      )
                    }
                    tableNodeId={id}
                  />
                </>
              )}
              <Typography mt={2}>Additional configuration</Typography>
              <Grid columnSpacing={1} container={true} rowSpacing={0} xs="auto">
                <Grid md={6} sm={12}>
                  <DatasetConstraints
                    dataType={dataNode.dataType}
                    disabled={false}
                    id={id}
                    readOnly={readOnly}
                    value={isRequired}
                  />
                </Grid>
                {dataNode.dataType === "table" &&
                UNIQUENESS_ENABLED &&
                !!(dataNode as DataRoomDataTable).columns.length ? (
                  <Grid md={6} sm={12}>
                    <Uniqueness id={id} readOnly={readOnly} />
                  </Grid>
                ) : null}
              </Grid>
              {dataNode.dataType === "unstructured" ? (
                <Typography variant="body1">
                  Unstructured data files of any kind can be provisioned (e.g.
                  JSON, ZIP, TXT)
                </Typography>
              ) : null}
            </Collapse>
          </div>
        </StyledListItem>
      </Box>
    </>
  );
}

function DataNodeList({
  dataRoomId,
  dataNodes,
  isSelected,
  renderDataActions,
  toggle,
  dataNodesOrder,
}: DataNodeListProps) {
  const dataNodeIds = useMemo(
    () =>
      dataNodes
        .slice()
        .sort(
          (a, b) => dataNodesOrder.indexOf(a.id) - dataNodesOrder.indexOf(b.id)
        )
        .map((dataNode) => dataNode.id),
    [dataNodes, dataNodesOrder]
  );
  const [setDataRoomDataNodesOrder] = useSetDataNodesOrderMutation();
  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        const oldIndex = dataNodeIds.indexOf(active!.id as string);
        const newIndex = dataNodeIds.indexOf(over!.id as string);
        const dataNodesOrder = arrayMove(dataNodeIds, oldIndex, newIndex);
        setDataRoomDataNodesOrder({
          optimisticResponse: {
            __typename: "Mutation",
            draftDataRoom: {
              __typename: "DraftDataRoomMutations",
              setDataNodesOrder: {
                __typename: "DraftDataRoom",
                dataNodesOrder,
                id: dataRoomId,
              },
            },
          },
          variables: {
            dataNodesOrder,
            dataRoomId,
          },
        });
      }
    },
    [dataNodeIds, dataRoomId, setDataRoomDataNodesOrder]
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToParentElement, restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      <SortableContext
        items={dataNodeIds}
        strategy={verticalListSortingStrategy}
      >
        <List disablePadding={true}>
          {dataNodeIds.map((id) => {
            const dataNode = dataNodes.find(
              ({ id: dataNodeId }) => dataNodeId === id
            );
            const otherDataNodes = dataNodes.filter(
              ({ id: dataNodeId }) => dataNodeId !== id
            );
            return dataNode ? (
              <DataNodeItem
                dataNode={dataNode}
                isExpanded={isSelected(dataNode.id)}
                key={dataNode.id}
                otherComputeNodeNames={otherDataNodes.map(({ name }) => name)}
                renderDataActions={renderDataActions}
                toggle={toggle}
              />
            ) : null;
          })}
        </List>
      </SortableContext>
    </DndContext>
  );
}

const emptyConstructorStatusMap = new Map<DataNodeConstructorMode, string>([
  [
    DataNodeConstructorMode.READONLY,
    "This data clean room doesn't have any data nodes declared.",
  ],
  [
    DataNodeConstructorMode.STATUS,
    "This data clean room doesn't have any data nodes declared.",
  ],
  [DataNodeConstructorMode.ACTION, "There is no data available to you."],
  [
    DataNodeConstructorMode.DEGRADE_ACTION,
    "You are not allowed to delete data.",
  ],
]);

interface DataNodeConstructorProps {
  dataRoomId: string;
  dataNodes: DataRoomData[];
  renderDataActions?: DataRoomDataNodeActionsRenderer;
  mode: DataNodeConstructorMode;
  dataNodesOrder: string[];
  loading?: boolean;
  error?: ApolloError;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:first-of-type:not(:last-of-type)": {
    marginBottom: 0,
  },
  // TODO use this border radius instead when new theming available
  // borderRadius: theme.shape.borderRadius,
  borderRadius: 4,
}));

const DataNodeConstructor: React.FC<DataNodeConstructorProps> = (
  {
    dataRoomId,
    dataNodes,
    mode,
    renderDataActions,
    dataNodesOrder,
    loading,
    error,
  },
  ref
) => {
  const indexes = useMemo(() => dataNodes.map((t) => t.id), [dataNodes]);
  const { isSelected, toggle } = useSelections(indexes, []);

  const computeNodeNamesAndTypes = useMemo(() => {
    return dataNodes.map(({ name, dataType }) => ({
      dataType,
      name,
    }));
  }, [dataNodes]);

  if (loading && !dataNodes.length) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress color="inherit" size="1.5rem" thickness={1} />
      </Box>
    );
  }
  if (error) {
    return (
      <Alert severity="error">
        Data clean room data nodes could not be retrieved. Please try again by
        refreshing the page.
      </Alert>
    );
  }

  return (
    <DataNodeConstructorParamsWrapper mode={mode}>
      <DataNodeConstructorParamsConsumer>
        {({ readOnly }) =>
          readOnly ? undefined : (
            <DataNodeAddNewField
              computeNodeNamesAndTypes={computeNodeNamesAndTypes}
              toggle={toggle}
            />
          )
        }
      </DataNodeConstructorParamsConsumer>
      <DataNodeChangeOutcomeHandler>
        {dataNodes.length > 0 && (
          <DataNodeList
            dataNodes={dataNodes}
            dataNodesOrder={dataNodesOrder}
            dataRoomId={dataRoomId}
            isSelected={isSelected}
            renderDataActions={renderDataActions}
            toggle={toggle}
          />
        )}
      </DataNodeChangeOutcomeHandler>
      {!dataNodes.length && emptyConstructorStatusMap.get(mode) ? (
        <Typography sx={{ mb: 1 }} variant="body1">
          {emptyConstructorStatusMap.get(mode)}
        </Typography>
      ) : undefined}
    </DataNodeConstructorParamsWrapper>
  );
};

DataNodeConstructor.displayName = "DataNodeConstructor";

export default DataNodeConstructor;
