import { Avatar, styled, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { memo } from "react";

const StyledAvatar = styled(Avatar)({
  ".MuiAvatar-img": {
    objectFit: "contain",
  },
});

type MediaDataRoomParticipantAvatarProps = {
  logoBase64?: string;
  avatarText?: string;
  size?: string;
};

const MediaDataRoomParticipantAvatar: React.FC<
  MediaDataRoomParticipantAvatarProps
> = ({ logoBase64, size = "100%", avatarText = "" }) => {
  return logoBase64 ? (
    <StyledAvatar
      src={`data:image;base64,${logoBase64}`}
      sx={{
        height: size,
        margin: "2px 0",
        maxHeight: "80px",
        maxWidth: "200px",
        width: size,
      }}
      variant="square"
    />
  ) : (
    <Avatar
      style={{
        backgroundColor: grey["100"],
        color: grey["400"],
        flex: 1,
        height: "auto",
        maxHeight: "80px",
        position: "relative",
        width: size,
      }}
      variant="square"
    >
      <Tooltip placement="top" title={avatarText}>
        <Typography style={{ cursor: "pointer" }} variant="body2">
          {avatarText?.substr(0, 2).toUpperCase()}
        </Typography>
      </Tooltip>
    </Avatar>
  );
};

MediaDataRoomParticipantAvatar.displayName = "Logo";

export default memo(MediaDataRoomParticipantAvatar);
