import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import MediaDataRoomSummary from "./MediaDataRoomSummary";

interface MediaDataRoomViewConfigurationDialogProps {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
}

const MediaDataRoomViewConfigurationDialog: React.FC<MediaDataRoomViewConfigurationDialogProps> =
  memo(({ dataRoomId, open, onCancel }) => {
    const cancelOnClick = useCallback(() => {
      onCancel();
    }, [onCancel]);
    const cancelLabel = "Close";
    const cancelProps = {
      color: "inherit" as "inherit",
      onClick: cancelOnClick,
      variant: "text" as "text",
    };
    return (
      <Dialog
        PaperProps={{ sx: { p: 0, transition: "all 0.2s linear" } }}
        fullWidth={true}
        maxWidth="lg"
        open={open}
      >
        <DialogTitle
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            lineHeight: "1.5rem",
            p: 1,
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
            Media Insights Clean Room configuration
          </Typography>
          <IconButton color="inherit" onClick={onCancel}>
            <FontAwesomeIcon fixedWidth={true} icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box p={1}>
            <MediaDataRoomSummary />
          </Box>
          <Box
            sx={{
              borderColor: "divider",
              borderTop: 1,
              display: "flex",
              justifyContent: "space-between",
              mt: "auto",
              p: 1,
            }}
          >
            <Button {...cancelProps}>{cancelLabel}</Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  });

export default MediaDataRoomViewConfigurationDialog;
