import {
  useUpdateSqlComputeNodeMutation,
  useUpdateSqliteComputeNodeMutation,
} from "@decentriq/graphql/dist/hooks";
import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import {
  faCaretLeft as faCaretLeftSolid,
  faCaretRight as faCaretRightSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useBoolean, useDebounceFn } from "ahooks";
import { memo, useCallback, useRef } from "react";
import {
  type ImperativePanelHandle,
  Panel,
  PanelGroup,
} from "react-resizable-panels";
import { SqlEditorField } from "components";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { FileExplorer, PrivacyFilterEditor } from "./components";
import useSqlComputeNode from "./useSqlComputeNode";

interface SqlComputeNodeEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
  editorOptions?: object;
  hidePrivacyFilter?: boolean;
  fullHeight?: boolean;
  variant: WorkerTypeShortName.Sqlite | WorkerTypeShortName.Sql;
}

const SqlComputeNodeEditor: React.FC<SqlComputeNodeEditorProps> = memo(
  ({ computeNodeId, readOnly, editorOptions, hidePrivacyFilter, variant }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { node, isLoading } = useSqlComputeNode(computeNodeId);
    const [
      isFileExplorerExpanded,
      { setTrue: expandFileExplorer, setFalse: collapseFileExplorer },
    ] = useBoolean(true);
    const sqlStatement = node?.statement || "";
    const [updateSqlComputeNodeMutation] = useUpdateSqlComputeNodeMutation();
    const [updateSqliteComputeNodeMutation] =
      useUpdateSqliteComputeNodeMutation();
    const updateSqlComputeNode = useCallback(
      async (value: string = "") => {
        try {
          return await (
            variant === WorkerTypeShortName.Sql
              ? updateSqlComputeNodeMutation
              : updateSqliteComputeNodeMutation
          )({
            variables: {
              input: {
                id: computeNodeId,
                statement: value,
              },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "The computation could not be updated."
            )
          );
        }
      },
      [
        variant,
        updateSqlComputeNodeMutation,
        updateSqliteComputeNodeMutation,
        computeNodeId,
        enqueueSnackbar,
      ]
    );
    const { run: debouncedUpdateSqlComputeNode } = useDebounceFn(
      updateSqlComputeNode,
      { wait: 750 }
    );

    const fileExplorerRef = useRef<ImperativePanelHandle>(null);

    return isLoading ? null : (
      <Grid
        columnSpacing={isFileExplorerExpanded ? 1 : 0}
        container={true}
        flex={1}
        height="100%"
      >
        <PanelGroup direction="horizontal">
          <Panel defaultSize={70}>
            <Grid container={true} direction="row" height="100%" item={true}>
              <Box
                sx={
                  !readOnly
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 1,
                        width: "calc(100% - 24px)",
                      }
                    : {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }
                }
              >
                {!hidePrivacyFilter && (
                  <PrivacyFilterEditor
                    computeNodeId={computeNodeId}
                    readOnly={readOnly}
                  />
                )}
                <SqlEditorField
                  defaultValue={sqlStatement}
                  editorOptions={editorOptions}
                  onChange={debouncedUpdateSqlComputeNode}
                />
              </Box>
              {!readOnly && (
                <Box
                  sx={{
                    alignItems: "center",
                    background: grey[100],
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    width: "16px",
                  }}
                >
                  <FontAwesomeIcon
                    color={grey[500]}
                    fixedWidth={true}
                    icon={
                      isFileExplorerExpanded
                        ? faCaretRightSolid
                        : faCaretLeftSolid
                    }
                    onClick={() => {
                      if (isFileExplorerExpanded) {
                        fileExplorerRef.current?.collapse();
                        collapseFileExplorer();
                        return;
                      }
                      fileExplorerRef.current?.expand();
                      expandFileExplorer();
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Panel>
          <Panel
            collapsedSize={0}
            collapsible={true}
            id="file-explorer"
            minSize={10}
            onCollapse={collapseFileExplorer}
            onExpand={expandFileExplorer}
            ref={fileExplorerRef}
          >
            {!readOnly && isFileExplorerExpanded && (
              <Grid height="auto" item={true} overflow="auto">
                <FileExplorer />
              </Grid>
            )}
          </Panel>
        </PanelGroup>
      </Grid>
    );
  }
);
SqlComputeNodeEditor.displayName = "SqlComputeNodeEditor";

export default SqlComputeNodeEditor;
