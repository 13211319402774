import { useBoolean } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  type KeychainItem,
  type KeychainItemKind,
  type KeychainService,
} from "services/keychain";
import { useKeychainService } from "contexts/keychain";

interface UseGetKeychainItemsProps {
  kinds?: KeychainItemKind[];
}

interface UseGetKeychainItems {
  loading: boolean;
  error: null | string;
  items: KeychainItem[];
}

// Keychain is a generic javascript client with no reactive properties.
// To allow reactive updates, the hooks needs to subscribe with a listener callback.
// This listener then needs to be removed on unMount to prevent memory leaks.
const useSubscribeToKeychain = <T>({
  keychain,
  updater,
  updaterParams,
}: {
  keychain: KeychainService;
  updater: (params: T) => void;
  updaterParams: T;
}) => {
  useEffect(() => {
    const updateWithKinds = () => updater(updaterParams);
    // Register
    const unsubscribe = keychain.registerListener(updateWithKinds);
    // Update for the first time
    updateWithKinds();
    // Unsubscribe to allow the updater to be garbage collected
    return unsubscribe;
  }, [keychain, updater, updaterParams]);
};

export function useGetKeychainItems({
  kinds,
}: UseGetKeychainItemsProps = {}): UseGetKeychainItems {
  const { keychain } = useKeychainService();
  const [items, setItems] = useState<KeychainItem[]>([]);
  const [loading, { setTrue: setLoadingTrue, setFalse: setLoadingFalse }] =
    useBoolean(false);
  const [error, setError] = useState<null | string>(null);

  const updater = useCallback(
    async ({
      keychain,
      kinds,
    }: {
      keychain: KeychainService;
      kinds?: KeychainItemKind[];
    }) => {
      setLoadingTrue();
      const items = await keychain.getItems().catch((error) => {
        setError(`Failed to load keychain items: ${error}`);
        return [];
      });
      const filteredItems = kinds
        ? items.filter(({ kind }) => kinds.includes(kind))
        : items;
      setItems(filteredItems);
      setLoadingFalse();
    },
    [setLoadingFalse, setLoadingTrue]
  );

  useSubscribeToKeychain({
    keychain,
    updater,
    updaterParams: useMemo(
      () => ({
        keychain,
        kinds,
      }),
      [keychain, kinds]
    ),
  });

  return {
    error,
    items,
    loading,
  };
}
