import { Skeleton } from "@mui/material";
import { useCallback, useState } from "react";
import { useDataRoom } from "contexts";
import { useUniqueness } from "./hooks";
import UniquenessConstructor from "./UniquenessConstructor";

interface UniquenessProps {
  id: string;
  readOnly?: boolean;
}

const Uniqueness: React.FC<React.PropsWithChildren<UniquenessProps>> = ({
  id,
  readOnly = false,
}) => {
  const { isPublished } = useDataRoom();
  const [value, setValue] = useState<string[]>([]);
  const {
    uniqueColumnIds,
    uniquenessLoading,
    dataNodesColumnIdsByName,
    dataNodesColumnNamesById,
    draftTableLeafNodeUpdateUniquenessMutation,
    draftTableLeafNodeUpdateUniquenessMutationLoading,
  } = useUniqueness(id);
  const onAddUniqueColumnIds = useCallback(() => {
    if (value.length > 0) {
      draftTableLeafNodeUpdateUniquenessMutation({
        onCompleted: () => {
          setValue([]);
        },
        variables: {
          id,
          uniqueness: {
            value: {
              uniqueColumnIds: [value, ...(uniqueColumnIds || [])],
            },
          },
        },
      });
    }
  }, [
    draftTableLeafNodeUpdateUniquenessMutation,
    setValue,
    id,
    uniqueColumnIds,
    value,
  ]);
  const onDeleteUniqueColumnIds = useCallback(
    (idsIndexToDelete: number) => {
      if (idsIndexToDelete !== undefined) {
        draftTableLeafNodeUpdateUniquenessMutation({
          variables: {
            id,
            uniqueness: {
              value: {
                uniqueColumnIds:
                  uniqueColumnIds?.filter(
                    (ids: any, index: number) => index !== idsIndexToDelete
                  ) || [],
              },
            },
          },
        });
      }
    },
    [draftTableLeafNodeUpdateUniquenessMutation, id, uniqueColumnIds]
  );
  const onAddColumn = useCallback(
    (name: string) => {
      if (name.length > 0) {
        const idByName = dataNodesColumnIdsByName.get(id)?.get(name);
        if (idByName) {
          setValue((prevValue) => [...prevValue, idByName]);
        }
      }
    },
    [dataNodesColumnIdsByName, id, setValue]
  );
  const onDeleteColumn = useCallback(
    (name: string, indexToDelete: number) => {
      if (indexToDelete !== undefined) {
        setValue((prevValue) =>
          prevValue.filter((name, index) => index !== indexToDelete)
        );
      }
    },
    [setValue]
  );
  const onAddColumnAndAddUniqueColumnIds = useCallback(
    (name: string) => {
      let currentUniqueColumnIds = [...value];
      if (name.length > 0) {
        const idByName = dataNodesColumnIdsByName.get(id)?.get(name);
        if (idByName) {
          currentUniqueColumnIds = [...currentUniqueColumnIds, idByName];
        }
      }
      if (currentUniqueColumnIds.length > 0) {
        draftTableLeafNodeUpdateUniquenessMutation({
          onCompleted: () => {
            setValue([]);
          },
          variables: {
            id,
            uniqueness: {
              value: {
                uniqueColumnIds: [
                  currentUniqueColumnIds,
                  ...(uniqueColumnIds || []),
                ],
              },
            },
          },
        });
      }
    },
    [
      dataNodesColumnIdsByName,
      draftTableLeafNodeUpdateUniquenessMutation,
      id,
      uniqueColumnIds,
      value,
    ]
  );
  return uniquenessLoading ? (
    <Skeleton />
  ) : (
    <UniquenessConstructor
      columnNameById={dataNodesColumnNamesById.get(id)}
      currentColumns={value}
      isPublished={isPublished}
      loading={
        uniquenessLoading || draftTableLeafNodeUpdateUniquenessMutationLoading
      }
      onAddColumn={onAddColumn}
      onAddColumnAndAddUniqueColumnIds={onAddColumnAndAddUniqueColumnIds}
      onAddUniqueColumnIds={onAddUniqueColumnIds}
      onDeleteColumn={onDeleteColumn}
      onDeleteUniqueColumnIds={onDeleteUniqueColumnIds}
      readOnly={readOnly}
      uniqueColumnIds={uniqueColumnIds}
    />
  );
};

export default Uniqueness;
