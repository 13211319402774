import { faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import { memo } from "react";
import {
  Controller,
  type FieldError,
  type Merge,
  useFieldArray,
  useFormContext,
} from "react-hook-form";

const SegmentOwnersField: React.FC = () => {
  const { control } = useFormContext();
  const {
    fields: segmentOwnersFields,
    append: appendSegmentOwner,
    remove: removeSegmentOwner,
  } = useFieldArray({
    control,
    name: "segmentOwners",
  });

  return (
    <Box display="flex" flexDirection="column">
      {segmentOwnersFields.length ? (
        <Typography mb={1} mt={1} variant="subtitle2">
          Segment Owners
        </Typography>
      ) : null}
      {segmentOwnersFields.map((arrayFieldId, index) => {
        return (
          <Box display="flex" key={arrayFieldId.id}>
            <Controller
              control={control}
              name={`segmentOwners.${index}.id`}
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = get(errors?.segmentOwners, `${index}.id`) as
                  | Merge<FieldError, (FieldError | undefined)[]>
                  | undefined;
                return (
                  <FormControl
                    error={!!fieldError}
                    fullWidth={true}
                    sx={{ mb: 1 }}
                  >
                    <FormLabel component="legend" sx={{ fontSize: "12px" }}>
                      {`Segment Owner ${index + 1}`}
                    </FormLabel>
                    <Input
                      error={!!fieldError}
                      fullWidth={true}
                      inputProps={{
                        sx: {
                          "&.MuiInput-input": { pl: "2px" },
                        },
                      }}
                      placeholder={`Example: KLAB00${index + 1}`}
                      size="medium"
                      {...field}
                    />
                    <FormHelperText sx={{ ml: 0 }}>
                      {fieldError?.message || ""}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
            {segmentOwnersFields.length > 1 && (
              <IconButton
                color="error"
                onClick={() => removeSegmentOwner(index)}
                sx={{ ml: 1, width: "60px" }}
              >
                <FontAwesomeIcon fixedWidth={true} icon={faTrash} />
              </IconButton>
            )}
          </Box>
        );
      })}
      <Button
        onClick={() => appendSegmentOwner({ id: "" })}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        sx={{ width: "fit-content" }}
        variant="text"
      >
        Add Segment Owner
      </Button>
    </Box>
  );
};

SegmentOwnersField.displayName = "SegmentOwnersField";

export default memo(SegmentOwnersField);
