export enum MediaDataRoomUserRole {
  Publisher = "publisher",
  Advertiser = "advertiser",
  Observer = "observer",
  Agency = "agency",
  DataPartner = "dataPartner",
}

export type AudienceOverlap = {
  type: string;
  overlap: number;
  total: number;
  notMatching?: number;
};

export type OverlapInsight = {
  segment: string;
  basePropensity: number;
  netPropensity: number;
  overlapPropensity: number;
};

export const entireOverlapKey = "All audiences combined";

export const getAudiencePresentation = (key: string): string =>
  key === entireOverlapKey ? "All audiences combined" : key;

export const sortAudiences = (audiences: string[]) =>
  [...audiences].sort((a, b) => (a === entireOverlapKey ? 1 : -1));

export const DEFAULT_PARTICIPANTS_EMAILS = new Map<
  MediaDataRoomUserRole,
  string[]
>([
  [MediaDataRoomUserRole.Publisher, []],
  [MediaDataRoomUserRole.DataPartner, []],
  [MediaDataRoomUserRole.Advertiser, []],
  [MediaDataRoomUserRole.Observer, []],
  [MediaDataRoomUserRole.Agency, []],
]);
