/* eslint-disable sort-keys-fix/sort-keys-fix */
import { EMAIL_REGEXP } from "constants/index";
import * as yup from "yup";

export const MeasurementConfigurationValidationSchema = yup.object({
  dataRoomName: yup
    .string()
    .max(800, "Data room name must be no longer than 800 characters")
    .required("Data room name is required"),
  publishers: yup
    .array()
    .of(
      yup.object({
        emails: yup
          .array()
          .of(yup.string().email().required("Publisher email is required"))
          .min(1, "At least one publisher email is required")
          .required(),
        name: yup.string().required("Publisher name is required"),
      })
    )
    .min(1, "Please select at least one publisher")
    .max(5, "You can select a maximum of 5 publishers")
    .test(
      "is-unique",
      "There are duplicate emails in the publishers",
      (value) => {
        const emails = value?.flatMap((publisher) => publisher.emails) || [];
        return emails.length === new Set(emails).size;
      }
    )
    .required(),
  advertiserUserEmails: yup
    .array()
    .of(
      yup
        .string()
        .email({ message: "Invalid email", regex: EMAIL_REGEXP })
        .required("Advertiser email is required")
    )
    .min(1, "At least one advertiser email is required")
    .required()
    .test(
      "is-unique",
      "There are duplicate emails in the advertiser emails",
      (emails) => emails?.length === new Set(emails).size
    )
    .test(
      "is-current-participant",
      "Your user must also be a participant of this data clean room.",
      (value, context) => {
        const { parent, options } = context as yup.TestContext<{
          currentUserEmail: string;
        }>;
        const publisherEmails =
          parent.publishers?.flatMap(
            (publisher: { emails: string[] }) => publisher.emails
          ) || [];
        return [...value, ...publisherEmails].includes(
          options.context?.currentUserEmail
        );
      }
    )
    .test(
      "has-common-with-publisher",
      "The following emails are already part of the publisher emails",
      (value, { parent }) => {
        const publisherEmails =
          parent.publishers?.flatMap(
            (publisher: { emails: string[] }) => publisher.emails
          ) || [];
        const emails = [...value, ...publisherEmails];
        return emails.length === new Set(emails).size;
      }
    ),
  measurementConfiguration: yup.object({
    attributionRule: yup.string().required("Please select an Attribution Rule"),
    clickLookbackWindow: yup
      .number()
      .required("Please select a Click Lookback Window"),
    viewLookbackWindow: yup
      .number()
      .required("Please select a View Lookback Window"),
  }),
});

export type MeasurementConfigurationFormValues = yup.InferType<
  typeof MeasurementConfigurationValidationSchema
>;

export type MeasurementPublisherConfiguration =
  MeasurementConfigurationFormValues["publishers"][number];

export type MeasurementAttributionConfiguration =
  MeasurementConfigurationFormValues["measurementConfiguration"];

export const defaultMeasurementConfigurationFormValues: MeasurementConfigurationFormValues =
  {
    advertiserUserEmails: [],
    dataRoomName: "",
    measurementConfiguration: {
      attributionRule: "last_click",
      clickLookbackWindow: 1,
      viewLookbackWindow: 0,
    },
    publishers: [],
  };

export const lookBackWindow = [
  { label: "1 Day", value: 1 },
  { label: "7 Days", value: 7 },
  { label: "28 Days", value: 28 },
  { label: "91 Days", value: 91 },
];
export const lookBackWindowWithNone = [
  { label: "None", value: 0 },
  { label: "1 Day", value: 1 },
  { label: "7 Days", value: 7 },
  { label: "28 Days", value: 28 },
  { label: "91 Days", value: 91 },
];

export const attributions = [
  { label: "Last Click + Viewthrough", value: "last_click" },
  { label: "Even Credit", value: "even_credit" },
  { label: "First and Last", value: "first_last" },
];
