import { Box, Typography } from "@mui/material";
import {
  DataRoomsList,
  GettingStartedActions,
  RecentDataRoomsBlock,
} from "components";
import { Main } from "layouts";

const DataRoomsPage = () => {
  return (
    <Main>
      <Box
        sx={{
          backgroundColor: "background.bar",
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Typography variant="h6">
          <strong>Data clean rooms</strong>
        </Typography>
        <GettingStartedActions />
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: 2,
          overflow: "hidden",
          padding: 2,
        }}
      >
        <RecentDataRoomsBlock />
        <DataRoomsList />
      </Box>
    </Main>
  );
};

export default DataRoomsPage;
