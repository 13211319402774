import {
  faCaretDown as faCaretDownSolid,
  faCaretRight as faCaretRightSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, styled, Tooltip } from "@mui/material";
import { memo, useCallback } from "react";
import { useRequestsVars } from "contexts";

interface RequestExpandButtonProps {
  requestId: string;
  className?: string;
}

const RequestExpandButton: React.FC<RequestExpandButtonProps> = memo(
  ({ requestId, className }) => {
    const { checkRequestSelection, toggleRequest } = useRequestsVars();
    const expandRequest = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        toggleRequest(requestId);
      },
      [requestId, toggleRequest]
    );
    return (
      <Tooltip
        placement="top"
        title={checkRequestSelection(requestId) ? "Collapse" : "Expand"}
      >
        <IconButton
          className={className}
          color="inherit"
          onClick={expandRequest}
          size="small"
        >
          <FontAwesomeIcon
            fixedWidth={true}
            icon={
              checkRequestSelection(requestId)
                ? faCaretDownSolid
                : faCaretRightSolid
            }
          />
        </IconButton>
      </Tooltip>
    );
  }
);

RequestExpandButton.displayName = "RequestExpandButton";

const StyledRequestExpandButton = styled(RequestExpandButton)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

export default StyledRequestExpandButton;
