import { DqTable } from "@decentriq/components";
import { ActivationType } from "@decentriq/graphql/dist/types";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useMemo } from "react";
import {
  ConsentlessActivationDownloadButton,
  DirectActivationDownloadButton,
} from "..";
import usePublisherMediaDataRoomActivation from "./usePublisherMediaDataRoomActivation";

interface PublisherMediaDataRoomActivationProps {
  id: string;
  activationType: ActivationType;
}

const PublisherMediaDataRoomActivation: React.FC<
  PublisherMediaDataRoomActivationProps
> = ({ activationType }) => {
  const { audiences, loading, error, activationDownloadByPublisher } =
    usePublisherMediaDataRoomActivation({
      activationType,
    });
  type Audience = Omit<(typeof audiences)[number], "__typename">;
  const columns = useMemo<MRT_ColumnDef<Audience>[]>(
    () => [
      {
        Cell: ({ row }) => (
          <Typography sx={{ my: "auto" }}>{row.original.name}</Typography>
        ),
        header:
          activationType === ActivationType.Consentless
            ? "Top affinity audience"
            : "Audience name",
        id: "name",
        minSize: 150,
      },
      {
        Cell: ({ cell }) => (
          <Typography sx={{ my: "auto" }}>{cell.getValue<string>()}</Typography>
        ),
        accessorFn: (row) => {
          if (!activationDownloadByPublisher) {
            return "Ready for activation";
          }
          if (!row.downloaded) {
            return "Available for download";
          }
          return "Downloaded";
        },
        header: "Status",
        id: "status",
        minSize: 200,
      },
    ],
    [activationDownloadByPublisher, activationType]
  );
  return (
    <>
      <Typography gutterBottom={true} variant="h6">
        Audiences
      </Typography>
      {loading ? (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          padding="1rem"
        >
          <CircularProgress color="inherit" size="1.5rem" thickness={1} />
        </Box>
      ) : error ? (
        <Alert severity="error">
          {error.message ||
            "Audiences could not be retrieved. Please try again by refreshing the page."}
        </Alert>
      ) : !audiences.length ? (
        "This page will show audiences to download once the Advertiser has created them."
      ) : (
        <DqTable
          columns={columns}
          data={audiences}
          enableRowActions={true}
          enableSorting={false}
          localization={{
            actions: "",
          }}
          renderRowActions={({ row }) => {
            const { name: audienceName, id: audienceId } = row.original || {};
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                {activationType === ActivationType.Consentless && (
                  <ConsentlessActivationDownloadButton
                    audienceId={audienceId || audienceName}
                    audienceName={audienceName}
                  />
                )}
                {activationType === ActivationType.Direct && (
                  <DirectActivationDownloadButton
                    audienceId={audienceId || audienceName}
                    audienceName={audienceName}
                  />
                )}
              </Box>
            );
          }}
        />
      )}
    </>
  );
};

export default memo(PublisherMediaDataRoomActivation);
