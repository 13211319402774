import { createContext, useContext } from "react";
import { type AudienceOverlapStatisticsRaw } from "features/mediaDataRoomV2/models";
import {
  type ModelledSegment,
  type OverlapSegment,
} from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";

export enum OverlapInsightsExecutionContext {
  insightsTab = "insights_tab",
  advertiserInsights = "advertiser_insights",
  publisherInsights = "publisher_insights",
}

interface OverlapInsightsContextValue {
  executionContext: OverlapInsightsExecutionContext; // Defines whether insights rendered on the Insights tab, or Publisher's Insights dialog, or Advertiser's Insights dialog
  reach?: number;
  audienceType?: string;
  overlapInsightsIsLoading: boolean;
  overlapInsightsError?: string;
  overlapInsightsDataStatus?: "COMPUTING" | "COMPLETED" | "FETCHING";
  overlapInsightsStatistics: AudienceOverlapStatisticsRaw[];
  overlapInsightsSegments: ModelledSegment[] | OverlapSegment[];
  overlapInsights: ModelledSegment[] | OverlapSegment[];
  overlapInsightsWithNoiseCalculation: boolean;
  overlapInsightsTotalPublisherCount: number | undefined;
  isInsightsDialogOpen: boolean;
  openInsightsDialog: () => void;
  closeInsightsDialog: () => void;
  retry: () => Promise<void>;
}

export const LookalikeMediaOverlapInsightsContext =
  createContext<OverlapInsightsContextValue>({
    audienceType: "",
    closeInsightsDialog: () => {},
    executionContext: OverlapInsightsExecutionContext.insightsTab,
    isInsightsDialogOpen: false,
    openInsightsDialog: () => {},
    overlapInsights: [],
    overlapInsightsError: "",
    overlapInsightsIsLoading: false,
    overlapInsightsSegments: [],
    overlapInsightsStatistics: [],
    overlapInsightsTotalPublisherCount: undefined,
    overlapInsightsWithNoiseCalculation: false,
    reach: undefined,
    retry: () => Promise.resolve(),
  });

export const LookalikeMediaOverlapInsightsProvider =
  LookalikeMediaOverlapInsightsContext.Provider;

export const useLookalikeMediaOverlapInsightsContext = () =>
  useContext(LookalikeMediaOverlapInsightsContext);

export default useLookalikeMediaOverlapInsightsContext;
