import { Grid } from "@mui/material";
import { memo } from "react";
import { MediaDataRoomInsightsComparisonItem } from "./components";

const MediaDataRoomInsightsComparison: React.FC = () => {
  return (
    <Grid
      columnSpacing={4}
      container={true}
      height="100%"
      item={true}
      overflow="hidden"
      xs={12}
    >
      <Grid height="100%" item={true} overflow="hidden" xs={6}>
        <MediaDataRoomInsightsComparisonItem />
      </Grid>
      <Grid height="100%" item={true} overflow="hidden" xs={6}>
        <MediaDataRoomInsightsComparisonItem />
      </Grid>
    </Grid>
  );
};

MediaDataRoomInsightsComparison.displayName = "MediaDataRoomInsightsComparison";

export default memo(MediaDataRoomInsightsComparison);
