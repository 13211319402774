import { ListItem, styled } from "@mui/material";
import { Box } from "@mui/system";
import { memo, type PropsWithChildren } from "react";
import { Chip } from "components";

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flex: 1,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  overflow: "hidden",
  padding: theme.spacing(1),
}));

interface AccessTokenListItemProps extends PropsWithChildren {
  revoked?: boolean;
}

const AccessTokenListItem: React.FC<AccessTokenListItemProps> = ({
  children,
  revoked = false,
}) => {
  return (
    <StyledListItem>
      <Box sx={{ p: 1 }}>
        {revoked ? (
          <Chip color="error" label="Revoked" sx={{ marginRight: 1 }} />
        ) : (
          <Chip color="success" label="Active" sx={{ marginRight: 1 }} />
        )}
      </Box>
      {children}
    </StyledListItem>
  );
};

AccessTokenListItem.displayName = "AccessTokenListItem";

export default memo(AccessTokenListItem);
