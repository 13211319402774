import { faBuildings } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

type AdminPortalSidebarMenuItemProps = {};

const AdminPortalSidebarMenuItem: React.FC<
  AdminPortalSidebarMenuItemProps
> = () => {
  return (
    <SidebarMenuItem
      data-testid="sidebar_admin_portal"
      icon={faBuildings}
      label="Admin"
      to="/admin"
    />
  );
};

AdminPortalSidebarMenuItem.displayName = "AdminPortalSidebarMenuItem";

export default memo(AdminPortalSidebarMenuItem);
