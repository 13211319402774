import { Box, Grid } from "@mui/material";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  type InsightsDetailedViewFormValues,
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  useMediaDataRoomOverlapInsightsContext,
} from "features/MediaInsightsDcr";
import {
  AffinityRatioSegmentDemographicsBarChart,
  AffinityRatioSegmentDemographicsTable,
  GenderBarChart,
} from "./components";

const MediaDataRoomInsightsDetailedView: React.FC = () => {
  const { audienceType } = useMediaDataRoomOverlapInsightsContext();
  const mediaDataRoomInsightsDetailedViewForm =
    useForm<InsightsDetailedViewFormValues>({
      defaultValues: {
        audienceType: audienceType || "",
        dataSortDirection: "desc",
        genderChartValueKey: "shareInOverlap",
      },
      mode: "onChange",
    });

  return (
    <Box display="flex" height="100%">
      <FormProvider {...mediaDataRoomInsightsDetailedViewForm}>
        <Grid
          columnSpacing={3}
          container={true}
          flex={1}
          item={true}
          overflow="auto"
        >
          <Grid container={true} item={true} md={4} xs={12}>
            <MediaDataRoomInsightsAdvertiserAudienceSelector />
          </Grid>
          <Grid item={true} mb={2} minHeight={250} xs={12}>
            <AffinityRatioSegmentDemographicsBarChart />
          </Grid>
          <Grid columnSpacing={5} container={true} flex={1} item={true} xs={12}>
            <Grid
              container={true}
              item={true}
              mb={2}
              md={6}
              minHeight={230}
              xs={12}
            >
              <GenderBarChart />
            </Grid>
            <Grid container={true} item={true} md={6} xs={12}>
              <AffinityRatioSegmentDemographicsTable />
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
};

MediaDataRoomInsightsDetailedView.displayName =
  "MediaDataRoomInsightsDetailedView";

export default memo(MediaDataRoomInsightsDetailedView);
