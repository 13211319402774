import { useDatasetByHashQuery } from "@decentriq/graphql/dist/hooks";
import {
  faFileLines as faFileLinesRegular,
  faTable as faTableRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  styled,
  type Theme,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { format, isValid, parseISO } from "date-fns";
import { memo, useState } from "react";
import { DatasetPopup } from "features/datasets";
import { type DataType } from "models";
import { ellipsisify } from "utils";

const StyledTooltip = styled(Tooltip)(({ theme }: { theme: Theme }) => ({
  root: {
    [`${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  },
}));

interface DataNodeDataButtonProps {
  id: string;
  dataType: DataType;
  datasetHash?: string;
  onClick?: () => void;
  testing?: boolean;
  withActions?: boolean;
}

const DataNodeDataButton: React.FC<DataNodeDataButtonProps> = ({
  id,
  dataType,
  datasetHash,
  onClick,
  testing,
  withActions,
}) => {
  const { data } = useDatasetByHashQuery({
    skip: !datasetHash,
    variables: { manifestHash: datasetHash! },
  });
  const [isPopupOpen, setPopupOpen] = useState(false);
  const dataset = data?.datasetByManifestHash;
  if (!dataset) {
    return <></>;
  }
  const isDatasetOwner = dataset && dataset?.isOwner;
  const ownerEmail = dataset?.owner?.email;
  const formattedDate =
    dataset && isValid(parseISO(dataset?.createdAt || ""))
      ? format(parseISO(dataset?.createdAt || ""), "MMM d, HH:mm")
      : null;
  const dataIngestionLabel = dataType === "table" ? "dataset" : "file";
  const name = ellipsisify(dataset.name, 23);
  return (
    <>
      <StyledTooltip
        placement="top"
        title={
          <Typography variant="caption">
            {`${
              isDatasetOwner ? "You" : ownerEmail
            } provisioned a ${dataIngestionLabel}, uploaded on ${formattedDate}.`}
            <br />
            Click to see more details.
          </Typography>
        }
      >
        <Button
          color={testing ? "secondary" : "primary"}
          onClick={(e) => {
            e.stopPropagation();
            setPopupOpen(true);
            onClick?.();
          }}
          size="small"
          startIcon={
            <FontAwesomeIcon
              fixedWidth={true}
              icon={dataType === "table" ? faTableRegular : faFileLinesRegular}
            />
          }
          style={{
            justifyContent: "flex-start",
            maxWidth: "221px",
            minWidth: "221px",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          variant={"outlined"}
        >
          {name}
        </Button>
      </StyledTooltip>
      <DatasetPopup
        datasetHash={dataset?.manifestHash}
        onDismiss={() => setPopupOpen(false)}
        open={isPopupOpen}
        testing={testing ? { dataNodeId: id } : undefined}
        withActions={withActions}
      />
    </>
  );
};

export default memo(DataNodeDataButton);
