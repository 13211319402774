import { Box } from "@mui/material";
import { memo } from "react";
import {
  LookalikeMediaInsightsAdvertiserAudienceSelector,
  LookalikeMediaInsightsAggregationSelector,
  LookalikeMediaInsightsChartTypeSelector,
  LookalikeMediaInsightsVisualizeSelector,
} from "./components";

const LookalikeMediaInsightsForm: React.FC = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <LookalikeMediaInsightsAdvertiserAudienceSelector />
      <LookalikeMediaInsightsAggregationSelector />
      <LookalikeMediaInsightsVisualizeSelector />
      <LookalikeMediaInsightsChartTypeSelector />
    </Box>
  );
};

LookalikeMediaInsightsForm.displayName = "LookalikeMediaInsightsForm";

export default memo(LookalikeMediaInsightsForm);
