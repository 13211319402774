import { ActivationType } from "@decentriq/graphql/dist/types";
import { memo } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import PublisherMediaDataRoomActivation from "../PublisherMediaDataRoomActivation/PublisherMediaDataRoomActivation";
import AdvertiserDirectMediaDataRoomActivation from "./AdvertiserDirectMediaDataRoomActivation";

interface DirectMediaDataRoomActivationProps {
  id: string;
}

const DirectMediaDataRoomActivation = memo<DirectMediaDataRoomActivationProps>(
  ({ id }) => {
    const { isPublisher, isAdvertiser, isAgency } = usePublishedMediaDataRoom();
    if (isPublisher) {
      return (
        <PublisherMediaDataRoomActivation
          activationType={ActivationType.Direct}
          id={id}
        />
      );
    }
    if (isAdvertiser || isAgency) {
      return <AdvertiserDirectMediaDataRoomActivation id={id} />;
    }
    return <></>;
  }
);

export default DirectMediaDataRoomActivation;
