import { DqCard, DqCardContent, InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { memo, useCallback, useMemo } from "react";
import { Chip } from "components";
import { EmptyStateContainer } from "features/mediaDataRoomV2";
import {
  MediaInsightsDcrCreationStep,
  useCreationWizardConfiguration,
  useCreationWizardPublisher,
  useCreationWizardStepper,
} from "features/MediaInsightsDcr";
import { MediaInsightsDcrCreateMatchingIdChip } from "features/MediaInsightsDcr/components/MediaInsightsDcrCreateWizard/components";
import { OrganizationTile } from "features/mediaPortalShared";
import { mediaDataRoomCollaborationTypes } from "models";
import MediaInsightsDcrCreateSelectPublisherStepMarketFilters from "./MediaInsightsDcrCreateSelectPublisherStepMarketFilters";

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const MediaInsightsDcrCreateSelectPublisherStep: React.FC = () => {
  const { handleBackStep, handleNextStep, setActiveStep } =
    useCreationWizardStepper();
  const {
    allowedCollaborationTypes,
    canProceedToConfiguration,
    contactButtonEnabled,
    contactButtonTooltip,
  } = useCreationWizardConfiguration();
  const {
    isAvailablePublishersLoading = false,
    availablePublishers = [],
    selectedPublisher,
    selectPublisher,
    invitePublisher,
  } = useCreationWizardPublisher();
  const handleInviteNewPublisher = useCallback(() => {
    invitePublisher();
    handleNextStep();
  }, [handleNextStep, invitePublisher]);
  const addPublisher = useMemo(
    () => (
      <Grid
        alignItems="center"
        container={true}
        data-testid={
          testIds.mediaInsightsDcr.mediaInsightDcrCreateSelectPublisher
            .invitePublisher
        }
        direction="column"
        item={true}
        key={0}
        lg={3}
        md={4}
        xs={6}
      >
        <DqCard
          onSelect={handleInviteNewPublisher}
          selected={false}
          sx={{ height: "100%", minHeight: 120, width: "100%" }}
        >
          <DqCardContent>
            <StyledIcon fixedWidth={true} icon={faNewspaper} size="3x" />
            <Typography
              color="initial"
              fontWeight={500}
              textAlign="center"
              variant="subtitle1"
            >
              + Invite publisher
            </Typography>
          </DqCardContent>
        </DqCard>
      </Grid>
    ),
    [handleInviteNewPublisher]
  );
  let content: React.ReactNode;
  if (isAvailablePublishersLoading) {
    content = (
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        height="200px"
        justifyContent="center"
      >
        <CircularProgress color="inherit" thickness={2} />
      </Box>
    );
  } else if (!availablePublishers?.length) {
    content = (
      <EmptyStateContainer height="200px">{addPublisher}</EmptyStateContainer>
    );
  } else {
    content = (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography fontWeight="fontWeightSemiBold" mb={1} variant="body1">
          Select publisher
        </Typography>
        <MediaInsightsDcrCreateSelectPublisherStepMarketFilters />
        <Box
          mb={2}
          sx={{
            borderColor: grey[300],
            borderStyle: "solid",
            borderWidth: 2,
            minHeight: "330px",
            p: 1.5,
          }}
        >
          <Grid columnSpacing={2} container={true} rowSpacing={2}>
            {addPublisher}
            {availablePublishers.map((publisher) => {
              const { id, publisherLogo, publisherName, marketIds } = publisher;
              const isSelected = id === selectedPublisher?.id;
              return (
                <Grid
                  alignItems="center"
                  container={true}
                  direction="column"
                  item={true}
                  key={id}
                  lg={3}
                  md={4}
                  xs={6}
                >
                  <OrganizationTile
                    logo={publisherLogo}
                    marketIds={marketIds}
                    name={publisherName}
                    onSelect={() => selectPublisher(publisher)}
                    selected={isSelected}
                    sx={{ height: "100%", minHeight: 120, width: "100%" }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={{ minHeight: "215px", overflowY: "auto" }}>
          {selectedPublisher?.publisherDescription ? (
            <Box mb={2}>
              <Typography
                fontWeight={"fontWeightSemiBold"}
                mb={0.5}
                variant="body1"
              >
                Description
              </Typography>
              <Typography>{selectedPublisher.publisherDescription}</Typography>
            </Box>
          ) : null}
          {canProceedToConfiguration && (
            <>
              <MediaInsightsDcrCreateMatchingIdChip />
              <Box>
                <Typography
                  fontWeight={"fontWeightSemiBold"}
                  mb={0.5}
                  variant="body1"
                >
                  Supported collaboration types
                  <InfoTooltip tooltip="If you are interested in a collaboration type which is not yet supported, please contact the publisher using the button to the right." />
                </Typography>
                <Box>
                  {mediaDataRoomCollaborationTypes.map((feature) => (
                    <Chip
                      disabled={!allowedCollaborationTypes.includes(feature)}
                      key={feature}
                      label={feature}
                      sx={{ mr: 1 }}
                    />
                  ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <>
      <DialogTitle>Create new media clean room</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        {content}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "auto",
        }}
      >
        <Button color="inherit" onClick={handleBackStep} variant="text">
          Back
        </Button>
        {!canProceedToConfiguration && contactButtonEnabled ? (
          <Tooltip title={contactButtonTooltip}>
            <div>
              <Button
                color="primary"
                data-testid={
                  testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard
                    .requestCollaborationButton
                }
                onClick={() => {
                  setActiveStep(
                    MediaInsightsDcrCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER
                  );
                }}
                variant="contained"
              >
                Contact publisher
              </Button>
            </div>
          </Tooltip>
        ) : null}
        {canProceedToConfiguration && (
          <Button
            color="primary"
            data-testid={
              testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard.submitButton
            }
            onClick={handleNextStep}
            variant="contained"
          >
            Continue
          </Button>
        )}
      </DialogActions>
    </>
  );
};

MediaInsightsDcrCreateSelectPublisherStep.displayName =
  "MediaInsightsDcrCreateSelectPublisherStep";

export default memo(MediaInsightsDcrCreateSelectPublisherStep);
