import { testIds } from "@decentriq/utils";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import { memo } from "react";
import {
  useCreationWizardConfiguration,
  useCreationWizardStepper,
} from "features/MediaInsightsDcr/contexts";
import {
  CollaborationTypesEditor,
  DataRoomName,
  MatchingIdSelector,
  RolesAndParticipants,
} from "./components";

const MediaInsightsDcrCreateCollaborationConfigurationStep: React.FC = () => {
  const { contactButtonTooltip, handleSubmit } =
    useCreationWizardConfiguration();
  const { handleBackStep } = useCreationWizardStepper();
  return (
    <>
      <DialogTitle>Create new media clean room</DialogTitle>
      <DialogContent>
        <Box mb={0}>
          <DataRoomName />
        </Box>
        <Box mb={2}>
          <RolesAndParticipants />
        </Box>
        <Grid columnSpacing={1} container={true}>
          <Grid item={true} xs={4}>
            <MatchingIdSelector />
          </Grid>
          <Grid item={true} xs={8}>
            <CollaborationTypesEditor />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "auto",
        }}
      >
        <Button color="inherit" onClick={handleBackStep} variant="text">
          Back
        </Button>
        <Tooltip title={contactButtonTooltip}>
          <div>
            <Button
              color="primary"
              data-testid={
                testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard
                  .submitButton
              }
              onClick={handleSubmit}
              variant="contained"
            >
              Publish
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </>
  );
};

MediaInsightsDcrCreateCollaborationConfigurationStep.displayName =
  "MediaInsightsDcrCreateCollaborationConfigurationStep";

export default memo(MediaInsightsDcrCreateCollaborationConfigurationStep);
