import { Box, Collapse, ListItem, styled } from "@mui/material";
import { memo } from "react";
import { useRequest, useRequestsVars } from "contexts";
import { ComputeNode } from "features/computeNode";
import { ComputeNodesListItem } from "features/computeNodes";
import RequestAnalysts from "../RequestAnalysts/RequestAnalysts";
import RequestApprovers from "../RequestApprovers/RequestApprovers";
import RequestToolbar from "../RequestToolbar/RequestToolbar";
import SubmittedRequestRebase from "../SubmittedRequestRebase/SubmittedRequestRebase";
import SubmittedRequestSignees from "../SubmittedRequestSignees/SubmittedRequestSignees";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
}));

interface RequestItemProps {
  requestId: string;
}

const RequestItem: React.FC<RequestItemProps> = ({ requestId }) => {
  const { checkRequestSelection, context } = useRequestsVars();
  const isRequestSelected = checkRequestSelection(requestId);
  const { computeNodeId } = useRequest();
  return (
    <StyledListItem
      key={requestId}
      style={{
        background: "white",
        border: "solid #bbb 1px",
        marginBottom: 8,
        padding: "7px",
      }}
    >
      <Box flex="1" maxWidth="100%">
        {context === "submitted" ? (
          <SubmittedRequestRebase requestId={requestId} />
        ) : null}
        <RequestToolbar requestId={requestId} />
        <Collapse in={isRequestSelected} timeout="auto" unmountOnExit={true}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "12px 8px",
            }}
          >
            <Box mr={4}>
              <RequestAnalysts requestId={requestId} />
            </Box>
            <Box width="100%">
              {context === "submitted" ? (
                <SubmittedRequestSignees submittedRequestId={requestId} />
              ) : null}
              {context === "draft" ? (
                <RequestApprovers requestId={requestId} />
              ) : null}
            </Box>
          </Box>
          <Box style={{ padding: "0 8px" }}>
            <ComputeNodesListItem draggable={false}>
              <ComputeNode computeNodeId={computeNodeId} />
            </ComputeNodesListItem>
          </Box>
        </Collapse>
      </Box>
    </StyledListItem>
  );
};

RequestItem.displayName = "RequestItem";

export default memo(RequestItem);
