import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { memo } from "react";
import { localStorageKeychainKeyId } from "services/keychain";
import { UnauthorizedDialog } from "components";

const KeychainError = memo(({ message }: { message: string | undefined }) => {
  const { logout } = useAuth0();
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <UnauthorizedDialog
        error={
          message || "The Keychain setup verification failed for this user."
        }
        onClose={() => {
          localStorage.removeItem(localStorageKeychainKeyId);
          logout({ logoutParams: { returnTo: window.location.origin } });
        }}
        open={true}
      />
    </Box>
  );
});

KeychainError.displayName = "KeychainError";

export default KeychainError;
