import {
  useAddRequestParticipantMutation,
  useDeleteRequestParticipantMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftAnalystPermission,
  type DraftParticipant,
  type PublishedAnalystPermission,
} from "@decentriq/graphql/dist/types";
import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  styled,
  Typography,
} from "@mui/material";
import { difference } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useRequest } from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import useRequestAnalysts from "./useRequestAnalysts";

interface RequestAnalystsProps {
  requestId: string;
}

const StyledMenuItem = styled(MenuItem)({
  disabled: {
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    opacity: "1 !important",
  },
});

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": { color: theme.palette.primary.main },
}));

const RequestAnalysts: React.FC<RequestAnalystsProps> = ({ requestId }) => {
  const { computeNodeId } = useRequest();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [addRequestParticipant] = useAddRequestParticipantMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(error, "Participant could not be added.")
      );
    },
  });
  const [deleteRequestParticipant] = useDeleteRequestParticipantMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(error, "Participant could not be removed.")
      );
    },
  });
  const { participants, permissions, readonly } =
    useRequestAnalysts(computeNodeId);
  const initialValue: {
    value: string;
    title: string;
    participantId: string;
  }[] = permissions.map((p) => {
    const participant = (
      p as DraftAnalystPermission | PublishedAnalystPermission
    )?.participant;
    return {
      participantId: (participant as DraftParticipant).id,
      title: participant.userEmail,
      value: participant.userEmail,
    };
  });
  const [checkedPermissions, setCheckedPermissions] = useState<string[]>(
    Array.from(new Set(initialValue.map((p) => p.value)))
  );
  const options = participants
    .map((node) => ({
      title: node.userEmail,
      value: node.userEmail,
    }))
    .filter((o) => !readonly || checkedPermissions.includes(o.value));
  const checkedParticipants = initialValue.map((p) => p.value).join();
  useEffect(() => {
    setCheckedPermissions((s) => {
      const localCheckedParticipants = s.join();
      if (localCheckedParticipants !== checkedParticipants) {
        return checkedParticipants.split(",");
      }
      return s;
    });
  }, [checkedParticipants, setCheckedPermissions]);
  const dataKey = "participant";
  const handleSelectChange = useCallback(
    ({ target }: SelectChangeEvent<string[]>) => {
      if (readonly) return;
      const { value } = target;
      if (value.includes("all")) {
        setCheckedPermissions(
          checkedPermissions.length === options.length
            ? []
            : options.map(({ value }) => value)
        );
        return;
      }
      const updatedValue = typeof value === "string" ? value.split(",") : value;
      setCheckedPermissions(updatedValue);
    },
    [checkedPermissions.length, options, readonly]
  );
  const handleSelectAll = useCallback(() => {
    const permissionsValues = options.map(({ value }) => value);
    if (checkedPermissions.length !== options.length) {
      const addPermissions = difference(permissionsValues, checkedPermissions);
      addPermissions.forEach((value, index) => {
        addRequestParticipant({
          awaitRefetchQueries: index === addPermissions.length - 1,
          refetchQueries:
            index === addPermissions.length - 1
              ? ["RequestComputeNodePermissions"]
              : undefined,
          variables: {
            input: {
              permissions: [
                {
                  analyst: {
                    draftNodeId: computeNodeId,
                  },
                },
              ],
              userEmail: value,
            },
            requestId,
          },
        });
      });
    } else {
      options.forEach(({ value }, index) => {
        const participantId = initialValue.find(
          (p) => p.value === value
        )?.participantId;
        if (participantId) {
          deleteRequestParticipant({
            awaitRefetchQueries: index === options.length - 1,
            refetchQueries:
              index === options.length - 1
                ? ["RequestComputeNodePermissions"]
                : undefined,
            variables: {
              id: initialValue.find((p) => p.value === value)?.participantId!,
            },
          });
        }
      });
    }
  }, [
    checkedPermissions,
    options,
    addRequestParticipant,
    computeNodeId,
    requestId,
    deleteRequestParticipant,
    initialValue,
  ]);
  return (
    <Box>
      <Typography variant="body2">
        <strong>Analyst permissions:</strong>
      </Typography>
      <Select
        data-testid={`permissions_${dataKey}s`}
        displayEmpty={true}
        fullWidth={true}
        multiple={true}
        onChange={handleSelectChange}
        renderValue={(selected) => {
          const selectedPermissions = options
            .filter(({ value: id }) => selected.includes(id))
            .map(({ title }) => title);
          return selectedPermissions.length === 1
            ? selectedPermissions[0]
            : `${selectedPermissions.length} ${dataKey}s`;
        }}
        size="small"
        style={{ background: "transparent", width: "300px" }}
        value={checkedPermissions}
        variant="standard"
      >
        {options.length > 1 && !readonly ? (
          <StyledMenuItem
            disabled={readonly}
            onClick={handleSelectAll}
            value="all"
          >
            <StyledCheckbox
              checked={options.length === checkedPermissions.length}
              disabled={readonly}
            />
            <ListItemText primary={"Select all"} />
          </StyledMenuItem>
        ) : null}
        {options.map(({ value, title }) => (
          <StyledMenuItem
            key={value}
            onClick={() => {
              if (readonly) return;
              if (checkedPermissions.includes(value)) {
                deleteRequestParticipant({
                  awaitRefetchQueries: true,
                  refetchQueries: ["RequestComputeNodePermissions"],
                  variables: {
                    id: initialValue.find((p) => p.value === value)
                      ?.participantId!,
                  },
                });
              } else {
                addRequestParticipant({
                  awaitRefetchQueries: true,
                  refetchQueries: ["RequestComputeNodePermissions"],
                  variables: {
                    input: {
                      permissions: [
                        {
                          analyst: {
                            draftNodeId: computeNodeId,
                          },
                        },
                      ],
                      userEmail: value,
                    },
                    requestId,
                  },
                });
              }
            }}
            value={value}
          >
            <StyledCheckbox
              checked={checkedPermissions.includes(value)}
              disabled={readonly}
            />
            <ListItemText primary={title} />
          </StyledMenuItem>
        ))}
      </Select>
    </Box>
  );
};

RequestAnalysts.displayName = "RequestAnalysts";

export default memo(RequestAnalysts);
