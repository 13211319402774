import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, type Theme, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { memo } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { useCopyToClipboard } from "hooks";

const useTooltipStyles = makeStyles()({
  tooltip: {
    maxWidth: "none",
  },
});

const useTooltipWrapperStyles = makeStyles()({
  tooltipWrapper: {
    alignItems: "center",
    display: "flex",
  },
});

interface TooltipWrapperProps {
  children?: React.ReactNode;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = memo(({ children }) => {
  const {
    classes: { tooltipWrapper },
  } = useTooltipWrapperStyles();
  return <div className={tooltipWrapper}>{children}</div>;
});

const useTooltipButtonStyles = makeStyles()((theme: Theme) => ({
  tooltipId: {
    fontFamily: "SFMono-Normal, Consolas, Liberation Mono, Menlo, monospace",
    lineHeight: "1rem",
    margin: theme.spacing(-0.5, -1),
    minWidth: 0,
    padding: theme.spacing(0.5, 0.75),
    textAlign: "left",
    whiteSpace: "nowrap",
  },
}));

const TooltipButton = memo<{
  text: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
}>(({ text, onClick }) => {
  const {
    classes: { tooltipId },
  } = useTooltipButtonStyles();
  return (
    <Button className={tooltipId} color="inherit" onClick={onClick}>
      {text}
    </Button>
  );
});

const useLinkIdStyles = makeStyles()((theme: Theme) => ({
  linkId: {
    "&:hover": {
      opacity: 1,
    },
    borderRadius: theme.shape.borderRadius,
    fontFamily: "SFMono-Normal, Consolas, Liberation Mono, Menlo, monospace",
    lineHeight: "1em",
    opacity: 0.5,
    transition: "all 0.2s linear",
  },
  linkWrapper: {
    "&:hover": {
      textDecoration: "underline",
    },
    alignItems: "baseline",
    color: "inherit",
    display: "flex",
    textDecoration: "none",
  },
}));

const LinkId = memo<{ id: string; href: string }>(({ id, href }) => {
  const {
    classes: { linkWrapper, linkId },
  } = useLinkIdStyles();
  return (
    <NavLink className={linkWrapper} to={href}>
      <span>&#8203;</span>
      <div className={linkId}>{id?.substring(0, 8)}</div>
    </NavLink>
  );
});

const usePlainIdStyles = makeStyles()((theme: Theme, { clickable }) => ({
  plainId: {
    "&:hover": {
      opacity: 1,
    },
    borderRadius: theme.shape.borderRadius,
    cursor: clickable ? "pointer" : "default",
    fontFamily: "SFMono-Normal, Consolas, Liberation Mono, Menlo, monospace",
    lineHeight: "1em",
    opacity: 0.5,
    transition: "all 0.2s linear",
  },
  plainWrapper: {
    alignItems: "baseline",
    display: "flex",
  },
}));

const PlainId = memo<{ id: string; clickable?: boolean }>(
  ({ id, clickable }) => {
    const {
      classes: { plainWrapper, plainId },
    } = usePlainIdStyles({ clickable });
    return (
      <div className={plainWrapper}>
        <span>&#8203;</span>
        <div className={plainId}>{id?.substring(0, 8)}</div>
      </div>
    );
  }
);

const Id = memo<{
  id?: string;
  idTooltip?: React.ReactNode;
  href?: string;
  copyBehaviour?: "tooltip" | "content" | "both";
}>(({ id = "", idTooltip = "", href, copyBehaviour = "tooltip" }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes: tooltipClasses } = useTooltipStyles();
  const [, copyToClipboard] = useCopyToClipboard();
  const tooltipButtonText = idTooltip || id;
  const onTooltipButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    copyToClipboard(id);
    enqueueSnackbar(
      <div style={{ alignItems: "center", display: "flex" }}>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCopy}
          style={{ marginRight: "0.5rem" }}
        />
        Copied to clipboard
      </div>
    );
  };
  const copyFromTooltip =
    copyBehaviour === "both" || copyBehaviour === "tooltip";
  const copyFromContent =
    (copyBehaviour === "both" || copyBehaviour === "content") && !href;
  return id ? (
    <Tooltip
      classes={tooltipClasses}
      disableFocusListener={true}
      disableTouchListener={true}
      enterDelay={500}
      enterNextDelay={250}
      placement="top"
      title={
        <TooltipWrapper>
          <TooltipButton
            onClick={copyFromTooltip ? onTooltipButtonClick : undefined}
            text={tooltipButtonText}
          />
        </TooltipWrapper>
      }
    >
      <div onClick={copyFromContent ? onTooltipButtonClick : undefined}>
        {href ? (
          <LinkId href={href} id={id} />
        ) : (
          <PlainId clickable={copyFromContent} id={id} />
        )}
      </div>
    </Tooltip>
  ) : null;
});

export default Id;
