import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { memo, useCallback } from "react";
import { MediaDataRoomSummary } from "features/mediaDataRoom";
import { LookalikeMediaDataRoomSummary } from "features/mediaDataRoomV2";
import { DataRoomTypeNames } from "models";

interface MediaDataRoomViewConfigurationDialogProps {
  id: string;
  [key: string]: any;
  typename: DataRoomTypeNames;
  driverAttestationHash?: string;
}

const MediaDataRoomViewConfigurationDialog: React.FC<MediaDataRoomViewConfigurationDialogProps> =
  memo(({ id, open, onCancel, typename, driverAttestationHash }) => {
    const cancelOnClick = useCallback(() => {
      onCancel();
    }, [onCancel]);
    const cancelLabel = "Close";
    const cancelProps = {
      color: "inherit" as "inherit",
      onClick: cancelOnClick,
      variant: "text" as "text",
    };
    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open}>
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            {typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom &&
              "Lookalike Clean Room configuration"}
            {typename === DataRoomTypeNames.PublishedMediaDataRoom &&
              "Top-Affinity & Remarketing Clean Room configuration"}
          </span>
          <IconButton color="inherit" onClick={onCancel}>
            <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box p={1}>
            {typename === DataRoomTypeNames.PublishedMediaDataRoom && (
              <MediaDataRoomSummary id={id} open={open} />
            )}
            {typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom && (
              <LookalikeMediaDataRoomSummary
                driverAttestationHash={driverAttestationHash!}
                id={id}
                open={open}
              />
            )}
          </Box>
          <Box
            sx={{
              borderColor: "divider",
              borderTop: 1,
              display: "flex",
              justifyContent: "space-between",
              mt: "auto",
              p: 1,
            }}
          >
            <Button {...cancelProps}>{cancelLabel}</Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  });

export default MediaDataRoomViewConfigurationDialog;
