import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PublishedDataRoom } from "containers";
import { type DataRoomContextValue, DataRoomProvider } from "contexts";
import { Main } from "layouts";

const PublishedDataRoomPage = () => {
  const { dataRoomId } = useParams();
  const [contextValue, setContextValue] = useState<DataRoomContextValue>({
    allowTestMode: true,
    dataRoomId: dataRoomId || "__default__",
    isPublished: true,
  });
  useEffect(() => {
    setContextValue((state) => ({
      ...state,
      dataRoomId: dataRoomId || "__default__",
    }));
  }, [dataRoomId, setContextValue]);
  const setAllowTestMode = useCallback(
    (allowTestMode: boolean) => {
      setContextValue((state) => ({ ...state, allowTestMode }));
    },
    [setContextValue]
  );
  return (
    <Main>
      <DataRoomProvider value={contextValue}>
        <PublishedDataRoom
          dataRoomId={dataRoomId!}
          setAllowTestMode={setAllowTestMode}
        />
      </DataRoomProvider>
    </Main>
  );
};

export default PublishedDataRoomPage;
