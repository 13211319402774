import { type ApiCoreSessionManager, data_science } from "@decentriq/core";
import {
  GetComputeJobEnclaveHandleDocument,
  type GetComputeJobEnclaveHandleQuery,
  type GetComputeJobEnclaveHandleQueryVariables,
  type MutationRetrieveComputeJobResultArgs,
} from "@decentriq/graphql/dist/types";
import * as forge from "node-forge";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";
import maybeUseDataRoomSecret from "../maybeUseDataRoomSecret/maybeUseDataRoomSecret";

export async function fetchJobResult(
  args: MutationRetrieveComputeJobResultArgs,
  context: LocalResolverContext,
  sessionManager: ApiCoreSessionManager
): Promise<Uint8Array> {
  const { dcrHash, driverAttestationHash, jobId, computeNodeId } = args.input;
  const sdkSession = await sessionManager.get({
    driverAttestationHash,
  });
  await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
  const dataScienceDataRoom =
    await sdkSession.retrieveDataScienceDataRoom(dcrHash);
  const wrapper = data_science.createDataScienceDataRoomWrapper(
    dcrHash,
    dataScienceDataRoom!,
    sdkSession
  );
  const computeJobEnclaveHandleEncoded = (
    await context.client.query<
      GetComputeJobEnclaveHandleQuery,
      GetComputeJobEnclaveHandleQueryVariables
    >({
      query: GetComputeJobEnclaveHandleDocument,
      variables: {
        id: jobId,
      },
    })
  ).data.computeJob.enclaveComputeJobHandleBase64;
  const computeJobEnclaveHandle: data_science.DataScienceJobId = JSON.parse(
    new TextDecoder().decode(
      forge.util.binary.base64.decode(computeJobEnclaveHandleEncoded)
    )
  );
  const result = await wrapper.fetchJobResult(computeJobEnclaveHandle);
  const chunkedNodeResult = result.get(computeNodeId);
  if (chunkedNodeResult === undefined) {
    throw new Error(`No result returned for node with id ${computeNodeId}`);
  } else {
    const data = await data_science.concatenateChunks(chunkedNodeResult);
    return data;
  }
}
