import {
  useDraftMatchNodeAddDependencyMutation,
  useDraftMatchNodeConfigQuery,
  useDraftMatchNodeDependenciesQuery,
  useDraftMatchNodeRemoveDependencyMutation,
  useDraftMatchNodeUpdateConfigMutation,
  usePublishedMatchNodeConfigQuery,
  usePublishedMatchNodeDependenciesQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftMatchNode,
  type DraftMatchNodeConfigQuery,
  type DraftNodeConnection,
  type DraftTableLeafNode,
  type DraftTableLeafNodeColumn,
  type PublishedMatchNode,
  type PublishedMatchNodeConfigQuery,
  type PublishedNode,
  type PublishedNodeConnection,
  type PublishedTableLeafNode,
  type PublishedTableLeafNodeColumn,
} from "@decentriq/graphql/dist/types";
import { useCallback, useEffect, useMemo } from "react";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
  useNodes,
} from "hooks";

enum MatchComputeNodeDataSourceTypename {
  DraftSqlNode = "DraftSqlNode",
  DraftTableLeafNode = "DraftTableLeafNode",
}

type MatchComputeNodeDataSource = {
  __typename: MatchComputeNodeDataSourceTypename;
  id: string;
  name: string;
  columns: (PublishedTableLeafNodeColumn | DraftTableLeafNodeColumn)[];
  updatedAt?: string;
};

export type useMatchNodeDataSourcesProps = (id: string) => {
  availableDataSources: MatchComputeNodeDataSource[];
  selectedLeftDataSourceNodeId?: string;
  selectedRightDataSourceNodeId?: string;
  setSelectedLeftDataSourceNodeId: (id: string) => void;
  setSelectedRightDataSourceNodeId: (id: string) => void;
  config: any;
  updateConfig: any;
};

const useMatchNodeDataSources: useMatchNodeDataSourcesProps = (id) => {
  const { dataRoomId, isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const isInteractivityContext =
    executionContext === "development" || executionContext === "requests";
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  const { nodes, loading: nodesLoading } = useNodes();
  const dataNodes = nodes.filter(
    ({ __typename }) =>
      __typename === "DraftTableLeafNode" ||
      __typename === "PublishedTableLeafNode"
  );
  const dataSources = useMemo(
    () => [
      ...dataNodes.map(
        (node) =>
          ({
            __typename: node?.__typename,
            columns:
              node?.__typename === "PublishedTableLeafNode"
                ? (node as PublishedTableLeafNode)?.columns || []
                : (node as DraftTableLeafNode)?.columns?.nodes || [],
            id: node?.id,
            name: node?.name,
          }) as MatchComputeNodeDataSource
      ),
    ],
    [dataNodes]
  );
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const {
    data: draftMatchNodeConfigData,
    loading: draftMatchNodeConfigLoading,
  } = useDraftMatchNodeConfigQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Matching settings could not be retrieved."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { id },
  });
  const {
    data: publishedMatchNodeConfigData,
    loading: publishedMatchNodeConfigLoading,
  } = usePublishedMatchNodeConfigQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "Matching settings could not be retrieved."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, dcrHash, driverAttestationHash, id },
  });
  const matchNodeConfigData = shouldUseDraft
    ? (draftMatchNodeConfigData as DraftMatchNodeConfigQuery)
    : (publishedMatchNodeConfigData as PublishedMatchNodeConfigQuery);
  const matchNodeConfigLoading = shouldUseDraft
    ? draftMatchNodeConfigLoading
    : publishedMatchNodeConfigLoading;
  const __typename = (
    shouldUseDraft
      ? ((matchNodeConfigData as DraftMatchNodeConfigQuery)
          ?.draftNode as DraftMatchNode)
      : ((matchNodeConfigData as PublishedMatchNodeConfigQuery)
          ?.publishedNode as PublishedMatchNode)
  )?.__typename;
  const leftDataSourceNodeId = (
    shouldUseDraft
      ? ((matchNodeConfigData as DraftMatchNodeConfigQuery)
          ?.draftNode as DraftMatchNode)
      : ((matchNodeConfigData as PublishedMatchNodeConfigQuery)
          ?.publishedNode as PublishedMatchNode)
  )?.config?.leftDataSourceNodeId;
  const rightDataSourceNodeId = (
    shouldUseDraft
      ? ((matchNodeConfigData as DraftMatchNodeConfigQuery)
          ?.draftNode as DraftMatchNode)
      : ((matchNodeConfigData as PublishedMatchNodeConfigQuery)
          ?.publishedNode as PublishedMatchNode)
  )?.config?.rightDataSourceNodeId;
  const { data: draftMatchNodeDependenciesData } =
    useDraftMatchNodeDependenciesQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "Matching data sources could not be retrieved."
          )
        );
      },
      skip: !shouldUseDraft,
      variables: { id },
    });
  const { data: publishedMatchNodeDependenciesData } =
    usePublishedMatchNodeDependenciesQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Matching data sources could not be retrieved."
          )
        );
      },
      skip: shouldUseDraft,
      variables: { commitId, dcrHash, driverAttestationHash, id },
    });
  const dependencies = useMemo(
    () =>
      shouldUseDraft
        ? (
            draftMatchNodeDependenciesData?.draftNode as DraftMatchNode
          )?.dependencies?.nodes?.map(
            (node) =>
              (node as DraftNodeConnection)?.draftNode?.id ||
              (node as PublishedNodeConnection).computeNodeId
          ) || []
        : (
            publishedMatchNodeDependenciesData?.publishedNode as PublishedMatchNode
          )?.dependencies?.map((node) => (node as PublishedNode).id) || [],
    [
      draftMatchNodeDependenciesData?.draftNode,
      publishedMatchNodeDependenciesData?.publishedNode,
      shouldUseDraft,
    ]
  );
  const [addDependency] = useDraftMatchNodeAddDependencyMutation();
  const [removeDependency] = useDraftMatchNodeRemoveDependencyMutation();
  const setDependencies = useCallback(
    (newDependencies: string[]) => {
      dependencies.forEach((computeNodeId) => {
        if (!newDependencies.includes(computeNodeId)) {
          removeDependency({
            // NOTE: Might want to add `optimisticResponse` here
            variables: {
              dependencyId: isInteractivityContext
                ? {
                    published: {
                      computeNodeId,
                      publishedDataRoomId: dataRoomId,
                    },
                  }
                : { draft: computeNodeId },
              id,
            },
          });
        }
      });
      newDependencies.forEach((computeNodeId) => {
        if (!dependencies.includes(computeNodeId)) {
          addDependency({
            // NOTE: Might want to add `optimisticResponse` here
            variables: {
              dependencyId: isInteractivityContext
                ? {
                    published: {
                      computeNodeId,
                      publishedDataRoomId: dataRoomId,
                    },
                  }
                : { draft: computeNodeId },
              id,
            },
          });
        }
      });
    },
    [
      addDependency,
      dataRoomId,
      dependencies,
      id,
      isInteractivityContext,
      removeDependency,
    ]
  );
  const [matchNodeUpdateConfigMutation] = useDraftMatchNodeUpdateConfigMutation(
    {
      onError: (error) => {
        enqueueSnackbar("Matching settings could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
    }
  );
  const setSelectedLeftDataSourceNodeId = (computeNodeId: string) => {
    // TODO: If `config.query` mentions dependency that is being removed, then bust `config.query` as well?
    const config = {
      ...(
        (matchNodeConfigData as DraftMatchNodeConfigQuery)
          ?.draftNode as DraftMatchNode
      )?.config,
      leftDataSourceNodeId: computeNodeId,
    };
    matchNodeUpdateConfigMutation({
      optimisticResponse: {
        draftMatchNode: {
          __typename: "DraftMatchNodeMutations",
          update: {
            __typename: "DraftMatchNode",
            config,
            id,
          },
        },
      },
      variables: {
        config,
        id,
      },
    }).then(() =>
      setDependencies([computeNodeId, rightDataSourceNodeId].filter(Boolean))
    );
  };
  const setSelectedRightDataSourceNodeId = (computeNodeId: string) => {
    // TODO: If `config.query` mentions dependency that is being removed, then bust `config.query` as well?
    const config = {
      ...(
        (matchNodeConfigData as DraftMatchNodeConfigQuery)
          ?.draftNode as DraftMatchNode
      )?.config,
      rightDataSourceNodeId: computeNodeId,
    };
    matchNodeUpdateConfigMutation({
      optimisticResponse: {
        draftMatchNode: {
          __typename: "DraftMatchNodeMutations",
          update: {
            __typename: "DraftMatchNode",
            config,
            id,
          },
        },
      },
      variables: {
        config,
        id,
      },
    }).then(() =>
      setDependencies([leftDataSourceNodeId, computeNodeId].filter(Boolean))
    );
  };
  // NOTE: Resetting the config if any of the dependencies and/or columns were removed
  const resetConfig = useCallback(
    () =>
      matchNodeUpdateConfigMutation({
        optimisticResponse: {
          draftMatchNode: {
            __typename: "DraftMatchNodeMutations",
            update: {
              __typename: "DraftMatchNode",
              config: "",
              id,
            },
          },
        },
        variables: {
          config: "",
          id,
        },
      }).then(() => setDependencies([])),
    [id, matchNodeUpdateConfigMutation, setDependencies]
  );
  const config = (
    shouldUseDraft
      ? ((matchNodeConfigData as DraftMatchNodeConfigQuery)
          ?.draftNode as DraftMatchNode)
      : ((matchNodeConfigData as PublishedMatchNodeConfigQuery)
          ?.publishedNode as PublishedMatchNode)
  )?.config;
  const query = (
    shouldUseDraft
      ? ((matchNodeConfigData as DraftMatchNodeConfigQuery)
          ?.draftNode as DraftMatchNode)
      : ((matchNodeConfigData as PublishedMatchNodeConfigQuery)
          ?.publishedNode as PublishedMatchNode)
  )?.config?.query;
  const idPairs = JSON.stringify(query)
    ?.match(/"var":"(?<id>.*?)"/g)
    ?.map((str) => JSON.parse(str.split(":")[1]).split("."));
  useEffect(() => {
    if (__typename === "DraftMatchNode") {
      if (!nodesLoading) {
        if (!matchNodeConfigLoading) {
          if (dataSources) {
            if (config) {
              if (idPairs) {
                for (const idPair of idPairs) {
                  const [dataSourceNodeId, columnId] = idPair;
                  const dataSourceNodeIdExists = dataSources.some(
                    ({ id }) => id === dataSourceNodeId
                  );
                  const columnIdExists = dataSources.some(({ columns }) =>
                    columns.some(({ name }) => name === columnId)
                  );
                  if (!dataSourceNodeIdExists || !columnIdExists) {
                    resetConfig();
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [
    __typename,
    config,
    dataSources,
    idPairs,
    matchNodeConfigLoading,
    nodesLoading,
    resetConfig,
  ]);
  return {
    availableDataSources: dataSources,
    config,
    selectedLeftDataSourceNodeId: leftDataSourceNodeId,
    selectedRightDataSourceNodeId: rightDataSourceNodeId,
    setSelectedLeftDataSourceNodeId,
    setSelectedRightDataSourceNodeId,
    updateConfig: matchNodeUpdateConfigMutation,
  };
};

export default useMatchNodeDataSources;
