import { type Reference } from "@apollo/client";
import {
  DraftNodeIdDocument,
  useDatasetManifestHashLazyQuery,
  useDeleteDatasetMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftNodeIdQuery,
  PublishedNodeIdDocument,
  type PublishedNodeIdQuery,
} from "@decentriq/graphql/dist/types";
import { useCallback } from "react";
import { KeychainItemKind } from "services/keychain/types";
import {
  useDataRoom,
  useKeychainService,
  usePublishedDataRoom,
} from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { logWarning } from "utils";

interface DeleteDatasetHookPayload {
  manifestHash: string;
  testing?: {
    dataNodeId: string;
  };
}

type DeleteDatasetHookResult = [() => Promise<boolean>, boolean];

const useDeleteDataset = ({
  manifestHash,
  testing,
}: DeleteDatasetHookPayload): DeleteDatasetHookResult => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const [deleteDatasetMutation, { loading: deleting }] =
    useDeleteDatasetMutation();
  const [fetchDatasetExec, { loading: fetching }] =
    useDatasetManifestHashLazyQuery({
      fetchPolicy: "network-only",
      variables: {
        manifestHash,
      },
    });
  const { keychain } = useKeychainService();
  const deleteDataset = useCallback(
    async () =>
      fetchDatasetExec()
        .then(async ({ data }) => {
          if (!data?.datasetByManifestHash) {
            throw Error("Dataset is missing");
          }
          const {
            publications,
            id: datasetId,
            manifestHash,
          } = data!.datasetByManifestHash!;
          try {
            await deleteDatasetMutation({
              update: (cache) => {
                cache.evict({
                  id: cache.identify({
                    __typename: "Dataset",
                    id: datasetId,
                  }),
                });
                cache.gc();
                cache.modify({
                  fields: {
                    datasets: (existing = {}, { canRead }) => ({
                      nodes: (existing?.nodes || []).filter(
                        (datasetRef: Reference) => canRead(datasetRef)
                      ),
                    }),
                  },
                });
                const nodeRefs: string[] = [];
                publications?.nodes?.forEach(
                  ({ leaf, dataRoom: { id, driverAttestationHash } }) => {
                    if (!leaf) {
                      return;
                    }
                    const computeNodeId = (
                      leaf.computeNodeId as string
                    ).replace("_leaf", "");
                    const cachedNode = cache.readQuery<PublishedNodeIdQuery>({
                      query: PublishedNodeIdDocument,
                      variables: {
                        commitId: leaf.commitId,
                        dcrHash: id,
                        driverAttestationHash,
                        nodeId: computeNodeId,
                      },
                    });
                    if (!cachedNode) {
                      return;
                    }
                    const ref = cache.identify({
                      __typename: cachedNode?.publishedNode?.__typename,
                      commitId: leaf.commitId,
                      dcrHash: id,
                      driverAttestationHash,
                      id: computeNodeId,
                    });
                    nodeRefs.push(ref!);
                  }
                );
                if (testing) {
                  const cachedNode = isPublished
                    ? cache.readQuery<PublishedNodeIdQuery>({
                        query: PublishedNodeIdDocument,
                        variables: {
                          commitId: null,
                          dcrHash,
                          driverAttestationHash,
                          nodeId: testing.dataNodeId,
                        },
                      })
                    : cache.readQuery<DraftNodeIdQuery>({
                        query: DraftNodeIdDocument,
                        variables: {
                          id: testing.dataNodeId,
                        },
                      });
                  const __typename = isPublished
                    ? (cachedNode as PublishedNodeIdQuery | null)?.publishedNode
                        .__typename
                    : (cachedNode as DraftNodeIdQuery | null)?.draftNode
                        .__typename;
                  if (!cachedNode) {
                    return;
                  }
                  const ref = isPublished
                    ? cache.identify({
                        __typename,
                        commitId: null,
                        dcrHash,
                        driverAttestationHash,
                        id: testing.dataNodeId,
                      })
                    : cache.identify({
                        __typename,
                        id: testing.dataNodeId,
                      });
                  nodeRefs.push(ref!);
                }
                nodeRefs.forEach((ref) =>
                  cache.modify({
                    fields: {
                      ...(isPublished && !testing
                        ? {
                            dataset: (existing = {}) => {
                              return null;
                            },
                          }
                        : {}),
                      ...(isPublished && testing
                        ? {
                            testDataset: (existing = {}) => {
                              return null;
                            },
                          }
                        : {}),
                      ...(!isPublished && testing
                        ? {
                            testModePublication: (existing = {}) => {
                              return null;
                            },
                          }
                        : {}),
                    },
                    id: ref,
                  })
                );
              },
              variables: {
                input: {
                  datasetId,
                  manifestHash: manifestHash,
                  publications: publications?.nodes || [],
                },
              },
            });
            try {
              await keychain.removeItems([
                {
                  id: manifestHash,
                  kind: KeychainItemKind.Dataset,
                },
                {
                  id: manifestHash,
                  kind: KeychainItemKind.DatasetMetadata,
                },
              ]);
            } catch (e) {
              logWarning(
                `Failed to delete Keychain item or it doesn't exist id: '${manifestHash}' ${KeychainItemKind.Dataset} error: ${e}`
              );
            }
            enqueueSnackbar(`Dataset has been successfully deleted.`);
            return true;
          } catch (error) {
            enqueueSnackbar(
              ...mapErrorToGeneralSnackbar(
                error,
                `Dataset could not be deleted.`
              )
            );
            return false;
          }
        })
        .catch((error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(error, `Dataset could not be deleted.`)
          );
          return false;
        }),
    [
      fetchDatasetExec,
      deleteDatasetMutation,
      enqueueSnackbar,
      keychain,
      isPublished,
      dcrHash,
      driverAttestationHash,
      testing,
    ]
  );
  return [deleteDataset, fetching || deleting];
};

export default useDeleteDataset;
