import {
  type ComputeJob,
  GetComputeJobsDocument,
  type GetComputeJobsQuery,
  type GetComputeJobsQueryVariables,
  type PublishedComputationNode,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextInterface } from "contexts";
import { type LocalResolverContext } from "../../models";

export const makePublishedComputationNodeResolvers = (
  client: ApiCoreContextInterface["client"],
  sessionManager: ApiCoreContextInterface["sessionManager"],
  store: ApiCoreContextInterface["store"]
) => ({
  job: async (
    parent: Partial<PublishedComputationNode>,
    _args: any,
    context: LocalResolverContext,
    _info: any
  ): Promise<Partial<ComputeJob> | null> => {
    const { id: computeNodeId, commitId } = parent;
    const { client, dataRoomId } = context;
    const variables: GetComputeJobsQueryVariables = {
      input: {
        published: commitId
          ? {
              commitId,
              computeNodeId,
              publishedDataRoomId: dataRoomId,
            }
          : {
              computeNodeId,
              publishedDataRoomId: dataRoomId,
            },
      },
    };
    const computeJobsCollection = await client.query<
      GetComputeJobsQuery,
      GetComputeJobsQueryVariables
    >({
      query: GetComputeJobsDocument,
      variables,
    });
    const computeJob = computeJobsCollection.data.computeJobs.nodes.reduce(
      (
        previous: GetComputeJobsQuery["computeJobs"]["nodes"][number] | null,
        current
      ) => {
        if (previous) {
          return current.createdAt > previous.createdAt ? current : previous;
        } else {
          return current;
        }
      },
      null
    );
    return computeJob || null;
  },
});
