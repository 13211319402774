import { useQuery } from "@tanstack/react-query";
import { useBoolean } from "ahooks";
import { memo, useCallback, useEffect, useState } from "react";
import { useApiCore, usePublishedLookalikeMediaDataRoom } from "contexts";
import {
  useAdvertiserAudiencesOverlapInsightsData,
  usePublisherAudiencesOverlapInsightsData,
} from "features/mediaDataRoomV2/components/LookalikeMediaInsights/hooks";
import {
  LookalikeMediaOverlapInsightsProvider,
  OverlapInsightsExecutionContext,
} from "features/mediaDataRoomV2/contexts";
import { useLookalikeMediaDataRoomData } from "features/mediaDataRoomV2/contexts/lookalikeMediaDataRoomData/LookalikeMediaDataRoomData";
import {
  type ModelledSegment,
  type OverlapSegment,
} from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";

type LookalikeMediaInsightsWrapperProps = {
  executionContext: OverlapInsightsExecutionContext;
  children: React.ReactNode;
  audienceType?: string;
  reach?: number;
};

const LookalikeMediaInsightsWrapper: React.FC<
  LookalikeMediaInsightsWrapperProps
> = ({
  executionContext = OverlapInsightsExecutionContext.advertiserInsights,
  audienceType = "",
  reach,
  children,
}) => {
  const [overlapInsights, setOverlapInsights] = useState<
    ModelledSegment[] | OverlapSegment[]
  >([]);
  const [overlapInsightsIsLoading, setOverlapInsightsIsLoading] =
    useState<boolean>(false);
  const [overlapInsightsError, setOverlapInsightsError] = useState<
    string | undefined
  >();
  const { isDeactivated, driverAttestationHash } =
    usePublishedLookalikeMediaDataRoom();
  const { sessionManager } = useApiCore();

  // Dialog for Advertiser and Publisher activation tab
  const [
    isInsightsDialogOpen,
    { setTrue: openInsightsDialog, setFalse: closeInsightsDialog },
  ] = useBoolean(false);

  // TODO use session form main context
  const sessionQuery = useQuery({
    queryFn: async () => {
      return await sessionManager.get({ driverAttestationHash });
    },
    queryKey: [
      "lal-dcr-lookalike-media-insights-wrapper-session",
      driverAttestationHash,
    ],
  });

  // Data for insights tab
  const {
    insights: {
      computeResults: {
        insights: overlapInsightsData = [],
        statistics: overlapInsightsStatistics = [],
        segments: overlapInsightsSegments = [],
        withNoiseCalculation: overlapInsightsWithNoiseCalculation = false,
        totalPublisherCount: overlapInsightsTotalPublisherCount = 0,
      } = {},
      error: overlapInsightsDataError,
      status: overlapInsightsDataStatus,
      retry: retryOverlapInsightsComputeJob,
    },
  } = useLookalikeMediaDataRoomData();

  // Data for publisher audience
  const {
    insights: publisherAudiencesInsights,
    error: publisherAudiencesOverlapInsightsError,
    loading: publisherAudiencesOverlapInsightsIsLoading,
    retry: retryPublisherAudiencesOverlapInsightsComputeJob,
  } = usePublisherAudiencesOverlapInsightsData({
    session: sessionQuery.data,
    skip:
      isDeactivated ||
      executionContext !== OverlapInsightsExecutionContext.publisherInsights ||
      !isInsightsDialogOpen,
  });
  // Data for advertiser audience
  const {
    insights: advertiserAudiencesInsights,
    error: advertiserAudiencesOverlapInsightsError,
    loading: advertiserAudiencesOverlapInsightsIsLoading,
    retry: retryAdvertiserAudiencesOverlapInsightsComputeJob,
  } = useAdvertiserAudiencesOverlapInsightsData({
    session: sessionQuery.data,
    skip:
      isDeactivated ||
      executionContext !== OverlapInsightsExecutionContext.advertiserInsights ||
      !isInsightsDialogOpen,
  });

  const retry = useCallback(async () => {
    if (overlapInsightsDataError) {
      await retryOverlapInsightsComputeJob();
    }
    if (advertiserAudiencesOverlapInsightsError) {
      await retryAdvertiserAudiencesOverlapInsightsComputeJob();
    }
    if (publisherAudiencesOverlapInsightsError) {
      await retryPublisherAudiencesOverlapInsightsComputeJob();
    }
  }, [
    overlapInsightsDataError,
    advertiserAudiencesOverlapInsightsError,
    publisherAudiencesOverlapInsightsError,
    retryOverlapInsightsComputeJob,
    retryAdvertiserAudiencesOverlapInsightsComputeJob,
    retryPublisherAudiencesOverlapInsightsComputeJob,
  ]);

  useEffect(() => {
    if (isDeactivated) return;
    switch (executionContext) {
      case OverlapInsightsExecutionContext.insightsTab:
        setOverlapInsights(overlapInsightsData);
        setOverlapInsightsError(overlapInsightsDataError);
        setOverlapInsightsIsLoading(overlapInsightsDataStatus !== "COMPLETED");
        break;
      case OverlapInsightsExecutionContext.publisherInsights:
        setOverlapInsights(publisherAudiencesInsights);
        setOverlapInsightsError(publisherAudiencesOverlapInsightsError);
        setOverlapInsightsIsLoading(publisherAudiencesOverlapInsightsIsLoading);
        break;
      case OverlapInsightsExecutionContext.advertiserInsights:
        setOverlapInsights(advertiserAudiencesInsights);
        setOverlapInsightsError(advertiserAudiencesOverlapInsightsError);
        setOverlapInsightsIsLoading(
          advertiserAudiencesOverlapInsightsIsLoading
        );
        break;
    }
  }, [
    advertiserAudiencesInsights,
    advertiserAudiencesOverlapInsightsError,
    advertiserAudiencesOverlapInsightsIsLoading,
    executionContext,
    overlapInsightsData,
    overlapInsightsDataError,
    overlapInsightsDataStatus,
    publisherAudiencesInsights,
    publisherAudiencesOverlapInsightsError,
    publisherAudiencesOverlapInsightsIsLoading,
    isDeactivated,
  ]);

  return (
    <LookalikeMediaOverlapInsightsProvider
      value={{
        audienceType,
        closeInsightsDialog,
        executionContext,
        isInsightsDialogOpen,
        openInsightsDialog,
        overlapInsights,
        overlapInsightsDataStatus,
        overlapInsightsError,
        overlapInsightsIsLoading,
        overlapInsightsSegments,
        overlapInsightsStatistics,
        overlapInsightsTotalPublisherCount,
        overlapInsightsWithNoiseCalculation,
        reach,
        retry,
      }}
    >
      {children}
    </LookalikeMediaOverlapInsightsProvider>
  );
};

LookalikeMediaInsightsWrapper.displayName = "LookalikeMediaInsightsWrapper";

export default memo(LookalikeMediaInsightsWrapper);
