import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo } from "react";

interface DeleteAccountDialogProps {
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const DeleteAccountDialog: React.FC<DeleteAccountDialogProps> = ({
  open,
  loading,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }),
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={!loading ? onCancel : () => {}}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>Are you sure you want to delete your account?</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          This is an irreversible action. All your created data clean rooms will
          be deactivated and all traces of your data will be deleted from the
          platform.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="inherit"
          loading={loading}
          loadingPosition="start"
          onClick={onConfirm}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          sx={{ color: "error.main" }}
          variant="contained"
        >
          Delete account
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteAccountDialog.displayName = "DeleteAccountDialog";

export default memo(DeleteAccountDialog);
