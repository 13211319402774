import { type DataSourceType } from "@decentriq/graphql/dist/types";
import { Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo, useCallback, useEffect, useState } from "react";
import { type DataType } from "models";
import ImportExternalDataDialog from "../ImportExternalDataDialog/ImportExternalDataDialog";
import ImportLocalDataDialog from "../ImportLocalDataDialog/ImportLocalDataDialog";
import ImportTypeSelectionDialog from "../ImportTypeSelectionDialog/ImportTypeSelectionDialog";

type ImportDataDialogProps = {
  renderTrigger: (open: () => void) => React.ReactNode;
};

const ImportDataDialog: React.FC<ImportDataDialogProps> = ({
  renderTrigger,
}) => {
  const [
    isTypeSelectionOpen,
    { setTrue: openTypeSelection, setFalse: closeTypeSelection },
  ] = useBoolean(false);
  const [externalDataSourceType, setExternalDataSourceType] =
    useState<DataSourceType | null>(null);
  const [localDataSourceType, setLocalDataSourceType] =
    useState<DataType | null>(null);
  const handleImportRestarting = useCallback(() => {
    openTypeSelection();
    setExternalDataSourceType(null);
    setLocalDataSourceType(null);
  }, [openTypeSelection]);
  const handleCancel = useCallback(() => {
    closeTypeSelection();
    setExternalDataSourceType(null);
    setLocalDataSourceType(null);
  }, [closeTypeSelection, setExternalDataSourceType, setLocalDataSourceType]);
  useEffect(() => {
    if (Boolean(externalDataSourceType) || Boolean(localDataSourceType)) {
      closeTypeSelection();
    }
  }, [externalDataSourceType, localDataSourceType, closeTypeSelection]);
  return (
    <>
      {renderTrigger(openTypeSelection)}
      <Portal>
        <>
          <ImportTypeSelectionDialog
            onCancel={closeTypeSelection}
            onConfirmFromExternal={setExternalDataSourceType}
            onConfirmFromLocal={setLocalDataSourceType}
            open={isTypeSelectionOpen}
          />
          <ImportExternalDataDialog
            onCancel={handleCancel}
            open={Boolean(externalDataSourceType)}
            restartImport={handleImportRestarting}
            selectedDataSource={externalDataSourceType || undefined}
          />
          <ImportLocalDataDialog
            dataType={localDataSourceType}
            onCancel={handleCancel}
            open={Boolean(localDataSourceType)}
            restartImport={handleImportRestarting}
          />
        </>
      </Portal>
    </>
  );
};

ImportDataDialog.displayName = "ImportExternalDataDialog";

export default memo(ImportDataDialog);
