import { testIds } from "@decentriq/utils";
import { faDatabase } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

const DataPartnerPortalMenuItem: React.FC = () => (
  <SidebarMenuItem
    data-testid={testIds.sidebar.sidebarMenuItems.dataPartnerPortalButton}
    icon={faDatabase}
    label="Data Partner Portal"
    to="/data-partner-portal"
  />
);

DataPartnerPortalMenuItem.displayName = "DataPartnerPortalMenuItem";

export default memo(DataPartnerPortalMenuItem);
