import { data_connector_job } from "@decentriq/core";
import {
  type DataConnectorJob,
  type DataConnectorJobResult,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextInterface } from "contexts";
import { type LocalResolverContext } from "../../models";

export function makeDatasetExportResolvers(
  client: ApiCoreContextInterface["client"],
  _sessionManager: ApiCoreContextInterface["sessionManager"],
  _store: ApiCoreContextInterface["store"]
) {
  return {
    result: async (
      parent: Partial<DataConnectorJob>,
      _args: null,
      _context: LocalResolverContext,
      _info: any
    ): Promise<DataConnectorJobResult> => {
      if (parent.id === undefined) {
        throw new Error(
          "In order to query the result of a DatasetExport, its id needs to be available"
        );
      }

      const enclaveSpecifications = await client.getEnclaveSpecifications();
      const datasetExportId = parent.id;

      try {
        await data_connector_job.getDataConnectorJobResult({
          client,
          dataConnectorJobId: datasetExportId,
          options: {
            additionalEnclaveSpecs: enclaveSpecifications,
          },
        });
        return {
          error: null,
          success: true,
        };
      } catch (e) {
        return {
          error: e.message,
          success: false,
        };
      }
    },
  };
}
