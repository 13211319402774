import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { List, styled } from "@mui/material";
import isEqual from "lodash/isEqual";
import { memo, useCallback } from "react";

const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export const ComputesNodesList = memo(({ sortable, children }: any) => {
  const { ids = [], onIdsReorder } = sortable || {};
  const modifiers = [restrictToParentElement, restrictToVerticalAxis];
  const onDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        onIdsReorder(
          arrayMove(
            ids,
            ids.indexOf(active.id),
            ids.indexOf(over.id)
          ) as string[]
        );
      }
    },
    [ids, onIdsReorder]
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const items = ids;
  return sortable ? (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={modifiers}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <StyledList>{children}</StyledList>
      </SortableContext>
    </DndContext>
  ) : (
    <StyledList>{children}</StyledList>
  );
}, isEqual);

ComputesNodesList.displayName = "ComputesNodesList";

export default ComputesNodesList;
