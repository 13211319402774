import { useMediaDataRoomAudienceInsightsQuery } from "@decentriq/graphql/dist/hooks";
import { ActivationType } from "@decentriq/graphql/dist/types";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Fragment, memo } from "react";
import {
  AudienceInsightsAdvertiser,
  AudienceInsightsPublisher,
  AudienceInsightsStatistics,
  AudienceOverlapInsights,
} from "./components";

interface MediaDataRoomAudienceInsightsProps {
  id: string;
  [key: string]: any;
}

const MediaDataRoomAudienceInsights: React.FC<MediaDataRoomAudienceInsightsProps> =
  memo(({ id }) => {
    const { data } = useMediaDataRoomAudienceInsightsQuery({
      fetchPolicy: "cache-and-network",
      variables: { id },
    });
    const { publishedMediaDataRoom } = data || {};
    const {
      activationType,
      enableOverlapInsights,
      advertiserDatasetHash,
      publisherDatasetHash,
    } = publishedMediaDataRoom || {};
    return (
      <Fragment>
        <Grid container={true} p={2} sx={{ background: "white" }}>
          <Grid
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            md={5}
            xs={12}
          >
            <AudienceInsightsAdvertiser id={id} />
            <AudienceInsightsPublisher id={id} />
          </Grid>
          <Grid
            alignItems="flex-start"
            display="flex"
            justifyContent="flex-start"
            md={7}
            sx={({ breakpoints }) => ({
              [breakpoints.down("md")]: {
                marginTop: 4,
              },
            })}
            xs={12}
          >
            {advertiserDatasetHash && publisherDatasetHash ? (
              <AudienceInsightsStatistics id={id} />
            ) : (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flex: "1",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <Typography textAlign="center" variant="h6">
                  No data to display
                </Typography>
                <Typography textAlign="center">
                  Waiting for all parties to provide their data
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        {activationType === ActivationType.Consentless &&
        enableOverlapInsights &&
        advertiserDatasetHash &&
        publisherDatasetHash ? (
          <AudienceOverlapInsights id={id} />
        ) : null}
      </Fragment>
    );
  });

MediaDataRoomAudienceInsights.displayName = "MediaDataRoomAudienceInsights";

export default MediaDataRoomAudienceInsights;
