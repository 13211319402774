import { testIds } from "@decentriq/utils";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

const PublisherPortalMenuItem: React.FC = () => {
  return (
    <SidebarMenuItem
      data-testid={testIds.sidebar.sidebarMenuItems.publisherPortalButton}
      icon={faNewspaper}
      label="Publisher Portal"
      to="/publisherportal"
    />
  );
};

PublisherPortalMenuItem.displayName = "PublisherPortalMenuItem";

export default memo(PublisherPortalMenuItem);
