import { useArchiveOrganizationMutation } from "@decentriq/graphql/dist/hooks";
import { OrganizationFragment } from "@decentriq/graphql/dist/types";
import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

interface OrganizationArchiveDialogProps {
  open: boolean;
  organizationId: string;
  onCancel: () => void;
}

const OrganizationArchiveDialog: React.FC<OrganizationArchiveDialogProps> = ({
  open,
  organizationId,
  onCancel,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const navigate = useNavigate();

  const [archiveOrganization, { loading: isArchiveOrganizationLoading }] =
    useArchiveOrganizationMutation({
      onCompleted: () => {
        onCancel();
        enqueueSnackbar(`Organization has been successfully archived.`);
        navigate("/admin/organizations");
      },
      onError: (error) => {
        onCancel();
        enqueueSnackbar(`Organization could not be archived.`, {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          data: data?.organization?.archive?.record,
          fragment: OrganizationFragment,
          id: cache.identify({
            __typename: "Organization",
            id: organizationId,
          }),
        });
      },
      variables: {
        organizationId,
      },
    });

  return (
    <Dialog
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }),
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={onCancel}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>Are you sure you want to archive this organization?</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          This is an irreversible action. This action will remove all users
          within this organisation. This action will remove all data uploaded by
          any user of this organisation. This action will stop all data clean
          rooms created by users of this organisation, rendering them unusable
          for all participants therein.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          disabled={isArchiveOrganizationLoading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          color="inherit"
          loading={isArchiveOrganizationLoading}
          loadingPosition="start"
          onClick={archiveOrganization}
          startIcon={
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangleRegular}
            />
          }
          sx={{ color: "error.main" }}
          variant="contained"
        >
          Archive organization
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

OrganizationArchiveDialog.displayName = "OrganizationArchiveDialog";

export default memo(OrganizationArchiveDialog);
