import { type ApiCoreContextInterface } from "contexts";
import {
  makeMediaCalculateOverlapByAudienceResolver,
  makeMediaCalculateOverlapInsightsResolver,
  makeMediaCalculateStatisticsResolver,
  makeMediaRetrieveAudienceTypes,
  makeMediaRetrieveConsentlessActivatedAudiencesResolver,
  makeMediaRetrieveConsentlessAudiencesResolver,
  makeMediaRetrieveDataRoomResolver,
  makeMediaRetrieveDirectActivatedAudiencesResolver,
  makeMediaRetrieveDirectAudiencesResolver,
  makeMediaRetrievePublishedDatasetsResolver,
} from "../media";
import makeAuditLogResolver from "./auditLog";
import makeDataRoomAttestationSpecsResolver from "./dataRoomAttestationSpecs";

export const makeQueryResolvers = (
  client: ApiCoreContextInterface["client"],
  sessionManager: ApiCoreContextInterface["sessionManager"],
  store: ApiCoreContextInterface["store"]
) => ({
  auditLog: makeAuditLogResolver(client, sessionManager, store),
  dataRoomAttestationSpecs: makeDataRoomAttestationSpecsResolver(
    client,
    sessionManager,
    store
  ),
  mediaCalculateOverlapByAudience: makeMediaCalculateOverlapByAudienceResolver(
    client,
    sessionManager,
    store
  ),
  mediaCalculateOverlapInsights: makeMediaCalculateOverlapInsightsResolver(
    client,
    sessionManager,
    store
  ),
  mediaCalculateStatistics: makeMediaCalculateStatisticsResolver(
    client,
    sessionManager,
    store
  ),
  mediaRetrieveAudienceTypes: makeMediaRetrieveAudienceTypes(
    client,
    sessionManager,
    store
  ),
  mediaRetrieveConsentlessActivatedAudiences:
    makeMediaRetrieveConsentlessActivatedAudiencesResolver(
      client,
      sessionManager,
      store
    ),
  mediaRetrieveConsentlessAudiences:
    makeMediaRetrieveConsentlessAudiencesResolver(
      client,
      sessionManager,
      store
    ),
  mediaRetrieveDataRoom: makeMediaRetrieveDataRoomResolver(
    client,
    sessionManager,
    store
  ),
  mediaRetrieveDirectActivatedAudiences:
    makeMediaRetrieveDirectActivatedAudiencesResolver(
      client,
      sessionManager,
      store
    ),
  mediaRetrieveDirectAudiences: makeMediaRetrieveDirectAudiencesResolver(
    client,
    sessionManager,
    store
  ),
  mediaRetrievePublishedDatasets: makeMediaRetrievePublishedDatasetsResolver(
    client,
    sessionManager,
    store
  ),
});
