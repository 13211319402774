import { DqTable } from "@decentriq/components";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { Box, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import { type MRT_ColumnDef } from "material-react-table";
import { useCallback, useMemo } from "react";
import { Chip } from "components";
import {
  type Audience,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import { replaceDecentriqOrgName } from "features/MediaInsightsDcr/utils";
import { ActivateAudienceButton, ExportAudienceButton } from "./components";

interface MediaDataRoomAudiencesTableProps {
  audiences: Audience[];
}

const audienceTypePresentationMap = new Map<string, string>([
  ["lookalike", "Lookalike"],
  ["retarget", "Remarketing"],
  ["remarketing", "Remarketing"],
  ["exclusion_targeting", "Exclusion targeting"],
]);

const MediaDataRoomAudiencesTable: React.FC<
  MediaDataRoomAudiencesTableProps
> = ({ audiences }) => {
  const {
    isPublisher,
    isAdvertiser,
    isObserver,
    isAgency,
    publisherOrganization,
    supportedFeatures: { enableAdvertiserAudienceDownload = false },
  } = usePublishedMediaInsightsDcr();

  const hasPublisherAccessColumn = !isObserver && !isPublisher;
  const hasExportButton =
    isPublisher ||
    ((isAdvertiser || isAgency) && enableAdvertiserAudienceDownload);

  const noRecordsToDisplay = useMemo(
    () =>
      isPublisher
        ? "The advertiser has not made any audience available for activation yet."
        : "The model is ready, please create your first audience.",
    [isPublisher]
  );

  const audienceStatusLabel = useMemo(() => {
    return `Available for download${
      isPublisher
        ? ""
        : publisherOrganization?.name
          ? " by " +
            replaceDecentriqOrgName(publisherOrganization?.name, "Publisher")
          : ""
    }`;
  }, [isPublisher, publisherOrganization?.name]);

  const rowActions = useCallback(
    (row: Audience) => {
      const { audienceType, reach } = row;
      return hasExportButton ? (
        <ExportAudienceButton
          activatedAudiences={audiences}
          activationType={row.activationType}
          audienceType={audienceType}
          reach={reach}
        />
      ) : null;
    },
    [hasExportButton, audiences]
  );

  const columns = useMemo<MRT_ColumnDef<Audience>[]>(
    () => [
      {
        Cell: ({ cell, row }) => {
          let label = cell.getValue() as string;
          if (row?.original?.activationType === "lookalike") {
            label += ` - ${row?.original?.reach}%`;
          }
          return (
            <Typography sx={{ fontSize: "14px", my: "auto" }}>
              {label}
            </Typography>
          );
        },
        accessorKey: "audienceType",
        header: "Audience",
        minSize: 150,
      },
      {
        Cell: ({ cell, row }) => {
          const audienceType = cell.getValue<string>();
          return audienceType === "lookalike" &&
            row?.original?.excludeSeedAudience
            ? "Lookalike (seed audience excluded)"
            : audienceTypePresentationMap.get(audienceType) ||
                capitalize(audienceType.replace(/_/g, " "));
        },
        accessorKey: "activationType",
        header: "Type",
        minSize: 150,
      },
      {
        Cell: ({ row }) => {
          const { audienceType, reach, published, activationType } =
            row?.original || {};
          if (published) {
            return <Chip label={audienceStatusLabel} />;
          }
          return hasPublisherAccessColumn ? (
            <ActivateAudienceButton
              activationType={activationType}
              audienceType={audienceType}
              published={published}
              reach={reach}
            />
          ) : null;
        },
        header: "Publisher Access",
        id: "status",
        minSize: 200,
      },
    ],
    [audienceStatusLabel, hasPublisherAccessColumn]
  );

  return (
    <DqTable
      columns={columns}
      data={audiences}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 200,
        },
      }}
      emptyStateIcon={isPublisher ? undefined : faCircleCheck}
      enableRowActions={true}
      getRowId={(row) => `${row.audienceType}-${row.reach}`}
      initialState={{ columnVisibility: { status: hasPublisherAccessColumn } }}
      localization={{
        noRecordsToDisplay,
      }}
      muiTableBodyRowProps={{
        sx: { "&:hover td:after": { backgroundColor: "transparent" } },
      }}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
          width: "100%",
        },
      }}
      renderRowActions={({ row }) => (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {rowActions(row?.original)}
        </Box>
      )}
    />
  );
};

MediaDataRoomAudiencesTable.displayName = "MediaDataRoomAudiencesTable";

export default MediaDataRoomAudiencesTable;
