import { InfoTooltip } from "@decentriq/components";
import { Grid, MenuItem, Typography } from "@mui/material";
import { memo } from "react";
import { useFormContext } from "react-hook-form";
import AttributionItem from "./AttributionItem";
import {
  attributions,
  lookBackWindow,
  lookBackWindowWithNone,
  type MeasurementConfigurationFormValues,
} from "./model";

const AttributionSelector: React.FC = () => {
  const { watch } = useFormContext<MeasurementConfigurationFormValues>();
  const clickLookbackWindow = watch(
    "measurementConfiguration.clickLookbackWindow"
  );

  return (
    <Grid container={true} item={true} mt={1} spacing={1}>
      <Grid item={true} sx={{ pb: 0 }} xs={12}>
        <Typography fontWeight="fontWeightSemiBold" variant="body1">
          3. Click lookback and attribution
          <InfoTooltip
            tooltip={
              <>
                <p>
                  The maximum number days a click or view may be before a
                  conversion and be attributed. Clicks and views may be set
                  separately, but the view window may not be longer than the
                  click window.
                </p>
                <p>For click-only attribution, set view lookback to “None”.</p>
                <p>
                  The “Last Click” attribution rule prioritizes clicks over
                  views, all other rules treat clicks and views equally.
                </p>
              </>
            }
          />
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <AttributionItem
          attributionLabel="Click Lookback Window"
          fieldName="measurementConfiguration.clickLookbackWindow"
          options={lookBackWindow}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <AttributionItem
          attributionLabel="View Lookback Window"
          customOptions={lookBackWindowWithNone.map(({ label, value }) => {
            return (
              <MenuItem
                disabled={!!clickLookbackWindow && clickLookbackWindow < value}
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            );
          })}
          fieldName="measurementConfiguration.viewLookbackWindow"
          options={lookBackWindowWithNone}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <AttributionItem
          attributionLabel="Attribution Rule"
          fieldName="measurementConfiguration.attributionRule"
          options={attributions}
        />
      </Grid>
    </Grid>
  );
};

AttributionSelector.displayName = "AttributionSelector";

export default memo(AttributionSelector);
