import { Box } from "@mui/material";
import { ResponsiveRadar } from "@nivo/radar";
import { memo, useMemo } from "react";
import { useLookalikeMediaOverlapInsights } from "features/mediaDataRoomV2/components/LookalikeMediaInsights/hooks";
import {
  SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY,
  SHARE_IN_OVERLAP_CHART_KEY,
} from "../../constants";

const RadarChart: React.FC = () => {
  const { aggregationData, isEmpty } = useLookalikeMediaOverlapInsights();

  const chartData = useMemo(
    () =>
      aggregationData.map((data) => ({
        [SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY]:
          data.shareInAddressableAudience,
        // Keys defined as strings here as there is no way for now neither
        // to customize labels inside of a tooltip, nor to pass a custom tooltip
        // Keys defined here are being set as properties names inside of a tooltip
        [SHARE_IN_OVERLAP_CHART_KEY]: data.shareInOverlap,
        aggregationName: data.aggregationName,
      })),
    [aggregationData]
  );

  return !isEmpty ? (
    <Box sx={{ height: "calc(100vh - 700px)", minHeight: "300px" }}>
      <ResponsiveRadar
        colors={["#37C7C7", "#2FA5E6"]}
        data={chartData}
        indexBy="aggregationName"
        {...(aggregationData.length > 30 ? { gridLabel: () => null } : {})}
        keys={[
          SHARE_IN_OVERLAP_CHART_KEY,
          SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY,
        ]}
        margin={{
          bottom: 24,
          top: 32,
        }}
        valueFormat={(value) => `${((value || 0) * 100).toFixed(1)}%`}
      />
    </Box>
  ) : null;
};

RadarChart.displayName = "RadarChart";

export default memo(RadarChart);
