import { InfoTooltip } from "@decentriq/components";
import { faDownload as faDownloadRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { ResponsiveBar } from "@nivo/bar";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import { memo, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { usePublishedLookalikeMediaDataRoom } from "contexts";
import { replaceDecentriqOrgName } from "features/mediaDataRoom/utils";
import { useLookalikeMediaOverlapInsightsContext } from "features/mediaDataRoomV2/contexts";
import useLookalikeMediaOverlapStatistics from "./useLookalikeMediaOverlapStatistics";

const LookalikeMediaInsightsStatistics: React.FC = () => {
  const { palette } = useTheme();
  const { watch } = useFormContext();
  const selectedAudienceType = watch("audienceType");

  const { advertiserOrganization, publisherOrganization } =
    usePublishedLookalikeMediaDataRoom();
  const { overlapInsightsStatistics } =
    useLookalikeMediaOverlapInsightsContext();
  const { overlapStatistics, overlapPercentage } =
    useLookalikeMediaOverlapStatistics(selectedAudienceType);

  const exportInsightsStatistics = useCallback(() => {
    const fileContent = overlapInsightsStatistics.reduce(
      (csv, { audienceType, overlapSize, advertiserSize }) => {
        const matchRate = ((overlapSize / advertiserSize) * 100).toFixed(1);
        return `${csv}\n${audienceType},${overlapSize},${advertiserSize},${matchRate}%`;
      },
      "Advertiser audience,Individuals in the overlap,Total customers in audience,Match Rate"
    );
    const fileName = `Basic_overlap_${publisherOrganization?.name}_${advertiserOrganization?.name}_${format(
      new Date(),
      "dd_MM_yyyy HH_mm"
    )}.csv`;
    const file = new File([fileContent], fileName, {
      type: "application/octet-stream;charset=utf-8",
    });
    saveAs(file);
  }, [
    advertiserOrganization?.name,
    overlapInsightsStatistics,
    publisherOrganization?.name,
  ]);

  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const barData = useMemo(
    () => [
      {
        key: "total",
        value: overlapStatistics.total || 0,
      },
      {
        key: "overlap",
        value: overlapStatistics.overlap,
      },
    ],
    [overlapStatistics.total, overlapStatistics.overlap]
  );

  return (
    <Box mb={3}>
      <Grid columnSpacing={3} container={true}>
        <Grid item={true} xs={11}>
          <Typography sx={{ color: grey[700] }}>
            There are{" "}
            <span>{formatter.format(overlapStatistics?.overlap)}</span>{" "}
            individuals in the overlap. This is a match rate of{" "}
            <span>
              {(isNaN(parseFloat(overlapPercentage)) ??
              overlapStatistics?.overlap ??
              overlapStatistics?.total)
                ? "Calculating the "
                : overlapPercentage}
            </span>
            %
            <InfoTooltip tooltip="We add a small amount of noise to the counts of matched users to protect individual privacy. We sample from the Laplace Mechanism, with a scale factor of 10. This means that there is a 63% chance of adding noise between -10 and 10 to any given count of users. The chance of adding more noise falls off rapidly, there is less than a 2% chance that the noise is larger than 40." />
          </Typography>
        </Grid>
        <Grid
          alignContent="flex-start"
          container={true}
          item={true}
          justifyContent="flex-end"
          xs={1}
        >
          <Tooltip
            disableFocusListener={true}
            disableTouchListener={true}
            placement="top-start"
            title="Export match rate of all audiences to CSV"
          >
            <IconButton
              onClick={exportInsightsStatistics}
              sx={{ padding: 0.5 }}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faDownloadRegular} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Box sx={{ height: "48px", margin: "10px 0", width: "100%" }}>
        <ResponsiveBar
          animate={true}
          colors={(k) => {
            if (k.indexValue === "total") {
              return palette.primary.main;
            }
            return grey[700];
          }}
          data={barData}
          enableGridY={false}
          enableLabel={false}
          groupMode="grouped"
          indexBy="key"
          isInteractive={false}
          layout="horizontal"
          maxValue={overlapStatistics?.total}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography sx={{ color: palette.primary.main }}>
          There are <span>{formatter.format(overlapStatistics?.total)}</span>{" "}
          customers in{" "}
          <span>
            {replaceDecentriqOrgName(
              advertiserOrganization?.name,
              "Advertiser"
            )}
            's
          </span>{" "}
          <span>{` ${selectedAudienceType}`}</span> audience.
        </Typography>
      </Box>
    </Box>
  );
};

LookalikeMediaInsightsStatistics.displayName =
  "LookalikeMediaInsightsStatistics";

export default memo(LookalikeMediaInsightsStatistics);
