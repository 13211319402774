import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { type DataType } from "models";

interface DatasetDeletionDialogProps {
  open: boolean;
  mode: DatasetDeletionMode;
  dataType: DataType;
  loading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export enum DatasetDeletionMode {
  delete,
  deprovision,
  replace,
}

const DatasetDeletionDialog: React.FC<DatasetDeletionDialogProps> = ({
  open,
  loading,
  onCancel,
  onConfirm,
  dataType = "table",
  mode = DatasetDeletionMode.delete,
}) => {
  const dataIngestionLabel = dataType === "table" ? "dataset" : "file";
  const title =
    mode === DatasetDeletionMode.delete ||
    mode === DatasetDeletionMode.replace ? (
      <strong>
        Are you sure you want to{" "}
        {mode === DatasetDeletionMode.replace ? "replace" : "delete"} this{" "}
        {dataIngestionLabel}?
      </strong>
    ) : (
      <strong>
        Are you sure you want to deprovision this {dataIngestionLabel}?
      </strong>
    );
  const content =
    mode === DatasetDeletionMode.delete || mode === DatasetDeletionMode.replace
      ? `This is irreversible: all traces of your data, including all derived
        ${dataIngestionLabel}s and results will be deleted from the
        platform.`
      : `This will deprovision this {dataIngestionLabel} from this data clean
        room. The data is not deleted yet. To delete it, go to the Datasets
        page.`;
  const action =
    mode === DatasetDeletionMode.delete
      ? "Delete"
      : mode === DatasetDeletionMode.replace
        ? "Replace"
        : "Deprovision";
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={loading ? undefined : onCancel}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>{title}</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          disabled={loading}
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          data-testid="delete-dataset-button"
          loading={loading}
          loadingPosition="start"
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          {action}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DatasetDeletionDialog.displayName = "DatasetDeletionDialog";

export default memo(DatasetDeletionDialog);
