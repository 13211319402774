import { type ApiCoreSessionManager, type Client } from "@decentriq/core";
import { createContext, useContext } from "react";

export interface ApiCoreStore {
  get: <T = any>(key: string) => T | undefined;
  pop: <T = any>(key: string) => T | undefined;
  popStrict: <T = any>(key: string) => T;
  push: (key: any) => string;
  reset: () => void;
}

export interface ApiCoreContextInterface {
  client: Client;
  sessionManager: ApiCoreSessionManager;
  reset: () => void;
  store: ApiCoreStore;
}

export const ApiCoreContext = createContext<
  ApiCoreContextInterface | undefined
>(undefined);

export const ApiCoreProvider = ApiCoreContext.Provider;

export const useApiCore = () => {
  const context = useContext(ApiCoreContext);
  if (!context) {
    throw new Error("You forgot to wrap your component in <ApiCoreProvider>.");
  }
  return context;
};

export default useApiCore;
