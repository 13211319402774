import { InfoTooltip } from "@decentriq/components";
import {
  faCircleExclamation,
  faFileCirclePlus,
} from "@fortawesome/pro-light-svg-icons";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePublishedLookalikeMediaDataRoom } from "contexts";
import { getAudiencePresentation } from "features/mediaDataRoom/types";
import { EmptyStateContainer } from "features/mediaDataRoomV2";
import { useLookalikeMediaDataRoomData } from "features/mediaDataRoomV2/contexts";
import { useAdvertiserAudiencesContext } from "../../contexts/advertiserAudiences/advertiserAudiences";

interface AdvertiserAudienceGeneratorProps {
  dataRoomId: string;
}

const AdvertiserAudienceGenerator: React.FC<
  AdvertiserAudienceGeneratorProps
> = ({ dataRoomId }) => {
  const [reach, setReach] = useState<number>(1);
  const [retryInProgress, setRetryInProgress] = useState(false);
  const [selectedAudienceType, setSelectedAudienceType] = useState<
    string | undefined
  >();
  const { isDeactivated } = usePublishedLookalikeMediaDataRoom();
  const {
    audienceSizes: {
      computeResults: audienceSizes,
      status: audienceSizesStatus,
      error: audienceSizesError,
      retry: retryAudienceSizes,
    },
    insights: {
      computeResults: { statistics: overlapStatistics = [] } = {},
      status: overlapStatisticsStatus,
      error: overlapStatisticsError,
      retry: retryOverlapStatistics,
    },
  } = useLookalikeMediaDataRoomData();

  const isEmptyState = useMemo(
    () => overlapStatistics.every(({ overlapSize }) => overlapSize === 0),
    [overlapStatistics]
  );

  useEffect(() => {
    if (isDeactivated || !overlapStatistics || !audienceSizes) {
      return;
    }
    const advertiserAudienceDefaultValue = overlapStatistics.filter(
      ({ audienceType, overlapSize }) => {
        // overlapSize is filtered, when it's < 150 it is set to 0
        // This change hasn't been propagated to audienceSizes yet
        // Adding a double condition to be on the safe side
        const overlapNotEmpty =
          0 < overlapSize && 0 < (audienceSizes.get(audienceType)?.size ?? 0);
        return overlapNotEmpty;
      }
    )[0]?.audienceType;
    if (advertiserAudienceDefaultValue) {
      setSelectedAudienceType(advertiserAudienceDefaultValue);
    }
  }, [overlapStatistics, audienceSizes, isDeactivated]);
  // Set the default value of reach to the smallest available reach value
  useEffect(() => {
    setReach((currentReach) => {
      const keys = Array.from(
        audienceSizes?.get(selectedAudienceType ?? "")?.keys() ?? []
      );
      if (keys.length === 0 || keys.includes(currentReach)) {
        return currentReach;
      }
      return keys[0];
    });
  }, [selectedAudienceType, audienceSizes]);
  const handleSelectAudienceType = useCallback(
    (event: SelectChangeEvent<string>) => {
      setSelectedAudienceType(event.target.value);
    },
    []
  );
  const { addAudience } = useAdvertiserAudiencesContext();
  const mediaGenerateConsentlessAudience = useCallback(
    async () =>
      addAudience({
        audienceType: selectedAudienceType!,
        reach,
      }),
    [selectedAudienceType, addAudience, reach]
  );
  const estimatedAudienceSize = useMemo(() => {
    if (!selectedAudienceType) {
      return 0;
    }
    const audienceSizesForAudienceType =
      audienceSizes?.get(selectedAudienceType);
    const key = reach;
    return audienceSizesForAudienceType?.get(key) ?? 0;
  }, [audienceSizes, reach, selectedAudienceType]);
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  const marks = useMemo(() => {
    const keys = Array.from(
      audienceSizes?.get(selectedAudienceType ?? "")?.keys() ?? []
    );
    return keys.map((reach, i) => ({
      label: i === 0 || i === keys.length - 1 ? `${reach}%` : "",
      value: reach,
    }));
  }, [audienceSizes, selectedAudienceType]);

  const retryComputations = useCallback(async () => {
    if (isDeactivated) return;
    try {
      if (overlapStatisticsError) {
        await retryOverlapStatistics();
      }
      if (audienceSizesError) {
        await retryAudienceSizes();
      }
    } finally {
      setRetryInProgress(false);
    }
  }, [
    overlapStatisticsError,
    audienceSizesError,
    retryOverlapStatistics,
    retryAudienceSizes,
    isDeactivated,
  ]);

  if (isDeactivated || audienceSizesError || overlapStatisticsError) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography mt={1} variant="subtitle2">
          Audience data could not be retrieved
        </Typography>
        {!isDeactivated && (
          <LoadingButton
            color="inherit"
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 2 }}
          >
            Retry
          </LoadingButton>
        )}
      </EmptyStateContainer>
    );
  }

  if (isEmptyState) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography mt={1} variant="subtitle2">
          There are not enough individuals in the overlap.
        </Typography>
        <Typography variant="subtitle2">
          Please check if the Matching ID is compatible.
        </Typography>
      </EmptyStateContainer>
    );
  }

  return overlapStatisticsStatus !== "COMPLETED" ||
    audienceSizesStatus !== "COMPLETED" ? (
    <Box
      alignItems="center"
      display="flex"
      height="70"
      justifyContent="center"
      padding="1rem"
    >
      <CircularProgress color="inherit" size="1.5rem" thickness={1} />
    </Box>
  ) : (
    <Box mb={3}>
      <Grid container={true} spacing={2}>
        <Grid
          alignItems="center"
          container={true}
          item={true}
          lg={3}
          sx={{ height: 80 }}
          xs={12}
        >
          <FormControl sx={{ width: "300px" }}>
            <FormLabel component="legend">Advertiser audience</FormLabel>
            <Select
              disabled={overlapStatistics.length <= 2}
              displayEmpty={true}
              fullWidth={true}
              onChange={handleSelectAudienceType}
              renderValue={getAudiencePresentation}
              size="small"
              sx={{
                ...(overlapStatistics.length <= 2
                  ? {
                      "& .MuiSelect-icon": { display: "none" },
                      "&.Mui-disabled": { background: "white" },
                    }
                  : {}),
                background: "transparent",
              }}
              value={selectedAudienceType || ""}
              variant="standard"
            >
              {overlapStatistics.map(({ audienceType }) => {
                // overlapSize is filtered, when it's < 150 it is set to 0
                // This change hasn't been propagated to audienceSizes yet
                // Adding a double condition to be on the safe side
                const overlapStatisticsEmptyOverlap =
                  overlapStatistics.find(
                    ({ audienceType: overlapStatisticsAudienceType }) =>
                      overlapStatisticsAudienceType === audienceType
                  )?.overlapSize === 0;
                const disabled =
                  overlapStatisticsEmptyOverlap ||
                  (audienceSizes?.get(audienceType)?.size ?? 0) === 0;
                return (
                  <MenuItem
                    disabled={disabled}
                    key={audienceType}
                    value={audienceType}
                  >
                    <ListItemText
                      primary={getAudiencePresentation(audienceType)}
                      secondary={disabled ? "Overlap is too small" : ""}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          alignItems="center"
          container={true}
          direction="column"
          item={true}
          lg={6}
          md={12}
        >
          <Grid
            container={true}
            direction="row"
            display="flex"
            mb={2}
            spacing={2}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={4}
              sx={{ flex: 1, height: 70 }}
            >
              <Box>Precision</Box>
              <Slider
                marks={marks}
                max={30}
                min={1}
                onChange={(event, newReach: number | number[]) =>
                  setReach(newReach as number)
                }
                size="small"
                step={null}
                sx={{
                  "& .MuiSlider-markLabel": { top: "1.5rem" },
                  minWidth: 200,
                }}
                value={reach}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value}%`}
              />
              <Box>Reach</Box>
            </Stack>
            <InfoTooltip tooltip="The generated top affinity audiences will include segments until the estimated reach is achieved." />
            <Grid sx={{ ml: 2 }}>
              <Typography variant="body1">Estimated audience size</Typography>
              <Typography
                style={{
                  lineHeight: "150%",
                }}
              >
                {formatter.format(estimatedAudienceSize)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          alignItems="flex-start"
          container={true}
          item={true}
          justifyContent={{ lg: "flex-end", md: "flex-start" }}
          lg={3}
          md={12}
          sx={{ height: 70 }}
        >
          <Tooltip
            disableFocusListener={true}
            disableTouchListener={true}
            placement="top"
            title="This will generate an audience with segments for activation based on the selected Advertiser audience. Segments are ranked by the highest affinity ratio. Users in this audience belong to at least one segment."
          >
            <LoadingButton
              color="primary"
              disabled={!selectedAudienceType}
              loadingPosition="start"
              onClick={mediaGenerateConsentlessAudience}
              startIcon={<FontAwesomeIcon icon={faFileCirclePlus} />}
              variant="contained"
            >
              Generate
            </LoadingButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container={true} spacing={2}>
        <Grid
          // alignItems="center"
          container={true}
          item={true}
          lg={3}
          // sx={{ height: 80 }}
          xs={12}
        ></Grid>
        <Grid container={true} item={true} xs={6}>
          <Typography variant="body1">
            The Lookalike model takes all individuals from advertiser's{" "}
            <strong>{selectedAudienceType} audience</strong> that matched the
            publishers users, and ranks every publisher for similarity to that
            matched audience. This ranking is based on an AI model that groups
            people with similar reading behavior on the publisher.
          </Typography>
          {/* Long-form description - required a few additional data */}
          {/* <Typography sx={{ maxWidth: 600 }}>
              The Lookalike model takes the{" "}
              <Box component="span">
                {formatter.format(overlapSize)} overlap
              </Box>{" "}
              as a{" "}
              <Box component="span">
                seed audience
              </Box>{" "}
              and ranks similar profiles in the
              <Box component="span">
                entire publisher audience
              </Box>{" "}
              based on the available{" "}
              <Box component="span">
                {formatter.format(numProfiles)} profiles
              </Box>{" "}
              with{" "}
              <Box component="span">
                {formatter.format(numAttributes)} attributes.
              </Box>
            </Typography> */}
        </Grid>
      </Grid>
    </Box>
  );
};

AdvertiserAudienceGenerator.displayName = "AdvertiserAudienceGenerator";

export default AdvertiserAudienceGenerator;
