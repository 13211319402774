// @ts-nocheck
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, styled } from "@mui/material";
import { memo, useCallback } from "react";

const DragHandleButton = memo(({ className, ...rest }) => {
  const stopPropagation = useCallback((event) => {
    event.stopPropagation();
  }, []);
  return (
    <Button
      className={className}
      onClick={stopPropagation}
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      {...rest}
    >
      <FontAwesomeIcon icon={faGripVertical} />
    </Button>
  );
});

const StyledDragHandleButton = styled(DragHandleButton)(({ theme }) => ({
  "&:active": {
    cursor: "grabbing",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  backgroundColor: "transparent",
  color: "#999999",
  cursor: "grab",
  display: "flex",
  fontSize: "1rem",
  gap: theme.spacing(0.25),
  justifyContent: "space-between",
  marginBottom: theme.spacing(-0.25),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(-0.25),
  minWidth: theme.spacing(2),
  padding: theme.spacing(0.75, 0.5),
  width: theme.spacing(2),
}));

export default StyledDragHandleButton;
