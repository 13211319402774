import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { KeychainActions, KeychainItemsList } from "features/keychain";
import { Main } from "layouts";

const KeychainPage = memo(() => (
  <Main>
    <Box
      sx={{
        backgroundColor: "background.bar",
        display: "flex",
        justifyContent: "space-between",
        padding: 2,
      }}
    >
      <Typography variant="h6">
        <strong>Keychain</strong>
      </Typography>
      <KeychainActions />
    </Box>
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: 2,
        overflow: "hidden",
        padding: 2,
      }}
    >
      <KeychainItemsList />
    </Box>
  </Main>
));

export default KeychainPage;
