import { DqTable } from "@decentriq/components";
import { useOrganizationDataRoomDetailsQuery } from "@decentriq/graphql/dist/hooks";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { memo, useMemo } from "react";
import { AdminDialog } from "components";
import { useUserRole } from "hooks";
import DeactivateDataRoomButton from "../DeactivateDataRoomButton/DeactivateDataRoomButton";

type OrganizationDataRoomDetailsDialogProps = {
  dataRoomId: string;
  open: boolean;
  onClose: () => void;
};

const OrganizationDataRoomDetailsDialog: React.FC<
  OrganizationDataRoomDetailsDialogProps
> = ({ dataRoomId, open, onClose }) => {
  const { isSuperAdminReadOnly } = useUserRole();

  const { data } = useOrganizationDataRoomDetailsQuery({
    variables: { dataRoomId },
  });

  const {
    title,
    id,
    activatedAt,
    deactivatedAt,
    isActive,
    stoppedAt,
    owner,
    participantUsers: { nodes: dataRoomParticipants = [] } = {},
  } = data?.publishedDataRoom || {};

  const dataRoomParticipantsColumns = useMemo(
    () => [
      {
        accessorKey: "email",
        header: "Email",
        id: "email",
      },
      {
        accessorKey: "organization.name",
        header: "Organization",
        id: "organization",
      },
    ],
    []
  );

  return (
    <AdminDialog
      confirmButtonText="Close"
      maxWidth="md"
      onClose={onClose}
      onConfirm={onClose}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          "& > .MuiDialogContent-root": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
          height: "fit-content",
        },
      }}
      title="Data clean room details"
      withCancelButton={false}
    >
      <Typography sx={{ lineHeight: 1.75 }}>
        <strong>DCR name:</strong> {title || "—"}
      </Typography>
      <Typography sx={{ lineHeight: 1.75 }}>
        <strong>ID:</strong> {id || "—"}
      </Typography>
      <Typography sx={{ lineHeight: 1.75 }}>
        <strong>Activated At:</strong>{" "}
        {activatedAt ? format(new Date(activatedAt), "dd.MM.yyy, HH:mm") : "—"}
      </Typography>
      <Typography sx={{ lineHeight: 1.75 }}>
        <strong>Deactivated At:</strong>{" "}
        {deactivatedAt
          ? format(new Date(deactivatedAt), "dd.MM.yyy, HH:mm")
          : "—"}
      </Typography>
      <Typography sx={{ lineHeight: 1.75 }}>
        <strong>Stopped At:</strong>{" "}
        {stoppedAt ? format(new Date(stoppedAt), "dd.MM.yyy, HH:mm") : "—"}
      </Typography>
      <Typography sx={{ lineHeight: 1.75 }}>
        <strong>DCR Creator:</strong> {owner?.email || "—"}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px 0" }}>
        <Typography>
          <strong>Participants and Organizations:</strong>
        </Typography>
        <DqTable
          columns={dataRoomParticipantsColumns}
          data={dataRoomParticipants}
        />
      </Box>
      {isActive && !isSuperAdminReadOnly && (
        <DeactivateDataRoomButton
          closeDataRoomDetailsDialog={onClose}
          dataRoomId={dataRoomId}
        />
      )}
    </AdminDialog>
  );
};

OrganizationDataRoomDetailsDialog.displayName =
  "OrganizationDataRoomDetailsDialog";

export default memo(OrganizationDataRoomDetailsDialog);
