import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => Promise<void>;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  onCancel,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await onDelete();
      onCancel();
    } finally {
      setLoading(false);
    }
  }, [setLoading, onDelete, onCancel]);
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} role="delete-dialog">
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>
            Are you sure you want to delete the advertiser audience data?
          </strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          Deleting this data means it is no longer available to any clean room.
          This will delete any insights and the dashboard will no longer be
          viewable for all clean rooms that used this data. Any audiences
          generated through remarketing or lookalike modeling will no longer be
          available to export. Previously exported audiences will still be
          available.
          <br />
          <br />
          If you provision new data to this clean room, any insights or
          audiences will be recalculated. This may take some time, especially if
          using a lookalike audience, as the model needs to be retrained.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          disabled={loading}
          loading={loading}
          onClick={handleDelete}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteConfirmationDialog.displayName = "DeleteConfirmationDialog";

export default DeleteConfirmationDialog;
