import {
  type ApolloClient,
  type NormalizedCacheObject,
  useApolloClient,
} from "@apollo/client";
import { useSafeState } from "ahooks";
import format from "date-fns/format";
import { type lookalike_media_request } from "ddc";
import saveAs from "file-saver";
import { loadAsync } from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useState } from "react";
import { useApiCore } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { parseMediaDataRoomError } from "utils";
import {
  getLookalikeAudienceCacheKey,
  getMediaJob,
  storeMediaJob,
} from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";

interface ExportAudienceHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  audienceType: string;
  reach: number;
}

interface ExportAudienceHookResult {
  loading: boolean;
  exportAudience: () => Promise<void>;
}

const useExportAudience = ({
  dataRoomId,
  driverAttestationHash,
  audienceType,
  reach,
}: ExportAudienceHookPayload): ExportAudienceHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const { client, sessionManager } = useApiCore();
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [loading, setLoading] = useSafeState(false);
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const jobType = "LOOKALIKE_MEDIA_LOOKALIKE_AUDIENCE";

  const handleAudienceExport = useCallback(async () => {
    setErrorSnackbarId((snackbarId) => {
      if (snackbarId) {
        closeSnackbar(snackbarId);
      }
      return undefined;
    });
    setLoading(true);
    try {
      const cacheKey = await getLookalikeAudienceCacheKey({
        audienceType,
        client,
        dataRoomId,
        driverAttestationHash,
        // TODO remove this
        forceRecompute: false,
        reach,
        sessionManager,
      });
      if (cacheKey == null) {
        throw new Error(
          "Getting lookalike audience requires both the publisher and advertiser dataset uploaded  and non-empty activated audiences config published"
        );
      }
      // TODO @matyasfodor skipping LRUExpiringPromise cache for now - can be added later as needed

      try {
        const session = await sessionManager.get({
          driverAttestationHash,
        });
        const existingJob = await getMediaJob(
          session,
          apolloClient,
          cacheKey.dataRoomId,
          jobType,
          cacheKey,
          false
        );
        var result: Uint8Array;
        if (existingJob) {
          result = await session.getComputationResult(
            {
              computeNodeId: existingJob.computeNodeName,
              jobId: existingJob.jobIdHex,
            },
            { interval: 1 }
          );
        } else {
          const scopeId = await client.ensureDcrDataScope(cacheKey.dataRoomId);
          const request: lookalike_media_request.LookalikeMediaRequest = {
            getLookalikeAudience: {
              dataRoomIdHex: cacheKey.dataRoomId,
              requestedAudience: {
                audienceType: cacheKey.audienceType,
                reach: cacheKey.reach,
              },
              scopeIdHex: scopeId,
            },
          };
          const response = await session.sendLookalikeMediaRequest(request);
          if (!("getLookalikeAudience" in response)) {
            throw new Error("Expected ingestAudiencesReport response");
          }
          const computeNodeName = response.getLookalikeAudience.computeNodeName;
          const jobIdHex = response.getLookalikeAudience.jobIdHex;
          await storeMediaJob(
            session,
            apolloClient,
            { computeNodeName, jobIdHex },
            cacheKey.dataRoomId,
            jobType,
            cacheKey
          );
          result = await session.getComputationResult(
            { computeNodeId: computeNodeName, jobId: jobIdHex },
            { interval: 1 }
          );
        }
        const zip = await loadAsync(result);
        const audienceUsersFile = zip.file("audience_users.csv");
        if (audienceUsersFile === null) {
          throw new Error("audience_users.csv not found in zip");
        }
        const audienceUsersCsv = await audienceUsersFile.async("string");
        if (!audienceUsersCsv) {
          throw new Error("Audience is empty");
        }
        const fileName = `Advertiser_${audienceType}-${reach}%_${format(
          new Date(),
          "dd_MM_yyyy HH_mm"
        )}.csv`;
        const file = new File([audienceUsersCsv], fileName, {
          type: "application/octet-stream;charset=utf-8",
        });
        saveAs(file!);
      } catch (error) {
        setErrorSnackbarId(
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              parseMediaDataRoomError(error),
              "Unable to export audience"
            )
          )
        );
      }
    } finally {
      setLoading(false);
    }
  }, [
    apolloClient,
    audienceType,
    client,
    closeSnackbar,
    dataRoomId,
    driverAttestationHash,
    enqueueSnackbar,
    reach,
    sessionManager,
    setErrorSnackbarId,
    setLoading,
  ]);

  return {
    exportAudience: handleAudienceExport,
    loading,
  };
};

export default useExportAudience;
