import { InfoTooltip } from "@decentriq/components";

interface TableNodeColumnConstructorHeaderProps {
  isListEmpty: boolean;
  readOnly: boolean;
}

const TableNodeColumnConstructorHeader: React.FC<
  TableNodeColumnConstructorHeaderProps
> = ({ isListEmpty, readOnly }) =>
  !isListEmpty ? (
    <div
      style={{
        borderBottom: `1px solid lightgrey`,
        display: "flex",
        padding: "8px 0px 8px 0px",
      }}
    >
      <div style={{ flex: 1 }}>Column name</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minWidth: 124,
          width: 240,
        }}
      >
        Data type
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          minWidth: 184,
          whiteSpace: "nowrap",
          width: 184,
        }}
      >
        Hashed values{" "}
        <InfoTooltip
          tooltip="Check this box to require values in this column to be a valid SHA256 hash. When provisioning a dataset, there will be an option to hash values automatically if they are not yet hashed."
          top="0px"
        />
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          minWidth: readOnly ? 180 : 208,
          paddingRight: readOnly ? 0 : 28,
          whiteSpace: "nowrap",
          width: readOnly ? 180 : 208,
        }}
      >
        Allow empty values{" "}
        <InfoTooltip
          tooltip="Check this box to accept empty values in this column"
          top="0px"
        />
      </div>
    </div>
  ) : null;

export default TableNodeColumnConstructorHeader;
