import { type MediaPublishDraftDataRoomInput } from "@decentriq/graphql/dist/types";
import {
  faBinaryLock as faBinaryLockRegular,
  faXmark as faXmarkRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Fragment, memo, useCallback, useMemo } from "react";
import {
  MediaDataRoomUserRole,
  useMediaDataRoomCreateWizard,
} from "features/mediaDataRoom";
import { DataRoomType, dataRoomTypePresentationMap } from "models";
import { type PublishLookalikeMediaDataRoomInput } from "../MediaDataRoomCreateDialog/usePublishLookalikeMediaDataRoom";
import { ConfigureStep } from "./components";

interface MediaDataRoomCreateWizardProps {
  closeDialog: () => void;
  submitMediaDataRoom: (input: MediaPublishDraftDataRoomInput) => Promise<void>;
  submitLookalikeMediaDataRoom: (
    input: PublishLookalikeMediaDataRoomInput
  ) => Promise<void>;
}

const MediaDataRoomCreateWizard: React.FC<MediaDataRoomCreateWizardProps> =
  memo(({ closeDialog, submitMediaDataRoom, submitLookalikeMediaDataRoom }) => {
    const {
      activationDownloadByAdvertiser,
      activationDownloadByPublisher,
      activationType,
      dataRoomType,
      enableOverlapInsights,
      name,
      participantsEmails,
      mainPublisherUserEmail,
      mainAdvertiserUserEmail,
      matchingId,
    } = useMediaDataRoomCreateWizard();
    const cancelOnClick = useCallback(() => {
      closeDialog();
    }, [closeDialog]);
    const cancelLabel = "Cancel";
    const cancelProps = {
      color: "inherit" as "inherit",
      onClick: cancelOnClick,
      variant: "text" as "text",
    };
    const nextOnClick = () => {
      if (dataRoomType === DataRoomType.Media) {
        submitMediaDataRoom({
          activationDownloadByAdvertiser,
          activationDownloadByAgency: activationDownloadByAdvertiser,
          activationDownloadByPublisher,
          activationType,
          advertiserUserEmails,
          agencyUserEmails,
          enableOverlapInsights,
          mainAdvertiserUserEmail,
          mainPublisherUserEmail,
          name,
          observerUserEmails,
          publisherUserEmails,
        });
      } else {
        submitLookalikeMediaDataRoom({
          advertiserEmails: advertiserUserEmails,
          agencyEmails: agencyUserEmails,
          mainAdvertiserEmail: mainAdvertiserUserEmail,
          mainPublisherEmail: mainPublisherUserEmail,
          matchingIdFormat: matchingId,
          name,
          observerEmails: observerUserEmails,
          publisherEmails: publisherUserEmails,
        });
      }
    };
    const publisherUserEmails =
      participantsEmails.get(MediaDataRoomUserRole.Publisher) || [];
    const advertiserUserEmails =
      participantsEmails.get(MediaDataRoomUserRole.Advertiser) || [];
    const observerUserEmails =
      participantsEmails.get(MediaDataRoomUserRole.Observer) || [];
    const agencyUserEmails =
      participantsEmails.get(MediaDataRoomUserRole.Agency) || [];
    const nextIcon = useMemo(() => {
      return <FontAwesomeIcon fixedWidth={true} icon={faBinaryLockRegular} />;
    }, []);
    const nextLabel = "Publish";
    const nextProps = {
      color: "primary" as "primary",
      disabled: false,
      endIcon: null,
      onClick: nextOnClick,
      startIcon: nextIcon,
      variant: "contained" as "contained",
    };
    return (
      <Fragment>
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            Create new {dataRoomTypePresentationMap[dataRoomType]} Clean Room
          </span>
          <IconButton color="inherit" onClick={closeDialog}>
            <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ConfigureStep />
        </DialogContent>
        <DialogActions>
          <Button {...cancelProps}>{cancelLabel}</Button>
          <Tooltip
            placement="top"
            title={
              nextProps.disabled
                ? "Make sure that all mandatory fields are filled under and that your user email is assigned to a role."
                : ""
            }
          >
            <span>
              <Button {...nextProps}>{nextLabel}</Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Fragment>
    );
  });

export default MediaDataRoomCreateWizard;
