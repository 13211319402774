import {
  FormControl,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { memo, useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import useMediaDataRoomAggregationData from "./useMediaDataRoomAggregationData";

const fieldName = "aggregation";

const MediaDataRoomInsightsAggregationSelector: React.FC = () => {
  const { control: formControl, setValue, watch, getValues } = useFormContext();
  const selectedAudienceType = watch("audienceType");

  const aggregationData = useMediaDataRoomAggregationData(selectedAudienceType);

  const optionsTag =
    aggregationData?.map(({ value, label }) => `${label}+${value}`).join("|") ||
    "";
  const previousOptionsRef = useRef<string>();
  // Set default value
  useEffect(() => {
    const options = optionsTag.split("|").map((item) => item.split("+"));
    const defaultAggregationValue = options[0]?.[1];
    const currentValue = getValues().aggregation;
    if (!currentValue && defaultAggregationValue) {
      setValue(fieldName, defaultAggregationValue);
      return;
    }
    if (!currentValue) {
      return;
    }
    const currentOption = options.find((option) => currentValue === option[1]);
    if (currentOption) {
      return;
    }
    const previousOptions =
      previousOptionsRef.current?.split("|").map((item) => item.split("+")) ||
      [];
    const previousOption = previousOptions.find(
      (option) => currentValue === option[1]
    );
    if (!previousOption) {
      return;
    }
    const correspondingCurrentOption = options.find(
      (option) => option[0] === previousOption[0]
    );
    if (!correspondingCurrentOption) {
      return;
    }
    setValue(fieldName, correspondingCurrentOption[1]);
  }, [optionsTag, previousOptionsRef, setValue, getValues]);
  useEffect(() => {
    previousOptionsRef.current = optionsTag;
  }, [optionsTag]);
  return (
    <FormControl sx={{ marginBottom: 2, width: "100%" }}>
      <FormLabel
        component="legend"
        sx={{ fontSize: "12px", paddingLeft: "2px" }}
      >
        Aggregation
      </FormLabel>
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field }) => (
          <Select
            displayEmpty={true}
            fullWidth={true}
            multiple={false}
            renderValue={(value) =>
              aggregationData.find(
                (aggregation) => aggregation?.value === value
              )?.label || ""
            }
            sx={{
              background: "transparent",
            }}
            variant="filled"
            {...field}
          >
            {aggregationData.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

MediaDataRoomInsightsAggregationSelector.displayName =
  "MediaDataRoomInsightsAggregationSelector";

export default memo(MediaDataRoomInsightsAggregationSelector);
