import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/material";
import { memo } from "react";
import ExternalConnectionName from "../ExternalConnectionName/ExternalConnectionName";

interface ExternalConnectionToolbarProps {
  connectionId: string;
  onDatasetsNavigate?: () => void;
}

const ExternalConnectionToolbar: React.FC<ExternalConnectionToolbarProps> = ({
  connectionId,
  onDatasetsNavigate,
}) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          gap: 1,
          overflow: "hidden",
        }}
      >
        <ExternalConnectionName connectionId={connectionId} />
      </Box>
      {onDatasetsNavigate && (
        <Button
          color="inherit"
          onClick={onDatasetsNavigate}
          startIcon={<FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />}
          sx={({ palette }) => ({ borderColor: palette.text.disabled })}
        >
          Go to dataset
        </Button>
      )}
    </Box>
  );
};

ExternalConnectionToolbar.displayName = "ExternalConnectionToolbar";

export default memo(ExternalConnectionToolbar);
