import {
  type ApiCoreSessionManager,
  type lookalike_media_request,
  type LookalikeMediaDataRoomLatest,
} from "@decentriq/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { type LookalikeMediaDataRoom } from "ddc/dist/cjs/schemas/lookalike_media_request";
import { useApiCore } from "contexts";

type UsePublishedLookalikeMediaDataRoomDefinitionResult = UseQueryResult<{
  definition: LookalikeMediaDataRoomLatest;
  supportedFeatures: string[];
}>;

export const fetchPublishedLookalikeMediaDataRoomDefinition =
  (sessionManager: ApiCoreSessionManager) =>
  async ({
    driverAttestationHash,
    dataRoomId,
  }: {
    driverAttestationHash: string;
    dataRoomId: string;
  }): Promise<{
    definition: LookalikeMediaDataRoom;
    supportedFeatures: string[];
  }> => {
    const request: lookalike_media_request.LookalikeMediaRequest = {
      retrieveDataRoom: {
        dataRoomIdHex: dataRoomId,
      },
    };
    const session = await sessionManager.get({ driverAttestationHash });
    const response = await session.sendLookalikeMediaRequest(request);
    if (!("retrieveDataRoom" in response)) {
      throw new Error("Expected retrieveDataRoom response");
    }
    const definition = response.retrieveDataRoom.dataRoom;
    const supportedFeatures =
      session.compiler.lookalikeMedia.getFeatures(definition);
    const latestLmDcr =
      session.compiler.lookalikeMedia.convertToLatest(definition);
    return { definition: latestLmDcr, supportedFeatures };
  };

const usePublishedLookalikeMediaDataRoomDefinition = ({
  dataRoomId,
  driverAttestationHash,
  queryKeyPrefix,
}: {
  queryKeyPrefix: string[];
  dataRoomId: string;
  driverAttestationHash: string;
}): UsePublishedLookalikeMediaDataRoomDefinitionResult => {
  // TODO @matyasfodor - figure out session management. Could be created once and passed down to all hooks
  const { sessionManager } = useApiCore();
  return useQuery({
    queryFn: async () => {
      return fetchPublishedLookalikeMediaDataRoomDefinition(sessionManager)({
        dataRoomId,
        driverAttestationHash,
      });
    },
    queryKey: [
      ...queryKeyPrefix,
      "definition",
      dataRoomId,
      driverAttestationHash,
    ],
  });
};

export default usePublishedLookalikeMediaDataRoomDefinition;
