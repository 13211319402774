import * as yup from "yup";

export const DataPartnerDisplayConfigurationValidationSchema = yup.object({
  collaborationRequestUsers: yup
    .array()
    .of(yup.string().required())
    .min(1, "Please select at least one participant"),
  description: yup
    .string()
    .max(800, "Description must be no longer than 800 characters"),
  logo: yup.string(),
  marketIds: yup
    .array()
    .of(yup.string().required())
    .min(1, "Please select at least one market"),
  name: yup.string().required("Name is required"),
});

export type DataPartnerDisplayConfigurationFormValues = yup.InferType<
  typeof DataPartnerDisplayConfigurationValidationSchema
>;

export const defaultDataPartnerDisplayConfigurationFormValues: DataPartnerDisplayConfigurationFormValues =
  {
    collaborationRequestUsers: [],
    description: "",
    logo: undefined,
    marketIds: [],
    name: "",
  };
