import { testIds } from "@decentriq/utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { DataRoomsList } from "components";
import { DataPartnerPortalWrapper } from "features/dataPartnerPortal/contexts";
import { DataRoomTypeNames } from "models";
import { useTabListStyles, useTabPanelStyles, useTabStyles } from "styles";
import DataPartnerConfigurations from "../DataPartnerConfigurations/DataPartnerConfigurations";

export enum DataPartnerPortalTabs {
  DATAROOMS = "datarooms",
  CONFIGURATIONS = "configurations",
}

interface DataPartnerPortalProps {
  activeTab: DataPartnerPortalTabs;
  baseUrl: string;
}

const DataPartnerPortal: React.FC<DataPartnerPortalProps> = ({
  activeTab,
  baseUrl,
}) => {
  const { classes: tabClasses } = useTabStyles();
  const { classes: tabListClasses } = useTabListStyles();
  const { classes: tabPanelClasses } = useTabPanelStyles();

  return (
    <TabContext value={activeTab}>
      <Box
        sx={{
          backgroundColor: "background.bar",
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Typography variant="h6">
          <strong>Data partner portal</strong>
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "background.bar" }}>
        <TabList
          classes={tabListClasses}
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            classes={tabClasses}
            component={Link}
            data-testid={testIds.dataPartnerPortal.tabList.dcrList}
            label="DCRS"
            to={`${baseUrl}/${DataPartnerPortalTabs.DATAROOMS}`}
            value={DataPartnerPortalTabs.DATAROOMS}
          />
          <Tab
            classes={tabClasses}
            component={Link}
            data-testid={testIds.dataPartnerPortal.tabList.configurations}
            label="CONFIGURATIONS"
            to={`${baseUrl}/${DataPartnerPortalTabs.CONFIGURATIONS}`}
            value={DataPartnerPortalTabs.CONFIGURATIONS}
          />
        </TabList>
      </Box>
      <Box sx={{ flex: 1, margin: 2.5, overflow: "hidden" }}>
        <TabPanel
          classes={tabPanelClasses}
          sx={{
            flexDirection: "column",
            height: "100%",
            padding: "0 !important",
          }}
          value={DataPartnerPortalTabs.DATAROOMS}
        >
          <DataRoomsList
            exposedDataRoomTypes={[
              DataRoomTypeNames.PublishedMediaDataRoom,
              DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
              DataRoomTypeNames.PublishedMediaInsightsDcr,
            ]}
          />
        </TabPanel>
        <TabPanel
          classes={tabPanelClasses}
          sx={{
            flexDirection: "column",
            height: "100%",
            padding: "0 !important",
          }}
          value={DataPartnerPortalTabs.CONFIGURATIONS}
        >
          <DataPartnerPortalWrapper>
            <DataPartnerConfigurations />
          </DataPartnerPortalWrapper>
        </TabPanel>
      </Box>
    </TabContext>
  );
};

DataPartnerPortal.displayName = "DataPartnerPortal";

export default DataPartnerPortal;
