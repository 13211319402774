import { memo } from "react";
import {
  DatasetStatistics,
  type DatasetStatisticsInterface,
} from "features/datasets";

type DatasetPopupStatisticsTabPanelProps = {
  statistics: DatasetStatisticsInterface;
};

const DatasetPopupStatisticsTabPanel: React.FC<
  DatasetPopupStatisticsTabPanelProps
> = ({ statistics }) => {
  return <DatasetStatistics statistics={statistics} />;
};

DatasetPopupStatisticsTabPanel.displayName = "DatasetPopupStatisticsTabPanel";

export default memo(DatasetPopupStatisticsTabPanel);
