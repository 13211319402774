import { testIds } from "@decentriq/utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Tab } from "@mui/material";
import { memo } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { DataRoomAuditLogs } from "containers";
import {
  MediaDataRoomData,
  MediaDataRoomInsights,
  MediaDataRoomInsightsWrapper,
  MediaDataRoomTab,
  OverlapInsightsExecutionContext,
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import { useTabListStyles, useTabPanelStyles, useTabStyles } from "styles";
import { AdvertiserAudiencesContextWrapper } from "../contexts/advertiserAudiences/advertiserAudiences";

interface MediaDataRoomTabsProps {
  activeTab: MediaDataRoomTab;
  baseUrl: string;
}

const MediaDataRoomTabs: React.FC<MediaDataRoomTabsProps> = ({
  activeTab,
  baseUrl,
}) => {
  const {
    isAdvertiser,
    isAgency,
    isObserver,
    isPublisher,
    loading: dataRoomLoading,
    dataRoomId,
    driverAttestationHash,
    features: {
      insights: enableInsights,
      lookalike: enableLookalike,
      retargeting: enableRetargeting,
      exclusionTargeting: enableExclusionTargeting,
    },
  } = usePublishedMediaInsightsDcr();

  const { dataLabDataLoading } = useMediaInsightsDcrData();
  const loading = dataRoomLoading || dataLabDataLoading;
  const { classes: tabListClasses } = useTabListStyles();
  const { classes: tabPanelClasses } = useTabPanelStyles();
  const { classes: tabClasses } = useTabStyles();
  if (loading) {
    return (
      <CircularProgress
        color="inherit"
        size="2.5rem"
        sx={{ margin: "auto" }}
        thickness={1}
      />
    );
  }

  const isInsightsTabEnabled =
    enableInsights && (isObserver || isPublisher || isAdvertiser || isAgency);

  const isActivationTabEnabled =
    (enableLookalike || enableRetargeting || enableExclusionTargeting) &&
    (isObserver || isPublisher || isAdvertiser || isAgency);

  if (
    (activeTab === MediaDataRoomTab.insights && !enableInsights) ||
    (activeTab === MediaDataRoomTab.activation && !isActivationTabEnabled)
  ) {
    return <Navigate to={`${baseUrl}/${MediaDataRoomTab.data}`} />;
  }
  return (
    <TabContext key={dataRoomId} value={activeTab}>
      <Box sx={{ backgroundColor: "background.bar" }}>
        <TabList
          classes={tabListClasses}
          style={{ marginTop: 0 }}
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            classes={tabClasses}
            component={Link}
            data-testid={testIds.containers.mediaDataRoom.data}
            label="DATA"
            to={`${baseUrl}/${MediaDataRoomTab.data}`}
            value={MediaDataRoomTab.data}
          />
          {isInsightsTabEnabled && (
            <Tab
              classes={tabClasses}
              component={Link}
              data-testid={testIds.containers.mediaDataRoom.insights}
              label="INSIGHTS"
              to={`${baseUrl}/${MediaDataRoomTab.insights}`}
              value={MediaDataRoomTab.insights}
            />
          )}
          {isActivationTabEnabled && (
            <Tab
              classes={tabClasses}
              component={Link}
              data-testid={testIds.containers.mediaDataRoom.activation}
              label="ACTIVATION"
              to={`${baseUrl}/${MediaDataRoomTab.activation}`}
              value={MediaDataRoomTab.activation}
            />
          )}
          <Tab
            classes={tabClasses}
            component={Link}
            data-testid={testIds.containers.mediaDataRoom.auditLog}
            label="AUDIT LOG"
            to={`${baseUrl}/${MediaDataRoomTab.auditLog}`}
            value={MediaDataRoomTab.auditLog}
          />
        </TabList>
      </Box>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <TabPanel classes={tabPanelClasses} value={MediaDataRoomTab.data}>
          <Box
            flexShrink={0}
            minHeight="100%"
            sx={{ backgroundColor: "common.white", p: 2 }}
          >
            <MediaDataRoomData />
          </Box>
        </TabPanel>
        {isInsightsTabEnabled && (
          <TabPanel classes={tabPanelClasses} value={MediaDataRoomTab.insights}>
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              flexShrink={0}
              minHeight="100%"
              sx={{ backgroundColor: "common.white", p: 2 }}
            >
              <MediaDataRoomInsightsWrapper
                executionContext={OverlapInsightsExecutionContext.insightsTab}
              >
                <MediaDataRoomInsights />
              </MediaDataRoomInsightsWrapper>
            </Box>
          </TabPanel>
        )}
        {isActivationTabEnabled && (
          <TabPanel
            classes={tabPanelClasses}
            value={MediaDataRoomTab.activation}
          >
            <Box
              flexShrink={0}
              minHeight="100%"
              sx={{ backgroundColor: "common.white", p: 2 }}
            >
              <AdvertiserAudiencesContextWrapper>
                <Outlet />
              </AdvertiserAudiencesContextWrapper>
            </Box>
          </TabPanel>
        )}
        <TabPanel classes={tabPanelClasses} value={MediaDataRoomTab.auditLog}>
          <DataRoomAuditLogs
            dcrHash={dataRoomId!}
            driverAttestationHash={driverAttestationHash!}
          />
        </TabPanel>
      </div>
    </TabContext>
  );
};

MediaDataRoomTabs.displayName = "MediaDataRoomTabs";

export default memo(MediaDataRoomTabs);
