import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled, type Theme } from "@mui/material/styles";
import { useCallback, useState } from "react";
import { usePublishedLookalikeMediaDataRoom } from "contexts";
import {
  EmptyStateContainer,
  LookalikeMediaInsights,
} from "features/mediaDataRoomV2";
import { useLookalikeMediaOverlapInsightsContext } from "features/mediaDataRoomV2/contexts";

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
  })
);

interface LookalikeMediaInsightsDialogProps {
  onClose: () => void;
  open: boolean;
}

const LookalikeMediaInsightsDialog: React.FC<
  LookalikeMediaInsightsDialogProps
> = ({ onClose, open = false }) => {
  const {
    audienceType,
    reach,
    overlapInsightsDataStatus,
    overlapInsightsError,
    retry,
  } = useLookalikeMediaOverlapInsightsContext();
  const { isDeactivated } = usePublishedLookalikeMediaDataRoom();

  const [retryInProgress, setRetryInProgress] = useState(false);
  const retryComputations = useCallback(async () => {
    if (isDeactivated) return;
    setRetryInProgress(true);
    try {
      await retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [retry, setRetryInProgress, isDeactivated]);

  return (
    <Dialog fullWidth={true} maxWidth="lg" onClose={onClose} open={open}>
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>
          {audienceType} - {reach}%
        </span>
        <IconButton color="inherit" onClick={onClose}>
          <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {overlapInsightsError ? (
          <EmptyStateContainer>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faCircleExclamation}
              size="2x"
            />
            <Typography mt={1} variant="subtitle2">
              Audience data could not be retrieved
            </Typography>
            {!isDeactivated && (
              <LoadingButton
                color="inherit"
                loading={retryInProgress}
                onClick={retryComputations}
                sx={{ marginTop: 2 }}
              >
                Retry
              </LoadingButton>
            )}
          </EmptyStateContainer>
        ) : overlapInsightsDataStatus === "COMPUTING" ? (
          <Typography
            sx={{ alignItems: "center", display: "inline-flex" }}
            variant="subtitle2"
          >
            <CircularProgress
              color="inherit"
              size={14}
              sx={{ marginRight: 1 }}
              thickness={3}
            />
            Computing insights...
          </Typography>
        ) : overlapInsightsDataStatus === "FETCHING" ? (
          <Typography
            sx={{ alignItems: "center", display: "inline-flex" }}
            variant="subtitle2"
          >
            <CircularProgress
              color="inherit"
              size={14}
              sx={{ marginRight: 1 }}
              thickness={3}
            />
            Retrieving insights...
          </Typography>
        ) : (
          <LookalikeMediaInsights />
        )}
      </DialogContent>
    </Dialog>
  );
};

LookalikeMediaInsightsDialog.displayName = "LookalikeMediaInsightsDialog";

export default LookalikeMediaInsightsDialog;
