import {
  useDownloadActivatedConsentlessAudienceMutation,
  useMediaDataRoomActivationDownloadConfigQuery,
} from "@decentriq/graphql/dist/hooks";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import format from "date-fns/format";
import saveAs from "file-saver";
import { memo, useCallback } from "react";
import { useApiCore, usePublishedMediaDataRoom } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

interface ConsentlessActivationDownloadButtonProps {
  audienceId: string;
  audienceName: string;
}

const ConsentlessActivationDownloadButton: React.FC<ConsentlessActivationDownloadButtonProps> =
  memo(({ audienceId, audienceName }) => {
    const { store } = useApiCore();
    const {
      advertiserOrganization,
      isPublisher,
      isAdvertiser,
      isAgency,
      dataRoomId,
      dcrHash,
      driverAttestationHash,
    } = usePublishedMediaDataRoom();
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { data, loading, error } =
      useMediaDataRoomActivationDownloadConfigQuery({
        fetchPolicy: "cache-and-network",
        variables: { id: dataRoomId },
      });
    const { activationDownloadByAdvertiser, activationDownloadByPublisher } =
      data?.publishedMediaDataRoom || {};
    const allowDownloadingForAdvertiser =
      (isAdvertiser || isAgency) && activationDownloadByAdvertiser;
    const allowDownloadingForPublisher =
      isPublisher && activationDownloadByPublisher;
    const [
      downloadActivatedConsentlessAudienceMutation,
      { loading: downloadingConsentless },
    ] = useDownloadActivatedConsentlessAudienceMutation({
      onCompleted: (data) => {
        const audienceCsv =
          data?.mediaDownloadActivatedConsentlessAudience.audienceCsv;
        const fileName = `Advertiser_${advertiserOrganization?.name}_${audienceName}_${format(
          new Date(),
          "dd_MM_yyyy HH_mm"
        )}.csv`;
        if (audienceCsv) {
          const content = store.pop<string>(audienceCsv!);
          if (content) {
            const file = new File([content], fileName, {
              type: "application/octet-stream;charset=utf-8",
            });
            saveAs(file!);
          }
        }
      },
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Audience could not be downloaded."
          )
        );
      },
      update: (cache, { data }) => {
        const id = data!.mediaDownloadActivatedConsentlessAudience.audienceId;
        cache.modify({
          fields: {
            downloaded: () => true,
          },
          id: cache.identify({
            __typename: "ConsentlessAudience",
            id,
          }),
        });
      },
    });
    const downloadActivatedAudience = useCallback(() => {
      downloadActivatedConsentlessAudienceMutation({
        variables: {
          input: {
            audienceId,
            dataRoomId: dcrHash,
            driverAttestationHash,
          },
        },
      });
    }, [
      downloadActivatedConsentlessAudienceMutation,
      audienceId,
      dcrHash,
      driverAttestationHash,
    ]);
    if (
      loading ||
      error ||
      (!allowDownloadingForAdvertiser && !allowDownloadingForPublisher)
    ) {
      return <></>;
    }
    return (
      <Tooltip
        disableFocusListener={true}
        disableTouchListener={true}
        placement="top"
        title="Download audience with segments for activation. Segments are ranked by the highest affinity ratio. Users in this audience belong to at least one segment."
      >
        <LoadingButton
          color="inherit"
          loading={downloadingConsentless}
          loadingPosition="start"
          onClick={downloadActivatedAudience}
          startIcon={<FontAwesomeIcon icon={faDownload} />}
          sx={{ marginRight: 1 }}
          variant="text"
        >
          Download
        </LoadingButton>
      </Tooltip>
    );
  });

ConsentlessActivationDownloadButton.displayName =
  "ConsentlessActivationDownloadButton";

export default memo(ConsentlessActivationDownloadButton);
