import { useMediaDataRoomMainbarQuery } from "@decentriq/graphql/dist/hooks";
import { AppBar, Box, type Theme, Toolbar, Tooltip } from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";
import { Label } from "components";
import { usePublishedMediaDataRoom } from "contexts";
import { MediaDataRoomActions } from "features/mediaDataRoom";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

export const useToolbarStyles = makeStyles()((theme: Theme) => ({
  menuLeftWrapper: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginRight: theme.spacing(0.5),
    maxWidth: "100%",
    overflow: "hidden",
  },
  nameEditorWrapper: {
    "&:not(:first-child)": {
      marginLeft: theme.spacing(0.5),
    },
    "&:not(:last-child)": {
      marginRight: theme.spacing(0.5),
    },
    alignItems: "center",
    display: "flex",
    lineHeight: "24px",
    maxWidth: "100%",
    padding: theme.spacing(0.5),
  },
  toolbar: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "minmax(0, 1fr) auto",
    justifyContent: "space-between",
    margin: theme.spacing(1.25, 2),
    minHeight: "32px !important",
  },
}));

interface MediaDataRoomMainbarProps {
  id: string;
}

const MediaDataRoomMainbar: React.FC<MediaDataRoomMainbarProps> = memo(
  ({ id }) => {
    const { classes: toolbarClasses } = useToolbarStyles();
    const { toolbar, menuLeftWrapper, nameEditorWrapper } = toolbarClasses;
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { isStopped, isDeactivated } = usePublishedMediaDataRoom();
    const { data } = useMediaDataRoomMainbarQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            `Media data clean room could not be retrieved. Please try again by refreshing the page.`
          )
        );
      },
      variables: { id },
    });
    const { publishedMediaDataRoom } = data || {};
    const { title: name } = publishedMediaDataRoom || {};
    return (
      <AppBar
        color="transparent"
        elevation={0}
        position="static"
        sx={{ backgroundColor: "background.bar" }}
      >
        <Box>
          <Toolbar className={toolbar} disableGutters={true}>
            <div className={menuLeftWrapper}>
              <div className={nameEditorWrapper}>
                {name}
                <Box sx={{ ml: 0.5, mt: "2px" }}>
                  {isStopped ? (
                    <Tooltip title="This data clean room is in STOPPED state.">
                      <Label label="STOPPED" />
                    </Tooltip>
                  ) : isDeactivated ? (
                    <Tooltip title="This data clean room is in DEACTIVATED state.">
                      <Label label="DEACTIVATED" />
                    </Tooltip>
                  ) : null}
                </Box>
              </div>
            </div>
            <Box>
              <Box sx={{ display: "flex" }}>
                <MediaDataRoomActions id={id} inline={false} />
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    );
  }
);

export default MediaDataRoomMainbar;
