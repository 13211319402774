import { CircularProgress } from "@mui/material";
import { memo, useCallback } from "react";
import { useMediaInsightsDcrData } from "features/MediaInsightsDcr/contexts";

const AdvertiserDataReportLabel: React.FC<{}> = () => {
  const { advertiserDataReport } = useMediaInsightsDcrData();
  const entryLabel = useCallback(
    (num: number) => (num === 1 ? "entry" : "entries"),
    []
  );
  if (advertiserDataReport.error) {
    return <>Failed to fetch data report</>;
  }
  if (advertiserDataReport.status !== "COMPLETED") {
    return (
      <>
        Checking for duplicates in the data...
        <CircularProgress size={12} sx={{ marginLeft: "6px" }} />
      </>
    );
  }
  if (!advertiserDataReport.computeResults) {
    return <>Data report is missing</>;
  }
  const { numberOfDeduplicatedRows, numberOfIngestedRows } =
    advertiserDataReport.computeResults;
  if (!numberOfDeduplicatedRows) {
    return <>No duplicates found in the data</>;
  }
  return (
    <>{`Dropped ${numberOfDeduplicatedRows} duplicate ${entryLabel(
      numberOfDeduplicatedRows
    )}. There are ${numberOfIngestedRows} remaining ${entryLabel(
      numberOfIngestedRows
    )}.`}</>
  );
};

export default memo(AdvertiserDataReportLabel);
