import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, type TextFieldProps } from "@mui/material";
import { useDebounceFn } from "ahooks";
import { memo, type SetStateAction, useCallback, useState } from "react";

type AdminSearchTextFieldProps = {
  setSearchQuery: (searchQuery: SetStateAction<string>) => void;
} & TextFieldProps;

const AdminSearchTextField: React.FC<AdminSearchTextFieldProps> = ({
  setSearchQuery,
  placeholder = "Search user...",
  sx = {},
  InputProps = {},
}) => {
  const [fieldValue, setFieldValue] = useState<string>("");
  const { run: debouncedEffectiveSetSearch } = useDebounceFn(setSearchQuery, {
    wait: 500,
  });

  const handleSearch = useCallback(
    (event: { target: { value: SetStateAction<string> } }) => {
      const search = event.target.value || "";
      setFieldValue(search);
      debouncedEffectiveSetSearch(search);
    },
    [debouncedEffectiveSetSearch]
  );

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faSearch}
            style={{ marginRight: 4, marginTop: 2, opacity: 0.5 }}
          />
        ),
        sx: {
          "& .MuiInput-input": { padding: "4px 0" },
          "&:before": { borderBottomStyle: "solid" },
          paddingBottom: "4px",
        },
        ...InputProps,
      }}
      onChange={handleSearch}
      placeholder={placeholder}
      size="small"
      sx={{ width: "240px", ...sx }}
      value={fieldValue}
      variant="standard"
    />
  );
};

AdminSearchTextField.displayName = "AdminSearchTextField";

export default memo(AdminSearchTextField);
