import {
  DataImportExportStatus,
  type DataSourceType,
  type DataTargetType,
} from "@decentriq/graphql/dist/types";
import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import {
  faCheck,
  faClock,
  faExclamationCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useMount } from "ahooks";
import { format } from "date-fns";
import { memo, useCallback } from "react";
import TimeAgo from "react-timeago";
import {
  DetailsGridIcon,
  DetailsGridLabel,
  DetailsGridValue,
} from "components";
import {
  dataSourceTypePresentation,
  dataTargetTypePresentation,
} from "features/datasets/models";
import { useTimeFormatter } from "hooks";
import ExternalConnectionConfiguration from "../ExternalConnectionConfiguration/ExternalConnectionConfiguration";
import {
  EXTERNAL_CONNECTIONS_TYPES,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "../ExternalConnections";
import {
  useExternalConnectionData,
  useExternalConnectionResult,
} from "../ExternalConnections/hooks";

export const externalConnectionStatusPresentation = new Map<
  DataImportExportStatus,
  string
>([
  [DataImportExportStatus.Success, ""],
  [DataImportExportStatus.Pending, "In progress"],
  [DataImportExportStatus.Failed, "Failed"],
]);

interface ExternalConnectionDetailsProps {
  connectionId: string;
}

const FailedStatusDetailsGridRow: React.FC<{
  connectionId: string;
}> = ({ connectionId }) => {
  const [fetchExternalConnectionResult, { error, isLoading }] =
    useExternalConnectionResult(connectionId);
  useMount(() => {
    fetchExternalConnectionResult();
  });
  const color = red[500];
  return (
    <Grid item={true} padding={"8px"} sx={{ backgroundColor: red[50] }} xs={12}>
      <FontAwesomeIcon
        color={color}
        icon={faExclamationCircle}
        style={{ marginRight: "4px" }}
      />
      <Typography color={color} display="inline" variant="body2">
        Failed{error && !isLoading ? ":" : ""}
      </Typography>
      {error && !isLoading ? (
        <Typography variant="body2">{error}</Typography>
      ) : null}
      {isLoading ? (
        <Box sx={{ alignItems: "center", display: "flex" }}>
          <CircularProgress size={16} sx={{ marginRight: "4px" }} />
          <Typography variant="body2">Getting error details</Typography>
        </Box>
      ) : null}
    </Grid>
  );
};

const ExternalConnectionDetails: React.FC<ExternalConnectionDetailsProps> = ({
  connectionId,
}) => {
  const { type, createdAt, finishedAt, status, connectionType } =
    useExternalConnectionData(connectionId);
  const timeFormatter = useTimeFormatter();
  const isExport = type === EXTERNAL_CONNECTIONS_TYPES.EXPORT;
  const statusIcon = useCallback((status: DataImportExportStatus): IconProp => {
    switch (status) {
      case DataImportExportStatus.Pending:
        return faSpinnerThird;
      case DataImportExportStatus.Failed:
        return faExclamationCircle;
      case DataImportExportStatus.Success:
        return faCheck;
    }
  }, []);
  if (!status) {
    return <></>;
  }
  const pending = status === DataImportExportStatus.Pending;
  return (
    <Grid columnSpacing={2} container={true} padding={"8px"} rowSpacing={3}>
      <Grid container={true} item={true} xs={12}>
        <DetailsGridLabel label="Status" />
        {status === DataImportExportStatus.Failed ? (
          <FailedStatusDetailsGridRow connectionId={connectionId} />
        ) : (
          <Grid item={true} xs={12}>
            {pending ? (
              <CircularProgress size={14} sx={{ marginRight: "6px" }} />
            ) : (
              <DetailsGridIcon
                icon={statusIcon(status as DataImportExportStatus)}
              />
            )}
            <Typography display="inline" variant="body2">
              {status === DataImportExportStatus.Success ? (
                <>
                  Completed{" "}
                  {!!finishedAt && (
                    <TimeAgo date={finishedAt!} formatter={timeFormatter} />
                  )}
                </>
              ) : (
                externalConnectionStatusPresentation.get(
                  status as DataImportExportStatus
                )!
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
      {!!createdAt && (
        <Grid
          container={true}
          direction="column"
          item={true}
          xs={finishedAt ? 6 : 12}
        >
          <DetailsGridLabel label="Started at" />
          <DetailsGridValue
            icon={faClock}
            value={format(new Date(createdAt), "dd-MM-yyyy HH:mm:ss")}
          />
        </Grid>
      )}
      {!!finishedAt && (
        <Grid container={true} direction="column" item={true} xs={6}>
          <DetailsGridLabel
            label={
              status === DataImportExportStatus.Success
                ? "Finished at"
                : "Failed at"
            }
          />
          <DetailsGridValue
            icon={faClock}
            value={format(new Date(finishedAt), "dd-MM-yyyy HH:mm:ss")}
          />
        </Grid>
      )}
      <Grid container={true} item={true} xs={6}>
        <DetailsGridLabel label={isExport ? "Exported to" : "Imported from"} />
        <Grid alignItems="center" display="flex" item={true} xs={12}>
          <ExternalConnectionsIcon
            connectionType={connectionType as DataSourceType | DataTargetType}
            size={ExternalConnectionsIconSize.xs}
          />
          <Typography display="inline" marginLeft={"4px"} variant="body2">
            {type === EXTERNAL_CONNECTIONS_TYPES.EXPORT
              ? dataTargetTypePresentation.get(connectionType as DataTargetType)
              : dataSourceTypePresentation.get(
                  connectionType as DataSourceType
                )}
          </Typography>
        </Grid>
      </Grid>
      {/* Uncomment when dataset hash will be added to connection so type can be identified */}
      {/* <Grid container={true} item={true} xs={6}>
        <Label label="Type" />
        <Value icon={isTabular ? faTable : faFileLines} value={name!} />
      </Grid> */}
      <Grid container={true} item={true} xs={12}>
        <ExternalConnectionConfiguration
          connectionId={connectionId}
          connectionType={connectionType as DataSourceType | DataTargetType}
        />
      </Grid>
    </Grid>
  );
};

ExternalConnectionDetails.displayName = "ExternalConnectionDetails";

export default memo(ExternalConnectionDetails);
