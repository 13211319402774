import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Client, SessionManager } from "@decentriq/core";
import { type ApiToken } from "@decentriq/core/dist/api";
import { memo, useCallback, useEffect, useState } from "react";
import { Loading } from "components";
import { ApiCoreProvider } from "contexts/apicore/apicore";
import { useConfiguration } from "contexts/configuration/configuration";
import { logInfo } from "utils";
import { Store } from "./utils";

interface ApiCoreWrapperProps {
  children?: React.ReactNode;
}

const ApiCoreWrapper: React.FC<ApiCoreWrapperProps> =
  withAuthenticationRequired(
    memo(({ children }) => {
      const { user, isLoading, getAccessTokenSilently } = useAuth0();
      const [store] = useState(new Store());
      const {
        configuration: {
          auth0RelayClientId: clientId,
          diswaHost: host,
          diswaPort: port,
          diswaUseTls: useTls,
          clientLogRequests: logRequests,
        },
      } = useConfiguration();
      const [client, setClient] = useState<Client | undefined>();
      const [sessionManager, setApiCoreSessionManager] = useState<
        SessionManager | undefined
      >();
      /// TODO: This is REALLY REALLY BAD. Just for temp usage
      useEffect(() => {
        if (sessionManager) {
          window.__exportDcrHlDefinition =
            __exportDcrHlDefinition(sessionManager);
        }
      }, [sessionManager]);
      useEffect(() => {
        const initializeClient = async () => {
          const email = user?.email;
          if (email) {
            const apiToken = await getAccessTokenSilently();
            const token = {
              type: "user",
              value: apiToken,
            } as ApiToken;
            const newClient = Client.create(email, token, {
              clientId,
              host,
              logRequests,
              port,
              useTls,
            });
            setClient(newClient);
          }
        };
        initializeClient();
      }, [
        clientId,
        host,
        port,
        useTls,
        user,
        getAccessTokenSilently,
        logRequests,
      ]);
      useEffect(() => {
        if (client) {
          setApiCoreSessionManager(new SessionManager(client));
        }
      }, [client]);
      const reset = useCallback(() => {
        setClient(undefined);
        setApiCoreSessionManager(undefined);
      }, [setClient, setApiCoreSessionManager]);
      useEffect(reset, [reset, user?.email]);
      if (!client || !sessionManager || isLoading) {
        return <Loading />;
      } else {
        const value = {
          client,
          reset,
          sessionManager,
          store,
        };
        return <ApiCoreProvider value={value}>{children}</ApiCoreProvider>;
      }
    })
  );

const __exportDcrHlDefinition =
  (sessionManager: SessionManager) => async (dataRoomId: string) => {
    const sdkSession = await sessionManager.get();
    const publishedDataRoom = await sdkSession.retrieveDataRoom(dataRoomId);
    logInfo(
      JSON.stringify(
        JSON.parse(
          new TextDecoder().decode(publishedDataRoom.highLevelRepresentation!)
        ),
        null,
        2
      )
    );
  };

export default ApiCoreWrapper;
