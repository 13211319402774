import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { DatasetDeletionDialog } from "features/dataNodes/components";
import { DatasetDeletionMode } from "features/dataNodes/components/DatasetDeletionDialog/DatasetDeletionDialog";
import { useDeleteDataset } from "features/datasets";

type DeleteDatasetButtonProps = {
  datasetManifestHash: string;
  closeDatasetPopup: () => void;
  testing?: {
    dataNodeId: string;
  };
};

const DeleteDatasetButton: React.FC<DeleteDatasetButtonProps> = ({
  datasetManifestHash: manifestHash,
  closeDatasetPopup,
  testing,
}) => {
  const [isDeletionOpen, setDeletionOpen] = useState(false);
  const [deleteDataset, isDeletionLoading] = useDeleteDataset({
    manifestHash,
    testing,
  });
  const handleDatasetDeletion = useCallback(async () => {
    const deleted = await deleteDataset();
    if (deleted) {
      closeDatasetPopup();
    }
  }, [deleteDataset, closeDatasetPopup]);
  return (
    <>
      <Button
        color="error"
        onClick={() => setDeletionOpen(true)}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />}
        sx={{ borderWidth: "1px", marginRight: 1, maxWidth: "200px" }}
      >
        Delete dataset
      </Button>
      <DatasetDeletionDialog
        dataType={"table"}
        loading={isDeletionLoading}
        mode={DatasetDeletionMode.delete}
        onCancel={() => setDeletionOpen(false)}
        onConfirm={handleDatasetDeletion}
        open={isDeletionOpen}
      />
    </>
  );
};

DeleteDatasetButton.displayName = "DeleteDatasetButton";

export default memo(DeleteDatasetButton);
