import { useQuery } from "@tanstack/react-query";
import JSZip from "jszip";
import { useApiCore } from "contexts";

interface UseGetValidationReportParams {
  enabled: boolean;
  dataRoomId: string;
  driverAttestationHash: string;
  datasetHash: string | null;
  datanodeId: string;
}

const useGetValidationReport = ({
  enabled,
  driverAttestationHash,
  dataRoomId,
  datasetHash,
  datanodeId,
}: UseGetValidationReportParams) => {
  const { sessionManager } = useApiCore();
  const data = useQuery({
    enabled:
      enabled &&
      Boolean(driverAttestationHash) &&
      Boolean(dataRoomId) &&
      Boolean(datasetHash) &&
      Boolean(datanodeId),
    queryFn: async () => {
      try {
        const session = await sessionManager.get({ driverAttestationHash });
        const reportResponse = await session.runComputationAndGetResults(
          dataRoomId,
          `${datanodeId}_validation_report`
        );
        const reportZip = await JSZip.loadAsync(reportResponse);
        const report =
          (await reportZip.file("validation-report.json")?.async("string")) ??
          "";
        return report;
      } catch (error) {
        throw new Error(
          `Fetching advertiser audience report failed with: ${error}`
        );
      }
    },
    queryKey: [
      "lal-dcr-advertiser-audience-report",
      driverAttestationHash,
      dataRoomId,
      datasetHash,
      datanodeId,
    ],
  });
  return data;
};

export default useGetValidationReport;
