import { useDatasetsQuery } from "@decentriq/graphql/dist/hooks";
import { faFileLines, faTable } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, styled, Typography } from "@mui/material";
import { memo, useState } from "react";
import { DatasetPopup } from "features/datasets";

const DataRoomDatasetContainer = styled(Box)(({ theme: { palette } }) => ({
  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${palette.grey[400]}`,
  },
  alignItems: "center",
  display: "flex",
}));

type DataRoomDatasetsInformationProps = {
  dataRoomId: string;
};

const DataRoomDatasetsInformation: React.FC<
  DataRoomDatasetsInformationProps
> = ({ dataRoomId }) => {
  const [selectedDatasetManifestHash, setSelectedDatasetManifestHash] =
    useState<string>("");
  const { data: { datasets } = {} } = useDatasetsQuery();
  const dataRoomDatasets = (datasets?.nodes || []).filter(({ publications }) =>
    (publications?.nodes || []).some(
      ({ dataRoom }) => dataRoom.id === dataRoomId
    )
  );
  if (!dataRoomDatasets.length) {
    return (
      <Typography color="grey.600" fontStyle="italic" variant="body2">
        No datasets provisioned to this data room yet
      </Typography>
    );
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {dataRoomDatasets.map(({ manifestHash, name }) => (
          <DataRoomDatasetContainer key={manifestHash}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={name.includes("csv") ? faTable : faFileLines}
              onClick={() => setSelectedDatasetManifestHash(manifestHash)}
              size="sm"
              style={{ cursor: "pointer" }}
            />
            <Typography margin={0.5} variant="body2">
              {name}
            </Typography>
          </DataRoomDatasetContainer>
        ))}
      </Box>
      <DatasetPopup
        datasetHash={selectedDatasetManifestHash}
        onDismiss={() => setSelectedDatasetManifestHash("")}
        open={!!selectedDatasetManifestHash}
        withActions={false}
      />
    </>
  );
};

DataRoomDatasetsInformation.displayName = "DataRoomDatasetsInformation";

export default memo(DataRoomDatasetsInformation);
