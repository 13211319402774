import { DataSourceType } from "@decentriq/graphql/dist/types";
import {
  createContext,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useState,
} from "react";
import { EXTERNAL_DATA_IMPORT_STEPS } from "../types";

export const ImportExternalDataContext = createContext<{
  importStep: EXTERNAL_DATA_IMPORT_STEPS;
  selectedDataSource: DataSourceType;
  setImportStep: (
    importStep: SetStateAction<EXTERNAL_DATA_IMPORT_STEPS>
  ) => void;
  setSelectedDataSource: (
    selectedDataSource: SetStateAction<DataSourceType>
  ) => void;
  // After submitting a form, datasetImportId is returned, so dataset import data (incl. status) can be fetched via datasetImport query
  datasetImportId: string | null;
  setDatasetImportId: (datasetImportId: SetStateAction<string | null>) => void;
  setIsImportCreated: (isImportCreated: SetStateAction<boolean>) => void;
  isImportCreated: boolean;
  setDatasetImportError: (error: string) => void;
  datasetImportError: string | null;
}>({
  datasetImportError: "",
  datasetImportId: null,

  importStep: EXTERNAL_DATA_IMPORT_STEPS?.SELECT_SOURCE,
  // Returns true if the first step of external import is completed (creating import itself)
  isImportCreated: false,
  selectedDataSource: DataSourceType.S3,
  setDatasetImportError: () => {},
  setDatasetImportId: () => {},
  setImportStep: () => {},
  setIsImportCreated: () => {},
  setSelectedDataSource: () => {},
});

export const useImportExternalData = () =>
  useContext(ImportExternalDataContext);

export const ImportExternalDataWrapper: React.FC<
  PropsWithChildren<{ defaultSelectedDataSource?: DataSourceType }>
> = ({ children, defaultSelectedDataSource }) => {
  // After submitting a form, datasetImportId is returned, so dataset import data (incl. status)
  // can be fetched via datasetImport query on the IMPORT_STATUS step
  const [datasetImportId, setDatasetImportId] = useState<string | null>(null);
  const [importStep, setImportStep] = useState<EXTERNAL_DATA_IMPORT_STEPS>(
    defaultSelectedDataSource
      ? EXTERNAL_DATA_IMPORT_STEPS.SOURCE_FORM
      : EXTERNAL_DATA_IMPORT_STEPS.SELECT_SOURCE
  );
  const [selectedDataSource, setSelectedDataSource] = useState<DataSourceType>(
    defaultSelectedDataSource || DataSourceType.S3
  );
  // Returns true if the first step of external import is completed (creating import itself)
  const [isImportCreated, setIsImportCreated] = useState<boolean>(false);
  // Errors that come from the local resolver
  const [datasetImportError, setDatasetImportError] = useState<string | null>(
    null
  );
  return (
    <ImportExternalDataContext.Provider
      value={{
        datasetImportError,
        datasetImportId,
        importStep,
        isImportCreated,
        selectedDataSource,
        setDatasetImportError,
        setDatasetImportId,
        setImportStep,
        setIsImportCreated,
        setSelectedDataSource,
      }}
    >
      {children}
    </ImportExternalDataContext.Provider>
  );
};
