import { Box, useTheme } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { memo, useMemo } from "react";
import { useAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";

const PositiveCurveScoreChart: React.FC = () => {
  const {
    selectedAudienceQuality,
    selectedLookalikeAudience,
    selectedAudienceType,
  } = useAdvertiserAudienceGenerator();
  const { palette } = useTheme();

  const { tpr: truePositiveValues, fpr: falsePositiveValues } =
    selectedLookalikeAudience!.quality!.roc_curve;

  const selectedAudienceFalsePositiveValue = useMemo(
    () => parseFloat(selectedAudienceQuality!.roc_curve?.fpr.toFixed(2)),
    [selectedAudienceQuality]
  );

  const positiveCurveScoreChartData = useMemo(
    () => [
      {
        data: falsePositiveValues.map((falsePositiveValue, index) => ({
          x: falsePositiveValue,
          y: truePositiveValues[index],
        })),
        id: `${selectedAudienceType}`,
      },
      {
        data: [
          {
            x: selectedAudienceFalsePositiveValue,
            y: 0,
          },
          {
            x: selectedAudienceFalsePositiveValue,
            y: 1,
          },
        ],
        id: `${(selectedAudienceFalsePositiveValue * 100).toFixed(0)}% of addressable audience`,
      },
    ],
    [
      falsePositiveValues,
      selectedAudienceFalsePositiveValue,
      selectedAudienceType,
      truePositiveValues,
    ]
  );

  return (
    <Box height={400} mt={2} width="100%">
      <ResponsiveLine
        axisBottom={{
          format: (value) => `${value * 100}%`,
        }}
        axisLeft={{
          format: (value) => `${value * 100}%`,
        }}
        colors={[palette.warning.main, palette.primary.light]}
        data={positiveCurveScoreChartData}
        enableGridX={true}
        enableGridY={false}
        enablePointLabel={false}
        enablePoints={false}
        isInteractive={false}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            itemDirection: "left-to-right",
            itemHeight: 20,
            itemOpacity: 0.85,
            itemWidth: 100,
            symbolShape: "circle",
            symbolSize: 8,
            translateX: -32,
            translateY: 70,
          },
        ]}
        lineWidth={3}
        margin={{
          bottom: 70,
          left: 40,
          right: 16,
          top: 16,
        }}
        xScale={{
          type: "linear",
        }}
      />
    </Box>
  );
};

PositiveCurveScoreChart.displayName = "PositiveCurveScoreChart";

export default memo(PositiveCurveScoreChart);
