import { Grid } from "@mui/material";
import React, { memo, useCallback } from "react";
import { useRequestsVars } from "contexts";
import RequestActions from "../RequestActions/RequestActions";
import RequestExpandButton from "../RequestExpandButton/RequestExpandButton";
import RequestName from "../RequestName/RequestName";
import SubmittedRequestSubmittionStatus from "../SubmittedRequestSubmittionStatus/SubmittedRequestSubmittionStatus";

interface RequestToolbarProps {
  requestId: string;
}

const RequestToolbar: React.FC<RequestToolbarProps> = ({ requestId }) => {
  const { toggleRequest, context } = useRequestsVars();
  const toggle = useCallback(
    () => toggleRequest(requestId),
    [toggleRequest, requestId]
  );
  return (
    <Grid container={true} spacing={1}>
      <Grid
        alignItems="center"
        container={true}
        flexWrap="wrap"
        item={true}
        onClick={toggle}
        style={{ cursor: "pointer", height: "40px" }}
        xs={12}
      >
        <RequestExpandButton requestId={requestId} />
        <RequestName requestId={requestId} />
        {context === "submitted" && (
          <SubmittedRequestSubmittionStatus requestId={requestId} />
        )}
        <RequestActions requestId={requestId} />
      </Grid>
    </Grid>
  );
};

RequestToolbar.displayName = "RequestToolbar";

export default memo(RequestToolbar);
