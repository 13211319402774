import { testIds } from "@decentriq/utils";
import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo } from "react";

interface ActivationConfigurationDeleteDialogProps {
  name: string;
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ActivationConfigurationDeleteDialog: React.FC<
  ActivationConfigurationDeleteDialogProps
> = ({ open, name, loading, onCancel, onConfirm }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={!loading ? onCancel : () => {}}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>Are you sure you want to delete {name}?</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          You can't restore the information, all data will be lost.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          data-testid={
            testIds.publisherPortal.activationConfigurations
              .deleteDialogConfirmationButton
          }
          loading={loading}
          loadingPosition="start"
          onClick={onConfirm}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ActivationConfigurationDeleteDialog.displayName =
  "ActivationConfigurationDeleteDialog";

export default memo(ActivationConfigurationDeleteDialog);
