import { useDatasetByHashQuery } from "@decentriq/graphql/dist/hooks";
import { type DatasetByHashQuery } from "@decentriq/graphql/dist/types";
import { useEffect, useMemo, useState } from "react";
import { KeychainItemKind } from "services/keychain";
import { useKeychainService } from "contexts";
import { logError } from "utils";
import { type DatasetSchema, validateDatasetSchema } from "utils/dataset";

interface DatasetWithSchemaHookPayload {
  skipFetching: boolean;
  datasetHash?: string;
}

interface DatasetWithSchemaHookResult {
  dataset?: DatasetByHashQuery["datasetByManifestHash"];
  schema?: DatasetSchema;
}

export const useDatasetWithSchema = ({
  skipFetching,
  datasetHash,
}: DatasetWithSchemaHookPayload): DatasetWithSchemaHookResult => {
  const { keychain } = useKeychainService();
  const [schema, setSchema] = useState<DatasetSchema | undefined>();
  const { data: dataByHash } = useDatasetByHashQuery({
    skip: skipFetching || !datasetHash,
    variables: {
      manifestHash: datasetHash!,
    },
  });
  useEffect(() => {
    if (skipFetching || !datasetHash) {
      return;
    }
    keychain
      .getItem(datasetHash, KeychainItemKind.DatasetMetadata)
      .then(({ item: keychainItem }) => {
        const schema = validateDatasetSchema(
          JSON.parse(keychainItem?.value ?? "")
        );
        setSchema(schema);
      })
      .catch((error) => logError("Failed to parse dataset metadata", error));
  }, [datasetHash, keychain, skipFetching]);
  return {
    dataset: useMemo(() => dataByHash?.datasetByManifestHash, [dataByHash]),
    schema: schema,
  };
};
