import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import { countBy } from "lodash";
import { memo, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr/contexts";
import { useAdvertiserAudiences } from "features/MediaInsightsDcr/contexts/advertiserAudiences/advertiserAudiences";
import { type Audience } from "features/MediaInsightsDcr/models";
import useMediaDataRoomPublishedAudiences from "./useMediaDataRoomPublishedAudiences";

const MediaDataRoomActivationSummary: React.FC = () => {
  const {
    dataRoomId,
    isPublisher,
    isAdvertiser,
    isAgency,
    driverAttestationHash,
    isDeactivated,
    features: {
      lookalike: enableLookalike,
      retargeting: enableRetargeting,
      exclusionTargeting: enableExclusionTargeting,
    },
  } = usePublishedMediaInsightsDcr();
  const { hasRequiredData } = useMediaInsightsDcrData();
  const { activatedAudiences } = useAdvertiserAudiences();
  const { audiences: publisherAudiences } = useMediaDataRoomPublishedAudiences({
    dataRoomId,
    driverAttestationHash,
    hasRequiredData,
    skip: useMemo(
      () => isDeactivated || !isPublisher,
      [isDeactivated, isPublisher]
    ),
  });

  const audiences: Audience[] = useMemo(() => {
    return isPublisher ? publisherAudiences : activatedAudiences;
  }, [isPublisher, publisherAudiences, activatedAudiences]);

  const publishedAudiences: Audience[] = useMemo(
    () => activatedAudiences.filter(({ published }) => !!published),
    [activatedAudiences]
  );

  const {
    lookalike: lookalikeAudiencesCount = 0,
    retarget: retargetAudiencesCount = 0,
    exclusion_targeting: exclusionTargetingAudiencesCount = 0,
  } = countBy(audiences, "activationType");

  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="space-between" mb={1}>
      <Box columnGap={3} display="flex">
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle2">Total audiences</Typography>
          <Typography variant="subtitle2">{audiences.length || 0}</Typography>
        </Box>
        {enableLookalike && (
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">LAL audiences</Typography>
            <Typography variant="subtitle2">
              {lookalikeAudiencesCount}
            </Typography>
          </Box>
        )}
        {enableRetargeting && (
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">Remarketing audiences</Typography>
            <Typography variant="subtitle2">
              {retargetAudiencesCount}
            </Typography>
          </Box>
        )}
        {enableExclusionTargeting && (
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">
              Exclusion targeting audiences
            </Typography>
            <Typography variant="subtitle2">
              {exclusionTargetingAudiencesCount}
            </Typography>
          </Box>
        )}
        {(isAdvertiser || isAgency) && (
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">Available to publisher</Typography>
            <Typography variant="subtitle2">
              {publishedAudiences.length || 0}
            </Typography>
          </Box>
        )}
      </Box>
      {enableLookalike && (isAdvertiser || isAgency) && (
        <Button
          color="primary"
          onClick={() => navigate("create")}
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          sx={{ height: "fit-content" }}
          variant="contained"
        >
          Create new lookalike
        </Button>
      )}
    </Box>
  );
};

MediaDataRoomActivationSummary.displayName = "MediaDataRoomActivationSummary";

export default memo(MediaDataRoomActivationSummary);
