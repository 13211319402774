import { memo } from "react";
import { Navigate, useResolvedPath, useRoutes } from "react-router-dom";
import { Datasets, DatasetsTabs } from "features/datasets";
import { Main } from "layouts";

const DatasetsPage: React.FC = () => {
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;

  const element = useRoutes([
    {
      element: <Datasets activeTab={DatasetsTabs.DATASETS} baseUrl={url} />,
      path: DatasetsTabs.DATASETS,
    },
    {
      element: <Datasets activeTab={DatasetsTabs.EXTERNAL} baseUrl={url} />,
      path: DatasetsTabs.EXTERNAL,
    },
    {
      element: <Navigate to={`${url}/${DatasetsTabs.DATASETS}`} />,
      path: "/",
    },
    {
      element: <Navigate to={`${url}/${DatasetsTabs.DATASETS}`} />,
      path: "*",
    },
  ]);

  return <Main>{element}</Main>;
};

export default memo(DatasetsPage);
