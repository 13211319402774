import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { localStorageKeychainKeyId } from "services/keychain";
import { useApiCore } from "contexts";

const useSignOut = () => {
  const client = useApolloClient();
  const { reset } = useApiCore();
  const { logout } = useAuth0();
  const signOut = useCallback(() => {
    localStorage.removeItem(localStorageKeychainKeyId);
    client.clearStore();
    reset();
    logout({
      logoutParams: { federated: true, returnTo: window.location.origin },
    });
  }, [client, logout, reset]);
  return signOut;
};

export default useSignOut;
