import { faArrowRight as faArrowRightRegular } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDataRoomOverviewData } from "components/entities/dataRoom/DataRoomInformation/hooks";
import { DataRoomActions, DataRoomActionTypes } from "features";
import { dataRoomPathBuilder, type DataRoomTypeNames } from "models";

type DataRoomToolbarProps = {
  dataRoomId: string;
  __typename: DataRoomTypeNames;
};

const DataRoomToolbar: React.FC<DataRoomToolbarProps> = ({
  dataRoomId,
  __typename,
}) => {
  const navigate = useNavigate();
  const { data: overviewData } = useDataRoomOverviewData(
    dataRoomId,
    __typename
  );
  const { name, isFavorite } = overviewData || {};
  const dataRoomLink = dataRoomPathBuilder(
    dataRoomId,
    __typename as DataRoomTypeNames
  );

  const dataRoomToolbarActions = ({
    buttons,
    menuLists,
  }: {
    buttons: any[];
    menuLists: any[];
  }) => ({
    buttons: [
      {
        color: "primary",
        component: Button,
        icon: faArrowRightRegular,
        name: "Open",
        onClick: () => navigate(dataRoomLink),
        sx: {
          "&:hover": {
            backgroundColor: "#297474 !important",
          },
        },
        type: "Open",
        variant: "contained",
      },
      ...buttons.filter(
        ({ type }: any) =>
          ![
            DataRoomActionTypes.Publish,
            DataRoomActionTypes.ToggleFavorite,
          ].includes(type)
      ),
    ],
    menuLists,
  });
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          gap: 1,
          overflow: "hidden",
        }}
      >
        <Typography fontWeight="fontWeightSemiBold" noWrap={true} variant="h6">
          {name}
        </Typography>
        {isFavorite ? (
          <Box component="span" sx={{ color: "primary.main" }}>
            <FontAwesomeIcon fixedWidth={true} icon={faStarSolid} />
          </Box>
        ) : null}
      </Box>
      <DataRoomActions
        __typename={__typename}
        actions={dataRoomToolbarActions}
        id={dataRoomId}
      />
    </Box>
  );
};

DataRoomToolbar.displayName = "DataRoomToolbar";

export default memo(DataRoomToolbar);
