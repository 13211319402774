import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo } from "react";

interface DataRoomStopDialogProps {
  name?: string;
  open: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const DataRoomStopDialog: React.FC<DataRoomStopDialogProps> = ({
  name,
  open,
  loading,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={!loading ? onCancel : () => {}}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>Are you sure you want to stop {name}?</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          Stopping the data clean room is an irreversible action. If a data
          clean room has been stopped, it is not possible anymore to provide
          data, run computations and retrieve results. Participants can still
          delete datasets and access the audit log.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={onCancel}>
          Go back
        </Button>
        <LoadingButton
          color="error"
          loading={loading}
          loadingPosition="start"
          onClick={onConfirm}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Stop
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DataRoomStopDialog.displayName = "DataRoomStopDialog";

export default memo(DataRoomStopDialog);
