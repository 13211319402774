import { Alert, AlertTitle, Box } from "@mui/material";
import { memo } from "react";
import { DataLabStatistics, useDataLabContext } from "features/dataLabs";

const DataLabValidationDetails: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();

  return (
    dataLab?.statistics && (
      <Box m={1}>
        {dataLab?.statistics.errors?.length ? (
          <Alert severity="error" sx={{ marginBottom: 1 }}>
            <AlertTitle>Failed:</AlertTitle>
            <ul>
              {dataLab?.statistics.errors.map((error: string) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        ) : null}
        <DataLabStatistics />
      </Box>
    )
  );
};

DataLabValidationDetails.displayName = "DataLabValidationDetails";

export default memo(DataLabValidationDetails);
