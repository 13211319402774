import { type DataSourceType } from "@decentriq/graphql/dist/types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { type DataType } from "models";
import { ExternalDataSourceSelector } from "../ImportExternalDataDialog/components";
import LocalDataSourceSelector from "../ImportLocalDataDialog/LocalDataSourceSelector/LocalDataSourceSelector";

interface ImportTypeSelectionDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirmFromLocal: (type: DataType) => void;
  onConfirmFromExternal: (type: DataSourceType) => void;
}

const ImportTypeSelectionDialog = memo<ImportTypeSelectionDialogProps>(
  ({ open, onCancel, onConfirmFromExternal, onConfirmFromLocal }) => {
    const [externalDataSourceType, setExternalDataSourceType] =
      useState<DataSourceType | null>(null);
    const [localDataSourceType, setLocalDataSourceType] =
      useState<DataType | null>(null);
    const isLocalSelected = Boolean(localDataSourceType);
    const isExternalSelected = Boolean(externalDataSourceType);
    useEffect(() => {
      if (!open) {
        setExternalDataSourceType(null);
        setLocalDataSourceType(null);
      }
    }, [open, setLocalDataSourceType, setExternalDataSourceType]);
    useEffect(() => {
      if (isLocalSelected) {
        setExternalDataSourceType(null);
      }
    }, [isLocalSelected, setExternalDataSourceType]);
    useEffect(() => {
      if (isExternalSelected) {
        setLocalDataSourceType(null);
        return;
      }
    }, [isExternalSelected, setLocalDataSourceType]);
    const hasSelectedType = isLocalSelected || isExternalSelected;
    return (
      <Dialog fullWidth={true} maxWidth="md" onClose={onCancel} open={open}>
        <DialogTitle>Import dataset</DialogTitle>
        <DialogContent>
          <Typography fontWeight="fontWeightSemiBold" mb={0.5} variant="body1">
            Upload from computer
          </Typography>
          <LocalDataSourceSelector
            onTypeChange={setLocalDataSourceType}
            selectedType={localDataSourceType}
          />
          <Typography
            fontWeight="fontWeightSemiBold"
            mb={0.5}
            mt={2}
            variant="body1"
          >
            or import from external source
          </Typography>
          <ExternalDataSourceSelector
            onTypeChange={setExternalDataSourceType}
            selectedType={externalDataSourceType}
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "auto",
          }}
        >
          <Button color="inherit" onClick={onCancel} variant="text">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!hasSelectedType}
            onClick={() => {
              if (!hasSelectedType) {
                return;
              }
              if (isLocalSelected) {
                onConfirmFromLocal(localDataSourceType as DataType);
              } else {
                onConfirmFromExternal(externalDataSourceType as DataSourceType);
              }
            }}
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

ImportTypeSelectionDialog.displayName = "ImportTypeSelectionDialog";

export default ImportTypeSelectionDialog;
