import { TabContext } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  INSIGHTS_CHART_VALUE_KEY_BUTTONS,
  type InsightsBasicViewFormValues,
  type InsightsDetailedViewFormValues,
  MediaDataRoomInsightsChartTabs,
} from "features/MediaInsightsDcr";

interface MediaDataRoomInsightsChartValueKeySelectorProps {
  title: string;
  withSuppressedSegments: boolean;
  suppressedSegmentsInfoComponent: React.ReactNode;
}

// Component defines whether shareInOverlap or shareInAddressableAudience value is taken for the chart
const MediaDataRoomInsightsChartValueKeySelector: React.FC<
  MediaDataRoomInsightsChartValueKeySelectorProps
> = ({ title, withSuppressedSegments, suppressedSegmentsInfoComponent }) => {
  const { control: formControl, watch } = useFormContext<
    InsightsBasicViewFormValues | InsightsDetailedViewFormValues
  >();
  const chartValueKey = watch("genderChartValueKey");

  const chartKeyTitle = INSIGHTS_CHART_VALUE_KEY_BUTTONS.find(
    ({ value }) => value === chartValueKey
  )?.label;

  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Box
        alignItems="baseline"
        display="flex"
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Typography fontWeight="fontWeightSemiBold" mr={1}>
          {title} ({chartKeyTitle})
        </Typography>
        {withSuppressedSegments && suppressedSegmentsInfoComponent}
      </Box>
      <Controller
        control={formControl}
        name="genderChartValueKey"
        render={({ field: { value, onChange } }) => {
          return (
            <TabContext value={value}>
              <MediaDataRoomInsightsChartTabs
                onChange={(_, newValue: string) => onChange(newValue)}
              >
                {INSIGHTS_CHART_VALUE_KEY_BUTTONS.map(
                  ({ label, value }, index) => (
                    <Tab key={index} label={label} value={value} />
                  )
                )}
              </MediaDataRoomInsightsChartTabs>
            </TabContext>
          );
        }}
      />
    </Box>
  );
};

MediaDataRoomInsightsChartValueKeySelector.displayName =
  "MediaDataRoomInsightsChartValueKeySelector";

export default memo(MediaDataRoomInsightsChartValueKeySelector);
