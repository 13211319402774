import { useQuery } from "@apollo/client";
import { DqTable } from "@decentriq/components";
import {
  type OrganizationUsersQuery,
  type OrganizationUsersQueryVariables,
  UsersDocument,
  type UsersQuery,
  type UsersQueryVariables,
} from "@decentriq/graphql/dist/types";
import { type TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { format } from "date-fns";
import get from "lodash/get";
import { type MRT_ColumnDef } from "material-react-table";
import { Fragment, memo, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Chip,
  DataRoomParticipantAvatar,
  UserDetailsDialog,
  UsersAddButton,
} from "components";
import { useUserRole } from "hooks";
import { userRolePresentation } from "models";

interface UsersListProps {
  variables?: UsersQueryVariables | OrganizationUsersQueryVariables;
  query?: DocumentNode<
    UsersQuery | OrganizationUsersQuery,
    UsersQueryVariables | OrganizationUsersQueryVariables
  >;
  dataKey?: string;
}

type User = UsersQuery["users"]["nodes"][number];
interface UserWithOrganization extends Omit<User, "organization"> {
  organization: string;
  organizationId: string;
}

const UsersList: React.FC<UsersListProps> = ({
  variables,
  query = UsersDocument,
  dataKey = "users",
}) => {
  const [selectedUserId, selectEditUser] = useState<string | null>(null);
  const { data: usersData, loading } = useQuery<UsersQuery>(query, {
    variables: variables as UsersQueryVariables,
  });
  const { usersList, totalCount } = useMemo<{
    usersList: UserWithOrganization[];
    totalCount: number;
  }>(() => {
    if (!usersData) {
      return {
        totalCount: 0,
        usersList: [],
      };
    }
    const usersList: UserWithOrganization[] = get(
      usersData!,
      `${dataKey}.nodes`,
      [] as User[]
    ).map(({ organization, ...user }: User) => ({
      ...user,
      organization: organization?.name || "",
      organizationId: organization?.id || "",
    })) as UserWithOrganization[];
    return {
      totalCount: usersList.length,
      usersList,
    };
  }, [usersData, dataKey]);
  const { isSuperAdmin, isSuperAdminReadOnly } = useUserRole();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isUsersPage = pathname.includes("/admin/users");
  // Additional column Organization is added and visible only for Decentriq users on Users page
  const withOrganizationColumn =
    (isSuperAdmin || isSuperAdminReadOnly) && isUsersPage;
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "2.5rem",
          width: "100%",
        }}
      >
        <CircularProgress color="inherit" size={16} thickness={3} />
      </div>
    );
  }
  type UserForColumnDef = Omit<UserWithOrganization, "__typename">;
  const usersListColumns: MRT_ColumnDef<UserForColumnDef>[] = [
    {
      Cell: ({ cell, row }) => {
        const avatarImage = cell.getValue();
        return avatarImage ? (
          <Avatar
            src={`data:image;base64,${avatarImage}`}
            sx={{ height: "32px", margin: "2px 0", width: "32px" }}
          />
        ) : (
          <DataRoomParticipantAvatar
            name={row?.original?.email || ""}
            size="32px"
          />
        );
      },
      accessorKey: "logo",
      header: "",
      id: "avatar",
      maxSize: withOrganizationColumn ? 50 : 10,
    },
    {
      Cell: ({ cell, row }) => {
        const userEmail = cell.getValue<string>();
        return (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <Typography>{userEmail}</Typography>
            {(isSuperAdmin || isSuperAdminReadOnly) &&
              row?.original?.isDemoUser && (
                <Chip label="Demo" sx={{ marginLeft: 1 }} />
              )}
          </Box>
        );
      },
      Footer: <div>Total: {totalCount}</div>,
      accessorKey: "email",
      header: "Email",
      id: "email",
      minSize: 350,
    },
    {
      accessorFn: (rowData) => userRolePresentation.get(rowData?.userRole),
      header: "Role",
      id: "role",
    },
    ...(withOrganizationColumn
      ? ([
          {
            Cell: ({ cell, row }) => (
              <Typography
                onClick={() =>
                  navigate(
                    `/admin/organizations/${row?.original?.organizationId}`
                  )
                }
                sx={{
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {cell.getValue<string>()}
              </Typography>
            ),
            accessorKey: "organization",
            header: "Organization",
            id: "organization",
          },
        ] as MRT_ColumnDef<UserForColumnDef>[])
      : []),
    {
      Cell: ({ cell }) => {
        const createdAt = cell.getValue<string>();
        return createdAt
          ? format(new Date(createdAt), "dd.MM.yyy, HH:mm")
          : "—";
      },
      accessorKey: "createdAt",
      header: "Created at",
      id: "createdAt",
    },
  ];
  return (
    <Fragment>
      <Box
        sx={{
          alignItems: "stretch",
          backgroundColor: "common.white",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "stretch",
          overflow: "hidden",
        }}
      >
        <DqTable
          columns={usersListColumns}
          data={usersList}
          enableGlobalFilter={true}
          enableSorting={true}
          enableTopToolbar={true}
          initialState={{
            showGlobalFilter: true,
          }}
          localization={{
            noRecordsToDisplay: "No users found",
          }}
          muiSearchTextFieldProps={{
            InputProps: {
              sx: {
                margin: 1,
                minWidth: "260px",
              },
            },
            placeholder: "Search users",
            size: "medium",
            variant: "standard",
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => selectEditUser(row?.original?.id),
            sx: {
              "&:hover td:after": {
                backgroundColor: "primary.light",
                content: '""',
                height: "100%",
                left: 0,
                opacity: 0.125,
                position: "absolute",
                top: 0,
                width: "100%",
                zIndex: -1,
              },
              cursor: "pointer",
            },
          })}
          muiTablePaperProps={{
            sx: {
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "100%",
              overflow: "hidden",
              width: "100%",
            },
          }}
          muiTopToolbarProps={{
            sx: (theme) => ({
              flex: "0 0 auto",
              minHeight: theme.spacing(8),
              // eslint-disable-next-line sort-keys-fix/sort-keys-fix
              borderBottom: "1px solid",
              borderColor: "divider",
            }),
          }}
          renderTopToolbarCustomActions={() => {
            return !isUsersPage && !isSuperAdminReadOnly ? (
              <UsersAddButton />
            ) : null;
          }}
        />
      </Box>
      {selectedUserId && (
        <UserDetailsDialog
          onClose={() => selectEditUser(null)}
          open={!!selectedUserId}
          userId={selectedUserId!}
        />
      )}
    </Fragment>
  );
};

UsersList.displayName = "UsersList";

export default memo(UsersList);
