import { FormControlLabel, Switch, type Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useDataRoom } from "contexts";
import usePublishedDataRoomTestMode from "../hooks/usePublishedDataRoomTestMode/usePublishedDataRoomTestMode";

const useSwitchStyles = makeStyles()((theme: Theme) => ({
  root: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      "& .MuiSwitch-thumb": {
        background: "white",
      },
      "&+.MuiSwitch-track": {
        background: "rgb(54,18,63)",
        opacity: 1,
      },
    },
  },
  track: {
    background: "rgb(54,18,63)",
  },
}));

const TestModeSwitch: React.FC = () => {
  const { classes: switchClasses } = useSwitchStyles();
  const { allowTestMode } = useDataRoom();
  const [checked, handleChange] = usePublishedDataRoomTestMode();
  if (!allowTestMode) {
    return <></>;
  }
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          classes={switchClasses}
          color="primary"
          onChange={handleChange}
        />
      }
      label="Test mode"
      sx={{
        margin: 0,
        marginRight: "1rem",
      }}
    />
  );
};

export default TestModeSwitch;
