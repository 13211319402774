import { testIds } from "@decentriq/utils";
import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Popover } from "@mui/material";
import { useCallback, useRef } from "react";
import { Chip } from "components";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { usePopupState } from "hooks";
import { type DataType } from "models";

interface DataNodeAddNewFieldProps {
  computeNodeNamesAndTypes: { name: string; dataType: DataType }[];
  toggle: (id: string) => void;
}

const DataNodeAddNewField: React.FC<DataNodeAddNewFieldProps> = ({
  computeNodeNamesAndTypes,
  toggle,
}) => {
  const { handleCreate } = useDataNodeActions();
  // TODO remove this and use the updated theme when available

  const makeUniqueName = useCallback(
    (prefix: string, data: { name: string; dataType: DataType }[]) => {
      let helper = 1;
      const isNameTaken = (element: { name: string; dataType: DataType }) =>
        element.name.includes(`${prefix} ${helper}`);

      while (data.some(isNameTaken)) {
        helper++;
      }
      return `${prefix} ${helper}`;
    },
    []
  );

  const generateName = useCallback(
    (dataType: DataType) => {
      if (dataType === "table") {
        const tables = computeNodeNamesAndTypes.filter(
          ({ dataType }) => dataType === "table"
        );

        return makeUniqueName("Table", tables);
      }
      if (dataType === "unstructured") {
        const files = computeNodeNamesAndTypes.filter(
          ({ dataType }) => dataType === "unstructured"
        );
        return makeUniqueName("File", files);
      }
      // Can't be undefined
      return "Name";
    },
    [computeNodeNamesAndTypes, makeUniqueName]
  );

  const create = useCallback(
    async (dataType: DataType) => {
      const name = generateName(dataType);
      const id = await handleCreate({
        dataType: dataType,
        name: name,
      });
      if (id) {
        toggle(id);
      }
    },
    [generateName, handleCreate, toggle]
  );

  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleMenuItemClick = useCallback(
    async (value: DataType) => {
      create(value);
      close();
    },
    [close, create]
  );

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Button
        aria-controls={isOpen ? "add_table_dropdown" : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        color="primary"
        data-testid={testIds.dataNode.dataNodeAddNewField.newDataButton}
        id="add_table_dropdown"
        onClick={open}
        ref={anchorRef}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faPlusRegular} />}
        variant="contained"
      >
        Add data
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={close}
        open={isOpen}
        sx={{
          minWidth: "210px",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <Box
          sx={{
            border: "none",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginLeft: 2,
            marginRight: 6,
            marginY: 2,
          }}
        >
          <Chip
            data-testid={testIds.dataNode.dataNodeAddNewField.dataToggleFile}
            label="File"
            onClick={() => handleMenuItemClick("unstructured")}
            size="small"
            sx={{
              backgroundColor: (theme) => theme.palette.chips.pastel.main,
            }}
          />
          <Chip
            data-testid={testIds.dataNode.dataNodeAddNewField.dataToggleTable}
            label="Table"
            onClick={() => handleMenuItemClick("table")}
            size="small"
            sx={{
              backgroundColor: (theme) => theme.palette.background.bar,
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default DataNodeAddNewField;
