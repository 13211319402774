import { usePublishedDataRoomDataRoomHashQuery } from "@decentriq/graphql/dist/hooks";
import { Box, Tooltip } from "@mui/material";
import { memo } from "react";
import { Id, Label } from "components";
import { DataRoomTypeNames } from "models";

const SHOW_DCR_ID = false;

interface DataRoomNameEditorEndAdornmentProps {
  __typename: string;
  id: string;
}

const DataRoomNameEditorEndAdornment: React.FC<DataRoomNameEditorEndAdornmentProps> =
  memo(({ __typename, id: dataRoomId }) => {
    const { data } = usePublishedDataRoomDataRoomHashQuery({
      skip: __typename === DataRoomTypeNames.DraftDataRoom,
      variables: { id: dataRoomId },
    });
    const {
      id = "",
      isStopped = false,
      deactivatedAt,
    } = data?.publishedDataRoom || {};
    const isDeactivated = Boolean(deactivatedAt);
    return (
      <Box sx={{ ml: 0.5, mt: "2px" }}>
        {__typename === DataRoomTypeNames.DraftDataRoom ? (
          <Tooltip title="This data clean room is in DRAFT. The definition can still be modified and it is not yet accessible by the other participants. Publish it to the enclave to begin the collaboration.">
            <Label label="Draft" />
          </Tooltip>
        ) : isStopped ? (
          <Tooltip title="This data clean room is in STOPPED state.">
            <Label label="STOPPED" />
          </Tooltip>
        ) : isDeactivated ? (
          <Tooltip title="This data clean room is in DEACTIVATED state.">
            <Label label="DEACTIVATED" />
          </Tooltip>
        ) : null}
        {SHOW_DCR_ID && id && (
          <Id
            copyBehaviour="both"
            id={id}
            idTooltip={
              <span>
                Unique identifier of this data clean room: {id}
                <br />
                Useful when referencing it from the SDK.
              </span>
            }
          />
        )}
      </Box>
    );
  });

DataRoomNameEditorEndAdornment.displayName = "DataRoomNameEditorEndAdornment";

export default DataRoomNameEditorEndAdornment;
