import { type lookalike_media_request } from "@decentriq/core";
import * as uuid from "uuid";
import { useApiCore } from "contexts";
import {
  calculateAttestationHash,
  retrieveLatestMediaEnclaveSpecifications,
} from "wrappers/ApolloWrapper/resolvers/media";

export interface PublishLookalikeMediaDataRoomInput {
  advertiserEmails: string[];
  agencyEmails: string[];
  observerEmails: string[];
  publisherEmails: string[];
  mainAdvertiserEmail: string;
  mainPublisherEmail: string;
  name: string;
  matchingIdFormat: string;
}

interface PublishLookalikeMediaDataRoomResponse {
  id: string;
  driverAttestationHash: string;
}

const getMatchingIdAndHash = (
  rawMatchingId: string
): {
  matchingIdFormat: lookalike_media_request.FormatType;
  hashMatchingIdWith?: lookalike_media_request.HashingAlgorithm;
} => {
  switch (rawMatchingId) {
    case "string": {
      return { matchingIdFormat: "STRING" };
    }
    case "email": {
      return { matchingIdFormat: "EMAIL" };
    }
    case "hashed_email": {
      return { hashMatchingIdWith: "SHA256_HEX", matchingIdFormat: "EMAIL" };
    }
    case "phone_number": {
      return { matchingIdFormat: "PHONE_NUMBER_E164" };
    }
    case "hashed_phone_number": {
      return {
        hashMatchingIdWith: "SHA256_HEX",
        matchingIdFormat: "PHONE_NUMBER_E164",
      };
    }
    default:
      throw new Error(`Unrecognized matching id format: "${rawMatchingId}"`);
  }
};

const usePublishLookalikeMediaDataRoom = () => {
  const {
    client: apiCoreClient,
    // uploader,
    sessionManager,
    // store: apiCoreStore,
  } = useApiCore();
  const publish = async (
    input: PublishLookalikeMediaDataRoomInput
  ): Promise<PublishLookalikeMediaDataRoomResponse> => {
    const enclaveSpecifications =
      await retrieveLatestMediaEnclaveSpecifications(apiCoreClient);
    const driverAttestationHash = calculateAttestationHash(
      enclaveSpecifications.driverEnclaveSpecification
    );

    const [authenticationRootCertificatePem, session] = await Promise.all([
      apiCoreClient.decentriqCaRootCertificate(),
      sessionManager.get({ driverAttestationHash }),
    ]);

    const { matchingIdFormat, hashMatchingIdWith } = getMatchingIdAndHash(
      input.matchingIdFormat
    );

    const dataRoom = session.compiler.lookalikeMedia.create({
      v3: {
        v3: {
          advertiserEmails: input.advertiserEmails,
          agencyEmails: input.agencyEmails,
          authenticationRootCertificatePem: new TextDecoder().decode(
            authenticationRootCertificatePem
          ),
          driverEnclaveSpecification:
            enclaveSpecifications.driverEnclaveSpecification,
          enableAuditLogRetrieval: true,
          enableDebugMode: false,
          enableDevComputations: true,
          hashMatchingIdWith,
          id: uuid.v4(),
          mainAdvertiserEmail: input.mainAdvertiserEmail,
          mainPublisherEmail: input.mainPublisherEmail,
          matchingIdFormat,
          modelEvaluation: {
            postScopeMerge: ["ROC_CURVE"],
            preScopeMerge: [],
          },
          name: input.name,
          observerEmails: input.observerEmails,
          publisherEmails: input.publisherEmails,
          pythonEnclaveSpecification:
            enclaveSpecifications.pythonEnclaveSpecification,
        },
      },
    });

    const request: lookalike_media_request.LookalikeMediaRequest = {
      publishDataRoom: {
        dataRoom,
        requirePassword: false,
        showOrganizationLogo: false,
      },
    };
    const resp = await session.sendLookalikeMediaRequest(request);
    if (!("publishDataRoom" in resp)) {
      throw new Error("Expected publishDataRoom response");
    }
    return {
      driverAttestationHash,
      id: resp?.publishDataRoom.dataRoomId,
    };
  };
  return {
    publish,
  };
};

export default usePublishLookalikeMediaDataRoom;
