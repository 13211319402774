import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { memo } from "react";

const ExternalConnectionConfigurationLabel: React.FC = () => {
  return (
    <Typography color="inherit">
      <FontAwesomeIcon
        icon={faGear}
        style={{ marginRight: 4, marginTop: 2, opacity: 0.5 }}
      />
      Configuration
    </Typography>
  );
};

ExternalConnectionConfigurationLabel.displayName =
  "ExternalConnectionConfigurationLabel";

export default memo(ExternalConnectionConfigurationLabel);
