import { ActivationType } from "@decentriq/graphql/dist/types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { memo, useEffect } from "react";
import { SelectableControlLabel } from "components";
import { matchingIdTypes } from "features/dataLabs/components/DataLabCreateDialog/DataLabCreateDialog";
import { useMediaDataRoomCreateWizard } from "features/mediaDataRoom";
import { DataRoomType } from "models";
import RolesAndParticipants from "./containers/RolesAndParticipants";

const ENABLE_MEDIA_PLANNING = false;

const StyledCheckboxLabelTop = memo(
  styled("div")<{ checked?: boolean }>(({ checked }) => ({ theme }) => ({
    color: checked ? theme.palette.primary.main : "inherit",
    lineHeight: "20px",
  }))
);
StyledCheckboxLabelTop.displayName = "StyledCheckboxLabelTop";

const StyledCheckboxLabelBottom = memo(styled("div")({ lineHeight: "16px" }));
StyledCheckboxLabelBottom.displayName = "StyledCheckboxLabelBottom";

interface ActivationTypeDescriptionProps {
  className?: string;
  activationType: ActivationType | null;
}

const ActivationTypeDescription: React.FC<ActivationTypeDescriptionProps> =
  memo(({ className, activationType }) => {
    switch (activationType) {
      case ActivationType.Direct:
        return (
          <div className={className}>
            This data clean room type defines audiences in terms of
            Publisher-provided Activation IDs.
            <p>
              Only the Activation IDs which are in the overlap are returned. The
              Publisher will receive the Activation IDs and use them as
              targetable audience.
            </p>
          </div>
        );
      case null:
        return (
          <div className={className}>
            Choose this data clean room type if you are only interested in the
            count of individuals in the overlap, for media planning.
          </div>
        );
      case ActivationType.Consentless:
      default:
        return (
          <div className={className}>
            This data clean room type defines audiences as a list of
            Publisher-provided interest segments. An example of such a segment
            could be <em>Sports &gt; Tennis</em>. The top affinity segments are
            those that are highly overrepresented in the overlap compared to the
            entire Publisher audience.
            <p>
              The Publisher will translate these segments into a targetable
              audience. No personal data ever leaves the clean room.
            </p>
          </div>
        );
    }
  });
ActivationTypeDescription.displayName = "ActivationTypeDescription";

const StyledActivationTypeDescription = memo(
  styled(ActivationTypeDescription)({ lineHeight: "20px", minHeight: "176px" })
);
StyledActivationTypeDescription.displayName = "StyledActivationTypeDescription";

styled(FormControlLabel);

const ConfigureStep: React.FC = memo(() => {
  const {
    dataRoomType,
    name,
    setName,
    activationType,
    setActivationType,
    activationDownloadByPublisher,
    setActivationDownloadByPublisher,
    activationDownloadByAdvertiser,
    setActivationDownloadByAdvertiser,
    enableOverlapInsights,
    setEnableOverlapInsights,
    matchingId,
    setMatchingId,
  } = useMediaDataRoomCreateWizard();
  useEffect(() => {
    setEnableOverlapInsights(activationType === ActivationType.Consentless);
  }, [activationType, setEnableOverlapInsights]);
  return (
    <Grid container={true} spacing={3}>
      <Grid container={true} item={true} spacing={1}>
        <Grid item={true} xs={12}>
          <TextField
            autoComplete="off"
            autoFocus={true}
            fullWidth={true}
            onChange={(event) => setName(event.target.value)}
            placeholder="Data clean room name (required)"
            required={true}
            size="small"
            value={name}
          />
        </Grid>
      </Grid>
      <Grid container={true} item={true} mb={1} mt={1} spacing={1}>
        <RolesAndParticipants />
      </Grid>
      {dataRoomType === DataRoomType.LookalikeMedia ? (
        <Grid container={true} item={true} spacing={1}>
          <Grid item={true} sx={{ pb: 0 }} xs={12}>
            <Typography sx={{ color: "#666666" }}>Matching ID</Typography>
          </Grid>
          <Grid
            display="flex"
            flexDirection="column"
            item={true}
            sm={4}
            xs={12}
          >
            <FormControl size="small">
              <Select
                displayEmpty={true}
                onChange={(event) => setMatchingId(event.target.value)}
                renderValue={(value) => {
                  const option = matchingIdTypes.find((o) => o.value === value);

                  if (!option) {
                    return "None";
                  }

                  return option.name;
                }}
                value={matchingId}
                variant="standard"
              >
                {matchingIdTypes.map(({ name, value }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <Grid container={true} item={true} spacing={1}>
          <Grid item={true} sx={{ pb: 0 }} xs={12}>
            <Typography sx={{ color: "#666666" }}>
              Data clean room type
            </Typography>
          </Grid>
          <Grid
            display="flex"
            flexDirection="column"
            item={true}
            sm={4}
            xs={12}
          >
            <FormControl size="small">
              <RadioGroup sx={{ gap: "0.5rem" }}>
                <SelectableControlLabel
                  control={
                    <Radio
                      checked={activationType === ActivationType.Consentless}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setActivationType(ActivationType.Consentless);
                          if (!activationDownloadByPublisher) {
                            setActivationDownloadByPublisher(true);
                          }
                          if (!activationDownloadByAdvertiser) {
                            setActivationDownloadByAdvertiser(true);
                          }
                        }
                      }}
                      size="small"
                      sx={{ marginRight: "8px", padding: "2px" }}
                    />
                  }
                  disableTypography={true}
                  label="Top affinity segments"
                  selected={activationType === ActivationType.Consentless}
                  value="consentless"
                />
                <SelectableControlLabel
                  control={
                    <Radio
                      checked={activationType === ActivationType.Direct}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setActivationType(ActivationType.Direct);
                          if (!activationDownloadByPublisher) {
                            setActivationDownloadByPublisher(true);
                          }
                          if (activationDownloadByAdvertiser) {
                            setActivationDownloadByAdvertiser(false);
                          }
                        }
                      }}
                      size="small"
                      sx={{ marginRight: "8px", padding: "2px" }}
                    />
                  }
                  disableTypography={true}
                  label="Remarketing"
                  selected={activationType === ActivationType.Direct}
                  value="direct"
                />
                {ENABLE_MEDIA_PLANNING && (
                  <SelectableControlLabel
                    control={
                      <Radio
                        checked={activationType === null}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setActivationType(null);
                            if (activationDownloadByPublisher) {
                              setActivationDownloadByPublisher(false);
                            }
                            if (activationDownloadByAdvertiser) {
                              setActivationDownloadByAdvertiser(false);
                            }
                          }
                        }}
                        size="small"
                        sx={{ marginRight: "8px", padding: "2px" }}
                      />
                    }
                    disableTypography={true}
                    label="Media planning"
                    selected={activationType === null}
                    value="none"
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item={true} md={true} sm={8}>
            <StyledActivationTypeDescription activationType={activationType} />
          </Grid>
          <Grid item={true} md={4} xs={12}>
            <FormControl size="small">
              <FormGroup sx={{ gap: "0.5rem" }}>
                {activationType !== null ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activationDownloadByPublisher}
                        disabled={activationType !== ActivationType.Consentless}
                        onChange={(event) =>
                          setActivationDownloadByPublisher(event.target.checked)
                        }
                        size="small"
                        sx={{ marginRight: "8px", padding: "2px" }}
                      />
                    }
                    label={
                      <div>
                        <StyledCheckboxLabelTop
                          checked={activationDownloadByPublisher}
                        >
                          Allow download by Publisher
                        </StyledCheckboxLabelTop>
                        <StyledCheckboxLabelBottom>
                          This allows the Publisher to download the
                          {activationType === ActivationType.Consentless
                            ? " top affinity segments"
                            : " list of Activation IDs"}
                        </StyledCheckboxLabelBottom>
                      </div>
                    }
                    sx={{ marginLeft: "-4px" }}
                  />
                ) : null}
                {activationType === ActivationType.Consentless ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activationDownloadByAdvertiser}
                        disabled={activationType !== ActivationType.Consentless}
                        onChange={(event) =>
                          setActivationDownloadByAdvertiser(
                            event.target.checked
                          )
                        }
                        size="small"
                        sx={{ marginRight: "8px", padding: "2px" }}
                      />
                    }
                    label={
                      <div>
                        <StyledCheckboxLabelTop
                          checked={activationDownloadByAdvertiser}
                        >
                          Allow download by Advertiser
                        </StyledCheckboxLabelTop>
                        <StyledCheckboxLabelBottom>
                          This allows the Advertiser to download the top
                          affinity segments
                        </StyledCheckboxLabelBottom>
                      </div>
                    }
                    sx={{ marginLeft: "-4px" }}
                  />
                ) : null}
                {activationType === ActivationType.Consentless ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={enableOverlapInsights}
                        disabled={activationType !== ActivationType.Consentless}
                        onChange={(event) =>
                          setEnableOverlapInsights(event.target.checked)
                        }
                        size="small"
                        sx={{ marginRight: "8px", padding: "2px" }}
                      />
                    }
                    label={
                      <div>
                        <StyledCheckboxLabelTop checked={enableOverlapInsights}>
                          Segments insights
                        </StyledCheckboxLabelTop>
                        <StyledCheckboxLabelBottom>
                          This allows all parties to view statistics about the
                          segments
                        </StyledCheckboxLabelBottom>
                      </div>
                    }
                    sx={{ marginLeft: "-4px" }}
                  />
                ) : null}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
});

ConfigureStep.displayName = "ConfigureStep";

export default ConfigureStep;
