import { type lookalike_media_request, type Session } from "@decentriq/core";
import { useQuery } from "@tanstack/react-query";
import { loadAsync } from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApiCore, usePublishedLookalikeMediaDataRoom } from "contexts";
import { filterNullableOverlapInsightsRows } from "features/mediaDataRoomV2/components/LookalikeMediaInsights/helpers";
import { useQueryMediaInsightsComputeJob } from "features/mediaDataRoomV2/hooks";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  getViewModelledOverlapInsightsCacheKey,
  type ModelledOverlapInsightsCacheKey,
  type ModelledSegment,
  type ModelledSegmentsCollection,
} from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";

interface PublisherAudiencesOverlapInsightsHookPayload {
  session?: Session;
  skip?: boolean;
}

type PublisherAudiencesOverlapInsightsHookResult = {
  insights: ModelledSegment[];
  loading: boolean;
  error?: string;
  retry: () => Promise<void>;
};

const usePublisherAudiencesOverlapInsightsData = ({
  session,
  skip = true,
}: PublisherAudiencesOverlapInsightsHookPayload): PublisherAudiencesOverlapInsightsHookResult => {
  const { dataRoomId, driverAttestationHash } =
    usePublishedLookalikeMediaDataRoom();
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const jobType = "LOOKALIKE_MEDIA_MODELLED_OVERLAP_INSIGHTS_VIEW";
  const { client, sessionManager } = useApiCore();

  const jobCacheKey = useQuery({
    enabled: !skip,
    queryFn: async () => {
      return await getViewModelledOverlapInsightsCacheKey({
        client,
        dataRoomId,
        driverAttestationHash,
        forceRecompute: false,
        sessionManager,
      });
    },
    queryKey: ["lal-dcr-publisher-audiences-overlap-insights-cache-key"],
  });

  const computeJob = useQueryMediaInsightsComputeJob({
    createCacheKeyString: useCallback(
      async (key: ModelledOverlapInsightsCacheKey) => {
        const publishedDatasets =
          (await session?.retrievePublishedDatasets(key.dataRoomId))
            ?.publishedDatasets || [];
        return computeCacheKeyString({
          ...key,
          publishedDatasets,
        });
      },
      [session]
    ),
    createJob: useCallback(
      async ({
        jobCacheKeyString,
        key,
        session,
      }: {
        jobCacheKeyString: string;
        key: ModelledOverlapInsightsCacheKey;
        session: Session;
      }) => {
        const scopeId = await client.ensureDcrDataScope(key.dataRoomId);
        const request: lookalike_media_request.LookalikeMediaRequest = {
          calculateModelledAudienceInsightsView: {
            dataRoomIdHex: key.dataRoomId,
            scopeIdHex: scopeId,
          },
        };
        const response = await session.sendLookalikeMediaRequest(request);
        if (!("calculateModelledAudienceInsightsView" in response)) {
          throw new Error(
            "Expected calculateModelledAudienceInsightsView response"
          );
        }
        const computeNodeName =
          response.calculateModelledAudienceInsightsView.computeNodeName;
        const jobIdHex =
          response.calculateModelledAudienceInsightsView.jobIdHex;
        return {
          cacheKey: jobCacheKeyString,
          computeNodeName,
          jobIdHex,
          jobType,
          publishedDataRoomId: key.dataRoomId,
        };
      },
      [client]
    ),
    jobCacheKey: jobCacheKey.data ?? undefined,
    jobType,
    queryKeyPrefix: ["lal-dcr-publisher-audiences-overlap-insights"],
    session,
    skip,
    transform: useCallback(async (result: Uint8Array) => {
      const zip = await loadAsync(result);
      const modelledInsightsSegmentsFile = zip.file("segments.json");
      if (modelledInsightsSegmentsFile === null) {
        throw new Error("segments.json not found in zip");
      }
      const modelledInsightsSegments: ModelledSegmentsCollection = JSON.parse(
        await modelledInsightsSegmentsFile.async("string")
      );
      const publisherOverlapInsights = filterNullableOverlapInsightsRows(
        modelledInsightsSegments
      ) as ModelledSegment[];
      return publisherOverlapInsights;
    }, []),
  });

  useEffect(() => {
    if (computeJob.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          computeJob.error,
          `Cannot fetch Publisher overlap insights`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [computeJob.error, enqueueSnackbar, closeSnackbar, setErrorSnackbarId]);

  return {
    error: useMemo(() => computeJob.error, [computeJob.error]),
    insights: useMemo(
      () => computeJob.computeResults ?? [],
      [computeJob.computeResults]
    ),
    loading: ["COMPUTING", "FETCHING"].includes(computeJob.status),
    retry: useCallback(async () => computeJob.retry(), [computeJob]),
  };
};

export default usePublisherAudiencesOverlapInsightsData;
