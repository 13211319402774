import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
} from "features/datasets/components/ExternalConnections";

const SplickyFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    seatId: yup.string().trim().required("Seat ID is required"),
    userDefinedAudienceName: yup
      .string()
      .trim()
      .required("Audience name is required"),
  }),
});

type SplickyFormValues = yup.Asserts<typeof SplickyFormValidationSchema>;

const SplickyForm: React.FC<ExportDatasetFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
}) => {
  const splickyForm = useForm({
    defaultValues: {
      configuration: {
        seatId: "",
        userDefinedAudienceName: "",
      },
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(SplickyFormValidationSchema),
  });
  const { handleSubmit, reset, control } = splickyForm;

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: SplickyFormValues) => {
      const {
        configuration: { seatId, userDefinedAudienceName },
      } = formValues;

      onSubmit({
        input: {
          splicky: {
            seatId,
            userDefinedAudienceName,
          },
        },
      });
      reset();
    },
    [reset, onSubmit]
  );

  return (
    <>
      <FormProvider {...splickyForm}>
        <Box>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.seatId"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.seatId;
              return (
                <FormControl
                  error={!!fieldError}
                  fullWidth={true}
                  sx={{ mb: 1, mt: 1 }}
                >
                  <InputLabel>Seat ID</InputLabel>
                  <OutlinedInput
                    error={!!fieldError}
                    label="Seat ID"
                    placeholder="Example: 7165"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message || ""}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.userDefinedAudienceName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.userDefinedAudienceName;
              return (
                <FormControl error={!!fieldError} fullWidth={true}>
                  <InputLabel>Audience Name</InputLabel>
                  <OutlinedInput
                    error={!!fieldError}
                    fullWidth={true}
                    label="Audience Name"
                    placeholder="Example: DCR Audience"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message || ""}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </Box>
      </FormProvider>
      <ExternalConnectionActionsWrapper>
        <Button
          color="inherit"
          onClick={handlePreviousStepClick}
          variant="text"
        >
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Export
        </Button>
      </ExternalConnectionActionsWrapper>
    </>
  );
};

SplickyForm.displayName = "SplickyForm";

export default memo(SplickyForm);
