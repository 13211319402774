import { Fragment, memo } from "react";
import { EnableImmutabilityCheckbox } from "components";

interface ParticipantsInteractivitySettingsProps {
  id: string;
}

const ParticipantsInteractivitySettings: React.FC<ParticipantsInteractivitySettingsProps> =
  memo(({ id, ...restProps }) => {
    return (
      <Fragment>
        <EnableImmutabilityCheckbox idForDataRoom={id} {...restProps} />
      </Fragment>
    );
  });

ParticipantsInteractivitySettings.displayName =
  "ParticipantsInteractivitySettings";

export default ParticipantsInteractivitySettings;
