import { faDatabase } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, type SetStateAction, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { type ActivationType, type Audience } from "features/MediaInsightsDcr";
import { useStoreAsDataset } from "./useStoreAsDataset";

type SaveAsDatasetButtonProps = {
  activationType: ActivationType;
  audienceType: string;
  reach: number;
  activatedAudiences: Audience[];
  closeExportButtonPopover: () => void;
  setExportAudienceLoading: (isLoading: SetStateAction<boolean>) => void;
};

const SaveAsDatasetButton: React.FC<SaveAsDatasetButtonProps> = ({
  activationType,
  activatedAudiences,
  audienceType,
  reach,
  closeExportButtonPopover,
  setExportAudienceLoading,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, saveAsDataSet } = useStoreAsDataset({
    activatedAudiences,
    activationType,
    audienceType,
    reach,
  });

  const savePublisherAudienceAsDataset = useCallback(async () => {
    setExportAudienceLoading(true);
    closeExportButtonPopover();
    try {
      await saveAsDataSet();
      enqueueSnackbar({
        action: (
          <Button onClick={() => navigate("/datasets/external")}>
            Go to Datasets
          </Button>
        ),
        message: `${audienceType} result is being stored. Please check the status in the 'Datasets' page.`,
      });
    } finally {
      setExportAudienceLoading(false);
    }
  }, [
    audienceType,
    closeExportButtonPopover,
    enqueueSnackbar,
    navigate,
    saveAsDataSet,
    setExportAudienceLoading,
  ]);

  return (
    <LoadingButton
      color="inherit"
      loading={loading}
      loadingPosition="start"
      onClick={savePublisherAudienceAsDataset}
      startIcon={<FontAwesomeIcon icon={faDatabase} />}
      sx={({ spacing }) => ({
        "& .MuiButton-startIcon": {
          marginRight: spacing(1.5),
        },
        fontSize: "16px",
        fontWeight: 400,
        justifyContent: "flex-start",
        padding: spacing(1, 2),
      })}
      variant="text"
    >
      Store as dataset
    </LoadingButton>
  );
};

SaveAsDatasetButton.displayName = "SaveAsDatasetButton";

export default memo(SaveAsDatasetButton);
