import * as yup from "yup";
import { CollaborationTypes } from "models";

export const ActivationConfigurationFormValidationSchema = yup.object({
  advertiserDomains: yup
    .array()
    .of(yup.string().required())
    .when("matchAnyDomain", (matchAnyDomain, schema) =>
      matchAnyDomain
        ? schema.optional()
        : schema
            .min(1, "Please, enter at least one Advertiser Domain")
            .required()
    ),
  dataRoomTypes: yup
    .array()
    .of(yup.string().required())
    .required("Please, select at least one Collaboration type"),
  matchAnyDomain: yup.boolean().required(),
  matchingId: yup.string().required("Please, select the Matching ID"),
  name: yup.string().required("Configuration name is required"),
  publisherParticipants: yup
    .array()
    .of(yup.string().required())
    .min(1, "Please, select at least one Publisher Participant")
    .required("Please, select at least one Publisher Participant"),
});

export type ActivationConfigurationFormValues = yup.InferType<
  typeof ActivationConfigurationFormValidationSchema
>;

export const defaultActivationConfigurationFormValues: ActivationConfigurationFormValues =
  {
    advertiserDomains: [],
    dataRoomTypes: [],
    matchAnyDomain: false,
    matchingId: "",
    name: "",
    publisherParticipants: [],
  };

export const publisherPortalCollaborationTypes = [
  CollaborationTypes.Insights,
  CollaborationTypes.Retargeting,
  CollaborationTypes.ExclusionTargeting,
  CollaborationTypes.Lookalike,
  CollaborationTypes.Measurements,
];
