import { data_science } from "@decentriq/core";
import { type ApiCoreContextInterface } from "contexts";
import { maybeUseDataRoomSecret } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "../../../models";

export const makeRetrieveJobStatusResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (_obj: null, args: any, context: LocalResolverContext, _info: any) => {
    const { dcrHash, driverAttestationHash, job } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    const { status } = await wrapper.retrieveJobStatus(job);
    // TODO: map to correct type here
    return status;
  };
