import { Backdrop, CircularProgress, styled } from "@mui/material";
import { memo } from "react";

const Loading: React.FC<{ className?: string; loading?: boolean }> = ({
  className,
  loading = true,
}) => {
  return (
    <Backdrop className={className} open={loading}>
      <CircularProgress color="inherit" size="1rem" thickness={3} />
    </Backdrop>
  );
};

const StyledLoading = styled(Loading)({
  backgroundColor: "inherit",
  position: "absolute",
});

export default memo(StyledLoading);
