import {
  useOrganizationFeaturesQuery,
  useOrganizationLicenseQuery,
  useUpdateOrganizationFeaturesMutation,
  useUpdateOrganizationSettingsMutation,
} from "@decentriq/graphql/dist/hooks";
import { OrganizationState } from "@decentriq/graphql/dist/types";
import { Box, FormControl, FormGroup, FormLabel } from "@mui/material";
import { memo, useCallback } from "react";
import { Checkbox, Chip } from "components";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";

interface OrganizationFeaturesEditorProps {
  organizationId: string;
}

const OrganizationFeaturesEditor: React.FC<OrganizationFeaturesEditorProps> = ({
  organizationId,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  const {
    data: {
      organization: {
        hasAdvertiserFeatures = false,
        hasAnalyticsFeatures = false,
        hasPublisherFeatures = false,
        hasDataPartnerFeatures = false,
        canViewDataPartners = false,
        canViewMeasurements = false,
        allowExcludingSeedAudience = false,
        enableExtendedLookalikeQualityStatistics = false,
      } = {},
    } = {},
  } = useOrganizationFeaturesQuery({
    variables: { organizationId },
  });

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationLicense = organizationLicenseData?.organization?.state;

  const [updateOrganizationFeaturesMutation] =
    useUpdateOrganizationFeaturesMutation();
  const [updateOrganizationSettingsMutation] =
    useUpdateOrganizationSettingsMutation({
      onError: (error) => {
        enqueueSnackbar("Available feature settings could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
    });

  const updateOrganizationFeatures = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      try {
        await updateOrganizationFeaturesMutation({
          variables: {
            input: {
              id: organizationId,
              [target.name]: value,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar("Available feature settings could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      }
    },
    [organizationId, enqueueSnackbar, updateOrganizationFeaturesMutation]
  );

  // Updating this checkbox has to be in a separate mutation due to Access Policies on a backend
  const handleUpdateOrganizationSettings = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateOrganizationSettingsMutation({
        variables: {
          input: {
            enableExtendedLookalikeQualityStatistics: event?.target?.checked,
            organizationId,
          },
        },
      });
    },
    [organizationId, updateOrganizationSettingsMutation]
  );

  const isEditorDisabled =
    !organizationLicense ||
    [OrganizationState.Archived].includes(organizationLicense);

  const isPublisherCheckboxEnabled =
    organizationLicense === OrganizationState.Passive && isSuperAdmin;

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel>Features</FormLabel>
      {isSuperAdmin ? (
        <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
          <Checkbox
            checked={!!hasAdvertiserFeatures}
            disabled={isEditorDisabled}
            label="Advertiser features"
            name="hasAdvertiserFeatures"
            onChange={updateOrganizationFeatures}
            sx={{ ml: 0 }}
          />
          <Checkbox
            checked={!!hasAnalyticsFeatures}
            disabled={isEditorDisabled}
            label="Analytics features"
            name="hasAnalyticsFeatures"
            onChange={updateOrganizationFeatures}
            sx={{ ml: 0 }}
          />
          <Checkbox
            checked={!!hasPublisherFeatures}
            disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
            label="Publisher features"
            name="hasPublisherFeatures"
            onChange={updateOrganizationFeatures}
            sx={{ ml: 0 }}
          />
          <Checkbox
            checked={!!hasDataPartnerFeatures}
            disabled={isEditorDisabled}
            label="Data partner features"
            name="hasDataPartnerFeatures"
            onChange={updateOrganizationFeatures}
            sx={{ ml: 0 }}
          />
          <Checkbox
            checked={!!canViewDataPartners}
            disabled={isEditorDisabled}
            label="Can view data partners"
            name="canViewDataPartners"
            onChange={updateOrganizationFeatures}
            sx={{ ml: 0 }}
          />
          <Checkbox
            checked={!!canViewMeasurements}
            disabled={isEditorDisabled}
            label="Can view measurements"
            name="canViewMeasurements"
            onChange={updateOrganizationFeatures}
            sx={{ ml: 0 }}
          />
          <Checkbox
            checked={!!allowExcludingSeedAudience}
            disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
            label="Allow Excluding Seed Audience"
            name="allowExcludingSeedAudience"
            onChange={updateOrganizationFeatures}
            sx={{ ml: 0 }}
          />
        </FormGroup>
      ) : (
        <Box sx={{ mb: 0.5, mt: 0.5 }}>
          {hasAdvertiserFeatures && (
            <Chip label="Advertiser" sx={{ marginRight: 1 }} />
          )}
          {hasAnalyticsFeatures && (
            <Chip label="Analytics" sx={{ marginRight: 1 }} />
          )}
          {hasPublisherFeatures && (
            <Chip label="Publisher" sx={{ marginRight: 1 }} />
          )}
        </Box>
      )}
      <Checkbox
        checked={!!enableExtendedLookalikeQualityStatistics}
        disabled={isEditorDisabled}
        label="Enable Extended Lookalike Quality Statistics"
        name="enableExtendedLookalikeQualityStatistics"
        onChange={handleUpdateOrganizationSettings}
        sx={{ ml: 0 }}
      />
    </FormControl>
  );
};

OrganizationFeaturesEditor.displayName = "OrganizationFeaturesEditor";

export default memo(OrganizationFeaturesEditor);
