import { useUserDetailsQuery } from "@decentriq/graphql/dist/hooks";
import { UserRole } from "@decentriq/graphql/dist/types";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { UserDeleteDialog } from "components";
import { useUserRole } from "hooks";

interface UserDeleteButtonProps {
  userId: string;
  closeUserDetailsDialog: () => void;
}

const UserDeleteButton: React.FC<UserDeleteButtonProps> = ({
  userId,
  closeUserDetailsDialog,
}) => {
  const { data: { user } = {} } = useUserDetailsQuery({
    variables: { userId },
  });

  const { isOrganizationAdmin, isSuperAdmin } = useUserRole();

  const [
    isUserDeleteDialogOpen,
    { setTrue: openUserDeleteDialog, setFalse: closeUserDeleteDialog },
  ] = useBoolean(false);

  // User with lower role than SUPER_ADMIN cannot change role for super admin
  const isDeleteUserButtonDisabled =
    user?.userRole === UserRole.SuperAdmin && !isSuperAdmin;

  return isSuperAdmin || isOrganizationAdmin ? (
    <>
      <Button
        color="error"
        disabled={isDeleteUserButtonDisabled}
        onClick={(event) => {
          event.stopPropagation();
          openUserDeleteDialog();
        }}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />}
        sx={{ marginTop: 1, width: "fit-content" }}
      >
        Delete user
      </Button>
      <UserDeleteDialog
        closeUserDetailsDialog={closeUserDetailsDialog}
        onCancel={closeUserDeleteDialog}
        open={isUserDeleteDialogOpen}
        userId={userId}
      />
    </>
  ) : null;
};

UserDeleteButton.displayName = "UserDeleteButton";

export default memo(UserDeleteButton);
