import {
  type OptionsObject,
  type ProviderContext,
  type SnackbarAction,
  type SnackbarKey,
  useSnackbar,
  type VariantMap,
  type VariantType,
} from "notistack";
import { useCallback } from "react";
import * as uuid from "uuid";
import { CustomSnackbarActions, ErrorReportButton } from "components";

export interface SnackbarHookPayload {
  origin: string;
  withReportOnError?: boolean;
}

export type EnqueueSnackbarOptions<V extends VariantType> = OptionsObject<V> &
  VariantMap[V] & { context?: string };

export interface EnqueueSnackbar {
  <V extends VariantType>(
    message: string,
    options?: EnqueueSnackbarOptions<V>
  ): SnackbarKey;
}

export type SnackbarHookResult = Omit<ProviderContext, "enqueueSnackbar"> & {
  enqueueSnackbar: EnqueueSnackbar;
};

export enum CommonSnackbarOrigin {
  ADMIN = "ADMIN",
  KEYCHAIN = "KEYCHAIN",
  DASHBOARD = "DASHBOARD",
  DATA_LAB = "DATA_LAB",
  PUBLISHER_PORTAL = "PUBLISHER_PORTAL",
  DATASET_PORTAL = "DATASET_PORTAL",
  DATA_PARTNER_PORTAL = "DATA_PARTNER_PORTAL",
}

export const wrapSnackbarAction = ({
  suffixAction,
  variant,
  action,
  withReportOnError,
}: {
  action?: SnackbarAction;
  variant?: keyof VariantMap;
  withReportOnError: boolean;
  suffixAction?: (closeSnackBar: () => void) => React.ReactNode;
}): { action: SnackbarAction; id: SnackbarKey } => {
  const id = uuid.v4();
  const wrappedAction =
    variant === "error" && withReportOnError
      ? ((id: SnackbarKey) => {
          const originalAction = action
            ? typeof action === "function"
              ? action(id)
              : action
            : undefined;
          return (
            <CustomSnackbarActions
              id={id}
              render={(closeSnackbar) => [
                ...(originalAction ? [originalAction] : []),
                ...(suffixAction ? [suffixAction(closeSnackbar)] : []),
              ]}
            />
          );
        })(id)
      : action;
  return { action: wrappedAction, id };
};

export const useGeneralSnackbar = ({
  origin,
  ...payload
}: SnackbarHookPayload): SnackbarHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const withReportOnError = Boolean(payload?.withReportOnError ?? true);
  const handleEnqueueSnackbar: EnqueueSnackbar = useCallback(
    (message, options) => {
      const action = wrapSnackbarAction({
        action: options?.action,
        suffixAction: (closeSnackbar) => (
          <ErrorReportButton
            closeSnackbar={closeSnackbar}
            details={options?.context || ""}
            error={message}
            origin={origin}
          />
        ),
        variant: options?.variant,
        withReportOnError,
      });
      const effectiveOptions: typeof options =
        typeof options === "object"
          ? {
              // @ts-ignore
              ...options,
              ...action,
            }
          : { ...action };
      return enqueueSnackbar(message, effectiveOptions);
    },
    [enqueueSnackbar, origin, withReportOnError]
  );
  return {
    closeSnackbar,
    enqueueSnackbar: handleEnqueueSnackbar,
  };
};
