import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { memo, useCallback } from "react";
import { useAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";

const ExcludeSeedAudienceInfoDialog: React.FC = () => {
  const {
    excludeSeedAudience,
    generateLookalikeAudience,
    isExcludeSeedAudienceInfoDialogVisible,
    closeExcludeSeedAudienceInfoDialog,
  } = useAdvertiserAudienceGenerator();

  const handleContinueClick = useCallback(() => {
    generateLookalikeAudience();
    closeExcludeSeedAudienceInfoDialog();
  }, [closeExcludeSeedAudienceInfoDialog, generateLookalikeAudience]);

  return (
    <Dialog
      maxWidth="sm"
      onClose={closeExcludeSeedAudienceInfoDialog}
      open={isExcludeSeedAudienceInfoDialogVisible}
      role="alert"
    >
      <DialogContent>
        {excludeSeedAudience ? (
          <span>
            You have selected to <b>exclude</b> the matched seed audience in the
            lookalike audience. This means none of the users used for this
            activation will be ones that have matched, it will be the lookalikes
            “only”. All further audiences in this clean room will use the same
            setting. This is to reduce the risk of you users being singled out
            if you send very similar audiences with them included and excluded.
          </span>
        ) : (
          <span>
            You have selected to <b>include</b> the matched seed audience in the
            lookalike audience. This is typically recommended, but all further
            audiences in this clean room will use the same setting. This is to
            reduce the risk of you users being singled out if you send very
            similar audiences with them included and excluded.
          </span>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button color="inherit" onClick={closeExcludeSeedAudienceInfoDialog}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleContinueClick}
          variant="contained"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExcludeSeedAudienceInfoDialog.displayName = "ExcludeSeedAudienceInfoDialog";

export default memo(ExcludeSeedAudienceInfoDialog);
