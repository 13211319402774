import { testIds } from "@decentriq/utils";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  styled,
} from "@mui/material";
import { xor } from "lodash";
import difference from "lodash/difference";
import { memo, useCallback } from "react";

const StyledMenuItem = styled(MenuItem)({
  disabled: {
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    opacity: "1 !important",
  },
});

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": { color: theme.palette.primary.main },
}));

interface PermissionsSelectProps {
  options: { title: string; value: string; __typename?: string }[];
  permissions: string[];
  disabled?: boolean;
  onAddPermission: (value: string) => void;
  onRemovePermission: (value: string, title: string) => void;
  dataKey: string;
  disableSelectAll?: boolean;
  maxWidth?: string;
}

const DataRoomPermissionsSelect = ({
  disabled = false,
  options = [],
  permissions,
  onAddPermission,
  onRemovePermission,
  dataKey,
  disableSelectAll,
  maxWidth,
}: PermissionsSelectProps) => {
  const handleSelectAll = useCallback(() => {
    const permissionsValues = options.map(({ value }) => value);
    if (permissions.length !== options.length) {
      const addPermissions = difference(permissionsValues, permissions);
      addPermissions.forEach((value) => {
        onAddPermission(value);
      });
    } else {
      options.forEach(({ value, title }) => {
        onRemovePermission(value, title);
      });
    }
  }, [onAddPermission, onRemovePermission, options, permissions]);
  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<string[] | string>) => {
      if (!disabled) {
        const { value } = event.target;
        const effectiveValue =
          typeof value === "string" ? value.split(",") : (value as string[]);
        const diff = xor(effectiveValue, permissions || []);
        const focusedValue = diff[0];
        if (!focusedValue) {
          return;
        }
        if (focusedValue === "all") {
          handleSelectAll();
          return;
        }
        if (permissions.includes(focusedValue)) {
          onRemovePermission(
            focusedValue,
            options.find((o) => o.value === focusedValue)?.title || ""
          );
          return;
        }
        onAddPermission(focusedValue);
      }
    },
    [
      options,
      permissions,
      onAddPermission,
      onRemovePermission,
      handleSelectAll,
      disabled,
    ]
  );
  return (
    <Select<string[]>
      data-testid={`${testIds.dataroom.dataRoomPermissionSelect.helper}${dataKey}`}
      displayEmpty={true}
      fullWidth={true}
      multiple={true}
      onChange={handleSelectChange}
      renderValue={(selected) => {
        if (selected.length === 1) {
          const [selectedId] = selected;
          const node = options.find(
            ({ value: nodeId }) => nodeId === selectedId
          );
          const { __typename = "", title } = node || {};
          return ["DraftMatchNode", "PublishedMatchNode"].includes(__typename)
            ? `${title} — Statistics`
            : title;
        } else {
          const selectedPermissions = options
            .filter(({ value: id }) => selected.includes(id))
            .map(({ title }) => title);
          return `${selectedPermissions.length} ${dataKey}s`;
        }
      }}
      size="small"
      style={{ background: "transparent", maxWidth: maxWidth || "250px" }}
      value={permissions}
      variant="standard"
    >
      {!disableSelectAll && options.length > 1 && !disabled ? (
        <StyledMenuItem value="all">
          <StyledCheckbox
            checked={options.length === permissions.length}
            data-testid={testIds.dataroom.dataRoomPermissionSelect.selectAll}
            disabled={disabled}
          />
          <ListItemText primary={"Select all"} />
        </StyledMenuItem>
      ) : null}
      {options
        .filter(({ value }) => !disabled || permissions.includes(value))
        .map(({ __typename = "", value, title }) => (
          <StyledMenuItem
            data-testid={`${testIds.dataroom.dataRoomPermissionSelect.helper}${title}`}
            disabled={disabled}
            key={value}
            value={value}
          >
            {!disabled && (
              <StyledCheckbox checked={permissions.includes(value)} />
            )}
            <ListItemText
              primary={
                ["DraftMatchNode", "PublishedMatchNode"].includes(__typename)
                  ? `${title} — Statistics`
                  : title
              }
            />
          </StyledMenuItem>
        ))}
    </Select>
  );
};

DataRoomPermissionsSelect.displayName = "DataRoomPermissionsSelect";

export default memo(DataRoomPermissionsSelect);
