import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { JsonEditorField, Loading } from "components";

interface AdvertiserValidationReportDialogProps {
  data?: string;
  loading: boolean;
  onClose: () => void;
  open: boolean;
}

const AdvertiserValidationReportDialog: React.FC<
  AdvertiserValidationReportDialogProps
> = ({ data, loading, open, onClose }) => {
  const formattedValue =
    data && typeof data === "string"
      ? JSON.stringify(JSON.parse(data), null, 2)
      : data;
  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>Validation report for Advertiser audience table</span>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <JsonEditorField
            editorOptions={{
              lineNumbers: "off",
              readOnly: true,
              resizable: false,
            }}
            height={400}
            value={formattedValue}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdvertiserValidationReportDialog.displayName =
  "AdvertiserValidationReportDialog";
export default AdvertiserValidationReportDialog;
