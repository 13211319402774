import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelectedAudienceAggregations } from "features/mediaDataRoomV2/components/LookalikeMediaInsights/hooks";
import {
  type AggregationData,
  getAggregationPresentation,
  OverlapInsightsVisualizeDirection,
} from "features/mediaDataRoomV2/models";

export type OverlapInsightsHookReturn = {
  aggregationData: AggregationData[];
  // Indicates whether length of selected aggregation is 0 or no
  isEmpty: boolean;
};

// Hook that takes all available overlap data and transforms into
// more comfortable data structure based on selected filtering values
const useLookalikeMediaOverlapInsights = (): OverlapInsightsHookReturn => {
  const { watch } = useFormContext();
  const {
    audienceType: selectedAudienceType,
    aggregation: selectedAggregation,
    visualize,
  } = watch();

  const [aggregationData, setAggregationData] = useState<AggregationData[]>([]);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const aggregations = useSelectedAudienceAggregations(selectedAudienceType);

  useEffect(() => {
    const {
      aggregation = [],
      rows = [],
      columns = [],
    } = aggregations.find(({ id }) => id === selectedAggregation) || {};

    const shareInOverlapDataIndex = columns.indexOf("share_in_overlap");
    const affinityRatioDataIndex = columns.indexOf("affinity_ratio");
    const shareInAddressableAudienceDataIndex = columns.indexOf(
      "share_in_addressable_audience"
    );
    const addressableAudienceSizeDataIndex = columns.indexOf(
      "addressable_audience_size"
    );
    const countUsersOverlapDataIndex = columns.indexOf("count_users_overlap");
    const interestDataIndex = columns.indexOf("interest");
    const ageDataIndex = columns.indexOf("age");
    const genderDataIndex = columns.indexOf("gender");

    const orderByColumnIndex = columns?.indexOf(visualize?.orderBy);
    // Items should always be ordered by descending, by column, selected in the By selector (orderBy value)
    // Amount of items (limit) define how many items are shown, so slice is used
    // Value of visualize.direction defines whether TOP or BOTTOM {visualize.limit} rows selected
    const selectedRows = orderBy(rows, (row) => row[orderByColumnIndex], [
      "desc",
    ]);
    const slicedRows =
      visualize.direction === OverlapInsightsVisualizeDirection.top
        ? selectedRows.slice(0, visualize?.limit)
        : selectedRows.slice(selectedRows.length - visualize?.limit);

    const aggregationData = slicedRows?.map((dataRow) => ({
      addressableAudienceSize: dataRow[addressableAudienceSizeDataIndex],
      affinityRatio: dataRow[affinityRatioDataIndex],
      age: dataRow[ageDataIndex],
      aggregationName: dataRow?.slice(0, aggregation.length)?.join(", "),
      countUsersOverlap: dataRow[countUsersOverlapDataIndex],
      gender: dataRow[genderDataIndex],
      interest: getAggregationPresentation(dataRow[interestDataIndex]),
      shareInAddressableAudience: dataRow[shareInAddressableAudienceDataIndex],
      shareInOverlap: dataRow[shareInOverlapDataIndex],
    }));

    const isEmpty =
      !slicedRows.length ||
      aggregationData.every(({ shareInOverlap }) => shareInOverlap === 0);

    setAggregationData(aggregationData);
    setIsEmpty(isEmpty);
  }, [
    aggregations,
    selectedAggregation,
    selectedAudienceType,
    visualize?.direction,
    visualize?.limit,
    visualize?.orderBy,
  ]);

  return { aggregationData, isEmpty };
};

export default useLookalikeMediaOverlapInsights;
