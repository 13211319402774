import { faChartMixed } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { memo } from "react";
import { LookalikeMediaInsightsDialog } from "features/mediaDataRoomV2";
import { useLookalikeMediaOverlapInsightsContext } from "features/mediaDataRoomV2/contexts";

const ActivationInsightsButton: React.FC = () => {
  const { isInsightsDialogOpen, closeInsightsDialog, openInsightsDialog } =
    useLookalikeMediaOverlapInsightsContext();
  return (
    <>
      <LoadingButton
        color="inherit"
        loadingPosition="start"
        onClick={openInsightsDialog}
        startIcon={<FontAwesomeIcon icon={faChartMixed} />}
        variant="text"
      >
        See Insights
      </LoadingButton>
      {isInsightsDialogOpen && (
        <LookalikeMediaInsightsDialog
          onClose={closeInsightsDialog}
          open={isInsightsDialogOpen}
        />
      )}
    </>
  );
};

ActivationInsightsButton.displayName = "ActivationInsightsButton";

export default memo(ActivationInsightsButton);
