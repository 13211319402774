import { InfoTooltip } from "@decentriq/components";
import { FormControl, FormLabel, TextField } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ExternalConnectionCredentialsLabel } from "features/datasets/components/ExternalConnections";

const GoogleCloudStorageFormCredentials: React.FC = () => {
  const { control } = useFormContext();
  return (
    <>
      <ExternalConnectionCredentialsLabel />
      <Controller
        control={control}
        name="credentials.credentialsFile"
        render={({ field: { value, onChange, ...restField }, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.credentials?.credentialsFile;
          return (
            <FormControl sx={{ marginTop: 0.5 }}>
              <FormLabel component="legend" sx={{ marginBottom: 0.5 }}>
                Service account key in JSON format
                <InfoTooltip
                  tooltip={
                    <>
                      Please{" "}
                      <a
                        href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                        rel="noreferrer"
                        style={{ color: "inherit" }}
                        target="_blank"
                      >
                        follow the instructions
                      </a>{" "}
                      to obtain a service account key
                    </>
                  }
                />
              </FormLabel>
              <TextField
                {...restField}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    accept: "application/JSON",
                    multiple: false,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                      onChange((event?.target?.files || [])[0]),
                    type: "file",
                  },
                  sx: {
                    "& .MuiInput-input": { padding: "0 0 7px" },
                  },
                }}
                error={!isEmpty(fieldError)}
                helperText={fieldError?.message}
                size="small"
                type="file"
                value={value?.fileName}
                variant="standard"
              />
            </FormControl>
          );
        }}
      />
    </>
  );
};

GoogleCloudStorageFormCredentials.displayName =
  "GoogleCloudStorageFormCredentials";

export default memo(GoogleCloudStorageFormCredentials);
