import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { faDotCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio, type RadioProps, styled } from "@mui/material";
import { memo, useMemo } from "react";

interface FontAwesomeRadioboxProps extends RadioProps {
  className?: string;
}

const FontAwesomeRadiobox = memo<FontAwesomeRadioboxProps>(
  ({ className, ...rest }) => {
    const icon = useMemo(
      () => <FontAwesomeIcon fixedWidth={true} icon={faCircle} />,
      []
    );
    const checkedIcon = useMemo(
      () => <FontAwesomeIcon fixedWidth={true} icon={faDotCircle} />,
      []
    );
    const TouchRippleProps = { center: false };
    return (
      <Radio
        TouchRippleProps={TouchRippleProps}
        checkedIcon={checkedIcon}
        className={className}
        color="default"
        icon={icon}
        {...rest}
      />
    );
  }
);

const StyledFontAwesomeRadiobox = styled(FontAwesomeRadiobox)(({ theme }) => ({
  "&.Mui-disabled": {
    color: theme.palette.action.disabled,
    pointerEvents: "none",
  },
  borderRadius: "100%",
  color: "inherit",
  padding: theme.spacing(0.5),
}));

export default StyledFontAwesomeRadiobox;
