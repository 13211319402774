import { faFileExport, faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { format, isDate } from "date-fns";
import { saveAs } from "file-saver";
import { parse as parseCsv } from "papaparse";
import { memo, useCallback, useMemo } from "react";
import { type TableCellProps } from "react-virtualized";
import { VirtualizedTable } from "components";

enum AuditLogRecordType {
  StopDataRoomRequest = "StopDataRoomRequest",
  RetrieveAuditLogRequest = "RetrieveAuditLogRequest",
  ReturningQueryResults = "ReturningQueryResults",
  SqlQueryRequest = "SqlQueryRequest",
  CreateDataRoomRequest = "CreateDataRoomRequest",
  PublishDatasetToDataRoomRequest = "PublishDatasetToDataRoomRequest",
}

const auditLogRecordPrioryty: AuditLogRecordType[] = [
  AuditLogRecordType.CreateDataRoomRequest,
  AuditLogRecordType.PublishDatasetToDataRoomRequest,
  AuditLogRecordType.SqlQueryRequest,
  AuditLogRecordType.ReturningQueryResults,
  AuditLogRecordType.StopDataRoomRequest,
  AuditLogRecordType.RetrieveAuditLogRequest,
];

interface DataRoomLogsPreviewProps {
  logs?: string;
  asTable?: boolean;
  onFetchLogs?: () => void;
  isLoading?: boolean;
}

const columnsWidthFactors = [0.8, 1.2, 5];
const columnsHeaders = ["Event date", "Performed by", "Description"];

const DataRoomLogsPreview: React.FC<DataRoomLogsPreviewProps> = memo(
  ({ logs, asTable = false, onFetchLogs, isLoading }) => {
    const handleExport = useCallback((logs?: string) => {
      // TODO: remove sorting when that will be implemented in enclave
      const logsData = (logs?.split("\n") || []).reverse();
      const file = new File([logsData.join("\n")], `Audit.csv`, {
        type: "application/octet-stream;charset=utf-8",
      });
      saveAs(file);
    }, []);
    const logsData = useMemo<{ data: string[][]; rowLength: number }>(() => {
      if (asTable && logs) {
        const { data, errors } = parseCsv<string[]>(logs);
        if (!errors.length) {
          return {
            data: data
              .filter((r) => r.some((e) => e))
              .map((r) => {
                const timestamp = r[0];
                const currentTimestamp = Date.now().toString();
                const date = new Date(
                  Number(timestamp) *
                    (currentTimestamp.length === timestamp.length ? 1 : 1000)
                );
                if (isDate(date)) {
                  r[0] = date.getTime().toString();
                } else {
                  r[0] = "";
                }
                return r;
              })
              // TODO: remove sorting when that will be implemented in enclave
              .sort((a, b) => {
                const diff = Number(b[0]) - Number(a[0]);
                if (diff === 0) {
                  const aType = a[3];
                  const bType = b[3];
                  return (
                    auditLogRecordPrioryty.indexOf(
                      bType as AuditLogRecordType
                    ) -
                    auditLogRecordPrioryty.indexOf(aType as AuditLogRecordType)
                  );
                }
                return diff;
              }),
            rowLength: 3,
          };
        }
        return { data: [], rowLength: 0 };
      }
      return { data: [], rowLength: 0 };
    }, [logs, asTable]);
    const hasLogs = logs && logs.trim().length;
    const columns = asTable
      ? Array.from({ length: logsData.rowLength }).map((_, i) => ({
          dataKey: i.toString(),
          label: columnsHeaders[i],
          widthFactor: columnsWidthFactors[i],
        }))
      : [];
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div>
          <Grid
            container={true}
            flexDirection="column"
            item={true}
            justifyContent="space-between"
            style={{ marginBottom: ".5rem" }}
            xs={12}
          >
            <Box sx={({ spacing }) => ({ marginBottom: spacing(2) })}>
              <Typography sx={{ lingHeight: "24px", mb: 1 }} variant="h6">
                Tamper-proof audit log
              </Typography>
              <Typography variant="body1">
                The audit log tracks all operations by any participant in this
                data clean room. <br />
                It is integrity-protected by the enclave and thus tamper-proof.
              </Typography>
            </Box>
            <Box>
              <Button
                color="inherit"
                disabled={isLoading}
                onClick={onFetchLogs}
                size="small"
                startIcon={
                  isLoading ? (
                    <CircularProgress color="inherit" size={18} thickness={3} />
                  ) : (
                    <FontAwesomeIcon fixedWidth={true} icon={faSync} />
                  )
                }
                style={{ marginRight: "1rem" }}
              >
                {isLoading ? "Loading" : "Refresh"}
              </Button>
              {hasLogs && (
                <Button
                  color="inherit"
                  disabled={!hasLogs}
                  onClick={() => handleExport(logs)}
                  size="small"
                  startIcon={
                    <FontAwesomeIcon fixedWidth={true} icon={faFileExport} />
                  }
                  style={{ marginRight: "1rem" }}
                >
                  Export audit log
                </Button>
              )}
            </Box>
          </Grid>
        </div>
        {asTable ? (
          <VirtualizedTable
            cellRenderer={({ cellData, columnIndex }: TableCellProps) => {
              if (columnIndex !== 0) {
                return cellData;
              }
              if (!cellData) {
                return "Invalid date";
              }
              return (
                <span>
                  {format(new Date(Number(cellData)), "HH:mm:ss dd-MM-yyyy")}
                </span>
              );
            }}
            columns={columns}
            minColumnWidth={600}
            rowCount={logsData.data.length}
            rowGetter={({ index }) => logsData.data[index]}
            rowHeight={38}
            withHeader={true}
          />
        ) : (
          <div style={{ flex: "1", overflowY: "scroll" }}>
            <textarea
              defaultValue={logs}
              readOnly={true}
              style={{
                maxWidth: "100%",
                minHeight: "5rem",
                minWidth: "100%",
              }}
            />
          </div>
        )}
      </div>
    );
  }
);

DataRoomLogsPreview.displayName = "DataRoomLogsPreview";

export default DataRoomLogsPreview;
