import { memo } from "react";
import { useParams } from "react-router-dom";
import { MediaDataRoom } from "features/MediaInsightsDcr";
import { Main } from "layouts";

const MediaInsightsDcrPage: React.FC = () => {
  const { dataRoomId } = useParams();
  return (
    <Main>
      <MediaDataRoom id={dataRoomId!} />
    </Main>
  );
};

export default memo(MediaInsightsDcrPage);
