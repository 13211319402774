import { testIds } from "@decentriq/utils";
import {
  faCaretDown as faCaretDownSolid,
  faCaretRight as faCaretRightSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import { memo, useCallback } from "react";
import { useComputeNodesVars } from "contexts";

interface ComputeNodeExpandButtonProps {
  computeNodeId: string;
  className?: string;
}

const ComputeNodeExpandButton: React.FC<ComputeNodeExpandButtonProps> = memo(
  ({ computeNodeId }) => {
    const { isExpanded, toggle } = useComputeNodesVars();
    const expandComputeNode = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        toggle(computeNodeId);
      },
      [computeNodeId, toggle]
    );
    return (
      <Tooltip
        placement="top"
        title={isExpanded(computeNodeId) ? "Collapse" : "Expand"}
      >
        <IconButton
          color="inherit"
          data-testid={testIds.computeNode.computeNodeCreator.toggleEditor}
          onClick={expandComputeNode}
        >
          <FontAwesomeIcon
            fixedWidth={true}
            icon={
              isExpanded(computeNodeId) ? faCaretDownSolid : faCaretRightSolid
            }
          />
        </IconButton>
      </Tooltip>
    );
  }
);
ComputeNodeExpandButton.displayName = "ComputeNodeExpandButton";

export default ComputeNodeExpandButton;
