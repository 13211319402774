import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";
import { Typography } from "@mui/material";
import { memo } from "react";

type GoogleDV360ConnectionConfigurationProps = {
  connectionId: string;
};

const GoogleDV360ConnectionConfiguration: React.FC<
  GoogleDV360ConnectionConfigurationProps
> = ({ connectionId }) => {
  const {
    data: { dataConnectorJob: { config: datasetExportConfig = {} } = {} } = {},
  } = useDataConnectorJobQuery({
    variables: { id: connectionId },
  });
  const { advertiserId, displayName, description, membershipDurationDays } =
    datasetExportConfig || {};

  return (
    <div>
      <Typography variant="body2">
        <strong>Advertiser ID:</strong> {advertiserId}
      </Typography>
      <Typography variant="body2">
        <strong>Display name:</strong> {displayName}
      </Typography>
      <Typography variant="body2">
        <strong>Description:</strong> {description || '""'}
      </Typography>
      <Typography variant="body2">
        <strong>Membership duration in days:</strong> {membershipDurationDays}
      </Typography>
    </div>
  );
};

GoogleDV360ConnectionConfiguration.displayName =
  "GoogleDV360ConnectionConfiguration";

export default memo(GoogleDV360ConnectionConfiguration);
