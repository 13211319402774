import { DqCard, DqCardContent } from "@decentriq/components";
import { DataSourceType } from "@decentriq/graphql/dist/types";
import { Grid, styled, Typography } from "@mui/material";
import { memo } from "react";
import {
  dataSourceTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { useDocsLink } from "hooks";

const DocsLink = styled("a")({
  alignItems: "baseline",
  color: "inherit",
});

const WITH_AZURE = true;

type ExternalDataSourceSelectorProps = {
  onTypeChange: (type: DataSourceType) => void;
  selectedType: DataSourceType | null;
};

const ExternalDataSourceSelector: React.FC<ExternalDataSourceSelectorProps> = ({
  onTypeChange: setSelectedDataSource,
  selectedType: selectedDataSource,
}) => {
  const docsBaseLink = useDocsLink();
  const dataSourceOptions: DataSourceType[] = [
    DataSourceType.S3,
    ...(WITH_AZURE ? [DataSourceType.Azure] : []),
    DataSourceType.GoogleCloudStorage,
    DataSourceType.Snowflake,
    DataSourceType.Salesforce,
    DataSourceType.Permutive,
  ];
  return (
    <Grid container={true} item={true} spacing={3} xs={12}>
      <Grid container={true} item={true} rowSpacing={1} xs={5}>
        {dataSourceOptions.map((dataSource) => (
          <Grid item={true} key={dataSource} xs={12}>
            <DqCard
              onSelect={() => setSelectedDataSource(dataSource)}
              selected={dataSource === selectedDataSource}
            >
              <DqCardContent
                sx={{
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <ExternalConnectionsIcon
                  connectionType={dataSource}
                  size={ExternalConnectionsIconSize.sm}
                />
                <Typography fontWeight="fontWeightSemiBold" sx={{ ml: 0.5 }}>
                  {dataSourceTypePresentation.get(dataSource)}
                </Typography>
              </DqCardContent>
            </DqCard>
          </Grid>
        ))}
      </Grid>
      <Grid item={true} xs={7}>
        {selectedDataSource === DataSourceType.S3 && (
          <>
            <Typography mb={1}>
              This will import any object from an Amazon S3 Bucket and store it
              as a dataset in the Decentriq platform.
            </Typography>
            <Typography>
              Note: The imported file/dataset will be stored as is, without any
              data manipulation.
            </Typography>
          </>
        )}
        {WITH_AZURE && selectedDataSource === DataSourceType.Azure && (
          <>
            <Typography mb={1}>
              This will import any object from an Azure Blob Storage Container
              and store it as a dataset in the Decentriq platform.
            </Typography>
            <Typography>
              Note: The imported file/dataset will be stored as is, without any
              data manipulation.
            </Typography>
          </>
        )}
        {selectedDataSource === DataSourceType.GoogleCloudStorage && (
          <>
            <Typography mb={1}>
              This will import any object from a Google Cloud Storage Bucket and
              store it as a dataset in the Decentriq platform.
            </Typography>
            <Typography>
              Note: The imported file/dataset will be stored as is, without any
              data manipulation.
            </Typography>
          </>
        )}
        {selectedDataSource === DataSourceType.Snowflake && (
          <>
            <Typography mb={1}>
              This will import the contents of any table or query data unloaded
              to a Snowflake stage, and store it as a dataset in the Decentriq
              platform.
            </Typography>
            <Typography>
              Note: please{" "}
              <DocsLink
                href={`${docsBaseLink}/data-management/import/from-external-source/supported-sources#snowflake`}
                rel="noreferrer"
                target="_blank"
              >
                follow the instructions
              </DocsLink>{" "}
              to prepare your data for import.
            </Typography>
          </>
        )}
        {selectedDataSource === DataSourceType.Salesforce && (
          <>
            <Typography mb={1}>
              This will import any object from Salesforce and store it as a
              dataset in the Decentriq platform.
            </Typography>
            <Typography>
              Note: The imported file/dataset will be stored as is, without any
              data manipulation.
            </Typography>
          </>
        )}
        {selectedDataSource === DataSourceType.Permutive && (
          <>
            <Typography mb={1}>
              This will import Publisher datasets from Permutive via a Cloud
              Storage service and store them as datasets in the Decentriq
              platform. These datasets can then be provisioned directly to
              Decentriq datalabs for Publishers.
            </Typography>
            {/* <Typography>
                Note: To prepare your datasets for importing, please follow the{" "}
                <a
                  // href={""}
                  rel="noreferrer"
                  style={{ color: "inherit" }}
                  target="_blank"
                >
                  Permutive guide
                </a>
              </Typography> */}
          </>
        )}
      </Grid>
    </Grid>
  );
};

ExternalDataSourceSelector.displayName = "ExternalDataSourceSelector";

export default memo(ExternalDataSourceSelector);
