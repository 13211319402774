import { Box, Paper, Typography } from "@mui/material";
import { type PointTooltipProps, ResponsiveLine } from "@nivo/line";
import { memo, useMemo } from "react";
import { useAudienceOverlapInsightsData } from "features/mediaDataRoom/hooks";
import {
  BASE_PROPENSITY_CHART_KEY,
  OVERLAP_PROPENSITY_CHART_KEY,
} from "../../constants/index";

type LineChartProps = {
  selectedSegments: string[];
  selectedAudienceType: string;
};

const LineTooltip: React.FC<PointTooltipProps> = ({
  point: { serieId, data } = {},
}) => (
  <Paper sx={{ padding: "4px" }}>
    <Typography>
      <>
        <b>Segment:</b> {data?.x}
      </>
    </Typography>
    <Typography>
      <>
        <b>{serieId}:</b> {data?.y}%
      </>
    </Typography>
  </Paper>
);

const LineChart: React.FC<LineChartProps> = ({
  selectedSegments = [],
  selectedAudienceType,
}) => {
  const { overlapInsights } =
    useAudienceOverlapInsightsData(selectedAudienceType);

  const selectedSegmentsData = overlapInsights.filter(({ segment }) =>
    selectedSegments.includes(segment)
  );
  const lineChartData = useMemo(
    () => [
      {
        data: selectedSegmentsData.map(({ segment, overlapPropensity }) => ({
          x: segment,
          y: overlapPropensity,
        })),
        id: OVERLAP_PROPENSITY_CHART_KEY,
      },
      {
        data: selectedSegmentsData.map(({ segment, basePropensity }) => ({
          x: segment,
          y: basePropensity,
        })),
        id: BASE_PROPENSITY_CHART_KEY,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSegmentsData.length]
  );

  return (
    <Box sx={{ height: "calc(100vh - 600px)", minHeight: "300px" }}>
      <ResponsiveLine
        axisLeft={{ format: (value) => `${value}%` }}
        {...(selectedSegmentsData.length > 6 ? { axisBottom: null } : {})}
        colors={["#37C7C7", "#2FA5E6"]}
        data={lineChartData}
        enableArea={true}
        enableCrosshair={true}
        isInteractive={true}
        legends={[
          {
            anchor: "top-left",
            direction: "row",
            itemDirection: "left-to-right",
            itemHeight: 20,
            itemOpacity: 0.75,
            itemWidth: 0,
            itemsSpacing: 170,
            justify: false,
            symbolSize: 12,
            translateX: -32,
            translateY: -32,
          },
        ]}
        margin={{
          bottom: selectedSegmentsData.length > 6 ? 8 : 32,
          left: 40,
          right: 32,
          top: 32,
        }}
        tooltip={LineTooltip}
        useMesh={true}
      />
    </Box>
  );
};

LineChart.displayName = "LineChart";

export default memo(LineChart);
