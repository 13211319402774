import { useApolloClient } from "@apollo/client";
import {
  useMediaInviteParticipantsMutation,
  useMediaPublishDraftDataRoomMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type MediaPublishDraftDataRoomInput,
  MyDataRoomsDocument,
} from "@decentriq/graphql/dist/types";
import { Dialog } from "@mui/material";
import { useSafeState } from "ahooks";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  MediaDataRoomCreateProgress,
  MediaDataRoomCreateWizard,
  MediaDataRoomCreateWizardWrapper,
} from "features/mediaDataRoom";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import {
  dataRoomPathBuilder,
  type DataRoomType,
  DataRoomTypeNames,
} from "models";
import { getEffectiveErrorMessage } from "utils";
import usePublishLookalikeMediaDataRoom, {
  type PublishLookalikeMediaDataRoomInput,
} from "./usePublishLookalikeMediaDataRoom";
import useValidateMediaDataRoom from "./useValidateMediaDataRoom";

const delay = (ms: number): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

const noSoonerThan = async <T,>(
  ms: number,
  promise: Promise<T>
): Promise<T> => {
  const [result] = await Promise.all([promise, delay(ms)]);
  return result;
};

interface MediaDataRoomCreateDialogProps {
  dataRoomType: DataRoomType;
  open: boolean;
  onCancel: () => void;
}

const MediaDataRoomCreateDialog: React.FC<MediaDataRoomCreateDialogProps> =
  memo(({ open, onCancel, dataRoomType }) => {
    const apolloClient = useApolloClient();
    const { enqueueSnackbar } = useGeneralSnackbar({
      origin: CommonSnackbarOrigin.DASHBOARD,
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useSafeState(false);
    const [
      mediaPublishDraftDataRoom,
      {
        loading: publishDraftMediaDataRoomLoading,
        called: publishDraftMediaDataRoomCalled,
        error: publishDraftMediaDataRoomError,
      },
    ] = useMediaPublishDraftDataRoomMutation({
      onError: (error) => {
        enqueueSnackbar("Data clean room could not be published.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
      refetchQueries: ["MyDataRooms"],
    });
    const [mediaInviteParticipants] = useMediaInviteParticipantsMutation({
      onError: (error) => {
        enqueueSnackbar("Data clean room participants could not be invited.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
    });
    const closeMediaDataRoomCreateDialog = useCallback(() => {
      onCancel();
    }, [onCancel]);
    const { validateMediaDataRoom, validateLookalikeMediaDataRoom } =
      useValidateMediaDataRoom();
    const { publish: publishLookalikeMediaDataRoom } =
      usePublishLookalikeMediaDataRoom();
    const submitMediaDataRoomCreateDialog = useCallback(
      async (mediaDataRoomInput: MediaPublishDraftDataRoomInput) => {
        const isMediaDataRoomValid = validateMediaDataRoom(mediaDataRoomInput);
        if (!isMediaDataRoomValid) {
          return;
        }
        setLoading(true);
        try {
          await delay(1000);
          const result = await noSoonerThan(
            1000,
            mediaPublishDraftDataRoom({
              variables: mediaDataRoomInput,
            })
          );
          const dataRoomId = result?.data?.mediaPublishDraftDataRoom.id!;
          const driverAttestationHash =
            result?.data?.mediaPublishDraftDataRoom.driverAttestationHash!;
          await noSoonerThan(
            1000,
            mediaInviteParticipants({
              variables: {
                input: {
                  dataRoomDescription: "",
                  publishedDataRoomDriverAttestationHash: driverAttestationHash,
                  publishedDataRoomEnclaveId: dataRoomId,
                },
              },
            })
          );
          await delay(1000);
          closeMediaDataRoomCreateDialog();
          navigate(
            dataRoomPathBuilder(
              dataRoomId,
              DataRoomTypeNames.PublishedMediaDataRoom
            )
          );
        } catch (error) {
          enqueueSnackbar(`Failed to create Media DCR`, {
            context: getEffectiveErrorMessage(error),
            persist: true,
            variant: "error",
          });
        } finally {
          setLoading(false);
        }
      },
      [
        mediaPublishDraftDataRoom,
        mediaInviteParticipants,
        closeMediaDataRoomCreateDialog,
        navigate,
        setLoading,
        validateMediaDataRoom,
        enqueueSnackbar,
      ]
    );

    const submitLookalikeMediaDataRoomCreateDialog = useCallback(
      async (mediaDataRoomInput: PublishLookalikeMediaDataRoomInput) => {
        const isMediaDataRoomValid =
          validateLookalikeMediaDataRoom(mediaDataRoomInput);
        if (!isMediaDataRoomValid) {
          return;
        }
        setLoading(true);
        try {
          await delay(1000);
          const { id: dataRoomId, driverAttestationHash } = await noSoonerThan(
            1000,
            publishLookalikeMediaDataRoom(mediaDataRoomInput)
          );
          await noSoonerThan(
            1000,
            mediaInviteParticipants({
              variables: {
                input: {
                  dataRoomDescription: "",
                  publishedDataRoomDriverAttestationHash: driverAttestationHash,
                  publishedDataRoomEnclaveId: dataRoomId,
                },
              },
            })
          );
          await delay(1000);
          closeMediaDataRoomCreateDialog();
          await apolloClient.query({
            errorPolicy: "ignore",
            fetchPolicy: "network-only",
            query: MyDataRoomsDocument,
          });
          navigate(
            dataRoomPathBuilder(
              dataRoomId,
              DataRoomTypeNames.PublishedLookalikeMediaDataRoom
            )
          );
        } catch (error) {
          enqueueSnackbar(`Data clean room could not be published.`, {
            context: getEffectiveErrorMessage(error),
            persist: true,
            variant: "error",
          });
        } finally {
          setLoading(false);
        }
      },
      [
        validateLookalikeMediaDataRoom,
        publishLookalikeMediaDataRoom,
        enqueueSnackbar,
        mediaInviteParticipants,
        closeMediaDataRoomCreateDialog,
        setLoading,
        navigate,
        apolloClient,
      ]
    );

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open} scroll="paper">
        {loading ? (
          <MediaDataRoomCreateProgress
            publishing={publishDraftMediaDataRoomLoading}
            publishingCalled={publishDraftMediaDataRoomCalled}
            publishingError={publishDraftMediaDataRoomError}
          />
        ) : (
          <MediaDataRoomCreateWizardWrapper dataRoomType={dataRoomType}>
            <MediaDataRoomCreateWizard
              closeDialog={closeMediaDataRoomCreateDialog}
              submitLookalikeMediaDataRoom={
                submitLookalikeMediaDataRoomCreateDialog
              }
              submitMediaDataRoom={submitMediaDataRoomCreateDialog}
            />
          </MediaDataRoomCreateWizardWrapper>
        )}
      </Dialog>
    );
  });

export default MediaDataRoomCreateDialog;
