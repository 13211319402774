import { SignSubmittedDataRoomRequestDocument } from "@decentriq/graphql/dist/hooks";
import {
  type ApproveSubmittedDataRoomRequestPayload,
  MergeSubmittedDataRoomRequestDocument,
  type MutationSignSubmittedDataRoomRequestArgs,
  type SignSubmittedDataRoomRequestMutation,
  type SignSubmittedDataRoomRequestMutationVariables,
  SubmittedDataRoomRequestProcessingStatus,
  SubmittedDataRoomRequestSignaturesDocument,
  type SubmittedDataRoomRequestSignaturesQuery,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextInterface } from "contexts";
import { maybeUseDataRoomSecret } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeApproveSubmittedDataRoomRequestResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: any,
    args: MutationSignSubmittedDataRoomRequestArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<ApproveSubmittedDataRoomRequestPayload> => {
    const { id, commitId, driverAttestationHash, dcrHash } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
    const currentUserEmail = sdkSession.metaData.email;
    const {
      data: {
        submittedDataRoomRequest: {
          signers: { nodes: signers },
        },
      },
    } = await context.client.query<SubmittedDataRoomRequestSignaturesQuery>({
      query: SubmittedDataRoomRequestSignaturesDocument,
      variables: {
        id,
      },
    });
    const isCurrentUserApprover = signers.some(
      (s) => s.userEmail === currentUserEmail
    );
    if (!isCurrentUserApprover) {
      throw new Error("Not allowed for current user");
    }
    const { data } = await context.client.mutate<
      SignSubmittedDataRoomRequestMutation,
      SignSubmittedDataRoomRequestMutationVariables
    >({
      mutation: SignSubmittedDataRoomRequestDocument,
      variables: {
        input: {
          commitId,
          dcrHash,
          driverAttestationHash,
          id,
        },
      },
    });
    const isMergeable = data?.signSubmittedDataRoomRequest?.signers?.every(
      (s) => Boolean(s.signature)
    );
    const currentUserSignature = data?.signSubmittedDataRoomRequest?.signature!;
    if (!isMergeable) {
      return {
        signature: currentUserSignature,
        status: SubmittedDataRoomRequestProcessingStatus.Approved,
      };
    }
    await context.client.mutate({
      mutation: MergeSubmittedDataRoomRequestDocument,
      variables: {
        input: {
          // commitId, // NOTE: was defined here but does not belong according to spec
          dcrHash,
          driverAttestationHash,
          id,
        },
      },
    });
    return {
      signature: currentUserSignature,
      status: SubmittedDataRoomRequestProcessingStatus.Merged,
    };
  };
