import { DqTable, InfoTooltip } from "@decentriq/components";
import {
  type DataPartnerUsageConfigurationFragment,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { type MRT_ColumnDef } from "material-react-table";
import React, { memo, useMemo } from "react";
import { EmptyData } from "components";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import { CollaborationTypes } from "models";
import DeleteUsageConfigurationButton from "../DeleteUsageConfigurationButton/DeleteUsageConfigurationButton";

const UsageConfigurationsTable: React.FC = () => {
  const { usageConfigurations, displayConfiguration } = useDataPartnerPortal();
  const hasDisplayConfiguration = Boolean(displayConfiguration);
  const columns: MRT_ColumnDef<DataPartnerUsageConfigurationFragment>[] =
    useMemo(() => {
      return [
        {
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="User defined name for the configuration. Not publicly visible, only within your organization." />
            </div>
          ),
          accessorKey: "name",
          header: "Configuration name",
          id: "name",
          maxSize: 150,
        },
        {
          Cell: ({ cell, row }) => {
            const advertiserDomains = cell.getValue<string[]>() || [];
            const { matchAnyDomain, id } = row?.original || {};
            return matchAnyDomain
              ? "Match any domain"
              : advertiserDomains.map((domain, index) => (
                  <React.Fragment key={`${id}-match-${index}`}>
                    {index > 0 && <br />}
                    <span>{domain}</span>
                  </React.Fragment>
                ));
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="Advertisers registered to these domains will be able to create clean rooms using this config. If there is both a domain and global config, the domain config has precedence." />
            </div>
          ),
          accessorKey: "advertiserDomains",
          header: "Advertiser",
          id: "advertiserDomains",
          maxSize: 150,
        },
        {
          Cell: ({ row }) => {
            const {
              allowInsights,
              allowLookalike,
              allowRetargeting,
              allowExclusionTargeting,
            } = row?.original || {};

            const allowedDcrFeatures = [
              allowInsights ? CollaborationTypes.Insights : undefined,
              allowRetargeting ? CollaborationTypes.Retargeting : undefined,
              allowLookalike ? CollaborationTypes.Lookalike : undefined,
              allowExclusionTargeting
                ? CollaborationTypes.ExclusionTargeting
                : undefined,
            ].filter(Boolean);
            return allowedDcrFeatures.join(", ");
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="These are the DCR collaboration types that are available under this configuration. Other collaboration types will be greyed out in the data partner discovery menu and unavailable in the clean room creation dialog." />
            </div>
          ),
          accessorKey: "allowInsights",
          header: "Collaboration types",
          id: "dcrFeatures",
        },
        {
          Cell: ({ row }) => {
            const { matchingIdFormat, matchingIdHashingAlgorithm } =
              row?.original || {};
            return matchingIdTypeAndHashingAlgorithmPresentation({
              matchingIdFormat,
              matchingIdHashingAlgorithm:
                matchingIdHashingAlgorithm as TableColumnHashingAlgorithm,
            });
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="The type of matching ID. The data you will upload into DCRs created by advertisers under this configuration must include this type of identifier." />
            </div>
          ),
          accessorKey: "matchingIdFormat",
          header: "Matching ID",
          id: "matchingId",
        },
        {
          Cell: ({ cell, row }) => {
            const participants = cell.getValue<string[]>() || [];
            const { id } = row?.original || {};
            return (
              <div>
                {participants.map((participant, index) => (
                  <React.Fragment key={`${id}-participants-${index}`}>
                    {index > 0 && <br />}
                    <span>{participant}</span>
                  </React.Fragment>
                ))}
              </div>
            );
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="These are the users from your organization that will be invited to newly created clean rooms using this configuration. These users will have permission to upload your data into the DCR." />
            </div>
          ),
          accessorKey: "participants",
          header: "Accounts",
          id: "participants",
        },
      ];
    }, []);

  if (usageConfigurations.length === 0) {
    return (
      <EmptyData
        secondaryText={`No usage configurations found. ${hasDisplayConfiguration ? "Please create one." : "Please create first a display configuration and then a usage configuration."}`}
      />
    );
  }
  return (
    <DqTable
      columns={columns}
      data={usageConfigurations}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 150,
        },
      }}
      enableRowActions={true}
      localization={{
        actions: "",
      }}
      muiTableBodyRowProps={({ row }) => ({
        "data-testid":
          testIds.dataPartnerPortal.usageConfigurations.recordHelper +
          row.original.name.toLowerCase(),
      })}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
          width: "100%",
        },
      }}
      renderRowActions={({
        row: {
          original: { id, name },
        },
      }) => <DeleteUsageConfigurationButton id={id} name={name} />}
    />
  );
};

export default memo(UsageConfigurationsTable);
