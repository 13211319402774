import { type CreateDatasetImportMutationVariables } from "@decentriq/graphql/dist/types";
import { type ExternalConnectionFormProps } from "features/datasets/components/ExternalConnections";

export enum EXTERNAL_DATA_IMPORT_STEPS {
  SELECT_SOURCE,
  SOURCE_FORM,
  IMPORT_STATUS,
}

export type ImportExternalDataFormProps = {
  onSubmit: (payload: CreateDatasetImportMutationVariables) => void;
} & ExternalConnectionFormProps;
