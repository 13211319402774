import { Box, styled, type Theme, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { memo } from "react";

const DataLabStatisticsTileBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  alignItems: "center",
  backgroundColor: grey[100],
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: theme.spacing(2),
  width: "100%",
}));

type DataLabStatisticsTileProps = {
  title: string;
  tooltipTitle: string;
  value: number;
};

const DataLabStatisticsTile: React.FC<DataLabStatisticsTileProps> = ({
  title,
  tooltipTitle,
  value,
}) => {
  const formatter = Intl.NumberFormat("en-US", { notation: "compact" });
  return (
    <Tooltip placement="top" title={tooltipTitle}>
      <DataLabStatisticsTileBox>
        <Typography sx={{ color: "#297474", marginBottom: 0.5 }} variant="h5">
          {formatter.format(value)}
        </Typography>
        <Typography sx={{ textAlign: "center" }} variant="body2">
          {title}
        </Typography>
      </DataLabStatisticsTileBox>
    </Tooltip>
  );
};

DataLabStatisticsTile.displayName = "DataLabStatisticsTile";

export default memo(DataLabStatisticsTile);
