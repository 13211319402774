import { useAuth0 } from "@auth0/auth0-react";
import { type VersionedSchema } from "@decentriq/components/dist/components/DatasetUploader/types";
import { Key } from "@decentriq/utils";
import { useCallback, useMemo, useState } from "react";
import { type KeychainItem, KeychainItemKind } from "services/keychain/types";
import { useApiCore, useKeychainService } from "contexts";
import { type DataRoomDataNodeUploading } from "features/dataNodes";
import {
  mapErrorToGeneralSnackbar,
  type NormalizedStateHookOptions,
  useDataRoomSnackbar,
  useNormalizedState,
} from "hooks";
import { type DataRoomData } from "models";
import { hashedIdAsArray, idAsHash } from "utils/apicore";
import { mapVersionedSchemaToDatasetSchema } from "utils/dataset";

interface CommonDataNodesHookPayload {
  onAfterIngestData: (args: {
    key: Key;
    dataNodeId: string;
    manifestHash: string;
    datasetId: string;
  }) => Promise<void>;
  onConnectDataset: (args: {
    key: Key;
    dataNodeId: string;
    manifestHash: string;
  }) => Promise<void>;
  onDatasetDeprovision: (args: { dataNodeId: string }) => Promise<void>;
  onDatasetDelete: (args: { dataNodeId: string }) => Promise<void>;
}

const useCommonDataNodeActions = ({
  onAfterIngestData,
  onConnectDataset,
  onDatasetDeprovision,
  onDatasetDelete,
}: CommonDataNodesHookPayload) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { user } = useAuth0();
  const { client } = useApiCore();
  const { keychain } = useKeychainService();
  const currentUserEmail = user?.email;
  const [dataNodeForIngestion, setDataNodeForIngestion] = useState<
    DataRoomData | undefined
  >();
  const uploadingsOptions = useMemo<
    NormalizedStateHookOptions<DataRoomDataNodeUploading>
  >(
    () => ({
      id: (uploading: DataRoomDataNodeUploading) =>
        `${uploading.id}-${uploading.user}`,
      initialValues: [],
    }),
    []
  );
  const {
    byId: uploadings,
    addOrUpdate: addOrUpdateUploading,
    reset: resetUploadings,
    remove: removeUploading,
  } = useNormalizedState<DataRoomDataNodeUploading>(uploadingsOptions);
  const [activeDataRoomUpload, setActiveDataRoomUpload] = useState<
    string | null
  >(null);
  const handleIngestData = useCallback(
    async ({
      dataNodeId,
      shouldStoreInKeychain,
      schema,
      uploadResult,
    }: {
      dataNodeId: string;
      shouldStoreInKeychain: boolean;
      schema?: VersionedSchema | undefined | null;
      uploadResult: { key: Key; manifestHash: string };
    }) => {
      try {
        setActiveDataRoomUpload(`${dataNodeId}-${user?.email}`);
        addOrUpdateUploading({
          error: null,
          id: dataNodeId,
          isLoading: true,
          user: user?.email,
        });
        const { manifestHash, key } = uploadResult;
        const { id: datasetId } = await client.getDataset(manifestHash);
        if (shouldStoreInKeychain) {
          const datasetHashKeychainItem: KeychainItem = {
            id: manifestHash,
            kind: KeychainItemKind.Dataset,
            value: idAsHash(key.material)!,
          };
          const itemsToInsert: KeychainItem[] = [datasetHashKeychainItem];
          if (schema) {
            const metadataKeychainItem = {
              id: manifestHash,
              kind: KeychainItemKind.DatasetMetadata,
              value: JSON.stringify(mapVersionedSchemaToDatasetSchema(schema)),
            };
            itemsToInsert.push(metadataKeychainItem);
          }
          try {
            await keychain.insertItems(itemsToInsert);
          } catch (error) {
            enqueueSnackbar(
              ...mapErrorToGeneralSnackbar(
                error,
                `Failed to store dataset hash and metadata in keychain ${error}`
              )
            );
          }
        }
        await onAfterIngestData({
          dataNodeId,
          datasetId,
          key,
          manifestHash,
        });
        removeUploading(`${dataNodeId}-${user?.email}`);
      } catch (error) {
        addOrUpdateUploading({
          error,
          id: dataNodeId,
          isLoading: false,
          user: user?.email,
        });
      }
    },
    [
      addOrUpdateUploading,
      client,
      enqueueSnackbar,
      keychain,
      onAfterIngestData,
      removeUploading,
      user?.email,
    ]
  );
  const handleConnectFromKeychain = useCallback(
    async (dataNodeId: string, keychainItem: KeychainItem) => {
      try {
        setActiveDataRoomUpload(`${dataNodeId}-${user?.email}`);
        addOrUpdateUploading({
          error: null,
          id: dataNodeId,
          isLoading: true,
          user: user?.email,
        });
        await onConnectDataset({
          dataNodeId: dataNodeId,
          key: new Key(hashedIdAsArray(keychainItem.value)),
          manifestHash: keychainItem.id,
        });
        removeUploading(`${dataNodeId}-${user?.email}`);
      } catch (error) {
        addOrUpdateUploading({
          error,
          id: dataNodeId,
          isLoading: false,
          user: user?.email,
        });
      }
    },
    [addOrUpdateUploading, onConnectDataset, removeUploading, user?.email]
  );

  const handleDataDeprovision = async (dataNodeId: string) => {
    addOrUpdateUploading({
      id: dataNodeId,
      isLoading: true,
      user: currentUserEmail,
    });
    await onDatasetDeprovision({ dataNodeId });
    removeUploading(`${dataNodeId}-${currentUserEmail}`);
  };

  const handleDataDelete = async (dataNodeId: string) => {
    addOrUpdateUploading({
      id: dataNodeId,
      isLoading: true,
      user: currentUserEmail,
    });
    await onDatasetDelete({ dataNodeId });
    removeUploading(`${dataNodeId}-${currentUserEmail}`);
  };
  const activeDataRoomUploadError =
    uploadings[activeDataRoomUpload as string]?.error;
  const handleAwaitedDataUploadingResult = useCallback(
    (isSuccess: boolean) => {
      if (!isSuccess && activeDataRoomUploadError) {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            activeDataRoomUploadError,
            `Dataset could not be uploaded.`
          )
        );
      }
    },
    [enqueueSnackbar, activeDataRoomUploadError]
  );
  const isUploadFailed =
    !!activeDataRoomUpload && uploadings[activeDataRoomUpload]?.error;
  const isUploadSuccessful =
    !!activeDataRoomUpload &&
    !uploadings[activeDataRoomUpload]?.isLoading &&
    !uploadings[activeDataRoomUpload]?.error;
  const isUploadValidating =
    !!activeDataRoomUpload && uploadings[activeDataRoomUpload]?.isLoading;
  const handleUploadClose = useCallback(() => {
    setDataNodeForIngestion(undefined);
    setActiveDataRoomUpload(null);
  }, [setDataNodeForIngestion, setActiveDataRoomUpload]);
  return {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDelete,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  };
};

export default useCommonDataNodeActions;
