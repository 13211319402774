import { Box } from "@mui/material";
import { ResponsiveRadar } from "@nivo/radar";
import { memo } from "react";
import { useAudienceOverlapInsightsData } from "features/mediaDataRoom/hooks";
import {
  BASE_PROPENSITY_CHART_KEY,
  OVERLAP_PROPENSITY_CHART_KEY,
} from "../../constants/index";

type RadarChartProps = {
  selectedSegments: string[];
  selectedAudienceType: string;
};

const RadarChart: React.FC<RadarChartProps> = ({
  selectedSegments = [],
  selectedAudienceType,
}) => {
  const { overlapInsights } =
    useAudienceOverlapInsightsData(selectedAudienceType);

  const selectedSegmentsData = overlapInsights
    .filter(({ segment }) => selectedSegments.includes(segment))
    .map((data) => ({
      // Keys defined as strings here as there is no way for now neither
      // to customize labels inside of a tooltip, nor to pass a custom tooltip
      // Keys defined here are being set as properties names inside of a tooltip
      [BASE_PROPENSITY_CHART_KEY]: data.basePropensity,
      [OVERLAP_PROPENSITY_CHART_KEY]: data.overlapPropensity,
      segment: data.segment,
    }));

  return (
    <Box sx={{ height: "calc(100vh - 700px)", minHeight: "300px" }}>
      <ResponsiveRadar
        colors={["#37C7C7", "#2FA5E6"]}
        data={selectedSegmentsData}
        indexBy="segment"
        keys={[OVERLAP_PROPENSITY_CHART_KEY, BASE_PROPENSITY_CHART_KEY]}
        margin={{
          bottom: 24,
          top: 32,
        }}
        valueFormat={(value) => `${value}%`}
      />
    </Box>
  );
};

RadarChart.displayName = "RadarChart";

export default memo(RadarChart);
