import { useCallback, useEffect, useRef, useState } from "react";
import { useEffectiveSearchParams } from "contexts";

interface DataRoomTabsNavigationStateHookPayload {
  dataRoomId: string;
  defaultTab: string;
  tabs: string[];
}

interface DataRoomTabsNavigationStateHookResult {
  setActiveTab: (tab: string) => void;
  activeTab: string;
}

const useDataRoomTabsNavigationState = ({
  dataRoomId,
  defaultTab,
  tabs,
}: DataRoomTabsNavigationStateHookPayload): DataRoomTabsNavigationStateHookResult => {
  const firstRenderRef = useRef(true);
  const { searchParams, setSearchParams } = useEffectiveSearchParams();
  const tab = searchParams.get("tab");
  const [activeTab, setActiveTab] = useState<string | null>(
    tab && tabs.includes(tab) ? tab : null
  );
  const handleSetActiveTab = useCallback(
    (activeTab: string) => {
      setActiveTab((currentActiveTab) => {
        if (activeTab === (currentActiveTab || defaultTab)) {
          return currentActiveTab;
        }

        setSearchParams({
          tab: activeTab,
        });

        return activeTab;
      });
    },
    [setActiveTab, defaultTab, setSearchParams]
  );
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      setActiveTab(defaultTab);
    }
  }, [dataRoomId, defaultTab, setActiveTab]);
  return {
    activeTab: activeTab || defaultTab,
    setActiveTab: handleSetActiveTab,
  };
};

export default useDataRoomTabsNavigationState;
