import { testIds } from "@decentriq/utils";
import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActivationConfigurationForm } from "features/publisherPortal/components";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import { CollaborationTypes } from "models";
import {
  matchingIdTypeToGqlValues,
  type RawMatchingID,
} from "models/dataRoom/matchingId";
import {
  ActivationConfigurationFormValidationSchema,
  type ActivationConfigurationFormValues,
  defaultActivationConfigurationFormValues,
} from "../CreateActivationConfigurationForm/model";

type CreateActivationConfigurationDialogProps = {
  open: boolean;
  onClose: () => void;
};

const CreateActivationConfigurationDialog: React.FC<
  CreateActivationConfigurationDialogProps
> = ({ open, onClose }) => {
  const { addActivationConfiguration, publisherDisplayConfiguration } =
    usePublisherPortal();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, reset, ...activationConfigurationForm } =
    useForm<ActivationConfigurationFormValues>({
      defaultValues: defaultActivationConfigurationFormValues,
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(ActivationConfigurationFormValidationSchema),
    });
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);
  const publisherDisplayConfigurationId = publisherDisplayConfiguration?.id;
  const handleCreate = useCallback(
    async (values: ActivationConfigurationFormValues) => {
      const {
        matchingId,
        name,
        advertiserDomains = [],
        publisherParticipants = [],
        dataRoomTypes = [],
        matchAnyDomain = false,
      } = values;
      const [matchingIdFormat, matchingIdHashingAlgorithm] =
        matchingIdTypeToGqlValues[matchingId! as RawMatchingID];
      setLoading(true);
      await addActivationConfiguration({
        onCompleted: () => {
          enqueueSnackbar(`DCR configuration has been created`);
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(`Cannot create DCR configuration.`, {
            context: error?.message,
            persist: true,
            variant: "error",
          });
        },
        variables: {
          input: {
            advertiserDomains: matchAnyDomain ? [] : advertiserDomains,
            allowExclusionTargeting: dataRoomTypes.includes(
              CollaborationTypes.ExclusionTargeting
            ),
            allowInsights: dataRoomTypes.includes(CollaborationTypes.Insights),
            allowLookalike: dataRoomTypes.includes(
              CollaborationTypes.Lookalike
            ),
            allowMeasurements: dataRoomTypes.includes(
              CollaborationTypes.Measurements
            ),
            allowRetargeting: dataRoomTypes.includes(
              CollaborationTypes.Retargeting
            ),
            matchAnyDomain,
            matchingIdFormat,
            matchingIdHashingAlgorithm,
            name,
            publisherDisplayConfigurationId: publisherDisplayConfigurationId!,
            publisherParticipants,
          },
        },
      });
      setLoading(false);
    },
    [
      addActivationConfiguration,
      publisherDisplayConfigurationId,
      enqueueSnackbar,
      onClose,
    ]
  );
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={loading ? undefined : onClose}
      open={open}
    >
      <FormProvider
        {...activationConfigurationForm}
        handleSubmit={handleSubmit}
        reset={reset}
      >
        <DialogTitle>New DCR configuration</DialogTitle>
        <DialogContent>
          <ActivationConfigurationForm />
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            color="inherit"
            disabled={loading}
            onClick={onClose}
            variant="text"
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            data-testid={
              testIds.publisherPortal.createActivationConfigurationDialog
                .submitButton
            }
            loading={loading}
            loadingPosition="start"
            onClick={handleSubmit(handleCreate)}
            startIcon={<FontAwesomeIcon icon={faPlusRegular} />}
            variant="contained"
          >
            Create
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

CreateActivationConfigurationDialog.displayName =
  "CreateActivationConfigurationDialog";

export default memo(CreateActivationConfigurationDialog);
